import React from 'react';
import Axios from 'axios';
import qs from 'qs';
import { Button, TextField, Link, Typography, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { AuthContext, SnackbarContext, CredentialsModel, ThemeContext } from 'go-boost-library-react';
import history from '../history';
import { log, withContext, MaxWidth } from 'kn-react';
import { Link as RouterLink } from 'react-router-dom';
import FreshChatButton from 'AppNavigation/freshChatButton';


class SignIn extends React.Component {
  state = {
    email: '',
    password: '',
  };

  componentDidMount() {
    // TODO Replace this with AuthForbiddenGuard from go-boost-library-react when I get it working...
    if (this.props.hasToken()) {
      history.push('/');
    }
  }


  onSubmit = e => {
    e.preventDefault();

    if (!this.state.email.length) {
      return this.props.showSnackbar('Please enter a valid email.');
    }

    return Axios.post(
      '/api/core/auth/sign_in',
      { email: this.state.email, password: this.state.password }
    )
      .then(response => {
        log('response', response);
        const credentials = CredentialsModel.fromJSON(response.data.credentials);
        log('credentials', credentials);
        this.props.setCredentials(credentials);
        this.props.hideSnackbar();

        const state = this.props.location.state || {};
        log('referrer', state.referrer);
        history.push(state.referrer || '/');
      })
      .catch(e => {
        log('error', e);
        this.props.showSnackbar('Invalid email or password.');
      });
  }

  onClickChat = () => {
    const { fcWidget } = window;
    if (!fcWidget) {
      log('Cannot find fcWidget!');
      return;
    }

    fcWidget.open();
    fcWidget.show();
  }


  render() {
    const { classes } = this.props;
    let { isSignInDisabled } = this.props.theme;
    const { signInRedirectText, signInRedirectUrl, isSignUpDisabled } = this.props.theme;

    if( this.props.theme ){
      const queryParams = qs.parse(window.location.search.slice(1));
      const signInOverride = [true, 'true'].includes( queryParams.sign_in );

      isSignInDisabled = signInOverride ? false : this.props.theme.isSignInDisabled;
    }

    return (
      <MaxWidth maxWidth={430}>
        <Paper className={classes.paperRoot}>
          <div style={{ float: 'right' }}>
            <FreshChatButton/>
          </div>

          <Typography variant="h4">
            Sign In
          </Typography>

          {
            isSignInDisabled ?
              null
            :
              <>
                <form onSubmit={this.onSubmit} className={classes.formRoot}>
                  <TextField
                    label="Enter your email"
                    type="email"
                    onChange={e => this.setState({ email: e.target.value })}
                    value={this.state.email}
                    fullWidth
                    style={{ marginBottom: 25 }}
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    label="Enter your password"
                    type="password"
                    onChange={e => this.setState({ password: e.target.value })}
                    value={this.state.password}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />

                  <Typography>
                    <Link
                      component={RouterLink}
                      to="/forgot"
                    >
                      Forgot your password?
                    </Link>
                  </Typography>

                  <Button
                    variant="contained"
                    className={classes.signInButton}
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Sign in
                  </Button>
                </form>
              </>
          }

          {
            !isSignUpDisabled ?
              <Typography className={classes.signUpLinkRoot}>
                Don't have an account?&nbsp;

                <Link
                  component={RouterLink}
                  to="/sign_up"
                  className={classes.signUpLink}
                >
                  Sign Up
                </Link>
              </Typography>
            :
              null
          }

          {
            isSignInDisabled && signInRedirectUrl && signInRedirectText ?
              <Button
                variant="contained"
                className={classes.signInButton}
                color={'primary'}
                fullWidth
                component={"a"}
                href={signInRedirectUrl}
                style={{ marginBottom: 25 }}
              >
                { signInRedirectText }
              </Button>
            :
              isSignUpDisabled && isSignInDisabled ?
                <p>Please contact Support for help.</p>
              :
                null
          }
        </Paper>
      </MaxWidth>
    );
  }
}


const styles = theme => ({
  paperRoot: {
    marginTop: 100,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 35,
    paddingBottom: 5,
  },
  heading: {
    textAlign: 'center',
  },
  formRoot: {
    marginTop: 25,
    marginBottom: 25,
  },
  signInButton: {
    marginTop: 25
  },
  signUpLinkRoot: {
    marginTop: 20,
    padding: 15,
    textAlign: 'center',
  },
  signUpLink: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  }
});

export default withStyles(styles)(withContext(AuthContext, ThemeContext, SnackbarContext, SignIn));

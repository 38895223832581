import React from 'react';
import Axios from 'axios';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import PlanVirtualFormDesignModel from './PlanVirtualFormDesignModel';
import VirtualFormDesignModel from 'Virtual/VirtualFormDesign/VirtualFormDesignModel';

const DEFAULT_STATE = {
  isVirtualFormDesignsLoading: true,
  planVirtualFormDesigns: [],
  virtualFormDesigns: [],
};


export const VirtualFormDesignSetupContext = React.createContext(DEFAULT_STATE);


class VirtualFormDesignSetupProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    return this.fetchVirtualFormDesigns();
  }



  fetchVirtualFormDesigns = () => {
    return Axios.get(
      `/api/virtual/virtual_form_designs/companies/${ this.props.company.id }/available`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const planVirtualFormDesigns = response.data.plan_virtual_form_designs.map(
        v => PlanVirtualFormDesignModel.fromJSON( v )
      );

      const virtualFormDesigns = response.data.virtual_form_designs.map(
        v => VirtualFormDesignModel.fromJSON( v )
      );

      return this.setState({
        planVirtualFormDesigns,
        virtualFormDesigns,
        isVirtualFormDesignsLoading: false
      });
    })
  }



  render() {
    if( this.state.isVirtualFormDesignsLoading ){
      return <LinearProgress />;
    }


    return (
      <VirtualFormDesignSetupContext.Provider
        value={{
          ...this.state
        }}

      >
        {this.props.children}
      </VirtualFormDesignSetupContext.Provider>
    );
  }
}


export default withContext(
  CompanyRoleContext,
  VirtualFormDesignSetupProvider
);
import React from 'react';
import { withContext, log } from 'kn-react';
import { TableToolbar, PromiseButton, SnackbarContext } from 'go-boost-library-react';

import { Paper, Grid, withStyles, TextField } from '@material-ui/core';




class SearchConsoleTag extends React.Component {
  state = {
    tag: ''
  }

  componentDidMount = () => {
    return this.setState({ tag: this.props.tag });
  }

  componentDidUpdate = oldProps => {
    if( this.props !== oldProps ){
      return this.setState({ tag: this.props.tag });
    }
  }

  onEditTag = () => {
    return this.props.onEditTag(
      this.state.tag
    ).then(
      () => this.props.showSnackbar('Search Console Tag successfully updated.')
    ).catch(error => {
      log(error);
      this.props.showSnackbar('There was an error updating the Search Console Tag.');
    });
  }


  render() {
    const { classes } = this.props;

    return (
      <Paper style={{ padding: 15 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <TableToolbar
              title="Google Search Console Tag"
            />

            <div style={{ padding: 15 }}>
              <pre>
                <TextField
                  multiline
                  fullWidth
                  disabled={this.props.canEdit ? undefined : true}
                  value={this.state.tag}
                  variant='outlined'
                  InputProps={{
                    style: { fontSize: 15 }
                  }}
                  className={classes.code}
                  onChange={e => this.setState({ tag: e.target.value })}
                />
              </pre>
            </div>
          </Grid>

          {
            !this.props.canEdit ?
              null
            :
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <PromiseButton
                  onProcess={this.onEditTag}
                  buttonProps= {{
                    variant: 'contained',
                    color: 'primary',
                    type: 'submit',
                    id: 'update-search-console-tag',
                    name: 'update-search-console-tag',
                  }}
                >
                  Update
                </PromiseButton>
              </Grid>
          }
        </Grid>
      </Paper>
    )
  }
}


const styles = theme => ({
  code: {
    backgroundColor: '#eee'
  },
  divider: {
    marginTop: 15
  }
})




SearchConsoleTag.defaultProps = {
  tag: '',
  canEdit: true,
  onEditTag: () => Promise.resolve()
}



export default withContext(
  SnackbarContext,
  withStyles(styles)(
    SearchConsoleTag
  )
)
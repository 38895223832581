import React from 'react';
import { withContext } from 'kn-react';
import { Typography, withStyles, Paper, TextField } from '@material-ui/core';
import { isDemo, isStaging, isDevelopment } from 'go-boost-library-react';
import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';


const embedScriptTag = props => {
  const { classes } = props;

  return (
    <Paper className={classes.paperRoot}>
      <Typography variant="subtitle1" color="primary">Embed Script Tag</Typography>

      <Typography variant="body1">
        To embed reviews, you must place the following tag on your website.
      </Typography>
      <br />

      <TextField
        variant="outlined"
        multiline
        value={`<script async src="${ embedScriptSource() }" data-gb-reviews-embed-id="${ props.companyReviewsProfile.embedId }"></script>`}
        className={classes.codeBlock}
      />
    </Paper>
  );
}

const styles = theme => ({
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 5
  },
  avatarText: {
    lineHeight: '30px'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  paperRoot: {
    padding: 16
  },
  codeBlock: {
    width: '100%',
    backgroundColor: 'whitesmoke'
  }
})

export default withContext(
  CompanyReviewsProfileContext,
  withStyles(styles)(embedScriptTag)
);



export const reviewsBucketUrl = () => {
  let bucket;
  if( isDevelopment() || isStaging() ){
    bucket = 'go-boost-partners-staging-public';
  } else if( isDemo() ){
    bucket = 'go-boost-partners-demo-public';
  } else{
    bucket = 'go-boost-partners-public';
  }

  return `https://storage.googleapis.com/${ bucket }/reviews`;
}



export const embedScriptSource = () => (
  `${ reviewsBucketUrl() }/go-boost-reviews-embed.min.js`
)
import React from 'react';
import Axios from 'axios';

import { FacebookAccountModel, FacebookAccountPageModel, SnackbarContext, UserRoleContext, Ability } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import CompanyReviewSiteModel from 'Reviews/CompanyReviewSiteModel';



const DEFAULT_STATE = {
  facebookClientId: '',
  facebookAccountLoading: true,
  facebookAccountPageLoading: false,
  facebookAccount: {},
  facebookAccountPage: {}
};


export const FacebookAccountContext = React.createContext(DEFAULT_STATE);


class FacebookAccountProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    return this.setState(
      { facebookAccountLoading: true },
      this.fetchData
    );
  }



  fetchData = () => {
    return this.setState(
      { facebookAccountLoading: true },
      () => this.fetchClientId()
        .then(this.fetchFacebookAccount)
        .then(this.fetchFacebookAccountPage)
        .finally(() => this.setState({ facebookAccountLoading: false }))
    );
  }


  fetchClientId = () => {
    log('fetchClientId');

    if (!this.props.currentUserRole.roleHasAbility( Ability.EDIT_FACEBOOK_ACCOUNT )) {
      // Return a promise so that this function can be chained
      return new Promise((resolve, reject) => resolve('Skipping fetchClientId because of improper abilities.'))
    }

    return Axios.get(
      `/api/core/facebook_accounts/auth_params`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchClientId response', response);
      this.setState({ facebookClientId: response.data.params.client_id })
    })
  }


  fetchFacebookAccount = () => {
    log('fetchFacebookAccount');

    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/facebook_accounts/companies/${currentUserRole.roleTypeId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchFacebookAccount', response);
        this.setFacebookAccount(response.data.facebook_account)
      })
      .catch(error => {
        log('fetchFacebookAccount error.response', error.response);
        if (error.response && error.response.data && error.response.data.message) {
          this.props.showSnackbar(error.response.data.message);
        }
      });
  }



  setFacebookAccount = (accountData, callback) => {

    const facebookAccount = FacebookAccountModel.fromJSON(accountData);
    log('setFacebookAccount Model', facebookAccount);
    this.setState(
      { facebookAccount },
      () => (callback) ? callback() : null
    );
  }


  onCode = (code, callback) => {
    log('onCode');

    return Axios.post(
      '/api/core/facebook_accounts/auth',
      { code },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('onCode response', response);
      this.setFacebookAccount(response.data.facebook_account, callback);
    })
    .catch(error => {
      log('facebook auth processCode error', error);
      this.props.showSnackbar(error.response.data.message);
    });
  }




  fetchFacebookAccountPage = () => {
    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/facebook_account_pages/companies/${currentUserRole.roleTypeId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchFacebookAccountPage', response);
        const facebookAccountPage = FacebookAccountPageModel.fromJSON(response.data.facebook_account_page);
        log('fetchFacebookAccountPage Model', facebookAccountPage);
        this.setState({ facebookAccountPage });
      })
      .catch(error => {
        log('fetchFacebookAccountPage error.response', error.response);
        if (error.response && error.response.data && error.response.data.message) {
          this.props.showSnackbar(error.response.data.message);
        }
      });
  }


  putFacebookAccountPage = (page, options={ shouldConnectBusinessManager: false }) => {
    const shouldConnectBusinessManager = (
      options ?
        options.shouldConnectBusinessManager || undefined
      :
        undefined
    );

    this.setState(
      { facebookAccountPageLoading: true }
    );

    return Axios.put(
        `/api/core/facebook_account_pages`,
        {
          ...page,
          should_connect_business_manager: shouldConnectBusinessManager
        },
        { headers: this.props.getUserRoleAuthHeaders() }
      ).then(response => {
        const facebookAccountPage = FacebookAccountPageModel.fromJSON(response.data.facebook_account_page);
        log('creating facebook account page', facebookAccountPage);

        this.setState({
          facebookAccountPage,
          facebookAccountPageLoading: false
        });

        return facebookAccountPage;
      }).catch(error => {
        log('fetchFacebookAccountPage error.response', error.response);
        if (error.response && error.response.data && error.response.data.message) {
          this.props.showSnackbar(error.response.data.message, { autoHide: false });
        }

        this.setState({ facebookAccountPageLoading: false });

        throw error
      });
  }


  connectToBusinessManager = () => {
    return Axios.post(
      `/api/core/facebook_account_pages/${ this.state.facebookAccountPage.id }/business_manager`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        const facebookAccountPage = FacebookAccountPageModel.fromJSON(response.data.facebook_account_page);
        log('updating facebook account page', facebookAccountPage);
        this.setState({ facebookAccountPage });
        return facebookAccountPage;
      })
      .catch(error => {
        log('connectToBusinessManager error.response', error.response);
        if (error.response && error.response.data && error.response.data.message) {
          this.props.showSnackbar(error.response.data.message, { autoHide: false });
        }

        throw error
      });
  }


  facebookSignOut = () => {
    const { facebookAccount } = this.state;
    const { getUserRoleAuthHeaders } = this.props;

    return Axios.get(
      `/api/core/facebook_accounts/${ facebookAccount.id }/sign_out`,
      { headers: getUserRoleAuthHeaders() }
    ).then(response => this.setState(
      { loading: true },
      this.fetchData
    )).catch(error => {
      log('fetchFacebookAccountPage error.response', error.response);
    });
  }


  isSignedIn = () => {
    const { facebookAccount, facebookAccountPage } = this.state;

    return Boolean(facebookAccount.id && facebookAccountPage.id)
  }



  getFacebookPagesCallback = (errorCallback) => {
    log('getFacebookPagesCallback', this.state.facebookAccount);

		return Axios.get(
      `/api/core/facebook_accounts/${ this.state.facebookAccount.id }/pages`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('getFacebookPagesCallback response', response.data);

        const pages = response.data.facebook_pages;

        return pages
      })
      .catch(error => {
        log('getFacebookPagesCallback error', error);
        this.props.showSnackbar('Something went wrong.');

        return (errorCallback) ? errorCallback() : null;
      });
  }


  findOrCreateFacebookReviewSite = () => {
    log('findOrCreateFacebookReviewSite', this.state.facebookAccountPage);

    const respondWithCompanyReviewSite = response => {
      log('findOrCreateFacebookReviewSite response', response.data);

      return CompanyReviewSiteModel.fromJSON(
        response.data.company_review_site
      );
    };


    return Axios.get(
      `/api/reviews/company_review_sites/companies/${ this.state.facebookAccount.companyId }/facebook`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      respondWithCompanyReviewSite
    ).catch(error => {
      log('findOrCreateFacebookReviewSite error', error);
      if( error.response.status === 404 ){
        return Axios.post(
          `/api/core/facebook_account_pages/companies/${ this.state.facebookAccount.companyId }/company_review_site`,
          {},
          { headers: this.props.getUserRoleAuthHeaders() }
        ).then(
          respondWithCompanyReviewSite
        ).catch(error => {
          log('findOrCreateFacebookReviewSite error', error);
          this.props.showSnackbar('Something went wrong.');
        });
      }


      this.props.showSnackbar('Something went wrong.');
    });
  }


  render() {
    if (this.state.facebookAccountLoading) {
      return <LinearProgress />;
    }

    return (
      <FacebookAccountContext.Provider
        value={{
          facebookClientId: this.state.facebookClientId,
          facebookAccount: this.state.facebookAccount,
          facebookAccountPage: this.state.facebookAccountPage,
          facebookAccountPageLoading: this.state.facebookAccountPageLoading,
          setFacebookAccount: this.setFacebookAccount,
          fetchFacebookAccount: this.fetchFacebookAccount,
          fetchFacebookAccountPage: this.fetchFacebookAccountPage,
          facebookSignOut: this.facebookSignOut,
          putFacebookAccountPage: this.putFacebookAccountPage,
          facebookAccountIsSignedIn: this.isSignedIn,
          onCode: this.onCode,
          getFacebookPagesCallback: this.getFacebookPagesCallback,
          connectToBusinessManager: this.connectToBusinessManager,
          findOrCreateFacebookReviewSite: this.findOrCreateFacebookReviewSite
        }}
      >
        {this.props.children}
      </FacebookAccountContext.Provider>
    );
  }
}


export default withContext(UserRoleContext, SnackbarContext, FacebookAccountProvider);
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Star from '@material-ui/icons/Star';
import * as d3 from 'd3';
import { HorizontalPercentBar } from 'go-boost-library-react';
import { withContext } from 'kn-react';
import React from 'react';
import { ReviewSourcesContext } from 'Reviews/ReviewSourcesProvider';

const format = d3.format('.2f');


const reviewSourceDistribution = props => {
  const { reviewSourceDistribution, classes } = props;

  const sum = d3.sum(reviewSourceDistribution, d => d.count);
  const max = d3.max(reviewSourceDistribution, d => d.count);


  return (
    <Grid container item xs={12}>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Review Sites Distribution</Typography>
        </Grid>

        <Grid container item xs={12}>
          {
            reviewSourceDistribution && reviewSourceDistribution.length ?
              reviewSourceDistribution.map(d => {
                const reviewSource = props.getReviewSourceById(d.review_source_id);


                if (!reviewSource) {
                  return;
                }

                return (
                  <Grid container key={reviewSource.id} style={{ paddingBottom: 5 }}>
                    <Grid item xs={4} style={{ display: 'flex' }}>
                      {
                        reviewSource.getAvatar({
                          avatarProps: { classes: { root: classes.smallAvatar, img: classes.smallAvatarImage } },
                        })
                      }
                      {reviewSource.name}
                    </Grid>

                    <Grid item xs={2} style={{ display: 'flex', textAlign: 'center' }}>
                      {format(d.rating_average)} <Star style={{ fontSize: 20, color: '#F0641F' }} />
                    </Grid>

                    <Grid item xs={6}>
                      <HorizontalPercentBar
                        backgroundColor='#fff'
                        color='#37caad'
                        widthPercent={d.count / max}
                        displayPercent={d.count / sum}
                        number={d.count}
                      />
                    </Grid>
                  </Grid>
                )
              })
            :
              <p style={{ opacity: 0.5 }}>No chart data available</p>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

reviewSourceDistribution.defaultProps = {
  reviewSourceDistribution: [],
}


const styles = theme => ({
  smallAvatar: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
  smallAvatarImage: {
    height: 20,
  }
});


export default withStyles(styles)(
  withContext(ReviewSourcesContext, reviewSourceDistribution)
);
import React from "react";
import Axios from "axios";

import { TableToolbar, SmallTablePagination, UserRoleContext, CompanyModel } from "go-boost-library-react";
import { withContext, log } from "kn-react";

import { Clear } from "@material-ui/icons";
import { IconButton, Tooltip, Typography, LinearProgress, withStyles, Paper, Divider, Grid } from "@material-ui/core";

import CompaniesList from "./CompaniesList";
import CompanySearchBox from "./CompanySearchBox";

class CompanyFilterTable extends React.Component {
  state = {
    loadedCompanies: [],
    selectedCompanies: this.props.initialCompanies,
    loadedCompaniesPage: 0,
    selectedCompaniesPage: 0,
    loadedCompaniesRowsPerPage: 5,
    selectedCompaniesRowsPerPage: 5,
    count: 0,
    loading: true,
    refreshing: false,
    query: "",
  };

  componentDidMount = () => {
    this.fetchCompanies();
  };

  componentDidUpdate = (oldProps) => {
    if (
      this.props.organization &&
      this.props.organization.id !== oldProps.organization.id
    ) {
      this.fetchCompanies();
    }
  };

  fetchCompanies = () => {
    const { loadedCompaniesRowsPerPage, loadedCompaniesPage, query } = this.state;
    log("fetchCompanies", loadedCompaniesRowsPerPage, loadedCompaniesPage);

    return Axios.get(
      `/api/core/organizations/${this.props.organization.id}/companies`,
      {
        params: {
          limit: loadedCompaniesRowsPerPage,
          page: loadedCompaniesPage,
          query: query ? query : undefined,
          include_descendants: true,
        },
        headers: this.props.getUserRoleAuthHeaders(),
      }
    ).then((response) => {
      log("fetchCompanies response", response);
      const loadedCompanies = response.data.companies.map((c) =>
        CompanyModel.fromJSON(c)
      );

      response.data.company_organizations.forEach((co) => {
        const company = loadedCompanies.find((c) => c.id === co.company_id);
        const organizations = company.organizationName || [];

        organizations.push(co.name);
        company.organizationName = organizations;
      });

      this.setState({
        loadedCompanies,
        count: response.data.count,
        loading: false,
        refreshing: false,
      });
    });
  };

  onSubmitSearch = (query) => {
    this.setState(
      { query, refreshing: true, loading: true, loadedCompaniesPage: 0 },
      this.fetchCompanies
    );
  };

  onClickCompany = company => {
    let newSelectedCompanies = [
      ...this.state.selectedCompanies
    ];

    if( newSelectedCompanies.find(c => c.id === company.id) ){
      newSelectedCompanies = newSelectedCompanies.filter(
        c => c.id !== company.id
      );
    } else {
      newSelectedCompanies.push( company );
    }

    return this.setState(
      { selectedCompanies: newSelectedCompanies },
      this.props.onCompaniesChange(
        newSelectedCompanies
      )
    );
  };

  listItemSecondaryAction = (company) => (
    <Tooltip title={`Remove ${company.name}`}>
      <IconButton onClick={() => this.onClickCompany(company)}>
        <Clear />
      </IconButton>
    </Tooltip>
  );

  render() {
    const { classes } = this.props;

    const selectedCompanyIds = this.state.selectedCompanies.map(
      c => +c.id
    );

    const selectedCompaniesStartIndex = (
      this.state.selectedCompaniesRowsPerPage*this.state.selectedCompaniesPage
    );
    const selectedCompaniesEndIndex = Math.min(
      selectedCompaniesStartIndex+this.state.selectedCompaniesRowsPerPage,
      this.state.selectedCompanies.length
    );

    const selectedCompanies = this.state.selectedCompanies.slice(
      selectedCompaniesStartIndex,
      selectedCompaniesEndIndex
    );

    return (
      <>
        <TableToolbar
          title="Filter Companies"
          actions={this.props.actions}
          caption={
            <>
              <Typography variant='caption'>
                Companies that belong to{" "}
                <span className={classes.primary}>{this.props.organization.name}</span>{" "}
                and its suborganizations.
              </Typography>

              <Typography variant='caption'>
                Type the company's name in the box and hit "enter" to search for a company.
              </Typography>
            </>
          }
        />

        {
          this.state.loading ?
            <LinearProgress />
          :
            <div style={{ height: 4 }} />
        }

        <CompanySearchBox onSubmitSearch={this.onSubmitSearch} />

        <Grid container>
          <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
            <div style={{ display: "flex", flexDirection: "column", width: '100%', borderRight: '1px solid #ccc' }}>
              <Typography variant="h6" className={classes.sectionHeader}>
                All Companies
              </Typography>

              <div style={{ flex: 1 }}>
                <div style={{ overflowY: "scroll", height: 400 }}>
                  {!this.state.loading && !this.state.loadedCompanies.length ? (
                    <Typography variant="caption" className={classes.noData}>
                      No companies.
                    </Typography>
                  ) : (
                    <CompaniesList
                      loading={this.state.loading}
                      refreshing={this.state.refreshing}
                      companies={this.state.loadedCompanies}
                      showDescendantsInfo={true}
                      shouldRoute={false}
                      onClick={this.onClickCompany}
                      selectedCompanyIds={selectedCompanyIds}
                    />
                  )}
                </div>
              </div>

              <Divider />

              <SmallTablePagination
                count={this.state.count || 0}
                rowsPerPage={this.state.loadedCompaniesRowsPerPage}
                page={this.state.loadedCompaniesPage}
                onChangeRowsPerPage={(e) =>
                  this.setState(
                    {
                      loadedCompaniesRowsPerPage: e.target.value,
                      refreshing: true
                    },
                    this.fetchCompanies
                  )
                }
                onChangePage={(e, p) =>
                  this.setState(
                    {
                      loadedCompaniesPage: p,
                      refreshing: true
                    },
                    this.fetchCompanies
                  )
                }
                rowsPerPageOptions={[5, 10, 25, 50]}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
            <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
              <Typography variant="h6" className={classes.sectionHeader}>
                Selected Companies
              </Typography>

              <div style={{ flex: 1 }}>
                <CompaniesList
                  companies={selectedCompanies}
                  showDescendantsInfo={true}
                  shouldRoute={false}
                  onClick={this.onClickCompany}
                  listItemSecondaryAction={this.listItemSecondaryAction}
                />
              </div>

              <Divider />

              <SmallTablePagination
                count={this.state.selectedCompanies.length || 0}
                rowsPerPage={this.state.selectedCompaniesRowsPerPage}
                page={this.state.selectedCompaniesPage}
                onChangeRowsPerPage={e =>
                  this.setState({ selectedCompaniesRowsPerPage: e.target.value })
                }
                onChangePage={(e, p) =>
                  this.setState({ selectedCompaniesPage: p })
                }
                rowsPerPageOptions={[5, 10, 25, 50]}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

CompanyFilterTable.defaultProps = {
  initialCompanies: [],
  organization: {},
  getUserRoleAuthHeaders: () => {},
  onCompaniesChange: () => {},
};

const styles = (theme) => ({
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  sectionHeader: {
    textAlign: "left",
    color: "#777",
    paddingLeft: 15,
    paddingTop: 5,
  },
  searchIcon: {
    marginLeft: 24,
    color: "#A7A7A7",
  },
  primary: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
});

export default withStyles(styles)(
  withContext(UserRoleContext, CompanyFilterTable)
);

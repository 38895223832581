import React from "react";
import Axios from "axios";
import Moment from 'moment';
import * as d3 from "d3";
import qs from "qs";
import { withStyles } from "@material-ui/core/styles";
import { log, withContext, format } from "kn-react";
import { SnackbarContext, TableToolbar, UserRoleContext } from "go-boost-library-react";
import { Link as RouterLink } from "react-router-dom";

import { SmallTablePagination, StarsRating } from 'go-boost-library-react';
import { DateRangePicker } from 'react-dates';
import { MenuItem, TextField, Typography } from "@material-ui/core";

import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";

import CompanySearchBox from "Settings/Companies/CompanyTable/CompanySearchBox";
import { ratingColors } from 'Reviews/ReviewsDashboard/RatingDistribution';
import downloadFile from 'Download/downloadFile';
import { compareToPrior, metricChange, getPriorTimePeriod, absoluteChangeFormat, decimalChangeFormat, fillMissingMoments } from 'SharedDashboard/sharedDashboard';

import CompanyDistributionModel from "./CompanyDistributionModel";


const timePeriodOptions = [
  { unit: 'month', label: 'All time' },
  { unit: 'day', numberOfUnits: 7, label: 'Past 7 days' },
  { unit: 'day', numberOfUnits: 30, label: 'Past 30 days' },
  { unit: 'day', numberOfUnits: 90, label: 'Past 90 days' },
  { unit: 'month', numberOfUnits: 12, label: 'Past year' },
  { label: 'Custom' },
];


const START_DATE = Moment('1/1/2019');
const END_DATE = Moment();



class ReviewsReporting extends React.Component {
  state = {
    loading: true,
    companyDistribution: [],
    query: '',
    limit: 50,
    page: 0,
    count: 0,
    timePeriod: timePeriodOptions[ 0 ],
    startDate: null,
    endDate: null,
    focusedInput: null
  };

  componentDidMount = () => {
    this.fetchReviewsReporting();
  };

  fetchReviewsReporting = () => {
    const { currentUserRole } = this.props;
    const { query, limit, page } = this.state;

    const timePeriodMoment = this.getTimePeriodMoment();

    return Axios.get(
      `/api/reviews/organizations/${currentUserRole.roleTypeId}/company_distribution`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          limit,
          page,
          query: query || undefined,
          start_date: this.getStartDate( timePeriodMoment ),
          end_date: this.getEndDate()
        },
      }
    ).then((response) => {
      log("fetchReviewsReporting", response);

      const companyDistribution = response.data.company_distribution.map((r) =>
        CompanyDistributionModel.fromJSON(r)
      );
      log('companyDistribution', companyDistribution);

      this.setState({
        companyDistribution,
        count: response.data.count,
        loading: false,
      });

      const top = window.document.getElementById("top");
      if (top){
        top.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    });
  };



  onSubmitSearch = (query) => {
    this.setState({ query }, this.fetchReviewsReporting);
  };



  onClickDownload = () => {
    const { currentUserRole } = this.props;


    const timePeriodMoment = this.getTimePeriodMoment();

    const params = {
      csv: true,
      query: this.state.query || undefined,
      start_date: this.getStartDate( timePeriodMoment ),
      end_date: this.getEndDate()
    };

    return downloadFile(
      `/api/reviews/organizations/${currentUserRole.roleTypeId}/company_distribution?${qs.stringify(params)}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
  }



  onChangeRowsPerPage = limit => {
    this.setState(
      { limit },
      this.fetchReviewsReporting
    );
  }



  onChangePage = page => {
    this.setState(
      { page },
      this.fetchReviewsReporting
    );
  }



  onChangeTimePeriodOption = e => {
    const timePeriod = e.target.value;

    const shouldFetchReport = (
      timePeriod !== timePeriodOptions[ timePeriodOptions.length - 1 ]
    );

    return this.setState(
      {
        timePeriod,
        startDate: null,
        endDate: null,
        focusedInput: null,
        loading: shouldFetchReport ? true : this.state.loading
      },
      () => {
        if( shouldFetchReport ){
          return this.fetchReviewsReporting();
        }
      }
    );
  }



  getTimePeriodMoment = () => {
    const { numberOfUnits, unit } = this.state.timePeriod;
    return numberOfUnits ? Moment().subtract(numberOfUnits, unit) : undefined;
  }



  getStartDate = timePeriodMoment => {
    return (
      this.state.startDate ?
        this.state.startDate.toISOString()
      :
        timePeriodMoment ?
          timePeriodMoment.format('YYYY-MM-DD')
        :
          undefined
    );
  }



  getEndDate = () => {
    return (
      this.state.endDate ?
        this.state.endDate.toISOString()
      :
        undefined
    );
  }



  onDatesChange = ({ startDate, endDate }) => {
    const newState = {
      'startDate': startDate ? startDate : this.state.startDate,
      'endDate': endDate ? endDate : this.state.endDate,
    };

    const shouldFetchReport = (
      newState.startDate &&
      newState.endDate
    );

    if( shouldFetchReport ){
      newState.loading = true;
    }


    return this.setState(
      { ...newState },
      () => {
        if( shouldFetchReport ){
          return this.fetchReviewsReporting();
        }
      }
    )
  }



  render() {
    const { classes } = this.props;
    const { companyDistribution } = this.state;

    return (
      <>
        <div className={classes.timePeriodDiv}>
          <TextField
            select
            label="Time Period"
            variant="outlined"
            value={this.state.timePeriod}
            onChange={this.onChangeTimePeriodOption}
            className={classes.textField}
          >
            {
              timePeriodOptions.map((t, i) => (
                <MenuItem value={t} key={i}>{t.label}</MenuItem>
              ))
            }
          </TextField>

          {
            this.state.timePeriod !== timePeriodOptions[ timePeriodOptions.length - 1 ] ?
              null
            :
              <div className={classes.dateRangePicker}>
                <DateRangePicker
                  disabled={this.state.loading}
                  startDate={this.state.startDate}
                  startDateId="reviews-reporting-start-date"
                  endDate={this.state.endDate}
                  endDateId="reviews-reporting-end-date"
                  onDatesChange={this.onDatesChange}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                  isOutsideRange={d => d < START_DATE || d > END_DATE}
                />
              </div>
          }
        </div>

        <Paper>
          <TableToolbar
            title="Company Distribution"
            caption={`
              Review statistics for your organization's companies.
            `}
            actions={
              <>
                <Tooltip title="Download Data">
                  <IconButton onClick={this.onClickDownload} variant="contained">
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
          />

          <CompanySearchBox placeholder="Search Companies..." onSubmitSearch={this.onSubmitSearch} />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell>Location</TableCell>
                <TableCell align="right">Total Reviews</TableCell>
                <TableCell align="center">Rating Average</TableCell>
                <TableCell align="center">Rating Distribution</TableCell>
                <TableCell align="right">Reviews Replied To</TableCell>
                <TableCell align="right">Reviews Requested</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (
                  !companyDistribution ||
                  !companyDistribution.length ||
                  this.state.loading
                ) ?
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Typography variant="caption">{ this.state.loading ? 'Loading...' : 'No company reviews.' }</Typography>
                    </TableCell>
                  </TableRow>
                :
                  companyDistribution.map((s) => (
                    <TableRow key={s.companyId}>

                      <TableCell component="th" scope="row">
                      <Link component={RouterLink} to={`/companies/${s.companyId}`}>
                          {s.name}
                        </Link>
                      </TableCell>
                      <TableCell>{ s.city }, { s.state }</TableCell>
                      <TableCell align="right">{ format.commas(s.count) }</TableCell>
                      <TableCell>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}>
                            { format.commas(s.ratingAverage, { decimals: 2 }) }
                          </div>
                          <StarsRating average={s.ratingAverage} starSize={14} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Distribution
                          distribution={[ s.r1,s.r2,s.r3,s.r4,s.r5 ]}
                          fills={ [1,2,3,4,5].map(r => ratingColors[r]) }
                          formatTitle={(d,i) => {
                            return (
                              `${d} ${ d === 1 ? 'review' : 'reviews'} with a rating of ${i + 1}.`
                            )
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">{ format.commas(s.isRespondedToSum) }</TableCell>
                      <TableCell align="right">{ s.reviewRequestCount }</TableCell>
                    </TableRow>
                  ))
              }
            </TableBody>
          </Table>

          <SmallTablePagination
              count={this.state.count || 0}
              rowsPerPage={this.state.limit}
              page={this.state.page}
              onChangeRowsPerPage={e => this.onChangeRowsPerPage(e.target.value)}
              onChangePage={(e, p) => this.onChangePage(p)}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
        </Paper>
      </>
    );
  }
}

const styles = {
  table: {
    minWidth: 750,
  },
  textField: {
    // minWidth: 200
  },
  timePeriodDiv: {
    justifyContent: 'right',
    marginBottom: 15,
    display: 'flex'
  },
  dateRangePicker: {
    marginLeft: 15
  }
};

export default withStyles(styles)(
  withContext(UserRoleContext, SnackbarContext, ReviewsReporting)
);


const Distribution = props => {
  const height = props.height || 20;
  const barWidth = props.barWidth || 15;
  const barPadding = props.barPadding || 3;
  const { distribution } = props;


  const max = d3.max(distribution);
  const scale = d3.scaleLinear().domain([0,max]).range([0, height]);

  return (
    <svg height={height} width={distribution.length * (barWidth + barPadding)}>
      {
        props.distribution.map((d,i) => {
          const coloredBarY = (
            max > 0 ?
              height - scale(d)
            :
              height
          );

          const coloredBarHeight = (
            max > 0 ?
              scale(d)
            :
              0
          );


          return(
            <>
              <rect
                x={i * (barWidth + barPadding)}
                y={0}
                width={barWidth}
                height={height}
                fill={'whitesmoke'}
              >
                { props.formatTitle ? <title>{ props.formatTitle(d,i) }</title> : null }
              </rect>

              <rect
                x={i * (barWidth + barPadding)}
                y={coloredBarY}
                width={barWidth}
                height={coloredBarHeight}
                fill={props.fills ? props.fills[i] : 'steelblue'}
              >
                { props.formatTitle ? <title>{ props.formatTitle(d,i) }</title> : null }
              </rect>
            </>
          )
        })
      }
    </svg>
  )

}
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/core/styles/withStyles';
import { FacebookIcon, GoogleIcon } from 'go-boost-library-react';

const boostedServiceIcon = props => {
  const { channels, boostedService, classes } = props;
  const boostedServiceChannel = channels.find(c => c.id === boostedService.channelId);

  let Icon;
  if (boostedServiceChannel.isFacebook()) {
    Icon = FacebookIcon;
  } else if (boostedServiceChannel.isGoogleSearch()) {
    Icon = GoogleIcon;
  }

  return (
    <Avatar className={classes.avatar}>
      {
        Icon ?
          <Icon />
          :
          boostedService.name.slice(0, 1).toUpperCase()
      }
    </Avatar>
  );

}


boostedServiceIcon.propTypes = {
  channels: PropTypes.array.isRequired,
  boostedService: PropTypes.object.isRequired,
};

const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  }
})

export default withStyles(styles)(boostedServiceIcon);
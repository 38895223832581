import React from 'react';

import { withStyles, Typography, TextField, FormControl, Link } from '@material-ui/core';

import paperPadding from 'paperPadding';



class BaseSiteDomainSettings extends React.Component {
  render() {
    const { classes, subdomain } = this.props;

    return (
      <div className={classes.root} >
        <FormControl component="fieldset" className={classes.radioButtons}>
          <Typography
            variant={'body1'}
          >
            Your website url will be <span className={classes.primary}>{ subdomain ? subdomain : 'subdomain' }.{ this.props.domain }</span>.
          </Typography>
        </FormControl>

        <TextField
          fullWidth
          label="Subdomain"
          placeholder="mycompany"
          helperText={`Don't include "http(s)" or "www".`}
          value={subdomain}
          onChange={e => this.props.onChangeSubdomain( (e.target.value || '').trim() )}
          id='base-site-domain-input'
          name='base-site-domain-input'
        />
      </div>
    );
  }
}



const styles = theme => ({
  root: {
    padding: paperPadding
  },
  primary: {
    color: theme.palette.primary.main,
  }
})



BaseSiteDomainSettings.defaultProps = {
  domain: '',
  subdomain: '',
  onChangeSubdomain: () => {},
}



export default withStyles(styles)(BaseSiteDomainSettings);
import React from 'react';

import { log, withContext } from 'kn-react';
import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import history from '../../../../history';




class ConversionTracker extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      adwordsConversionTrackerTemplate: {}
    };
  }

  componentDidMount = () => {
    this.load();
  }


  load = () => {
    log(this.props);
    const id = this.props.match.params.id;
    log('load conversion_trackers', id);
    Axios.get(
      `/api/ads/adwords_templates/conversion_trackers/${ id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.setState({ adwordsConversionTrackerTemplate: response.data.adwords_conversion_tracker_template });
    });
  }


  onToggle = () => {
    if(!window.confirm('Are you sure? Enabling this template will allow it to be deployed when users sign up or update their settings.')) {
      return;
    }

    const id = this.props.match.params.id;
    const { adwordsConversionTrackerTemplate } = this.state;

    Axios.post(
      `/api/ads/adwords_templates/conversion_trackers/${id}/toggle`,
      { is_enabled: !adwordsConversionTrackerTemplate.is_enabled },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.props.showSnackbar('Conversion Tracker Template updated.');
      this.setState({ adwordsConversionTrackerTemplate: response.data.adwords_conversion_tracker_template });
    });
  }


  onDelete = () => {
    if(!window.confirm('Are you sure?')) {
      return;
    }

    const id = this.props.match.params.id;

    Axios.delete(
      `/api/ads/adwords_templates/conversion_trackers/${id}/delete`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.props.showSnackbar('Conversion Tracker Template deleted.');
      history.push('/ads/adwords_templates')
    });
  }


  captionAndTitle = (caption, title) => {
    return (
      <div key={caption} style={{ padding: 15 }}>
        <Typography variant="caption">{ caption }</Typography>
        <Typography variant="h6">
          { title }
        </Typography>
      </div>
    );
  }


  render(){
    const { adwordsConversionTrackerTemplate } = this.state;
    return (
      <div style={{ padding: 15 }}>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={this.onToggle}
            variant="contained"
            style={{ marginRight: 15 }}
            color={ adwordsConversionTrackerTemplate.is_enabled ? 'default' : 'primary' }
          >
            { adwordsConversionTrackerTemplate.is_enabled ? 'Disable' : 'Enable' }
          </Button>
          <Button onClick={this.onDelete} color="secondary">Delete</Button>
        </div>


        <Typography variant="headline">Conversion Tracker Template</Typography>

        {
          Object.keys(adwordsConversionTrackerTemplate).map(k => (
            this.captionAndTitle( k, (adwordsConversionTrackerTemplate[k] || '').toString() ))
          )
        }

      </div>
    );
  }

}

export default withContext(UserRoleContext, SnackbarContext, ConversionTracker);
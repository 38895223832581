import React from 'react';
import { withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import CompanyHome from './CompanyHome/CompanyHome';
import OrganizationHome from './OrganizationHome/OrganizationHome';

const home = props => (
  props.currentUserRole.isCompanyRole() ? <CompanyHome/> : <OrganizationHome/>
)

export default withContext(UserRoleContext, home);
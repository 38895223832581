import { JsonModel, log } from 'kn-react';
import moment from 'moment';
import { OpportunityModel } from 'go-boost-library-react';

export default class OpportunitiesSummaryModel {

	static fromJSON(json) {
		const opportunitiesModel = new OpportunitiesSummaryModel();
		let opportunityModel;
		const opportunities = [];

		json.forEach(j => {
			opportunityModel = new OpportunityModel();
			opportunities.push(JsonModel.modelFromJSON(opportunityModel, j))
		})

		return JsonModel.modelFromJSON(
			opportunitiesModel,
			{ opportunities }
		);
	}

	toJSON() {
		return JsonModel.modelToJSON(this);
	}

	getSummary = (timePeriod) => {
		const percentChange = this.getPercentChange(timePeriod);
		const { calls, formSubmissions } = this.getSummaryData(timePeriod);

		return {percentChange,calls,formSubmissions}
	}

	getPercentChange = (timePeriod) => {
		const lastDateForTimePeriod = this.getLastDateForTimePeriod(timePeriod);
		const firstHalfOpportunities = this.opportunities.filter(o => moment(o.createdAt).isSameOrAfter(lastDateForTimePeriod)).length;
		const secondHalfOpportunities = this.opportunities.filter(o => moment(o.createdAt).isBefore(lastDateForTimePeriod)).length;

		return (firstHalfOpportunities-secondHalfOpportunities)/secondHalfOpportunities
	}

	getSummaryData = (timePeriod) => {
		const lastDateForTimePeriod = this.getLastDateForTimePeriod(timePeriod);
		let calls = 0;
		let formSubmissions = 0;
		const timePeriodUnit = (timePeriod >= 365) ? 'month' : 'day';

		this.opportunities.forEach(o => {
			if (moment(o.createdAt).isSameOrAfter(lastDateForTimePeriod,timePeriodUnit) && o.opportunityType === 'call') {
				calls += 1;
			} else if (moment(o.createdAt).isSameOrAfter(lastDateForTimePeriod,timePeriodUnit) && o.opportunityType === 'form_entry') {
				formSubmissions += 1;
			}
		})

		return { calls, formSubmissions }
	}

	getLastDateForTimePeriod = (timePeriod) => {
		timePeriod = (timePeriod === Infinity) ? 9e4 : timePeriod;
		return moment().subtract(timePeriod,'days');
	}

	// data is assumed to be in descending order
	// timePeriod is time in days
	isFullTimePeriod = (timePeriod, timePeriodUnit, intendedLastDay=null) => {
		const { opportunities } = this;

		if(!opportunities || !opportunities.length) {
			return false;
		}

		const lastDataDate = moment(opportunities[opportunities.length-1].createdAt);
		intendedLastDay = (intendedLastDay) ? intendedLastDay : moment().subtract(2*timePeriod,'days')
		log('intended!!!!',intendedLastDay)


		// Check if the last data date is the intended date. If it's not and it is a weekend,
		// the intended last day may be shifted to the last day that was a Mon-Fri. Account
		// for this behavior.
		log('timePeriodUnit!!!',timePeriodUnit)
		if (lastDataDate.isSame(intendedLastDay,timePeriodUnit.toLowerCase())) {
			return true;
		} else if (intendedLastDay.day() === 0) {
			// Saturday case
			intendedLastDay = intendedLastDay.clone().add(1,'days')
			return this.isFullTimePeriod(timePeriod,timePeriodUnit,intendedLastDay);
		} else if (intendedLastDay.day() === 6) {
			// Sunday case
			intendedLastDay = intendedLastDay.clone().add(2,'days')
			return this.isFullTimePeriod(timePeriod,timePeriodUnit,intendedLastDay);
		}
		return false;
	}

}
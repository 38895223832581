import React from 'react';
import { withContext, MaxWidth } from 'kn-react';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { KnowledgeBaseButton } from 'go-boost-library-react';
import FacebookAccountCard from 'FacebookAccount/FacebookAccountCard';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import paperPadding from 'paperPadding';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';

class FacebookAccountSetup extends React.Component {

  componentDidMount() {
    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'started_facebook_account_setup', this.props.getUserRoleAuthHeaders);
  }

  render() {

    const { transitionTo, transitions } = this.props;

    const ProductUndoResubscriptionAlert = this.props.productUndoResubscriptionAlert;

    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <ProductUndoResubscriptionAlert />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">
              Link Your Accounts

              {
                !this.props.knowledgeBasePath ?
                  null
                :
                  <>
                    &nbsp;

                    <KnowledgeBaseButton
                      path={this.props.knowledgeBasePath}
                      tooltipTitle={this.props.knowledgeBaseTooltipTitle}
                    />
                  </>
              }
            </Typography>
          </Grid>

          <CancelSetupButton
            to={this.props.cancelTo}
          />

          <Grid item xs={12}>
						<Paper style={{ padding: paperPadding }}>
							<Typography variant="subtitle1" color="primary">Link Your Facebook Account</Typography>
              <Typography style={{paddingBottom: 24}} variant="body2">{ this.props.linkAccountText }</Typography>

							<FacebookAccountCard
                shouldEnqueueReviewsImporter={this.props.shouldEnqueueReviewsImporter}
                shouldConnectBusinessManager={this.props.shouldConnectBusinessManager}
                canSignOut={this.props.canSignOut}
                canSignIn={this.props.canSignIn}
                isProductShutdown={this.props.isProductShutdown}
              />
						</Paper>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Button color="primary" variant="outlined" onClick={() => transitionTo('back', transitions)}>Back</Button>
            </Grid>

            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button style={{ marginRight: 15 }} onClick={() => transitionTo('next', this.props.transitions, this.props)} id='skip-button'>Skip</Button>

							<Button variant="contained" color="primary" onClick={() => transitionTo('next', this.props.transitions, this.props)}>Next</Button>
						</Grid>
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }
}



FacebookAccountSetup.defaultProps = {
  shouldEnqueueReviewsImporter: false,
  shouldConnectBusinessManager: false,
  linkAccountText: 'Sign-in to Facebook to give GoBoost access to your Facebook Page.',
  canSignOut: true,
  canSignIn: true,
  isProductShutdown: false,
  cancelTo: '/',
  productUndoResubscriptionAlert: () => null,
  knowledgeBasePath: '',
  knowledgeBaseTooltipTitle: '',
}



export default withContext(
  TransitionContext,
  FacebookAccountSetup
);

import React from 'react';

import { withContext } from 'kn-react';

import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import ViewFeedItem from 'Social/FeedItem/ViewFeedItem';


const viewFeedItem = props => {
  const hasFacebookAccountError = (
    props.facebookAccountIsSignedIn() && (
      props.facebookAccountPage.isError ||
      props.facebookAccountPage.errorResponse
    )
  );

  return (
    <ViewFeedItem
      {...props}
      hasFacebookAccountError={hasFacebookAccountError}
    />
  );
}


export default withContext(
  FacebookAccountContext,
  viewFeedItem
);
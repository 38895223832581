import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withContext, log, MaxWidth } from 'kn-react';
import { Alert, PromiseButton } from 'go-boost-library-react';
import { Typography, Grid, withStyles, Toolbar, Tooltip, IconButton } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import paperPadding from 'paperPadding';
import AdCarousel from 'Ads/CompanyAds/BoostedServices/BoostedServicesList/AdCarousel/AdCarousel';
import { CalendarToday } from '@material-ui/icons';

class FeedList extends React.Component {

  render() {
    const { classes } = this.props;
    const { availableFeeds } = this.props;

    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>

          <Grid item xs={12}>
            {
              availableFeeds.length ?

                availableFeeds.map(f => {
                  let carousel;
                  if (f.assetData && f.assetData.carousel) {
                    carousel = f.assetData.carousel;
                  }

                  return (
                    <Grid item xs={12} key={f.id} style={{ paddingBottom: 15 }}>
                      <Card>

                        <CardHeader
                          className={classes.cardHeader}
                          disableTypography
                          title={
                            <Typography variant="h6">{f.name}</Typography>
                          }
                          subheader=""
                          avatar={null}
                          action={
                            this.props.canEditFeed && this.props.selectedFeedIds.includes(f.id) ?
                              <Toolbar disableGutters>
                                <Tooltip title="Edit Feed Timeline" enterDelay={500}>
                                  <IconButton
                                    component={RouterLink}
                                    to={`/social/feeds/${f.id}/timeline`}
                                  >
                                    <CalendarToday />
                                  </IconButton>
                                </Tooltip>
                              </Toolbar>
                            :
                              null
                          }
                        />

                        {
                          carousel ?
                            <AdCarousel data={carousel} itemWidth={265} />
                            : null
                        }


                        {
                          this.props.selectedFeedIds.includes(f.id) || !this.props.canLaunchFeed ?
                            null :
                            <CardContent>

                              <PromiseButton
                                onProcess={() => this.props.onClickSetup(f)}
                                buttonProps={{
                                  variant: 'contained',
                                  color: 'primary',
                                  disabled: !this.props.canEdit,
                                  fullWidth: true,
                                }}
                              >
                                {this.props.setupText}
                              </PromiseButton>
                            </CardContent>
                        }
                      </Card>
                    </Grid>

                  );

                })
                :
                <Alert warning>{this.props.warningText}</Alert>
            }

          </Grid>
        </Grid>
      </MaxWidth>
    );
  }

}


FeedList.defaultProps = {
  availableFeeds: [],
  selectedFeedIds: [],
  getPreviewFeedLink: () => {},
  canEdit: true,
  onClickSetup: () => {},
  warningText: '',
  setupText: '',
  canLaunchFeed: true,
  canEditFeed: true
};


const styles = theme => ({
  paper: {
    padding: paperPadding
  },
  cardHeader: {
    borderBottom: '1px solid #efefef',
  },
});


export default withStyles(styles)(FeedList)
import { JsonModel } from 'kn-react';

const GOOGLE_SEARCH_CHANNEL_NAME = 'Google Search';
const FACEBOOK_CHANNEL_NAME = 'Facebook';
const CRAIGSLIST_CHANNEL_NAME = 'Craigslist';
const WEBSITE_CHANNEL_NAME = 'Website';
const VIRTUAL_WORK_REQUEST_CHANNEL_NAME = 'Virtual Work Request';

export default class ChannelModel {

  static fromJSON(json){
    const model = new ChannelModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

  isGoogleSearch(){
    return this.name === GOOGLE_SEARCH_CHANNEL_NAME;
  }

  isFacebook(){
    return this.name === FACEBOOK_CHANNEL_NAME;
  }

  isCraigslist(){
    return this.name === CRAIGSLIST_CHANNEL_NAME;
  }

  isWebsite(){
    return this.name === WEBSITE_CHANNEL_NAME;
  }

  isVirtualWorkRequest(){
    return this.name === VIRTUAL_WORK_REQUEST_CHANNEL_NAME;
  }

}

import React from 'react';

import { log, withContext, format } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import { Add } from '@material-ui/icons'
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, withStyles, IconButton, Typography, Icon } from '@material-ui/core';

import CreateFacebookReport from './CreateFacebookReport';
import FacebookReportModel from 'Ads/FacebookReportModel';


class FacebookReports extends React.Component {
  state = {
      loading: true,
      facebookReports: [],
      createFacebookReportOpen: false,
  }


  componentDidMount = () => {
    this.loadFacebookReports();
  }


  loadFacebookReports = () => {
    return Axios.get(
      '/api/ads/facebook_reports',
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('loadFacebookReports response', response);
      const facebookReports = response.data.facebook_reports.map(r => (
        FacebookReportModel.fromJSON(r)
      ));

      this.setState({ facebookReports, loading: false });
    });
  }


  render(){
    const { classes } = this.props;
    const { facebookReports } = this.state;

    return (
      <>
      <Typography variant="h5">Facebook Reports</Typography>
      <IconButton onClick={e => (
        this.setState({ createFacebookReportOpen: true })
      )}>
        <Add/>
      </IconButton>

      <CreateFacebookReport
        open={this.state.createFacebookReportOpen}
        onSuccess={ () => this.loadFacebookReports() }
        onClose={ () => this.setState({ createFacebookReportOpen: false }) }
      />
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Campaign Name</TableCell>
                <TableCell>Reported On</TableCell>
                <TableCell align="right">Impression Device</TableCell>
                <TableCell align="right">Reach</TableCell>
                <TableCell align="right">Impressions</TableCell>
                <TableCell align="right">Result Type</TableCell>
                <TableCell >Results</TableCell>
                <TableCell align="right">Amount Spent Cents</TableCell>
                <TableCell align="right">Clicks All</TableCell>
                <TableCell align="right">Created At</TableCell>
                <TableCell align="right">Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facebookReports.map(r => (
                <TableRow key={r.id}>
                  <TableCell>{r.id}</TableCell>
                  <TableCell>{r.campaignName}</TableCell>
                  <TableCell>{format.date(r.reportedOn)}</TableCell>
                  <TableCell align="right">{r.impressionDevice}</TableCell>
                  <TableCell align="right">{r.reach}</TableCell>
                  <TableCell align="right">{r.impressions}</TableCell>
                  <TableCell align="right">{r.resultType}</TableCell>
                  <TableCell >{r.results}</TableCell>
                  <TableCell align="right">{r.amountSpentCents}</TableCell>
                  <TableCell align="right">{r.clicksAll}</TableCell>
                  <TableCell align="right">{format.date(r.createdAt)}</TableCell>
                  <TableCell align="right">{format.date(r.updatedAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
      </>
    );
  }

}

const styles = theme => ({
  tableWrapper: {
    overflowX: 'auto'
  },
	table: {
    minWidth: 1200,
  },
});

export default withStyles(styles)(withContext(UserRoleContext, FacebookReports));
import React from 'react';
import { withContext, log } from 'kn-react';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import SitesUndoResubscription from 'Sites/CompanySites/SitesSetup/SitesUndoResubscription/SitesUndoResubscription';
import Survey from 'SharedSetup/Survey/Survey';
import { getSiteLink } from 'Sites/siteDesignPreviewHelpers'

const surveySetup = props => {
  const { id } = props.match.params;
  const { siteDesigns } = props;
  const siteDesign = siteDesigns.find(d => d.id == id);
  log('surveySetup siteDesign', siteDesign);

  const { currentUserRole, getUserRoleAuthHeaders } = props;
  trackCurrentUserSegmentEvent(currentUserRole.userId, 'finished_sites_design_step', getUserRoleAuthHeaders);

  trackCurrentUserSegmentEvent(
    currentUserRole.userId,
    'started_sites_survey',
    getUserRoleAuthHeaders,
    { funnel: true }
  );

  const companySitesProfile = props.companySitesProfile || {};

  const previewUrl = getSiteLink( siteDesign.baseSiteId );

  return (
    <Survey
      {...props}
      surveyId={siteDesign.surveyId}
      surveyTitle={`${siteDesign.name} Setup`}
      onSubmit={() => props.transitionTo('next', props.transitions, { siteDesignId: siteDesign.id })}
      onBack={() => props.transitionTo('back', props.transitions, { siteDesignId: siteDesign.id })}
      canSubmitSurvey={companySitesProfile.isShutdown ? !companySitesProfile.isSetupComplete : true}
      productUndoResubscription={SitesUndoResubscription}
      previewUrl={previewUrl}
    />
  );

};

export default withContext(TransitionContext, surveySetup);

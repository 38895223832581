import React from 'react';
import Axios from 'axios';

import { BusinessHours, SnackbarContext, ThemeContext, PromiseButton, UserRoleContext, PlanContext, Ability, CompanyRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';
import { withContext, log, format } from 'kn-react';

import {
  Grid, Typography, Paper, withStyles, Button, Tooltip, List, ListItem,
  ListItemText, ListItemSecondaryAction, IconButton, FormControl, FormLabel,
  FormControlLabel, Switch, Link
} from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import { CompanySitesProfileContext } from '../CompanySitesProfile/CompanySitesProfileProvider';
import { fetchCurrentSite } from 'Sites/CompanySites/UnpublishedCurrentSiteAlert/UnpublishedCurrentSiteAlert'
import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';
import { SiteDesignsContext } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import ImageUpload from 'ImageUpload/ImageUpload';
import ReceivingNumberModel from './ReceivingNumberModel';
import SearchConsoleTag from '../SearchConsoleTag/SearchConsoleTag';
import sitePreviewUrl from 'Sites/sitePreviewUrl';
import TrackingNumberModel from './TrackingNumberModel';


class SiteSettings extends React.Component {

  state = {
    businessHours: this.props.companySitesProfile.businessHours,
    logoImageFile: null,
    site: null,
    trackingNumbers: [],
    receivingNumbers: []
  }


  componentDidMount = () => {
    return fetchCurrentSite(
      this.props.companySitesProfile.companySiteDesignId,
      this.props.companySiteDesigns
    ).then(site => this.setState({ site }))
    .then(this.fetchSitesTrackingNumbers);
  }


  onUpdateBusinessHours = () => {
    const { businessHours } = this.state;

    return this.props.updateCompany({ businessHours })
      .then(() => this.props.updateCompanySitesProfile({ businessHours }))
      .then(() => this.props.showSnackbar('Business Hours successfully updated.'));
  }



  fetchSitesTrackingNumbers = () => {
    return Axios.get(
      `/api/core/tracking_numbers/companies/${ this.props.company.id }/sites`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const trackingNumbers = response.data.tracking_numbers.map(
        t => TrackingNumberModel.fromJSON( t )
      );

      const receivingNumbers = response.data.receiving_numbers.map(
        r => ReceivingNumberModel.fromJSON( r )
      );

      return this.setState({
        trackingNumbers,
        receivingNumbers
      })
    })
  }



  onUpdateLogo = () => {
    return this.uploadLogoImage()
      .then(mediaItem => {
        return this.props.updateCompanySitesProfile({
          logo_url: mediaItem ? mediaItem.url : undefined
        });
      })
      .then(() => this.props.showSnackbar('Logo successfully updated.'));
  }


  uploadLogoImage = () => {
    const { logoImageFile } = this.state;
    return logoImageFile ? this.props.createMediaItem(logoImageFile) : Promise.resolve(null);
  }


  onChangeLogoImage = logoImageFile => {
    log('onChangeLogoImage', logoImageFile);
    this.setState({ logoImageFile });
  }


  onValidationError = e => {
    this.props.showSnackbar(e.message);
  }


  onToggleCallTracking = () => {
    return this.props.updateCompanySitesProfile({
      callTrackingDisabled: !this.props.companySitesProfile.callTrackingDisabled
    }).then(
      () => this.props.showSnackbar('Site Call Tracking successfully updated.')
    );
  }



  render() {
    const { classes, theme } = this.props;


    const isByoDomain = this.props.companySitesProfile.byoDomain ? true : false;
    const domain = this.props.companySitesProfile.byoDomain || this.props.companySitesProfile.newDomain || '';
    const isBaseSite = domain.trim().split('.').length >= 3;

    const canEdit = this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_SITES_PROFILE );

    const callTrackingAvailable = this.props.subscribedSitesPlan && this.props.subscribedSitesPlan.isTrackingNumberIncluded;

    const isShutdown = this.props.companySitesProfile && this.props.companySitesProfile.isShutdown;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">Site Settings</Typography>
        </Grid>


        <Grid item xs={6}>
          <Paper className={classes.topPaper}>
            <Typography variant="h6">
              Site Domain

              &nbsp;

              <KnowledgeBaseButton
                path={'/domains'}
                tooltipTitle={'Learn more about Domains'}
              />
            </Typography>
            <List>
              {
                !this.state.site || this.state.site.publishedAt ?
                  null
                :
                  <ListItem>
                    <ListItemText
                      primary={sitePreviewUrl( this.state.site )}
                      primaryTypographyProps={{
                        color: 'primary',
                        variant: 'h6'
                      }}
                      secondary={`Preview site`}
                    />

                    <ListItemSecondaryAction>
                      <Tooltip title="Visit your domain">
                        <IconButton component="a" target="_blank" href={sitePreviewUrl( this.state.site )}>
                          <OpenInNew/>
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
              }

              <ListItem>
                <ListItemText
                  primary={domain}
                  primaryTypographyProps={{
                    color: 'primary',
                    variant: 'h6'
                  }}
                  secondary={
                    isBaseSite ?
                      null
                    :
                      isByoDomain ?
                        `Company-managed domain`
                      :
                        `${theme.appTitle}-managed domain`
                  }
                />

                <ListItemSecondaryAction>
                  <Tooltip title="Visit your domain">
                    <IconButton component="a" target="_blank" href={`http://${domain}`}>
                      <OpenInNew/>
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {
          callTrackingAvailable ?
            <Grid item xs={6}>
              <Paper className={classes.topPaper}>
                <Typography variant="h6">
                  Call Tracking

                  &nbsp;

                  <KnowledgeBaseButton
                    path={'/what-is-call-tracking'}
                    tooltipTitle={'Learn more about Call Tracking'}
                  />
                </Typography>

                <FormControl component="fieldset" className={classes.callTracking}>
                  <FormLabel component="legend" style={{ paddingTop: 25 }}>Should call tracking be enabled for your site?</FormLabel>

                  <FormControlLabel
                    control={
                      <Switch
                        color='primary'
                        checked={!this.props.companySitesProfile.callTrackingDisabled}
                        onChange={this.onToggleCallTracking}
                      />
                    }
                    label={`Call Tracking ${ this.props.companySitesProfile.callTrackingDisabled ? 'Disabled' : 'Enabled'}`}
                  />

                  <List>
                    {
                      this.state.trackingNumbers.map(
                        (t, i) => {
                          const receivingNumber = this.state.receivingNumbers.find(
                            r => r.trackingNumberId === t.id
                          );

                          return (
                            <ListItem key={i}>
                              <ListItemText
                                primary={
                                  t.ctmTrackingNumber.length === 12 ?
                                    format.phone(
                                      t.ctmTrackingNumber.startsWith('+1') ?
                                        t.ctmTrackingNumber.slice(2)
                                      :
                                        t.ctmTrackingNumber
                                    )
                                  :
                                    t.ctmTrackingNumber.length === 10 ?
                                      format.phone( t.ctmTrackingNumber )
                                    :
                                      t.ctmTrackingNumber
                                }
                                secondary={
                                  receivingNumber ?
                                    `Forwards to ${ receivingNumber.phoneNumber.length === 10 ?
                                      format.phone( receivingNumber.phoneNumber )
                                    :
                                      receivingNumber.phoneNumber }`
                                  :
                                    null
                                }
                              />
                            </ListItem>
                          );
                      }
                      )
                    }
                  </List>

                  {
                    !this.state.trackingNumbers.length ?
                      null
                    :
                      <Tooltip
                        title="Contact Support for help."
                        style={{ cursor: 'pointer', marginBottom: 15 }}
                      >
                        <Link to="#">
                          Update Tracking Number{ this.state.trackingNumbers.length > 1 ? 's' : null }
                        </Link>
                      </Tooltip>
                  }
                </FormControl>

              </Paper>
            </Grid>
          :
            null
        }

        <Grid item xs={callTrackingAvailable ? 12 : 6} id='logo'>
          <Paper className={classes.paper}>
            <Typography variant="h6">Company Logo</Typography>

            <div style={{ textAlign: 'center' }}>
              <ImageUpload
                existingImageUrl={this.props.companySitesProfile.logoUrl}
                onChangeImage={this.onChangeLogoImage}
              />
              <Typography variant="caption">Supported Image Formats: JPG, PNG, GIF</Typography>
            </div>

            {
               canEdit && !isShutdown ?
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <PromiseButton
                      onProcess={this.onUpdateLogo}
                      buttonProps= {{
                        variant: 'contained',
                        color: 'primary',
                        type: 'submit',
                        id: 'update-logo',
                        name: 'update-logo',
                      }}
                    >
                      Update
                    </PromiseButton>
                  </Grid>
                :
                  null
            }

          </Paper>
        </Grid>


        <Grid item xs={12} id='search-console-tag'>
          <SearchConsoleTag
            tag={this.props.companySitesProfile.searchConsoleTag}
            onEditTag={searchConsoleTag => this.props.updateCompanySitesProfile({ search_console_tag: searchConsoleTag })}
            canEdit={this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_SITES_PROFILE )}
          />
        </Grid>


        {/* <Grid item xs={12}>
          <Paper className={classes.paper} id='business-hours'>
            <Typography variant="h6">Business Hours</Typography>

            <BusinessHours
              businessHours={this.props.company.businessHours || {}}
              onChange={businessHours => this.setState({ businessHours })}
            />

            {
              canEdit ?
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onUpdateBusinessHours}
                      id='update-business-hours'
                      name='update-business-hours'
                    >
                      Update
                    </Button>
                  </Grid>
                :
                  null
            }

          </Paper>
        </Grid> */}

      </Grid>
    );
  }

}

const styles = theme => ({
  paper: {
    padding: 15,
    maxWidth: '100%',
  },
  topPaper: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    maxWidth: '100%',
    minHeight: 125
  },
  button: {
    marginTop: 16,
    float: 'right'
  },
  callTracking: {
    paddingLeft: 15,
    paddingRight: 15,
  }
})

export default withContext(
  ThemeContext,
  CompanyRoleContext,
  SnackbarContext,
  CompanySitesProfileContext,
  MediaItemsContext,
  PlanContext,
  SiteDesignsContext,
  UserRoleContext,
  withStyles(styles)(SiteSettings)
);
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';


const cancelSetupButton = props => (
  <Grid
    item
    xs={6}
    style={{ textAlign: 'right' }}
  >
    <Tooltip title='Cancel Setup'>
      <IconButton
        component={RouterLink}
        to={props.to}
      >
        <Clear />
      </IconButton>
    </Tooltip>
  </Grid>
)


cancelSetupButton.defaultProps = {
  to: '/'
}


export default cancelSetupButton
import React from 'react';

import { BusinessHours, PromiseButton, DEFAULT_BUSINESS_HOURS, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, MaxWidth, AutoError } from 'kn-react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { TransitionContext } from 'TransitionProvider/TransitionProvider';


class BusinessHoursSetup extends React.Component {

  state = {
    businessHours: {
      ...DEFAULT_BUSINESS_HOURS,
      ...(this.props.company.businessHours || {})
    }
  };

  onChange = businessHours => {
    this.setState({ businessHours });
  }


  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions);
  }


  onClickNext = () => {
    const { businessHours } = this.state;

    return this.props.updateCompany({ businessHours })
      .then(() => this.props.updateCompanySitesProfile({ businessHours }))
      .then(() => this.props.transitionTo('next', this.props.transitions))
      .catch(AutoError.catch.bind(this));
  }

  render() {
    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h5">Set your Business Hours</Typography>
            <Typography variant="body2" paragraph>These will be shown on your website.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <BusinessHours
                businessHours={this.props.company.businessHours || {}}
                onChange={this.onChange}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.onClickBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <PromiseButton
              onProcess={this.onClickNext}
              buttonProps={{
                variant: 'contained',
                color: 'primary'
              }}
            >
              Next
            </PromiseButton>
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }

}

export default withContext(
  TransitionContext,
  CompanyRoleContext,
  BusinessHoursSetup
);
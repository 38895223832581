import React from 'react';
import Axios from 'axios';

import { Link } from 'react-router-dom';
import { withContext, MaxWidth } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import { Grid, List, ListItem, ListItemText, Typography, Button, TextField, MenuItem, LinearProgress, withStyles, ListItemIcon } from '@material-ui/core';
import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';
import { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';


const MEDIA_ITEM_TYPES = {
  'all': 'All',
  'image/': 'Image',
  'video/': 'Video',
}

class MediaItemsIndex extends React.Component {
  state = {
    type: 'all',
    mediaItems: [],
  }

  componentDidMount = () => {
    return this.props.fetchMediaItems()
  }


  render() {
    const isShutdown = this.props.companySocialProfile && this.props.companySocialProfile.isShutdown;

    return (
      <MaxWidth maxWidth={1200} style={{ padding: 8 }}>
        <Grid container spacing={8} style={{ overflowY: 'auto' }}>

          <Grid item xs={12}>
            <Typography variant="body2">Images, videos, documents, and audio files for your website.</Typography>
          </Grid>

          <Grid item xs={12} sm={10}>

            <Button
              size="small"
              color="primary"
              variant="outlined"
              to={`/social/media_items/new`}
              component={Link}
              disabled={isShutdown}
            >
              Add Media Item
            </Button>
          </Grid>

          <Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
            <TextField
              select
              name='type-filter'
              id='type-filter'
              value={this.state.type}
              onChange={e => this.setState({ type: e.target.value })}
              variant="outlined"
              style={{ textAlign: 'left' }}
              fullWidth
              margin="dense"
            >
              {
                Object.keys(MEDIA_ITEM_TYPES).map(
                  (m, i) => (
                    <MenuItem value={m} key={i}>
                      {MEDIA_ITEM_TYPES[m]}
                    </MenuItem>
                  )
                )
              }
            </TextField>
          </Grid>

          <Grid item xs={12}>

            <Typography variant="caption" className={this.props.classes.blockDotLegend}>
              &#9679; Inherited Media Item
              &nbsp;&nbsp;
              &#9675; Owned Media Item
            </Typography>

            {
              this.props.mediaItems.length ?
                <List>
                  {
                    this.props.mediaItems.sort(
                      (a, b) => a.name.localeCompare(b.name)
                    ).filter(
                      m => !m.isDeleted
                    ).map(
                      m => (
                        this.state.type === 'all' || m.mimeType.includes(this.state.type) ?
                          <ListItem key={m.id} component={Link} to={`/social/media_items/${m.id}/edit`} button>
                            <ListItemIcon style={{ marginRight: 0 }}>
                              {
                                this.props.currentUserRole.roleType === m.roleType && this.props.currentUserRole.roleType === m.roleType ?
                                  <span title="Owned Media Item" className={this.props.classes.ownedItem}>&#9675;</span>
                                :
                                  <span title="Inherited Media Item" className={this.props.classes.ownedItem}>&#9679;</span>
                              }
                            </ListItemIcon>

                            <ListItemText primary={m.name} />
                          </ListItem>
                        :
                          null
                      )
                    )
                  }
                </List>

              :
                <div style={{ padding: 10, textAlign: 'center' }}>
                  <Typography variant="caption">You don't have any Media Items.</Typography>
                </div>
            }
          </Grid>
        </Grid>

      </MaxWidth>
    )
  }
}

MediaItemsIndex.defaultProps = {
  mediaItems: []
}


const styles = theme => ({
  blockDotLegend: {
    color: '#999',
    textAlign: 'right',
  },
  ownedItem: {
    color: '#999 !important',
    fontSize: 10,
  },
})

export default withContext(
  CompanySocialProfileContext,
  UserRoleContext,
  MediaItemsContext,
  withStyles(styles)(MediaItemsIndex)
);



// font-size: 0.8125rem;
// padding: 4px 8px;
import { JsonModel } from 'kn-react';
import shortid from 'shortid';


export default class SignUpCodeModel {

  static fromJSON(json){
    const model = new SignUpCodeModel();
    return JsonModel.modelFromJSON(model, json);
  }

  static generateCode() {
    return SignUpCodeModel.cleanCode(shortid.generate());
  }

  static cleanCode(code) {
    return code.toUpperCase().replace(/\s+/g, '-');
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

}

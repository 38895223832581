import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Alert, TableToolbar, SmallTablePagination, OpportunityModel } from 'go-boost-library-react';
import { format, gradeOpportunity, opportunityConstants } from 'kn-react';

import { Typography, withStyles, Table, TableHead, TableRow, TableCell, TableBody, LinearProgress } from '@material-ui/core';

import history from 'history.js'

const { ADDRESS_KEY } = opportunityConstants;

const GRADE_NOT_AVAILABLE = 'N/A';


class JobsTable extends React.Component {
  render() {
    const { classes, total, jobs, isLoading } = this.props;

    return (
      <>
        <TableToolbar
          title={`${ total } Incomplete Jobs`}
        />

        {
          isLoading ?
            <LinearProgress />
          :
            null
        }

        {
          (
            !isLoading &&
            ( !jobs || !jobs.length )
          ) ?
            <Typography variant="caption" className={classes.noData}>No { this.props.opportunityName }.</Typography>
          :
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Customer's Name
                  </TableCell>

                  {
                    !this.props.showAddress ?
                      null
                    :
                      <TableCell>
                        Customer's Address
                      </TableCell>
                  }

                  {
                    !this.props.showGrade ?
                      null
                    :
                      <TableCell>
                        Grade
                      </TableCell>
                  }

                  <TableCell>
                    Conversion Status
                  </TableCell>

                  <TableCell>
                    Date &amp; Time Submitted
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  jobs.map((o, i) => {
                    const responseLink = `${ this.props.viewJobBaseUrl }/${ o.id }`;


                    let address;
                    if( o && o.additionalData ){
                      const addressQuestion = o.additionalData.find(
                        d => d.key === ADDRESS_KEY
                      );

                      if( addressQuestion && addressQuestion.value ){
                        address = addressQuestion.value.fullAddress;
                      }
                    }


                    let companyVirtualFormDesign = null;
                    if( this.props.companyVirtualFormDesigns ){
                      companyVirtualFormDesign = this.props.companyVirtualFormDesigns.find(
                        c => c.id === o.opportunitySourceId
                      );
                    }


                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={i}
                        onClick={() => history.push( responseLink )}
                        to={responseLink}
                        component={RouterLink}
                        style={{
                          opacity: this.props.isLoading ? 0.5 : 1
                        }}
                      >
                        <TableCell>
                          <RouterLink to={responseLink}>
                            { o.name }
                          </RouterLink>
                        </TableCell>

                        {
                          !this.props.showAddress ?
                            null
                          :
                            <TableCell>
                              { address }
                            </TableCell>
                        }

                        {
                          !this.props.showGrade ?
                            null
                          :
                            <TableCell>
                              {
                                !companyVirtualFormDesign ?
                                  GRADE_NOT_AVAILABLE
                                :
                                  gradeOpportunity(
                                    o,
                                    companyVirtualFormDesign.steps
                                  ) || GRADE_NOT_AVAILABLE
                              }
                            </TableCell>
                        }

                        <TableCell>
                          {
                            o.conversionStatus === OpportunityModel.CONTACTED_CONVERSION_STATUS ?
                              'Contacted'
                            :
                              <Alert danger className={classes.alert}>
                                Not Contacted
                              </Alert>
                          }
                        </TableCell>

                        <TableCell>
                          { format.dateTime( o.createdAt ) }
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
        }

        <SmallTablePagination
          count={total || 0}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.page}
          onChangeRowsPerPage={this.props.onChangeRowsPerPage}
          onChangePage={this.props.onChangePage}
          rowsPerPageOptions={this.props.rowsPerPageOptions}
        />
      </>
    );
  }
}


JobsTable.defaultProps = {
  total: 0,
  page: 0,
  jobs: [],
  isLoading: false,
  rowsPerPage: 5,
  onChangeRowsPerPage: () => {},
  rowsPerPageOptions: [5, 10, 25, 50],
  showAddress: false,
  showGrade: false,
  viewJobBaseUrl: '',
  companyVirtualFormDesigns: [],
  opportunityName: ''
}


const styles = theme => ({
  paper: {
    padding: 16,
  },
  tableRow: {
    textDecoration: 'none',
  },
  noData: {
		textAlign: 'center',
		padding: 25,
		color: '#777',
	},
  alert: {
    width: 'fit-content'
  }
});



export default withStyles(styles)(
  JobsTable
);
import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import * as d3 from 'd3';
import { enumerateTimeBetweenMoments } from 'kn-react';

import { FormControl, InputLabel, Input, MenuItem, Select } from '@material-ui/core';


export const compareToPrior = (current, prior, thing, priorPeriodName, formatter = d => d) => {
  if (!prior || isNaN(prior)) {
    return `No ${ thing } in ${ priorPeriodName }`;
  }

  if (!current || isNaN(current)) {
    return `No ${ thing }`;
  }
  const change = current - prior;
  return `${ change === 0 ? 'No change' : formatter(change) } vs. ${ priorPeriodName }`;
}


export const metricChange = change => {
  return (
    <span style={{textTransform: 'none', fontSize: 12, color: '#777'}}>
      { change }
    </span>
  )
}



export const getPriorTimePeriod = (m, numberOfUnits, unit) => {
  const priorStartDate = m.clone().subtract(numberOfUnits, unit).format('YYYY-MM-DD');
  const priorEndDate = m.clone().subtract(1, 'day').format('YYYY-MM-DD');
  return [priorStartDate, priorEndDate];
}




export const decimalChangeFormat = d3.format('+0.2f');
export const absoluteChangeFormat = d3.format('+,');
export const percentChangeFormat = d3.format('+%');


export const fillMissingMoments = (data, startMoment, endMoment, unit, dateAccessor = (d => d), generator = ((m, e = {}) => ({ moment: m, ...e }))) => {


  const formatByUnit = {
    day: 'YYYY-MMMM-DD 00:00:00',
    month: 'YYYY-MMMM-01 00:00:00',
  };


  let dataByMoment = {};
  data.forEach(d => {
    const m = moment(dateAccessor(d));
    const key = m.format(formatByUnit[unit]);
    dataByMoment[key] = generator(m, d);
  });


  let enumeratedMoments = enumerateTimeBetweenMoments(startMoment, endMoment, unit, unit);

  const filledData = enumeratedMoments.map(m => {
    const key = m.format(formatByUnit[unit]);
    const d = dataByMoment[key];
    return d ? d : generator(m, d);
  });


  return filledData;
}


export const getTimePeriodMoment = timePeriod => {
  const { numberOfUnits, unit } = timePeriod;
  return numberOfUnits ? moment().subtract(numberOfUnits, unit) : undefined;
}


export const getStartDate = timePeriodMoment => {
  return timePeriodMoment ? timePeriodMoment.format('YYYY-MM-DD') : undefined;
}


export const dashboardDataRequest = (controllerPath, subPath, props, extraParams = {}) => {
  const { companyIds } = extraParams;

  return Axios.get(
    `/api${ controllerPath }/${props.roleTypePath}/${props.roleTypeId}${subPath}`,
    {
      headers: props.getUserRoleAuthHeaders(),
      params: {
        ...extraParams,
        include_descendants: (props.roleTypePath === 'organizations' && (!companyIds || !companyIds.length)) ? true : undefined
      }
    }
  )
}


export const chartFormats = {
  day: 'MMMM D',
  month: 'MMM YYYY',
};



class FilterDropdown extends React.Component {

  renderDropdownItem = items => (
    items.map(i => (
      <MenuItem
        value={ (this.props.getOptionValue) ? this.props.getOptionValue(i) : null }
        key={ (this.props.getOptionKey) ? this.props.getOptionKey(i) : null }
      >
        { (this.props.getOptionLabel) ? this.props.getOptionLabel(i) : null }
      </MenuItem>
    ))
  )

  onChangeFilter = selectedValues => {
    let values;
    const selectedIncludesAllFilter = selectedValues.map(v => v.id).includes('all');
    if (selectedIncludesAllFilter && selectedValues.length === 2) {
      values = selectedValues.filter(v => v.id !== 'all');
    } else if (selectedIncludesAllFilter) {
      values = [ selectedValues.find(v => v.id === 'all') ];
    } else {
      values = [...selectedValues];
    }

    this.props.onChangeFilter && this.props.onChangeFilter(values);
  }

  render(){
    return(
      <FormControl fullWidth>
        <InputLabel htmlFor={`filter-dropdown-${this.props.dropdownIdentifier}`}>
          { this.props.label || '' }
        </InputLabel>

        <Select
          multiple
          value={ this.props.selectedValues || [] }
          onChange={e => this.onChangeFilter(e.target.value)}
          input={<Input id={`filter-dropdown-${this.props.dropdownIdentifier}`}/>}
          renderValue={values => values.map(v => (this.props.getOptionLabel ? this.props.getOptionLabel(v) : null)).join(', ') }
        >
          {
            this.renderDropdownItem(this.props.options)
          }
        </Select>
      </FormControl>
    )
  }
}

FilterDropdown.defaultProps = {
  dropdownIdentifier: Math.random(),
  label: '',
  selectedValues: [],
  onChangeFilter: e => {},
  options: [],
  getOptionValue: f => f,
  getOptionKey: f => f.id,
  getOptionLabel: f => f.name
}

export { FilterDropdown }
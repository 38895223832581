import { Link as RouterLink } from "react-router-dom";
import React from "react";

import { log, MaxWidth, withContext, AutoError } from "kn-react";
import { PromiseButton, SnackbarContext, PlanContext, CompanyRoleContext } from "go-boost-library-react";

import { Button, Grid, LinearProgress, Link, Paper, Typography, withStyles } from "@material-ui/core";

import { BoostedServicesContext } from "Ads/CompanyAds/BoostedServices/BoostedServicesProvider";
import { trackCurrentUserSegmentEvent } from "SharedSetup/setupHelpers";
import { TransitionContext } from "TransitionProvider/TransitionProvider";
import AdsUndoResubscriptionAlert from 'Ads/CompanyAds/AdsUndoResubscriptionAlert/AdsUndoResubscriptionAlert';
import CancelSetupButton from "SharedSetup/CancelSetupButton/CancelSetupButton";
import CouponCheckoutRow from "SharedSetup/CouponCode/CouponCheckoutRow";
import CouponCode from "SharedSetup/CouponCode/CouponCode";
import paperPadding from "paperPadding";
import PaymentMethodForm from "Billing/PaymentMethod/PaymentMethodForm/PaymentMethodForm";
import TodaysSummary from "./TodaysSummary/TodaysSummary";

class CheckoutSetup extends React.Component {
  state = {
    loading: true,
    processing: false,
    selectedPlan: {},
    coupon: null,
  };

  componentDidMount = () => {
    const {
      selectedPlanId,
      upgradableAdsPlans,
      companyBoostedServices,
    } = this.props;
    log("upgradableAdsPlans", upgradableAdsPlans);
    log("selectedPlanId", selectedPlanId);

    const selectedPlan = upgradableAdsPlans.find((p) => p.id == selectedPlanId);
    log("selectedPlan", selectedPlan);

    const selectedPlanBoostedServices = this.props.filterBoostedServicesByPlanId(selectedPlanId);
    const companyBoostedServicesInPlan = companyBoostedServices.filter(c =>
      selectedPlanBoostedServices.find(b => b.id == c.boostedServiceId)
    );
    log("companyBoostedServicesInPlan", companyBoostedServicesInPlan);

    this.setState({
      selectedPlan,
      companyBoostedServicesInPlan,
      loading: false,
    });
    this.sendInitialSegmentEvents();
  };

  sendInitialSegmentEvents = () => {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      "finished_boosted_services_step",
      getUserRoleAuthHeaders
    );
    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      "started_ads_checkout_step",
      getUserRoleAuthHeaders
    );
  };

  shouldComponentUpdate = (nextProps) => {
    // If we allow the component to update while we're processing
    // payment, the promise button can re-render, and enable itself.
    return !this.state.processing;
  };

  onClickBack = () => {
    return this.props.transitionTo("back", this.props.transitions);
  };

  onClickPay = () => {
    const { selectedPlan, coupon } = this.state;
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      "finished_ads_checkout_step",
      getUserRoleAuthHeaders,
      { funnel: true }
    );

    return this.props.findOrCreateCustomer()
      .then(() => this.props.findOrCreateSubscription(selectedPlan.id, coupon ? coupon.id : undefined))
      .then(this.addToBoostBalances)
      .then(this.props.enqueueCompanyAdsProfileSetup)
      .then(this.props.fetchCompanyBoostedServices)
      .then(this.props.fetchCompanyRoleProviderData)
      .then(() =>
        this.props.showSnackbar(
          "Bots are building your ads - live within 24 hours."
        )
      )
      .then(() => this.props.transitionTo("next", this.props.transitions))
      .catch((error) => {
        const { response } = error;


        log('error', error);
        if (response && response.data && response.data.message) {
          throw {
            name: "ClickPayError",
            message: response.error.message,
          };
        } else {
          throw error;
        }
      });
  };

  onStripeError = (error) => {
    this.props.showSnackbar(
      error.message ||
        "Something went wrong processing your payment! Contact us for assistance."
    );
  };

  onClickPayError = (error) => {
    log("onClickPayError", error);
    this.props.showSnackbar(
      error.message || "Something went wrong! Contact us for assistance."
    );
  };

  addToBoostBalances = () => {
    const { companyBoostedServicesInPlan } = this.state;

    const boostTotalCentsByCompanyBoostedService = {};

    companyBoostedServicesInPlan.forEach((c) => {
      if (c.initialBoostTotalCents) {
        boostTotalCentsByCompanyBoostedService[c.id] = c.initialBoostTotalCents;
      }
    });

    if (!Object.keys(boostTotalCentsByCompanyBoostedService).length) {
      log("Nothing to invoice", boostTotalCentsByCompanyBoostedService);
      return Promise.resolve();
    }

    return this.props.addToBoostBalances(
      boostTotalCentsByCompanyBoostedService
    );
  };


  onValidCouponCode = coupon => {
    this.setState({ coupon });
  }

  onClickRemoveCoupon = () => {
    this.setState({ coupon: null });
  }


  render() {
    const { classes, boostedServices, company } = this.props;
    const { selectedPlan, companyBoostedServicesInPlan, loading, coupon } = this.state;

    if (loading) {
      return <LinearProgress />;
    }

    const isSubscribedToSelectedPlan = this.props.isSubscribedToPlanId(selectedPlan.id);

    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <AdsUndoResubscriptionAlert />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5">Today's Summary</Typography>
            </Grid>

            {/* <CancelSetupButton /> */}

            <Grid item xs={12}>
              <Paper className={classes.paperRoot}>
                <TodaysSummary
                  selectedPlan={selectedPlan}
                  coupon={coupon}
                  onClickRemoveCoupon={this.onClickRemoveCoupon}
                  boostedServices={boostedServices}
                  companyBoostedServicesInPlan={companyBoostedServicesInPlan}
                />

                { isSubscribedToSelectedPlan ? null : <CouponCode onValidCouponCode={this.onValidCouponCode}/> }
              </Paper>
            </Grid>

            {company.stripeLastFour ? (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.paymentMethod} variant="body2">
                    Paying with card ending in{" "}
                    <Link component={RouterLink} to="/billing/payment_method">
                      {company.stripeLastFour}
                    </Link>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.onClickBack}
                  >
                    Back
                  </Button>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <PromiseButton
                    buttonProps={{
                      fullWidth: false,
                      variant: "contained",
                      color: "primary",
                      type: "submit",
                    }}
                    type="submit"
                    onProcess={() => {
                      this.setState({ processing: true });

                      return this.onClickPay()
                        .catch(AutoError.catch.bind(this))
                        .finally(() => this.setState({ processing: false }));
                    }}
                  >
                    Pay
                  </PromiseButton>
                </Grid>
              </>
            ) :
              // You ALWAYS require a credit card ads, because you can't sign up
              // without funding a boost balance.
              <Grid item xs={12}>
                <PaymentMethodForm
                  showUpdate={false}
                  processing={this.state.processing}
                  children={(paymentMethodForm) => (
                    <>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.onClickBack}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <PromiseButton
                          buttonProps={{
                            fullWidth: false,
                            variant: "contained",
                            color: "primary",
                            type: "submit",
                          }}
                          type="submit"
                          onProcess={() => {
                            this.setState({ processing: true });

                            return this.props.findOrCreateCustomer()
                              .then(paymentMethodForm.onClickSave)
                              .then(this.onClickPay)
                              .catch(AutoError.catch.bind(this))
                              .finally(() =>
                                this.setState({ processing: false })
                              );
                          }}
                        >
                          Pay
                        </PromiseButton>
                      </Grid>
                    </>
                  )}
                />
              </Grid>
            }
          </Grid>
        </div>
      </MaxWidth>
    );
  }
}

const styles = (theme) => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: paperPadding,
  },
  paymentMethod: {
    textAlign: "right",
    marginTop: 30,
    marginRight: 10,
  },
});

export default withStyles(styles)(
  withContext(
    CompanyRoleContext,
    BoostedServicesContext,
    PlanContext,
    TransitionContext,
    SnackbarContext,
    CheckoutSetup
  )
);

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Material
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
// Kinertia
import { SubNavigation, ReviewsStarIcon } from 'go-boost-library-react';
import { withProvider, log } from 'kn-react';
// App
import AppNavigation from '../../AppNavigation/AppNavigation';
import OrganizationReviewDetails from './OrganizationReviewDetails/OrganizationReviewDetails';
import ReviewsDashboard from './ReviewsDashboard/ReviewsDashboard';
import ReviewsReporting from './ReviewsReporting/ReviewsReporting';
import ReviewSourcesProvider from 'Reviews/ReviewSourcesProvider';
import ReviewsTable from 'Reviews/ReviewsTable/ReviewsTable';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';



class OrganizationReviews extends React.Component {

  render() {
    var subNavLinks = [
      { primaryText: 'Dashboard', path: '/reviews', exactPathMatch: true, icon: <DashboardIcon /> },
      { primaryText: 'Reporting', path: '/reviews/reporting', icon: <BarChartIcon />,},
      { primaryText: 'Reviews', path: '/reviews/all', icon: <ReviewsStarIcon /> }
    ];


    return (
      <AppNavigation title="Reviews">
        <SubNavigation links={subNavLinks}>
          <KnowledgeBaseLaunchBanner />
          <Switch>
            <Route exact path="/reviews" component={ReviewsDashboard} />
            <Route path="/reviews/reporting" component={ReviewsReporting} />
            <Route path="/reviews/all" component={ReviewsTable} />
            <Route exact path="/reviews/:id(\d+)" component={OrganizationReviewDetails} />
            <Route path="*" component={props => <Redirect to='/'/>} />
          </Switch>
        </SubNavigation>
      </AppNavigation>
    );
  }

}

export default withProvider(ReviewSourcesProvider, OrganizationReviews);
import React from 'react';
import Axios from 'axios';

import { SnackbarContext, TableToolbar, Ability, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { Add, Remove } from '@material-ui/icons';
import { LinearProgress, Paper, Toolbar, IconButton, Tooltip, List, ListItemText, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import history from 'history.js';
import NewCompanyOrganizationDialog from './NewCompanyOrganizationDialog';
import paperPadding from 'paperPadding';

class CompanyOrganizationsTable extends React.Component {
  state = {
    loading: true,
    organization: {},
    dialogOpen: false,
    organizations: []
  };


  componentDidMount = () => {
    this.fetchOrganizations();
  }


  fetchOrganizations = () => {
    const companyId = this.props.company.id;
    return Axios.get(
      `/api/core/company_organizations/${ companyId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchOrganizations', response);
      const organizations = response.data.organizations;

      this.setState({ organizations, loading: false });


      return organizations;
    }).catch(error => {
      if( [403, 404].includes( +error.response.status ) ){
        this.props.showSnackbar('Company not available.');

        history.push('/companies');
      }
    });
  }

  createNewCompanyOrganization = organizationId => {
    const companyId = this.props.company.id;
    return Axios.post(
      `/api/core/company_organizations/${ companyId }`,
      { organization_id: organizationId },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      this.setState({ loading: true, dialogOpen: false }, () => this.fetchOrganizations());
    })
    .catch(error => {
      this.props.showSnackbar(error.response.data.message)
    });
  }


  deleteAssociation = organizationId => {
    log('deleteAssociation', organizationId);
    const companyId = this.props.company.id;
    Axios.delete(
      `/api/core/company_organizations/${ companyId }/${ organizationId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      this.setState(
        { loading:true },
        () => {
          this.props.showSnackbar('Association deleted.');

          return this.fetchOrganizations();
        }
      );
    })
    .catch(error => {
      log('deleteAssociation error', error);
      this.props.showSnackbar(error.response.data.message);
    })
  }



  render(){
    const { classes, company, currentUserRole } = this.props;
    const { loading, organizations, dialogOpen } = this.state;

    return (
      <>
        <Paper>


          <TableToolbar
            title="Organizations"
            caption={
              <>
                Organizations associated with <span className={classes.primary}>{ company.name }.</span>
              </>
            }
            actions={
              <Tooltip enterDelay={200} title="Add Organization">
                <IconButton color="secondary" onClick={e => this.setState({ dialogOpen: true })}>
                  <Add/>
                </IconButton>
              </Tooltip>
            }
          />

          { this.state.loading ? <LinearProgress color="primary" /> : null }

          <List>
            {
              organizations.map(o => (
                <ListItem key={o.organization_id}>
                  <ListItemText primary={o.name} />
                  <ListItemSecondaryAction>
                    <Toolbar>
                      {
                        currentUserRole.roleHasAbility(
                          Ability.EDIT_FAMILY_ORGANIZATIONS
                        ) ?
                          <Tooltip enterDelay={200} title="Delete Association">
                            <IconButton onClick={() => this.deleteAssociation(o.organization_id)}>
                              <Remove/>
                            </IconButton>
                          </Tooltip>
                        : null
                      }
                    </Toolbar>

                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>

        </Paper>

        {
          currentUserRole.isOrganizationRole() ?
            <NewCompanyOrganizationDialog
              open={dialogOpen}
              onClose={() => this.setState({ dialogOpen: false }) }
              createNewCompanyOrganization={this.createNewCompanyOrganization}
            />
          : null
        }
      </>
    );
  }

}


CompanyOrganizationsTable.defaultProps = {
  organization: []
}



const styles = theme => ({
  paperRoot: {
    padding: paperPadding,
  },
  blackText: {
    color: 'black',
  },
  primary: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
});

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    SnackbarContext,
    CompanyOrganizationsTable
  )
);
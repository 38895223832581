import React from 'react';
import Axios from 'axios';



const fetchReviewsNextSteps = props => {
  const nextSteps = [ ...steps ];

  if( props.googleAccountLocation.id ){
    nextSteps[0].checked = true;
  }

  if( props.facebookAccountPage.id ){
    nextSteps[1].checked = true;
  }

  return Axios.get(
    `/api/reviews/review_requests/companies/${props.company.id}`,
    {
      params: {
        page: 0,
        limit: 1
      },
      headers: props.getUserRoleAuthHeaders()
    }
  ).then(response => {
    if( response.data.review_requests.length ){
      nextSteps[2].checked = true;
    }

    return Axios.get(
      `/api/core/companies/${props.company.id}/user_roles`,
      {
        params: {
          offset: 0,
          limit: 100
        },
        headers: props.getUserRoleAuthHeaders()
      }
    )
  }).then(response => {
    const otherCompanyUsers = response.data.user_roles.filter(
      u => u.id !== props.currentUserRole.id
    );

    if( otherCompanyUsers.length ){
      nextSteps[3].checked = true;
    }

    return nextSteps
  })
}



fetchReviewsNextSteps.defaultProps = {
  googleAccountLocation: {},
  facebookAccountPage: {},
  company: {},
  getUserRoleAuthHeaders: () => {},
  currentUserRole: {}
}



export default fetchReviewsNextSteps



export const steps = [
  {
    text: 'Connect your Google account.',
    checked: false,
    link: '/reviews/settings#connected-accounts'
  },
  {
    text: 'Connect your Facebook account.',
    checked: false,
    link: '/reviews/settings#connected-accounts'
  },
  {
    text: 'Send yourself a review request.',
    checked: false,
    link: '/reviews/review_requests'
  },
  {
    text: 'Invite a Review Requestor to your company.',
    checked: false,
    link: '/users/user_invitations/new'
  },
]
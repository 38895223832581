import React from 'react';
import { withContext, format } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';

import { Grid, Paper, withStyles, Typography, MenuItem, TextField } from '@material-ui/core';

import SitesDashboardSummary from './SitesDashboardSummary/SitesDashboardSummary';
import SoftwareUsageTable from 'Sites/SitesDashboard/SoftwareUsageTable/SoftwareUsageTable';
import AcquisitionTable from 'Sites/SitesDashboard/AcquisitionTable/AcquisitionTable';
import OpportunityTypePieChart from 'SharedDashboard/OpportunityTypePieChart/OpportunityTypePieChart';
import CompanyAutosuggestDialog from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/CompanyAutosuggest/CompanyAutosuggestDialog';
import FetchedOpportunitiesTable from 'Opportunities/FetchedOpportunitiesTable';

import { ChannelContext } from 'Channels/ChannelProvider';

import { getTimePeriodMoment, getStartDate } from 'SharedDashboard/sharedDashboard';


// http://localhost:8080/api/sites/matomo_reports/companies/29871/actions?report_date=last60

class SitesDashboardContent extends React.Component {

  state = {
    value: 0
  }

  render(){
    const { classes } = this.props;

    const companyIds = this.props.companies.map(c => c.id);

    const opportunitiesStartDate = getStartDate( getTimePeriodMoment(this.props.timePeriod) );

    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Typography variant="h6">Sites Dashboard</Typography>
            {
              this.props.dataAsOfDate ?
                <Typography variant="caption" color="textSecondary">
                  Data as of { format.dateTime(this.props.dataAsOfDate) }
                </Typography>
              : null
            }
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              select
              value={ this.props.timePeriod }
              onChange={e => this.props.onChangeTimePeriod(e.target.value) }
              fullWidth
              label={'Time Period'}
            >
              {
                this.props.timePeriodOptions.map((t,i) => (
                  <MenuItem value={t} key={i}>{ t.label }</MenuItem>
                ))
              }
            </TextField>
          </Grid>

          {
            this.props.isSearchCompaniesVisible ?
              <Grid item xs={12} sm={3}>
                <CompanyAutosuggestDialog
                  initialCompanies={this.props.companies}
                  roleTypeId={this.props.roleTypeId}
                  onChangeCompanies={this.props.onChangeCompanies}
                />
              </Grid>
            :
              null
          }
          <Grid item xs={12} sm={this.props.isSearchCompaniesVisible ? 6 : 9}/>

          <Grid item xs={12} >
            <Paper className={classes.dashboardItem}>
              <SitesDashboardSummary
                companyIds={companyIds}
                timePeriod={this.props.timePeriod}
                roleTypePath={this.props.roleTypePath}
                roleTypeId={this.props.roleTypeId}
                getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                channelIds={this.props.sitesChannels.map(c => c.id)}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Paper className={classes.dashboardItem}>
              <Typography variant="h6">Browser Software</Typography>
              <div className={classes.softwareTable}>
                <SoftwareUsageTable
                  timePeriod={this.props.timePeriod}
                  roleTypePath={this.props.roleTypePath}
                  roleTypeId={this.props.roleTypeId}
                  getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                  segment={'browserCode=='}
                  companyIds={companyIds}
                />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Paper className={classes.dashboardItem}>
              <Typography variant="h6">Operating System Software</Typography>
              <div className={classes.softwareTable}>
                <SoftwareUsageTable
                  timePeriod={this.props.timePeriod}
                  roleTypePath={this.props.roleTypePath}
                  roleTypeId={this.props.roleTypeId}
                  getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                  segment={'None'}
                  companyIds={companyIds}
                />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Paper className={classes.dashboardItem}>
              <Typography variant="h6">Device Type</Typography>
              <div className={classes.softwareTable}>
                <SoftwareUsageTable
                  timePeriod={this.props.timePeriod}
                  roleTypePath={this.props.roleTypePath}
                  roleTypeId={this.props.roleTypeId}
                  getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                  segment={'deviceType=='}
                  companyIds={companyIds}
                />
              </div>
            </Paper>
          </Grid>



          <Grid item xs={12}>
            <Paper style={{ minHeight: 379 }}>
              {/* <div style={{ padding: 15 }}>
                <Typography variant="h6">Acquisition Types</Typography>
              </div> */}
              <AcquisitionTable
                timePeriod={this.props.timePeriod}
                roleTypePath={this.props.roleTypePath}
                roleTypeId={this.props.roleTypeId}
                getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                segment={'deviceType=='}
                companyIds={companyIds}
              />
            </Paper>
          </Grid>



          <Grid item xs={12} sm={4} >
            <Paper style={{ padding: 15, minHeight: 379 }}>
              <Typography variant="h6">Opportunity Type</Typography>
                <OpportunityTypePieChart
                  timePeriod={this.props.timePeriod}
                  roleTypePath={this.props.roleTypePath}
                  roleTypeId={this.props.roleTypeId}
                  getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                  companyIds={companyIds}
                  channelIds={this.props.sitesChannels.map(c => c.id)}
                />
            </Paper>
          </Grid>



          <Grid item xs={12} sm={8} >
            <div style={{ minHeight: 379 }}>
              <FetchedOpportunitiesTable
                getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                rowsPerPage={5}
                rowsPerPageOptions={[5]}
                startDate={opportunitiesStartDate}
                roleTypePath={this.props.roleTypePath}
                roleTypeId={this.props.roleTypeId}
                channels={this.props.sitesChannels}
                showCompanyName={this.props.roleTypePath === 'organizations'}
                isSpamFiltered={true}
                companyIds={companyIds}
                shouldShowKnowledgeBase={this.props.currentUserRole.isCompanyRole()}
              />
            </div>
          </Grid>


        </Grid>

      </div>
    );
  }

}




const styles = theme => ({
  root: {
    padding: 16,
    opportunityDataColors: []
  },
  dashboardItem: {
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
  },
  softwareTable: {
    overflowY: 'auto',
  }
});


SitesDashboardContent.defaultProps = {
  companies: [],
  timePeriod: {},
  timePeriodOptions: [],
  onChangeTimePeriod: () => {},
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  isSearchCompaniesVisible: false,
  onChangeCompany: () => {}
}



export default withContext(
  UserRoleContext,
  ChannelContext,
  withStyles(styles)(
    SitesDashboardContent
  )
)
import React from 'react';
import * as d3 from 'd3';
import Axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';

import { log } from 'kn-react';
import { OpportunityModel } from 'go-boost-library-react';

import {
  fillMissingMoments, getPriorTimePeriod, compareToPrior, decimalChangeFormat, chartFormats,
  absoluteChangeFormat, metricChange, getTimePeriodMoment, getStartDate, dashboardDataRequest
} from 'SharedDashboard/sharedDashboard';
import MatomoReportModel from '../MatomoReportModel';
import SitesDashboardSummaryContent from './SitesDashboardSummaryContent';



class SitesDashboardSummary extends React.Component {

  state = {
    summaryRefreshing: false,

    avgDailyUniqueVisitors: '',
    nbVisits: '',
    avgDailyUniqueVisitorsChange: '',
    nbVisitsChange: '',

    visitsChartData: [],

    avgDailyUniquePageviews: '',
    avgDailyUniquePageviewsChange: '',

    actionsChartData: [],

    totalOpportunities: '',
    totalOpportunitiesChange: '',

    opportunitiesChartData: [],
  }

  componentDidMount = () => {
    this.fetchData();
  }

  componentDidUpdate = oldProps => {
    if(this.props !== oldProps) {
      this.setState(
        { summaryRefreshing: true },
        this.fetchData
      )
    }
  }

  dashboardDataRequest = (controllerPath, subPath, extraParams = {}) => {
    return dashboardDataRequest(
      controllerPath,
      subPath,
      this.props,
      {
        ...extraParams,
        company_ids: this.props.companyIds
      }
    )
  }


  fetchData = () => {
    return Axios.all([
      this.fetchVisitsStats(),
      this.fetchVisitsReports(),
      this.fetchActionsStats(),
      this.fetchActionsReports(),
      this.fetchOpportunityTypeDistribution(),
      this.fetchOpportunitiesChartData(),
    ])
    .finally(() => (
      this.setState({summaryRefreshing: false })
    ));
  }






  fetchVisitsStats = () => {
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return this.dashboardDataRequest(
      '/sites/site_visits_reports',
      '/stats',
      { start_date: getStartDate(timePeriodMoment) }
    )
    .then(response => {
      log('fetchVisitsStats response', response);

      const visitStats = MatomoReportModel.fromJSON(response.data.stats);
      const { avgDailyUniqueVisitors, nbVisits } = visitStats;


      this.setState({
        avgDailyUniqueVisitors,
        nbVisits
      });


      if(!timePeriodMoment) {
        this.setState({
          avgDailyUniqueVisitorsChange: ' ',
          nbVisitsChange: ' '
        });
        return;
      }



      const { numberOfUnits, unit } = this.props.timePeriod;
      const priorTimePeriod = getPriorTimePeriod(timePeriodMoment, numberOfUnits, unit);

      return this.dashboardDataRequest(
        '/sites/site_visits_reports',
        '/stats',
        {
          start_date: priorTimePeriod[0],
          end_date: priorTimePeriod[1]
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;
        const priorVisitsStats = MatomoReportModel.fromJSON(response.data.stats);

        const avgDailyUniqueVisitorsChange = compareToPrior(
          avgDailyUniqueVisitors,
          priorVisitsStats.avgDailyUniqueVisitors,
          'unique visitors',
          priorPeriodName,
          decimalChangeFormat
        );


        const nbVisitsChange = compareToPrior(
          nbVisits,
          priorVisitsStats.nbVisits,
          'visits',
          priorPeriodName,
          absoluteChangeFormat
        );


        this.setState({
          avgDailyUniqueVisitorsChange: metricChange(avgDailyUniqueVisitorsChange),
          nbVisitsChange: metricChange(nbVisitsChange),
         });
      });
    });

  }




  fetchVisitsReports = () => {
    const { timePeriod } = this.props;
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return this.dashboardDataRequest(
      '/sites/site_visits_reports',
      '/reports',
      {
        start_date: getStartDate(timePeriodMoment),
        time_period_unit: timePeriod.unit
      }
    )
    .then(response => {
      log('fetchVisitsReports response', response);
      const visitReports = response.data.reports.map(r => (
        MatomoReportModel.fromJSON(r)
      ))


      if(!visitReports.length) {
        this.setState({
          visitsChartData: []
        });
        return;
      }


      const endMoment = moment();
      let startMoment;
      if (timePeriod.numberOfUnits) {
        startMoment = endMoment.clone().subtract(timePeriod.numberOfUnits, timePeriod.unit);
      } else {
        startMoment = moment(visitReports[0].reportedOn);
      }


      let visitsData = fillMissingMoments(
        visitReports,
        startMoment,
        endMoment,
        timePeriod.unit,
        d => d.reportedOn,
        (m, d = {
          avgDailyUniqueVisitors: 0,
          nbVisits: 0,
        }) => ({
          'Unique Visitors': d.avgDailyUniqueVisitors || 0,
          Visits: d.nbVisits || 0,
          name: m.format(chartFormats[timePeriod.unit]),
        })
      );

      let visitsChartData = visitsData;
      if(timePeriod.numberOfUnits) {
        visitsChartData = visitsChartData.slice(-timePeriod.numberOfUnits);
      }

      this.setState({ visitsChartData });
    });

  }



  fetchActionsStats = () => {
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return this.dashboardDataRequest(
      '/sites/site_actions_reports',
      '/stats',
      { start_date: getStartDate(timePeriodMoment) }
    )
    .then(response => {
      log('fetchActionsStats response', response);

      const actionsStats = MatomoReportModel.fromJSON(response.data.stats);
      const { avgDailyUniquePageviews } = actionsStats;


      this.setState({
        avgDailyUniquePageviews,
      });


      if(!timePeriodMoment) {
        this.setState({
          avgDailyUniquePageviewsChange: ' '
        });
        return;
      }


      const { numberOfUnits, unit } = this.props.timePeriod;
      const priorTimePeriod = getPriorTimePeriod(timePeriodMoment, numberOfUnits, unit);

      return this.dashboardDataRequest(
        '/sites/site_actions_reports',
        '/stats',
        {
          start_date: priorTimePeriod[0],
          end_date: priorTimePeriod[1]
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;
        const priorActionsStats = MatomoReportModel.fromJSON(response.data.stats);

        const avgDailyUniquePageviewsChange = compareToPrior(
          avgDailyUniquePageviews,
          priorActionsStats.avgDailyUniquePageviews,
          'pageviews',
          priorPeriodName,
          decimalChangeFormat
        );


        this.setState({
          avgDailyUniquePageviewsChange: metricChange(avgDailyUniquePageviewsChange),
         });
      });
    });

  }




  fetchActionsReports = () => {
    const { timePeriod } = this.props;
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return this.dashboardDataRequest(
      '/sites/site_actions_reports',
      '/reports',
      {
        start_date: getStartDate(timePeriodMoment),
        time_period_unit: timePeriod.unit
      }
    )
    .then(response => {
      log('fetchActionsReports response', response);
      const actionsReports = response.data.reports.map(r => (
        MatomoReportModel.fromJSON(r)
      ))


      if(!actionsReports.length) {
        this.setState({
          actionsChartData: []
        });
        return;
      }


      const endMoment = moment();
      let startMoment;
      if (timePeriod.numberOfUnits) {
        startMoment = endMoment.clone().subtract(timePeriod.numberOfUnits, timePeriod.unit);
      } else {
        startMoment = moment(actionsReports[0].reportedOn);
      }


      let actionsData = fillMissingMoments(
        actionsReports,
        startMoment,
        endMoment,
        timePeriod.unit,
        d => d.reportedOn,
        (m, d = {
          avgDailyUniquePageviews: 0,
          avgNbUniqOutlinks: 0,
          avgTimeGeneration: 0,
          avgUniqDownloads: 0,
          nbDownloads: 0,
          nbKeywords: 0,
          nbOutlinks: 0,
          nbPageviews: 0,
          nbSearches: 0,
        }) => ({
          'Pageviews': d.avgDailyUniquePageviews || 0,
          name: m.format(chartFormats[timePeriod.unit]),
        })
      );

      let actionsChartData = actionsData;
      if(timePeriod.numberOfUnits) {
        actionsChartData = actionsChartData.slice(-timePeriod.numberOfUnits);
      }

      this.setState({ actionsChartData });
    });

  }



  fetchOpportunityTypeDistribution = () => {
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return this.dashboardDataRequest(
      '/core/opportunities',
      '/opportunity_type_distribution',
      {
        start_date: getStartDate(timePeriodMoment),
        opportunity_types: ['call','form_entry'],
        channel_ids: this.props.channelIds,
        is_spam: false
      }
    )
    .then(response => {
      log('fetchOpportunityTypeDistribution response', response);
      const opportunityTypeDistribution = response.data.opportunity_type_distribution.map(d => ({
        count: d.count,
        opportunityType: d.opportunity_type
      }));

      const totalOpportunities = d3.sum(opportunityTypeDistribution, d => d.count)

      this.setState({ totalOpportunities });


      if(!timePeriodMoment) {
        this.setState({
          totalOpportunitiesChange: ' ',
        });
        return;
      }

      const { numberOfUnits, unit } = this.props.timePeriod;
      const priorTimePeriod = getPriorTimePeriod(timePeriodMoment, numberOfUnits, unit);

      return this.dashboardDataRequest(
        '/core/opportunities',
        '/opportunity_type_distribution',
        {
          start_date: priorTimePeriod[0],
          end_date: priorTimePeriod[1],
          opportunity_types: ['call','form_entry'],
          channel_ids: this.props.channelIds,
          is_spam: false
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;

        const priorDistribution = response.data.opportunity_type_distribution;
        log('priorDistribution', priorDistribution);

        const priorTotalOpportunities = d3.sum(priorDistribution, d => d.count)

        const totalOpportunitiesChange = compareToPrior(
          totalOpportunities,
          priorTotalOpportunities,
          'opportunities',
          priorPeriodName,
          absoluteChangeFormat
        );

        this.setState({
          totalOpportunitiesChange: metricChange(totalOpportunitiesChange)
        });
      });

    });
  }



  fetchOpportunitiesChartData = () => {
    const { timePeriod } = this.props;
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return this.dashboardDataRequest(
      '/core/opportunities',
      '/daily_opportunities',
      {
        start_date: getStartDate(timePeriodMoment),
        time_period_unit: timePeriod.unit,
        channel_ids: this.props.channelIds,
        is_spam: false
      }
    )
    .then(response => {
      log('fetchOpportunitiesChartData response', response);
      const dailyOpportunities = response.data.opportunities.map(o => (
        OpportunityModel.fromJSON(o)
      ));



      if(!dailyOpportunities.length) {
        this.setState({
          opportunitiesChartData: []
        });
        return;
      }


      const endMoment = moment();
      let startMoment;
      if (timePeriod.numberOfUnits) {
        startMoment = endMoment.clone().subtract(timePeriod.numberOfUnits, timePeriod.unit);
      } else {
        startMoment = moment(dailyOpportunities[0].createdAt);
      }


      let opportunitiesData = fillMissingMoments(
        dailyOpportunities,
        startMoment,
        endMoment,
        timePeriod.unit,
        d => d.createdAt,
        (m, d = {
          callCount: 0,
          formEntryCount: 0,
        }) => ({
          Calls: d.callCount || 0,
          'Form Entries': d.formEntryCount || 0,
          name: m.format(chartFormats[timePeriod.unit]),
        })
      );

      let opportunitiesChartData = opportunitiesData;
      if(timePeriod.numberOfUnits) {
        opportunitiesChartData = opportunitiesChartData.slice(-timePeriod.numberOfUnits);
      }

      this.setState({ opportunitiesChartData });
    });

  }



  render(){
    return (
      <div style={{ opacity: this.state.summaryRefreshing ? 0.5 : 1 }}>
        <SitesDashboardSummaryContent
          avgDailyUniqueVisitors={this.state.avgDailyUniqueVisitors}
          nbVisits={this.state.nbVisits}
          avgDailyUniqueVisitorsChange={this.state.avgDailyUniqueVisitorsChange}
          nbVisitsChange={this.state.nbVisitsChange}

          visitsChartData={this.state.visitsChartData}

          avgDailyUniquePageviews={this.state.avgDailyUniquePageviews}
          avgDailyUniquePageviewsChange={this.state.avgDailyUniquePageviewsChange}

          actionsChartData={this.state.actionsChartData}

          totalOpportunities={this.state.totalOpportunities}
          totalOpportunitiesChange={this.state.totalOpportunitiesChange}

          opportunitiesChartData={this.state.opportunitiesChartData}
        />
      </div>
    );
  }

}

SitesDashboardSummary.defaultProps = {
  companyIds: [],
  timePeriod: {},
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  channelIds: []
};


SitesDashboardSummary.propsTypes = {
  roleTypePath: PropTypes.string.isRequired,
  roleTypeId: PropTypes.number.isRequired,
  getUserRoleAuthHeaders: PropTypes.func.isRequired,
};


export default SitesDashboardSummary
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'kn-react';
import { AdsChip, SitesChip, ReviewsChip, SocialChip, VirtualChip } from 'go-boost-library-react';
import { Chip, List, ListItem, ListItemText, Tooltip, withStyles } from '@material-ui/core';



const listSubscribedPlans = props => {
  const { subscribedPlans, classes, subscribedAnnualDomainPlan } = props;

  const plans = subscribedPlans;
  if( subscribedAnnualDomainPlan ){
    plans.push( subscribedAnnualDomainPlan );
  }

  return (
    <List>
      { plans.map(plan => {
        const isSites = (
          plan.isSitesIncluded ||
          ( subscribedAnnualDomainPlan && subscribedAnnualDomainPlan.id === plan.id )
        );

        const subscription = props.subscriptions.find(
          s => s.planId === plan.id
        );

        return (
          <ListItem key={plan.id} classes={{ root: classes.listItemRoot }}>
            <ListItemText
              primary={plan.name}
              primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
              secondary={
                <>
                  {plan.isAdsIncluded ? <AdsChip /> : null}
                  {isSites ? <SitesChip /> : null}
                  {plan.isReviewsIncluded ? <ReviewsChip /> : null}
                  {plan.isSocialIncluded ? <SocialChip /> : null}
                  {plan.isVirtualIncluded ? <VirtualChip /> : null}

                  {
                    ( !( subscription && subscription.hasFailedPayment ) ) ?
                      null
                    :
                      <>
                        <br />

                        <Tooltip title={`Payment failed at: ${ format.dateTime( subscription.failedPaymentAt ) }`}>
                          <Chip
                            className={classes.failedPaymentChip}
                            label='Billing Issue'
                            component={props.disableBillingLink ? undefined : RouterLink}
                            to={props.disableBillingLink ? undefined : '/billing/payment_method'}
                            onClick={props.disableBillingLink ? undefined : () => {/* onClick allows the chip to be tabbed */}}
                            style={{
                              cursor: props.disableBillingLink ? 'initial' : undefined
                            }}
                          />
                        </Tooltip>
                      </>
                  }
                </>
              }
            />

            <ListItemText
              classes={{ root: classes.priceRoot }}
              primary={
                <>
                  <span className={classes.price}>
                    { format.money(plan.planAmountCents / 100) }
                  </span>

                  &nbsp;

                  <span className={classes.period}>
                    { plan.billingPeriod || '' }
                  </span>
                </>
              }
              primaryTypographyProps={{ variant: 'h6' }}
            // secondary={`${ format.money(plan.setupFeeCents / 100) } setup`}
            // secondaryTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
            />
          </ListItem>
        );
      })}
    </List>
  );

}


const styles = theme => ({
  listItemRoot: {
    backgroundColor: '#FAFAFA',
    border: '1px solid #F5F5F5',
    borderRadius: 5,
    padding: 20,
  },
  priceRoot: {
    textAlign: 'right'
  },
  price: {
    color: theme.palette.primary.main,
    fontSize: 32,
  },
  period: {
    color: '#7f8788',
    fontSize: 16,
  },
  noSubscriptions: {
    textAlign: 'left',
    padding: 25,
  },
  failedPaymentChip: {
    marginTop: 15,
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer'
  }
});


listSubscribedPlans.defaultProps = {
  subscriptions: [],
  disableBillingLink: false
}


export default withStyles(styles)(
  listSubscribedPlans
);
const fetchSocialNextSteps = props => {
  const nextSteps = [ ...steps ];

  if( props.companyFeeds.length === 1 ){
    nextSteps[0].link = `/social/feeds/${ props.companyFeeds[ 0 ].feedId }/timeline`;
  }

  return nextSteps
}



fetchSocialNextSteps.defaultProps = {
  companyFeeds: []
}



export default fetchSocialNextSteps



export const steps = [
  {
    text: 'Review your Feed',
    checked: false,
    link: '/social/feeds'
  }
]
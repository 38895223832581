import React from 'react';
import Axios from 'axios';

import { log, withContext } from 'kn-react';
import { SnackbarContext, TableToolbar, UserRoleContext, PlanModel, SubscriptionModel, CompanyModel, Ability, OrganizationRoleContext } from 'go-boost-library-react';

import { withStyles, LinearProgress, Paper, Typography, Grid } from '@material-ui/core';

import CompanyInfoCard from './CompanyInfoCard';
import CompanyInvitationCard from 'Settings/UserInvitations/CompanyUserInvitations/CompanyUserInvitationCard/CompanyUserInvitationCard';
import CompanyOrganizationsTable from 'Settings/Organizations/CompanyOrganizationsTable/CompanyOrganizationsTable';
import CompanyUserCard from 'Settings/Users/CompanyUsers/CompanyUserCard/CompanyUserCard';
import ExternalData from 'Settings/CompanyProfile/ExternalData';
import ListSubscribedPlans from 'Settings/Subscriptions/SubscriptionList/listSubscribedPlans';
import OrganizationModel from 'Settings/Organizations/OrganizationModel';
import paperPadding from 'paperPadding';
import SignUpCodeModel from 'SignUp/SignUpCodeModel'

class CompanyDetails extends React.Component {

  state = {
    loading: true,
    company: {},
    signUpCode: {},
    lastUserInvitedAt: null,
    signUpCodes: [],
    organization: null,
    plans: [],
    annualDomainPlan: {},
    subscriptions: [],
    subscribedPlans: [],
  }

  componentDidMount = () => {
    this.fetchCompany()
    .then( this.getSignUpOrganization )
    .then( this.getPossibleSignUpCodes )
    .then( this.fetchPlans )
    .then( this.fetchAnnualDomainPlan )
    .then( this.fetchSubscriptions )
    .then( this.onFindSubscribedPlans )
    .finally(() => this.setState({ loading: false }));
  }

  onCancel = () => {
    this.getSignUpOrganization()
    .then( this.getPossibleSignUpCodes );
  }

  fetchCompany = () => {
    const { id } = this.props.match.params;

    log('fetchCompany', id);
    return Axios.get(
        `/api/core/companies/${id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      )
      .then(response => {
        log('fetchCompany response', response);
        const company = CompanyModel.fromJSON(response.data.company);
        const signUpCode = SignUpCodeModel.fromJSON(response.data.sign_up_code);
        this.setState({ company, signUpCode });
      })
  }

  updateCompany = (updatedAttributes) => {
    const { id } = this.state.company;
    const company = new CompanyModel(updatedAttributes);

    if(!updatedAttributes.serviceArea) {
      delete company.serviceArea;
    }

    return Axios.put(
      `/api/core/companies/${id}`,
      { company },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const company = CompanyModel.fromJSON(response.data.company);
      this.setState({ company });
      this.props.showSnackbar('Company Info Successfully Updated');
    }).catch(error => {
      if( error.response && error.response.data && error.response.data.message ){
        return this.props.showSnackbar(
          error.response.data.message
        );
      }
    })
  }

  getSignUpOrganization = () => {
    log('getSignUpOrganization');
    return Axios.get(
      `/api/core/organizations/${this.state.company.signUpOrganizationId}`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          include_descendants: false
        }
      }
    ).then(response => {
      log('getSignUpOrganization response', response);
      const organization = OrganizationModel.fromJSON(
        response.data.organization
      );

      this.setState({ organization });
    }).catch(error => {
      if( error.response.status !== 403 ){
        throw error;
      }
    });
  }


  getPossibleSignUpCodes = () => {
    if( !this.state.organization ){
      return Promise.resolve();
    }


    log('getPossibleSignUpCodes');
    return Axios.get(
      `/api/core/sign_up_codes/organizations/${ this.state.organization.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('getPossibleSignUpCodes response', response);
      const signUpCodes = response.data.sign_up_codes.map(c => SignUpCodeModel.fromJSON(c));

      this.setState({ signUpCodes });
    });
  }


  onOrganizationSuggestionsFetchRequested = query => {
    log('onOrganizationSuggestionsFetchRequested');
    return Axios.get(
      `/api/core/organizations/${ this.props.organization.id }/family`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: { query }
      }
    ).then(response => {
      log('onOrganizationSuggestionsFetchRequested response', response);
      return response.data.family.map(
        o => OrganizationModel.fromJSON( o )
      );
    });
  }

  onOrganizationSuggestionSelected = organization => {
    return this.setState(
      { organization },
      this.getPossibleSignUpCodes
    );
  }


  onInviteUser = () => {
    this.setState({ lastUserInvitedAt: Date.now().toString() })
  }


  fetchPlans = () => {
    return Axios.get(
      `/api/core/companies/${ this.state.company.id }/plans`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const plans = response.data.plans.map(
        p => PlanModel.fromJSON( p )
      );

      return this.setState({ plans });
    })
  }



  fetchAnnualDomainPlan = () => {
    return Axios.get(
      `/api/core/companies/${ this.state.company.id }/plans/sites/annual_domain`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const annualDomainPlan = PlanModel.fromJSON(
        response.data.plan
      );

      return this.setState({ annualDomainPlan });
    }).catch(error => {
      log(error);

    })
  }



  fetchSubscriptions = () => {
    return Axios.get(
      `/api/core/companies/${ this.state.company.id }/subscriptions`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const subscriptions = response.data.subscriptions.map(
        s => SubscriptionModel.fromJSON( s )
      );

      return this.setState({ subscriptions });
    })
  }



  onFindSubscribedPlans = () => {
    const { plans, subscriptions } = this.state;

    const subscribedPlanIds = subscriptions.map(
      s => +s.planId
    );

    const subscribedPlans = plans.filter(
      p => subscribedPlanIds.includes( +p.id )
    );

    return this.setState({
      subscribedPlans
    });
  }

  render(){
    const { classes, currentUserRole } = this.props;
    const { loading, company, signUpCode, lastUserInvitedAt } = this.state;
    const canEdit = currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_INFO );
    const canEditExternalData = currentUserRole.roleHasAbility( Ability.EDIT_ORGANIZATION_EXTERNAL_DATA );


    if(loading){
      return <LinearProgress />;
    }


    const annualDomainSubscription = this.state.subscriptions.find(s => (
      this.state.annualDomainPlan &&
      s.planId === this.state.annualDomainPlan.id
    ));

    const subscribedAnnualDomainPlan = (
      annualDomainSubscription && this.state.annualDomainPlan ?
        this.state.annualDomainPlan
      :
        null
    );


    const planIds = new Set();
    const subscriptions = this.state.subscriptions.filter(
      s => s && !planIds.has( s.planId ) && planIds.add( s.planId )
    );

    return (
      <div className={classes.root}>
        <Grid
          spacing={16}
          container
        >
          <Grid item xs={12}>
            <Typography variant="h5">Company Details</Typography>
          </Grid>

          <Grid item xs={12}>
            <CompanyInfoCard
              company={company}
              signUpCode={signUpCode}
              onUpdateCompany={this.updateCompany}
              canEdit={canEdit}
              subscribedPlans={this.state.subscribedPlans}
              signUpCodes={this.state.signUpCodes}
              organization={this.state.organization}
              organizationId={this.props.organization.id}
              onOrganizationSuggestionsFetchRequested={this.onOrganizationSuggestionsFetchRequested}
              onOrganizationSuggestionSelected={this.onOrganizationSuggestionSelected}
              onCancel={this.onCancel}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ padding: 15 }}>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <TableToolbar
                    title="Subscriptions"
                  />

                  {
                    !this.state.subscribedPlans.length ?
                      <>
                        <Typography color="textSecondary" variant="caption" className={classes.noSubscriptions}>
                          No current subscriptions.
                        </Typography>
                      </>
                    :
                      <ListSubscribedPlans
                        disableBillingLink={true}
                        subscriptions={subscriptions}
                        subscribedPlans={this.state.subscribedPlans}
                        subscribedAnnualDomainPlan={subscribedAnnualDomainPlan}
                      />
                  }
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <CompanyOrganizationsTable company={company}/>
          </Grid>

          <Grid item xs={12}>
            <CompanyUserCard company={company} onInviteUser={this.onInviteUser}/>
          </Grid>

          <Grid item xs={12}>
            <CompanyInvitationCard company={company} newInvitationPath="/companies/:id/user_invitations/new"/>
          </Grid>

          <Grid item xs={12}>
            <ExternalData
              companyId={company.id}
              canEditExternalData={canEditExternalData}
            />
          </Grid>
      </Grid>
      </div>
    );
  }

}

const styles = theme => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: paperPadding,
    paddingBottom: 32,
  }
});

export default withStyles(styles)(
  withContext(
    UserRoleContext,
    OrganizationRoleContext,
    SnackbarContext,
    CompanyDetails
  )
);

import { Button, Grid, LinearProgress, Link, Paper, Typography, withStyles } from '@material-ui/core';
import { PromiseButton, SnackbarContext } from 'go-boost-library-react';
import history from 'history.js';
import { log, MaxWidth, withContext } from 'kn-react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import CheckoutSummary from '../CheckoutSummary/CheckoutSummary';
import paperPadding from 'paperPadding';


class Checkout extends React.Component {

  state = {
    loading: true,
    companyBoostedService: {},
  }

  componentDidMount = () => {
    const boostedServiceId = parseInt(this.props.match.params.id);
    const { companyBoostedServices } = this.props;
    const companyBoostedService = companyBoostedServices.find(c => c.boostedServiceId === boostedServiceId);
    this.setState({ companyBoostedService, loading: false });
  }

  onClickPay = () => {
    return this.addToBoostBalances()
      .then(this.enqueueCompanyBoostedServiceSetup)
      .then(() => this.props.showSnackbar('Bots are building your ads - live within 24 hours.'))
      .then(() => this.props.transitionTo('next', this.props.transitions))
      .catch(error => {
        log('onClickPay error', error);

        const errorState = {
          errorStatus: error.response ? error.response.status : null,
          errorMessage: error.response ? error.response.message : error.toString(),
        };

        history.push({
          pathname: '/error',
          state: errorState
        });
      });
  }


  addToBoostBalances = () => {
    const { companyBoostedService } = this.state;
    const boostTotalCentsByCompanyBoostedService = {
      [companyBoostedService.id]: companyBoostedService.initialBoostTotalCents
    };

    if (!Object.keys(boostTotalCentsByCompanyBoostedService).length) {
      log('Nothing to invoice', boostTotalCentsByCompanyBoostedService);
      return Promise.resolve();
    }

    return this.props.addToBoostBalances(boostTotalCentsByCompanyBoostedService);
  }



  enqueueCompanyBoostedServiceSetup = () => {
    const { companyBoostedService } = this.state;
    return this.props.enqueueCompanyBoostedServiceSetup(companyBoostedService.id);
  }


  render() {
    const { classes, company } = this.props;
    const { loading, companyBoostedService } = this.state;
    const plan = this.props.subscribedAdsPlan;

    if (loading) {
      return <LinearProgress />;
    }


    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="h5">Today's Summary</Typography>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paperRoot}>
                <CheckoutSummary
                  primaryChargeCents={ companyBoostedService.initialBoostTotalCents }
                  managementFeePercent={ plan.adsManagementFeePercent }
                />
              </Paper>

              <Typography className={classes.paymentMethod} variant="body2">
                Paying with card ending in <Link component={RouterLink} to="/billing/payment_method">{ company.stripeLastFour }</Link>
              </Typography>
            </Grid>



            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.goBack()}
              >
                Back
            </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <PromiseButton
                buttonProps={{
                  fullWidth: false,
                  variant: 'contained',
                  color: 'primary',
                  type: 'submit',
                }}
                type="submit"
                onProcess={this.onClickPay}
              >
                Pay
              </PromiseButton>
            </Grid>


          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16
  },
  paperRoot: {
    padding: paperPadding,
  },
  paymentMethod: {
    textAlign: 'right',
    marginTop: 30,
    marginRight: 10,
  }
});


export default withStyles(styles)(
  withContext(
    TransitionContext,
    SnackbarContext,
    Checkout
  )
);

import React from 'react';

import { withContext } from 'kn-react';
import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';

import { withStyles } from '@material-ui/core';

import { USER } from './Category';
import ActivityLogList from './ActivityLogList';

const companyActivityLog = props => {
  const { classes, company } = props;

  return (
    <ActivityLogList
      companyIds={ [ company.id ] }
      negatedCategories={ [ USER ] }
      tableToolbarCaption={
        <>
          Previous activity at <span className={classes.primary}>{company.name}</span>.
        </>
      }
    />
  )
}

const styles = theme => ({
  primary: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
})

export default withContext(
  CompanyRoleContext,
  PlanContext,
  withStyles(styles)(
    companyActivityLog
  )
)
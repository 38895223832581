import React from 'react';

import { MaxWidth, withContext } from 'kn-react';
import { UserRoleContext, Ability, KnowledgeBaseButton } from 'go-boost-library-react';

import { Button, Grid, Typography, withStyles, IconButton, Tooltip } from '@material-ui/core';

import { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import { FeedContext } from 'Social/Feed/FeedProvider';
import FeedList from 'Social/Feed/FeedList';


class Feed extends React.Component {
  render() {
    const { classes } = this.props;

    const supportText = this.props.feeds && this.props.feeds.length ?
        'Click below to add a Feed.'
      :
        ''
    ;

    const isShutdown = this.props.companySocialProfile && this.props.companySocialProfile.isShutdown;

    const companyFeedFeedIds = (
      isShutdown ?
        []
      :
        this.props.companyFeeds.map(f => f.feedId)
    );

    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>

            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Current Feeds

                &nbsp;

                <KnowledgeBaseButton
                  path={'/what-is-a-feed'}
                  tooltipTitle={'Learn more about Feeds'}
                />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FeedList
                availableFeeds={this.props.feeds.filter(f => companyFeedFeedIds.includes(f.id))}
                selectedFeedIds={companyFeedFeedIds}
                getPreviewFeedLink={() => {}}
                warningText={`You do not have any Company Feeds. ${ supportText }`}
              />
            </Grid>

            {
              isShutdown || this.props.feeds.length === this.props.companyFeeds.length ?
                null
              :
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">Available Feeds</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FeedList
                      availableFeeds={this.props.feeds.filter(f => !companyFeedFeedIds.includes(f.id))}
                      selectedFeedIds={this.props.companyFeeds.map(f => f.feedId)}
                      onClickSetup={feed => this.props.subscribeToFeed(feed.id)}
                      setupText={'Subscribe to Feed'}
                      canLaunchFeed={this.props.currentUserRole.roleHasAbility(Ability.EDIT_SOCIAL_COMPANY_FEED)}
                    />
                  </Grid>
                </>
            }
          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: 0
  },
  add: {
    textAlign: 'right'
  }
});

export default withContext(
  CompanySocialProfileContext,
  UserRoleContext,
  FeedContext,
  withStyles(styles)(Feed)
);
import React from 'react';
import { CardElement, Elements, injectStripe } from 'react-stripe-elements';

import { AmexCardIcon, DiscoverCardIcon, MastercardCardIcon, VisaCardIcon, CompanyRoleContext } from 'go-boost-library-react';
import { AutoError, log, MaxWidth, withContext } from 'kn-react';

import { CreditCard } from '@material-ui/icons';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, withStyles } from '@material-ui/core';

import paperPadding from 'paperPadding';

class PaymentMethodForm extends React.Component {
  state = {
    paymentMethodUpdatedAt: ''
  }


  shouldComponentUpdate = nextProps => {
    return !this.props.processing;
  }


  onClickSave = () => {
    return this.getStripeToken()
      .then(stripeToken => this.props.updatePaymentMethod(stripeToken.id))
      .then(() => {
        // Set paymentMethodUpdatedAt to force a rerender and get rid of the payment info
        // in the Stripe component
        if (this.props.clearOnSave) {
          this.setState({ paymentMethodUpdatedAt: new Date() });
        }
      });
  }

  getStripeToken = () => {
    log('getStripeToken');

    if (!this.props.stripe) {
      log('Stripe not yet loaded');
      return;
    }

    return this.props.stripe.createToken()
      .then(response => {
        log('stripe.createToken() response', response);
        if (response.error) {
          throw {
            name: 'StripeError',
            message: response.error.message,
          };
        }
        return response.token
      });
  }


  cardLogo = () => {
    const { company: { stripeBrand } } = this.props;
    const brandIcon = {
      'Visa': VisaCardIcon,
      'American Express': AmexCardIcon,
      'Discover': DiscoverCardIcon,
      'MasterCard': MastercardCardIcon,
    }
    const Icon = brandIcon[stripeBrand] || CreditCard;
    return <Icon fontSize="large" style={{ color: '#555' }} />;
  }


  render() {
    const { classes, company, children } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={16}>
              {
                company.stripeLastFour ?
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Current Payment Method</Typography>

                    <List>
                      <ListItem>
                        <ListItemIcon>
                          {this.cardLogo()}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <>
                              ****&nbsp;&nbsp;&nbsp;
                              ****&nbsp;&nbsp;&nbsp;
                              ****&nbsp;&nbsp;&nbsp;
                                  <span className={classes.colorPrimary}>
                                {company.stripeLastFour}
                              </span>
                            </>
                          }
                          secondary={
                            <>
                              Valid thru&nbsp;
                                <span className={classes.colorPrimary}>
                                {company.stripeExpMonth || 'XX'}/{company.stripeExpYear || 'XX'}
                              </span>
                            </>
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  : null
              }
              {
                !company.stripeLastFour || this.props.showUpdate ?
                  <Grid item xs={12}>
                    {
                      company.stripeLastFour ?
                        <Typography variant="h6" gutterBottom>Update</Typography>
                        : null
                    }
                    <Typography variant="body2" color="textSecondary" paragraph>Please enter your payment info below.</Typography>
                    <form>
                      <CardElement
                        style={cardStyles}
                        // Set paymentMethodUpdatedAt to force a rerender
                        // and get rid of the payment info
                        // in the Stripe component
                        key={this.state.paymentMethodUpdatedAt}
                      />
                    </form>
                  </Grid>
                  : null
              }
            </Grid>
          </Paper>
        </Grid>

        {children(this)}
      </Grid>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16
  },
  paper: {
    padding: paperPadding,
  },
  colorPrimary: {
    color: theme.palette.primary.main
  }
});

const cardStyles = {
  base: {
    fontSize: '20px',
    color: '#424770',
    letterSpacing: '0.025em',
    fontFamily: 'Source Code Pro, monospace',
    '::placeholder': {
      color: '#777',
    },
  },
  invalid: {
    color: '#9e2146',
  },
};

PaymentMethodForm.defaultProps = {
  showUpdate: true,
  clearOnSave: false,
  processing: false,
};


const StripeInjectedComponent = injectStripe(PaymentMethodForm);
const StripeElementsComponent = props => (
  <Elements>
    <StripeInjectedComponent {...props} />
  </Elements>
);


export default withStyles(styles)(
  withContext(
    CompanyRoleContext,
    StripeElementsComponent
  )
);

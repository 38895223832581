import React from 'react';
import Axios from 'axios';
import { withContext, log } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';

import { LinearProgress } from '@material-ui/core';
import { SnackbarContext } from 'go-boost-library-react';
import UserNotificationPreferencesModel from './UserNotificationPreferencesModel';



const DEFAULT_STATE = {
	preferencesLoading: true,
	userNotificationPreferences: {}
};


export const UserNotificationPreferencesContext = React.createContext(DEFAULT_STATE);


class UserNotificationPreferencesProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
	}


  fetchData = () => {
    this.fetchUserNotificationPreferences()
      .finally(() => this.setState({ preferencesLoading: false }));
	}


	fetchUserNotificationPreferences = () => {
		const { currentUserRole, getUserRoleAuthHeaders } = this.props;
		return Axios.get(
			`/api/core/user_notification_preferences/user/${ currentUserRole.userId }`,
			{
				headers: getUserRoleAuthHeaders()
			}
		)
		.then(response => {
			const userNotificationPreferences = UserNotificationPreferencesModel.fromJSON(response.data.user_notification_preference);
			log('fetchUserNotificationPreferences',userNotificationPreferences)
			this.setState({ userNotificationPreferences });
		})
	}


	togglePreferences = (newPreferences, shouldShowSnackbar=true) => {
		const user_notification_preferences = UserNotificationPreferencesModel.fromJSON(newPreferences).toJSON();

		const { currentUserRole, getUserRoleAuthHeaders } = this.props;
		return Axios.put(
			`/api/core/user_notification_preferences/user/${ currentUserRole.userId }`,
			{ user_notification_preferences },
			{ headers: getUserRoleAuthHeaders() }
		)
		.then(response => {
			const userNotificationPreferences = UserNotificationPreferencesModel.fromJSON(response.data.user_notification_preference);
			this.setState({ userNotificationPreferences })

			if (shouldShowSnackbar) {
				this.props.showSnackbar('Notification preferences updated.');
			}
		})
		.catch(error => {
			log('togglePreferences error', error)
			this.props.showSnackbar('Something went wrong.');
		});
	}


  render() {
    const { preferencesLoading, userNotificationPreferences } = this.state;

    if (preferencesLoading) {
      return <LinearProgress />;
    }



    return (
      <UserNotificationPreferencesContext.Provider
        value={{
					userNotificationPreferences,
					togglePreferences: this.togglePreferences
        }}
      >
        {this.props.children}
      </UserNotificationPreferencesContext.Provider>
    );
  }
}


export default withContext(UserRoleContext, SnackbarContext, UserNotificationPreferencesProvider);
import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, withProvider } from 'kn-react';

import { withStyles } from '@material-ui/core';

import CompanyAdsProfileProvider, { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import CompanySocialProfileProvider, { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import fetchSitesNextSteps from './SitesNextSetps';
import ProductCompletionPage from 'SharedSetup/ProductCompletionPage/ProductCompletionPage';


class SitesSetupCompletion extends React.Component {
  state = {
    nextSteps: []
  }

  componentDidMount = () => {
    return fetchSitesNextSteps({
      getUserRoleAuthHeaders: this.props.getUserRoleAuthHeaders,
      companySitesProfile: this.props.companySitesProfile
    }).then(nextSteps => {
      this.setState({ nextSteps });

      if( nextSteps && nextSteps.length && nextSteps[0].link ){
        return window.open(
          nextSteps[0].link
        );
      }
    });
  }

  render(){
    const { classes } = this.props;


    const knowledgeBaseArticles = [
      {
        url: `${ this.props.theme.knowledgeBaseUrl || 'https://support.goboost.com/goboost' }/site-build-meetings`,
        name: 'Come Prepared to the Site Build Meeting'
      }
    ];

    return(
      <ProductCompletionPage
        product={'Sites'}
        nextSteps={this.state.nextSteps}
        dashboardLink={'/sites'}
        knowledgeBaseArticles={knowledgeBaseArticles}
      />
    )
  }
}


const styles = theme => ({});


export default withProvider(
  CompanyAdsProfileProvider,
  CompanySitesProfileProvider,
  CompanySocialProfileProvider,
  withContext(
    CompanyAdsProfileContext,
    CompanySitesProfileContext,
    CompanySocialProfileContext,
    CompanyRoleContext,
    PlanContext,
    withStyles(styles, { withTheme: true })(SitesSetupCompletion)
  )
)
import React from 'react';
import { Paper, Avatar, Typography, withStyles } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import paperPadding from 'paperPadding';

const productNotSetup = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Paper className={classes.paperRoute}>
        <Avatar className={classes.avatar}>
          {props.icon}
        </Avatar>

        <Typography variant='h6'>
          { props.displayText }
        </Typography>

        <Typography variant='caption' className={classes.caption}>
          Please contact <a href="mailto:support@goboost.com">support@goboost.com</a> for help.
        </Typography>
      </Paper>
    </div>
  )
}

const styles = theme => ({
  paperRoute: {
    padding: paperPadding,
    marginTop: 24,
    width: '100%',
    textAlign: 'center'
  },
  root: {
    width: '50%',
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  avatar: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 50,
    height: 50
  },
  caption: {
    marginTop: 15
  }
})

productNotSetup.defaultProps = {
  displayText: "You cannot access this page because this product is not setup",
  icon: <Warning/>
}

export default withStyles(styles)(productNotSetup)

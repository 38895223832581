import React from 'react';

import { PlanContext } from 'go-boost-library-react';
import { withContext, MaxWidth, format, log } from 'kn-react';

import { RadioButtonChecked, RadioButtonUnchecked, Clear } from '@material-ui/icons'
import { withStyles, Card, CardHeader, Divider, IconButton, Chip, Grid, Typography, Button, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';

class ProductPlanSetup extends React.Component {

  state = {
    selectedPlanId: this.props.selectedPlanId || (
      this.props.subscribedProductPlan ? this.props.subscribedProductPlan.id : ''
    )
  };


  selectPlan = plan => {
    this.setState({ selectedPlanId: plan.id });

    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'plan_selected', this.props.getUserRoleAuthHeaders, plan);
  }


  includedServices = plan => {
    const services = ['isAdsIncluded', 'isReviewsIncluded', 'isSitesIncluded'];
    const includedServices = services.filter(s => plan[s]).map(s => s.replace(/is(.+)Included/, '$1')).join(', ')
    log('includedServices', includedServices);
    return includedServices;
  }


  onClickBack = () => {
    this.props.transitionTo('back', this.props.transitions);
  }

  onClickNext = () => {
    const { selectedPlanId } = this.state;
    return this.props.transitionTo(
      'next',
      this.props.transitions,
      { selectedPlanId }
    );
  }


  planCard = p => {
    const { classes } = this.props;
    const { selectedPlanId } = this.state;


    const createHTML = description => {
      return {__html: description}
    };


    return (
      <Grid item xs={12} key={p.id}>
      <Card
        id={`plan-${p.id}`}
        onClick={() => this.selectPlan(p)}
      >
        <CardHeader
          avatar={
            <IconButton onClick={() => this.selectPlan(p)} color="primary">
              {p.id === selectedPlanId ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
            </IconButton>
          }
          title={p.name}
          titleTypographyProps={{
            variant: 'h5'
          }}
          subheader={
            `${format.money(p.planAmountCents / 100)}${ p.billingPeriod ? ' ' + p.billingPeriod : '' }` + (`, ${format.money(p.setupFeeCents / 100)} one-time setup${ p.trialPeriodDays ? `, ${p.trialPeriodDays} day free trial` : '' }`)

          }
          subheaderTypographyProps={{
            variant: 'subtitle2'
          }}
          className={classes.cardHeader}
        />
        <Divider />
        <div dangerouslySetInnerHTML={ createHTML(p.descriptionHtml) } />
      </Card>
    </Grid>
    )
  }


  // action={
  //   ?
  //     <Chip color="primary" label="Subscribed" icon={<CheckIcon/>} classes={{ root: classes.subscribedChip }}/>
  //       :
  //     null
  // }

  render() {
    const { subscribedProductPlan, productPlans } = this.props;
    const { selectedPlanId } = this.state;

    const ProductUndoResubscriptionAlert = this.props.productUndoResubscriptionAlert;

    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <ProductUndoResubscriptionAlert />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">Select a Plan</Typography>
          </Grid>

          {/* <CancelSetupButton /> */}

          {
            subscribedProductPlan ?
            <>
              <h5>Currently Subscribed {this.props.productName || ''} Plan:</h5>
              { this.planCard(subscribedProductPlan) }

              <h5>Additional {this.props.productName || ''} Plans:</h5>
            </>
            : null
          }

          { productPlans.map(p => p !== subscribedProductPlan ? this.planCard(p) : null )}

          <Grid item xs={6}>
            {
              this.props.showBack ?
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={this.onClickBack}
                >
                  Back
                  </Button>
                : null
            }

          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={this.onClickNext}
              disabled={!selectedPlanId}
            >
              Next
              </Button>
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }

}


ProductPlanSetup.defaultProps = {
  showBack: false,
  productUndoResubscriptionAlert: () => null,
};


const styles = theme => ({
  planNamePrimary: {
    color: theme.palette.primary.main,
  },
  priceRoot: {
    textAlign: 'right'
  },
  buttonGrid: {
    marginTop: 20,
  },
  cardHeader: {
    cursor: 'pointer'
  },
  subscribedChip: {
    marginTop: 2 * theme.spacing.unit,
    marginRight: 2 * theme.spacing.unit,

  }
});


export default withStyles(styles)(
  withContext(
    PlanContext,
    TransitionContext,
    ProductPlanSetup
  )
);

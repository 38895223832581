import React from 'react';
import Axios from 'axios';

import { MaxWidth, log, formHelpers, AutoError, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, UserRoleContext, CompanyRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';

import { Typography, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import history from 'history.js';


const MAX_CHARACTER_COUNT = 120;

class NewReviewRequest extends React.Component {
  state = {
    fullName: '',
    phoneNumber: '',
    email: '',
    message: ''
  }

  componentDidMount = () => {
    this.setState({ message: this.requestDefaultMessage() });
  }

  requestDefaultMessage = () => {
    const { currentUserRole, companyReviewsProfile, company } = this.props;
    return `Hello, this is ${ company.name }. ${ companyReviewsProfile.reviewRequestDefaultMessage || '' }`;
  }

  createReviewRequest = () => {
    const { fullName, phoneNumber, email, message } = this.state;

    return Axios.post(
      '/api/reviews/review_requests',
      {
        full_name: fullName,
        phone: phoneNumber,
        email: email,
        message
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('onCreateReviewRequest response', response);
      history.push(`/reviews/review_requests`);
    }).catch(error => {
      log('createReviewRequest error', error)
      if( error.response.status === 400 && error.response.data.message ){
        this.props.showSnackbar(
          error.response.data.message,
          { authHide: false }
        );
      }
    });
  }

  changeInput = (field, newValue) => {
    this.setState({ [field]: newValue });
  }

  onProcess = () => {
    return this.validate()
      .then(this.createReviewRequest)
      .catch(AutoError.catch.bind(this));;
  }

  onValidationError = error => {
    log(error.message)
    this.props.showSnackbar(error.message);
  }

  availableCharacters = () => (
    this.state.phoneNumber && this.state.phoneNumber.trim().length ?
      MAX_CHARACTER_COUNT - ( this.state.fullName || '' ).length
    :
      Infinity
  )

  validate = () => {
    const baseValidations = {
      fullName: {
        presence: { message: `Please enter the reviewer's name.` }
      },
      message: {
        presence: { message: `Please enter a message to the reviewer.` },
        length: { atMost: this.availableCharacters(), message:`Please enter a message that is no longer than ${ this.availableCharacters() } characters.` }
      }
    };

    if (!this.state.email.length && !this.state.phoneNumber.length) {
      baseValidations.email = {
        presence: { message: `Please enter the reviewer's email or phone number.` }
      };
    }

    if(this.state.email.length) {
      baseValidations.email = {
        presence: { message: `Please enter the reviewer's email address.` },
        email: { message: 'Please enter a valid email address.' }
      };
    }

    if(this.state.phoneNumber.trim().length) {
      baseValidations.phoneNumber = {
        presence: { message: `Please enter the reviewer's phone number.` },
        length: { equalTo: 10, message: `Phone number must be 10 digits.` }
      };
    }

    return formHelpers.validate(this.state, baseValidations);
  }


  insertPlaceHolderText = text => {
    if(!this.state.message) {
      this.setState({ message: text });
    }
  }

  render() {
    const { classes } = this.props;
    const { fullName, phoneNumber, email, message } = this.state;

    const placeholderText = "Please review us. We'd appreciate your feedback!";

    return (
      <MaxWidth maxWidth={800}>
        <Typography variant="h5">
          Send a Review Request

          &nbsp;

          <KnowledgeBaseButton
            path={'/reviews_on_goboost_what_are_they_and_how_do_i_manage_them'}
            tooltipTitle={'Your Google or Facebook account must be connected to "Send a Review Request"'}
          />
        </Typography>
        <Typography variant="caption">Please enter the following information to send a review request.</Typography>
        <Paper className={classes.root}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <TextField
                onChange={e => this.setState({ fullName: e.target.value })}
                onBlur={e => this.setState({ fullName: e.target.value.trim() })}
                fullWidth
                id='full-name'
                label="Name"
                margin="normal"
                value={fullName}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                onChange={e => this.setState({ email: e.target.value })}
                onBlur={e => this.setState({ email: e.target.value.trim() })}
                fullWidth
                helperText='Enter an email address to send an email.'
                id='email'
                label="Email Address"
                margin="normal"
                value={email}
                inputProps={{
                  type: 'email'
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                  label="Phone Number"
                  onChange={e => {
                    const phoneNumber = e.target.value.replace(/[^\d]/g, '');
                    this.setState({ phoneNumber });
                  }}
                  inputProps={{
                    name: 'phone-number',
                    id: 'phone-number',
                    maxLength: 10,
                    type: 'tel',
                    pattern: '[0-9]*',
                    noValidate: true
                  }}
                  helperText='Enter a phone number to send a text message.'
                  value={phoneNumber}
                  margin="normal"
                  fullWidth
                />
            </Grid>

            <Grid item xs={12}>
              <TextField
                onChange={e => this.setState({ message: e.target.value })}
                onBlur={e => this.setState({ message: e.target.value.trim() })}
                onDoubleClick={e => this.insertPlaceHolderText(placeholderText)}
                fullWidth
                variant='outlined'
                name='message'
                id='message'
                multiline={true}
                label="Message"
                placeholder={placeholderText + " (Double-click to insert)"}
                margin="normal"
                value={message}
                helperText={
                  !this.state.phoneNumber || !this.state.phoneNumber.trim().length ?
                    null
                  :
                    <span style={{ color: ( message.length > this.availableCharacters() ) ? 'red' : 'black' }}>
                      { message.length || 0 }/{ this.availableCharacters() } characters used.
                    </span>
                }
              />
            </Grid>


            <Grid item xs={3} />
            <Grid item xs={6}>
              <PromiseButton
                onProcess={this.onProcess}
                buttonProps={{
                  color: 'primary',
                  fullWidth: true,
                  variant: 'contained',
                  disabled: this.props.companyReviewsProfile && this.props.companyReviewsProfile.isShutdown
                }}
              >
                Send Request
              </PromiseButton>
            </Grid>
            <Grid item xs={1} className={classes.buttonDiv}>
              <KnowledgeBaseButton
                path={'/sending_review_requests'}
                tooltipTitle={'Learn how to "Send a Review Request"'}
              />
            </Grid>
          </Grid>
        </Paper>
      </MaxWidth>
    );
  }

}

const styles = theme => ({
  root: {
    padding: 16,
    marginTop: 20
  }
});

export default withStyles(styles)(
  withContext(
    CompanyReviewsProfileContext,
    CompanyRoleContext,
    UserRoleContext,
    SnackbarContext,
    NewReviewRequest
  )
);
import React from 'react';

import { JsConvertCase, format } from 'kn-react';

import { withStyles, Typography } from '@material-ui/core';

import reactionsIconAndNames from '../ReactionsIconAndNames';

import hotlike from "./hotlike.svg";
import comment from "./comment.svg";
import share from "./share.svg";



const engagement = props => {
  // const LikesWrapper = props.likes ? Badge : React.Fragment;
  const reactions = [];
  let sum = 0;

  reactionsIconAndNames.forEach(
    r => {
      const key = JsConvertCase.toCamelCase(r.key);

      if( Object.keys(props.stats).find(s => s === key ) ){
        reactions.push( r );
        sum += props.stats[ key ];
      }
    }
  );


  return (
    <div className={props.classes.footer}>

      {
        sum ?
          <div className={props.classes.likebar}>
            {
              reactions.length ?
                reactions.map(
                  i => {
                    const Icon = i.icon;

                    return <Icon/>
                  }
                )
              :
                null
            }

            <Typography variant="caption" className={props.classes.sum}>
              { format.commas(sum) }
            </Typography>
          </div>
        :
          null
      }

      <div className={props.classes.actions}>
        <div className={props.classes.action}>
          <img src={hotlike} alt="Like icon" className={props.classes.actionImg}/>

          <div className={props.classes.actionDiv}>Like</div>
        </div>

        <div className={props.classes.action}>
          <img src={comment} alt="Comment icon" className={props.classes.actionImg}/>

          <div className={props.classes.actionDiv}>Comment</div>
        </div>

        <div className={props.classes.action}>
          <img style={{ marginLeft: 20 }} alt="Share icon" src={share} className={props.classes.actionImg}/>

          <div className={props.classes.actionDiv}>Share</div>
        </div>
      </div>
    </div>
  )
}

const styles = theme => ({
  footer: {
    borderTop: '0.5px solid #dcdee3',
  },
  likebar: {
    display: 'flex',
    margin: '0 20px',
    padding: '10px 0',
    borderBottom: '0.5px solid #dcdee3',
  },
  iconImg: {
    width: '16px',
    height: '16px',
  },
  likebarSpan: {
    alignSelf: 'center',
    color: '#616770',
    fontSize: '13px',
    paddingLeft: '10px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
  },
  action: {
    flex: '1',
    alignSelf: 'center',
  },
  actionImg: {
    width: '16px',
    height: '15px',
    alignSelf: 'center',
  },
  actionDiv: {
    color: '#616770',
    fontWeight: 'bold',
    fontSize: '13px',
    paddingLeft: '10px',
    display: 'inline-block',
  },
  sum: {
    lineHeight: '25px',
    marginLeft: 5
  }
})


engagement.defaultProps = {
  stats: {}
}



export default withStyles(styles)(engagement)
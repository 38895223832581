import React from 'react';

import { Dialog, DialogContent, Button } from '@material-ui/core';

import UploadMediaItem from './UploadMediaItem';


export default props => (
  <Dialog
    open={props.open}
    fullScreen
    onClose={props.onClose}
  >
    <DialogContent>
      <UploadMediaItem
        onNavigate={props.onClose}
        cancelButton={
          <Button
            variant='outlined'
            color='primary'
            style={{ marginRight: 15 }}
            onClick={props.onClose}
          >
            Cancel
          </Button>
        }
      />
    </DialogContent>
  </Dialog>
)
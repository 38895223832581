import React from 'react';
import * as d3 from 'd3';
import { format, log } from 'kn-react';

import {
  Typography, Table, TableBody, TableCell, TableHead,
  TableRow, LinearProgress, IconButton, withStyles, Divider
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { TableToolbar } from 'go-boost-library-react';

import { getTimePeriodMoment, dashboardDataRequest, getStartDate } from 'SharedDashboard/sharedDashboard';
import MatomoReportModel from 'Sites/SitesDashboard/MatomoReportModel';

const REFERRER_TYPES = {
  2: 'Search Engines',
  3: 'Websites',
  7: 'Social Networks',
}

const findReferrerTypesByName = name => (
  Object.keys(REFERRER_TYPES).find(
    r => REFERRER_TYPES[r] === name
  )
);


class AcquisitionTable extends React.Component {
  state = {
    acquisitionsLoading: true,
    acquisitionsRefreshing: true,
    acquisitionTypes: [],
    referrerStats: [],
    expanded: false,
    focusedReferrerType: 0, // int
    maxVisits: 0,
  }

  componentDidMount = () => {
    this.fetchData();
  }

  componentDidUpdate = oldProps => {
    if (this.props !== oldProps) {
      this.setState(
        { acquisitionsRefreshing: true },
        this.fetchData
      );
    }
  }

  fetchData = () => {
    return this.fetchReferrerTypeStats()
      .then(this.fetchReferrersStats)
      .finally(() => this.setState({ acquisitionsLoading: false, acquisitionsRefreshing: false }));
  }

  dashboardDataRequest = (controllerPath, timePeriodMoment) => {
    return dashboardDataRequest(
      controllerPath,
      '/stats',
      this.props,
      {
        start_date: getStartDate(timePeriodMoment),
        companyIds: this.props.companyIds.length ? this.props.companyIds : undefined
      }
    )
  }

  fetchReferrerTypeStats = () => {
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return this.dashboardDataRequest(
      '/sites/site_referrer_types_reports',
      timePeriodMoment
    )
      .then(response => {
        log('fetchReferrerTypeStats response', response);

        const acquisitionTypes = response.data.stats.map(
          r => MatomoReportModel.fromJSON(r)
        );

        acquisitionTypes.sort(
            (a, b) => d3.descending(a.nbVisits, b.nbVisits)
          );

        log('acquisitionTypes', acquisitionTypes)


        this.setState({
          acquisitionTypes,
          maxVisits: acquisitionTypes.length ? acquisitionTypes[0].nbVisits : 0
        });
      });
  }


  fetchReferrersStats = () => {
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return this.dashboardDataRequest(
      '/sites/site_referrers_reports',
      timePeriodMoment
    )
      .then(response => {
        log('fetchReferrerTypeStats response', response);

        const referrerStats = response.data.stats.map(
          r => MatomoReportModel.fromJSON(r)
        );
        log('referrerStats', referrerStats)

        this.setState({
          referrerStats,
        });
      });
  }

  expandPanel = newFocusedReferrerSource => {
    const newFocusedReferrerType = findReferrerTypesByName(newFocusedReferrerSource);

    const { expanded, focusedReferrerType } = this.state;
    let newExpandStatusState = true;

    if (expanded && focusedReferrerType === newFocusedReferrerType) {
      newExpandStatusState = false;
    }

    this.setState({
      expanded: newExpandStatusState,
      focusedReferrerType: newFocusedReferrerType
    });
  }


  tableRow = (a, rowProps = {}) => {
    const { classes } = this.props;
    return (
      <React.Fragment key={a.label}>
        <TableRow {...rowProps}>
          <TableCell>
            {a.label}
          </TableCell>

          <TableCell align="right">
            {format.commas(a.nbVisits, { decimals: 0 })}
          </TableCell>

          <TableCell align="right">
            {format.commas(a.avgDailyUniqueVisitors, { decimals: 2 })}
          </TableCell>

          <TableCell align="right">
            {format.commas(a.nbActions, { decimals: 0 })}
          </TableCell>

          <TableCell align="right">
            {format.commas(a.nbActions / a.nbVisits, { decimals: 2 })}
          </TableCell>

          <TableCell align="right">
            {format.duration(a.avgVisitLength)}
          </TableCell>

          <TableCell align="right">
            {format.percent(a.bounceCount / a.nbVisits)}
          </TableCell>

          <TableCell>
            {
              // Check if this referrer type has source stats
              this.state.referrerStats.find(
                r => REFERRER_TYPES[String(r.referrerType)] === a.label
              ) ?
                <IconButton onClick={() => this.expandPanel(a.label)}>
                  {
                    (this.state.expanded && this.state.focusedReferrerType === findReferrerTypesByName(a.label)) ?
                      <ExpandLess />
                      :
                      <ExpandMore />
                  }

                </IconButton>
                :
                null
            }
          </TableCell>

        </TableRow>

        {
          (this.state.expanded && REFERRER_TYPES[this.state.focusedReferrerType] === a.label) ?
            this.state.referrerStats.filter(
              r => r.referrerType == this.state.focusedReferrerType
            ).map(c => this.tableRow(c, rowProps = { className: classes.subRow }))
            :
            null
        }
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;


    return (
      <>
        <TableToolbar
					title='Acquisition Types'
				/>

        {
          this.state.acquisitionsLoading || this.state.acquisitionsRefreshing ?
            <LinearProgress />
          :
            null
        }

        {
          (
            !this.state.acquisitionsLoading &&
            !this.state.acquisitionsRefreshing && (
              !this.state.acquisitionTypes ||
              !this.state.acquisitionTypes.length
            )
          ) ?
            <Typography variant="caption" className={classes.noData}>No Acquisitions.</Typography>
          :
            <div className={classes.tableWrapper}>
              <Table padding="dense" className={classes.table} style={{ opacity: this.state.acquisitionsRefreshing ? 0.5 : 1 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Acquisition Source</TableCell>
                    <TableCell align="right">Visits</TableCell>
                    <TableCell align="right">Unique Visitors</TableCell>
                    <TableCell align="right">Actions</TableCell>
                    <TableCell align="right">Actions per Visit</TableCell>
                    <TableCell align="right">Average Time on Website</TableCell>
                    <TableCell align="right">Bounce Rate</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    this.state.acquisitionTypes.map(a => this.tableRow(a))
                  }
                </TableBody>
              </Table>
            </div>
        }
      </>
    )
  }
}


AcquisitionTable.defaultProps = {
  timePeriod: {},
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => { },
  companyIds: [],
};

const styles = {
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  subRow: {
    backgroundColor: 'whitesmoke',
  },
  noData: {
		textAlign: 'center',
		padding: 25,
		color: '#777',
	},
}


export default withStyles(styles)(AcquisitionTable);
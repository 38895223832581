import React from 'react';
import qs from 'qs';

import { withContext } from 'kn-react';
import { CompanyRoleContext } from 'go-boost-library-react';

import { withStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import { FilterDropdown } from 'SharedDashboard/sharedDashboard';

import { ChannelContext } from 'Channels/ChannelProvider';
import FetchedOpportunitiesTable from 'Opportunities/FetchedOpportunitiesTable';




class AdsOpportunities extends React.Component {
  constructor(props){
    super(props);

    const urlParams = qs.parse(
      ( window.location.search || '' ).replace(
        /^\?/,
        ''
      )
    );

    this.state = {
      // selectedChannels: [ this.props.adsChannels[0] ],
      channel: this.props.adsChannels[0],
      channels: [ this.props.adsChannels[0] ],
      isSpamFiltered: true,
      query: urlParams && urlParams.query ? urlParams.query : null,
      idQuery: urlParams && urlParams.id ? urlParams.id : null
    };
  }



  render(){
    // const selectedChannels = this.state.selectedChannels.filter(c => c.id !== 'all');
    // const channels = selectedChannels.length ? selectedChannels : this.props.adsChannels.filter(c => c.id !== 'all');

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}/>
        <Grid item xs={12} sm={3}>
          {/* <FilterDropdown
            label='Channels'
            selectedValues={this.state.selectedChannels}
            options={this.props.adsChannels}
            onChangeFilter={selectedChannels => this.setState({ selectedChannels })}
          /> */}
          <TextField
            select
            label="Channel"
            value={ this.state.channel || '' }
            onChange={e => this.setState({ channel: e.target.value, channels: [ e.target.value ] })}
            fullWidth
          >
            {
              this.props.adsChannels.map(c => (
                <MenuItem value={c} key={c.name}>{ c.name }</MenuItem>
              ))
            }
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            label="Filter Opportunities"
            value={ this.state.query || '' }
            onChange={e => this.setState({ query: e.target.value })}
            placeholder="Filter by author or content."
            fullWidth
          />
        </Grid>

        {/* <Grid item xs={12} sm={2}>
          <TextField
            select
            label="Exclude Spam"
            value={ this.state.isSpamFiltered }
            onChange={e => this.setState({ isSpamFiltered: e.target.value })}
            fullWidth
          >
            <MenuItem value={true} >Yes</MenuItem>
            <MenuItem value={false} >No</MenuItem>
          </TextField>
        </Grid> */}

        <Grid item xs={12}>
          <FetchedOpportunitiesTable
            getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
            rowsPerPageOptions={[10,25,50]}
            roleTypePath={'companies'}
            roleTypeId={this.props.company.id}
            channels={this.state.channels}
            showCompanyName={false}
            isSpamFiltered={this.state.isSpamFiltered}
            displaySpamLabel={false}
            query={this.state.query}
            idQuery={this.state.idQuery}
            shouldShowKnowledgeBase={true}
            knowledgeBasePath={'/lead-opportunities-from-ads'}
          />
        </Grid>
      </Grid>
    );

  }

}

const styles = theme => ({
  progress: {
    theme:theme
  }
})

export default withContext(
  CompanyRoleContext,
  ChannelContext,
  withStyles(styles)(
    AdsOpportunities
  )
);
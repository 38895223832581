import React from 'react';
import moment from 'moment';
import Axios from 'axios';
import { log, formHelpers, AutoError, withContext } from 'kn-react';

import { Grid, TextField, Paper, Typography, Link, List, IconButton, Tooltip, ListSubheader, ListItem, ListItemText, withStyles, CircularProgress } from '@material-ui/core';
import { ReviewListItem, PromiseButton, SnackbarContext, Alert, UserRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';
import ReviewModel from 'Reviews/ReviewModel';
import ReviewResponseModel from 'Reviews/ReviewResponseModel';
import { ReviewSourcesContext } from 'Reviews/ReviewSourcesProvider';
import { Link as RouterLink } from 'react-router-dom';
import { ScrollTo } from 'kn-react';
import paperPadding from 'paperPadding';
import { OpenInNew } from '@material-ui/icons';
import CompanyReviewSiteModel from 'Reviews/CompanyReviewSiteModel';


class ReviewDetails extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,
      isLoadingResponses: props.refresh,
      review: null,
      reviewResponse: null,
      reviewResponses: [],
      reviewSource: null,
      newResponseContent: '',
      newResponsePlaceholder: '',
      companyReviewSite: null
    };
  }


  componentDidMount = () => {
    // Fetch responses first since the responses can change whether or not
    // a review has been updated
    this.fetchReviewResponse()
      .then(this.fetchReview)
      .then(this.fetchCompanyReviewSite)
      .then(this.refreshReviewResponses)
      .finally(() => this.setState({ isLoading: false }));
  }


  fetchReviewResponse = () => {
    return Axios.get(
      `/api/reviews/review_responses/reviews/${this.props.match.params.id}`,
      {
        params: { refresh: this.props.refresh },
        headers: this.props.getUserRoleAuthHeaders()
      }
    )
      .then(response => {
        log('fetchReviewResponse', response);
        const reviewResponses = [];
        let reviewResponse;

        response.data.review_responses.forEach(r => {
          reviewResponse = ReviewResponseModel.fromJSON(r);
          reviewResponses.push( reviewResponse );
        });

        log('fetchReviewResponse reviewResponses', reviewResponses);


        this.setState({ reviewResponses });
      });
  }


  refreshReviewResponses = () => {
    if( !this.state.review || this.state.review.isResponseRefreshRequired ){
      setTimeout(
        this.fetchReview().then(
          this.refreshReviewResponses
        ),
        2000
      );
    } else {
      this.setState({
        isLoadingResponses: false
      });
    }

    return Promise.resolve();
  }



  fetchReview = () => {
    return Axios.get(
      `/api/reviews/${this.props.match.params.id}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchReview', response);
      const review = ReviewModel.fromJSON(response.data.review);

      const reviewSource = this.props.getReviewSourceById(review.reviewSourceId);
      const isRespondable = this.props.respondableReviewSourceIds.includes(review.reviewSourceId);

      let newResponsePlaceholder = [`We appreciate your feedback.`];
      if (review.rating < 3) {
        newResponsePlaceholder.push(`We apologize for your experience and will work to make it right.`);
      } else {
        newResponsePlaceholder.push(`We hope to serve you again soon!`);
      }

      newResponsePlaceholder = newResponsePlaceholder.join(' ');

      this.setState({
        review,
        reviewSource,
        isRespondable,
        newResponsePlaceholder,
      });
    });
  }



  fetchCompanyReviewSite = () => {
    return Axios.get(
      `/api/reviews/company_review_sites/reviews/${ this.state.review.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchCompanyReviewSite response', response);
      const companyReviewSite = CompanyReviewSiteModel.fromJSON(response.data.company_review_site);

      log('fetchCompanyReviewSite model', companyReviewSite);
      this.setState({ companyReviewSite });
    })
    .catch(error => {
      log('fetchCompanyReviewSite error', error);
      if (error.response.status !== 404) {
        this.props.showSnackbar(error.response.data.message);
      }
    })
  }



  createReviewResponse = () => {
    const { newResponseContent, review } = this.state;

    return Axios.post(
      '/api/reviews/review_responses',
      {
        content: newResponseContent,
        review_id: review.id
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('createReviewResponse response', response);
        this.setState({
          reviewResponses: [...this.state.reviewResponses, ReviewResponseModel.fromJSON(response.data.review_response)],
          newResponseContent: '',
          review: {...review, isRespondedTo: true} // edit in place so we do not have to refresh the entire object
        });
      });
  }



  onProcess = () => {
    if(
      !this.state.isLoadingResponses ||
      window.confirm('Our bots are still fetching your newest responses. Are you sure you would like to submit this response?')
    ){
      return this.validate()
      .then(this.createReviewResponse)
      .catch(AutoError.catch.bind(this));
    }

    return Promise.reject();
  }



  onValidationError = error => {
    log(error.message)
    this.props.showSnackbar(error.message);
  }



  onError = error => {
    log(error.response)
    this.props.showSnackbar(error.response.data.message);
  }



  validate = () => {
    const baseValidations = {
      newResponseContent: {
        presence: { message: "Please enter a reply." }
      }
    };

    return formHelpers.validate(this.state, baseValidations);
  }



  /*
    For the cases where the user has edit permission and
    the review cannot be response to (either because the
    review site source is Google/Facebook and they do not
    have their Google/Facebook account connected OR the
    review site source is non-respondable).
  */
  nonRespondableResponseText = () => {
    const { reviewSource } = this.state;

    if (!reviewSource) {
      return null;
    }


    const mustConnectAccount = (
      (reviewSource.isGoogle() && !this.props.googleAccountPresent)
      ||
      (reviewSource.isFacebook() && !this.props.facebookAccountPresent)
    );


    let link;
    let informationText = `Responding to reviews is not supported on ${reviewSource.name}.`;


    if ( mustConnectAccount ) {
      informationText = `You do not have your ${reviewSource.name} account connected.`;
      link = (
        <Link
          component={RouterLink}
          onClick={() => setTimeout(() => ScrollTo.scroll('connected-accounts'), 500)}
          to={'/reviews/settings'}
        >
          Click here to connect your {reviewSource.name} account.
        </Link>
      );
    }


    return (
      <Alert warning variant='caption' style={{ paddingTop: 25, paddingBottom: 25 }}>
        <Typography variant="body2">
          {informationText} {link}
        </Typography>
      </Alert>
    );
  }


  responseTextField = () => {
    const { newResponseContent, reviewSource } = this.state;

    return (
      <Grid container spacing={16} key="responseTextField">
        <Grid item xs={12}>
          <TextField
            onChange={e => this.setState({ newResponseContent: e.target.value })}
            onBlur={e => this.setState({ newResponseContent: e.target.value.trim() })}
            fullWidth
            multiline
            id='message'
            label="Reply"
            placeholder={this.state.newResponsePlaceholder}
            margin="normal"
            variant="outlined"
            value={newResponseContent}
            helperText={`This reply will be publicly visible on ${ reviewSource.name }`}
          />
        </Grid>

        <Grid item xs={12} sm={10} />

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <PromiseButton
            onProcess={this.onProcess}
            buttonProps={{
              fullWidth: false,
              color: 'primary',
              variant: 'contained',
              id: 'respond-button'
            }}
          >
            Respond on { reviewSource.name }
          </PromiseButton>
        </Grid>
      </Grid>
    )
  }


  getTimeStamp = (dateTime) => {
    const timeDifference = moment().diff(moment(dateTime), 'hours');
    const timeStamp = (
      timeDifference < 24 ?
        !timeDifference ? 'Now' : `${timeDifference} hours ago`
        :
        moment(dateTime).format('MMM D, YYYY')
    );

    return timeStamp
  }



  render() {
    const { classes } = this.props;
    const { isLoading, review, reviewSource, isRespondable, reviewResponses } = this.state;

    if (isLoading || !review) {
      return (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress/>
        </div>);
    }


    const isAccountPresent = reviewSource && reviewSource.id && (
      (reviewSource.isGoogle() && this.props.googleAccountPresent)
      ||
      (reviewSource.isFacebook() && this.props.facebookAccountPresent)
    );


    const reviewTimeStamp = this.getTimeStamp(review.publishedAt);

    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: paperPadding, marginBottom: 25 }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6">Review</Typography>
              </Grid>

              {
                (this.state.companyReviewSite && this.state.companyReviewSite.url) ?
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Tooltip title='View Review Site'>
                        <IconButton
                          component={'a'}
                          target='_blank'
                          href={this.state.companyReviewSite.url || '#'}
                        >
                          <OpenInNew/>
                        </IconButton>
                      </Tooltip>
                    </Grid>

                :
                  null
              }
            </Grid>


            <List>
              <ReviewListItem
                review={review}
                time={reviewTimeStamp}
                icon={reviewSource ? reviewSource.getAvatar() : null}
                isRespondable={isRespondable}
                divider={false}
                disableGutters={true}
              />

              <ListSubheader color="primary" disableGutters={true}>
                {
                  ( reviewResponses.length || this.props.canEdit ) ?
                    'Reply'
                  :
                    null
                }

                {
                  !this.props.currentUserRole.isCompanyRole() ?
                    null
                  :
                    <>
                      &nbsp;

                      <KnowledgeBaseButton
                        path={'/responding-to-reviews'}
                        tooltipTitle={'Learn more about responding to reviews'}
                      />
                    </>
                }
              </ListSubheader>

              {
                reviewResponses.map(r => (
                  <ListItem key={r.id} disableGutters>
                    <ListItemText
                      primary={r.content}
                      primaryTypographyProps={{
                        variant: "body2"
                      }}
                      secondary={
                        <>
                          <span className={r.isCompanyAuthored ? classes.companyAuthor : classes.otherAuthor}>
                            {r.author || 'Unknown Author'}
                          </span> — { moment(r.respondedAt).format('MMM D, YYYY') }
                        </>
                      }
                      secondaryTypographyProps={{
                        variant: "caption"
                      }}
                    />
                  </ListItem>
                ))
              }

              {
                !this.state.reviewSource || !reviewSource.isFacebook() ?
                  null
                :
                  <Typography variant='caption'>
                    Facebook's policies may prevent some comments from appearing.
                  </Typography>
              }

              {
                !this.state.isLoadingResponses ?
                  null
                :
                  <>
                    <CircularProgress style={{ marginBottom: 15 }}/>

                    <Typography
                      variant='caption'
                      style={{ color: 'grey' }}
                    >
                      Our bots are fetching your newest responses. They make take some time...
                    </Typography>
                  </>
              }

              {
                this.props.canEdit && ( !reviewResponses.length || reviewSource.isFacebook() ) ?
                  isAccountPresent ? this.responseTextField() : this.nonRespondableResponseText()
                :
                  null
              }
            </List>
          </Paper>
        </Grid>

      </Grid>
    )
  }
}

ReviewDetails.defaultProps = {
  googleAccountPresent: false,
  facebookAccountPresent: false,
  canEdit: false,
  refresh: false,
}

const styles = theme => ({
  companyAuthor: {
    color: theme.palette.primary.main
  },
  otherAuthor: {
    color: theme.palette.secondary.main
  }
});

export default withStyles(styles)(
  withContext(
    UserRoleContext,
    ReviewSourcesContext,
    SnackbarContext,
    ReviewDetails
  )
);
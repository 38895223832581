import React from 'react';
import history from 'history.js';
import UserTable from 'Settings/Users/UserTable/UserTable';


class CompanyUserCard extends React.Component {

  render() {
    const { company } = this.props;


    return (
      <UserTable
        userRolesUrl={`/api/core/companies/${company.id}/user_roles`}
        roleTypeId={company.id}
        roleTypeName={company.name || ''}
        showDescendantsInfo = { false }
      />
    );
  }

}
export default CompanyUserCard;

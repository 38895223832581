import React from 'react';
import Axios from 'axios';

import { log, withContext } from 'kn-react';
import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';

import { LinearProgress } from '@material-ui/core';

import { ChannelContext } from 'Channels/ChannelProvider';
import { CompanyAdsProfileContext } from '../CompanyAdsProfile/CompanyAdsProfileProvider';
import BoostedServiceModel from '../../BoostedServiceModel';
import CompanyBoostedServiceModel from './CompanyBoostedServiceModel';
import PlanBoostedServiceModel from './PlanBoostedServiceModel';



const DEFAULT_STATE = {
  loadingBoostedServices: true,
  boostedServices: [],
  planBoostedServices: [],
  subscribedPlanBoostedServics: [],
  companyBoostedServices: [],
};


export const BoostedServicesContext = React.createContext(DEFAULT_STATE);


class BoostedServicesProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {

    return this.fetchBoostedServices()
      .then(this.fetchCompanyBoostedServices)
      .finally(() => this.setState({ loadingBoostedServices: false }));
  }


  fetchBoostedServices = () => {
    const { company } = this.props;
    log('fetchBoostedServices');
    return Axios.get(
        `/api/ads/boosted_services/companies/${company.id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      )
      .then(response => {
        log('fetchBoostedServices', response);
        const boostedServices = response.data.boosted_services.map(b => (
          BoostedServiceModel.fromJSON(b)
        ));

        const planBoostedServices = response.data.plan_boosted_services.map(b => (
          PlanBoostedServiceModel.fromJSON(b)
        ));

        this.setState({ boostedServices, planBoostedServices });
      });
  }


  filterBoostedServicesByPlanId = planId => {
    log('filterBoostedServicesByPlanId', planId);
    const { planBoostedServices, boostedServices } = this.state;
    const inPlan = planBoostedServices.filter(p => p.planId == planId);
    log('inPlan', inPlan, planBoostedServices);
    return boostedServices.filter(b => inPlan.find(p => p.boostedServiceId == b.id));
  }


  fetchCompanyBoostedServices = () => {
    const { company } = this.props;

    return Axios.get(
        `/api/ads/company_boosted_services/companies/${company.id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      )
      .then(response => {
        log('fetchCompanyBoostedServices', response);
        const companyBoostedServices = response.data.company_boosted_services.map(b => (
          CompanyBoostedServiceModel.fromJSON(b)
        ))
        this.setState({ companyBoostedServices });
      });
  }


  createCompanyBoostedService = boostedServiceId => {
    log('createCompanyBoostedService', boostedServiceId)
    return Axios.post(
      `/api/ads/company_boosted_services`,
      { boosted_service_id: boostedServiceId },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('createCompanyBoostedService response', response);
      this.setState({
        companyBoostedServices: [
          ...this.state.companyBoostedServices,
          CompanyBoostedServiceModel.fromJSON(response.data.company_boosted_service)
        ]
      });
    });
  }


  updateCompanyBoostedService = (companyBoostedServiceId, updatedAttributes) => {
    log('updateCompanyBoostedService', companyBoostedServiceId, updatedAttributes);
    const companyBoostedService = new CompanyBoostedServiceModel(updatedAttributes);

    return Axios.put(
      `/api/ads/company_boosted_services/${companyBoostedServiceId}`,
      { company_boosted_service: companyBoostedService },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('updateCompanyBoostedService response', response);
      const newCompanyBoostedService = CompanyBoostedServiceModel.fromJSON(
        response.data.company_boosted_service
      );

      this.setState({
        companyBoostedServices: [
          ...this.state.companyBoostedServices.filter(c => (
            c.id !== newCompanyBoostedService.id
          )),
          newCompanyBoostedService
        ]
      });
    });
  }


  addToBoostBalances = boostTotalCentsByCompanyBoostedServiceId => {
    log('addToBoostBalances', boostTotalCentsByCompanyBoostedServiceId);
    return Axios.post(`/api/ads/company_boosted_services/add_to_boost_balances`,
      { boost_total_cents_by_company_boosted_service: boostTotalCentsByCompanyBoostedServiceId },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(this.parseAndUpdateCompanyBoostedServiceResponse)
    .then(this.fetchCompanyBoostedServices);
  }



  toggleCompanyBoostedService = companyBoostedService => {
    log('toggleCompanyBoostedService', companyBoostedService);
    const action = companyBoostedService.isActivated ? 'deactivate' : 'activate';
    return Axios.put(
      `/api/ads/company_boosted_services/${ companyBoostedService.id }/${ action }`,
      {  },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(this.parseAndUpdateCompanyBoostedServiceResponse)
    .then(this.props.fetchCompanyAdsProfile);
  }


  parseAndUpdateCompanyBoostedServiceResponse = response => {
    log('parseAndUpdateCompanyBoostedServiceResponse response', response);

    const newCompanyBoostedService = CompanyBoostedServiceModel.fromJSON(
      response.data.company_boosted_service
    );

    const companyBoostedServices = [...this.state.companyBoostedServices];
    const index = companyBoostedServices.findIndex(c => c.id === newCompanyBoostedService.id);
    companyBoostedServices[index] = newCompanyBoostedService;

    this.setState({ companyBoostedServices });
  }


  enqueueCompanyBoostedServiceSetup = companyBoostedServiceId => {
    log('enqueueCompanyBoostedServiceSetup', companyBoostedServiceId);
    return Axios.post(
      `/api/ads/company_boosted_service_setup/enqueue`,
      { company_boosted_service_id: companyBoostedServiceId },
      { headers: this.props.getUserRoleAuthHeaders()}
    )
    .then(response => {
      log('enqueueCompanyBoostedServiceSetup response', response);
    })
    .then(this.fetchCompanyBoostedServices);
  }


  facebookCompanyBoostedServices = () => {
    const facebookCompanyBoostedServices = [];

		this.state.companyBoostedServices.forEach(c => {
			const boostedService = this.state.boostedServices.find(
				b => b.id === c.boostedServiceId
			);
			const channel = this.props.channels.find(
				ch => ch.id === boostedService.channelId
			);

			if( channel.isFacebook() ){
				facebookCompanyBoostedServices.push( c );
			}
    })

    return facebookCompanyBoostedServices
  }



  render(){
    const { loadingBoostedServices } = this.state;

    if(loadingBoostedServices) {
      return <LinearProgress/>;
    }

    return (
      <BoostedServicesContext.Provider
        value={{
          boostedServices: this.state.boostedServices,
          companyBoostedServices: this.state.companyBoostedServices,
          filterBoostedServicesByPlanId: this.filterBoostedServicesByPlanId,
          createCompanyBoostedService: this.createCompanyBoostedService,
          updateCompanyBoostedService: this.updateCompanyBoostedService,
          enqueueCompanyBoostedServiceSetup: this.enqueueCompanyBoostedServiceSetup,
          toggleCompanyBoostedService: this.toggleCompanyBoostedService,
          addToBoostBalances: this.addToBoostBalances,
          fetchCompanyBoostedServices: this.fetchCompanyBoostedServices,
          facebookCompanyBoostedServices: this.facebookCompanyBoostedServices
        }}
      >
        { this.props.children }
      </BoostedServicesContext.Provider>
    );
  }
}


export default withContext(PlanContext, CompanyRoleContext, CompanyAdsProfileContext, ChannelContext, BoostedServicesProvider);
import React from 'react';
import TagManager from 'react-gtm-module';


const DEFAULT_STATE = {
  gtmId: 'GTM-KVFJ7HG',
};



export const GoogleTagManagerContext = React.createContext(DEFAULT_STATE);


class GoogleTagManagerProvider extends React.Component {
  state = DEFAULT_STATE;

  pushToDataLayer = data => {
    const { dataLayer } = window;
    if (dataLayer && data) {
      dataLayer.push(data);
    }
  }


  render() {
    return (
      <GoogleTagManagerContext.Provider
        value={{
          pushToDataLayer: this.pushToDataLayer
        }}
      >
        {this.props.children}
      </GoogleTagManagerContext.Provider>
    );
  }
}


export default GoogleTagManagerProvider;
import React from 'react';

import { withContext, MaxWidth, AutoError, log } from 'kn-react';
import { PromiseButton, SnackbarContext } from 'go-boost-library-react';

import { Grid, withStyles, Typography, TextField, Button } from '@material-ui/core';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import AdsSettingsCard from '../../AdsSettings/AdsSettingsCard';
import AdsUndoResubscriptionAlert from 'Ads/CompanyAds/AdsUndoResubscriptionAlert/AdsUndoResubscriptionAlert';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';

class AdsSettingsSetup extends React.Component {
  state = {
    companyShortName: ''
  }



  componentDidMount = () => {
    const { companyAdsProfile, currentUserRole, getUserRoleAuthHeaders } = this.props;
    const companyShortName = companyAdsProfile.companyShortName || this.getInitialCompanyShortName();

    this.setState({ companyShortName });

    trackCurrentUserSegmentEvent(currentUserRole.userId, 'finished_ads_plan_step', getUserRoleAuthHeaders);

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      'started_ads_settings_step',
      getUserRoleAuthHeaders,
      { funnel: true }
    );
  }



  getInitialCompanyShortName = () => {
    const { company } = this.props;
    const companyShortName = company.name.length < 30 ? company.name : company.name.slice(0,30).trim();
    return companyShortName;
  }



  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions);
  }



  onClickNext = () => {
    const { transitionTo } = this.props;
    const { companyShortName } = this.state;

    return this.props.updateCompanyAdsProfile(
      { companyShortName }
    ).then(
      () => transitionTo('next', this.props.transitions)
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  onChangeCompanyShortName = companyShortName => {
    this.setState({ companyShortName });
  }



  onError = error => {
    log('error', error);
    this.props.showSnackbar(
      error.response ?
        error.response.data.message || 'Error'
      :
        'Error'
    );
  }



  render(){
    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <AdsUndoResubscriptionAlert />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">Ads Settings</Typography>
          </Grid>

          {/* <CancelSetupButton /> */}

          <Grid item xs={12}>
            <AdsSettingsCard
              companyShortName={this.state.companyShortName}
              onChangeCompanyShortName={this.onChangeCompanyShortName}
              includePaper={true}
            />
          </Grid>
          <Grid item xs={6}>
            {
              this.props.showBack ?
                <Button variant="outlined" color="primary" onClick={this.onClickBack}>Back</Button>
              : null
            }
          </Grid>

          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <PromiseButton
              onProcess={this.onClickNext}
              buttonProps= {{
                variant: 'contained',
                color: 'primary',
                type: 'submit'
              }}
            >
              Next
            </PromiseButton>
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }

}


AdsSettingsSetup.defaultProps = {
  showBack: true,
};


const styles = theme => ({
  companyShortNameExplanation: {
    marginTop: 35,
    marginBottom: 20
  }
})


export default withStyles(styles)(
  withContext(
    SnackbarContext,
    TransitionContext,
    AdsSettingsSetup
  )
);

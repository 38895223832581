import React from 'react';
import { CircleMarker, Tooltip } from 'react-leaflet';
import * as d3 from 'd3';
import Axios from 'axios';
import L from 'leaflet';

import { ServicesColors, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log, format } from 'kn-react';

import { Typography, withStyles } from '@material-ui/core';
import { Public } from '@material-ui/icons';

import ProductSummaryMap from '../ProductSummaryMap/ProductSummaryMap';

class SitesSummaryMap extends React.Component {

  state = {
    stats: null,
    companyDistribution: [],
    loading: true,
    totalVisits: 0,
    companyAvgDailyVisitors: 0,
  }

  componentDidMount = () => {
    this.fetchCompanySitesProfilesStats()
      .then(this.fetchSitesMapData)
  }


  fetchCompanySitesProfilesStats = () => {
    log('fetchCompanySitesProfilesStats');

    return Axios.get(
      `/api/sites/company_sites_profiles/organizations/${this.props.organization.id}/stats`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchCompanySitesProfilesStats response', response);
        this.setState({ stats: response.data.stats, loading: false });
      })
  }


  fetchSitesMapData = () => {
    log('fetchSitesMapData');

    return Axios.get(
      `/api/sites/site_visits_reports/organizations/${this.props.organization.id}/company_distribution`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchSitesMapData response', response);

        let companyAvgDailyVisitors = 0;

        const totalVisits = response.data.company_distribution.map(d => {
          companyAvgDailyVisitors += d.avg_daily_unique_visitors;
          return d.nb_visits;
        }).reduce((a, b) => (a + b), 0);

        companyAvgDailyVisitors /= response.data.company_distribution.length;

        const maxVisits = d3.max(response.data.company_distribution, d => d.nb_visits);
        this.radiusScale = d3.scaleSqrt().domain([0, maxVisits]).range([2.5, 12]);

        this.setState({
          companyDistribution: response.data.company_distribution,
          companyAvgDailyVisitors,
          totalVisits
        });
      })
  }


  render() {
    const { companyDistribution, loading, stats } = this.state;

    if (loading) {
      return null;
    }

    const companiesWithPosition = companyDistribution.filter(c => c.latitude && c.longitude);

    let bounds;
    if (companiesWithPosition.length) {
      bounds = new L.LatLngBounds(companiesWithPosition.map(c => [c.latitude, c.longitude]));
    }

    const darkerColor = d3.rgb(ServicesColors.sites.primary).darker(0.2);

    return (
      <ProductSummaryMap
        title="Sites"
        titleLink="/sites"
        icon={Public}
        iconColor={ServicesColors.sites.primary}
        bounds={bounds}
        enrolledCount={stats.is_setup_complete_count}
        incompleteCount={stats.count - stats.is_setup_complete_count}
        metric1Title={format.commas(this.state.totalVisits)}
        metric1Caption="Total Visits"
        metric2Title={format.commas(this.state.companyAvgDailyVisitors)}
        metric2Caption="Avg. Company Daily Visitors"
      >
        {
          companiesWithPosition.map(c => {
            return (
              <CircleMarker
                key={c.company_id}
                center={[c.latitude, c.longitude]}
                radius={this.radiusScale(c.nb_visits)}
                weight={1}
                opacity={0.9}
                color={darkerColor}
                fillOpacity={0.6}
                fillColor={ServicesColors.sites.primary}
              >
                <Tooltip>
                  <Typography variant="body2">{c.name}</Typography>
                  <Typography variant="caption">
                    {format.commas(c.nb_visits)} Total Visits
                    <br/>
                    {format.commas(c.avg_daily_unique_visitors, { decimals: 2 })} Avg Daily Visitors
                  </Typography>
                </Tooltip>
              </CircleMarker>
            );
          })
        }
      </ProductSummaryMap>
    )
  }
}


const styles = {};

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    SitesSummaryMap
  )
);
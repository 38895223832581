import React from 'react';
import { Tooltip, IconButton, withStyles } from '@material-ui/core';
import { SwitchIcon, TextEllipsis } from 'go-boost-library-react';

export default props => {
  const { currentUserRole, userRoles } = props;

  const switchRoleButton = (
    <Tooltip title="Switch Role" enterDelay={200}>
      <IconButton onClick={ () => props.setUserRole({}) }>
        <SwitchIcon/>
      </IconButton>
    </Tooltip>
  );

  return {
    primaryText: (
      <TextEllipsis>
        <strong>{currentUserRole.userEmail}</strong>
      </TextEllipsis>
    ),
    secondaryText: (
      <span>
        {currentUserRole.roleName}
        <br/>
        <small>{currentUserRole.roleTypeName}</small>
      </span>
    ),
    secondaryAction: userRoles.length > 1 ? switchRoleButton : undefined,
    dense: true,
  }
};

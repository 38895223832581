import React from 'react';
import { CircleMarker, Tooltip } from 'react-leaflet';
import * as d3 from 'd3';
import Axios from 'axios';
import L from 'leaflet';

import { ServicesColors, OrganizationRoleContext, OpportunityModel } from 'go-boost-library-react';
import { withContext, withProvider, log, format } from 'kn-react';

import { Typography, withStyles, Divider } from '@material-ui/core';
import { Videocam } from '@material-ui/icons';

import ChannelProvider, { ChannelContext } from 'Channels/ChannelProvider';
import ProductSummaryMap from '../ProductSummaryMap/ProductSummaryMap';

class VirtualSummaryMap extends React.Component {

  state = {
    isSetupCompleteCount: 0,
    count: 0,
    stats: null,
    companyOpportunityStatistics: [],
    loading: true,
  }

  componentDidMount = () => {
    this.fetchCompanyVirtualProfilesStats()
      .then(this.fetchVirtualMapData)
  }


  fetchCompanyVirtualProfilesStats = () => {
    log('fetchCompanyVirtualProfilesStats');

    return Axios.get(
      `/api/virtual/company_virtual_profiles/organizations/${this.props.organization.id}/stats`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchCompanyVirtualProfilesStats response', response);
      this.setState({
        isSetupCompleteCount: response.data.stats.is_setup_complete_count,
        count: response.data.stats.count,
        stats: response.data.stats,
        loading: false
      });
    });
  }


  fetchVirtualMapData = () => {
    log('fetchVirtualMapData');

    return Axios.get(
      `/api/core/opportunities/organizations/${this.props.organization.id}/opportunity_conversion_status_distribution`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          is_spam: false,
          group_by_company: true,
          include_descendants: true,
          channel_ids: this.props.virtualChannels.map(c => c.id),
          opportunity_types: ['form_entry'],
        }
      }
    ).then(response => {
      log('fetchVirtualMapData response', response);
      const companyOpportunityStatistics = response.data.opportunity_conversion_status_distribution.map(
        s => OpportunityModel.fromJSON( s )
      );

      this.setState({ companyOpportunityStatistics });
    });
  }


  render() {
    const { companyOpportunityStatistics, loading, stats } = this.state;

    if (loading) {
      return null;
    }


    const statsByCompany = {}
    let totalOpportunities = 0
    let totalConvertedOpportunities = 0

    companyOpportunityStatistics.forEach(c => {
      if( !statsByCompany[ c.companyId ] ){
        statsByCompany[ c.companyId ] = {
          latitude: c.latitude,
          longitude: c.longitude,
          total: 0,
          noncontacted: 0,
          contacted: 0,
          rejected: 0,
          converted: 0,
        };
      }

      const count = ( c.count || 0 );
      if( !c.conversionStatus ){
        statsByCompany[ c.companyId ].noncontacted += count;
      } else if( c.conversionStatus === OpportunityModel.CONTACTED_CONVERSION_STATUS ){
        statsByCompany[ c.companyId ].contacted += count;
      } else if( c.conversionStatus === OpportunityModel.CONVERTED_CONVERSION_STATUS ){
        statsByCompany[ c.companyId ].converted += count;

        totalConvertedOpportunities += count;
      } else if( c.conversionStatus === OpportunityModel.REJECTED_CONVERSION_STATUS ){
        statsByCompany[ c.companyId ].rejected += count;
      }

      statsByCompany[ c.companyId ].total += count;
      totalOpportunities += count;
    });


    const radiusScale = d3.scaleSqrt().domain([0, totalOpportunities]).range([2.5, 12]);

    let bounds;
    if ( Object.keys( statsByCompany ).length ) {
      bounds = new L.LatLngBounds(
        Object.keys( statsByCompany ).map(
          c => [statsByCompany[ c ].latitude, statsByCompany[ c ].longitude]
        )
      );
    }

    const darkerColor = d3.rgb(ServicesColors.virtual.primary).darker(0.2);

    return (
      <ProductSummaryMap
        title="Virtual"
        titleLink="/virtual"
        icon={Videocam}
        iconColor={ServicesColors.virtual.primary}
        bounds={bounds}
        enrolledCount={this.state.isSetupCompleteCount}
        incompleteCount={this.state.count - this.state.isSetupCompleteCount}
        metric1Title={format.commas(totalOpportunities)}
        metric1Caption="Total Virtual Work Requests"
        metric2Title={format.commas(totalConvertedOpportunities)}
        metric2Caption="Converted Virtual Work Requests"
      >
        {
          Object.keys( statsByCompany ).map(companyId => {
            const c = statsByCompany[ companyId ];

            return (
              <CircleMarker
                key={companyId}
                center={[c.latitude, c.longitude]}
                radius={radiusScale(c.total)}
                weight={1}
                opacity={0.9}
                color={darkerColor}
                fillOpacity={0.6}
                fillColor={ServicesColors.virtual.primary}
              >
                <Tooltip>
                  <Typography variant="body2">{c.name}</Typography>
                  <Typography variant="caption">
                    {format.commas(c.total)} Total Virtual Work Requests
                    <Divider />
                    {format.commas(c.noncontacted)} Noncontacted Virtual Work Requests
                    <br/>
                    {format.commas(c.contacted)} Contacted Virtual Work Requests
                    <br/>
                    {format.commas(c.rejected)} Rejected Virtual Work Requests
                    <br/>
                    {format.commas(c.converted)} Converted Virtual Work Requests
                  </Typography>
                </Tooltip>
              </CircleMarker>
            );
          })
        }
      </ProductSummaryMap>
    )
  }
}


const styles = {};

export default withProvider(
  ChannelProvider,
  withContext(
    OrganizationRoleContext,
    ChannelContext,
    withStyles(styles)(
      VirtualSummaryMap
    )
  )
);
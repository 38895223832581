import React from 'react';
import Axios from 'axios';

import { MaxWidth, log, formHelpers, AutoError, withContext, usStatesList } from 'kn-react';
import { PromiseButton, SnackbarContext, UserRoleContext, CompanyModel } from 'go-boost-library-react';

import { Grid, Link, TextField, Typography, FormControl, InputLabel, Select, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import GooglePlacesAutosuggest from 'SignUp/GooglePlacesAutosuggest/GooglePlacesAutosuggest';
import history from 'history.js';
import OrganizationAutosuggest from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/OrganizationAutosuggest/OrganizationAutosuggest';
import OrganizationModel from '../../Organizations/OrganizationModel';
import SignUpCodeModel from 'SignUp/SignUpCodeModel';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';

class NewCompany extends React.Component {
  state = {
    cantFindPlace: false,
    googlePlaceId: null,
    googlePlace: null,
    name: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    primaryWebsite: '',
    parentOrganization: null,
    signUpCodes: [],
    signUpCode: 'None',
    signUpOrganizationName: '',
    signUpCodeId: 'None',
    signUpOrganizationId: this.props.match.params.id,
    defaultSignUpCodeId: ''
  }


  componentDidMount = () => {
    return this.fetchOrganization();
  }


  fetchOrganization = () => {
    return Axios.get(
      `/api/core/organizations/${ this.props.match.params.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const parentOrganization = OrganizationModel.fromJSON(
        response.data.organization
      );

      return this.setState({
        parentOrganization
      });
    })
  }



  getPossibleSignUpCodes = () => {
    log('getPossibleSignUpCodes');
    return Axios.get(
      `/api/core/sign_up_codes/organizations/${this.state.signUpOrganizationId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('getPossibleSignUpCodes response', response);
      const signUpCodes = response.data.sign_up_codes.map(c => SignUpCodeModel.fromJSON(c));

      const defaultSignUpCode = signUpCodes.find(
        c => c.id == this.state.defaultSignUpCodeId
      );

      this.setState({
        signUpCodes,
        signUpCodeId: defaultSignUpCode ? defaultSignUpCode.id : this.state.signUpCodeId,
        signUpCode: defaultSignUpCode ? defaultSignUpCode.code : this.state.signUpCode
      });
    });
  }


  onCantFindPlace = e => {
    e.preventDefault();
    const { cantFindPlace, googlePlace } = this.state;

    this.setState({
      cantFindPlace: !cantFindPlace,
      googlePlace: cantFindPlace ? null : googlePlace
    });
  }

  onProcess = () => {
    return this.validate()
      .then(this.postData)
      .then(company => this.redirectToNewCompany(company))
      .catch(AutoError.catch.bind(this));
  }

  onSuggestionSelected = suggestion => {
    this.setState({ googlePlaceId: suggestion.place_id, googlePlace: suggestion });
  }


  onClearSuggestion = suggestion => {
    this.setState({ googlePlaceId: null, googlePlace: null });
  }

  validate = () => {
    const { cantFindPlace } = this.state;
    let validations = {
      signUpOrganizationId: {
        presence: {message: "Please specify this new company's parent organization"}
      }
    };

    if(!cantFindPlace) {
      validations = {
        googlePlaceId: {
          presence: { message: `Please search for and select the new company.` }
        },
        ...validations,
      };
    }
    else {
      validations = {
        name: {
          presence: { message: `Please enter the new company's name.` }
        },
        addressLineOne: {
          presence: { message: `Please enter the new company's address.` }
        },
        city: {
          presence: { message: `Please enter the new company's city.` }
        },
        state: {
          presence: { message: `Please select the new company's state.` }
        },
        postalCode: {
          presence: { message: `Please enter the new company's postal code.` },
          length: { length: 5, message: `Postal Code must be five digits.` }
        },
        ...validations,
      };
    }


    return formHelpers.validate(this.state, validations);
  }

  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }

  postData = () => {
    return Axios.post(
      '/api/core/companies',
      {
        google_place_id: this.state.googlePlaceId,
        name: this.state.name,
        address_line_one: this.state.addressLineOne,
        address_line_two: this.state.addressLineTwo,
        city: this.state.city,
        state: this.state.state,
        postal_code: this.state.postalCode,
        phone_number: this.state.phoneNumber,
        primary_website: this.state.primaryWebsite,
        parent_organization_id: this.props.match.params.id,
        sign_up_organization_id: this.state.signUpOrganizationId,
        sign_up_code: this.state.signUpCode !== 'None' ? this.state.signUpCode : undefined
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('postData', response);
      return CompanyModel.fromJSON(response.data.company);
    }).catch(error => {
      log('error', error.response);
      if ([400,409].includes( error.response.status ) ) {
        this.onValidationError( error.response.data );
      } else {
        throw {
          name: 'PostDataError',
          message: 'Something went wrong.'
        };
      }
    });

  }

  redirectToNewCompany = company => {
    history.push(`/companies/${company.id}`)
  }

  onPostDataError = error => {
    log('onPostDataError', error);
    this.props.showSnackbar(error.message);
  };

  onOrganizationSuggestionSelected = organization => {
    return this.setState(
      {
        signUpOrganizationId: organization.id,
        defaultSignUpCodeId: organization.defaultCompanySignUpCodeId,
        organizationName: organization.name
      },
      this.getPossibleSignUpCodes
    );
  }


  onChangeSignUpCode = e => {
    const signUpCodeIdValue = e.target.value;
    const signUpCodeModel = this.state.signUpCodes.find(
      c => c.id == signUpCodeIdValue
    );

    const signUpCode = (
      signUpCodeModel ?
        signUpCodeModel.code
      :
        'None'
    );

    return this.setState({
      signUpCodeId: signUpCodeIdValue || 'None',
      signUpCode
    });
  }

  render(){
    const { googlePlace, cantFindPlace } = this.state;

    return (
      <div style={{ paddingBottom: 50 }}>
        <MaxWidth maxWidth={600}>

          <div style={{ padding: 25 }}>
            <KnowledgeBaseLaunchBanner />
            <img style={{ textAlign: 'center' }}/>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h4">Create New Company</Typography>
            </div>
          </div>

          <Paper style={{ padding: 15 }}>
            <Grid container spacing={16}>
              <Grid item sm={12}>

                {
                  !cantFindPlace ?
                    <GooglePlacesAutosuggest
                      initialInputValue={ this.state.name }
                      onSuggestionSelected={this.onSuggestionSelected}
                      onChangeValue={ newValue => this.setState({ name: newValue || '' }) }
                    />
                  :
                    <TextField
                      autoComplete="new-password"
                      label="Company Name"
                      id="name"
                      value={this.state.name}
                      onChange={e => this.setState({ name: e.target.value })}
                      onBlur={() => formHelpers.trim.call(this, 'name')}
                      margin="normal"
                      fullWidth
                    />
                }

                <Typography variant="caption">
                  <Link href="#" id="toggle-cant-find-company" onClick={this.onCantFindPlace}>
                  {
                    cantFindPlace ?
                      'Search for your company'
                    :
                      `Can't find your company?`
                  }
                  </Link>
                </Typography>


                {
                  !cantFindPlace && googlePlace ?
                    <Paper elevation={1} style={{ marginTop: 15 }}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={ googlePlace.structured_formatting.main_text }
                            secondary={ googlePlace.structured_formatting.secondary_text }
                          />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Remove" onClick={this.onClearSuggestion}>
                              <CloseIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Paper>
                  : null
                }
              </Grid>


                {
                  this.state.cantFindPlace ?
                    <>
                      <Grid item sm={12}>
                        <TextField
                          label="Company Address Line One"
                          value={this.state.addressLineOne}
                          onChange={e => this.setState({ addressLineOne: e.target.value })}
                          onBlur={() => formHelpers.trim.call(this, 'addressLineOne')}
                          inputProps={{
                            name: 'address-line-one',
                            id: 'address-line-one',
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={12}>
                        <TextField
                          label="Company Address Line Two"
                          value={this.state.addressLineTwo}
                          onChange={e => this.setState({ addressLineTwo: e.target.value })}
                          onBlur={() => formHelpers.trim.call(this, 'addressLineTwo')}
                          inputProps={{
                            name: 'address-line-two',
                            id: 'address-line-two',
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={12}>
                        <TextField
                          label="Company City"
                          value={this.state.city}
                          onChange={e => this.setState({ city: e.target.value })}
                          onBlur={() => formHelpers.trim.call(this, 'city')}
                          inputProps={{
                            name: 'city',
                            id: 'city',
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel htmlFor="company-state">Company State</InputLabel>
                          <Select
                            native
                            value={this.state.state}
                            onChange={e => this.setState({ state: e.target.value })}
                            inputProps={{
                              name: 'state',
                              id: 'state',
                            }}
                          >
                            {
                              [''].concat(usStatesList).map(s => <option key={s} value={s}>{s}</option>)
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Company Postal Code"
                          value={this.state.postalCode}
                          onChange={e => this.setState({ postalCode: e.target.value })}
                          onBlur={() => formHelpers.trim.call(this, 'postalCode')}
                          inputProps={{
                            name: 'postal-code',
                            id: 'postal-code',
                          }}
                          margin="normal"
                          fullWidth
                        />
                      </Grid>
                    </>
                  :
                    null
                }

              <Grid item sm={6}>
                <TextField
                  label="Company Phone Number"
                  id="company-phone-number"
                  onChange={e => {
                    const phoneNumber = e.target.value.replace(/[^\d]/g,'');
                    this.setState({ phoneNumber });
                  }}
                  inputProps={{
                    maxLength: 10,
                    name: 'phone-number',
                    id: 'phone-number',
                  }}
                  value={this.state.phoneNumber}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="Company Primary Website"
                  value={this.state.primaryWebsite}
                  onChange={e => this.setState({ primaryWebsite: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, 'primaryWebsite')}
                  inputProps={{
                    name: 'primary-website',
                    id: 'primary-website',
                  }}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  disabled
                  label="Parent Organization"
                  value={this.state.parentOrganization ? this.state.parentOrganization.name : ''}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={12}>
                <FormControl
                  style={{ width: '100%' }}
                >
                  <InputLabel
                    shrink={Boolean(this.state.signUpOrganizationName && this.state.signUpOrganizationName.length)}
                    htmlFor={'organization-autosuggest'}
                  >
                    Sign Up Organization
                  </InputLabel>

                  <OrganizationAutosuggest
                    organizationName={this.state.signUpOrganizationName}
                    onChangeInputValue={signUpOrganizationName => this.setState({ signUpOrganizationName })}
                    onSuggestionSelected={this.onOrganizationSuggestionSelected}
                    shouldHidePlaceholder={true}
                    organizationId={this.props.currentUserRole.roleTypeId}
                  />
                </FormControl>
              </Grid>

              <Grid item sm={12}>
                <TextField
                  select
                  fullWidth
                  label="Sign Up Code"
                  value={this.state.signUpCodeId}
                  onChange={this.onChangeSignUpCode}
                  SelectProps={{ native: true }}
                  inputProps={{
                    name: 'sign-up-code',
                    id: 'sign-up-code',
                  }}
                >
                  <option value='None'>
                    None
                  </option>

                  {
                    this.state.signUpCodes.map(c => (
                      <option key={c.id} value={c.id}>
                        {`${c.code}`}
                      </option>
                    ))
                  }
                </TextField>
              </Grid>

              <Grid item sm={3}/>
              <Grid item sm={6}>
                <PromiseButton onProcess={this.onProcess}>
                  Create Company
                </PromiseButton>
              </Grid>
              <Grid item sm={3}/>

            </Grid>
          </Paper>
        </MaxWidth>
      </div>
    );
  }

}

export default withContext(
  UserRoleContext,
  SnackbarContext,
  NewCompany
);

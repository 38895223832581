import React from 'react';
import { withContext } from 'kn-react';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import ProductPricingTable from 'SharedSetup/ProductPlanSetup/ProductPricingTable';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import VirtualUndoResubscriptionAlert from 'Virtual/CompanyVirtual/VirtualUndoResubscriptionAlert/VirtualUndoResubscriptionAlert';

class VirtualPlanSetup extends React.Component {

  componentDidMount() {
    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'started_virtual_plan_step', this.props.getUserRoleAuthHeaders);
  }

  render() {
    return (
      <ProductPricingTable
        {...this.props}
        subscribedProductPlan={this.props.subscribedVirtualPlan}
        productName="Virtual"
        productPlans={this.props.upgradableVirtualPlans}
        productUndoResubscriptionAlert={VirtualUndoResubscriptionAlert}
      />
    );
  }
}

export default withContext(TransitionContext, VirtualPlanSetup);

import React from 'react';
import Axios from 'axios';
import { withContext, log } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';

import { LinearProgress, Tooltip } from '@material-ui/core';
import ReviewSourceModel from './ReviewSourceModel';
import { Avatar } from '@material-ui/core';



const DEFAULT_STATE = {
  reviewSourcesLoading: true,
  reviewSources: [],
  relevantReviewSources: []
};


export const ReviewSourcesContext = React.createContext(DEFAULT_STATE);


class ReviewSourcesProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchReviewSources()
      .then(this.fetchRelevantReviewSources)
      .finally(() => this.setState({ reviewSourcesLoading: false }));
  }


  fetchReviewSources = () => {
    return Axios.get(
      `/api/reviews/review_sources`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchReviewSources', response);
        const reviewSources = response.data.review_sources.map(r => ReviewSourceModel.fromJSON(r));
        log('fetchReviewSources Model', reviewSources);
        this.setState({ reviewSources });
      })
      .catch(error => {
        log('fetchReviewSources error.response', error.response);
      });
  }


  fetchRelevantReviewSources = () => {
    const entityPath = this.props.currentUserRole.isCompanyRole() ? 'companies' : 'organizations';

    return Axios.get(
      `/api/reviews/review_sources/${ entityPath }/${ this.props.currentUserRole.roleTypeId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchRelevantReviewSources', response);
        const relevantReviewSources = response.data.review_sources.map(r => ReviewSourceModel.fromJSON(r));
        log('fetchRelevantReviewSources Models', relevantReviewSources);
        this.setState({ relevantReviewSources });
      })
      .catch(error => {
        log('fetchReviewSources error.response', error.response);
      });
  }


  getReviewSourceById = sourceId => {
    return this.state.reviewSources.find(s => s.id === sourceId);
  }


  render() {
    const { reviewSources, reviewSourcesLoading } = this.state;

    if (reviewSourcesLoading) {
      return <LinearProgress />;
    }

    const respondableReviewSourceIds = reviewSources.filter(s => s.isRespondable()).map(s => s.id);

    return (
      <ReviewSourcesContext.Provider
        value={{
          reviewSources,
          respondableReviewSourceIds,
          relevantReviewSources: this.state.relevantReviewSources,
          getReviewSource: this.getReviewSource,
          getReviewSourceById: this.getReviewSourceById,
          fetchReviewSources: this.fetchReviewSources,
          fetchRelevantReviewSources: this.fetchRelevantReviewSources
        }}
      >
        {this.props.children}
      </ReviewSourcesContext.Provider>
    );
  }
}


export default withContext(UserRoleContext, ReviewSourcesProvider);
import React from "react";
import Axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { log, withContext, format } from "kn-react";
import { SnackbarContext, TableToolbar, UserRoleContext } from "go-boost-library-react";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import downloadFile from "Download/downloadFile";

import PlanReportModel from "./PlanReportModel";

class PlanReports extends React.Component {
  state = {
    loading: true,
    planReports: [],
  };

  componentDidMount = () => {
    this.fetchPlanReports();
  };

  fetchPlanReports = () => {
    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/reporting/organizations/${currentUserRole.roleTypeId}/plans`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
      }
    ).then((response) => {
      log("fetchPlanReports", response);
      const { plan_reports } = response.data;
      this.setState({
        planReports: plan_reports.map((r) => PlanReportModel.fromJSON(r)),
        loading: false,
      });
      const top = window.document.getElementById("top");
      if (top)
        top.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
    });
  };

  onClickDownload = () => {
    const { currentUserRole } = this.props;

    return downloadFile(
      `/api/core/reporting/organizations/${currentUserRole.roleTypeId}/plans?csv=true`,
      { headers: this.props.getUserRoleAuthHeaders() }
    );
  };

  render() {
    const { classes } = this.props;
    const { planReports } = this.state;

    return (
      <>
        <Paper>
          <TableToolbar
            title="Plans"
            caption={`
              All plans for your organization and sub-organization, with a count of active and canceled subscriptions.
            `}
            actions={
              <Tooltip title="Download Data">
                <IconButton onClick={this.onClickDownload} variant="contained">
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            }
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Owner Organization</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell align="right">Pricing</TableCell>
                <TableCell align="right">Setup Fee</TableCell>
                <TableCell align="right">Ads</TableCell>
                <TableCell align="right">Reviews</TableCell>
                <TableCell align="right">Sites</TableCell>
                <TableCell align="right">Social</TableCell>
                <TableCell align="right">Virtual</TableCell>
                <TableCell align="right">Active</TableCell>
                <TableCell align="right">Canceled</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                this.state.loading ?
                  <TableRow style={{ opacity: 0.5 }}>
                    <TableCell>
                      Loading...
                    </TableCell>
                  </TableRow>
                :
                  planReports.map((p) => (
                    <TableRow key={p.id}>
                      <TableCell component="th" scope="row">
                        {p.ownerOrganizationName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {p.name}
                      </TableCell>
                      <TableCell align="right">
                        {format.money(p.planAmountCents / 100)} {p.billingPeriod}
                      </TableCell>
                      <TableCell align="right">
                        {format.money(p.setupFeeCents / 100)}
                      </TableCell>
                      <TableCell align="right">
                        {p.isAdsIncluded ? <CheckBoxIcon color="primary" /> : null}
                      </TableCell>
                      <TableCell align="right">
                        {p.isReviewsIncluded ? (
                          <CheckBoxIcon color="primary" />
                        ) : null}
                      </TableCell>
                      <TableCell align="right">
                        {p.isSitesIncluded ? (
                          <CheckBoxIcon color="primary" />
                        ) : null}
                      </TableCell>
                      <TableCell align="right">
                        {p.isSocialIncluded ? (
                          <CheckBoxIcon color="primary" />
                        ) : null}
                      </TableCell>
                      <TableCell align="right">
                        {p.isVirtualIncluded ? (
                          <CheckBoxIcon color="primary" />
                        ) : null}
                      </TableCell>
                      <TableCell align="right">{p.activeCount || ""}</TableCell>
                      <TableCell align="right">{p.canceledCount || ""}</TableCell>
                    </TableRow>
                  ))
              }
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }
}

const styles = {
  table: {
    minWidth: 750,
  },
};

export default withStyles(styles)(
  withContext(UserRoleContext, SnackbarContext, PlanReports)
);

import React from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as d3 from 'd3';

import { log } from 'kn-react';
import { OpportunityModel } from 'go-boost-library-react';

import {
  fillMissingMoments, getPriorTimePeriod, compareToPrior, decimalChangeFormat, chartFormats,
  absoluteChangeFormat, metricChange, getTimePeriodMoment, getStartDate, dashboardDataRequest
} from 'SharedDashboard/sharedDashboard';
import VirtualDashboardContent from './VirtualDashboardContent';


const timePeriodOptions = [
  { unit: 'month', label: 'All time' },
  { unit: 'day', numberOfUnits: 7, label: 'Past 7 days' },
  { unit: 'day', numberOfUnits: 30, label: 'Past 30 days' },
  { unit: 'day', numberOfUnits: 90, label: 'Past 90 days' },
  { unit: 'month', numberOfUnits: 12, label: 'Past year' },
];



class VirtualDashboard extends React.Component {

  state = {
    // Filters
    timePeriod: timePeriodOptions[2],
    reviewSource: 'all',
    companies: [],
    isRefreshing: true,

    totalOpportunities: '',
    totalOpportunitiesChange: '',
    opportunitiesChartData: [],
    opportunities: [],

    opportunityConversionStatusDistribution: [],
    opportunityConversionStatusDistributionChanges: [],
  }


  componentDidMount = () => {
    this.fetchData();
  }

  componentDidUpdate = oldProps => {
    if(this.props !== oldProps) {
      this.setState(
        { isRefreshing: true },
        this.fetchData
      )
    }
  }

  dashboardDataRequest = (controllerPath, subPath, extraParams = {}) => {
    return dashboardDataRequest(
      controllerPath,
      subPath,
      this.props,
      {
        ...extraParams,
        company_ids: this.props.companyIds
      }
    )
  }


  fetchData = () => {
    return Axios.all([
      this.fetchOpportunitiesChartData(),
      this.fetchOpportunityTypeDistribution(),
      this.fetchOpportunities(),
      this.fetchOpportunityConversionStatusDistribution()
    ])
    .finally(() => (
      this.setState({isRefreshing: false })
    ));
  }


  onChangeTimePeriod = timePeriod => {
    this.setState(
      { timePeriod },
      this.fetchData
    );
  }

  onChangeCompanies = companies => {
    this.setState(
      { companies },
      this.fetchData
    );
  }


  fetchOpportunityTypeDistribution = () => {
    const timePeriodMoment = getTimePeriodMoment(this.state.timePeriod);

    return this.dashboardDataRequest(
      '/core/opportunities',
      '/opportunity_type_distribution',
      {
        start_date: getStartDate(timePeriodMoment),
        opportunity_types: ['form_entry'],
        channel_ids: this.props.channelIds,
        is_spam: false
      }
    )
    .then(response => {
      log('fetchOpportunityTypeDistribution response', response);
      const opportunityTypeDistribution = response.data.opportunity_type_distribution.map(d => ({
        count: d.count,
        opportunityType: d.opportunity_type
      }));

      const totalOpportunities = d3.sum(opportunityTypeDistribution, d => d.count)

      this.setState({ totalOpportunities });


      if(!timePeriodMoment) {
        this.setState({
          totalOpportunitiesChange: ' ',
        });
        return;
      }

      const { numberOfUnits, unit } = this.state.timePeriod;
      const priorTimePeriod = getPriorTimePeriod(timePeriodMoment, numberOfUnits, unit);

      return this.dashboardDataRequest(
        '/core/opportunities',
        '/opportunity_type_distribution',
        {
          start_date: priorTimePeriod[0],
          end_date: priorTimePeriod[1],
          opportunity_types: ['call','form_entry'],
          channel_ids: this.props.channelIds,
          is_spam: false
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;

        const priorDistribution = response.data.opportunity_type_distribution;
        log('priorDistribution', priorDistribution);

        const priorTotalOpportunities = d3.sum(priorDistribution, d => d.count)

        const totalOpportunitiesChange = compareToPrior(
          totalOpportunities,
          priorTotalOpportunities,
          'virtual work requests',
          priorPeriodName,
          absoluteChangeFormat
        );

        this.setState({
          totalOpportunitiesChange: metricChange(totalOpportunitiesChange)
        });
      });

    });
  }



  fetchOpportunitiesChartData = () => {
    const { timePeriod } = this.state;
    const timePeriodMoment = getTimePeriodMoment(timePeriod);

    return this.dashboardDataRequest(
      '/core/opportunities',
      '/daily_opportunities',
      {
        start_date: getStartDate(timePeriodMoment),
        time_period_unit: timePeriod.unit,
        channel_ids: this.props.channelIds,
        is_spam: false
      }
    )
    .then(response => {
      log('fetchOpportunitiesChartData response', response);
      const dailyOpportunities = response.data.opportunities.map(o => (
        OpportunityModel.fromJSON(o)
      ));



      if(!dailyOpportunities.length) {
        this.setState({
          opportunitiesChartData: []
        });
        return;
      }


      const endMoment = moment();
      let startMoment;
      if (timePeriod.numberOfUnits) {
        startMoment = endMoment.clone().subtract(timePeriod.numberOfUnits, timePeriod.unit);
      } else {
        startMoment = moment(dailyOpportunities[0].createdAt);
      }


      let opportunitiesData = fillMissingMoments(
        dailyOpportunities,
        startMoment,
        endMoment,
        timePeriod.unit,
        d => d.createdAt,
        (m, d = {
          callCount: 0,
          formEntryCount: 0,
        }) => ({
          'Virtual Work Requests': d.formEntryCount || 0,
          name: m.format(chartFormats[timePeriod.unit]),
        })
      );

      let opportunitiesChartData = opportunitiesData;
      if(timePeriod.numberOfUnits) {
        opportunitiesChartData = opportunitiesChartData.slice(-timePeriod.numberOfUnits);
      }

      this.setState({ opportunitiesChartData });
    });
  }


  fetchOpportunities = () => {
    const { timePeriod } = this.state;
    const timePeriodMoment = getTimePeriodMoment(timePeriod);

    return this.dashboardDataRequest(
      '/core/opportunities',
      '',
      {
        start_date: getStartDate(timePeriodMoment),
        time_period_unit: timePeriod.unit,
        channel_ids: this.props.channelIds,
        is_spam: false,
        opportunity_types: ['form_entry'],
      }
    ).then(response => {
      log('fetchOpportunitiesChartData response', response);
      const opportunities = response.data.opportunities.map(o => (
        OpportunityModel.fromJSON(o)
      ));

      this.setState({ opportunities });
    });
  }



  fetchOpportunityConversionStatusDistribution = () => {
    const timePeriodMoment = getTimePeriodMoment(this.state.timePeriod);

    return this.dashboardDataRequest(
      '/core/opportunities',
      '/opportunity_conversion_status_distribution',
      {
        start_date: getStartDate(timePeriodMoment),
        opportunity_types: ['form_entry'],
        channel_ids: this.props.channelIds,
        is_spam: false
      }
    )
    .then(response => {
      log('fetchOpportunityConversionStatusDistribution response', response);
      const opportunityConversionStatusDistribution = response.data.opportunity_conversion_status_distribution.map(d => ({
        count: d.count,
        conversionStatus: d.conversion_status
      }));


      if(!timePeriodMoment) {
        this.setState({
          opportunityConversionStatusDistribution,
          opportunityConversionStatusDistributionChanges: [],
        });
        return;
      }

      const { numberOfUnits, unit } = this.state.timePeriod;
      const priorTimePeriod = getPriorTimePeriod(timePeriodMoment, numberOfUnits, unit);

      return this.dashboardDataRequest(
        '/core/opportunities',
        '/opportunity_conversion_status_distribution',
        {
          start_date: priorTimePeriod[0],
          end_date: priorTimePeriod[1],
          opportunity_types: ['call','form_entry'],
          channel_ids: this.props.channelIds,
          is_spam: false
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;

        const priorDistribution = response.data.opportunity_conversion_status_distribution.map(d => ({
          count: d.count,
          conversionStatus: d.opportunity_conversion_status
        }));
        log('priorDistribution', priorDistribution)

        const opportunityConversionStatusDistributionChanges = [null, 'converted', 'contacted'].map(
          s => {
            const currentCount = opportunityConversionStatusDistribution.find(
              d => d.conversionStatus === s
            );

            const previousCount = priorDistribution.find(
              d => d.conversionStatus === s
            );


            const comparison = compareToPrior(
              currentCount,
              previousCount,
              `${ s ? s : 'noncontacted' } virtual work requests`,
              priorPeriodName,
              absoluteChangeFormat
            );


            return {
              conversionStatus: s,
              metric: metricChange( comparison )
            };
          }
        );

        this.setState({
          opportunityConversionStatusDistribution,
          opportunityConversionStatusDistributionChanges
        });
      });

    });
  }



  render(){
    return (
      <VirtualDashboardContent
        onChangeCompanies={this.onChangeCompanies}
        companies={this.state.companies}
        timePeriod={this.state.timePeriod}
        timePeriodOptions={timePeriodOptions}
        onChangeTimePeriod={this.onChangeTimePeriod}
        roleTypePath={this.props.roleTypePath}
        roleTypeId={this.props.roleTypeId}
        getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
        isSearchCompaniesVisible={this.props.isSearchCompaniesVisible}
        totalOpportunities={this.state.totalOpportunities}
        totalOpportunitiesChange={this.state.totalOpportunitiesChange}
        opportunitiesChartData={this.state.opportunitiesChartData}
        opportunities={this.state.opportunities}
        includedGeoTypeIds={this.props.includedGeoTypeIds}
        excludedGeoTypeIds={this.props.excludedGeoTypeIds}
        opportunityConversionStatusDistribution={this.state.opportunityConversionStatusDistribution}
        opportunityConversionStatusDistributionChanges={this.state.opportunityConversionStatusDistributionChanges}
        virtualWorkRequestMapDefaultBounds={this.props.virtualWorkRequestMapDefaultBounds}
        virtualWorkRequestMapCenter={this.props.virtualWorkRequestMapCenter}
        showViewOpportunity={this.props.showViewOpportunity}
      />
    );
  }

}

VirtualDashboard.propsTypes = {
  roleTypePath: PropTypes.string.isRequired,
  roleTypeId: PropTypes.number.isRequired,
  getUserRoleAuthHeaders: PropTypes.func.isRequired,
};

VirtualDashboard.defaultProps = {
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  isSearchCompaniesVisible: false,
  includedGeoTypeIds: [],
  excludedGeoTypeIds: [],
  virtualWorkRequestMapDefaultBounds: [],
  virtualWorkRequestMapCenter: [],
  showViewOpportunity: false
};


export default VirtualDashboard;
import React from 'react';
import { log } from 'kn-react';
import * as d3 from 'd3';

import { LineChart, Dot, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ratingColors } from './RatingDistribution';
import { NoDataWrapper } from 'go-boost-library-react';

class CumulativeRatingChart extends React.Component {
  renderDot = d => {
    const reviewCount = d.payload[`r${d.value}`];
    if(!reviewCount) return null;

    return (
      <Dot
        {...d}
        fill={ ratingColors[ d.dataKey ]}
        fillOpacity={0.5}
        r={this.radiusScale( reviewCount )}
      />
    );
  }

  tooltipFormatter = (value, name, d) => {
    if( name.startsWith('Overall') ) {
      return d3.format('.2f')(value);
    }
    const reviewCount = d.payload[`r${value}`];
    return '+' + d3.format(',')(reviewCount);
  }


  render() {

    let ratingDataMax = 0;

    this.props.data.forEach(d => {
      ratingDataMax = d3.max([ratingDataMax, d['r1'], d['r2'], d['r3'], d['r4'], d['r5']]);
    });

    log('ratingDataMax', ratingDataMax);

    this.radiusScale = d3.scalePow()
      .domain([0, ratingDataMax])
      .range([2,20]);

    return (
      <NoDataWrapper data={this.props.data}>
        <ResponsiveContainer width={this.props.width} height={this.props.height}>
          <LineChart
            data={this.props.data}
            margin={{ ...this.props.chartMargin }}
          >

            <CartesianGrid {...this.props.gridProps} />
            <XAxis dataKey="name" />
            <YAxis
              label={{ value: 'Overall Rating', angle: -90, position: 'insideLeft' }}
              dataKey="Overall Rating"
              domain={[0, 5]}
              ticks={[1,2,3,4,5]}
            />


            <Tooltip formatter={this.tooltipFormatter} />



            {
              [1,2,3,4,5].map(d => (
                <Line
                  dataKey={`${d}`}
                  stroke={ratingColors[d]}
                  strokeWidth={0}
                  dot={this.renderDot}
                  activeDot={this.renderDot}
                />
              ))
            }

            <Line
              type="monotone"
              dataKey="Overall Rating"
              stroke="#37caad"
              strokeWidth={2.5}
              dot={false}
            />

          </LineChart>
        </ResponsiveContainer>
      </NoDataWrapper>
    );
  }
}

CumulativeRatingChart.defaultProps = {
  width: '99%',
  height: 500,
  xAxisProps: {
    dataKey: 'name',
    interval: 'preserveStartEnd',
  },
  gridProps: {
    strokeDasharray: "0 0",
    stroke: 'whitesmoke',
    vertical: false
  },
  chartMargin: {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20
  },
}


export default CumulativeRatingChart;




const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        <p className="label">{ label }</p>
        <p className="overallRating">Overall Rating: { payload[0].value }</p>
        {
          payload.slice(1).filter(p => p.value > 0).map(p => (
            <p className="label">{`${p.value} x ${ p.name } New Reviews`}</p>
          ))
        }
      </div>
    );
  }

  return null;
};
import React from 'react';
import qs from 'qs';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { withStyles, Grid, TextField } from '@material-ui/core';

import { ChannelContext } from 'Channels/ChannelProvider';
import FetchedOpportunitiesTable from 'Opportunities/FetchedOpportunitiesTable';



class SitesOpportunities extends React.Component {
  constructor(props){
    super(props);

    const urlParams = qs.parse(
      ( window.location.search || '' ).replace(
        /^\?/,
        ''
      )
    );

    this.state = {
      query: urlParams && urlParams.query ? urlParams.query : null,
      idQuery: urlParams && urlParams.id ? urlParams.id : null
    };
  }

  render(){
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={9}/>
        {/* <Grid item xs={12} sm={3}>
          <TextField
            label="Filter Opportunities"
            value={ this.state.query || '' }
            onChange={e => this.setState({ query: e.target.value })}
            placeholder="Filter by author or content."
            fullWidth
          />
        </Grid> */}

        <Grid item xs={12}>
          <FetchedOpportunitiesTable
            getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
            rowsPerPageOptions={[10,25,50]}
            roleTypePath={'companies'}
            roleTypeId={this.props.company.id}
            channels={this.props.virtualChannels}
            showCompanyName={false}
            isSpamFiltered={true}
            query={this.state.query}
            idQuery={this.state.idQuery}
            opportunityTableTitle={'Virtual Work Requests'}
            viewOpportunityRoute={opportunity => `/virtual/jobs/opportunities/${ opportunity.id }`}
            showViewOpportunity={true}
          />
        </Grid>
      </Grid>
    );
  }

}

const styles = theme => ({
  progress: {
    theme:theme
  }
})

export default withContext(CompanyRoleContext, ChannelContext, withStyles(styles)(SitesOpportunities));
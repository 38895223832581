import React from 'react';

import { UserRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import CompanyNavigation from  './CompanyNavigation';
import OrganizationNavigation from  './OrganizationNavigation';

const appNavigation = props => {
  return props.currentUserRole.isCompanyRole() ? <CompanyNavigation {...props}/> : <OrganizationNavigation {...props}/>
};


export default withContext(UserRoleContext, appNavigation);
import React from 'react';
import Axios from 'axios';

import { log, withContext } from 'kn-react';
import { OrganizationRoleContext } from 'go-boost-library-react';

import { LinearProgress } from '@material-ui/core';

import SiteDesignModel from 'Sites/CompanySites/SiteDesignModel';



const DEFAULT_STATE = {
  loadingSiteDesigns: true,
  siteDesigns: [],
};


export const SiteDesignsContext = React.createContext(DEFAULT_STATE);


class SiteDesignsProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchSiteDesigns();
  }


  fetchSiteDesigns = () => {
    const { organization } = this.props;
    log('fetchSiteDesigns');
    return Axios.get(
        `/api/sites/site_designs/organizations/${organization.id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      )
      .then(response => {
        log('fetchSiteDesigns', response);
        const siteDesigns = response.data.site_designs.map(b => (
          SiteDesignModel.fromJSON(b)
        ))
        this.setState({ siteDesigns, loadingSiteDesigns: false });
      });
  }



  render(){
    const { loadingSiteDesigns } = this.state;

    if(loadingSiteDesigns) {
      return <LinearProgress/>;
    }

    return (
      <SiteDesignsContext.Provider
        value={{
          ...this.state
        }}
      >
        { this.props.children }
      </SiteDesignsContext.Provider>
    );
  }
}


export default withContext(OrganizationRoleContext, SiteDesignsProvider);
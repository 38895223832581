import React from 'react';
import ProductNotSetUp from 'ProductNotSetUp/ProductNotSetUp';

const virtualNotSetup = props => {
  return (
    <ProductNotSetUp
      displayText = 'You cannot access this page because Virtual has not been setup.'
    />
  )
}

export default virtualNotSetup

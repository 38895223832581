import React from 'react';
import { withContext } from 'kn-react';

import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import CompanyServiceArea from 'Settings/Companies/CompanyServiceArea/CompanyServiceArea';



const companyServiceArea = props => (
  <CompanyServiceArea
    isShutdown={props.companySitesProfile && props.companySitesProfile.isShutdown}
  />
);



export default withContext(
  CompanySitesProfileContext,
  companyServiceArea
);
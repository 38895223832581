import React from 'react';
import Axios from 'axios';
import classnames from 'classnames';
import Moment from 'moment';

import { PromiseButton, SnackbarContext, PrimaryAndSecondaryColors, PlanContext, Ability, CompanyRoleContext, OpportunityModel, Alert } from 'go-boost-library-react';
import { withContext, log, format } from 'kn-react';

import { Button, Grid, Typography, withStyles, Paper, IconButton, Tooltip, List, ListItem, ListItemText, ListItemSecondaryAction, TextField, Tabs, Tab, FormControlLabel, FormLabel, FormGroup, FormControl, Checkbox, FormHelperText } from '@material-ui/core';
import { Settings, Delete, OpenInNew, Email, CheckCircle, Warning } from '@material-ui/icons';

import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';
import EmbedVirtualMeetingRoomDialog from './EmbedVirtualMeetingRoomDialog';
import ImageUpload from 'ImageUpload/ImageUpload';
import MeetingRoomInvitation from './MeetingRoomInvitation';
import MeetingRoomModel from './MeetingRoomModel';
import MeetingModel from 'Virtual/CompanyVirtual/Meetings/MeetingModel'
import { ChannelContext } from 'Channels/ChannelProvider';
import VirtualMeetingRoomDialog, { ROOM_MODES } from './VirtualMeetingRoomDialog';


const NO_IMAGE = 0;
const IMAGE = 1;


const HOST = 'Host';
const PARTICIPANT = 'Host';
const GUEST = 'Guest';


const COLOR_OPTIONS = [
  'rgb(42,100,85)',
  'rgb(61,61,61)',
  'rgb(102,106,165)',
  'rgb(168,80,58)',
]

class VirtualSettings extends React.Component {
  state = {
    meetingRoomInvitationDefaultMessage: '',
    meetingRooms: [],
    isAddDialogOpen: false,
    isEmbedDialogOpen: false,
    isMeetingRoomInvitationDialogOpen: false,
    logoImageFile: null,
    backgroundImageFile: null,
    primaryColor: '',
    secondaryColor: '',
    activeMeetingRoom: '',
    backgroundColor: COLOR_OPTIONS[ 0 ],
    backgroundImageUrl: '',
    hasBackgroundImage: NO_IMAGE,
    dataAsOf: null,
    opportunityCount: 0,
    meetingDurationMinutes: 0,
  }


  componentDidMount = () => {
    const fetchMeetingRoomsCallback = () => this.fetchMeetingDuration(
      this.fetchOpportunityCount
    );

    return this.setState(
      {
        primaryColor: this.props.companyVirtualProfile.primaryColor || '',
        secondaryColor: this.props.companyVirtualProfile.secondaryColor || '',
        meetingRoomInvitationDefaultMessage: this.props.companyVirtualProfile.meetingRoomInvitationDefaultMessage || '',
        backgroundColor: this.props.companyVirtualProfile.backgroundColor || '',
        backgroundImageUrl: this.props.companyVirtualProfile.backgroundImageUrl || '',
        hasBackgroundImage: this.props.companyVirtualProfile.backgroundImageUrl ? IMAGE : NO_IMAGE,
        dataAsOf: new Date()
      },
      () => this.fetchMeetingRooms(
        fetchMeetingRoomsCallback
      )
    );
  }


  componentDidUpdate = oldProps => {
    if( oldProps !== this.props ){
      return this.setState({
        primaryColor: this.props.companyVirtualProfile.primaryColor || '',
        secondaryColor: this.props.companyVirtualProfile.secondaryColor || '',
        meetingRoomInvitationDefaultMessage: this.props.companyVirtualProfile.meetingRoomInvitationDefaultMessage || '',
      });
    }
  }


  fetchMeetingRooms = callback => {
    return Axios.get(
      `/api/virtual/meeting_rooms/companies/${ this.props.company.id }`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          is_active: true
        }
      }
    ).then(response => {
      const meetingRooms = response.data.meeting_rooms.map(
        m => MeetingRoomModel.fromJSON( m )
      );

      return this.setState(
        { meetingRooms },
        callback
      );
    });
  }



  usageStartDate = () => (
    Moment().startOf('month').format('YYYY-MM-DD hh:mm')
  )



  usageEndDate = () => (
    Moment().endOf('month').format('YYYY-MM-DD hh:mm')
  )



  fetchMeetingDuration = callback => {
    return Axios.get(
      `/api/virtual/meetings/companies/${ this.props.company.id }`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          start_date: this.usageStartDate(),
          end_date: this.usageEndDate()
        }
      }
    ).then(response => {
      const meetingDurationMilliseconds = response.data.meetings.map(
        m => MeetingModel.fromJSON( m )
      ).reduce(
        (a, b) => a + ( ( b.liveAnalysisErrorAt || b.endedAt || ( new Date() ) ) - b.createdAt ),
        0
      );

      const meetingDurationMinutes = ( meetingDurationMilliseconds / ( 1000 * 60 ) ).toFixed(2);

      return this.setState(
        { meetingDurationMinutes },
        callback
      );
    });
  }



  fetchOpportunityCount = () => {
    return Axios.get(
      `/api/core/opportunities/companies/${ this.props.company.id }`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          start_date: this.usageStartDate(),
          end_date: this.usageEndDate(),
          opportunity_types: ['form_entry'],
          channel_ids: this.props.virtualChannels.map(c => c.id),
          is_spam: false
        }
      }
    ).then(response => {
      const opportunities = response.data.opportunities.map(
        o => OpportunityModel.fromJSON( o )
      );

      return this.setState({
        opportunityCount: opportunities.length
      });
    });
  }


  onAddUpdateRoom = details => (
    this.state.activeMeetingRoom && this.state.activeMeetingRoom.id ?
      this.onUpdateRoom( details )
    :
      this.onAddRoom( details )
  )


  onAddRoom = details => {
    return Axios.post(
      '/api/virtual/meeting_rooms',
      {
        company_id: this.props.company.id,
        url: details.url,
        room_type: details.roomType,
        room_mode: details.roomMode,
        is_locked: details.isLocked,
        should_record: details.shouldRecord,
        is_virtual_transcript_included: details.isVirtualTranscriptIncluded,
        is_virtual_text_analysis_included: details.isVirtualTextAnalysisIncluded,
        is_virtual_video_analysis_included: details.isVirtualVideoAnalysisIncluded,
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const activeMeetingRoom = MeetingRoomModel.fromJSON(
        response.data.meeting_room
      );

      this.props.showSnackbar('Room successfully created.');


      return this.setState(
        {
          isAddDialogOpen: false,
          isEmbedDialogOpen: false,
          activeMeetingRoom
        },
        this.fetchMeetingRooms
      );
    });
  }


  onUpdateRoom = details => {
    if( !this.state.activeMeetingRoom || !this.state.activeMeetingRoom.id ){
      return Promise.reject();
    }


    return Axios.put(
      `/api/virtual/meeting_rooms/${ this.state.activeMeetingRoom.id }`,
      {
        is_virtual_transcript_included: details.isVirtualTranscriptIncluded,
        is_virtual_text_analysis_included: details.isVirtualTextAnalysisIncluded,
        is_virtual_video_analysis_included: details.isVirtualVideoAnalysisIncluded,
        is_locked: details.isLocked,
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const activeMeetingRoom = MeetingRoomModel.fromJSON(
        response.data.meeting_room
      );

      this.props.showSnackbar('Room successfully updated.');

      return this.setState(
        {
          isAddDialogOpen: false,
          isEmbedDialogOpen: false,
          activeMeetingRoom
        },
        this.fetchMeetingRooms
      );
    });
  }


  onDeleteRoom = meetingRoomId => {
    if( !window.confirm('Are you sure you want to delete this Meeting Room? If it is hosted on your GoBoost Site, it will automatically be removed.') ){
      return Promise.resolve(null);
    }


    return Axios.delete(
      `/api/virtual/meeting_rooms/${ meetingRoomId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(() => {
      this.props.showSnackbar('Room successfully deleted.');

      return this.fetchMeetingRooms();
    });
  }

  uploadLogoImage = () => {
    const { logoImageFile } = this.state;

    if( !logoImageFile ){
      return Promise.resolve(null);
    }

    return this.props.createMediaItem(logoImageFile).then(
      mediaItem => this.props.updateCompanyVirtualProfile({
        logoUrl: mediaItem.url
      })
    );
  }


  onChangeLogoImage = logoImageFile => {
    log('onChangeLogoImage', logoImageFile);
    this.setState({ logoImageFile });
  }


  onChangeBackgroundImage = (backgroundImageFile, backgroundImageUrl) => {
    log('onChangeBackgroundImage', backgroundImageFile);
    this.setState({ backgroundImageFile, backgroundImageUrl });
  }


  onUpdateColors = () => {
    const { primaryColor, secondaryColor } = this.state;
    if( !( primaryColor && secondaryColor ) ){
      return window.alert('Please select a primary and secondary color.');
    }


    return this.props.updateCompanyVirtualProfile({
      primaryColor: this.state.primaryColor,
      secondaryColor: this.state.secondaryColor,
    }).then(
      () => this.props.showSnackbar('Primary and secondary colors saved.')
    );
  }


  onUpdateBackground = () => {
    const { backgroundColor, hasBackgroundImage, backgroundImageFile } = this.state;
    if( hasBackgroundImage === IMAGE && !backgroundImageFile ){
      this.props.showSnackbar('Please select a background image.');

      return Promise.resolve();
    }


    if( !backgroundImageFile ){
      return this.props.updateCompanyVirtualProfile({
        backgroundColor,
        backgroundImageUrl: null
      }).then(
        () => this.props.showSnackbar('Meeting Room background color saved.')
      );
    }


    return this.props.createMediaItem(backgroundImageFile).then(
      mediaItem => this.props.updateCompanyVirtualProfile({
        backgroundImageUrl: mediaItem.url,
        backgroundColor: null
      })
    ).then(() => this.props.showSnackbar('Meeting Room background image successfully set.'));
  }


  viewOrCopyMeetingRoomUrlToClipboard = (hostUrl, roomUrl, urlType) => {
    let message;
    let beforeShowSnackbarFunction = () => Promise.resolve();
    if( this.canParseFromClipboard() ){
      message = `${ urlType } URL for the Meeting Room at ${ roomUrl } was successfully copied to your clipboard.`;
    } else {
      message = `The ${ urlType } URL for the Meeting Room is \n${ hostUrl }.\n`;
    }

    if( [HOST, PARTICIPANT].includes( urlType ) ){
      message += ` Use extreme caution when using this URL. Treat this URL like a password. Access to this URL will reveal your and your customers' data.`;
    }


    message = (
      <div className={this.props.classes.snackbarMessage}>
        { message }
      </div>
    );


    return beforeShowSnackbarFunction()
      .then(() => this.props.showSnackbar(
        message,
        { autoHide: false }
      ));
  }


  onUpdateDefaultMessage = () => {
    if( !this.state.meetingRoomInvitationDefaultMessage ){
      this.props.showSnackbar('Please enter a valid message.');
      return Promise.resolve();
    }

    return this.props.updateCompanyVirtualProfile({
      meetingRoomInvitationDefaultMessage: (
        this.state.meetingRoomInvitationDefaultMessage
      )
    }).then(
      () => this.props.showSnackbar('Default Meeting Room Invitation Message successfully updated.')
    );
  }



  constructBaseMeetingRoomUrl = meetingRoom => {
    let baseUrl = meetingRoom.url;

    if( !( baseUrl.startsWith('https://') || baseUrl.startsWith('http://') ) ){
      baseUrl = `https://${ baseUrl }`;
    }


    return baseUrl;
  }



  fetchMeetingRoom = meetingRoomId => {
    return Axios.get(
      `/api/virtual/meeting_rooms/${ meetingRoomId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const meetingRoom = MeetingRoomModel.fromJSON(
        response.data.meeting_room
      );


      return meetingRoom;
    });
  }




  onClickHostButton = meetingRoomId => {
    return this.fetchMeetingRoom(
      meetingRoomId
    ).then(meetingRoom => {
      const baseUrl = this.constructBaseMeetingRoomUrl(
        meetingRoom
      );


      const url = `${ baseUrl }?host=${ meetingRoom.hostKey }`;


      return this.viewOrCopyMeetingRoomUrlToClipboard(
        url,
        meetingRoom.url,
        HOST
      );
    });
  }



  onClickParticipantButton = meetingRoomId => {
    return this.fetchMeetingRoom(
      meetingRoomId
    ).then(meetingRoom => {
      const baseUrl = this.constructBaseMeetingRoomUrl(
        meetingRoom
      );


      const url = `${ baseUrl }?participant=${ meetingRoom.participantKey }`;


      return this.viewOrCopyMeetingRoomUrlToClipboard(
        url,
        meetingRoom.url,
        PARTICIPANT
      );
    });
  }



  onClickOpenInNew = (meetingRoom, canEdit) => {
    const baseUrl = this.constructBaseMeetingRoomUrl(
      meetingRoom
    );


    if( !canEdit ){
      return Promise.resolve().then(
        () => window.open(baseUrl, '_blank')
      );
    }


    return this.fetchMeetingRoom(
      meetingRoom.id
    ).then(meetingRoom => {
      const url = `${ baseUrl }?host=${ meetingRoom.hostKey }`;


      window.open(url, '_blank');
    })
  }



  onChangeIsGoBoostBrandingHidden = () => {
    return this.props.updateCompanyVirtualProfile({
      isGoBoostBrandingHidden: !this.props.companyVirtualProfile.isGoBoostBrandingHidden
    }).then(() => this.props.showSnackbar('GoBoost branding updated.'));
  }



  onChangeIsIdleMeetingWarningInactive = () => {
    return this.props.updateCompanyVirtualProfile({
      isIdleMeetingWarningInactive: !this.props.companyVirtualProfile.isIdleMeetingWarningInactive
    }).then(() => this.props.showSnackbar('Idle warning updated.'));
  }



  canParseFromClipboard = () => {
    if( !(navigator && navigator.clipboard && navigator.clipboard.readText ) ){
      return false;
    }

    return true;
  }




  render() {
    const { classes } = this.props;

    const isShutdown = this.props.companyVirtualProfile && this.props.companyVirtualProfile.isShutdown;
    const canEdit = (
      this.props.currentUserRole.roleHasAbility( Ability.EDIT_VIRTUAL_MEETING_ROOM ) &&
      !isShutdown
    );

    const renderColorPreview = () => (
      this.props.companyVirtualProfile ?
        <div
          className={classes.roomPreviewSquare}
          style={{
            backgroundImage: this.state.backgroundImageUrl ? `url(${ this.state.backgroundImageUrl })` : undefined,
            backgroundSize: this.state.backgroundImageUrl ? `cover` : undefined,
            backgroundColor: this.state.backgroundImageUrl ? undefined : this.state.backgroundColor
          }}
        >
          <div className={classes.roomPreviewLogo}>
            {
              this.props.companyVirtualProfile.logoUrl ?
                <img src={this.props.companyVirtualProfile.logoUrl} className={classes.roomPreviewLogoImg}/>
              :
                null
            }
          </div>

          <div className={classes.roomPreviewInnerSquare}>
            <Button
              fullWidth
              variant='contained'
              style={{
                backgroundColor: this.state.primaryColor,
                color: 'white'
              }}
            >
              Primary Color
            </Button>

            <Button
              fullWidth
              variant='outlined'
              style={{
                border: `2px solid ${ this.state.secondaryColor }`,
                marginTop: 5
              }}
            >
              Secondary Color
            </Button>
          </div>
        </div>
      :
        null
    );

    const monthlyVirtualWorkRequestCount = (
      this.props.subscribedVirtualPlan && this.props.subscribedVirtualPlan.areVirtualOpportunitiesLimited ?
        this.props.subscribedVirtualPlan.maxVirtualMonthlyOpportunities || 0
      :
        'Unlimited'
    );

    const hasExceededVirtualWorkRequests = (
      this.props.subscribedVirtualPlan &&
      this.props.subscribedVirtualPlan.areVirtualOpportunitiesLimited &&
      this.props.subscribedVirtualPlan.maxVirtualMonthlyOpportunities <= this.state.opportunityCount
    );

    const allowedMeetingMinutes = (
      this.props.subscribedVirtualPlan && this.props.subscribedVirtualPlan.isVirtualVideoDurationLimited ?
        `${ ( this.props.subscribedVirtualPlan.maxMonthlyVirtualVideoDurationSeconds || 0 ) / 60 } Monthly Meeting Minutes`
      :
        '120 Free Monthly Meeting Minutes. Additional time billed separately'
    );

    const hasExceededMeetingMinutes = (
      this.props.subscribedVirtualPlan &&
      this.props.subscribedVirtualPlan.isVirtualVideoDurationLimited &&
      ( ( this.props.subscribedVirtualPlan.maxMonthlyVirtualVideoDurationSeconds || 0 ) / 60 ) <= this.state.meetingDurationMinutes
    );

    const isGoBoostBrandingRequired = (
      this.props.subscribedVirtualPlan &&
      this.props.subscribedVirtualPlan.isVirtualBrandingRequired
    );

    const copyOrViewUrlText = (
      !this.canParseFromClipboard() ?
        'View'
      :
        'Copy To Clipboard'
    );

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h6">Virtual Settings</Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.usagePaper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Usage This Month
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                className={classnames(classes.usageGrid, classes.leftUsageGrid)}
              >
                <div className={classes.usageGridContent}>
                  <Typography variant='h3'>
                    { format.commas( this.state.opportunityCount || 0 ) }
                  </Typography>

                  <Typography variant='body1'>
                    Virtual Work Requests
                  </Typography>

                  {
                    !hasExceededVirtualWorkRequests ?
                      null
                    :
                      <Alert danger className={classes.warningAlert}>
                        <Warning className={classes.warningIcon}/>
                        &nbsp;
                        <Typography variant='caption'>
                          You have exceeded the monthly Virtual Work Request allowance.
                        </Typography>
                        <Typography variant='caption'>
                          Upgrade your plan to see all Virtual Work Requests. <a href="mailto:support@goboost.com">Contact Support for help.</a>
                        </Typography>
                      </Alert>
                  }
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                className={classes.usageGrid}
              >
                <div className={classes.usageGridContent}>
                  <Typography variant='h3'>
                    { format.commas( this.state.meetingDurationMinutes || 0 ) }
                  </Typography>

                  <Typography variant='body1'>
                    Meeting Minutes
                  </Typography>

                  {
                    !hasExceededMeetingMinutes ?
                      null
                    :
                      <Alert danger className={classes.warningAlert}>
                        <Warning className={classes.warningIcon}/>
                        &nbsp;
                        <Typography variant='caption'>
                          You have exceeded the monthly Meeting Minute allowance.
                        </Typography>
                        <Typography variant='caption'>
                          Upgrade your plan to host more Meetings. <a href="mailto:support@goboost.com">Contact Support for help.</a>
                        </Typography>
                      </Alert>
                  }
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.usageGridFooter}
              >
                <Typography
                  variant='caption'
                  className={classes.usageGridFooterTypography}
                >
                  As of: { this.state.dataAsOf ? format.dateTime( this.state.dataAsOf ) : '' }
                  &nbsp;
                  |
                  &nbsp;
                  { monthlyVirtualWorkRequestCount } Monthly Virtual Work Requests
                  &nbsp;
                  |
                  &nbsp;
                  { allowedMeetingMinutes }
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Meeting Room URLs</Typography>

            <List>
              {
                this.state.meetingRooms.map(
                  (m, i) => {
                    const url = this.constructBaseMeetingRoomUrl( m );

                    return (
                      <ListItem key={i}>
                        <ListItemText
                          primary={m.url}
                          secondary={
                            <>
                              <Typography
                                variant="caption"
                                className={classes.roomIsLocked}
                              >
                                { !m.shouldRecord ? 'Not' : '' } Recorded | { !m.isLocked ? 'Unlocked' : 'Locked' }
                              </Typography>

                              <Typography
                                variant="caption"
                              >
                                Size: { ROOM_MODES[ m.roomMode ] }
                              </Typography>

                              {
                                !canEdit ?
                                  null
                                :
                                  <Tooltip title={'Users of this URL can only take notes on the current meeting.'}>
                                    <Typography
                                      variant="caption"
                                      className={classes.hostUrl}
                                    >
                                      Editor URL:

                                      <PromiseButton
                                        buttonProps={{
                                          size: 'small',
                                          variant: 'contained',
                                          color: 'secondary',
                                          className: classes.viewOrCopyMeetingRoomUrlToClipboard,
                                        }}
                                        onProcess={() => this.onClickParticipantButton(m.id)}
                                      >
                                        { copyOrViewUrlText }
                                      </PromiseButton>
                                  </Typography>
                                </Tooltip>
                              }

                              {
                                !canEdit ?
                                  <Typography
                                    variant="caption"
                                    className={classes.hostUrl}
                                  >
                                    Viewer URL:

                                    <Button
                                      size={'small'}
                                      variant={'contained'}
                                      color={'secondary'}
                                      className={classes.viewOrCopyMeetingRoomUrlToClipboard}
                                      onClick={() => this.viewOrCopyMeetingRoomUrlToClipboard(url, m.url, GUEST)}
                                    >
                                      { copyOrViewUrlText }
                                    </Button>
                                  </Typography>
                                :
                                  <Tooltip title={'Users of this URL can take notes on the current meeting and view and take notes on all Virtual Work Requests.'}>
                                    <Typography
                                      variant="caption"
                                      className={classes.hostUrl}
                                    >
                                      Host URL:

                                      <PromiseButton
                                        buttonProps={{
                                          size: 'small',
                                          variant: 'contained',
                                          color: 'secondary',
                                          className: classes.viewOrCopyMeetingRoomUrlToClipboard,
                                        }}
                                        onProcess={() => this.onClickHostButton(m.id)}
                                      >
                                        { copyOrViewUrlText }
                                      </PromiseButton>
                                    </Typography>
                                  </Tooltip>
                              }
                            </>
                          }
                        />

                        <ListItemSecondaryAction>
                          <Tooltip title='Open Room As Host In New Tab'>
                            <PromiseButton
                              buttonProps={{
                                size: 'small'
                              }}
                              onProcess={() => this.onClickOpenInNew(m, canEdit)}
                            >
                              <OpenInNew />
                            </PromiseButton>
                          </Tooltip>

                          <Tooltip title='Invite Someone To This Room'>
                            <Button
                              size='small'
                              onClick={() => this.setState({ isMeetingRoomInvitationDialogOpen: true, activeMeetingRoom: m })}
                            >
                              <Email />
                            </Button>
                          </Tooltip>

                          <Tooltip title={`Edit Meeting Room's Settings`}>
                            <Button
                              size='small'
                              onClick={() => this.setState({ isAddDialogOpen: true, activeMeetingRoom: m })}
                            >
                              <Settings />
                            </Button>
                          </Tooltip>

                          {
                            !canEdit ?
                              null
                            :
                              <PromiseButton
                                onProcess={() => this.onDeleteRoom( m.id )}
                                buttonProps={{
                                  size: 'small'
                                }}
                              >
                                <Tooltip title='Delete Room'>
                                  <Delete />
                                </Tooltip>
                              </PromiseButton>
                          }
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  }
                )
              }
            </List>


            {
              !canEdit ?
                null
              :
                <Grid item xs={12} className={classes.addButtonGrid}>
                  <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={() => this.setState({ isAddDialogOpen: true, activeMeetingRoom: '' })}
                  >
                    Add Room
                  </Button>
                </Grid>
            }

            <VirtualMeetingRoomDialog
              open={this.state.isAddDialogOpen}
              onClose={() => this.setState({ isAddDialogOpen: false })}
              onAddRoom={this.onAddUpdateRoom}
              meetingRoom={this.state.activeMeetingRoom}
            />

            <EmbedVirtualMeetingRoomDialog
              open={this.state.isEmbedDialogOpen}
              onClose={() => this.setState({ isEmbedDialogOpen: false })}
              meetingRoom={this.state.activeMeetingRoom}
            />

            <MeetingRoomInvitation
              open={this.state.isMeetingRoomInvitationDialogOpen}
              onClose={() => this.setState({ isMeetingRoomInvitationDialogOpen: false })}
              meetingRoom={this.state.activeMeetingRoom}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} id='logo'>
          <Paper className={classes.paper}>
            <Typography variant="h6">Default Meeting Room Invitation Message</Typography>

            <TextField
              multiline
              fullWidth
              rows={2}
              onChange={e => this.setState({ meetingRoomInvitationDefaultMessage: e.target.value })}
              onBlur={e => this.setState({ meetingRoomInvitationDefaultMessage: e.target.value.trim() })}
              value={this.state.meetingRoomInvitationDefaultMessage || ''}
              variant={'outlined'}
              className={classes.meetingRoomInvitationDefaultMessage}
              helperText={
                <>
                  <Typography variant='caption'>
                    Your meeting room URL will automatically be added to the end of your message. &nbsp;
                    <span
                      style={{
                        color: ( this.state.meetingRoomInvitationDefaultMessage || '' ).length >= 160 ? 'red' : 'inherit'
                      }}
                    >
                      NOTE: messages over 160 characters may be split into multiple messages &nbsp;
                      (Current character count: { ( this.state.meetingRoomInvitationDefaultMessage || '' ).length })
                    </span>.
                  </Typography>
                </>
              }
            />

            <div className={classes.updateDefaultMessageDiv}>
              <PromiseButton
                onProcess={this.onUpdateDefaultMessage}
                buttonProps={{
                  variant: 'contained',
                  color: 'primary',
                  className: classes.updateDefaultMessage
                }}
              >
                Update
              </PromiseButton>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} id='meeting-preview'>
          <Paper className={classes.paper}>
            <Typography variant="h6">Meeting Room Preview</Typography>

            <Typography variant="caption">
              Your Meeting Room layout will look similar to the following preview
            </Typography>

            { renderColorPreview() }
          </Paper>
        </Grid>

        <Grid item xs={6} id='logo'>
          <Paper className={classes.paper}>
            <Typography variant="h6">Company Logo</Typography>

            <div style={{ textAlign: 'center' }}>
              <ImageUpload
                existingImageUrl={this.props.companyVirtualProfile.logoUrl}
                onChangeImage={this.onChangeLogoImage}
              />
              <Typography variant="caption">Supported Image Formats: JPG, PNG, GIF</Typography>
            </div>

            {
               canEdit && !isShutdown ?
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <PromiseButton
                      onProcess={this.uploadLogoImage}
                      buttonProps= {{
                        variant: 'contained',
                        color: 'primary',
                        type: 'submit',
                        id: 'update-logo',
                        name: 'update-logo',
                      }}
                    >
                      Update
                    </PromiseButton>
                  </Grid>
                :
                  null
            }

          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Meeting Room Background</Typography>

            <h4>Background Color</h4>

            <Grid container spacing={16}>
              {
                COLOR_OPTIONS.map(o => (
                  <Grid
                    item
                    xs={3}
                  >
                    <button
                      style={{ backgroundColor: o }}
                      className={classes.colorPalette}
                      onClick={e => this.setState({ backgroundColor: e.target.value })}
                      value={o}
                    >
                      {
                        this.state.backgroundColor === o ?
                          <CheckCircle className={classes.check} />
                        :
                          null
                      }
                    </button>
                  </Grid>
                ))
              }
            </Grid>

            <h4>Background Image</h4>

            <Tabs
              value={this.state.hasBackgroundImage}
              onChange={(e, hasBackgroundImage) => this.setState({ hasBackgroundImage })}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={'None'} disabled={Boolean(this.state.backgroundImageUrl)} />
              <Tab label={'Image'} />
            </Tabs>

            {
              !this.state.hasBackgroundImage ?
                null
              :
                <div style={{ textAlign: 'center' }}>
                  <ImageUpload
                    existingImageUrl={this.props.companyVirtualProfile.backgroundImageUrl}
                    onChangeImage={this.onChangeBackgroundImage}
                    inputId={'contained-background-image-button-file'}
                    maxFileSize={600000}
                  />

                  <Typography variant="caption">Supported Image Formats: JPG, PNG, GIF</Typography>
                  <Typography variant="caption">Max File Size: 600kb</Typography>

                  {
                    this.state.backgroundImageUrl && canEdit && !isShutdown ?
                      <Button
                        fullWidth
                        variant='contained'
                        color='secondary'
                        className={classes.removeButton}
                        onClick={() => this.setState({
                          backgroundImageUrl: null,
                          backgroundImageFile: null,
                          hasBackgroundImage: NO_IMAGE
                        })}
                      >
                        Remove
                      </Button>
                    :
                      null
                  }
                </div>
            }

            {
                canEdit && !isShutdown ?
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <PromiseButton
                      onProcess={this.onUpdateBackground}
                      buttonProps= {{
                        variant: 'contained',
                        color: 'primary',
                        type: 'submit',
                        id: 'update-logo',
                        name: 'update-logo',
                      }}
                    >
                      Update
                    </PromiseButton>
                  </Grid>
                :
                  null
            }
          </Paper>
        </Grid>


        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Company Colors</Typography>

            <PrimaryAndSecondaryColors
              logoUrl={this.props.companyVirtualProfile.logoUrl}
              uploadLogoUrl={'#logo'}
              title={' '}
              primaryColorSelectedColor={this.state.primaryColor}
              secondaryColorSelectedColor={this.state.secondaryColor}
              onChangePrimaryColor={primaryColor => this.setState({ primaryColor })}
              onChangeSecondaryColor={secondaryColor => this.setState({ secondaryColor })}
            />

            {
              canEdit && !isShutdown ?
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <PromiseButton
                    onProcess={this.onUpdateColors}
                    buttonProps= {{
                      variant: 'contained',
                      color: 'primary',
                      type: 'submit',
                      id: 'update-logo',
                      name: 'update-logo',
                    }}
                  >
                    Update
                  </PromiseButton>
                </Grid>
              :
                null
            }
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">
              General Virtual Settings
            </Typography>

            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">
                    Meeting Idle Warning
                  </FormLabel>

                  <FormGroup className={classes.formGroup}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color='primary'
                          checked={!this.props.companyVirtualProfile.isIdleMeetingWarningInactive}
                          onChange={this.onChangeIsIdleMeetingWarningInactive}
                        />
                      }
                      label={'End meeting when activity is not confirmed'}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">
                    GoBoost Branding
                  </FormLabel>

                  <FormGroup className={classes.formGroup}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color='primary'
                          checked={!this.props.companyVirtualProfile.isGoBoostBrandingHidden}
                          onChange={this.onChangeIsGoBoostBrandingHidden}
                          disabled={isGoBoostBrandingRequired}
                        />
                      }
                      label={'Display "Powered by GoBoost" text'}
                    />

                    {
                      !isGoBoostBrandingRequired ?
                        null
                      :
                        <FormHelperText>
                          GoBoost branding is required. Upgrade your plan to access this option.
                        </FormHelperText>
                    }
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}


VirtualSettings.defaultProps = {
}


const styles = theme => ({
  paper: {
    padding: 16,
  },
  roomPreviewSquare: {
    width: '50%',
    marginTop: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 500
  },
  roomPreviewInnerSquare: {
    width: '50%',
    height: 125,
    marginLeft: '25%',
    position: 'relative',
    top: 125,
    paddingTop: 50,
    backgroundColor: 'white',
    borderRadius: 50,
    paddingLeft: 15,
    paddingRight: 15,
  },
  roomPreviewLogo: {
    height: 50,
    paddingTop: 5,
    marginLeft: 5
  },
  roomPreviewLogoImg: {
    maxHeight: 50
  },
  hostUrl: {
    marginTop: 10
  },
  viewOrCopyMeetingRoomUrlToClipboard: {
    fontSize: 10,
    marginLeft: 5
  },
  meetingRoomInvitationDefaultMessage: {
    marginTop: 15
  },
  updateDefaultMessageDiv: {
    width: '100%',
    textAlign: 'right'
  },
  updateDefaultMessage: {
    marginTop: 5,
    // float: 'right'
  },
  roomIsLocked: {
    marginBottom: 5
  },
  colorPalette: {
    height: 100,
    padding: 10,
    width: '100%',
    border: '2px solid black',
    borderRadius: 8,
    cursor: 'pointer'
  },
  colorPaletteButton: {
    height: '100%',
    width: '100%',
  },
  check: {
    fontSize: 30,
    color: 'white',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  removeButton: {
    marginBottom: 15,
    marginTop: 10
  },
  usagePaper: {
    display: 'flex',
    padding: 20,

  },
  usageGrid: {
    padding: 5,
    display: 'flex',
    justifyContent: 'center'
  },
  leftUsageGrid: {
    borderRight: '1px solid lightgrey'
  },
  usageGridContent: {
    display: 'block',
    textAlign: 'center'
  },
  usageGridFooter: {
    marginTop: 25
  },
  usageGridFooterTypography: {
    color: 'grey'
  },
  warningIcon: {
    color: 'red'
  },
  warningAlert: {
    marginTop: 15
  },
  formControl: {
    margin: 15
  },
  formGroup: {
    marginLeft: 10
  },
  snackbarMessage: {
    wordBreak: 'break-word'
  }
});


export default withContext(
  CompanyVirtualProfileContext,
  CompanyRoleContext,
  MediaItemsContext,
  SnackbarContext,
  ChannelContext,
  PlanContext,
  withStyles(styles)(
    VirtualSettings
  )
);
import React from 'react';

import Axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';

import { SmallTablePagination, UserRoleContext, TableToolbar, Ability } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { withStyles, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Collapse, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import ActivityLogModel from './ActivityLogModel';


class ActivityLogList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      page: 0,
      limit: 10,
      activityLogs: [],
      loading: true,
      expandedActivityLogIds: []
    }
  }



  componentDidMount = () => {
    this.fetchActivityLogs()
  }



  fetchActivityLogs = () => {
    const { page, limit } = this.state;
    const {
      userRoleIds, companyIds, parentOrganizationId,
      categories, negatedCategories, names, negatedNames,
      includeDescendants
    } = this.props;

    Axios.get(
      '/api/core/activity_logs',
      {
        headers:this.props.getUserRoleAuthHeaders(),
        params: {
          user_role_ids: userRoleIds,
          company_ids: companyIds,
          parent_organization_id: parentOrganizationId || undefined,
          include_descendants: includeDescendants || undefined,
          categories,
          negated_categories: negatedCategories,
          names,
          negated_names: negatedNames,
          limit,
          page,
        }
      }
    )
    .then(response=>{
      const activityLogs = response.data.activity_logs.map(l=> {
        return (ActivityLogModel.fromJSON(l))
      })

      log('activityLogs',activityLogs);

      this.setState({ activityLogs, loading:false, total: response.data.total });
    })
  }



  changeRowsPerPage = (limit) => {
    this.setState(
      { limit },
      this.fetchActivityLogs
    );
  }



  changePage = (page) => {
    this.setState(
      { page },
      this.fetchActivityLogs
    );
  }


  toggleExpansionPanel = activityLogId => {
    const expandedActivityLogIds = [ ...this.state.expandedActivityLogIds ];

    if( !expandedActivityLogIds.includes( activityLogId ) ){
      expandedActivityLogIds.push( activityLogId );
    } else {
      expandedActivityLogIds.splice(
        expandedActivityLogIds.indexOf(activityLogId),
        1
      );
    }

    return this.setState({
      expandedActivityLogIds
    });
  }



  render(){
    const { loading } = this.state;
    const { classes } = this.props;

    return (
      <div className={ classes.root }>

        <Paper>
          <TableToolbar
            title="Activity"
            caption={
              this.props.tableToolbarCaption
            }
          />

          {
            (loading) ?
              <LinearProgress color="secondary" />
            :
              (!this.state.activityLogs || !this.state.activityLogs.length) ?
                <>
                  <Typography variant="caption" className={classes.noData}>No activity.</Typography>
                  <Divider/>
                </>
              :
                <List dense>
                  {
                    this.state.activityLogs.map((l, index)=>(
                      <React.Fragment key={l.id}>
                        <ListItem>
                          <ListItemText
                            disableTypography={true}
                            primary={
                              <Typography
                                variant='body2'
                                color='textPrimary'
                              >
                                { l.text }
                              </Typography>
                            }
                            secondary={
                              <>
                                {
                                  (this.props.showEntityName) ?
                                    <Typography
                                      variant='caption'
                                      color='textSecondary'
                                    >
                                      { l[ this.props.entityFieldName ] }
                                    </Typography>
                                  :
                                    null
                                }

                                <Typography
                                  variant='caption'
                                  color='textSecondary'
                                >
                                  { moment(l.createdAt).format('YYYY-M-DD, h:mm:ss a') }
                                </Typography>
                              </>

                            }
                            secondaryTypographyProps={{
                              variant: 'caption'
                            }}
                          />

                          {
                            this.props.currentUserRole.roleHasAbility(
                              Ability.VIEW_ACTIVITY_JSON
                            ) ?
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() => this.toggleExpansionPanel( l.id )}
                                >
                                  {
                                    this.state.expandedActivityLogIds.includes( l.id ) ?
                                      <ArrowDropUp />
                                    :
                                      <ArrowDropDown />
                                  }
                                </IconButton>
                              </ListItemSecondaryAction>
                            :
                              null
                          }
                        </ListItem>

                        <Collapse
                          in={this.state.expandedActivityLogIds.includes( l.id )}
                          style={
                            this.state.expandedActivityLogIds.includes( l.id ) ?
                              { padding: 15 }
                            :
                              {}
                          }
                          timeout='auto'
                        >
                          <Grid container spacing={40}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body1"
                              >
                                From JSON
                              </Typography>

                              <div className={classes.code}>
                                <pre className={classes.codePre}>
                                  {
                                    JSON.stringify(
                                      JSON.parse( l.dbToJson ),
                                      null,
                                      2
                                    )
                                  }
                                </pre>
                              </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body1"
                              >
                                To JSON
                              </Typography>

                              <div className={classes.code}>
                                <pre className={classes.codePre}>
                                  {
                                    JSON.stringify(
                                      JSON.parse( l.dbFromJson ),
                                      null,
                                      2
                                    )
                                  }
                                </pre>
                              </div>
                            </Grid>
                          </Grid>
                        </Collapse>

                        {
                          (index <= this.state.activityLogs.length-1) ?
                            <Divider/>
                          :
                            null
                        }

                      </React.Fragment>
                    ))
                  }
                </List>
          }

          <SmallTablePagination
            count={this.state.total || 0}
            rowsPerPage={this.state.limit}
            page={this.state.page}
            onChangeRowsPerPage={e => this.changeRowsPerPage(e.target.value)}
            onChangePage={(e, p) => this.changePage(p)}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </Paper>

      </div>
    );
  }
}

const styles = theme => ({
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
  root: {
    padding: 16
  },
  code: {
    backgroundColor: 'whitesmoke',
    padding: 10,
    borderRadius: 5
  },
  codePre: {
    whiteSpace: 'pre-wrap'
  }
})

ActivityLogList.defaultProps = {
  userRoleIds: [],
  companyIds: [],
  parentOrganizationId: null,
  includeDescendants: false,
  categories: [],
  negatedCategories: [],
  names: [],
  negatedNames: [],
  tableToolbarCaption: <></>,
  showEntityName: false,
  entityFieldName: '',
}

ActivityLogList.propTypes = {
  userRoleIds: PropTypes.array.isRequired,
  companyIds: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  negatedCategories: PropTypes.array.isRequired,
  names: PropTypes.array.isRequired,
  negatedNames: PropTypes.array.isRequired,
  tableToolbarCaption: PropTypes.node.isRequired,
};

export default withContext(
  UserRoleContext,
  withStyles(styles)(ActivityLogList)
);
import React from 'react';
import Axios from 'axios';
import Moment from 'moment';
import Link from 'react-router-dom/Link';

import { withContext, log, MaxWidth } from 'kn-react';
import { SnackbarContext, Alert, PromiseButton, UserRoleContext } from 'go-boost-library-react';

import { LinearProgress, Grid, Paper, withStyles, Typography, TextField, MenuItem, IconButton, Tooltip, Button } from '@material-ui/core';

import FeedItemPreview from 'Social/FeedItem/FeedItemPreview';

import FeedItemModel from 'Social/FeedItem/FeedItemModel';
import CompanyFeedItemModel from 'Social/CompanySocial/Feed/CompanyFeedItemModel';

import ReactionsDistribution from '../ReactionsDistribution';

import ImpressionsChart, { ImpressionsMetric } from 'Social/SocialCharts/ImpressionsChart';
import UniqueImpressionsChart, { UniqueImpressionsMetric } from 'Social/SocialCharts/UniqueImpressionsChart';
import ClicksChart, { ClicksMetric } from 'Social/SocialCharts/ClicksChart';
import LikesChart, { LikesMetric } from 'Social/SocialCharts/LikesChart';

import { createStatsChartData, createReactionDistribution, getStatsTotals } from 'Social/SocialDashboard/SocialAggregationHelpers';

import { KnPieChart } from 'go-boost-library-react';
import { OpenInNew } from '@material-ui/icons';


const timePeriodOptions = [
  { unit: 'month', label: 'All time' },
  { unit: 'day', numberOfUnits: 7, label: 'Past 7 days' },
  { unit: 'day', numberOfUnits: 30, label: 'Past 30 days' },
  { unit: 'day', numberOfUnits: 90, label: 'Past 90 days' },
  { unit: 'month', numberOfUnits: 12, label: 'Past year' },
];

class ViewFeedItem extends React.Component {
  state = {
    timePeriod: timePeriodOptions[0],
    feedItem: {},
    postAt: Moment(),
    companyFeedItem: {},
    statsChartData: [],
    statsTotals: {},
    reactions: {},
    loadingFeedItem: true,
    refreshingFeedItem: false,
    loadingMeta: false
  }


  componentDidMount = () => {
    return this.fetchFeedItem()
  }


  publishedAtFormat = () => {
    const { timePeriod } = this.state;

    return timePeriod.unit === 'month' ? '%Y-%m-01' : '%Y-%m-%d'
  }


  fetchFeedItem = () => {
    const fetchStatistics = this.props.currentUserRole.isCompanyRole() ?
        true
      :
        undefined
    ;

    return Axios.get(
      `/api/social/feed_items/${this.props.match.params.feedItemId}`,
      {
        params: {
          fetch_statistics: fetchStatistics,
          published_at_format: fetchStatistics || this.props.currentUserRole.isOrganizationRole() ?
              this.state.timePeriod.unit === 'month' ?'%Y-%m-01' : '%Y-%m-%d'
            :
              undefined
          ,
        },
        headers: this.props.getUserRoleAuthHeaders()
      }
    ).then(response => {
      const postAt = Moment( response.data.feed_item.post_at );
      const feedItem = FeedItemModel.fromJSON( response.data.feed_item.feed_content );

      feedItem.stats = response.data.statistics;

      // Chart Data
      const statsChartData = createStatsChartData(feedItem.stats, this.state.timePeriod);

      // Stats Data
      const statsTotals = getStatsTotals(feedItem.stats);

      // Reactions
      const reactions = createReactionDistribution(feedItem.stats);

      // Company Feed Item
      const companyFeedItem = (
        response.data.company_feed_item ?
          CompanyFeedItemModel.fromJSON( response.data.company_feed_item )
        :
          {}
      );

      return this.setState({
        feedItem,
        postAt,
        statsChartData,
        statsTotals,
        reactions,
        loadingFeedItem: false,
        refreshingFeedItem: false,
        companyFeedItem
      })
    })
  }


  onChangeTimePeriod = e => {
    return this.setState(
      {
        timePeriod: e.target.value,
        refreshingFeedItem: true,
      },
      this.fetchFeedItem
    )
  }


  onRepostFeedItem = () => {
    return Axios.post(
      `/api/social/feed_items/${ this.state.companyFeedItem.feedItemId }`,
      {
        company_id: this.state.companyFeedItem.companyId
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      this.fetchFeedItem
    ).then(() => this.props.showSnackbar('Reposting Feed Item. Please check back soon.'));
  }


  canRepostFeedItem = () => (
    this.state.companyFeedItem && this.state.companyFeedItem.id && !this.state.companyFeedItem.apiFeedId && this.state.postAt.add(10, 'minutes').isBefore( Moment() )
  )



  render() {
    const { classes } = this.props;

    if( this.state.loadingFeedItem ){
      return <LinearProgress />
    }

    const Preview = FeedItemPreview(this.state.feedItem.feedType);

    const apiFeedId = (
      this.state.companyFeedItem && this.state.companyFeedItem.apiFeedId ?
        this.state.companyFeedItem.apiFeedId.includes('_') ?
          this.state.companyFeedItem.apiFeedId.split('_')[1]
        :
          this.state.companyFeedItem.apiFeedId
      :
        null
    );

    return(
      <MaxWidth maxWidth={1200}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            {
              this.canRepostFeedItem() ?
                <Alert warning>
                  <Grid container>
                    {
                      this.props.hasFacebookAccountError ?
                        <>
                          <Grid item xs={12} sm={10} style={{ paddingTop: 7 }}>
                            <Typography variant='body1'>
                              There is an error with your Facebook Account Page. Please refresh your Page before trying to repost.
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Button
                              to='/social/settings'
                              component={Link}
                              variant='contained'
                              color='secondary'
                              fullWidth
                            >
                              Refresh
                            </Button>
                          </Grid>
                        </>
                      :
                        <>
                          <Grid item xs={12} sm={10} style={{ paddingTop: 7 }}>
                            <Typography variant='body1'>
                              There was an error posting this Feed Item.
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <PromiseButton
                              onProcess={this.onRepostFeedItem}
                            >
                              Repost
                            </PromiseButton>
                          </Grid>
                        </>
                    }

                  </Grid>
                </Alert>
              :
                null
            }
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h4">
              Feed Item for { Moment(this.state.feedItem.postAt).format('MMMM Do') }
            </Typography>

            {
              apiFeedId ?
                <IconButton
                  component={'a'}
                  href={`https://facebook.com/${ apiFeedId }`}
                  target='_blank'
                >
                  <Tooltip title='View Feed Item'>
                    <OpenInNew />
                  </Tooltip>
                </IconButton>
              :
                null
            }
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              value={ this.state.timePeriod }
              onChange={this.onChangeTimePeriod}
              label={'Time Period'}
              style={{ float: 'right' }}
            >
              {
                timePeriodOptions.map((t,i) => (
                  <MenuItem value={t} key={i}>{ t.label }</MenuItem>
                ))
              }
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={8} style={{ opacity: this.state.refreshingFeedItem ? 0.5 : 1 }}>

          <Grid item xs={12} sm={6}>
            <Preview
              text={this.state.feedItem.text}
              url={this.state.feedItem.url}
              meta={this.state.feedItem.linkMeta}
              loading={this.state.loadingMeta}
              attachments={this.state.feedItem.attachments}
              feedType={this.state.feedItem.feedType}
              stats={this.state.feedItem.stats}
            />
          </Grid>

          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.metricPaper}>
                <ImpressionsMetric
                  statsTotals={this.state.statsTotals}
                  statsChange={this.state.statsChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className={classes.metricPaper}>
                <UniqueImpressionsMetric
                  statsTotals={this.state.statsTotals}
                  statsChange={this.state.statsChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className={classes.metricPaper}>
                <ClicksMetric
                  statsTotals={this.state.statsTotals}
                  statsChange={this.state.statsChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className={classes.metricPaper}>
                <LikesMetric
                  statsTotals={this.state.statsTotals}
                  statsChange={this.state.statsChange}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper className={this.props.classes.dashboardItem}>
              <ReactionsDistribution
                reactions={this.state.reactions}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={this.props.classes.dashboardItem}>
              <Typography variant="h6">
                Impressions
              </Typography>

              <ImpressionsChart statsChartData={this.state.statsChartData} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={this.props.classes.dashboardItem}>
              <Typography variant="h6">
                Unique Impressions
              </Typography>

              <UniqueImpressionsChart statsChartData={this.state.statsChartData} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={this.props.classes.dashboardItem}>
              <Typography variant="h6">
                Clicks
              </Typography>

              <ClicksChart statsChartData={this.state.statsChartData} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={this.props.classes.dashboardItem}>
              <Typography variant="h6">
                Likes
              </Typography>

              <LikesChart statsChartData={this.state.statsChartData} />
            </Paper>
          </Grid>

        </Grid>
      </MaxWidth>
    )
  }
}


const styles = theme => ({
  dashboardItem: {
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
  },
  metricPaper: {
    margin: 15
  }
});


ViewFeedItem.defaultProps = {
  hasFacebookAccountError: false
}


export default withContext(
  UserRoleContext,
  SnackbarContext,
  withStyles(styles)(ViewFeedItem)
)
import React from 'react';
import ProductNotSetUp from 'ProductNotSetUp/ProductNotSetUp';

const adsNotSetup = props => {
  return (
    <ProductNotSetUp
      displayText = 'You cannot access this page because Ads has not been setup.'
    />
  )
}

export default adsNotSetup

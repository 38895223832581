import React from 'react';

import { Typography, withStyles } from '@material-ui/core';
import { log, withContext, format } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';


class DataCurrentAsOf extends React.Component {
  render() {
    const { classes, companyReviewsProfile } = this.props;


    if(!companyReviewsProfile){
      return(null);
    }
    else{
      const importsCompletedAt = [
        'facebookImporterLastCompletedAt',
        'googleImporterLastCompletedAt',
        'reviewTrackersImporterLastCompletedAt'
      ]
      var dateList = [];

      //For each review importer, add its most recent date as a Date object to dateList if it exists
      importsCompletedAt.forEach(i => (companyReviewsProfile[i]) ?
          dateList.push(new Date(companyReviewsProfile[i]))
        :
          null
      )

      if( !dateList.length ) {
        return <></>; // return an empty node if there are no imports for this profile
      }

      //Get the minimum value of the existing dates, and convert it back to a Date object, and into a formatted string
      const minDate = format.dateTime(new Date(Math.min.apply(null,dateList)),true);

      return(
        <Typography variant="caption" color="textSecondary">
          Data as of { minDate }
        </Typography>
      )
    }
  }
}




const styles = theme => ({
  paper: {
    padding: 16
  }
})

DataCurrentAsOf.defaultProps = {
  classes: '',
  companyReviewsProfile: '',
}

export default withContext(
  UserRoleContext,
  CompanyReviewsProfileContext,
  withStyles(styles)(
    DataCurrentAsOf
  )
);
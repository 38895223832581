import React from 'react';
import { MaxWidth } from 'kn-react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const productCard = props => {
  const { classes } = props;
  const Icon = props.icon;

  return (
    <MaxWidth maxWidth={300}>
      <Paper className={classes.paperRoot}>
        <div style={{ backgroundImage: `url(${props.backgroundImage})` }} className={classes.background} />
        <div className={classes.wrapper}>
          <div style={{ color: props.colors.primary }}>
            <Icon className={classes.icon} />
          </div>
          {props.children}
        </div>
      </Paper>
    </MaxWidth>
  );
}


const styles = theme => ({
  paperRoot: {
    position: 'relative',
    textAlign: 'center',
    paddingTop: 25,
    paddingRight: 15,
    paddingBottom: 25,
    paddingLeft: 15,
    minHeight: 250,
  },
  background: {
    zIndex: 1,
    opacity: 0.1,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  wrapper: {
    position: 'relative',
    zIndex: 2
  },
  icon: {
    fontSize: 80,
    padding: 10,
  },
})

export default withStyles(styles)(productCard);
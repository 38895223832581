import { Button, Grid, Typography, withStyles, IconButton, Tooltip } from '@material-ui/core';
import { MaxWidth, withContext } from 'kn-react';
import Axios from 'axios';
import React from 'react';
import { Paper } from '@material-ui/core';
import VirtualFormDesignList from 'Virtual/VirtualFormDesign/VirtualFormDesignList/VirtualFormDesignList';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import VirtualFormDesignModel from 'Virtual/VirtualFormDesign/VirtualFormDesignModel';
import { VirtualFormDesignSetupContext } from './VirtualFormDesignSetupProvider'
import VirtualUndoResubscription from 'Virtual/CompanyVirtual/VirtualUndoResubscription/VirtualUndoResubscription';


class VirtualFormDesignSetup extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      selectedVirtualFormDesignIds: [
        ...this.props.selectedVirtualFormDesignIds
      ],
      virtualFormDesigns: []
    }
  }

  componentDidMount = () => {
    return this.fetchVirtualFormDesignsForPlan()
  }

  componentDidUpdate = oldProps => {
    if( oldProps.selectedPlanId !== this.props.selectedPlanId ){
      return this.fetchVirtualFormDesignsForPlan()
    }
  }


  fetchVirtualFormDesignsForPlan = () => {
    const virtualFormDesignIds = this.props.planVirtualFormDesigns.filter(
      p => p.planId === this.props.selectedPlanId
    ).map(p => p.virtualFormDesignId);

    const virtualFormDesigns = this.props.virtualFormDesigns.filter(
      v => virtualFormDesignIds.includes( v.id )
    );


    return this.setState({
      virtualFormDesigns
    });
  }

  onSelectDesign = design => {
    const selectedVirtualFormDesignIds = [
      ...this.state.selectedVirtualFormDesignIds
    ];


    if( selectedVirtualFormDesignIds.includes( design.id ) ){
      selectedVirtualFormDesignIds.splice(
        selectedVirtualFormDesignIds.indexOf( design.id ),
        1
      );
    } else {
      selectedVirtualFormDesignIds.push(
        design.id
      );
    }


    return this.setState({
      selectedVirtualFormDesignIds
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <MaxWidth maxWidth={1200}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <VirtualUndoResubscription />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Virtual Work Request Forms</Typography>
            </Grid>

            {/* <CancelSetupButton /> */}

            <Grid item xs={12}>
              <VirtualFormDesignList
                virtualFormDesigns={this.state.virtualFormDesigns}
                selectedVirtualFormDesignIds={this.state.selectedVirtualFormDesignIds}
                onSelectDesign={this.onSelectDesign}
              />
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => this.props.transitionTo('back', this.props.transitions)}
                >
                  Back
                </Button>
              </Grid>

              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.transitionTo('next', this.props.transitions, { selectedVirtualFormDesignIds: this.state.selectedVirtualFormDesignIds })}
                  disabled={!this.state.selectedVirtualFormDesignIds || !this.state.selectedVirtualFormDesignIds.length}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </MaxWidth>
    );
  }
}


VirtualFormDesignSetup.defaultProps = {
  selectedVirtualFormDesignIds: []
}


const styles = theme => ({
  paperRoot: {
    padding: 16,
  },
  add: {
    textAlign: 'right'
  }
});

export default withContext(
  VirtualFormDesignSetupContext,
  TransitionContext,
  withStyles(styles)(VirtualFormDesignSetup)
);
import React from 'react';

import { log, withContext } from 'kn-react';
import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import history from 'history.js';


class CampaignTemplate extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      facebookCampaignTemplate: {},
      facebookCampaignTemplateCounts: {},
    };
  }

  componentDidMount = () => {
    this.load();
  }


  load = () => {
    log(this.props);
    const id = this.props.match.params.id;
    log('facebookCampaignTemplateId', id);
    Axios.get(
      `/api/ads/facebook_templates/campaigns/${ id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.setState({
        facebookCampaignTemplate: response.data.facebook_campaign_template,
        facebookCampaignTemplateCounts: response.data.facebook_campaign_template_counts,
      });
    });
  }


  onToggle = () => {
    if(!window.confirm('Are you sure? Enabling/disabling this template will allow it to be deployed when users sign up or update their settings.')) {
      return;
    }

    const id = this.props.match.params.id;
    const { facebookCampaignTemplate } = this.state;

    Axios.post(
      `/api/ads/facebook_templates/campaigns/${id}/toggle`,
      { is_enabled: !facebookCampaignTemplate.is_enabled },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.props.showSnackbar('Campaign Template updated.');
      this.setState({ facebookCampaignTemplate: response.data.facebook_campaign_template });
    });
  }


  onMigrate = () => {
    if( !this.state.facebookCampaignTemplate.is_enabled ){
      return this.props.showSnackbar('Campaign template must be enabled to migrate.')
    }

    if(!window.confirm('Are you sure? Migrating this campaign will use the latest version (which may not be this template) and will update all settings for campaigns that use this template.')) {
      return;
    }

    return Axios.post(
      `/api/ads/facebook_ads_migrations/boosted_services/${ this.state.facebookCampaignTemplate.boosted_service_id }/migrate`,
      {  },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.props.showSnackbar('Campaign Template migration enqueued.');
    });
  }

  onDelete = () => {
    if(!window.confirm('Are you sure? Deleting the template will delete all of dependencies.')) {
      return;
    }

    const id = this.props.match.params.id;

    Axios.delete(
      `/api/ads/facebook_templates/campaigns/${id}/delete`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.props.showSnackbar('Campaign Template deleted.');
      history.push('/ads/facebook_templates')
    });
  }

  captionAndTitle = (caption, title) => {
    return (
      <div key={caption} style={{ padding: 15 }}>
        <Typography variant="caption">{ caption }</Typography>
        <Typography variant="h6">
          { title }
        </Typography>
      </div>
    );
  }


  render(){
    const {
      facebookCampaignTemplate,
      facebookCampaignTemplateCounts,
    } = this.state;

    return (
      <div style={{ padding: 15 }}>

        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={this.onToggle}
            variant="contained"
            style={{ marginRight: 15 }}
            color={ facebookCampaignTemplate.is_enabled ? 'default' : 'primary' }
          >
            { facebookCampaignTemplate.is_enabled ? 'Disable' : 'Enable' }
          </Button>

          <Button onClick={this.onMigrate} style={{ marginRight: 15 }} variant="outlined" color="primary">Migrate</Button>

          <Button onClick={this.onDelete} variant="contained" color="secondary">Delete</Button>
        </div>


        <Typography variant="headline">Campaign Template</Typography>

        {
          Object.keys(facebookCampaignTemplate).map(k => (
            this.captionAndTitle( k, (facebookCampaignTemplate[k] || '').toString() ))
          )
        }

        {
          Object.keys(facebookCampaignTemplateCounts).map(k => (
            this.captionAndTitle( k, (facebookCampaignTemplateCounts[k] || '').toString() ))
          )
        }

      </div>
    );
  }

}


export default withContext(UserRoleContext, SnackbarContext, CampaignTemplate);
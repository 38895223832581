import React from 'react';
import { withContext } from 'kn-react';

import { SnackbarContext } from 'go-boost-library-react';

import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';

import { Grid, Typography, withStyles, TextField, MenuItem, Tooltip, IconButton } from '@material-ui/core';
import { Add, Delete, LibraryAdd } from '@material-ui/icons';
import NewMediaItemDialog from 'Social/MediaItems/NewMediaItemDialog';


class PhotoFeedItemForm extends React.Component {
  state = {
    text: '',
    attachments: [],
    mediaItemDialogOpen: false
  }


  initializeState = () => {
    this.setState({
      text: this.props.feedItem.text || '',
      attachments: this.props.feedItem.attachments || []
    });
  }


  componentDidMount = () => {
    return this.initializeState()
  }



  componentDidUpdate = oldProps => {
    if( this.props.feedItem !== oldProps.feedItem ){
      return this.initializeState()
    }
  }



  onBlurText = e => {
    this.setState(
      { text: e.target.value.trim() },
      this.onBlur
    )
  }


  onBlur = () => {
    return this.props.onBlur(
      this.state.text,
      this.state.attachments
    )
  }


  updateAttachments = ( value, attachmentIndex ) => {
    const attachments = [ ...this.state.attachments ];

    if( value ){
      attachments.splice(
        attachmentIndex,
        1,
        value
      );
    } else {
      attachments.splice(
        attachmentIndex,
        1,
      );
    }

    this.setState(
      { attachments },
      this.onBlur
    );
  }


  addAttachment = () => {
    if( this.state.attachments.length === 10 ){
      return this.props.showSnackbar('Facebook allows a maximum of 10 photos for a Photo Feed.')
    }

    this.setState({ attachments: [ ...this.state.attachments, {} ] })
  }



  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label={'Feed Content'}
            value={this.state.text}
            onChange={e => this.setState({ text: e.target.value })}
            onBlur={this.onBlurText}
            variant="outlined"
            fullWidth
            multiline
            rows={3}
          />
        </Grid>

        {
          this.state.attachments.map(
            (a, i) => (
              <>
                <Grid item xs={10}>
                  <TextField
                    label={`Attachment ${i+1}`}
                    value={a}
                    onChange={e => this.updateAttachments(e.target.value, i)}
                    onBlur={this.onBlur}
                    variant="outlined"
                    fullWidth
                    select
                    className={classes.mediaItemTextField}
                    key={i}
                  >
                    {
                      this.props.mediaItems.filter(
                        m => m.mimeType.includes('image/')
                      ).map(
                        (m, i) => (
                        <MenuItem value={m.id} key={i}>{m.name}</MenuItem>
                        )
                      )
                    }
                  </TextField>
                </Grid>

                <Grid item xs={2}>
                  <Tooltip title={'Remove Attachment'}>
                    <IconButton
                      className={classes.deleteButton}
                      onClick={() => this.updateAttachments(null, i)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            )
          )
        }

        <Grid item xs={12}>
          <Tooltip title={'Add Attachment'}>
            <IconButton
              onClick={this.addAttachment}
            >
              <Add />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Upload Media Item'}>
            <IconButton
              onClick={() => this.setState({ mediaItemDialogOpen: true })}
            >
              <LibraryAdd />
            </IconButton>
          </Tooltip>

          <NewMediaItemDialog
            open={this.state.mediaItemDialogOpen}
            onClose={() => this.setState({ mediaItemDialogOpen: false })}
          />
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    padding: 16,
  },
  mediaItemTextField: {
    marginTop: 15
  },
  deleteButton: {
    marginTop: 15,
    marginLeft: 5
  }
});


PhotoFeedItemForm.defaultProps = {
  feedItem: { text: '' },
  onBlur: () => {}
}

export default withContext(
  MediaItemsContext,
  SnackbarContext,
  withStyles(styles)(PhotoFeedItemForm)
)
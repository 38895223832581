import React from 'react';
import { Link } from 'react-router-dom';

import { ServicesColors, ReviewsStarIcon, UserRoleContext, Ability } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Dashboard, PersonAdd, Send } from '@material-ui/icons';
import { Typography, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ProductCard from '../ProductCard/ProductCard';

const colors = ServicesColors.reviews;

const goToReviews = props => {
  const { classes } = props;
  return (
    <ProductCard colors={colors} icon={ReviewsStarIcon} backgroundImage="/images/reviews_background.png">

      <Typography paragraph variant="h6">
        Reviews
      </Typography>

      <Button
        fullWidth
        variant="contained"
        component={Link}
        to="/reviews"
        className={classes.containedButton}
        style={{ backgroundColor: colors.primary, color: colors.contrastText }}
      >
        <Dashboard className={classes.leftIcon}/>
        Dashboard
      </Button>

      {
        props.currentUserRole.roleHasAbility( Ability.EDIT_REVIEWS_REVIEW_REQUESTS ) ?
          <Button
            fullWidth
            variant="outlined"
            component={Link}
            to="/reviews/review_requests/new"
            className={classes.outlinedButton}
            style={{ borderColor: colors.primary, color: colors.primary }}
          >
            <Send className={classes.leftIcon}/>
            New Review Request
          </Button>
        : null
      }

      {/* {
        props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_USERS ) ?
          <Button
            fullWidth
            variant="outlined"
            component={Link}
            to="/users/user_invitations/new"
            className={classes.outlinedButton}
            style={{ borderColor: colors.primary, color: colors.primary }}
          >
            <PersonAdd className={classes.leftIcon}/>
            New User
          </Button>
        : null
      } */}


    </ProductCard>
  );
}


const styles = theme => ({
  containedButton: {
    color: 'white',
    backgroundColor: '#FD9A51',
    '&:hover': {
      backgroundColor: '#FD9A51',
    },
    marginBottom: 15
  },
  outlinedButton: {
    borderColor: '#FD9A51',
    '&:hover': {
      borderColor: '#FD9A51',
    },
    marginBottom: 15
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default withStyles(styles)(
  withContext(UserRoleContext, goToReviews)
);
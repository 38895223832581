import Axios from 'axios';
import { log } from 'kn-react';
import history from './history';

Axios.interceptors.response.use(undefined, error => {

  return new Promise((resolve, reject) => {
    if( !error.response ){
      return reject(error);
    }

    const errorState = {
      errorStatus: error.response.status,
      errorMessage: error.response.message,
    }

    const isSignIn = window.location.pathname.includes('sign_in');

    if(error.response.status === 401 && !isSignIn){
      history.push('/sign_out');
      return reject(error);
    }

    if(error.response.status === 403){
      // history.push({
      //   pathname: '/sign_out',
      //   state: errorState
      // });

      // return reject(error);
      log( error );
    }

    if(error.response.status === 503){
      window.location.href = `/`;

      return reject(error);
    }

    throw error;

  });

});
import React from 'react';

import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@material-ui/core';
import AreaMap from './AreaMap';


const areaMapDialog = props => {
  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      maxWidth='lg'
    >
      <DialogTitle>
        Estimated Pavement Area
      </DialogTitle>

      <DialogContent>
        <AreaMap
          latLng={props.latLng}
          polygon={props.polygon}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant='outlined'
          color='primary'
          onClick={props.onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}


areaMapDialog.defaultProps = {
  latLng: {},
  polygon: {},
  open: false,
  onClose: () => {}
};


export default areaMapDialog;
import React from 'react';
import { withContext } from 'kn-react';
import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import ProductUndoResubscriptionAlert from 'CompanyRole/productUndoResubscriptionAlert';


const virtualUndoResubscriptionAlert = props => (
  <ProductUndoResubscriptionAlert
    isResubscribing={(
      props.companyVirtualProfile &&
      props.companyVirtualProfile.isShutdown &&
      !props.companyVirtualProfile.isSetupComplete
    )}
    undoText={'You are resubscribing to Virtual.'}
    onProcess={props.undoResubscriptionToVirtual}
  />
);


export default withContext(
  CompanyVirtualProfileContext,
  virtualUndoResubscriptionAlert
);
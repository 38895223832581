import React from 'react';

import { SubNavigation } from 'go-boost-library-react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DashboardIcon from '@material-ui/icons/Dashboard';
import CallIcon from '@material-ui/icons/Call';
import Brush from '@material-ui/icons/Brush';

import AppNavigation from '../../AppNavigation/AppNavigation';
import SitesDashboard from './SitesDashboard/SitesDashboard';
import SitesOpportunities from './SitesOpportunities/SitesOpportunities';
import SiteDesigns from './SiteDesigns/SiteDesigns';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';


class OrganizationSites extends React.Component {

  render(){
    var subNavLinks = [
      { primaryText: 'Dashboard', name:'dashboard', path: '/sites', exactPathMatch: true, icon: <DashboardIcon/> },
      { primaryText: 'Opportunities', name:'opportunities', path: '/sites/opportunities', icon: <CallIcon/> },
      { primaryText: 'Site Designs', name:'site_designs', path: '/sites/site_designs', icon: <Brush/> },
    ];

    return (
      <AppNavigation title="Sites">
        <Switch>
          <Route path="/sites">
            <SubNavigation links={subNavLinks}>
              <KnowledgeBaseLaunchBanner />
              <Switch>
                <Route exact path="/sites" component={SitesDashboard}/>
                <Route exact path="/sites/opportunities" component={SitesOpportunities}/>
                <Route exact path="/sites/site_designs" component={SiteDesigns}/>
                <Route path="*" component={props => <Redirect to='/'/>} />
              </Switch>
            </SubNavigation>
          </Route>
        </Switch>
      </AppNavigation>
    );
  }
}


export default OrganizationSites
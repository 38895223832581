import React from 'react';

import { withContext } from 'kn-react';

import { ChannelContext } from 'Channels/ChannelProvider';
import { VirtualFormDesignContext } from 'Virtual/CompanyVirtual/VirtualFormDesigns/VirtualFormDesignProvider';
import Jobs from 'Jobs/Jobs';



const companyJobs = props => (
  <Jobs
    showGrade={true}
    showAddress={true}
    channelIds={props.virtualChannels.map(c => c.id)}
    viewJobBaseUrl={'/virtual/jobs/opportunities'}
    companyVirtualFormDesigns={props.companyVirtualFormDesigns}
    opportunityName={'Virtual Work Requests'}
  />
)



export default withContext(
  VirtualFormDesignContext,
  ChannelContext,
  companyJobs
);
import React from 'react';

import { format, withContext } from 'kn-react';
import { PlanContext } from 'go-boost-library-react';

import { ListItemSecondaryAction, Divider, Link, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';

import CostRow from 'SharedSetup/costRow';
import CouponCheckoutRow from 'SharedSetup/CouponCode/CouponCheckoutRow';
import creditCardFeePercent from 'Ads/CompanyAds/creditCardFeePercent';



class TodaysSummary extends React.Component {
  state = {
    showDetails: false,
  }

  showDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  }


  computeTodaysBaseTotalCents = () => {
    const { selectedPlan, coupon } = this.props;
    const isSubscribedToSelectedPlan = this.props.isSubscribedToPlanId(selectedPlan.id);

    if( selectedPlan.trialPeriodDays ) {
      return isSubscribedToSelectedPlan ? 0 : selectedPlan.setupFeeCents || 0;
    }

    let todaysTotalCents = isSubscribedToSelectedPlan ? 0 : ( selectedPlan.planAmountCents + selectedPlan.setupFeeCents );

    // Coupons are only applied to subscriptions when they are created
    if( selectedPlan.planAmountCents && !isSubscribedToSelectedPlan ){
      todaysTotalCents -= coupon ? coupon.getDiscountCentsWithPlan(selectedPlan) : 0;
    }

    return todaysTotalCents;
  }



  render() {
    const { selectedPlan, companyBoostedServicesInPlan, boostedServices, coupon } = this.props;
    const isSubscribedToSelectedPlan = this.props.isSubscribedToPlanId(selectedPlan.id);

    let todaysTotalCents = this.computeTodaysBaseTotalCents();


    return (
      <List>

        {
          isSubscribedToSelectedPlan ?
            <ListItem>
              <ListItemText
                primary={`${selectedPlan.name} Plan`}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  style={{ textAlign: 'right' }}
                  primary="Subscribed"
                />
              </ListItemSecondaryAction>
            </ListItem>
          :

          <ListItem>
          <ListItemText
            primary={`${selectedPlan.name} Plan`}
            secondary={`${ selectedPlan.capitalizedBillingPeriod() } Recurring Subscription${ selectedPlan.trialPeriodDays ? `, ${selectedPlan.trialPeriodDays} day free trial` : ''}`}
          />
          <ListItemSecondaryAction>
            <ListItemText
              style={{ textAlign: 'right' }}
              primary={format.money(selectedPlan.planAmountCents / 100)}
            />
          </ListItemSecondaryAction>
        </ListItem>
        }



        { coupon ? <CouponCheckoutRow coupon={coupon} selectedPlan={selectedPlan} onClickRemove={this.props.onClickRemoveCoupon} /> : null }


        {
          !isSubscribedToSelectedPlan ?
              <ListItem>
                <ListItemText
                  primary="Setup Fee"
                />
                <ListItemSecondaryAction>
                  <ListItemText
                    style={{ textAlign: 'right' }}
                    primary={format.money(selectedPlan.setupFeeCents / 100)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            :
              null
        }


        {
          companyBoostedServicesInPlan.map(c => {
            if (!c.initialBoostTotalCents) {
              return null;
            }

            todaysTotalCents += c.initialBoostTotalCents;
            const b = boostedServices.find(b => b.id === c.boostedServiceId);
            const creditCardFeeCents = creditCardFeePercent * c.initialBoostTotalCents;
            const managementFeeCents = selectedPlan.adsManagementFeePercent * c.initialBoostTotalCents;

            const boostAmountCents = c.initialBoostTotalCents - creditCardFeeCents - managementFeeCents;
            return (
              <div key={c.id}>
                <ListSubheader color="primary">{b.name}</ListSubheader>

                <CostRow
                  leftPrimary="Boost Amount"
                  rightPrimary={format.money(boostAmountCents / 100)}
                />

                <CostRow
                  leftPrimary="Program Management and Media Placement"
                  leftSecondary={format.percent(selectedPlan.adsManagementFeePercent + creditCardFeePercent)}
                  rightPrimary={format.money((managementFeeCents + creditCardFeeCents) / 100)}
                  rightSecondary={
                    <Link component="button" onClick={this.showDetails}>Details</Link>
                  }
                  rightSecondaryTypographyProps={{
                    variant: 'caption'
                  }}
                />

                {
                  this.state.showDetails ?
                    <>
                      <Divider />
                      <CostRow
                        leftPrimary="Program Management"
                        leftSecondary={format.percent(selectedPlan.adsManagementFeePercent)}
                        rightPrimary={format.money(managementFeeCents / 100)}
                      />

                      <CostRow
                        leftPrimary="Credit Card Processing"
                        leftSecondary={format.percent(creditCardFeePercent)}
                        rightPrimary={format.money(creditCardFeeCents / 100)}
                      />
                    </>
                    : null
                }
              </div>
            );
          })
        }
        <Divider />
        <ListItem>
          <ListItemText
            primary="Today's Total"
          />
          <ListItemSecondaryAction>
            <ListItemText
              style={{ textAlign: 'right' }}
              primary={format.money(todaysTotalCents / 100)}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  }

}

TodaysSummary.defaultProps = {
  shouldPaySetupFee: true,
  selectedPlan: {},
  companyBoostedServicesInPlan: {},
  boostedServices: []
}

export default withContext(PlanContext, TodaysSummary);

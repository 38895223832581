import React from 'react';

import { AppNavigation, MegaphoneIcon, ReviewsStarIcon, ThemeContext, PlanContext, Ability, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log, isStandalone } from 'kn-react';

// appDrawerLinks
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GetAppIcon from '@material-ui/icons/GetApp';
import HistoryIcon from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import SocialIcon from '@material-ui/icons/People';
import Videocam from '@material-ui/icons/Videocam';

import appBarMenuItems, { SUPPORT_PORTAL_KEY } from './appBarMenuItems';
import history from 'history.js';
import userRoleNavItem from './userRoleNavItem';


class OrganizationNavigation extends React.PureComponent {

  render(){
    const { currentUserRole } = this.props;
    log('currentUserRole', currentUserRole);

    let appDrawerLinks = [
      userRoleNavItem(this.props),
      { divider: true },
    ];


    if(this.props.availableServices.length !== 1) {
      appDrawerLinks.push( { primaryText: 'Home', path: '/', icon: <HomeIcon/>, exactPathMatch: true } );
    }

    if(this.props.isAdsAvailable) {
      appDrawerLinks.push({ primaryText: 'Ads', path: '/ads', icon: <MegaphoneIcon/> });
    }

    if(this.props.isReviewsAvailable) {
      appDrawerLinks.push({ primaryText: 'Reviews', path: '/reviews', icon: <ReviewsStarIcon/> });
    }

    if(this.props.isSitesAvailable) {
      appDrawerLinks.push({ primaryText: 'Sites', path: '/sites', icon: <PublicIcon/> });
    }

    if(this.props.isSocialAvailable) {
      appDrawerLinks.push({ primaryText: 'Social', path: '/social', icon: <SocialIcon/> });
    }

    if(this.props.isVirtualAvailable) {
      appDrawerLinks.push({ primaryText: 'Virtual', path: '/virtual', icon: <Videocam/> });
    }

    appDrawerLinks = appDrawerLinks.concat(
      [
        { divider: true },
        { primaryText: 'Reporting', path: '/reporting', icon: <BarChartIcon/> },
        { primaryText: 'Organizations', path: '/organizations', icon: <BusinessIcon/> },
        { primaryText: 'Companies', path: '/companies', icon: <BusinessCenterIcon/> },
    ]);

    if(currentUserRole.roleHasAtLeastOneAbility(
      Ability.VIEW_ORGANIZATION_USERS,
      Ability.EDIT_ORGANIZATION_USERS
    )) {
      appDrawerLinks.push({ primaryText: 'Users', path: '/users', icon: <PeopleIcon/> });
    }

    if(currentUserRole.roleHasAbility(
      Ability.VIEW_ORGANIZATION_ACTIVITY
    )) {
      appDrawerLinks.push({ primaryText: 'Activity', path: '/activity', icon: <HistoryIcon/> });
    }


    appDrawerLinks.push({ primaryText: 'Profile', path: '/profile', icon: <PersonIcon /> });

    if (!isStandalone() && ( !this.props.theme || this.props.theme.shouldShowAddToHomeScreen !== false )) {
      appDrawerLinks.push({ primaryText: 'Add to Home Screen', path: '/add_to_home_screen', icon: <GetAppIcon /> });
    }

    appDrawerLinks.push({ primaryText: 'Sign out', path: '/sign_out', icon: <ExitToAppIcon /> });


    const supportPortalLinkIndex = appBarMenuItems.findIndex(
      a => a.key === SUPPORT_PORTAL_KEY
    );

    if(
      supportPortalLinkIndex >= 0 &&
      this.props.theme &&
      this.props.theme.knowledgeBaseUrl
    ){
      appBarMenuItems[ supportPortalLinkIndex ] = {
        ...appBarMenuItems[ supportPortalLinkIndex ],
        onClick: () => window.open(`${ this.props.theme.knowledgeBaseUrl }`, '_blank'),
      };
    }


    return (
      <AppNavigation
        title={this.props.title}
        appDrawerLinks={appDrawerLinks}
        appBarMenuItems={appBarMenuItems}
      >
        {this.props.children}
      </AppNavigation>
    );
  }

}


export default withContext(ThemeContext, PlanContext, OrganizationRoleContext, OrganizationNavigation);
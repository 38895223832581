import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { CompanySitesProfileContext } from '../CompanySitesProfile/CompanySitesProfileProvider';
import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';
import { SiteDesignsContext } from '../SiteDesigns/SiteDesignsProvider';
import { trackCompanyOwnerSegmentEvent, trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { UNITED_STATES } from 'SignUp/SignUp';
import BusinessHoursSetup from 'Sites/CompanySites/SitesSetup/BusinessHoursSetup/BusinessHoursSetup';
import CheckoutSetup from './CheckoutSetup/CheckoutSetup';
import ChooseSiteDesign from './ChooseSiteDesign/ChooseSiteDesign';
import DomainSetup from './DomainSetup/DomainSetup';
import LogoSetup from './LogoSetup/LogoSetup';
import ServiceAreaSetup from 'SharedSetup/ServiceAreaSetup/ServiceAreaSetup';
import SitesPlanSetup from './SitesPlanSetup/SitesPlanSetup';
import SitesSetupCompletion from './SitesSetupCompletion/SitesSetupCompletion';
import SitesUndoResubscription from 'Sites/CompanySites/SitesSetup/SitesUndoResubscription/SitesUndoResubscription';
import SurveySetup from '../SiteDesigns/SiteDesignsSetup/SurveySetup/SurveySetup';
import TransitionProvider from 'TransitionProvider/TransitionProvider';

class SitesSetupProcess extends React.Component {
  componentDidMount = () => {
    if (!this.props.companySitesProfile) {
      this.props.createCompanySitesProfile();
    }
  }

  render() {
    return (
      this.props.companySitesProfile ?
        <WrappedTransitionProvider initialStatePath="/sites/setup" machineStates={machineStates} />
        :
        null
    );
  }
}


export default withContext(CompanySitesProfileContext, SitesSetupProcess);

// 1) Wrap the Transition Provider with the Providers it will need to evaluate transitions
const WrappedTransitionProvider = withContext(
  PlanContext,
  CompanyRoleContext,
  CompanySitesProfileContext,
  SiteDesignsContext,
  MediaItemsContext,
  TransitionProvider
)


// 2) Define the steps of the process, each with the component, a path, optionally `exact` for the path, and a transitions object. At minimum, the transitions object should define a 'next' key, which is a function of props that returns a string path or object of the form `{ path: String, params: Object?, query: Object? } `


const sharedState = {
  selectedPlanId: null
};


const guardSelectedPlanId = props => (
  !sharedState.selectedPlanId ? '/sites/setup/plans' : null
);

const machineStates = [
  // Select a Plan
  {
    path: '/sites/setup/plans',
    component: props => <SitesPlanSetup {...props} selectedPlanId={sharedState.selectedPlanId}/>,
    guard: props => {
      log('sharedState', sharedState);
      log('SitesPlanSetup guard, plans', props.upgradableSitesPlans);

      if( !props.isSitesAvailable ){
        return '/'
      } else if( props.companySitesProfile.isSetupComplete ){
        return '/sites';
      }

      trackCurrentUserSegmentEvent(
        props.currentUserRole.userId,
        'started_sites_setup',
        props.getUserRoleAuthHeaders,
        { funnel: true }
      );

      if (props.upgradableSitesPlans.length === 1) {
        sharedState.selectedPlanId = sharedState.selectedPlanId || props.upgradableSitesPlans[0].id;

        return '/sites/setup/domain';
      }

      return null;
    },
    transitions: {
      next: props => {
        sharedState.selectedPlanId = props.selectedPlanId;
        return '/sites/setup/domain';
      }
    }
  },


  {
    path: '/sites/setup/domain',
    component: props => <DomainSetup {...props} showBack={props.upgradableSitesPlans.length > 1} selectedPlanId={sharedState.selectedPlanId} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/sites/setup/plans',
      next: '/sites/setup/logo'
    }
  },


  {
    path: '/sites/setup/logo',
    component: LogoSetup,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/sites/setup/domain',
      next: '/sites/setup/site_design'
    }
  },



  {
    path: '/sites/setup/site_design',
    component: props => <ChooseSiteDesign {...props} selectedPlanId={sharedState.selectedPlanId} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/sites/setup/logo',
      next: '/sites/setup/checkout',
      editSurvey: props => ({
        path: '/sites/setup/site_designs/:id/survey',
        params: { id: props.siteDesignId }
      }),
    }
  },


  {
    component: SurveySetup,
    path: '/sites/setup/site_designs/:id/survey',
    guard: (props, transitionKey) => (
      guardSelectedPlanId(props)
        ||
        hasSurvey(props) ? null : { continue: 'next' }
    ),
    transitions: {
      back: '/sites/setup/site_design',
      next: '/sites/setup/checkout'
    }
  },





  // {
  //   path: '/sites/setup/service_area',
  //   component: props => <ServiceAreaSetup {...props} productUndoResubscriptionAlert={SitesUndoResubscription}/>,
  //   guard: (props, transitionKey) => {
  //     if( props.company.country !== UNITED_STATES ){
  //       return { continue: 'next' };
  //     }

  //     return guardSelectedPlanId(props)
  //   },
  //   transitions: {
  //     back: '/sites/setup/logo',
  //     next: '/sites/setup/checkout'
  //   }
  // },
  // {
  //   path: '/sites/setup/business_hours',
  //   component: BusinessHoursSetup,
  //   guard: guardSelectedPlanId,
  //   transitions: {
  //     back: '/sites/setup/service_area',
  //     next: '/sites/setup/checkout'
  //   }
  // },
  {
    component: props => <CheckoutSetup {...props} selectedPlanId={sharedState.selectedPlanId} />,
    path: '/sites/setup/checkout',
    guard: guardSelectedPlanId,
    transitions: {
      back: '/sites/setup/site_design',
      next: '/sites/setup/done'
    }
  },
  {
    component: props => <SitesSetupCompletion {...props} />,
    path: '/sites/setup/done',
    guard: guardSelectedPlanId,
    transitions: {
      back: '/sites',
      next: '/sites'
    }
  },
];




const hasSurvey = props => {
  return getSiteDesign(props).surveyId;
}

const getSiteDesign = props => {
  return props.siteDesigns.find(d => d.id == props.siteDesignId);
}

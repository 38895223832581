import React from 'react';
import { withContext } from 'kn-react';
import Axios from 'axios';
import { Grid, withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Warning } from '@material-ui/icons';

import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import { SiteDesignsContext } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import { Alert } from 'go-boost-library-react';
import SiteModel from 'Sites/SiteModel';

import sitePreviewUrl from 'Sites/sitePreviewUrl';


class UnpublishedCurrentSiteAlert extends React.Component {
  state = {
    site: null
  }

  componentDidMount = () => {
    return fetchCurrentSite(
      this.props.companySitesProfile ? this.props.companySitesProfile.companySiteDesignId : '',
      this.props.companySiteDesigns
    ).then(site => this.setState({ site }));
  }



  render(){
    const { site } = this.state;

    const previewUrl = sitePreviewUrl(
      site
    );

    return (
      (
        site &&
        !site.publishedAt &&
        !this.props.companySitesProfile.isShutdown
      ) ?
        <Grid item sm={12}>
          <Alert
            warning
            component={'a'}
            href={ previewUrl }
            target='_blank'
            className={this.props.classes.alert}
          >
            <div className={this.props.classes.iconWrapper}>
              <Warning />
            </div>

            <div className={this.props.classes.text}>
              Your current website is not live!

              &nbsp;

              <a
                href={ previewUrl }
                target='_blank'
              >
                Click here to view and launch your preview site.
              </a>
            </div>
          </Alert>
        </Grid>
        :
        null
    );
  }
};




const styles = theme => ({
  alert: {
    display: 'block',
    textDecoration: 'none'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: 50,
  },
  text: {
    marginLeft: 30,
    lineHeight: '24px'
  }
})

export default withContext(
  CompanySitesProfileContext,
  SiteDesignsContext,
  withStyles(styles)(
    UnpublishedCurrentSiteAlert
  )
);


export const fetchCurrentSite = (companySiteDesignId, companySiteDesigns) => {
  const currentCompanySiteDesign = companySiteDesigns.find(
    d => companySiteDesignId == d.id
  );

  if( currentCompanySiteDesign && currentCompanySiteDesign.siteId ){
    return Axios.get(
      `/api/sites/${ currentCompanySiteDesign.siteId }`
    ).then(response => {
      return SiteModel.fromJSON(
        response.data.site
      );
    })
  }
}
import React from 'react';

import { Alert, PromiseButton, CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Grid, withStyles } from '@material-ui/core';
import { Warning } from '@material-ui/icons';




const productShutdownAlert = props => (
  props.profile && props.profile.isShutdown ?
    <Grid item sm={12}>
      <Alert warning className={props.classes.alert}>
        <div className={props.classes.iconWrapper}>
          <Warning />
        </div>

        <div className={props.classes.text}>
          Your profile for { props.product } is shutdown. Please resubscribe.
        </div>

        <div className={props.classes.buttonDiv}>
          <PromiseButton
            onProcess={props.onProcess}
            buttonProps={{
              fullWidth: false,
              variant: 'contained',
              color: 'primary',
              className: props.classes.button
            }}
          >
            Resubscribe
          </PromiseButton>
        </div>
      </Alert>
    </Grid>
  :
    null
);


productShutdownAlert.defaultProps = {
  profile: null,
  product: 'this product',
  onProcess: () => Promise.resolve()
}




const styles = theme => ({
  alert: {
    display: 'flex',
    textDecoration: 'none'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: 50,
  },
  text: {
    marginLeft: 30,
    lineHeight: '35px',
    width: 600
  },
  buttonDiv: {
    textAlign: 'right',
    width: '100%'
  },
})

export default withContext(
  CompanyRoleContext,
  withStyles(styles)(
    productShutdownAlert
  )
);
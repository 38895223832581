import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ProductCard from '../ProductCard/ProductCard';


const activateProduct = props => {
  const { classes } = props;
  return (
    <ProductCard colors={props.colors} icon={props.icon} backgroundImage={props.backgroundImage}>
      <Typography paragraph variant="h6">
        {props.description}
      </Typography>

      <Button
        fullWidth
        variant="contained"
        component={Link}
        to={props.to}
        className={classes.button}
        style={{ backgroundColor: props.colors.primary, color: props.colors.contrastText }}
      >
        {props.buttonText}
      </Button>
    </ProductCard>
  );
}


const styles = theme => ({
  button: {
    color: 'white',
    backgroundColor: '#FD9A51',
    '&:hover': {
      backgroundColor: '#FD9A51',
    },
  },
})

export default withStyles(styles)(activateProduct);
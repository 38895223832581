import React from 'react';
import Axios from 'axios';
import { log } from 'kn-react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from '@material-ui/core/ListItemText';

import { withStyles } from '@material-ui/core/styles';


class KnAutosuggest extends React.Component {
  state = {
    suggestions: [],
    loadingSuggestions: false
  };

  onSuggestionsFetchRequested = ({ value }) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        { loadingSuggestions: true },
        () => this.props.onSuggestionsFetchRequested(value)
          .then(suggestions => this.setState({ suggestions, loadingSuggestions: false }))
      )
    }, 750);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
    this.props.onSuggestionsClearRequested();
  };

  onSuggestionSelected = (event, { suggestion }) => {
    log('onSuggestionSelected', suggestion);
    this.props.onSuggestionSelected(suggestion);

    if( this.props.clearAfterSelected ){
      this.props.onChangeInputValue( '' );
    }
  }

  onChange = (event, { newValue }) => {
    this.props.onChangeInputValue(newValue);
  };


  renderInputComponent = inputProps => {
    const { classes, inputRef = () => {}, ref, onChange, ...other } = inputProps;

    return (
      <TextField
        fullWidth
        margin="normal"
        label={this.props.label}

        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          autoComplete:"new-password",
          classes: {
            input: classes.input,
          },
          endAdornment: this.state.loadingSuggestions ? <CircularProgress /> : null,
          onChange: e => this.state.loadingSuggestions ? null : onChange(e)
        }}
        {...other}
      />
    );
  }

  renderSuggestion = (suggestion, { isHighlighted }) => {

    return (
      <MenuItem selected={isHighlighted} component="div">
        {
          this.props.suggestionStartAdornment ?
            this.props.suggestionStartAdornment(suggestion)
          :
            null
        }

        <ListItemText
          primary={this.props.getSuggestionPrimaryValue(suggestion)}
          secondary={this.props.getSuggestionSecondaryValue(suggestion)}
        />
      </MenuItem>
    );
  }



  shouldRenderSuggestions = value => {
    return value.trim().length >= 3;
  }

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionValue: this.props.getSuggestionPrimaryValue,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      renderSuggestion: this.renderSuggestion,
    };

    return (
      <div
        className={classes.root}
        style={this.props.style}
      >
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: this.props.placeholder,
            value: this.props.inputValue,
            onChange: this.onChange,
            id: this.props.autosuggestId,
            onBlur: this.props.onBlur
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper
              {...options.containerProps}
              square
              className={classes.paper}
              id='autosuggest-paper-root'
            >
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

KnAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

KnAutosuggest.defaultProps = {
  label: '',
  getSuggestionPrimaryValue: suggestion => '',
  getSuggestionSecondaryValue: suggestion => '',
  onSuggestionsFetchRequested: value => Promise.resolve([]),
  onSuggestionsClearRequested: () => {},
  onSuggestionSelected: suggestion => {},
  onChangeValue: () => {},
  placeholder: '',
  autosuggestId: '',
  clearAfterSelected: false,
  suggestionStartAdornment: null,
  style: {},
  onBlur: () => {}
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  paper: {
    marginTop: 0,
    maxHeight: 500,
    overflowY: 'scroll'
  }
});


export default withStyles(styles)(KnAutosuggest);

import React from "react";
import { withContext } from 'kn-react';

import { withStyles, Grid } from "@material-ui/core";

import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';

const BIG_MAX_HEIGHT = 256;


const videoPreview = props => {
  const { classes } = props;

  const video = (mediaItemId, size, maxHeight, key) => {
    const mediaItem = props.mediaItems.find(
      m => m.id === mediaItemId
    );

    return (
      <Grid
        item
        xs={size}
        style={{
          maxHeight,
          textAlign: 'center',
          // paddingTop: 1,
          // paddingBottom: 1,
        }}
        key={key}
      >
        <video
          controls
          src={mediaItem ? mediaItem.url : null}
          style={{
            maxWidth: '100%',
            maxHeight,
          }}/>
      </Grid>
    )
  }

  const { attachments } = props;
  const videos = props.attachments.length;
  const height = videos === 1 ? BIG_MAX_HEIGHT : 0;

  return (
    <div className={classes.container} style={{ height }}>
      <Grid container spacing={8}>
        {
          attachments.length === 1 ?
            video(
              attachments[0],
              12,
              BIG_MAX_HEIGHT
            )
          :
            null
        }
      </Grid>
    </div>
  );
};



const styles = theme => ({
  container: {
    maxWidth: '375px',
    borderTop: '0.5px solid #dedede',
  },
  imageWrapper: {
    display: 'block',
    width: '100%',
    height: '0',
    paddingTop: '51.25%',
    borderBottom: '0.5px solid #dedede',
  },
  info: {
    padding: 15,
    backgroundColor: '#f2f3f5',
  },
  siteName: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#616770',
    fontFamily: '"Roboto", sans-serif',
  },
  title: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontSize: '15px',
    fontFamily: '"Roboto", sans-serif',
    color: '#1d2129',
    marginTop: '3px',
  },
  description: {
    display: 'block',
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '13px',
    color: '#616770',
    marginTop: '3px',
  },
  opaqueDiv: {
    opacity: 0.5,
    position: 'relative'
  },
  numberOverlay: {
    color: 'white',
    top: '40%',
    left: '40%',
    position: 'absolute',
    margin: 0,
    fontSize: 32,
  }
})

videoPreview.defaultProps = {
  ogImageUrl: '',
  ogUrl: '',
  ogTitle: '',
  ogDescription: ''
}

export default withContext(
  MediaItemsContext,
  withStyles(styles)(videoPreview)
)
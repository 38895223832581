import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AppNavigation from '../../AppNavigation/AppNavigation';
import CompanyIndex from './CompanyIndex/CompanyIndex';
import CompanySettings from './CompanySettings';

class Companies extends React.Component {
  render(){
    return (
      <AppNavigation title="Companies">
        <Switch>
          <Route exact path="/companies" component={ CompanyIndex }/>
          <Route path={"/companies/:id"} component={ CompanySettings }/>
        </Switch>
      </AppNavigation>
    );
  }
}

export default Companies;
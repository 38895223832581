import { JsonModel } from 'kn-react';
import { ToggleMapModel } from 'go-boost-library-react';

export default class CompanyBoostedServiceModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    let model = new CompanyBoostedServiceModel();
    model = JsonModel.modelFromJSON(model, json);
    model.boostZone = model.boostZone ? ToggleMapModel.fromJSON(model.boostZone) : new ToggleMapModel();

    return model;
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

  isConfigured = () => {
    // WARNING
    // This isn't the most comprehensive configuration check - it doesn't check Boosted Service
    // attributes like the survey or facebook settings. But since these are earlier in the configuration
    // process, this is a good proxy.
    return this.boostZone.includedGeographies.length && this.boostStrategyName;
  }


  hasBoostBalance = () => {
    return this.boostBalanceCents < 0 || this.boostBalanceCents === 0 || this.boostBalanceCents > 0;
  }


  isPreFacebookAuthBoostedService = () => {
    return [6,10,12].includes( this.boostedServiceId )
  }

}

import React from "react";
import Axios from 'axios';

import { log } from 'kn-react';

import { withStyles } from "@material-ui/core";

class Preview extends React.Component {
  state = {
    showImagePreview: false
  };

  componentDidMount = () => {
    if( this.props.ogImage && this.props.ogImage.url ){
      // The following call may error out with a 403.
      // To prevent sign out, use a new Axios instance that
      // does not use interceptors.

      const axiosWithoutInterceptors = Axios.create();

      return axiosWithoutInterceptors.get(
        this.props.ogImage.url
      )
      .then(() => this.setState({ showImagePreview: true }))
      .catch(error => log('Error with preview image.'));
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {
          this.state.showImagePreview && this.props.ogImage && this.props.ogImage.url ?
            <div
              className={classes.imageWrapper}
              src={this.props.ogImage.url}
              style={{
                background: `url(${this.props.ogImage.url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              onEr
            />
          :
            null
        }

        <div className={classes.info}>

          <span className={classes.siteName}>{this.props.ogUrl}</span>

          <span className={classes.title}>{this.props.ogTitle}</span>

          <span className={classes.description}>{this.props.ogDescription}</span>
        </div>
      </div>
    );
  };
}



const styles = theme => ({
  container: {
    maxWidth: '375px',
    borderTop: '0.5px solid #dedede',
  },
  imageWrapper: {
    display: 'block',
    width: '100%',
    height: '0',
    paddingTop: '51.25%',
    borderBottom: '0.5px solid #dedede',
  },
  info: {
    padding: 15,
    backgroundColor: '#f2f3f5',
  },
  siteName: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#616770',
    fontFamily: '"Roboto", sans-serif',
  },
  title: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontSize: '15px',
    fontFamily: '"Roboto", sans-serif',
    color: '#1d2129',
    marginTop: '3px',
  },
  description: {
    display: 'block',
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '13px',
    color: '#616770',
    marginTop: '3px',
  }
})

Preview.defaultProps = {
  ogImageUrl: '',
  ogUrl: '',
  ogTitle: '',
  ogDescription: ''
}

export default withStyles(styles)(Preview);
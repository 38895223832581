import { JsonModel } from 'kn-react';

export default class ExternalAccessConnectionModel {

  static fromJSON(json){
    const model = new ExternalAccessConnectionModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

}
import React from 'react';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { ChannelContext } from 'Channels/ChannelProvider';
import VirtualDashboard from 'Virtual/VirtualDashboard/VirtualDashboard';

const virtualDashboard = props => {
  const includedGeoTypeIds = (
    (
      props.company &&
      props.company.serviceArea &&
      props.company.serviceArea.includedGeographies
    ) ?
      props.company.serviceArea.includedGeographies
    :
      []
  );

  const excludedGeoTypeIds = (
    (
      props.company &&
      props.company.serviceArea &&
      props.company.serviceArea.excludedGeographies
    ) ?
      props.company.serviceArea.excludedGeographies
    :
      []
  );

  const center = [
    props.company.latitude,
    props.company.longitude,
  ];


  return (
    <VirtualDashboard
      roleTypePath="companies"
      roleTypeId={props.company.id}
      currentUserRole={props.currentUserRole}
      getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
      channelIds={props.virtualChannels.map(c => c.id)}
      includedGeoTypeIds={includedGeoTypeIds}
      excludedGeoTypeIds={excludedGeoTypeIds}
      virtualWorkRequestMapDefaultBounds={null}
      virtualWorkRequestMapCenter={center}
      showViewOpportunity={true}
    />
  )
};

export default withContext(
  CompanyRoleContext,
  ChannelContext,
  virtualDashboard
);
import React from 'react';
import { format } from 'kn-react';

import { OverTimeChart, ChangeMetric } from 'go-boost-library-react';
import { Visibility } from '@material-ui/icons';

const impressionsChart = props => {
  const impressionsLineStyles = {
    Visits: {
      type:"monotone",
      dataKey:"Impressions",
      stroke:"#37caad",
      activeDot: { r: 8 }
    }
  };


  return (
    <OverTimeChart
      data={props.statsChartData}
      lineStyles={impressionsLineStyles}
      tooltipProps={{
        formatter: value => format.commas(value)
      }}
    />
  )
}


impressionsChart.defaultProps = {
  statsChartData: []
}

export default impressionsChart



const ImpressionsMetric = props => (
  <ChangeMetric
    title="Impressions"
    metric={ format.commas( props.statsTotals.totalImpressions ) || 0 }
    icon={Visibility}
    iconColor="#37caad"
    iconBackgroundColor='rgba(55, 202, 173, 0.2)'
    change={ props.statsChange.impressionsChange || '' }
    width={260}
    height={260}
  />
)

ImpressionsMetric.defaultProps = {
  statsTotals: {},
  statsChange: {}
}

export { ImpressionsMetric }
import { JsonModel } from 'kn-react';

export default class BoostedServiceModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    let model = new BoostedServiceModel();
    model = JsonModel.modelFromJSON(model, json);
    return model;
  }


  toJSON(){
    return JsonModel.modelToJSON(this);
  }


  isFacebookChannel(channels){
    const facebookChannel = channels.find(c => c.isFacebook());
    return facebookChannel.id == this.channelId;
  }

}

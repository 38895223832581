import React from 'react';
import { isDemo, isStaging, isDevelopment, UserRoleContext } from 'go-boost-library-react';
import { LinearProgress } from '@material-ui/core';
import { withContext, log } from 'kn-react';
import Axios from 'axios';
import MediaItemModel from './MediaItemModel';


const DEFAULT_STATE = {
  isLoadingMediaItems: true,
  mediaItems: [],
};


export const MediaItemsContext = React.createContext(DEFAULT_STATE);

class MediaItemsProvider extends React.Component {

  state = {
    ...DEFAULT_STATE,
  }

  componentDidMount = () => {
    return this.fetchMediaItems().then(
      () => this.setState({ isLoadingMediaItems: false })
    )
  }


  fetchMediaItems = () => {
    log('MediaItemsProvider.fetchMediaItems');
    const { currentUserRole } = this.props;

    const path = currentUserRole.isCompanyRole() ? 'companies' : 'organizations';

    return Axios.get(
      `/api/core/media_items/${path}/${currentUserRole.roleTypeId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {

      const mediaItems = response.data.media_items.map(
        m => MediaItemModel.fromJSON( m )
      );

      log('MediaItemsProvider.fetchMediaItems', mediaItems);

      this.setState({
        mediaItems,
        isLoadingMediaItems: false
      });
    });
  }


  createMediaItem = (file, name) => {
    log('createMediaItem', file)

    const formData = new FormData();
    formData.append('file', file);

    return Axios.post(
      `${ this.mediaItemRoute() }?name=${name}`,
      formData,
      { headers: {...this.props.getUserRoleAuthHeaders(), 'Content-Type': 'multipart/form-data'} }
    )
    .then(response => {
      log('createMediaItem response', response);
      return MediaItemModel.fromJSON(response.data.media_item);
    })
    .catch(error => {
      this.props.showSnackbar(error.response.data.message);
      throw error
    });
  }


  updateMediaItem = (mediaItemId, file, name) => {
    log('updateMediaItem', mediaItemId)

    const formData = new FormData();
    formData.append('file', file);

    return Axios.put(
      `${ this.mediaItemRoute() }/${mediaItemId}${name ? '?name='+name : ''}`,
      file ? formData : {},
      { headers: {...this.props.getUserRoleAuthHeaders(), 'Content-Type': 'multipart/form-data'} }
    )
    .then(response => {
      log('updateMediaItem response', response);
      return MediaItemModel.fromJSON(response.data.media_item);
    })
    .catch(error => {
      this.props.showSnackbar(error.response.data.message);
      throw error
    });
  }


  deleteMediaItem = mediaItemId => {
    log('deleteMediaItem', mediaItemId)

    return Axios.delete(
      `${ this.mediaItemRoute() }/${mediaItemId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      return response
    }).catch(error => {
      throw error
    });
  }


  mediaItemRoute = () => {
    return (
      ( !isDevelopment() && !isStaging() && !isDemo() ) ?
        `https://images.goboost.com/api/core/media_items`
      :
        `/api/core/media_items`
    );
  }



  render(){
    const { isLoadingMediaItems } = this.state;
    if (isLoadingMediaItems) {
      return <LinearProgress />;
    }

    return (
      <MediaItemsContext.Provider
        value={{
          ...this.state,
          fetchMediaItems: this.fetchMediaItems,
          createMediaItem: this.createMediaItem,
          updateMediaItem: this.updateMediaItem,
          deleteMediaItem: this.deleteMediaItem
        }}
      >
        { this.props.children }
      </MediaItemsContext.Provider>
    );
  }
}


export default withContext(
  UserRoleContext,
  MediaItemsProvider
)
import React from 'react';

import LinkStatusFacebookPost from 'Social/FeedItem/LinkStatusFacebookPost';
import PhotoFacebookPost from 'Social/FeedItem/PhotoFacebookPost';
import VideoFacebookPost from 'Social/FeedItem/VideoFacebookPost';

const FEED_TYPES = [
  'Link',
  'Status',
  'Photo',
  'Video'
]

export default feedType => {
  if( FEED_TYPES.slice(0,2).includes(feedType) ){
    return LinkStatusFacebookPost
  } else if( FEED_TYPES.slice(2,3).includes(feedType) ){
    return PhotoFacebookPost
  } else if( FEED_TYPES.slice(3).includes(feedType) ){
    return VideoFacebookPost
  }
}
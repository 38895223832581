import React from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { withContext, MaxWidth } from 'kn-react';

import { Alert, UserRoleContext } from 'go-boost-library-react';

import { Tooltip, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import FeedItemModel from 'Social/FeedItem/FeedItemModel';
import SocialTimeline from 'Social/Timeline/SocialTimeline';
import FeedItemPreview from 'Social/FeedItem/FeedItemPreview';

class FeedTimeline extends React.Component {
  state = {
    feedItems: [],
    isLoading: false
  }


  componentDidMount = () => {
    return this.setState(
      { isLoading: true },
      () => this.fetchFeedItems()
        .finally(() => this.setState({ isLoading: false }))
    );
  }


  fetchFeedItems = () => {
    return Axios.get(
      `/api/social/feed_items/feeds/${this.props.match.params.feedId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const feedItems = response.data.feed_items.map(
        f => FeedItemModel.fromJSON(f)
      );

      Object.keys(response.data.statistics).forEach(
        feedItemId => {
          feedItems.find(
            f => f.id == feedItemId
          )['stats'] = FeedItemModel.fromJSON( response.data.statistics[ feedItemId ]);
        }
      );

      this.setState({ feedItems });
    })
  }


  timelineContent = feedItem => {
    const Preview = FeedItemPreview(feedItem.feedType);

    return <Preview
      text={feedItem.feedContent.text}
      url={feedItem.feedContent.url}
      meta={feedItem.feedContent.linkMeta}
      attachments={feedItem.feedContent.attachments}
      feedType={feedItem.feedContent.feedType}
      stats={feedItem.stats}
    />
  }

  onDeleteItem = feedItem => {
    if( window.confirm('Are you sure you want to delete this Feed Item? This action cannot be undone.') ){
      return Axios.delete(
        `/api/social/feed_items/${feedItem.id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      ).then(response => {
        return this.fetchFeedItems()
      })
    }
  }


  render() {
    return (
      <>
        <div style={{ textAlign: 'right' }}>
          <Tooltip title='Create Feed Post'>
            <IconButton
              to={`/social/feeds/${this.props.match.params.feedId}/new`}
              component={Link}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </div>

        {
          this.state.isLoading || this.state.feedItems.length ?
            <SocialTimeline
              items={this.state.feedItems}
              timelineContent={this.timelineContent}
              fetchEditLink={feedItem => `/social/feed_items/${feedItem.id}/edit`}
              fetchViewLink={feedItem => `/social/feed_items/${feedItem.id}/view`}
              onDeleteItem={this.onDeleteItem}
              deleteTooltipTextGetter={() => 'Delete Feed Item'}
              isLoading={this.state.isLoading}
            />
          :
            <MaxWidth maxWidth={800}>
              <Alert warning>This Feed does not have any Items. Click + to create a Feed Item.</Alert>
            </MaxWidth>
        }
      </>
    );
  }

}

export default withContext(
  UserRoleContext,
  FeedTimeline
)
import React from 'react';
import Axios from 'axios';
import { withContext, log } from 'kn-react';

import { LinearProgress } from '@material-ui/core';
import { GoogleAccountModel, GoogleAccountLocationModel, SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import CompanyReviewSiteModel from 'Reviews/CompanyReviewSiteModel';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';


const DEFAULT_STATE = {
  googleAccountLoading: true,
  googleAccountLocationLoading: false,
  googleAccount: {},
  googleAccountLocation: {}
};


export const GoogleAccountContext = React.createContext(DEFAULT_STATE);


class GoogleAccountProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }


  fetchData = () => {
    return this.setState(
      { googleAccountLoading: true },
      () => this.fetchGoogleAccount()
        .then(this.fetchGoogleAccountLocation)
        .finally(() => this.setState({ googleAccountLoading: false }))
    );
  }


  fetchGoogleAccount = () => {
    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/google_accounts/companies/${currentUserRole.roleTypeId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchGoogleAccount', response);
      const googleAccount = GoogleAccountModel.fromJSON(response.data.google_account);
      log('fetchGoogleAccount Model', googleAccount);
      this.setState({ googleAccount });
    })
    .catch(error => {
      log('fetchGoogleAccount error.response', error.response);
    });
  }

  googleOnCode = (code, callback) => {
    log('onCode');

    return Axios.post(
      '/api/core/google_accounts/auth',
      { code },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
		.then(response => {
			log('onCode response', response);
			this.setGoogleAccount(response.data.google_account, callback);
		})
		.catch(error => {
			log('google auth processCode error', error);
			this.props.showSnackbar(error.response.data.message);
		});

  }

  setGoogleAccount = (googleAccountData, callback) => {
		const googleAccount = GoogleAccountModel.fromJSON(googleAccountData);
		log('setGoogleAccount Model', googleAccount);

		this.setState(
			{ googleAccount },
			() => callback ? callback() : null
		);
	}


  fetchGoogleAccountLocation = () => {
    const { googleAccount } = this.state;

    if (!googleAccount.id) {
      return;
    }

    return Axios.get(
      `/api/core/google_account_locations/google_account/${googleAccount.id}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchGoogleAccountLocation', response);
      const googleAccountLocation = GoogleAccountLocationModel.fromJSON(response.data.google_account_location);
      log('fetchGoogleAccountLocation Model', googleAccountLocation);
      this.setState({ googleAccountLocation });
    })
    .catch(error => {
      log('fetchGoogleAccountLocation error.response', error.response);
    });
  }


	setGoogleMyBusinessAccount = (account,callback) => {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;
		return Axios.post(
			`/api/core/google_accounts/companies/${currentUserRole.roleTypeId}/my_business`,
			{
				account
			},
			{ headers: getUserRoleAuthHeaders() }
		)
		.then(response => {
			const googleAccount = GoogleAccountModel.fromJSON(response.data.google_account);
			log('setGoogleMyBusinessAccount response', googleAccount);
			this.setState(
				{ googleAccount },
				() => callback ? callback() : null
			);
		})
	}


	setGoogleAccountLocation = (location) => {
    const { googleAccount } = this.state;
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;
    this.setState(
      { googleAccountLocationLoading: true }
    );

    return Axios.post(
      `/api/core/google_account_locations/google_account/${googleAccount.id}`,
      location,
      { headers: getUserRoleAuthHeaders() }
    ).then(response => {
      const googleAccountLocation = GoogleAccountLocationModel.fromJSON(response.data.google_account_location);
      log('setGoogleMyBusinessAccountLocation response', googleAccountLocation);

      trackCurrentUserSegmentEvent(currentUserRole.userId, 'connected_google_account', getUserRoleAuthHeaders);

      this.setState({
        googleAccountLocation,
        googleAccountLocationLoading: false
      });
    }).catch(error => {
      this.props.showSnackbar(error.response.data.message);

      this.setState({ googleAccountLocationLoading: false });

      throw error
    });
  }


  googleSignOut = () => {
    const { googleAccount } = this.state;
    const { getUserRoleAuthHeaders } = this.props;

    return Axios.get(
      `/api/core/google_accounts/${googleAccount.id}/sign_out`,
      { headers: getUserRoleAuthHeaders() }
    )
    .then(response => {
			this.setState(
				{
					loading: true
				},
				this.fetchData
			);
    })
    .catch(error => {
      log('fetchGoogleAccountLocation error.response', error.response);
    });
  }


  isSignedIn = () => {
    const { googleAccount, googleAccountLocation } = this.state;

    return Boolean(googleAccount.id && googleAccountLocation.id)
  }

  getGoogleMyBusinessAccountsCallback = (errorCallback) => {
    return Axios.get(
      `/api/core/google_accounts/${ this.state.googleAccount.id }/my_business`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('getGoogleMyBusinessAccounts response', response.data);

      return response.data.my_business_accounts;
    })
    .catch(error => {
      log('getGoogleMyBusinessAccounts error', error);
      this.props.showSnackbar('Something went wrong.')
      return (errorCallback) ? errorCallback() : null;
    });
	}

	getGoogleLocationsCallback = (errorCallback) => {
    return Axios.get(
      `/api/core/google_accounts/${ this.state.googleAccount.id }/locations`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('getGoogleLocations response', response.data);

      if (!response.data.locations || !response.data.locations.length) {
        return this.setState(
          {
            loading: false,
            hasNoLocations: true
          }
        );
      }

      return response.data.locations;
    })
    .catch(error => {
      log('getGoogleLocations error', error);
      this.props.showSnackbar('Something went wrong.')
      return (errorCallback) ? errorCallback() : null;
    });
  }



  findOrCreateGoogleReviewSite = () => {
    log('findOrCreateGoogleReviewSite', this.state.googleAccountLocation);

    const respondWithCompanyReviewSite = response => {
      log('findOrCreateGoogleReviewSite response', response.data);

      return CompanyReviewSiteModel.fromJSON(
        response.data.company_review_site
      );
    };


    return Axios.get(
      `/api/reviews/company_review_sites/companies/${ this.state.googleAccount.companyId }/google`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      respondWithCompanyReviewSite
    ).catch(error => {
      log('findOrCreateGoogleReviewSite error', error);
      if( error.response.status === 404 ){
        return Axios.post(
          `/api/core/google_account_locations/companies/${ this.state.googleAccount.companyId }/company_review_site`,
          {},
          { headers: this.props.getUserRoleAuthHeaders() }
        ).then(
          respondWithCompanyReviewSite
        ).catch(error => {
          log('findOrCreateGoogleReviewSite error', error);
          this.props.showSnackbar('Something went wrong.');
        });
      }


      this.props.showSnackbar('Something went wrong.');
    });
  }




  render() {
    const { googleAccountLoading, googleAccount, googleAccountLocation, googleAccountLocationLoading } = this.state;

    if (googleAccountLoading) {
      return <LinearProgress />;
    }



    return (
      <GoogleAccountContext.Provider
        value={{
          googleAccount,
          googleAccountLocation,
          googleAccountLocationLoading,
          fetchGoogleAccount: this.fetchGoogleAccount,
          fetchGoogleAccountLocation: this.fetchGoogleAccountLocation,
          fetchGoogleAccountData: this.fetchData,
          setGoogleMyBusinessAccount: this.setGoogleMyBusinessAccount,
          setGoogleAccountLocation: this.setGoogleAccountLocation,
          setGoogleAccount: this.setGoogleAccount,
          googleSignOut: this.googleSignOut,
          googleAccountIsSignedIn: this.isSignedIn,
          googleOnCode: this.googleOnCode,
          getGoogleMyBusinessAccountsCallback: this.getGoogleMyBusinessAccountsCallback,
          getGoogleLocationsCallback: this.getGoogleLocationsCallback,
          findOrCreateGoogleReviewSite: this.findOrCreateGoogleReviewSite
        }}
      >
        {this.props.children}
      </GoogleAccountContext.Provider>
    );
  }
}


export default withContext(
  UserRoleContext,
  SnackbarContext,
  GoogleAccountProvider
);

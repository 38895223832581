import React from 'react';
import Axios from 'axios';
import qs from 'qs';

import { LinearProgress } from '@material-ui/core';

import { withContext, log } from 'kn-react';
import { AuthContext, CredentialsModel, removeStoredCurrentUserRoleId } from 'go-boost-library-react';

import history from 'history.js';


class SSO extends React.Component{
  componentDidMount = () => {
    const code = this.props.match.params.code

    return Axios.post(
      `/api/core/oauth/sso`,
      { code }
    ).then(response => {
      const credentials = CredentialsModel.fromJSON(response.data.credentials);
      log('credentials', credentials);

      // Remove any saved credentials
      this.props.removeCredentials();
      removeStoredCurrentUserRoleId();

      // Set credentials
      this.props.setCredentials(credentials);

      return history.push('/');
    })
  }

  render(){
    return(
      <LinearProgress />
    )
  }
}



export default withContext(
  AuthContext,
  SSO
)
import React from 'react';
import Axios from 'axios';

import { SnackbarContext, Alert, Ability, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, opportunityConstants } from 'kn-react';

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Button, withStyles, MenuItem, Typography, Grid, Divider } from '@material-ui/core';

import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import BuildingHomeIcon from 'Virtual/Icons/BuildingHomeIcon';
import BuyingHomeIcon from 'Virtual/Icons/BuyingHomeIcon';
import ContractIcon from 'Virtual/Icons/ContractIcon';
import MonthCalendarIcon from 'Virtual/Icons/MonthCalendarIcon';
import NoIcon from 'Virtual/Icons/NoIcon';
import OwnHomeIcon from 'Virtual/Icons/OwnHomeIcon';
import WeekCalendarIcon from 'Virtual/Icons/WeekCalendarIcon';
import YesIcon from 'Virtual/Icons/YesIcon';


const DISTANCE_PRECISION = 2;
const METERS_IN_FOOT = 0.3048;
const MILE_IN_METERS = 0.000621371;

const MAX_NO_WRAP_CHOICE_LABEL_CHARACTER_COUNT = 10;
const MAX_NO_WRAP_INPUT_LABEL_CHARACTER_COUNT = 15;


const {
  ADDRESS_KEY, CONTACT_KEY, ADDRESS_AUTOSUGGEST, MULTIPLE_INPUT, MULTIPLE_CHOICE, PHOTO_UPLOAD, VIDEO_UPLOAD,
  INPUT_INPUT_TYPE, TEXT_AREA_INPUT_TYPE, MAX_DISTANCE_METERS, MAX_DISTANCE_MILES, MAX_DISTANCE_KILOMETERS
} = opportunityConstants;



const STEP_TYPES = [
  MULTIPLE_CHOICE,
  ADDRESS_AUTOSUGGEST,
  MULTIPLE_INPUT,
  PHOTO_UPLOAD,
  VIDEO_UPLOAD
];


const GRADEABLE_STEP_TYPES = [
  MULTIPLE_CHOICE
]


const ICONS = {
  'HomePersonIcon': OwnHomeIcon,
  'HomeMoneyIcon': BuyingHomeIcon,
  'HomeCheckIcon': BuildingHomeIcon,
  'NoIcon': NoIcon,
  'YesIcon': YesIcon,
  'ContractIcon': ContractIcon,
  'MonthCalendarIcon': MonthCalendarIcon,
  'WeekCalendarIcon': WeekCalendarIcon,
};


const GRADES = [
  'A',
  'B',
  'C',
  'D',
  'F'
];


const INPUT_TYPES = [
  INPUT_INPUT_TYPE,
  TEXT_AREA_INPUT_TYPE
];

const INPUT_TYPE_MAP = {
  [ INPUT_INPUT_TYPE ]: 'Input',
  [ TEXT_AREA_INPUT_TYPE ]: 'Text Area'
};



export const BLANK_STEP = {
  headline: '',
  caption: '',
  key: '',
  type: '',
  distance: {},
  choices: [],
  inputs: [],
  helperText: '',
  onUpdateStep: '',
  shouldGrade: true,
  isRequired: true,
  canExclude: true,
  isInactive: false,
  isProcessing: false
}


class StepDialog extends React.Component {
  state = {
    ...BLANK_STEP
  }

  componentDidMount = () => {
    return this.constructState();
  }


  componentDidUpdate = oldProps => {
    if( this.props.open && oldProps.open !== this.props.open ){
      return this.constructState();
    }
  }


  constructState = () => {
    const { step } = this.props;

    if( !step ){
      return this.setState({
        ...BLANK_STEP
      });
    }


    const origin = (
      step.distance ?
        step.distance.origin
      :
        `${ this.props.company.addressLineOne }${ this.props.company.addressLineTwo ? ', ' + this.props.company.addressLineTwo: '' }, ${ this.props.company.city }, ${ this.props.company.state } ${ this.props.company.postalCode }`
    );

    const distance = (
      step.distance ?
        {
          ...step.distance,
          [ MAX_DISTANCE_MILES ]: ( step.distance[ MAX_DISTANCE_METERS ] * MILE_IN_METERS ).toFixed(2) || 0,
          [ MAX_DISTANCE_KILOMETERS ]: ( step.distance[ MAX_DISTANCE_METERS ] / 1000 ).toFixed( DISTANCE_PRECISION ) || 0,
          origin
        }
      :
        { origin }
    );

    return this.setState({
      headline: step.headline || '',
      caption: step.caption || '',
      key: step.key || '',
      type: step.type || '',
      distance,
      choices: step.choices || [],
      inputs: step.inputs || [],
      helperText: step.helperText || '',
      onUpdateStep: step.onUpdateStep || '',
      shouldGrade: step.shouldGrade || false,
      isRequired: step.isRequired || false,
      canExclude: step.canExclude || undefined,
      isInactive: step.isInactive || undefined,
      isProcessing: false
    });
  }


  onChangeNestedValue = (nestedField, field, value, index) => {
    const existingValues = this.state[ nestedField ];

    let updates;
    if( +index >= 0 ){
      updates = [ ...existingValues ];

      updates[ index ] = {
        ...updates[ index ],
        [ field ]: value
      };
    } else {
      updates = { ...existingValues };

      updates[field] = value;
    }


    if( field === MAX_DISTANCE_KILOMETERS ){
      updates[ MAX_DISTANCE_METERS ] = ( value * 1000 ).toFixed( DISTANCE_PRECISION ) || 0;
      updates[ MAX_DISTANCE_MILES ] = ( ( value * 1000 ) * MILE_IN_METERS ).toFixed( DISTANCE_PRECISION ) || 0;
    } else if( field === MAX_DISTANCE_MILES ){
      updates[ MAX_DISTANCE_METERS ] = ( value / MILE_IN_METERS ).toFixed( DISTANCE_PRECISION ) || 0;
      updates[ MAX_DISTANCE_KILOMETERS ] = ( ( value / MILE_IN_METERS ) / 1000 ).toFixed( DISTANCE_PRECISION ) || 0;
    }


    return this.setState({
      [ nestedField ]: updates
    });
  }


  validateStep = () => {
    if( !this.state.headline || !this.state.headline.trim() || !this.state.type ){
      return false;
    }

    if( [MULTIPLE_CHOICE, MULTIPLE_INPUT].includes(this.state.type ) ){
      const choiceOrInput = this.state.type === MULTIPLE_CHOICE ? 'choices' : 'inputs';

      const allLabels = this.state[ choiceOrInput ].filter(
        c => !c.isDeleted
      ).map(
        c => c.label
      );

      if( !allLabels.length ){
        return false;
      }

      const allLabelsPresent = allLabels.every(l => l);
      if( !allLabelsPresent ){
        return false;
      }


      const uniqueLabels = new Set( allLabels );
      if( uniqueLabels.size !== allLabels.length ){
        return false;
      }
    } else if( this.state.type === ADDRESS_AUTOSUGGEST ){
      if(
        !this.state.distance ||
        (
          this.state.distance.shouldExit &&
          (
            !this.state.distance.maxDistanceMeters ||
            !+this.state.distance.maxDistanceMeters ||
            !this.state.distance.origin
          )
        )
      ){
        return false;
      }
    }


    return true;
  }


  onUpdateStep = () => {
    if( !this.validateStep() ){
      return this.props.showSnackbar('All required fields must be present and unique.');
    }

    const canGrade = [MULTIPLE_CHOICE, MULTIPLE_INPUT].includes(
      this.state.type
    );

    const shouldGrade = (
      !canGrade || !this.state.shouldGrade ?
        false
      :
        true
    );

    const updatedStep = {
      headline: this.state.headline,
      caption: this.state.caption || undefined,
      key: this.state.key || this.props.generateStepKey(),
      type: this.state.type || STEP_TYPES[ 0 ],
      helperText: this.state.helperText || undefined,
      onUpdateStep: this.state.onUpdateStep || undefined,
      shouldGrade,
      isRequired: this.state.isRequired || false,
      canExclude: this.state.canExclude || undefined,
      isInactive: this.state.isInactive || undefined,
      distance: undefined,
      choices: undefined,
      inputs: undefined,
    };

    if( this.state.type === MULTIPLE_CHOICE ){
      const additionalChoiceKeys = [];

      updatedStep.choices = this.state.choices.map(
        c => {
          let key;
          if( ![null, undefined].includes( c.key ) ){
            key = c.key;
          } else {
            key = this.generateOptionKey(
              this.state.choices,
              additionalChoiceKeys
            );

            additionalChoiceKeys.push( key );
          }

          return ({
            key,
            label: c.label,
            icon: c.icon || Object.keys(ICONS)[ 0 ],
            grade: c.grade || GRADES[ 0 ],
            shouldExit: c.shouldExit || false,
            canExclude: c.canExclude === false ? false : true,
            isDeleted: c.isDeleted || false
          });
        }
      );
    } else if ( this.state.type === MULTIPLE_INPUT ){
      const additionalInputKeys = [];

      updatedStep.inputs = this.state.inputs.map(
        i => {
          let key;
          if( ![null, undefined].includes( i.key ) ){
            key = i.key;
          } else {
            key = this.generateOptionKey(
              this.state.inputs,
              additionalInputKeys
            );

            additionalInputKeys.push( key );
          }

          return ({
            key,
            label: i.label,
            type: i.type || INPUT_TYPES[ 0 ],
            placeholder: i.placeholder || undefined,
            canExclude: i.canExclude === false ? false : true,
            isRequired: i.isRequired || false,
            isDeleted: i.isDeleted || false
          });
        }
      );
    } else if( this.state.type === ADDRESS_AUTOSUGGEST ){
      updatedStep.distance = {
        shouldExit: this.state.distance.shouldExit,
        maxDistanceMeters: this.state.distance.maxDistanceMeters,
        origin: this.state.distance.origin,
      };

      if( this.state.distance.shouldExit && this.state.distance.origin ){
        return this.validateAddressAndUpdate(
          updatedStep
        );
      }
    }


    return this.props.onUpdateStep( updatedStep );
  }



  validateAddressAndUpdate = updatedStep => {
    if( !updatedStep ){
      return;
    }


    return this.setState(
      { isProcessing: true },
      () => Axios.get(
        '/api/core/geo/place_autocomplete',
        { params: { input: this.state.distance.origin } }
      ).then(response => {
        log('StepDialog loadPlaces', response);

        if(
          !(
            response.data.predictions &&
            response.data.predictions.length
          ) &&
          !window.confirm('We are having trouble finding the provided Origin. Using it may display error messages to users or provide in accurate results. Are you sure you want to continue?')
        ){
          return;
        }


        return this.props.onUpdateStep( updatedStep );
      })
    );
  }


  onUpdateType = e => {
    const newState = { type: e.target.value };

    if( GRADEABLE_STEP_TYPES.includes( e.target.value ) ){
      newState.shouldGrade = false;
    }


    return this.setState({
      ...newState
    });
  }



  generateOptionKey = (options, additionalExistingKey) => {
    if( !options ){
      return;
    }


    const keys = [
      ...( additionalExistingKey || [] )
    ];

    for( let o of options ){
      const key = +o.key;

      if( ![null, undefined, NaN].includes( key ) ){
        keys.push( key );
      }
    }


    if( !keys.length ){
      return 0
    }


    // Math.max will safely cast values to an int
    return ( Math.max( ...keys ) || 0 ) + 1;
  }



  onAddChoice = () => {
    const choices = [ ...this.state.choices ];

    choices.push({
      label: '',
      icon: Object.keys(ICONS)[ 0 ],
      grade: GRADES[ 0 ],
      shouldExit: false,
      canExclude: true,
      key: this.generateOptionKey( choices ),
      isDeleted: false
    });


    return this.setState({
      choices
    });
  }


  onAddInput = () => {
    const inputs = [ ...this.state.inputs ];

    inputs.push({
      label: '',
      type: INPUT_TYPES[ 0 ],
      placeholder: '',
      canExclude: true,
      key: this.generateOptionKey( inputs ),
      isDeleted: false
    });


    return this.setState({
      inputs
    });
  }


  isShouldExitSelected = () => {
    if( ![MULTIPLE_CHOICE, MULTIPLE_INPUT].includes( this.state.type ) ){
      return false;
    }

    const choiceInputs = (
      this.state.type === MULTIPLE_CHOICE ?
        this.state.choices
      :
        this.state.inputs
    );


    return ( choiceInputs || [] ).filter(
      c => !c.isDeleted
    ).map(
      c => c.shouldExit === true
    ).some(c => c);
  }


  onDeleteNestedField = (nestedFieldName, index) => {
    if( !window.confirm(`Are you sure you want to continue?`) ){
      return;
    }


    const newNestedField = JSON.parse(
      JSON.stringify( this.state[ nestedFieldName ] )
    );


    newNestedField[ index ].isDeleted = true;


    return this.setState({
      [ nestedFieldName ]: newNestedField
    });
  }


  render(){
    const { classes } = this.props;

    const isRequiredDisabled = [ADDRESS_KEY, CONTACT_KEY].includes(
      this.props.step ? this.props.step.key : null
    );

    const isRequiredHidden = (
      [ MULTIPLE_INPUT ].includes(
        this.props.step ? this.props.step.type : null
      ) &&
        this.props.step.key !== CONTACT_KEY
    );

    const canEdit = this.props.currentUserRole.roleHasAbility( Ability.EDIT_VIRTUAL_COMPANY_VIRTUAL_FORM_DESIGN );
    const isShutdown = this.props.companyVirtualProfile && this.props.companyVirtualProfile.isShutdown;

    // Will the text overflow on mobile?
    const hasExtendedChoiceMobileValue = Boolean(
      this.state.choices.filter(
        c => !c.isDeleted
      ).find(c => (
        c.label &&
        c.label.split(' ').some(cc => cc.length >= MAX_NO_WRAP_CHOICE_LABEL_CHARACTER_COUNT)
      ))
    );

    const hasExtendedInputMobileValue = Boolean(
      this.state.inputs.filter(
        i => !i.isDeleted
      ).find(i => (
        i.label &&
        i.label.split(' ').some(ii => ii.length >= MAX_NO_WRAP_INPUT_LABEL_CHARACTER_COUNT)
      ))
    );

    const hasExtendedMobileValue = (
      this.state.type === MULTIPLE_INPUT ?
        hasExtendedInputMobileValue
      :
        this.state.type === MULTIPLE_CHOICE ?
          hasExtendedChoiceMobileValue
        :
          null
    );


    const filteredInputs = this.state.inputs.filter(
      i => !i.isDeleted
    );

    const filteredChoices = this.state.choices.filter(
      c => !c.isDeleted
    );


    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth='md'
        fullWidth
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >

        <DialogTitle>
          { this.props.step ? 'Edit' : 'Add' } Step
        </DialogTitle>

        {
          this.isShouldExitSelected() ?
            <Alert danger>
              Note: If selected, one of this step's options may cause the customer to exit the Smart Form uncaptured.
            </Alert>
          :
            null
        }

        {
          hasExtendedMobileValue ?
            <Alert warning>
              Note: One of the options has content that may cause a scroll bar to appear on some small screen sizes.&nbsp;
              Max word letter count is { this.state.type === MULTIPLE_INPUT ? MAX_NO_WRAP_INPUT_LABEL_CHARACTER_COUNT : MAX_NO_WRAP_CHOICE_LABEL_CHARACTER_COUNT }.
            </Alert>
          :
            null
        }

        {
          (
            !this.state.shouldGrade &&
            GRADEABLE_STEP_TYPES.includes( this.state.type )
          ) ?
            <Alert warning>
              Note: This step will not impact the job's grade
            </Alert>
          :
            null
        }

        <DialogContent className={classes.dialogContent}>
          <TextField
            fullWidth
            required
            label='Headline'
            value={this.state.headline}
            onChange={e => this.setState({ headline: e.target.value })}
            variant='outlined'
            className={classes.textField}
            helperText={'What question do you want to ask?'}
          />

          <TextField
            fullWidth
            label='Caption'
            value={this.state.caption}
            onChange={e => this.setState({ caption: e.target.value })}
            variant='outlined'
            className={classes.textField}
            helperText={'What should show up directly under the question?'}
          />

          <TextField
            select
            required
            fullWidth
            label='Type'
            value={this.state.type}
            onChange={this.onUpdateType}
            variant='outlined'
            className={classes.textField}
          >
            {
              STEP_TYPES.map(
                (t, j) => (
                  <MenuItem value={t} key={j}>{ t }</MenuItem>
                )
              )
            }
          </TextField>

          <TextField
            fullWidth
            label='Helper Text'
            value={this.state.helperText}
            onChange={e => this.setState({ helperText: e.target.value })}
            variant='outlined'
            className={classes.textField}
            helperText={'What should show up at the bottom of the page? Note: words containing "@" will be converted to an email link.'}
          />

          <TextField
            select
            fullWidth
            label='Grade Step?'
            value={this.state.shouldGrade}
            onChange={e => this.setState({ shouldGrade: e.target.value })}
            variant='outlined'
            className={classes.textField}
            helperText={'Should this step be graded when determining the grade of the job? Note: Media Steps cannot be graded.'}
            disabled={!GRADEABLE_STEP_TYPES.includes( this.state.type )}
          >
            {
              [true, false].map(
                (t, j) => (
                  <MenuItem value={t} key={j}>{ t ? 'Yes': 'No' }</MenuItem>
                )
              )
            }
          </TextField>

          {
            isRequiredHidden ?
              null
            :
              <TextField
                select
                fullWidth
                label='Is Step Required?'
                value={this.state.isRequired}
                onChange={e => this.setState({ isRequired: e.target.value })}
                variant='outlined'
                className={classes.textField}
                helperText={'Are customers required to fill out this question?'}
                disabled={isRequiredDisabled}
              >
                {
                  [true, false].map(
                    (t, j) => (
                      <MenuItem value={t} key={j}>{ t ? 'Yes': 'No' }</MenuItem>
                    )
                  )
                }
              </TextField>
          }

          {
            this.state.type !== MULTIPLE_CHOICE ?
              null
            :
              <>
                <Typography variant='body1' className={classes.multipleTypeTitle}>
                  Choices*
                </Typography>

                <Grid container spacing={8}>
                  {
                    this.state.choices.map(
                      (c, j) => {
                        // Loop through all choices so indices are correct
                        if( c.isDeleted ){
                          return;
                        }


                        const filteredChoicesIndex = filteredChoices.findIndex(
                          fc => c.key === fc.key
                        );

                        return (
                          <React.Fragment key={j}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                required
                                label='Label'
                                value={c.label}
                                onChange={e => this.onChangeNestedValue('choices', 'label', e.target.value, j)}
                                variant='outlined'
                                className={classes.textField}
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                select
                                fullWidth
                                label='Grade'
                                value={c.grade}
                                onChange={e => this.onChangeNestedValue('choices', 'grade', e.target.value, j)}
                                variant='outlined'
                                className={classes.textField}
                                helperText={'When a customer selects this option, what grade should be given to the question?'}
                              >
                                {
                                  GRADES.map(
                                    (g, jj) => (
                                      <MenuItem value={g} key={jj}>
                                        { g }
                                      </MenuItem>
                                    )
                                  )
                                }
                              </TextField>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                select
                                fullWidth
                                label='Should Exit When Selected?'
                                value={c.shouldExit || false}
                                onChange={e => this.onChangeNestedValue('choices', 'shouldExit', e.target.value, j)}
                                variant='outlined'
                                className={classes.textField}
                                helperText={'When a customer selects this option, should the Smart Form inform the customer you cannot help them?'}
                              >
                                {
                                  [true, false].map(
                                    (t, jj) => (
                                      <MenuItem value={t} key={jj}>{ t ? 'Yes': 'No' }</MenuItem>
                                    )
                                  )
                                }
                              </TextField>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                select
                                fullWidth
                                label='Icon'
                                value={c.icon}
                                onChange={e => this.onChangeNestedValue('choices', 'icon', e.target.value, j)}
                                variant='outlined'
                                className={classes.textField}
                              >
                                {
                                  Object.keys( ICONS ).map(
                                    (icon, jj) => {
                                      const Icon = ICONS[ icon ];

                                      return (
                                        <MenuItem value={icon} key={jj}>
                                          <Icon />

                                          { icon }
                                        </MenuItem>
                                      )
                                    }
                                  )
                                }
                              </TextField>
                            </Grid>

                            {
                              c.canExclude ?
                                <div className={classes.deleteButtonDiv}>
                                  <Button
                                    variant='outlined'
                                    color='secondary'
                                    size='small'
                                    onClick={() => this.onDeleteNestedField('choices', j)}
                                  >
                                    Delete Choice
                                  </Button>
                                </div>
                              :
                                null
                            }

                            {
                              (
                                filteredChoices.length > 1 &&
                                filteredChoicesIndex !== filteredChoices.length - 1
                              ) ?
                                <Grid item xs={12} className={classes.divider}>
                                  <Divider />
                                </Grid>
                              :
                                null
                            }
                          </React.Fragment>
                        )
                      }
                    )
                  }

                  <Grid item xs={12} md={6}>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={this.onAddChoice}
                    >
                      Add Choice
                    </Button>
                  </Grid>
                </Grid>
              </>
          }

          {
            this.state.type !== MULTIPLE_INPUT ?
              null
            :
              <>
                <Typography variant='body1' className={classes.multipleTypeTitle}>
                  Inputs*
                </Typography>

                <Grid container spacing={8}>
                  {
                    this.state.inputs.map(
                      (ii, j) => {
                        // Loop through all inputs so indices are correct
                        if( ii.isDeleted ){
                          return;
                        }


                        const filteredInputsIndex = filteredInputs.findIndex(
                          fi => ii.key === fi.key
                        );

                        return (
                          <React.Fragment key={j}>
                            <Grid item xs={12} md={6} >
                              <TextField
                                fullWidth
                                required
                                label='Label'
                                value={ii.label}
                                onChange={e => this.onChangeNestedValue('inputs', 'label', e.target.value, j)}
                                variant='outlined'
                                className={classes.textField}
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                label='Placeholder'
                                value={ii.placeholder}
                                onChange={e => this.onChangeNestedValue('inputs', 'placeholder', e.target.value, j)}
                                variant='outlined'
                                className={classes.textField}
                                helperText={'When this question is not filled out, what should the customer see in the input box?'}
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                select
                                fullWidth
                                label='Input Type'
                                value={ii.type}
                                onChange={e => this.onChangeNestedValue('inputs', 'type', e.target.value, j)}
                                variant='outlined'
                                className={classes.textField}
                              >
                                {
                                  INPUT_TYPES.map(
                                    (it, jj) => (
                                      <MenuItem value={it} key={jj}>
                                        { INPUT_TYPE_MAP[ it ] }
                                      </MenuItem>
                                    )
                                  )
                                }
                              </TextField>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                select
                                fullWidth
                                label='Is Required?'
                                value={ii.isRequired || false}
                                onChange={e => this.onChangeNestedValue('inputs', 'isRequired', e.target.value, j)}
                                variant='outlined'
                                className={classes.textField}
                                disabled={!Boolean( ii.canExclude )}
                              >
                                {
                                  [false, true].map(
                                    (it, jj) => (
                                      <MenuItem value={it} key={jj}>
                                        { it ? 'Yes' : 'No' }
                                      </MenuItem>
                                    )
                                  )
                                }
                              </TextField>
                            </Grid>

                            {
                              ii.canExclude ?
                                <div className={classes.deleteButtonDiv}>
                                  <Button
                                    variant='outlined'
                                    color='secondary'
                                    size='small'
                                    onClick={() => this.onDeleteNestedField('inputs', j)}
                                  >
                                    Delete Input
                                  </Button>
                                </div>
                              :
                                null
                            }

                            {
                              (
                                filteredInputs.length > 1 &&
                                filteredInputsIndex !== filteredInputs.length - 1
                              ) ?
                                <Grid item xs={12} className={classes.divider}>
                                  <Divider />
                                </Grid>
                              :
                                null
                            }
                          </React.Fragment>
                        )
                      }
                    )
                  }



                  <Grid item xs={12} md={6}>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={this.onAddInput}
                    >
                      Add Input
                    </Button>
                  </Grid>
                </Grid>
              </>
          }

          {
            this.state.type !== ADDRESS_AUTOSUGGEST ?
              null
            :
              <>
                <TextField
                  select
                  fullWidth
                  label='Limit Distance?'
                  value={this.state.distance.shouldExit || false}
                  onChange={e => this.onChangeNestedValue('distance', 'shouldExit', e.target.value)}
                  variant='outlined'
                  className={classes.textField}
                  helperText={`If a customer's address if too far from your address (origin), should the Virtual Work Request let the customer know you cannot help them?`}
                >
                  {
                    [false, true].map(
                      (i, j) => (
                        <MenuItem value={i} key={j}>
                          { i ? 'Yes' : 'No' }
                        </MenuItem>
                      )
                    )
                  }
                </TextField>

                <TextField
                  fullWidth
                  required
                  label='Max Distance Miles'
                  value={this.state.distance[ MAX_DISTANCE_MILES ] || 0}
                  onChange={e => this.onChangeNestedValue('distance', MAX_DISTANCE_MILES, +e.target.value || 0)}
                  variant='outlined'
                  className={classes.textField}
                  type='number'
                  helperText={`What is the maximum distance you are willing to travel, in miles? NOTE: This value is automatically converted to kilometers.`}
                  disabled={!Boolean(this.state.distance.shouldExit)}
                />

                <TextField
                  fullWidth
                  required
                  label='Max Distance Kilometers'
                  value={this.state.distance[ MAX_DISTANCE_KILOMETERS ] || 0}
                  onChange={e => this.onChangeNestedValue('distance', MAX_DISTANCE_KILOMETERS, +e.target.value || 0)}
                  variant='outlined'
                  className={classes.textField}
                  type='number'
                  helperText={`What is the maximum distance you are willing to travel, in kilometers? NOTE: This value is automatically converted to miles.`}
                  disabled={!Boolean(this.state.distance.shouldExit)}
                />

                <TextField
                  fullWidth
                  required
                  label='Origin'
                  value={this.state.distance.origin || ''}
                  onChange={e => this.onChangeNestedValue('distance', 'origin', e.target.value)}
                  variant='outlined'
                  className={classes.textField}
                  helperText={`When calculating the distance to the customer's address, what address should be used as the origin? NOTE: Try entering the address without the suite number. For example, do not include "Suite 500" in "5000 Forbes Ave., Pittsburgh, Pennsylvania 15213".`}
                  disabled={!Boolean(this.state.distance.shouldExit)}
                />
              </>
          }
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={this.props.onClose}
            color='primary'
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          {
            !isShutdown && canEdit ?
              <Button
                variant='contained'
                onClick={this.onUpdateStep}
                color='primary'
              >
                Update
              </Button>
            :
              null
          }
        </DialogActions>
      </Dialog>
    )
  }
}


StepDialog.defaultProps = {
  step: null,
  open: false,
  onClose: () => {},
  onUpdateStep: () => {},
  generateStepKey: () => {}
}


const styles = theme => ({
  dialogContent: {
    paddingTop: 10
  },
  textField: {
    marginBottom: 30
  },
  multipleTypeTitle: {
    marginBottom: 15
  },
  divider: {
    marginBottom: 15
  },
  cancelButton: {
    marginRight: 15
  },
  deleteButtonDiv: {
    width: '100%',
    textAlign: 'right',
    marginBottom: 15
  }
})


export default withContext(
  CompanyVirtualProfileContext,
  CompanyRoleContext,
  SnackbarContext,
  withStyles(styles)(
    StepDialog
  )
)
import React from "react";
import Axios from "axios";
import { log, withContext } from "kn-react";
import { UserRoleContext } from 'go-boost-library-react';
import {
  TextField,
  Button,
  withStyles,
} from "@material-ui/core";
import CouponModel from "./CouponModel";

class CouponCode extends React.Component {
  state = {
    couponCode: "",
    loading: false,
    error: false,
    coupon: null,
  };

  onSubmitCheckCode = e => {
    e.preventDefault();

    const couponCode = this.state.couponCode.trim();

    return Axios.get(`/api/core/coupons`, {
      params: { coupon_code: couponCode },
      headers: this.props.getUserRoleAuthHeaders(),
    })
      .then((response) => {
        log("onSubmitCheckCode response", response);

        const coupon = CouponModel.fromJSON(
          response.data.coupon
        );
        this.setState({ error: false });

        this.props.onValidCouponCode(coupon);
      })
      .catch(e => {
        this.setState({ error: true });
      });
  };

  onBlur = (e) => {
    const couponCode = this.state.couponCode.trim();
    if (!couponCode) {
      this.setState({ error: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { couponCode, error } = this.state;

    return (
      <form onSubmit={this.onSubmitCheckCode} className={classes.root}>
        <TextField
          className={classes.textField}
          error={error}
          helperText={error ? "Invalid coupon code." : ""}
          label="Have a Coupon Code?"
          fullWidth
          margin="dense"
          disabled={this.state.loading}
          value={this.state.couponCode}
          onChange={(e) =>
            this.setState({ couponCode: e.target.value.replace(' ', '') })
          }
          onBlur={this.onBlur}
        />
        <Button
          type="submit"
          margin="dense"
          variant="outlined"
          color="primary"
          className={classes.button}
          aria-label="Apply Coupon Code"
          disabled={!couponCode.length}
        >
          Apply
        </Button>
      </form>
    );
  }
}

CouponCode.defaultProps = {
  onValidCouponCode: () => {},
};

const styles = {
  root: {
    display: "flex",
  },
  textField: {
    flex: 1,
  },
  button: {
    marginLeft: 15,
    marginTop: 17,
    marginBottom: 20,
    height: 36
  }
};

export default withContext(
  UserRoleContext,
  withStyles(styles)(CouponCode)
);

import React from 'react';
import { withContext } from 'kn-react';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import ProductPricingTable from 'SharedSetup/ProductPlanSetup/ProductPricingTable';
import SocialUndoResubscriptionAlert from 'Social/CompanySocial/SocialUndoResubscriptionAlert/SocialUndoResubscriptionAlert';

class SocialPlanSetup extends React.Component {
  render() {
    return (
      <ProductPricingTable
        {...this.props}
        subscribedProductPlan={this.props.subscribedSocialPlan}
        productPlans={this.props.upgradableSocialPlans}
        productName="Social"
        productUndoResubscriptionAlert={SocialUndoResubscriptionAlert}
      />
    );
  }
}

export default withContext(TransitionContext, SocialPlanSetup);
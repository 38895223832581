import React from 'react';
import { Link } from 'react-router-dom';

import { ServicesColors, UserRoleContext, Ability } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Dashboard, Call, Build, People, CalendarToday as CalendarIcon } from '@material-ui/icons';
import { Typography, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ProductCard from '../ProductCard/ProductCard';

const colors = ServicesColors.social;

const goToSocial = props => {
  const { classes } = props;
  return (
    <ProductCard colors={colors} icon={People} backgroundImage="/images/social_background.png">

      <Typography paragraph variant="h6">
        Social
      </Typography>

      <Button
        fullWidth
        variant="contained"
        component={Link}
        to="/social"
        className={classes.containedButton}
        style={{ backgroundColor: colors.primary, color: colors.contrastText }}
      >
        <Dashboard className={classes.leftIcon}/>
        Dashboard
      </Button>


      <Button
        fullWidth
        variant="outlined"
        component={Link}
        to="/social/timeline"
        className={classes.outlinedButton}
        style={{ borderColor: colors.primary, color: colors.primary }}
      >
        <CalendarIcon className={classes.leftIcon}/>
        Timeline
      </Button>


    </ProductCard>
  );
}


const styles = theme => ({
  containedButton: {
    color: 'white',
    backgroundColor: '#FD9A51',
    '&:hover': {
      backgroundColor: '#FD9A51',
    },
    marginBottom: 15
  },
  outlinedButton: {
    borderColor: '#FD9A51',
    '&:hover': {
      borderColor: '#FD9A51',
    },
    marginBottom: 15
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
})

export default withStyles(styles)(
  withContext(UserRoleContext, goToSocial)
);
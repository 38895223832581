import React from 'react';
import { Switch,  Route } from 'react-router-dom';
import UserIndex from './UserIndex/UserIndex';
import UserDetails from '../UserDetails/UserDetails';

export default class CompanyUsers extends React.Component {

  render(){

    return (
      <Switch>
        <Route exact path={['/users', '/users/user_invitations/new']} component={ UserIndex }/>
        <Route exact path="/users/:id" component={ UserDetails }/>
      </Switch>
    );
  }

}

import React from 'react';

import { withContext } from 'kn-react';

import { Alert, ThemeContext } from "go-boost-library-react";
import { withStyles, Grid } from "@material-ui/core";
import { LibraryBooks } from '@material-ui/icons';

const knowledgeBaseLaunchBanner = props => (
  <Grid item sm={12}>
    <Alert info className={props.classes.alert}>
      <div className={props.classes.iconWrapper}>
        <LibraryBooks />
      </div>

      <div className={props.classes.text}>
        Check out our new Knowledge Base and learn how to make the most of GoBoost.
        &nbsp;
        <a target='_blank' href={`${ props.theme.knowledgeBaseUrl || 'https://support.goboost.com/goboost' }`}>Click here to get started.</a>
      </div>
    </Alert>
  </Grid>
);



const styles = theme => ({
  alert: {
    display: 'block',
    textDecoration: 'none'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: 50,
  },
  text: {
    marginLeft: 30,
    lineHeight: '24px'
  }
})



export default withContext(
  ThemeContext,
  withStyles(styles, { withTheme: true })(
    knowledgeBaseLaunchBanner
  )
);
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { log, MaxWidth, withContext, format, AutoError } from "kn-react";
import { PromiseButton, SnackbarContext, PlanContext, CompanyRoleContext } from "go-boost-library-react";

import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Button, Grid, LinearProgress, Link, Paper, Typography, withStyles } from "@material-ui/core";

import { trackCurrentUserSegmentEvent } from "SharedSetup/setupHelpers";
import { TransitionContext } from "TransitionProvider/TransitionProvider";
import CancelSetupButton from "SharedSetup/CancelSetupButton/CancelSetupButton";
import CouponCheckoutRow from "SharedSetup/CouponCode/CouponCheckoutRow";
import CouponCode from "SharedSetup/CouponCode/CouponCode";
import domainYearlyCostCents from "../DomainSetup/DomainSettings/domainYearlyCostCents";
import hashIdCoder from "hashIdCoder";
import paperPadding from "paperPadding";
import PaymentMethodForm from "Billing/PaymentMethod/PaymentMethodForm/PaymentMethodForm";
import SitesUndoResubscription from 'Sites/CompanySites/SitesSetup/SitesUndoResubscription/SitesUndoResubscription';

class CheckoutSetup extends React.Component {
  state = {
    loading: true,
    processing: false,
    selectedPlan: {},
    coupon: null
  };

  componentDidMount = () => {
    const {
      selectedPlanId,
      upgradableSitesPlans,
      currentUserRole,
      getUserRoleAuthHeaders,
    } = this.props;
    log("upgradableSitesPlans", upgradableSitesPlans);
    log("selectedPlanId", selectedPlanId);

    const selectedPlan = upgradableSitesPlans.find(
      (p) => p.id == selectedPlanId
    );
    log("selectedPlan", selectedPlan);

    this.setState({ selectedPlan, loading: false });

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      "finished_sites_service_area_step",
      getUserRoleAuthHeaders
    );
    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      "started_sites_checkout_step",
      getUserRoleAuthHeaders,
      { funnel: true }
    );
  };

  shouldComponentUpdate = (nextProps) => {
    // If we allow the component to update while we're processing
    // payment, the promise button can re-render, and enable itself.
    return !this.state.processing;
  };

  onClickBack = () => {
    return this.props.transitionTo("back", this.props.transitions);
  };

  onClickPay = () => {
    const { selectedPlan, coupon } = this.state;

    const companySiteDesign = this.props.companySiteDesigns.find(
      (d) => d.id === this.props.companySitesProfile.companySiteDesignId
    );

    const siteDesign = this.props.siteDesigns.find(
      (d) => d.id === companySiteDesign.siteDesignId
    );

    trackCurrentUserSegmentEvent(
      this.props.currentUserRole.userId,
      "finished_sites_checkout_step",
      this.props.getUserRoleAuthHeaders,
      { funnel: true }
    );

    let site;
    return this.props.findOrCreateCustomer()
      .then(() => this.props.findOrCreateSubscription(selectedPlan.id, coupon ? coupon.id : undefined))
      .then(() =>
        this.props.duplicateSite(siteDesign.baseSiteId, companySiteDesign.id)
      )
      .then((_site) => {
        site = _site;

        return this.props.updateCompanySitesProfile({
          testLink: `https://site-${hashIdCoder.encode(
            site.id
          )}-preview.goboost.xyz`,
        });
      })
      .then(() => this.props.enqueueCompanySitesProfileSetup(site))
      .then(this.props.fetchCompanySiteDesigns)
      .then(this.props.fetchCompanyRoleProviderData)
      .then(() =>
        this.props.showSnackbar("Bots are getting things ready for your site.")
      )
      .then(() => this.props.transitionTo("next", this.props.transitions))
      .catch((error) => {
        const { response } = error;
        log('error', error);
        if (response && response.data && response.data.message) {
          throw {
            name: "ClickPayError",
            message: response.error.message,
          };
        } else {
          throw error;
        }
      });
  };

  onStripeError = (error) => {
    this.props.showSnackbar(
      error.message ||
        "Something went wrong processing your payment! Contact us for assistance."
    );
  };

  onClickPayError = (error) => {
    log("onClickPayError", error);
    this.props.showSnackbar(
      error.message || "Something went wrong! Contact us for assistance."
    );
  };

  onValidCouponCode = coupon => {
    this.setState({ coupon });
  }

  onClickRemoveCoupon = () => {
    this.setState({ coupon: null });
  }

  computeTodaysTotalCents = () => {
    const { companySitesProfile } = this.props;
    const { selectedPlan, coupon } = this.state;
    const isSubscribedToSelectedPlan = this.props.isSubscribedToPlanId(selectedPlan.id);


    if( selectedPlan.trialPeriodDays ) {
      return isSubscribedToSelectedPlan ? 0 : selectedPlan.setupFeeCents || 0;
    }

    let todaysTotalCents =  isSubscribedToSelectedPlan ? 0 : (selectedPlan.planAmountCents + selectedPlan.setupFeeCents);
    todaysTotalCents += companySitesProfile.newDomain ? domainYearlyCostCents : 0;

    // Coupons are only applied to subscriptions when they are created
    if( selectedPlan.planAmountCents && !isSubscribedToSelectedPlan ){
      todaysTotalCents -= coupon ? coupon.getDiscountCentsWithPlan(selectedPlan) : 0;
    }

    return todaysTotalCents;
  }

  render() {
    const { classes, company, companySitesProfile } = this.props;
    const { selectedPlan, loading, coupon } = this.state;

    if (loading) {
      return <LinearProgress />;
    }

    const todaysTotalCents = this.computeTodaysTotalCents();
    const isSubscribedToSelectedPlan = this.props.isSubscribedToPlanId(selectedPlan.id);

    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <SitesUndoResubscription />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5">Today's Summary</Typography>
            </Grid>

            {/* <CancelSetupButton /> */}

            <Grid item xs={12}>
              <Paper className={classes.paperRoot}>
                <List>
                  {
                    isSubscribedToSelectedPlan ?
                      <ListItem>
                        <ListItemText
                          primary={`${selectedPlan.name} Plan`}
                        />
                        <ListItemSecondaryAction>
                          <ListItemText
                            style={{ textAlign: 'right' }}
                            primary="Subscribed"
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    :
                      <ListItem>
                        <ListItemText
                          primary={`${selectedPlan.name} Plan`}
                          secondary={`${selectedPlan.capitalizedBillingPeriod()} Recurring Subscription ${ selectedPlan.trialPeriodDays ? `, ${selectedPlan.trialPeriodDays} day free trial` : ''}`}
                        />
                        <ListItemSecondaryAction>
                          <ListItemText
                            style={{ textAlign: "right" }}
                            primary={format.money(
                              selectedPlan.planAmountCents / 100
                            )}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                  }

                  { coupon ? <CouponCheckoutRow coupon={coupon} selectedPlan={selectedPlan} onClickRemove={this.onClickRemoveCoupon} /> : null }

                  {companySitesProfile.newDomain ? (
                    <ListItem>
                      <ListItemText
                        primary={companySitesProfile.newDomain}
                        secondary="Yearly Recurring"
                      />
                      <ListItemSecondaryAction>
                        <ListItemText
                          style={{ textAlign: "right" }}
                          primary={format.money(domainYearlyCostCents / 100)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : null}


                  {
                    !isSubscribedToSelectedPlan ?
                    <ListItem>
                      <ListItemText primary="Setup Fee" />
                      <ListItemSecondaryAction>
                        <ListItemText
                          style={{ textAlign: "right" }}
                          primary={format.money(
                            selectedPlan.setupFeeCents / 100
                          )}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    : null
                  }


                  <Divider />
                  <ListItem>
                    <ListItemText primary="Today's Total" />
                    <ListItemSecondaryAction>
                      <ListItemText
                        style={{ textAlign: "right" }}
                        primary={format.money(todaysTotalCents / 100)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>

                { selectedPlan.isCreditCardRequired() && !isSubscribedToSelectedPlan ? <CouponCode onValidCouponCode={this.onValidCouponCode}/> : null }

              </Paper>
            </Grid>

            {company.stripeLastFour ? (
              <Grid item xs={12}>
                <Typography className={classes.paymentMethod} variant="body2">
                  Paying with card ending in{" "}
                  <Link component={RouterLink} to="/billing/payment_method">
                    {company.stripeLastFour}
                  </Link>
                </Typography>
              </Grid>
            ) : null}

            {company.stripeLastFour || !selectedPlan.isCreditCardRequired() ? (
              <>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.onClickBack}
                  >
                    Back
                  </Button>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <PromiseButton
                    buttonProps={{
                      fullWidth: false,
                      variant: "contained",
                      color: "primary",
                      type: "submit",
                    }}
                    type="submit"
                    onProcess={() => {
                      this.setState({ processing: true });

                      return this.onClickPay()
                        .catch(AutoError.catch.bind(this))
                        .finally(() => this.setState({ processing: false }));
                    }}
                  >
                    {selectedPlan.isCreditCardRequired() ? 'Pay' : 'Setup'}
                  </PromiseButton>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <PaymentMethodForm
                  showUpdate={false}
                  processing={this.state.processing}
                  children={(paymentMethodForm) => (
                    <>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.onClickBack}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <PromiseButton
                          buttonProps={{
                            fullWidth: false,
                            variant: "contained",
                            color: "primary",
                            type: "submit",
                          }}
                          type="submit"
                          onProcess={() => {
                            this.setState({ processing: true });

                            return this.props.findOrCreateCustomer()
                              .then(paymentMethodForm.onClickSave)
                              .then(this.onClickPay)
                              .catch(AutoError.catch.bind(this))
                              .finally(() =>
                                this.setState({ processing: false })
                              );
                          }}
                        >
                          Pay
                        </PromiseButton>
                      </Grid>
                    </>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </MaxWidth>
    );
  }
}



const styles = (theme) => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: paperPadding,
  },
  paymentMethod: {
    textAlign: "right",
    marginTop: 30,
    marginRight: 10,
  },
});



export default withContext(
  PlanContext,
  CompanyRoleContext,
  TransitionContext,
  SnackbarContext,
  withStyles(styles)(
    CheckoutSetup
  )
);
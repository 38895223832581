import React from 'react';
import Axios from 'axios';
import { log, withContext } from 'kn-react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { UserRoleContext } from 'go-boost-library-react';


const renderInputComponent = inputProps => {
  const { classes, inputRef = () => {}, ref, onClear, loading, ...other } = inputProps;
  return (
    <>
    <TextField
      fullWidth
      label={inputProps.label}
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        autoComplete:"new-password",
        classes: {
          input: classes.input,
        },
        endAdornment:
          <InputAdornment position="end">
            {
              loading ?
                <CircularProgress/>
              :
                inputProps.value ?
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={onClear}
                  >
                    <Clear/>
                  </IconButton>
                : ''
            }
          </InputAdornment>
      }}
      {...other}
    />
    </>
  );
}

const renderSuggestion = (suggestion, { isHighlighted }) => {

  return (
    <MenuItem selected={isHighlighted} component="div">
      <ListItemText
        primary={suggestion.name}
        secondary={ `${suggestion.city}, ${ suggestion.state}` }
      />
    </MenuItem>
  );
}


const getSuggestionValue = suggestion => {
  return suggestion.name;
}

const shouldRenderSuggestions = value => {
  return value.trim().length > 2;
}


class CompanyAutosuggest extends React.Component {
  state = {
    inputValue: '',
    loading: false,
    suggestions: [],
  };

  componentDidMount = () => {
    const {initialInputValue } = this.props;
    if(initialInputValue) {
      this.setState({ inputValue: initialInputValue });
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ loading: true });
    this.props.onSuggestionsFetchRequested();

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      Axios.get(
        this.props.suggestionsUrl,
        {
          params: {
            limit: 10,
            include_descendants: true,
            query: value
          },
          headers: this.props.getUserRoleAuthHeaders()
        }
      ).then(response => {
        log('onSuggestionsFetchRequested', response);
        this.props.onSuggestionsFetchCompleted();
        this.setState({ suggestions: response.data.companies, loading: false });
      });
    }, 250);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    log('onSuggestionSelected', suggestion);
    this.props.onSuggestionSelected(suggestion);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      inputValue: newValue,
    });
    this.props.onChange(newValue);
  };


  onClear = () => {
    this.setState({ inputValue: '' });
    this.props.onClear();
  };


  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionValue,
      shouldRenderSuggestions,
      renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label: this.props.label,
            value: this.state.inputValue,
            loading: this.state.loading,
            onChange: this.onChange,
            onClear: this.onClear
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} style={{ marginTop: 0, overflowY: 'inherit' }} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

CompanyAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

CompanyAutosuggest.defaultProps = {
  label: "Search by company name...",
  suggestionsUrlParams: {},
  initialInputValue: '',
  onChange: () => {},
  onClear: () => {},
  onSuggestionsFetchRequested: () => {},
  onSuggestionsFetchCompleted: () => {},
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});


export default withStyles(styles)(
  withContext(UserRoleContext, CompanyAutosuggest)
);

import { JsonModel } from 'kn-react';

export default class CompanySocialProfileModel {

  constructor(props) {
    for (let k in props) {
      this[k] = props[k];
    }
  }

  static fromJSON(json) {
    let model = new CompanySocialProfileModel();
    model = JsonModel.modelFromJSON(model, json);
    return model;
  }

  toJSON() {
    return JsonModel.modelToJSON(this);
  }

}

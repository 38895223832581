import { JsonModel } from 'kn-react';

export default class OrganizationModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    const model = new OrganizationModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }
}



OrganizationModel.prototype.isGoBoost = function(){
  return +this.id === 1;
}
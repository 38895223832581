import React from 'react';

import { IconButton, SvgIcon } from '@material-ui/core';


const buildingHomeIcon = props => (
  <IconButton
    aria-label="Building Home Icon"
    style={{ backgroundColor: '#F7F6F6' }}
    disabled
  >
    <SvgIcon
      viewBox="0 0 50 50"
      style={{ fontSize: props.fontSize }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5263 8.29381L0.0333071 16.6629L0.00753664 18.5899C-0.0131754 20.1366 0.00572313 20.4998 0.102984 20.4299C0.169702 20.382 5.79909 16.6107 12.6128 12.0493C24.7043 3.95472 25.0068 3.75898 25.2252 3.89302C25.442 4.02616 43.4431 16.082 47.8286 19.0313L50 20.4915V18.5773V16.6631L37.6157 8.36981C30.8043 3.80852 25.1837 0.0423301 25.1254 0.000571951C25.0578 -0.0477137 20.4834 2.96325 12.5263 8.29381ZM37.1973 9.04975L49.2364 17.1153V18.0489C49.2364 18.7728 49.2096 18.9672 49.1171 18.914C49.0115 18.8533 25.8935 3.37163 25.2693 2.94358L25.0209 2.77315L13.052 10.7932C6.46923 15.2043 1.02806 18.8551 0.960578 18.9062C0.861694 18.981 0.843178 18.8102 0.86513 18.0286L0.892333 17.0579L12.9187 8.9893C19.5332 4.55158 24.993 0.934989 25.0516 0.952514C25.1103 0.969951 30.5758 4.61373 37.1973 9.04975ZM14.4936 13.1935L4.0421 20.1905L4.01795 35.0953L3.9938 50H25.0402H46.0867V35.1278V20.2558L35.6368 13.263C29.8894 9.41699 25.1325 6.25365 25.066 6.23335C24.9995 6.21306 20.2419 9.3451 14.4936 13.1935ZM35.1849 13.9199L45.2236 20.6262L45.2256 34.9107L45.2276 49.1952H25.0405H4.8534V34.9021V20.609L14.8992 13.89C20.4245 10.1946 24.9903 7.18065 25.0457 7.19227C25.1009 7.20399 29.6636 10.2314 35.1849 13.9199ZM28.9448 26.1475C26.7449 28.6151 24.4674 31.1657 23.8838 31.8157L22.8224 32.9975L20.1832 30.5915L17.544 28.1854L16.8948 28.832C16.5377 29.1876 15.7458 29.9816 15.1352 30.5963L14.0249 31.714L14.6649 32.3046C17.421 34.8482 23.1764 40.0746 23.2213 40.0746C23.2518 40.0746 26.2673 36.7047 29.9223 32.5859C33.5772 28.4671 36.6293 25.0304 36.7048 24.9489C36.8285 24.8152 36.661 24.6525 34.9978 23.2904C33.9835 22.4598 33.1065 21.7534 33.049 21.7207C32.9875 21.6856 31.3007 23.5049 28.9448 26.1475Z"
        fill={props.fill}
      />
    </SvgIcon>
  </IconButton>
);


buildingHomeIcon.defaultProps = {
  fill: '#5E5F64'
}


export default buildingHomeIcon;
import Axios from 'axios'

export default (url, requestOptions = {}) => {
  return Axios({
    ...requestOptions,
    url,
    method: 'GET',
    responseType: 'blob', // important
  }).then(response => {
    const contentDisposition = response.headers['content-disposition'];

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', contentDisposition.split('=')[1]);

    link.click();
  });
}
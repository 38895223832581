import React from 'react';

import { log, formHelpers, AutoError, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';



class EditByoDomainDialog extends React.Component {
  state = {
    byoDomain: ''
  };

  componentDidMount = () => {
    return this.initializeByoDomain();
  }


  componentDidUpdate = oldProps => {
    if( this.props !== oldProps ){
      return this.initializeByoDomain();
    }
  }


  initializeByoDomain = () => {
    return this.setState({
      byoDomain: this.props.companySitesProfile.byoDomain
    });
  }


  onProcess = () => {
    log('onProcess', this.state);
    return this.validate()
      .then(() => this.props.onUpdateByoDomain( this.state.byoDomain ))
      .catch(AutoError.catch.bind(this));;
  }

  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }

  onError = error => {
    log('error', error);
    this.props.showSnackbar(error.response.data.message)
  }

  validate = () => {
    const baseValidations = {
      byoDomain: {
        presence: { message: 'Please enter a valid BYO domain.' }
      }
    };
    return formHelpers.validate(this.state, baseValidations);
  }


  onClose = () => {
    this.initializeByoDomain();

    return this.props.onClose();
  }

  render(){
    const { classes } = this.props;

    return (
      <Dialog open={this.props.open} maxWidth="sm" onClose={this.onClose}>
        <DialogTitle id="form-dialog-title">{ this.props.isNewByoDomain ? 'Add' : 'Edit' } BYO Domain</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>NOTE:</b> Updating the BYO domain will temporarily invalidate its SSL certificate.
            </DialogContentText>

            <TextField
              fullWidth
              variant='outlined'
              label="BYO Domain"
              className={classes.textField}
              value={this.state.byoDomain || ''}
              onChange={e => this.setState({ byoDomain: e.target.value })}
              onBlur={e => this.setState({ byoDomain: this.state.byoDomain.trim() })}
            />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={this.onClose}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>

          <PromiseButton
            onProcess={this.onProcess}
          >
            Update
          </PromiseButton>
        </DialogActions>

      </Dialog>
    );
  }

}

const styles = theme => ({
  textField: {
    marginTop: 15
  }
});


EditByoDomainDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onUpdateByoDomain: () => {},
  companySitesProfile: {},
  isNewByoDomain: false
}

export default withStyles(styles)(
  withContext(
    CompanyRoleContext,
    SnackbarContext,
    EditByoDomainDialog
  )
);
import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { List, ListItem, ListItemText, Button, ListItemSecondaryAction } from '@material-ui/core';


const companiesList = props => {
  const listItemProps = c => (props.shouldRoute ?
      {
        component: RouterLink,
        to: `/companies/${c.id}`
      }
    :
      {
        onClick: () => props.onClick(c)
      })
  ;
  return (
    <List style={{ opacity: props.refreshing ? 0.5 : 1 }}>
      {
        props.companies.map(c => (
          <ListItem
            button
            key={c.id}
            disabled={props.loading || props.refreshing}
            name={`company-${c.id}`}
            selected={props.selectedCompanyIds && props.selectedCompanyIds.includes( +c.id )}
            {...listItemProps(c)}
          >
            <ListItemText
              primary={c.name}
              secondary={
                <>
                  {`${c.city}, ${c.state}`}<br />
                  {
                    (props.showDescendantsInfo) ?
                      <small>
                        { (c.organizationName || []).join(', ') }
                      </small>
                      :
                      null
                  }
                </>
              }
            />

            {
              props.listItemSecondaryAction ?
                  <ListItemSecondaryAction>
                    {props.listItemSecondaryAction(c)}
                  </ListItemSecondaryAction>
                :
                  null
            }
          </ListItem>
        ))
      }
    </List>
  )
}

companiesList.defaultProps = {
  shouldRoute: true,
  loading: false,
  refreshing: false,
  companies: [],
  showDescendantsInfo: false,
  listItemSecondaryAction: () => <></>,
  selectedCompanyIds: []
}

export default companiesList
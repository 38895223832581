import React from 'react';

import { MegaphoneIcon, WebsiteVisitorIcon, ServicesColors, PlanContext } from 'go-boost-library-react';
import { withContext, MaxWidth, format, log } from 'kn-react';

import { Done, Help, OpenInNew, StarRate, Videocam, People as PeopleIcon } from '@material-ui/icons'
import { withStyles, Link, Grid, Typography, Button, Tooltip, Table, TableRow, TableHead, TableCell, TableBody, Paper, Switch } from '@material-ui/core';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';

class ProductPricingTable extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      selectedPlanId: this.props.selectedPlanId || (
        this.props.subscribedProductPlan ? this.props.subscribedProductPlan.id : ''
      ),
      longestStringSideLength: 0,
      longestVerticalLength: 0,
      billingPeriod: 'monthly',
      expandedProductIndices: [0],
      hasPlanWithYearlyBilling: false
    };
  }


  componentDidMount = () => {
    const yearlyBillingPlanIds = this.props.productPlans.filter(
      p => p.billingPeriod === 'yearly'
    ).map(p => p.id);

    const billingPeriod = (
      (
        this.state.selectedPlanId &&
        yearlyBillingPlanIds.includes( this.state.selectedPlanId )
      ) ?
        'yearly'
      :
        this.state.billingPeriod
    );


    return this.setState({
      hasPlanWithYearlyBilling: Boolean(
        yearlyBillingPlanIds.length
      ),
      billingPeriod
    });
  }


  selectPlan = plan => {
    this.setState({ selectedPlanId: plan.id });

    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'plan_selected', this.props.getUserRoleAuthHeaders, plan);
  }


  includedServices = plan => {
    const services = ['isAdsIncluded', 'isReviewsIncluded', 'isSitesIncluded'];
    const includedServices = services.filter(s => plan[s]).map(s => s.replace(/is(.+)Included/, '$1')).join(', ')
    log('includedServices', includedServices);
    return includedServices;
  }


  onClickBack = () => {
    this.props.transitionTo('back', this.props.transitions);
  }

  onClickNext = () => {
    const { selectedPlanId } = this.state;
    return this.props.transitionTo(
      'next',
      this.props.transitions,
      { selectedPlanId }
    );
  }



  sortedPlansAndAttributesByProduct = ( billingPeriod='monthly' ) => {
    const sortedPlans = this.props.productPlans.filter(
      p => p.billingPeriod === billingPeriod
    ).sort(
      (a, b) => ( a.name || '' ).localeCompare( b.name || '')
    );


    let attributesByProduct = {};
    sortedPlans.forEach(
      plan => {
        if( plan.attributes ){
          Object.keys( plan.attributes ).forEach(
            p => {
              if( !Object.keys( attributesByProduct ).includes( p ) ){
                attributesByProduct[ p ] = []
              }

              plan.attributes[ p ].forEach(a => {
                if( !attributesByProduct[ p ].map(a => a.label).includes( a.label ) ){
                  attributesByProduct[ p ].push( a );
                }
              });
            }
          );
        }
      }
    );


    return {
      sortedPlans,
      attributesByProduct
    }
  }



  tableHeadRow = () => {
    const { classes } = this.props;

    const { sortedPlans } = this.sortedPlansAndAttributesByProduct(
      this.state.billingPeriod
    );

    const plansHaveAttributes = sortedPlans.map(
      p => p.attributes || {}
    ).some(
      a => a && Object.keys( a ).length
    );


    return (
      <>
        <TableRow>
          <TableCell
            className={`${ classes.tableCell } ${ classes.topTableRowCell } ${ classes.tableHeadCell }`}
          />

          <TableCell
            className={`${ classes.helperTextCell } ${ classes.tableCell } ${ classes.topTableRowCell } ${ classes.tableHeadCell }`}
          />

          {
            sortedPlans.map((p, i) => {
              let style = undefined;

              if( !i ){
                style = { borderTopLeftRadius: 2 };
              } else if( i === sortedPlans.length-1 ){
                style = { borderTopRightRadius: 2 };
              }

              const isSelected = p && p.id === this.state.selectedPlanId;

              let selectedCellClass;
              if( isSelected ){
                selectedCellClass = classes.selectedTopCell;
              }

              return (
                <TableCell
                  className={`${ selectedCellClass } ${ classes.tableCell } ${ classes.topTableRowCell } ${ classes.tableHeadCell }`}
                  key={i}
                  style={style}
                  onClick={() => this.selectPlan(p)}
                  component='td'
                >
                  <Typography variant={'body1'}>
                    { p.name }
                  </Typography>

                  <Typography variant={'h4'}>
                    ${ p.planAmountCents ? format.commas( p.planAmountCents / 100 ) : 0 }
                  </Typography>

                  {
                    this.state.hasPlanWithYearlyBilling ?
                      null
                    :
                      <Typography variant={'caption'}>
                        { p.billingPeriod || '' }
                      </Typography>
                  }
                </TableCell>
              )}
            )
          }
        </TableRow>

        <TableRow>
          <TableCell
            className={`${ classes.tableCell } ${ classes.tableHeadCell }`}
          />

          <TableCell
            className={`${ classes.tableCell } ${ classes.helperTextCell } ${ classes.tableHeadCell }`}
          />

          {
            sortedPlans.map((p, i) => {
              let style = { paddingTop: 0 };

              if( !i ){
                style.borderTopLeftRadius = 2;
              } else if( i === sortedPlans.length-1 ){
                style.borderTopRightRadius = 2;
              }

              const isSelected = p && p.id === this.state.selectedPlanId;

              let selectedCellClass;
              if( isSelected && !plansHaveAttributes ){
                selectedCellClass = classes.selectedBottomCell;
              } else if( isSelected ){
                selectedCellClass = classes.selectedCell;
              }

              return (
                <TableCell
                  className={`${ selectedCellClass } ${ classes.tableCell } ${ classes.tableHeadCell }`}
                  key={i}
                  style={style}
                  onClick={() => this.selectPlan(p)}
                  component='td'
                >
                  <Button
                    onClick={() => this.selectPlan( p )}
                    size='small'
                    color={'primary'}
                    variant={isSelected ? 'contained' : 'outlined'}
                  >
                    { isSelected ? 'Selected' : 'Select' } Plan
                  </Button>
                </TableCell>
              )}
            )
          }
        </TableRow>
      </>
    );
  }


  tableRowsForProduct = (sortedPlans, products, attributes, attribute, product, productIndex, attributeIndex) => {
    const { classes } = this.props;

    const fontSize = 20;

    const ProductTooltip = props => (
      <Tooltip title={attribute.helper_text || ''}>
        { props.children }
      </Tooltip>
    );

    const OpenInNewWindow = props => (
      <a
        href={attribute.link}
        target="_blank"
      >
        <OpenInNew color="primary" style={{ fontSize }}/>
      </a>
    );

    return (
      <TableRow key={attributeIndex}>
        <TableCell
          className={classes.attributeColumnCell}
        >
          <Typography variant={'body2'}>
            { attribute.label }
          </Typography>
        </TableCell>

        <TableCell
          className={`${ classes.attributeColumnCell } ${ classes.helperTextCell } ${ classes.rowHelperTextCell }`}
          align="right"
        >
          <div className={classes.attributeHelper}>
            {
              attribute.link ?
                <ProductTooltip>
                  <OpenInNewWindow />
                </ProductTooltip>
              :
                attribute.helper_text ?
                  <ProductTooltip>
                    <a
                      href={attribute.link}
                      target="_blank"
                    >
                      <Help color="primary" style={{ fontSize }}/>
                    </a>
                  </ProductTooltip>
                :
                  null
            }
          </div>
        </TableCell>

        {
          sortedPlans.map(
            (plan, plan_i) => {
              const isSelected = plan && plan.id === this.state.selectedPlanId;

              const hasAttribute = (
                plan.attributes &&
                plan.attributes[ product ] &&
                plan.attributes[ product ].map(a => a.label).includes( attribute.label )
              );

              const planHasNoAttributes = (
                !plan.attributes ||
                !plan.attributes[ product ] ||
                !plan.attributes[ product ].length
              );

              let selectedCellClass;
              if(
                // isSelected &&
                // products.length > 1 &&
                // productIndex === products.length-1 &&
                // attributeIndex === attributes.length-1
                isSelected &&
                attributeIndex === attributes.length-1 &&
                (
                  products.length < 2 ||
                  productIndex === products.length-1
                )
              ){
                selectedCellClass = classes.selectedBottomCell;
              } else if( isSelected ){
                selectedCellClass = classes.selectedCell
              }

              let noAttributesCell;
              if( planHasNoAttributes ){
                noAttributesCell = classes.noPlanAttributesCell;
              }

              return (
                <TableCell
                  className={`${ selectedCellClass || '' } ${ classes.tableCell } ${ noAttributesCell || '' }`}
                  key={plan_i}
                  onClick={() => this.selectPlan( plan )}
                >
                  {
                    hasAttribute ?
                      <Done color="primary" />
                    :
                      null
                  }
                </TableCell>
              );
            }
          )
        }
      </TableRow>
    )
  }


  onUpdateBillingPeriod = (e, billingPeriod) => {
    return this.setState(
      { billingPeriod },
      this.updateSpacing
    )
  }


  toggleExpandedProduct = productIndex => {
    const expandedProductIndices = [ ...this.state.expandedProductIndices ];

    if( expandedProductIndices.includes( productIndex ) ){
      expandedProductIndices.splice(
        expandedProductIndices.findIndex(
          v => v === productIndex
        ),
        1
      );
    } else {
      expandedProductIndices.push(
        productIndex
      );
    }

    this.setState({
      expandedProductIndices
    })
  }


  render() {
    const { classes } = this.props;
    const { selectedPlanId } = this.state;

    const ProductUndoResubscriptionAlert = this.props.productUndoResubscriptionAlert;


    const { sortedPlans, attributesByProduct } = this.sortedPlansAndAttributesByProduct(
      this.state.billingPeriod
    );


    let products = [ this.props.productName ];
    Object.keys( attributesByProduct ).filter(
      p => p !== this.props.productName
    ).sort(
      (a, b) => ( a ).localeCompare( b )
    ).forEach(
      p => products.push( p )
    );


    return (
      <MaxWidth maxWidth={1200} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <ProductUndoResubscriptionAlert />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">Select a Plan</Typography>
          </Grid>
          <Grid item xs={6} />

          {/* <CancelSetupButton /> */}

          {
            this.state.hasPlanWithYearlyBilling ?
              <>
                <Grid item xs={12} sm={4} />
                <Grid item xs={12} sm={4}>
                  <div className={classes.billingPeriod}>
                    <Typography
                      variant={'body1'}
                      className={classes.billingPeriodLabel}
                      color={this.state.billingPeriod === 'monthly' ? 'primary' : 'inherit'}
                    >
                      Monthly
                    </Typography>

                    <Switch
                      value={this.state.billingPeriod === 'monthly'}
                      color="default"
                      onChange={() => this.setState({ billingPeriod: this.state.billingPeriod === 'monthly' ? 'yearly' : 'monthly' })}
                    />

                    <Typography
                      variant={'body1'}
                      className={classes.billingPeriodLabel}
                      color={this.state.billingPeriod === 'yearly' ? 'primary' : 'inherit'}
                    >
                      Yearly
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} />
              </>
            :
              null
          }

          <Paper className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead id="pricing-table-head">
                { this.tableHeadRow() }
              </TableHead>

              <TableBody>
                {
                  products.map((p, i) => {
                    const isExpanded = this.state.expandedProductIndices.includes( i );

                    let selectedCellClass;
                    if( p && p.id === this.state.selectedPlanId ){
                      selectedCellClass = classes.selectedCell;
                    }

                    let planLabel = p;
                    if( p === 'Ads' ){
                      planLabel = (
                        <div className={classes.productLabelDiv} >
                          <MegaphoneIcon className={classes.ads} />

                          <Typography variant="body1">
                            Ads
                          </Typography>
                        </div>
                      );
                    } else if( p === 'Sites' ){
                      planLabel = (
                        <div className={classes.productLabelDiv} >
                          <WebsiteVisitorIcon className={classes.sites} />

                          <Typography variant="body1">
                            Sites
                          </Typography>
                        </div>
                      );
                    } else if( p === 'Reviews' ){
                      planLabel = (
                        <div className={classes.productLabelDiv} >
                          <StarRate className={classes.reviews} />

                          <Typography variant="body1">
                            Reviews
                          </Typography>
                        </div>
                      );
                    } else if( p === 'Social' ){
                      planLabel = (
                        <div className={classes.productLabelDiv} >
                          <PeopleIcon className={classes.social} />

                          <Typography variant="body1">
                            Social
                          </Typography>
                        </div>
                      );
                    } else if( p === 'Virtual' ){
                      planLabel = (
                        <div className={classes.productLabelDiv} >
                          <Videocam className={classes.virtual} />

                          <Typography variant="body1">
                            Virtual
                          </Typography>
                        </div>
                      );
                    }

                    return (
                      <React.Fragment key={i}>
                        {
                          products.length < 2 ?
                            null
                          :
                            <TableRow
                              className={classes.dividerRow}
                            >
                              <TableCell
                                className={`${classes.attributeColumnCell} ${classes.dividerRowCell}`}
                              >
                                { planLabel }
                              </TableCell>

                              <TableCell
                                className={`${classes.attributeColumnCell} ${classes.dividerRowCell}`}
                              />

                              {
                                sortedPlans.map(
                                  (sp, spI) => {
                                    const isDropdownFinalCell = i && spI === sortedPlans.length-1;
                                    const isSelected = this.state.selectedPlanId == sp.id;

                                    const isBottomSelectedRow = (
                                      isSelected &&
                                      i == products.length - 1 && // bottom
                                      !this.state.expandedProductIndices.includes( i ) // not expanded
                                    )

                                    return (
                                      <TableCell
                                        key={spI}
                                        className={`
                                          ${ classes.attributeColumnCell }
                                          ${ classes.dividerRowCell }
                                          ${ isDropdownFinalCell ? classes.finalDividerRowCell : null }
                                          ${ isSelected ? classes.selectedCell : null }
                                          ${ isBottomSelectedRow ? classes.selectedBottomCell : null }
                                        `}
                                      >
                                        {
                                          isDropdownFinalCell ?
                                            <Link
                                              component="button"
                                              onClick={() => this.toggleExpandedProduct( i )}
                                            >
                                              {
                                                isExpanded ?
                                                  `Hide ${ p } Features`
                                                :
                                                  `See ${ p } Features`
                                              }
                                            </Link>
                                          :
                                            null
                                        }
                                      </TableCell>
                                    );
                                  }
                                )
                              }
                            </TableRow>
                        }

                        {
                          !isExpanded || !attributesByProduct[ p ] ?
                            null
                          :
                            attributesByProduct[ p ].sort(
                              (a, b) => ( a.label ).localeCompare( b.label )
                            ).map((a, j) => (
                                this.tableRowsForProduct(
                                  sortedPlans,
                                  products,
                                  attributesByProduct[ p ],
                                  a,
                                  p,
                                  i,
                                  j
                                )
                            ))

                        }

                        {
                          isExpanded && p === this.props.productName ?
                            this.props.additionalProductTableRows(
                              this.state.selectedPlanId,
                              sortedPlans,
                              classes,
                              this.selectPlan
                            )
                          :
                            null
                        }
                      </React.Fragment>
                    )
                  })
                }
              </TableBody>
            </Table>
          </Paper>

          <Grid item xs={6}>
            {
              this.props.showBack ?
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={this.onClickBack}
                >
                  Back
                  </Button>
                : null
            }
          </Grid>

          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={this.onClickNext}
              disabled={!selectedPlanId}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }

}


ProductPricingTable.defaultProps = {
  showBack: false,
  productUndoResubscriptionAlert: () => null,
  additionalProductTableRows: () => null
};


const styles = theme => {
  const normalBorder = `2px solid ${ theme.palette.primary.main }`;
  const selectedBackgroundColor = 'whitesmoke';

  return ({
    planNamePrimary: {
      color: theme.palette.primary.main,
    },
    priceRoot: {
      textAlign: 'right'
    },
    buttonGrid: {
      marginTop: 20,
    },
    cardHeader: {
      cursor: 'pointer'
    },
    subscribedChip: {
      marginTop: 2 * theme.spacing.unit,
      marginRight: 2 * theme.spacing.unit,
    },
    rotatedTableCell: {
      whiteSpace: 'nowrap',
      padding: '0 !important',
      position: "sticky",
      backgroundColor: '#fafafa',
      top: 55,
      zIndex: 2,
      height: 15
    },
    rotatedTableCellDiv: {
      transform: 'translate(13px, 0px) rotate(315deg)',
      width: '30px',
      position: 'absolute',
      bottom: 15
    },
    rotatedTableCellMainSpan: {
    },
    selectedCell: {
      borderLeft: normalBorder,
      borderRight: normalBorder,
      backgroundColor: selectedBackgroundColor
    },
    selectedTopCell: {
      borderLeft: normalBorder,
      borderRight: normalBorder,
      borderTop: normalBorder,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      backgroundColor: selectedBackgroundColor
    },
    selectedBottomCell: {
      borderLeft: normalBorder,
      borderRight: normalBorder,
      borderBottom: normalBorder,
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
      backgroundColor: selectedBackgroundColor
    },
    table: {
      borderCollapse: 'separate !important',
      borderSpacing: '0px',
    },
    tableCell: {
      textAlign: 'center',
      cursor: 'pointer'
    },
    tableWrapper: {
      overflowX: 'scroll',
      overflowY: 'scroll',
      width: '100%'
    },
    attributeColumnCell: {
      position: "sticky",
      left: 0,
      zIndex: 1,
      paddingRight: 0
    },
    dividerRow: {
      height: 15
    },
    dividerRowCell: {
      backgroundColor: '#f2f2f2',
    },
    finalDividerRowCell: {
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: 'right',
      cursor: 'pointer',
    },
    noPlanAttributesCell: {
      backgroundColor: '#eee'
    },
    tableHeadCell: {
      textAlign: 'center',
      padding: '25px 20px 25px 20px'
    },
    topTableRowCell: {
      borderBottom: 'none'
    },
    helperTextCell: {
      padding: 0,
      maxWidth: 30
    },
    rowHelperTextCell: {
      borderRight: '1px solid #f2f2f2',
    },
    attributeHelper: {
      textAlign: 'right',
      paddingLeft: 5,
      paddingRight: 5,
      width: 20
    },
    billingPeriod: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 15
    },
    billingPeriodLabel: {
      lineHeight: '48px'
    },
    productLabelDiv: {
      display: 'flex'
    },
    ads: {
      color: ServicesColors.ads.primary,
      marginRight: 5,
      fontSize: 24
    },
    sites: {
      color: ServicesColors.sites.primary,
      marginRight: 5,
      fontSize: 24
    },
    reviews: {
      color: ServicesColors.reviews.primary,
      marginRight: 5,
      fontSize: 24
    },
    social: {
      color: ServicesColors.social.primary,
      marginRight: 5,
      fontSize: 24
    },
    virtual: {
      color: ServicesColors.virtual.primary,
      marginRight: 5,
      fontSize: 24
    },
  })
};


export default withStyles(styles)(
  withContext(
    PlanContext,
    TransitionContext,
    ProductPricingTable
  )
);


const maxElementDimension = (nodeList, dimensionGetter=null) => {
  const elements = [ ...nodeList ];

  const elementWidths = elements.map(
    e => dimensionGetter ? dimensionGetter( e ) : 0
  );

  let maxChildrenWidths = elements.filter(e => e.childElementCount).map(
    e => maxElementDimension(e.children, dimensionGetter)
  );

  return Math.max(
    0,
    ...elementWidths,
    ...maxChildrenWidths
  );
}
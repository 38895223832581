import React from 'react';

import { log, withContext } from 'kn-react';
import { SnackbarContext, PromiseButton, UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import history from 'history.js';



class CreateConversionTrackerTemplate extends React.Component {
  state = {
    processing: false,
    conversionTrackerId: '',
    boostedServiceId: ''
  }

  onCreate = () => {
    return Axios.post(
      `/api/ads/adwords_templates/conversion_trackers/create`,
      {
        conversion_tracker_id: this.state.conversionTrackerId,
        boosted_service_id: this.state.boostedServiceId
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('onCreate', response);
      this.props.onClose();
      this.props.showSnackbar('Conversion Tracker Template created.');
      history.push(`/ads/adwords_templates/conversion_trackers/${ response.data.adwords_conversion_tracker_template.id }`);
    });
  }



  render() {
    const { boostedServices } = this.props;

    return (
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Conversion Tracker Template</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a new Conversion Tracker Template, enter the ID below.
            </DialogContentText>

            <FormControl margin="normal" fullWidth>
              <InputLabel>Boosted Service</InputLabel>
              <Select
                autoFocus
                native
                value={this.state.boostedServiceId}
                onChange={ e => this.setState({ boostedServiceId: e.target.value })}
              >
                <option value=""></option>
                {
                  boostedServices.map(b => (
                    <option value={b.id} key={b.id}>{ b.id } - { b.name }</option>
                  ))
                }
              </Select>
            </FormControl>

            <TextField
              margin="dense"
              id="conversion-tracker-id"
              label="Conversion Tracker ID"
              type="text"
              value={this.state.conversionTrackerId}
              onChange={ e => this.setState({ conversionTrackerId: (e.target.value || '').trim() }) }
              fullWidth
            />


          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              Cancel
            </Button>
            <PromiseButton
              buttonProps={{
                disabled: (
                  this.state.processing || this.state.conversionTrackerId.length < 8 || !this.state.boostedServiceId
                ),
                fullWidth: false,
                variant: 'contained',
                color: 'primary',
                type: 'submit',
              }}
              type="submit"
              onProcess={() => {
                this.setState({ processing: true });

                return this.onCreate()
                  .finally(() => (
                    this.setState({ processing: false })
                  ));
              }}
            >
              Create
            </PromiseButton>
          </DialogActions>
        </Dialog>
    );
  }
}

CreateConversionTrackerTemplate.defaultProps = {
  boostedServices: [],
};


export default withContext(UserRoleContext, SnackbarContext, CreateConversionTrackerTemplate);
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';


import * as d3 from 'd3';
import { OverTimeChart, ChangeMetric, FormIcon } from 'go-boost-library-react';
import { LocationOn, People, Visibility } from '@material-ui/icons';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

class SitesDashboardSummaryContent extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };


  render() {
    const { classes } = this.props;
    const { value } = this.state;


    const visitsMetric = <ChangeMetric
      title="Visits"
      metric={ this.props.nbVisits || 0 }
      icon={LocationOn}
      iconColor="#37caad"
      iconBackgroundColor='rgba(55, 202, 173, 0.2)'
      change={ this.props.nbVisitsChange || '' }
      width={260}
      height={260}
    />

    const visitorsMetric = <ChangeMetric
      title="Average Daily Unique Visitors"
      metric={ parseFloat(this.props.avgDailyUniqueVisitors) ? d3.format('.2f')(this.props.avgDailyUniqueVisitors) : 0 }
      icon={People}
      iconColor="#5a7af9"
      iconBackgroundColor="rgba(90, 122, 249, 0.2)"
      change={this.props.avgDailyUniqueVisitorsChange}
      width={260}
      height={260}
    />

    const viewsMetric = <ChangeMetric
      title="Average Daily Unique Pageviews"
      metric={ parseFloat(this.props.avgDailyUniquePageviews) ? d3.format('.2f')(this.props.avgDailyUniquePageviews) : 0 }
      icon={Visibility}
      iconColor="#ff9a48"
      iconBackgroundColor="rgba(255, 154, 72, 0.2)"
      change={this.props.avgDailyUniquePageviewsChange}
      width={260}
      height={260}
    />

    const opportunitiesMetric = <ChangeMetric
      title="Opportunities"
      metric={ this.props.totalOpportunities || 0 }
      icon={FormIcon}
      iconColor="#9e70ff"
      iconBackgroundColor="rgba(158, 112, 255, 0.2)"
      change={this.props.totalOpportunitiesChange}
      width={260}
      height={260}
    />


    const visitsLineStyles = {
      Visits: {
        type:"monotone",
        dataKey:"Visits",
        stroke:"#37caad",
        activeDot: { r: 8 }
      }
    }

    const visitorsLineStyles = {
      'Unique Visitors': {
        type:"monotone",
        dataKey:"Unique Visitors",
        stroke:"#5a7af9",
        activeDot: { r: 8 }
      }
    }

    const pageviewsLineStyles = {
      Pageviews: {
        type:"monotone",
        dataKey:"Pageviews",
        stroke:"#ff9a48",
        activeDot: { r: 8 }
      }
    }

    const opportunitiesLineStyles = {
      Calls: {
        type:"monotone",
        dataKey:"Calls",
        stroke:"#36caad",
        activeDot: { r: 8 }
      },
      'Form Entries': {
        type:"monotone",
        dataKey:"Form Entries",
        stroke:"#9e70ff",
        activeDot: { r: 8 }
      }
    }


    return (
      <div className={classes.root}>
        <Hidden mdDown>
          <Tabs variant='fullWidth' value={value} onChange={this.handleChange}>
            <Tab variant='fullWidth' icon={visitsMetric} />
            <Tab variant='fullWidth' icon={visitorsMetric} />
            <Tab variant='fullWidth' icon={viewsMetric} />
            <Tab variant='fullWidth' icon={opportunitiesMetric} />
          </Tabs>
        {value === 0 && <OverTimeChart data={this.props.visitsChartData} lineStyles={visitsLineStyles}/>}
        {value === 1 && <OverTimeChart data={this.props.visitsChartData} lineStyles={visitorsLineStyles}/>}
        {value === 2 && <OverTimeChart data={this.props.actionsChartData} lineStyles={pageviewsLineStyles}/>}
        {value === 3 && <OverTimeChart data={this.props.opportunitiesChartData} lineStyles={opportunitiesLineStyles}/>}
      </Hidden>

      <Hidden lgUp>
        <div>{visitsMetric}</div>
        <div>{visitorsMetric}</div>
        <div>{viewsMetric}</div>
        <div>{opportunitiesMetric}</div>
      </Hidden>
      </div>
    );
  }
}


SitesDashboardSummaryContent.defaultProps = {
  avgDailyUniqueVisitors: '',
  nbVisits: '',
  avgDailyUniqueVisitorsChange: '',
  nbVisitsChange: '',

  visitsChartData: [],

  avgDailyUniquePageviews: '',
  avgDailyUniquePageviewsChange: '',

  actionsChartData: [],

  totalOpportunities: '',
  totalOpportunitiesChange: '',

  opportunitiesChartData: []
}

export default withStyles(styles)(SitesDashboardSummaryContent);
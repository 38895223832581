import React from 'react';
import Axios from 'axios';

import { Ability, CompanyRoleContext, OpportunityModel } from 'go-boost-library-react';
import { withContext, MaxWidth, opportunityConstants } from 'kn-react';

import { Alert, PromiseButton, SnackbarContext, UserRoleContext, GoogleMapsMap, OpportunityInformation, OpportunitySuggestedResponses, ExternalAssetConnectionTable } from 'go-boost-library-react';
import { Button, withStyles, Typography, Grid, LinearProgress, IconButton, Tooltip, Paper, Popover, MenuItem } from '@material-ui/core';
import { OpenInNew, Edit, Delete, Add, Settings } from '@material-ui/icons';

import AreaMapDialog from 'Virtual/AreaMap/AreaMapDialog';
import ExternalAccessConnectionModel from 'ExternalAssetConnection/ExternalAccessConnectionModel';
import ExternalAssetConnectionDialog, { SERVICETITAN, EXTERNAL_SOURCE_OPTIONS } from 'ExternalAssetConnection/ExternalAssetConnectionDialog';
import NoteTable from 'Note/NoteTable';

const { CONTACT_KEY, ADDRESS_KEY, AREA_MAP_KEY, PHOTO_UPLOAD, VIDEO_UPLOAD } = opportunityConstants;



class Opportunity extends React.Component {
  state = {
    isLoading: true,
    opportunity: '',
    steps: [],
    mediaSteps: [],
    externalAssetConnections: [],
    totalExternalAssetConnections: 0,
    isLoadingExternalAssetConnections: true,
    externalAssetConnectionsLimit: 5,
    externalAssetConnectionsPage: 0,
    isExternalAssetConnectionsDialogOpen: false,
    optionsMenuAnchor: null,
    activeExternalAccessConnection: null
  }

  componentDidMount = () => {
    return this.fetchOpportunity().then(
      this.fetchExternalAssetConnections
    );
  }


  fetchOpportunity = () => {
    return Axios.get(
      `/api/core/opportunities/${ this.props.opportunityId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const opportunity = OpportunityModel.fromJSON(
        response.data.opportunity
      );

      const steps = this.props.fetchSteps( opportunity );
      const mediaSteps = this.props.fetchMediaSteps( opportunity );

      return this.setState({
        opportunity,
        steps,
        mediaSteps,
        isLoading: false
      });
    })
  }


  fetchExternalAssetConnections = () => {
    return Axios.get(
      `/api/core/external_asset_connections/companies/${ this.props.company.id }`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          source: 'Opportunity',
          source_id: this.state.opportunity.id,
          limit: this.state.externalAssetConnectionsLimit,
          page: this.state.externalAssetConnectionsPage,
        }
      }
    ).then(response => {
      const externalAssetConnections = response.data.external_asset_connections.map(
        e => ExternalAccessConnectionModel.fromJSON( e )
      );


      return this.setState({
        externalAssetConnections,
        isLoadingExternalAssetConnections: false,
        totalExternalAssetConnections: response.data.total
      });
    })
  }


  onChangeExternalAssetConnectionTablePagination = pageOrRowsPerPage => {
    const newState = {
      isLoadingExternalAssetConnections: true
    };

    if( +pageOrRowsPerPage.page >= 0 ){
      newState.externalAssetConnectionsPage = +pageOrRowsPerPage.page;
    } else {
      newState.externalAssetConnectionsPage = 0;
      newState.externalAssetConnectionsLimit = pageOrRowsPerPage.rowsPerPage;
    }

    return this.setState(
      newState,
      this.fetchExternalAssetConnections
    );
  }


  fetchResponseByKey = (key, defaultObject={ value: '' }) => {
    if( !this.state.opportunity || !this.state.opportunity.additionalData ){
      return defaultObject;
    }


    return this.state.opportunity.additionalData.find(
      d => d.key === key
    ) || defaultObject;
  }


  fetchResponsesByType = type => {
    if( !this.state.opportunity || !this.state.opportunity.additionalData ){
      return [];
    }


    const typeKeys = this.state.steps.filter(
      s => s.type === type
    ).map(
      s => s.key
    );

    return this.state.opportunity.additionalData.filter(
      d => typeKeys.includes( d.key )
    ) || [];
  }


  setConversionStatus = conversionStatus => {
    return Axios.put(
      `/api/core/opportunities/${ this.state.opportunity.id }/conversion_status`,
      { conversion_status: conversionStatus },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(this.fetchOpportunity)
  }



  canParseFromClipboard = () => {
    if( !( navigator && navigator.clipboard && navigator.clipboard.readText ) ){
      return false;
    }

    return true;
  }



  onContactGetStarted = () => {
    const email = this.state.opportunity.email;
    const phoneNumber = this.state.opportunity.phoneNumber;

    let text = '';

    if( phoneNumber ){
      text = phoneNumber;
    }

    if( email ){
      if( text.length ){
        text += '; ';
      }

      text += email;
    }


    const copyToClipboard = () => navigator.clipboard.writeText(
      text
    ).then(
      () => this.props.showSnackbar(`${ phoneNumber ? 'Phone number' : '' }${ email && phoneNumber ? ' & email' : email ? 'Email' : '' } successfully copied to your clipboard.`)
    );


    this.props.showSnackbar(
      <>
        <Typography
          variant='body2'
          style={{ color: 'white' }}
        >
          { this.props.meetWithText ? this.props.meetWithText : 'Invite this customer to meet with you.' }&nbsp;
          The customer's { phoneNumber && !email ? 'phone number is' : phoneNumber ? 'phone number ' : '' }{ email && phoneNumber ? 'and email are' : email ? 'email is' : '' }: { text }.
        </Typography>

        {
          !this.canParseFromClipboard() ?
            false
          :
            <Button
              variant='contained'
              color='primary'
              style={{ float: 'right', marginTop: 15 }}
              onClick={copyToClipboard}
            >
              Copy { phoneNumber ? 'Phone number' : '' }{ email && phoneNumber ? ' & email' : email ? 'Email' : '' } To Clipboard
            </Button>
        }
      </>,
      { autoHide: false }
    );


    if( this.props.onClickContactGetStarted ){
      this.props.onClickContactGetStarted();
    }
  }



  onModifyExternalAssetConnection = () => {
    return this.setState(
      {
        isExternalAssetConnectionsDialogOpen: false,
        optionsMenuAnchor: null,
        activeExternalAccessConnection: null
      },
      this.fetchExternalAssetConnections
    );
  }



  onCloseExternalAssetConnectionDialog = () => {
    return this.setState({
      isExternalAssetConnectionsDialogOpen: false,
      optionsMenuAnchor: null,
      activeExternalAccessConnection: null
    });
  }



  contactStatusHelperText = () => (
    <div className={this.props.classes.helperText}>
      <Typography variant='caption'>
        <b>Mark as Ignored</b> - Remove this opportunity from the list of current Jobs.
      </Typography>

      {
        !this.state.opportunity.conversionStatus ?
          <Typography variant='caption'>
            <b>Mark as Contacted</b> - Indicate that this customer has been contacted about this opportunity.
          </Typography>
        :
          <Typography variant='caption'>
            <b>Mark as Converted</b> - Indicate that this Jobs has been completed and remove it from the list of current Jobs.
          </Typography>
        }
    </div>
  )



  constructAdditionalSecondaryChildren = externalAssetConnection => {
    if( externalAssetConnection.externalSourceId ){
      return <></>;
    }


    const onClose = () => this.setState({
      activeExternalAccessConnection: null,
      optionsMenuAnchor: null,
      isExternalAssetConnectionsDialogOpen: false
    });

    const onClickEdit = () => this.setState({
      activeExternalAccessConnection: this.state.activeExternalAccessConnection,
      optionsMenuAnchor: null,
      isExternalAssetConnectionsDialogOpen: true
    });

    const onClickDelete = () => this.onDeleteExternalAccessConnection(
      this.state.activeExternalAccessConnection.id
    );


    const externalAssetConnectionId = (
      !this.state.activeExternalAccessConnection ?
        null
      :
        this.state.activeExternalAccessConnection.id
    );

    return (
      <div style={{ width: 'right', textAlign: 'right' }}>
        <IconButton
          onClick={e => this.setState({ optionsMenuAnchor: e.currentTarget, activeExternalAccessConnection: externalAssetConnection })}
        >
          <Settings />
        </IconButton>

        <Popover
          open={Boolean(this.state.optionsMenuAnchor)}
          anchorEl={this.state.optionsMenuAnchor}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            component={'a'}
            href={`https://go.servicetitan.com/#/Search?go_boost_external_asset_connection=${ externalAssetConnectionId }`}
            target={'_blank'}
          >
            <OpenInNew className={this.props.classes.icon} />

            Connect
          </MenuItem>

          <MenuItem
            onClick={onClickEdit}
          >
            <Edit className={this.props.classes.icon} />

            Edit
          </MenuItem>

          <MenuItem
            onClick={onClickDelete}
          >
            <Delete className={this.props.classes.icon} />

            Delete
          </MenuItem>
        </Popover>
      </div>
    )
  }



  fetchIsListItemButton = externalAssetConnection => {
    return Boolean(
      externalAssetConnection.externalSourceId
    )
  }



  onClickListItem = externalAssetConnection => {
    const option = EXTERNAL_SOURCE_OPTIONS[ SERVICETITAN ].find(
      o => o.name === externalAssetConnection.externalSourceType
    );


    window.open(
      option.createUrl( externalAssetConnection ),
      '_blank'
    );
  }



  onDeleteExternalAccessConnection = activeExternalAccessConnectionId => {
    return Axios.delete(
      `/api/core/external_asset_connections/${ activeExternalAccessConnectionId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      () => this.props.showSnackbar('External Asset Connection successfully deleted.')
    ).then(
      this.onModifyExternalAssetConnection
    );
  }



  render(){
    const { classes } = this.props;

    if( this.state.isLoading ){
      return <LinearProgress />
    }


    const areaMapQuestion = this.fetchResponseByKey(
      AREA_MAP_KEY
    );

    const addressQuestion = this.fetchResponseByKey(
      ADDRESS_KEY
    );

    const photoQuestions = this.fetchResponsesByType(
      PHOTO_UPLOAD
    );

    const videoQuestions = this.fetchResponsesByType(
      VIDEO_UPLOAD
    );

    const center = (
      !( addressQuestion && addressQuestion.value && addressQuestion.value.latLng ) ?
        undefined
      :
        `${ addressQuestion.value.latLng.lat }, ${ addressQuestion.value.latLng.lng }`
    );


    const origin = (
      addressQuestion.distance && addressQuestion.distance.origin ?
        addressQuestion.distance.origin
      :
        this.props.defaultAddress
    );


    const hasPhotos = (
      photoQuestions &&
      photoQuestions.length &&
      photoQuestions.some(q => q.value && q.value.length)
    );

    const hasVideos = (
      videoQuestions &&
      videoQuestions.length &&
      videoQuestions.some(q => q.value && q.value.length)
    );

    const hasExpandedInfo = Boolean(
      hasPhotos ||
      hasVideos ||
      center
    );


    const createAlertButtons = children => (
      (
        !this.props.canEdit ||
        !(
          this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_ADS_PROFILE ) ||
          this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_SITES_PROFILE ) ||
          this.props.currentUserRole.roleHasAbility( Ability.EDIT_VIRTUAL_COMPANY_VIRTUAL_PROFILE )
        )
      ) ?
        null
      :
        <div className={classes.conversionStatusDiv}>
          <PromiseButton
            onProcess={() => this.setConversionStatus( OpportunityModel.REJECTED_CONVERSION_STATUS )}
            buttonProps={{
              variant: 'outlined',
              color: 'secondary',
              size: 'small'
            }}
          >
            Mark as Ignored
          </PromiseButton>

          {
            !this.state.opportunity.conversionStatus ?
              <PromiseButton
                onProcess={() => this.setConversionStatus( OpportunityModel.CONTACTED_CONVERSION_STATUS )}
                buttonProps={{
                  variant: 'contained',
                  color: 'primary',
                  size: 'small',
                  className: classes.rightConversionStatusButton
                }}
              >
                Mark as Contacted
              </PromiseButton>
            :
              <PromiseButton
                onProcess={() => this.setConversionStatus( OpportunityModel.CONVERTED_CONVERSION_STATUS )}
                buttonProps={{
                  variant: 'contained',
                  color: 'primary',
                  size: 'small',
                  className: classes.rightConversionStatusButton
                }}
              >
                Mark as Converted
              </PromiseButton>
          }

          { children }
        </div>
    );


    const noContactGetStartedButton = (
      <Button
        variant='contained'
        color='secondary'
        component={'a'}
        size='small'
        href='#get-started-contact'
        className={classes.getStartedButton}
      >
        Get Started
      </Button>
    );

    const contactGetStartedButton = (
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={this.onContactGetStarted}
        className={classes.getStartedButton}
      >
        Get Started
      </Button>
    );

    return(
      <>
        {
          this.state.opportunity && !this.state.opportunity.conversionStatus ?
            <Alert danger>
              <div className={classes.alertDiv}>
                <Typography variant='body1'>
                  This company has not yet been marked as "contacted". Don't miss this opportunity! Be sure to follow up with this customer.
                </Typography>

                { createAlertButtons( noContactGetStartedButton ) }

                { this.contactStatusHelperText() }
              </div>
            </Alert>
          :
            null
        }

        {
          this.state.opportunity && this.state.opportunity.conversionStatus === OpportunityModel.CONTACTED_CONVERSION_STATUS ?
            <Alert warning>
            <div className={classes.alertDiv}>
              <Typography variant='body1'>
                You have contacted this company but have not yet closed the deal. You're almost there, don't miss this opportunity!
              </Typography>

              { createAlertButtons( contactGetStartedButton ) }

              { this.contactStatusHelperText() }
            </div>
          </Alert>
          :
            null
        }

        {
          this.state.opportunity && this.state.opportunity.conversionStatus === OpportunityModel.CONVERTED_CONVERSION_STATUS ?
            <Alert success>
            <div className={classes.alertDiv}>
              <Typography variant='body1'>
                Way to go! You converted this customer!
              </Typography>
            </div>
          </Alert>
          :
            null
        }

        <MaxWidth maxWidth={1200}>
          <div className={classes.body}>
            {
              this.state.opportunity ?
                null
              :
                <LinearProgress />
            }

            <Grid container spacing={16}>
              {
                !hasExpandedInfo ?
                  null
                :
                  <Grid item xs={12} md={6}>
                    {
                      hasPhotos ?
                        <>
                          <Typography variant='caption' className={classes.response}>
                            Customer-Uploaded Photos
                          </Typography>

                          {
                            photoQuestions.filter(
                              q => q.value
                            ).map(q => q.value.filter(u => u).map((url, i) => (
                              <img src={url} key={i} className={classes.image}/>
                            )))
                          }
                        </>
                      :
                        null
                    }

                    {
                      hasVideos ?
                        <>
                          <Typography variant='caption' className={classes.response}>
                            Customer-Uploaded Videos
                          </Typography>

                          {
                            videoQuestions.filter(
                              q => q.value
                            ).map(q => q.value.filter(u => u).map((url, i) => (
                              <video key={i} className={classes.video} controls>
                                <source src={url} key={i} type="video/mp4"/>
                                Your browser does not support the video tag.
                              </video>
                            )))
                          }
                        </>
                      :
                        null
                    }

                    {
                      !center ?
                        null
                      :
                        <>
                          <Typography variant='caption'>
                            Customer's Location
                          </Typography>

                          <GoogleMapsMap
                            mapMode={'directions'}
                            parameters={{
                              center,
                              zoom: 10,
                              origin,
                              destination: addressQuestion.value.fullAddress
                            }}
                          />

                          <GoogleMapsMap
                            mapMode={'view'}
                            parameters={{
                              center,
                              zoom: 20,
                              maptype: 'satellite'
                            }}
                          />

                          <GoogleMapsMap
                            mapMode={'streetview'}
                            parameters={{
                              location: center
                            }}
                          />
                        </>
                    }
                  </Grid>
              }

              <Grid item xs={12} md={hasExpandedInfo ? 6 : 12}>
                <OpportunityInformation
                  opportunity={this.state.opportunity}
                  steps={this.state.steps}
                  mediaSteps={this.state.mediaSteps}
                  onClickPavementArea={() => this.setState({ isOpen: true })}
                />

                <Paper className={classes.paper}>
                  <NoteTable
                    roleType={this.props.currentUserRole.roleType}
                    roleTypeId={this.props.currentUserRole.roleTypeId}
                    noteSource={'Opportunity'}
                    noteSourceId={this.props.opportunityId}
                  />
                </Paper>

                {
                  !this.props.showExternalAssetConnection ?
                    null
                  :
                    <Paper className={classes.paper}>
                      <ExternalAssetConnectionTable
                        externalAssetConnections={this.state.externalAssetConnections}
                        total={this.state.totalExternalAssetConnections}
                        isLoading={this.state.isLoadingExternalAssetConnections}
                        rowsPerPage={this.state.externalAssetConnectionsLimit}
                        page={this.state.externalAssetConnectionsPage}
                        secondaryActions={(
                          <Tooltip title={'Add To Your CRM'}>
                            <IconButton
                              onClick={() => this.setState({ isExternalAssetConnectionsDialogOpen: true })}
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        )}
                        showCreatedAt={true}
                        caption={'Connect assets in GoBoost to assets in your CRM. Be sure to visit your CRM with the GoBoost Chrome Extension to finish the process.'}
                        changePagination={this.onChangeExternalAssetConnectionTablePagination}
                        constructAdditionalSecondaryChildren={this.constructAdditionalSecondaryChildren}
                        fetchIsListItemButton={this.fetchIsListItemButton}
                        onClickListItem={this.onClickListItem}
                      />
                    </Paper>
                }

                <Paper
                  className={classes.paper}
                  id={'get-started-contact'}
                >
                  <OpportunitySuggestedResponses
                    opportunity={this.state.opportunity}
                    firstName={this.state.opportunity.name}
                    companyName={this.props.company.name}
                  />
                </Paper>

              </Grid>
            </Grid>
          </div>

          <AreaMapDialog
            latLng={areaMapQuestion.latLng}
            polygon={areaMapQuestion.polygon}
            open={this.state.isOpen}
            onClose={() => this.setState({ isOpen: false })}
          />

          <ExternalAssetConnectionDialog
            externalAccessConnection={this.state.activeExternalAccessConnection}
            open={this.state.isExternalAssetConnectionsDialogOpen}
            onClose={this.onCloseExternalAssetConnectionDialog}
            onUpdateOrCreate={this.onModifyExternalAssetConnection}
            source={'Opportunity'}
            sourceId={this.state.opportunity.id}
          />
        </MaxWidth>
      </>
    );
  }
}


const styles = theme => ({
  body: {
    marginTop: 50
  },
  paper: {
    marginTop: 15
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'whitesmoke'
    }
  },
  logo: {
    maxWidth: 250
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center'
  },
  information: {
    marginBottom: 15
  },
  response: {
    marginBottom: 10
  },
  question: {

  },
  image: {
    maxWidth: '100%',
    maxHeight: 250,
    marginBottom: 10,
    borderRadius: 5,
    marginRight: 5
  },
  video: {
    maxWidth: '100%',
    maxHeight: 250,
    marginBottom: 10,
    borderRadius: 5,
    marginRight: 5
  },
  paper: {
    padding: 15,
    marginTop: 15
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'whitesmoke'
    }
  },
  conversionStatusDiv: {
    // width: '100%',
    // marginTop: 5,
    // justifyContent: 'right'
    marginLeft: 'auto'
  },
  rightConversionStatusButton: {
    // float: 'right'
    marginLeft: 15
  },
  alertDiv: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  otherQuestions: {
    // marginBottom: 25
  },
  sectionHeader: {
    borderBottom: '1px solid lightgrey',
    display: 'flex',
    marginBottom: 2
  },
  sectionHeaderIcon: {
    fontSize: 12,
    marginRight: 5,
    marginTop: 4
  },
  sectionHeaderText: {
    fontSize: 12
  },
  getStartedButton: {
    marginLeft: 15
  },
  helperText: {
    marginTop: 5
  },
  button: {
    marginBottom: 5
  },
  icon: {
    marginRight: 5
  }
});


Opportunity.defaultProps = {
  opportunityId: '',
  defaultAddress: '',
  fetchSteps: () => {},
  fetchMediaSteps: () => {},
  canEdit: true,
  meetWithText: '',
  onClickContactGetStarted: () => {},
  showExternalAssetConnection: false
}


export default withContext(
  CompanyRoleContext,
  SnackbarContext,
  UserRoleContext,
  withStyles(styles)(
    Opportunity
  )
);
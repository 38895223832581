import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SubNavigation } from 'go-boost-library-react';
import { withStyles } from '@material-ui/core/styles';
import { CreditCard as CreditCardIcon, History as HistoryIcon } from '@material-ui/icons';
import AppNavigation from '../AppNavigation/AppNavigation';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';


class Billing extends React.Component {

  render(){
    var subNavLinks = [
      { primaryText: 'History', path: '/billing/payment_history', icon: <HistoryIcon/> },
      { primaryText: 'Payment Method', path: '/billing/payment_method', icon: <CreditCardIcon/> },
    ];


    return (
      <AppNavigation title="Billing">
        <SubNavigation links={subNavLinks}>
          <KnowledgeBaseLaunchBanner />
          <Switch>
            <Route path="/billing/payment_history" component={PaymentHistory}/>
            <Route path="/billing/payment_method" component={PaymentMethod}/>
          </Switch>
        </SubNavigation>
      </AppNavigation>
    );
  }

}


const styles = theme => ({

});

export default withStyles(styles)(Billing);
import React from 'react';

import { log, withContext, format, formHelpers, AutoError, usStatesList, canadaProvincesList } from 'kn-react';
import { SnackbarContext, Ability } from 'go-boost-library-react';

import { withStyles, Paper, TextField, Typography, Grid, Button, InputLabel } from '@material-ui/core';

import { UNITED_STATES } from 'SignUp/SignUp';
import OrganizationAutosuggest from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/OrganizationAutosuggest/OrganizationAutosuggest';
import paperPadding from 'paperPadding';


class CompanyInfoCard extends React.Component {

  fields = {
    name: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    phoneNumber: '',
    primaryWebsite: '',
    signUpCodeId: '',
    signUpOrganizationId: '',
    organizationName: ''
  }

  state = {
    ...this.fields
  }


  initializeState = (props, callback=null) => {
    this.setState(
      {
        ...props.company,
        organizationName: props.organization ? props.organization.name : ''
      },
      callback ? callback() : null
    );
  }


  componentDidMount = () => {
    return this.initializeState(
      this.props
    );
  }


  componentWillReceiveProps = nextProps => {
    if (
      this.props.company != nextProps.company
    ) {
      return this.initializeState(
        nextProps
      );
    } else if (
      this.props.organization != nextProps.organization
    ) {
      return this.setState({
        organizationName: nextProps.organization.name
      })
    }
  }

  updateCompanyDetails = (field, newValue) => {
    const { currentUserRole } = this.props;
    if (currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_INFO )) {
      const updatedAttributes = [...this.state.updatedAttributes, field];
      this.setState({ updatedAttributes, [field]: newValue });
    }
  }

  updateCompany = () => {
    const updatedAttributes = {};
    Object.keys(this.fields).forEach(attr => updatedAttributes[attr] = this.state[attr]);

    this.props.onUpdateCompany(
      updatedAttributes
    );
  }

  onProcess = () => {
    log('onProcess', this.state);
    return this.validate()
      .then(this.updateCompany)
      .catch(AutoError.catch.bind(this));;
  }

  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }

  onError = error => {
    log('error', error);
    this.props.showSnackbar(error.response.data.message)
  }


  validate = () => {
    const validations = {
      name: {
        presence: { message: "Please specify the company's name" }
      },
      addressLineOne: {
        presence: { message: "Please specify the company's address" }
      },
      city: {
        presence: { message: "Please specify the company's city" }
      },
      state: {
        presence: { message: "Please specify the company's state" }
      },
      postalCode: {
        presence: { message: "Please specify the company's postal code" }
      },
      phoneNumber: {
        presence: { message: "Please specify the company's phone number" }
      },
      signUpOrganizationId: {
        presence: { message: "Please specify the company's sign up organization" }
      },
      signUpCodeId: {
        presence: { message: "Please specify the company's sign up code" }
      },
    };

    return formHelpers.validate(this.state, validations);
  }


  onOrganizationSuggestionSelected = organization => {
    return this.setState(
      {
        signUpOrganizationId: organization.id,
        signUpCodeId: organization.id == this.state.signUpOrganizationId ? this.state.signUpCodeId : null,
        organizationName: organization.name
      },
      () => this.props.onOrganizationSuggestionSelected( organization )
    );
  }


  onBlur = e => {
    if( e.target.value !== this.props.organization.name ){
      return this.onOrganizationSuggestionSelected(
        this.props.organization
      );
    }
  }


  onCancel = () => {
    return this.initializeState(
      this.props,
      this.props.onCancel
    );
  }

  canEditSignUpCode = () => (
    !this.props.subscribedPlans || !this.props.subscribedPlans.length
  )


  onOrganizationSuggestionsFetchRequested = () => {
    if( this.canEditSignUpCode() ){
      return this.props.onOrganizationSuggestionsFetchRequested(
        this.state.organizationName
      );
    }

    return Promise.resolve([]);
  }




  render() {
    const { classes, signUpCodes } = this.props;

    const statesList = (
      this.state.country === UNITED_STATES ?
        usStatesList
      :
        canadaProvincesList
    );

    return (
      <Paper className={classes.paperRoot} id="company-info">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h6">Company Info</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name='name'
              onChange={e => this.setState({ name: e.target.value}) }
              onBlur={e => this.setState({ name: e.target.value.trim()}) }
              fullWidth
              value={this.state.name || ''}
              label="Company Name"
              inputProps={{ name: 'name', id: 'name' }}
              disabled={!this.props.canEdit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name='address-line-one'
              onChange={e => this.setState({ addressLineOne: e.target.value}) }
              onBlur={e => this.setState({ addressLineOne: e.target.value.trim()}) }
              fullWidth
              value={this.state.addressLineOne || ''}
              label="Address Line One"
              inputProps={{ name: 'address-line-one', id: 'address-line-one' }}
              disabled={!this.props.canEdit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name='address-line-two'
              onChange={e => this.setState({ addressLineTwo: e.target.value}) }
              onBlur={e => this.setState({ addressLineTwo: e.target.value.trim()}) }
              fullWidth
              value={this.state.addressLineTwo || ''}
              label="Address Line Two"
              inputProps={{ name: 'address-line-two', id: 'address-line-two' }}
              disabled={!this.props.canEdit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name='city'
              onChange={e => this.setState({ city: e.target.value}) }
              onBlur={e => this.setState({ city: e.target.value.trim()}) }
              fullWidth
              value={this.state.city || ''}
              label="City"
              inputProps={{ name: 'city', id: 'city' }}
              disabled={!this.props.canEdit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              name='state'
              SelectProps={{ native: true }}
              onChange={e => this.setState({ state: e.target.value}) }
              fullWidth
              value={this.state.state || ''}
              label="State"
              inputProps={{ name: 'state', id: 'state' }}
              disabled={!this.props.canEdit}
            >
              {
                statesList.map(s => <option key={s} value={s}>{s}</option> || '')
              }
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name='postal-code'
              onChange={e => this.setState({ postalCode: e.target.value}) }
              onBlur={e => this.setState({ postalCode: e.target.value.trim()}) }
              fullWidth
              value={this.state.postalCode || ''}
              label="Postal Code"
              inputProps={{
                name: 'postal-code',
                id: 'postal-code',
                maxLength: 5,
                type: 'tel',
                pattern: '[0-9]*',
                noValidate: true
              }}
              disabled={!this.props.canEdit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name='country'
              fullWidth
              value={this.state.country || ''}
              label="Country"
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name='phone-number'
              type='numeric'
              onChange={e => this.setState({ phoneNumber: e.target.value}) }
              onBlur={e => this.setState({ phoneNumber: e.target.value.trim()}) }
              fullWidth
              value={this.state.phoneNumber || ''}
              inputProps={{
                name: 'phone-number',
                id: 'phone-number',
                maxLength: 10,
                type: 'tel',
                pattern: '[0-9]*',
                noValidate: true
              }}
              label="Phone Number"
              disabled={!this.props.canEdit}
            />
          </Grid>


          <Grid item xs={12} sm={6}>
            <TextField
              name='primary-website'
              onChange={e => this.setState({ primaryWebsite: e.target.value}) }
              onBlur={e => this.setState({ primaryWebsite: e.target.value.trim()}) }
              fullWidth
              value={this.state.primaryWebsite || ''}
              inputProps={{
                name: 'primary-website',
                id: 'primary-website',
                type: 'url',
              }}
              label="Primary Website"
              disabled={!this.props.canEdit}
            />
          </Grid>

          {
            !this.props.organization ?
              null
            :
              <Grid item xs={12} sm={6}>
                <InputLabel style={{ fontSize: 12 }}>
                  Sign Up Organization
                </InputLabel>

                <OrganizationAutosuggest
                  organizationName={this.state.organizationName}
                  onChangeInputValue={organizationName => this.setState({ organizationName })}
                  onSuggestionSelected={this.onOrganizationSuggestionSelected}
                  style={{ marginTop: -18 }}
                  onBlur={this.onBlur}
                  organizationId={this.props.organizationId}
                  disabled={!this.canEditSignUpCode()}
                />
              </Grid>
          }


          {
            !this.props.signUpCodes || !this.props.signUpCodes.length ?
              null
            :
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name='sign-up-code-id'
                  SelectProps={{ native: true }}
                  onChange={e => this.setState({ signUpCodeId: e.target.value}) }
                  fullWidth
                  value={this.state.signUpCodeId || ''}
                  label="Sign Up Code"
                  inputProps={{ name: 'sign-up-code-id', id: 'sign-up-code-id' }}
                  disabled={!this.canEditSignUpCode()}
                >
                  <option value={null} />
                  {
                    this.props.signUpCodes.map(
                      c => <option key={c.id} value={c.id}>{c.code}</option>
                    )
                  }
                </TextField>
              </Grid>
          }

          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {
              (this.props.canEdit) ?
                <>
                  <Button
                    color='primary'
                    variant='outlined'
                    // onClick={this.onCancel}
                    onClick={this.onCancel}
                    name='cancel-update-company-button'
                    style={{ marginRight: 5 }}
                  >
                    Cancel
                  </Button>

                  <Button
                    color='primary'
                    variant='contained'
                    onClick={this.onProcess}
                    name='update-company-button'
                  >
                    Update Company Info
                  </Button>
                </>
              :
                null
            }
          </Grid>
        </Grid>
      </Paper>
    );
  }

}

const styles = theme => ({
  paperRoot: {
    padding: paperPadding,
    paddingBottom: 32,
  }
});

CompanyInfoCard.defaultProps = {
  onUpdateCompany: a => {
    log('new attributes', a)
  },
  company: null,
  canEdit: true,
  organization: null,
  organizationName: '',
  onChangeOrganizationName: () => {},
  onOrganizationSuggestionsFetchRequested: () => Promise.resolve([]),
  onOrganizationSuggestionSelected: () => {},
  subscribedPlans: [],
  organizationId: ''
}

export default withContext(
  SnackbarContext,
  withStyles(styles)(
    CompanyInfoCard
  )
);
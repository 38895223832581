import React from 'react';

import { MaxWidth } from 'kn-react';

import { Grid, Typography, withStyles, TextField, MenuItem } from '@material-ui/core';

const FEED_TYPES = [
  'Link',
  'Status'
]

class LinkStatusFeedItemForm extends React.Component {
  state = {
    text: '',
    url: ''
  }



  componentDidMount = () => {
    this.setState({
      text: this.props.feedItem.text || '',
      url: this.props.feedItem.url || '',
    });
  }



  componentDidUpdate = oldProps => {
    if( this.props.feedItem !== oldProps.feedItem ){
      this.setState({
        text: this.props.feedItem.text || '',
        url: this.props.feedItem.url || ''
      });
    }
  }



  onBlur = (e, stateVariable) => {
    if( !e.target.value && !e.target.value.trim() ){
      return;
    }

    this.setState(
      { [stateVariable]: e.target.value.trim() },
      () => stateVariable === 'url' ?
        this.props.onBlurLink(this.state.text, this.state.url)
      :
        this.props.onBlurText(this.state.text, this.state.url)
    )
  }



  render() {
    const { classes } = this.props;

    return (
      <>
        <TextField
          label={'Feed Content'}
          value={this.state.text}
          onChange={e => this.setState({ text: e.target.value })}
          onBlur={e => this.onBlur(e, 'text')}
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          style={{ marginBottom: 15 }}
        />

        {
          this.props.feedType === 'Link' ?
            <TextField
              label={'Feed Link'}
              value={this.state.url}
              onChange={e => this.setState({ url: e.target.value })}
              onBlur={e => this.onBlur(e, 'url')}
              variant="outlined"
              fullWidth
            />
          :
            null
        }
      </>
    );
  }
}

const styles = theme => ({
  root: {
    padding: 16,
  }
});


LinkStatusFeedItemForm.defaultProps = {
  feedItem: { text: '', url: '' },
  feedType: '',
  onBlurText: () => {},
  onBlurLink: () => {},
}

export default withStyles(styles)(LinkStatusFeedItemForm)
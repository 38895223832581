import React from 'react';

import { IconButton, SvgIcon } from '@material-ui/core';


const yesIcon = props => (
  <IconButton
    aria-label="Yes Icon"
    style={{ backgroundColor: '#F7F6F6' }}
    disabled
  >
    <SvgIcon
      viewBox="0 0 50 37"
      style={{ fontSize: props.fontSize }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3536 26.3536L44.9828 0.724343L49.3093 5.48356L18.9953 35.7976L0.711853 17.9953L5.5 13.2071L18.6464 26.3536L19 26.7071L19.3536 26.3536Z"
        stroke={props.fill}
        fill='none'
      />
    </SvgIcon>
  </IconButton>
);


yesIcon.defaultProps = {
  fill: '#5E5F64'
}


export default yesIcon;
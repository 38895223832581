import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider, AuthProvider, SnackbarProvider, AuthRequiredGuard, AdBlockerDetectorProvider, UserRoleProvider, OrganizationRoleProvider, CompanyRoleProvider } from 'go-boost-library-react';
import { StripeProvider } from 'react-stripe-elements';

// import KnFacebookAuthCallback from 'FacebookAccount/KnFacebookAuthCallback';
// import KnGoogleAuthCallback from 'GoogleAccount/KnGoogleAuthCallback';
import './interceptors';
import { FacebookAuthCallback } from 'go-boost-library-react';
import { GoogleAuthCallback } from 'go-boost-library-react';
import AcceptUserInvitation from './Settings/UserInvitations/AcceptUserInvitation';
import ActivityLog from './Activity/ActivityLog';
import AddToHomeScreen from 'AddToHomeScreen/AddToHomeScreen';
import Ads from './Ads/Ads';
import Billing from './Billing/Billing';
import Companies from './Settings/Companies/Companies';
import CompanyProfile from './Settings/CompanyProfile/CompanyProfile';
import ErrorPage from './ErrorPage/ErrorPage';
import Forgot from './Forgot/Forgot';
import GoogleTagManagerProvider from './GoogleTagManager/GoogleTagManagerProvider';
import GoogleTagManagerUserData from './GoogleTagManager/GoogleTagManagerUserData';
import history from './history';
import Home from './Home/Home';
import Notifications from './Notifications/Notifications';
import Organizations from './Settings/Organizations/Organizations';
import Profile from './Profile/Profile';
import Reporting from './Reporting/Reporting';
import ReportSpam from './ReportSpam/ReportSpam';
import Reset from './Reset/Reset';
import ReviewRequestLandingPage from './Reviews/CompanyReviews/ReviewRequests/ReviewRequestLandingPage';
import Reviews from './Reviews/Reviews';
import SignIn from './SignIn/SignIn';
import SignOut from './SignOut/SignOut';
import SignUp from './SignUp/SignUp';
import Sites from './Sites/Sites';
import Social from './Social/Social';
import SSO from './SSO/SSO';
import stripeSetup from './stripeSetup.js';
import Users from './Settings/Users/Users';
import Virtual from './Virtual/Virtual';


import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';



class App extends React.Component {

  providers = children => (

    <SnackbarProvider>
      <AdBlockerDetectorProvider>
        <StripeProvider apiKey={stripeSetup.key}>
          <ThemeProvider>
            <GoogleTagManagerProvider>
              <AuthProvider>
                {children}
              </AuthProvider>
            </GoogleTagManagerProvider>
          </ThemeProvider>
        </StripeProvider>
      </AdBlockerDetectorProvider>
    </SnackbarProvider>
  )

  render() {
    return this.providers(
      <Router history={history}>

        <Switch>
          {/* Placed here to match routes like /user_invitations/new first */}
          <Route exact path="/invitations/:token/accept" component={ AcceptUserInvitation }/>
          <Route exact path="/user_invitations/:token/accept" component={ AcceptUserInvitation }/>
          <Route exact path="/error" component={ ErrorPage }/>
          <Route exact path="/sign_out" component={ SignOut }/>
          <Route exact path="/sign_in" component={ SignIn }/>
          <Route exact path="/sign_up" component={ SignUp }/>
          <Route exact path="/forgot" component={ Forgot }/>
          <Route path="/reset/:reset_token" component={ Reset }/>
          <Route path="/review_requests/:referenceId" component={ ReviewRequestLandingPage }/>
          <Route exact path="/auth/facebook/callback" component={FacebookAuthCallback} />
          <Route exact path="/auth/google/callback" component={GoogleAuthCallback} />
          <Route exact path="/sso/:code" component={ SSO }/>
          <Route exact path="/report_spam/:opportunityId" component={ ReportSpam }/>

          <AuthRequiredGuard>
            <UserRoleProvider
              history={history}
              roleProvider={currentUserRole => (
                currentUserRole.isCompanyRole() ?
                  CompanyRoleProvider
                  :
                  OrganizationRoleProvider
              )}
              createStopImpersonatingUrl={
                currentUserRoleUserId => `/users/${ currentUserRoleUserId }`
              }
            >
              <GoogleTagManagerUserData>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/notifications" component={Notifications} />
                  <Route path="/profile" component={Profile} />
                  <Route path="/add_to_home_screen" component={AddToHomeScreen} />

                  <Route path="/sites" component={Sites} />
                  <Route path="/ads" component={Ads} />
                  <Route path="/reviews" component={Reviews} />
                  <Route path="/social" component={Social} />
                  <Route path="/virtual" component={Virtual} />

                  <Route path="/company_profile" component={CompanyProfile} />
                  <Route path="/billing" component={Billing} />


                  <Route path="/reporting" component={ Reporting }/>
                  <Route path="/companies" component={ Companies }/>
                  <Route path="/organizations" component={ Organizations }/>
                  <Route path="/users" component={ Users }/>

                  <Route path="/activity" component={ActivityLog} />

                  <Route path="*" component={props => <Redirect to='/'/>} />
                </Switch>
              </GoogleTagManagerUserData>
            </UserRoleProvider>
          </AuthRequiredGuard>

        </Switch>
      </Router>
    );
  }
}

export default App;

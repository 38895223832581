import React from 'react';
import { withContext } from 'kn-react';

import { Dialog, IconButton, Link, TableCell, TableRow } from '@material-ui/core';
import { Done, Visibility } from '@material-ui/icons';

import { getSiteLink } from 'Sites/siteDesignPreviewHelpers';
import { SiteDesignsContext } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import ProductPricingTable from 'SharedSetup/ProductPlanSetup/ProductPricingTable';
import SiteDesignPreview from 'Sites/CompanySites/SiteDesigns/SiteDesignPreview/SiteDesignPreview';
import SitesUndoResubscription from 'Sites/CompanySites/SitesSetup/SitesUndoResubscription/SitesUndoResubscription';
class SitesPlanSetup extends React.Component {
  state = {
    isSiteDesignsDialogOpen: false,
    focusedSiteDesignId: 0
  }

  componentDidMount() {
    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'started_sites_plan_step', this.props.getUserRoleAuthHeaders);
  }


  toggleIsDialogOpen = siteDesignId => {
    this.setState({
      isSiteDesignsDialogOpen: !this.state.isSiteDesignsDialogOpen.selectedCell,
      focusedSiteDesignId: siteDesignId ? siteDesignId : null
    });
  }


  additionalSitesTableRows = (selectedPlanId, plans, classes, selectPlan) => {
    const siteDesignsById = {};
    const siteDesignsByPlanId = {};
    plans.forEach(
      p => {
        const siteDesigns = this.props.filterSiteDesignsByPlanId(
          p.id
        );

        if( !siteDesigns.length ){
          siteDesignsByPlanId[ p.id ] = [];
          return;
        }

        return siteDesigns.forEach(
          d => {
            siteDesignsById[ d.id ] = d;

            if( !siteDesignsByPlanId[ p.id ] ){
              siteDesignsByPlanId[ p.id ] = [];
            }

            if( !siteDesignsByPlanId[ p.id ].find(sd => sd.id === d.id) ){
              siteDesignsByPlanId[ p.id ].push( d );
            }
          }
        );
      }
    );

    const orderedSiteDesignsId = Object.keys( siteDesignsById ).sort(
      (a, b) => siteDesignsById[ a ].name.localeCompare(
        siteDesignsById[ b ].name
      )
    );


    const availableProducts = new Set();
    if( this.props.upgradableSitesPlans ){
      this.props.upgradableSitesPlans.forEach(
        p => Object.keys(p.attributes || {}).forEach(
          k => availableProducts.add( k )
        )
      );
    }

    return (
      <>
        <TableRow>
          <TableCell
            className={classes.attributeColumnCell}
          >
            Site Designs
          </TableCell>

          <TableCell
            className={`${ classes.attributeColumnCell } ${ classes.helperTextCell } ${ classes.rowHelperTextCell }`}
          />

          {
            plans.map((p, i) => {
              const selectedCellClass = (
                p.id === selectedPlanId ?
                  classes.selectedCell
                :
                  null
              );

              const siteDesigns = this.props.filterSiteDesignsByPlanId(
                p.id
              );

              return (
                <TableCell
                  key={i}
                  className={`${ classes.tableCell } ${ selectedCellClass }`}
                  onClick={() => selectPlan( p )}
                >
                  { siteDesigns.length }
                </TableCell>
              )
            })
          }
        </TableRow>

        {
          orderedSiteDesignsId.map(
            (siteDesignId, i) => {
              const siteDesign = siteDesignsById[ siteDesignId ];

              const previewUrl = (
                siteDesign && siteDesign.baseSiteId ?
                  getSiteLink( siteDesign.baseSiteId )
                :
                  null
              );

              return (
                <TableRow key={i}>
                  <TableCell
                    align="left"
                    style={{ paddingLeft: 60 }}
                    className={ classes.attributeColumnCell }
                  >
                    <Link
                      component='a'
                      href={previewUrl || '#'}
                      target='_blank'
                    >
                      { siteDesignsById[ siteDesignId ].name }
                    </Link>

                  </TableCell>

                  <TableCell
                    className={`${ classes.attributeColumnCell } ${ classes.helperTextCell } ${ classes.rowHelperTextCell }`}
                  />

                  {
                    plans.map((p, j) => {
                      const hasDesign = siteDesignsByPlanId[ p.id ].find(
                        d => d.id == siteDesignId
                      );

                      const isSelected = p.id === selectedPlanId;

                      const bottomCellClass = (
                        isSelected && availableProducts.size < 2 ?
                          classes.selectedBottomCell
                        :
                          null
                      );


                      return (
                        <TableCell
                          key={j}
                          style={{
                            textAlign: 'center'
                          }}
                          className={`
                            ${ isSelected ? classes.selectedCell : null }
                            ${ classes.tableCell }
                            ${ bottomCellClass }
                          `}
                          onClick={() => selectPlan( p )}
                        >
                          {
                            hasDesign ?
                              <Done color="primary"/>
                            :
                              null
                          }
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
              )
            }
          )
        }
      </>
    )
  }

  render() {
    const focusedSiteDesign = this.props.siteDesigns.find(
      d => d.id == this.state.focusedSiteDesignId
    );

    return (
      <>
        <ProductPricingTable
          {...this.props}
          subscribedProductPlan={this.props.subscribedSitesPlan}
          productPlans={this.props.upgradableSitesPlans}
          productName="Sites"
          productUndoResubscriptionAlert={SitesUndoResubscription}
          additionalProductTableRows={this.additionalSitesTableRows}
        />

        <Dialog
          open={this.state.isSiteDesignsDialogOpen}
          onClose={() => this.setState({ isSiteDesignsDialogOpen: false })}
          maxWidth='lg'
          PaperComponent={'div'}
        >
          <SiteDesignPreview
            focusedSiteDesign={focusedSiteDesign}
          />
        </Dialog>
      </>
    );
  }
}


SitesPlanSetup.defaultProps = {
  upgradableSitesPlans: []
}



export default withContext(
  TransitionContext,
  SiteDesignsContext,
  SitesPlanSetup
);
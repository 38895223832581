import React from 'react';
import { JsonModel } from 'kn-react';
import { Tooltip, Avatar, SvgIcon } from '@material-ui/core';

export default class ReviewSourceModel {

  static fromJSON(json) {
    const model = new ReviewSourceModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON() {
    return JsonModel.modelToJSON(this);
  }

  isFacebook() {
    return this.name === 'Facebook';
  }


  isGoogle() {
    return this.name === 'Google';
  }

  isRespondable() {
    return this.isFacebook() || this.isGoogle();
  }


  getAvatar(options = {}) {

    options.avatarProps = options.avatarProps || {
      imgProps: { style: { width: 45 } }
    };

    return (
      <Tooltip title={this.name}>
        <Avatar {...options.avatarProps} src={this.icon} />
      </Tooltip>
    );
  }

}
import React from 'react';
import Axios from 'axios';
import * as d3 from 'd3';

import { ChannelContext } from 'Channels/ChannelProvider';
import { withContext, log } from 'kn-react';
import moment from 'moment';

import AdsDashboardContent from './AdsDashboardContent';
import { compareToPrior, metricChange, getPriorTimePeriod, absoluteChangeFormat, percentChangeFormat, fillMissingMoments } from 'SharedDashboard/sharedDashboard';


const timePeriodOptions = [
  { unit: 'month', label: 'All time' },
  { unit: 'day', numberOfUnits: 7, label: 'Past 7 days' },
  { unit: 'day', numberOfUnits: 30, label: 'Past 30 days' },
  { unit: 'day', numberOfUnits: 90, label: 'Past 90 days' },
  { unit: 'month', numberOfUnits: 12, label: 'Past year' },
];


const chartFormats = {
  day: 'MMMM D',
  month: 'MMM YYYY',
};


class AdsDashboard extends React.Component {
  constructor(props) {
    super(props);

    const channelOptions = this.props.adsChannels;

    this.state = {
      // Filters
      timePeriod: timePeriodOptions[0],

      channelOptions,
      channel: channelOptions[0],

      companies: [],

      opportunityTypeDistribution: [],
      totalOpportunities: '',
      totalOpportunitiesChange: '',

      adsChartData: [],

      totalViews: '',
      totalViewsChange: '',

      totalClicks: '',
      totalClicksChange: '',

      totalCTR: '',
      totalCTRChange: '',

      totalCost: '',
    };

  }


  componentDidMount = () => {
    this.fetchData();
  }



  fetchData = () => {
    this.fetchOpportunityTypeDistribution();
    this.fetchAggregateAdsStats();
    this.fetchAggregateAdsReportedOnDistribution();
  }


  fetchAggregateAdsStats = () => {
    const { channel } = this.state;
    log('channel', channel);

    const timePeriodMoment = this.getTimePeriodMoment();

    return this.dashboardDataRequest(
      '/ads/aggregate_ads_reports',
      '/stats',
      {
        start_date: this.getStartDate(timePeriodMoment) ,
      }
    )
    .then(response => {
      log('fetchAggregateAdsStats response', response);

      const stats = response.data.stats;

      this.setState({
        totalViews: stats.views,
        totalClicks: stats.clicks,
        totalCost: stats.cost_cents / 100,
        totalCTR: stats.clicks / stats.views,
      });


      if(!timePeriodMoment) {
        this.setState({
          totalViewsChange: ' ',
          totalClicksChange: ' ',
          totalCTRChange: ' ',
        });
        return;
      }

      const { numberOfUnits, unit } = this.state.timePeriod;
      const priorTimePeriod = getPriorTimePeriod(timePeriodMoment, numberOfUnits, unit);

      return this.dashboardDataRequest(
        '/ads/aggregate_ads_reports',
        '/stats',
        {
          start_date: priorTimePeriod[0],
          end_date: priorTimePeriod[1]
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;
        const priorStats = response.data.stats;
        log('priorStats', priorStats);

        const totalClicksChange = compareToPrior(
          stats.clicks,
          priorStats.clicks,
          'clicks',
          priorPeriodName,
          absoluteChangeFormat
        );


        const totalViewsChange = compareToPrior(
          stats.views,
          priorStats.views,
          'views',
          priorPeriodName,
          absoluteChangeFormat
        );


        const totalCTRChange = compareToPrior(
          stats.clicks / stats.views,
          priorStats.clicks / priorStats.views,
          'data',
          priorPeriodName,
          percentChangeFormat
        );


        this.setState({
          totalClicksChange: metricChange(totalClicksChange),
          totalViewsChange: metricChange(totalViewsChange),
          totalCTRChange: metricChange(totalCTRChange),
        });

      });


    });
  }




  fetchOpportunityTypeDistribution = () => {
    const timePeriodMoment = this.getTimePeriodMoment();
    const includeDescendants = (this.props.roleTypePath === 'organizations' && !this.state.companies.length)

    return this.dashboardDataRequest(
      '/core/opportunities',
      '/opportunity_type_distribution',
      {
        start_date: this.getStartDate(timePeriodMoment),
        opportunity_types: ['call','form_entry'],
        include_descendants: includeDescendants ? includeDescendants : undefined,
        is_spam: false
      }
    )
    .then(response => {
      log('fetchOpportunityTypeDistribution response', response);
      const opportunityTypeDistribution = response.data.opportunity_type_distribution.map(d => ({
        count: d.count,
        opportunityType: d.opportunity_type
      }));

      const totalOpportunities = d3.sum(opportunityTypeDistribution, d => d.count)

      this.setState({ opportunityTypeDistribution, totalOpportunities });


      if(!timePeriodMoment) {
        this.setState({
          totalOpportunitiesChange: ' ',
        });
        return;
      }

      const { numberOfUnits, unit } = this.state.timePeriod;
      const priorTimePeriod = getPriorTimePeriod(timePeriodMoment, numberOfUnits, unit);

      return this.dashboardDataRequest(
        '/core/opportunities',
        '/opportunity_type_distribution',
        {
          start_date: priorTimePeriod[0],
          end_date: priorTimePeriod[1],
          opportunity_types: ['call','form_entry'],
          include_descendants: includeDescendants ? includeDescendants : undefined,
          is_spam: false
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;

        const priorDistribution = response.data.opportunity_type_distribution;
        log('priorDistribution', priorDistribution);

        const priorTotalOpportunities = d3.sum(priorDistribution, d => d.count)

        const totalOpportunitiesChange = compareToPrior(
          totalOpportunities,
          priorTotalOpportunities,
          'opportunities',
          priorPeriodName,
          absoluteChangeFormat
        );

        this.setState({
          totalOpportunitiesChange: metricChange(totalOpportunitiesChange)
        });
      });

    });
  }


  fetchAggregateAdsReportedOnDistribution = () => {
    const { timePeriod, channel } = this.state;
    log('channel', channel);

    const timePeriodMoment = this.getTimePeriodMoment();
    const reportedOnFormat = timePeriod.unit === 'month' ? '%Y-%m-01' : '%Y-%m-%d';

    return this.dashboardDataRequest(
      '/ads/aggregate_ads_reports',
      '/reported_on_distribution',
      {
        start_date: this.getStartDate(timePeriodMoment) ,
        reported_on_format: reportedOnFormat
      }
    )
    .then(response => {
      log('fetchAggregateAdsReportedOnDistribution response', response);

      const reportedOnDistribution = response.data.reported_on_distribution;

      if (!reportedOnDistribution.length) {
        this.setState({ adsChartData: [] });
        return;
      }

      // Find the earliest moment
      const endMoment = moment();
      let startMoment;
      if (timePeriod.numberOfUnits) {
        startMoment = endMoment.clone().subtract(timePeriod.numberOfUnits, timePeriod.unit);
      } else {
        startMoment = moment(reportedOnDistribution.slice(-1)[0].reported_on);
      }


      let adsChartData = fillMissingMoments(
        reportedOnDistribution,
        startMoment,
        endMoment,
        timePeriod.unit,
        d => d.reported_on,
        (m, d = {
          clicks: 0,
          cost_cents: 0,
          views: 0,
        }) => ({
          Views: d.views,
          Clicks: d.clicks,
          Cost: d.cost_cents / 100,
          CTR: d.clicks / d.views,
          name: m.format(chartFormats[timePeriod.unit]),
        })
      );

      log('adsChartData', adsChartData);
      this.setState({ adsChartData });
    })
  }




  dashboardDataRequest = (controllerPath, subPath, extraParams = {}) => {
    const { channel, companies } = this.state;
    const companyIds = companies.map(c => c.id);

    const channel_ids = channel && channel.id !== 'all' ? [channel.id] : this.props.adsChannels.filter(
      c => c.id !== 'all'
    ).map(c => c.id);

    return Axios.get(
      `/api${ controllerPath }/${this.props.roleTypePath}/${this.props.roleTypeId}${subPath}`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          ...extraParams,
          channel_ids,
          company_ids: companyIds.length ? companyIds : undefined,
        }
      }
    );
  }



  onChangeTimePeriod = timePeriod => {
    this.setState({ timePeriod, opportunitiesPage: 0 }, this.fetchData)
  }

  onChangeChannel = channel => {
    log('new ch', channel)
    this.setState({ channel, opportunitiesPage: 0 }, this.fetchData);
  }

  onChangeCompanies = companies => {
    this.setState({ companies }, this.fetchData);
  }

  render() {

    const timePeriodMoment = this.getTimePeriodMoment(this.state.timePeriod);
    const opportunitiesStartDate = this.getStartDate(timePeriodMoment);

    return (
      <AdsDashboardContent

        isSearchCompaniesVisible={this.props.isSearchCompaniesVisible}
        roleTypePath={this.props.roleTypePath}
        roleTypeId={this.props.roleTypeId}

        timePeriodOptions={timePeriodOptions}
        timePeriod={this.state.timePeriod}
        onChangeTimePeriod={this.onChangeTimePeriod}

        channelOptions={this.state.channelOptions}
        channel={this.state.channel}


        onChangeCompanies={this.onChangeCompanies}
        companies={this.state.companies}

        opportunitiesStartDate={opportunitiesStartDate}
        onChangeChannel={this.onChangeChannel}

        opportunityTypeDistribution={this.state.opportunityTypeDistribution}
        totalOpportunities={this.state.totalOpportunities}
        totalOpportunitiesChange={this.state.totalOpportunitiesChange}

        totalViews={this.state.totalViews}
        totalViewsChange={this.state.totalViewsChange}

        totalClicks={this.state.totalClicks}
        totalClicksChange={this.state.totalClicksChange}

        totalCTR={this.state.totalCTR}
        totalCTRChange={this.state.totalCTRChange}

        totalCost={this.state.totalCost}

        adsChartData={this.state.adsChartData}
        getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}

        />
    );
  }




  getTimePeriodMoment = () => {
    const { numberOfUnits, unit } = this.state.timePeriod;
    return numberOfUnits ? moment().subtract(numberOfUnits, unit) : undefined;
  }


  getStartDate = timePeriodMoment => {
    return timePeriodMoment ? timePeriodMoment.format('YYYY-MM-DD') : undefined;
  }

}

export default withContext(ChannelContext, AdsDashboard);
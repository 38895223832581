import React from 'react';
import Axios from 'axios';

import { log, withContext } from 'kn-react';
import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';

import { LinearProgress } from '@material-ui/core';

import { CompanySitesProfileContext } from '../CompanySitesProfile/CompanySitesProfileProvider';
import CompanySiteDesignModel from './CompanySiteDesignModel';
import PlanSiteDesignModel from './PlanSiteDesignModel';
import SiteDesignModel from 'Sites/CompanySites/SiteDesignModel';



const DEFAULT_STATE = {
  loadingSiteDesigns: true,
  siteDesigns: [],
  planSiteDesigns: [],
  companySiteDesigns: [],
};


export const SiteDesignsContext = React.createContext(DEFAULT_STATE);


class SiteDesignsProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {
    const { subscribedSitesPlan } = this.props;

    return this.fetchSiteDesigns()
      .then(this.fetchCompanySiteDesigns)
      .finally(() => this.setState({ loadingSiteDesigns: false }));
  }


  fetchSiteDesigns = () => {
    const { company } = this.props;
    log('fetchSiteDesigns');
    return Axios.get(
        `/api/sites/site_designs/companies/${company.id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      )
      .then(response => {
        log('fetchSiteDesigns', response);
        const siteDesigns = response.data.site_designs.map(b => (
          SiteDesignModel.fromJSON(b)
        ));
        log('fetchSiteDesigns siteDesigns', siteDesigns);

        const planSiteDesigns = response.data.plan_site_designs.map(b => (
          PlanSiteDesignModel.fromJSON(b)
        ));
        log('fetchSiteDesigns planSiteDesigns', planSiteDesigns);

        this.setState({ siteDesigns, planSiteDesigns });
      });
  }



  filterSiteDesignsByPlanId = planId => {
    // log('filterSiteDesignsByPlanId', planId);
    const { planSiteDesigns, siteDesigns } = this.state;
    const inPlan = planSiteDesigns.filter(p => p.planId == planId);
    // log('inPlan', inPlan, planSiteDesigns);
    return siteDesigns.filter(b => inPlan.find(p => p.siteDesignId == b.id));
  }


  fetchCompanySiteDesigns = () => {
    const { company } = this.props;

    return Axios.get(
        `/api/sites/company_site_designs/companies/${company.id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      )
      .then(response => {
        log('fetchCompanySiteDesigns', response);
        const companySiteDesigns = response.data.company_site_designs.map(b => (
          CompanySiteDesignModel.fromJSON(b)
        ));
        this.setState({ companySiteDesigns });
      });
  }


  findOrCreateCompanySiteDesign = siteDesignId => {
    const companySiteDesign = this.state.companySiteDesigns.find(
      d => d.siteDesignId === siteDesignId
    );


    if( companySiteDesign ){
      return Promise.resolve(companySiteDesign)
    }


    log('findOrCreateCompanySiteDesign', siteDesignId)
    return Axios.post(
      `/api/sites/company_site_designs`,
      { site_design_id: siteDesignId },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('findOrCreateCompanySiteDesign response', response);

      const companySiteDesign = CompanySiteDesignModel.fromJSON(response.data.company_site_design);

      this.setState({
        companySiteDesigns: [
          ...this.state.companySiteDesigns,
          companySiteDesign
        ]
      });

      return companySiteDesign;
    });
  }


  updateCompanyBoostedService = (companyBoostedServiceId, updatedAttributes) => {
    log('updateCompanyBoostedService', companyBoostedServiceId, updatedAttributes);
    const companyBoostedService = new CompanySiteDesignModel(updatedAttributes);

    return Axios.put(
      `/api/sites/company_site_designs/${companyBoostedServiceId}`,
      { company_site_design: companyBoostedService },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('updateCompanyBoostedService response', response);
      const newCompanyBoostedService = CompanySiteDesignModel.fromJSON(
        response.data.company_site_design
      );

      this.setState({
        companySiteDesigns: [
          ...this.state.companySiteDesigns.filter(c => (
            c.id !== newCompanyBoostedService.id
          )),
          newCompanyBoostedService
        ]
      });
    });
  }


  parseAndUpdateCompanyBoostedServiceResponse = response => {
    log('parseAndUpdateCompanyBoostedServiceResponse response', response);

    const newCompanyBoostedService = CompanySiteDesignModel.fromJSON(
      response.data.company_site_design
    );

    const companySiteDesigns = [...this.state.companySiteDesigns];
    const index = companySiteDesigns.findIndex(c => c.id === newCompanyBoostedService.id);
    companySiteDesigns[index] = newCompanyBoostedService;

    this.setState({ companySiteDesigns });
  }


  enqueueSiteDesignSetup = companyBoostedServiceId => {
    log('enqueueSiteDesignSetup', companyBoostedServiceId);
    return Axios.post(
      `/api/sites/company_site_design_setup/enqueue`,
      { company_site_design_id: companyBoostedServiceId },
      { headers: this.props.getUserRoleAuthHeaders()}
    )
    .then(response => {
      log('enqueueSiteDesignSetup response', response);
    })
    .then(this.fetchCompanySiteDesigns);
  }



  render(){
    const { loadingSiteDesigns } = this.state;

    if(loadingSiteDesigns) {
      return <LinearProgress/>;
    }

    return (
      <SiteDesignsContext.Provider
        value={{
          fetchCompanySiteDesigns: this.fetchCompanySiteDesigns,
          siteDesigns: this.state.siteDesigns,
          filterSiteDesignsByPlanId: this.filterSiteDesignsByPlanId,
          companySiteDesigns: this.state.companySiteDesigns,
          findOrCreateCompanySiteDesign: this.findOrCreateCompanySiteDesign,
          updateCompanyBoostedService: this.updateCompanyBoostedService,
          enqueueSiteDesignSetup: this.enqueueSiteDesignSetup,
          toggleCompanyBoostedService: this.toggleCompanyBoostedService,
        }}
      >
        { this.props.children }
      </SiteDesignsContext.Provider>
    );
  }
}


export default withContext(PlanContext, CompanyRoleContext, CompanySitesProfileContext, SiteDesignsProvider);
import React from 'react';

import { SnackbarContext, Alert, PromiseButton, PlanContext } from 'go-boost-library-react';
import { withContext, withProvider } from 'kn-react';

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Button, withStyles, MenuItem, Typography } from '@material-ui/core';
import { Code } from '@material-ui/icons';

import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import EmbedVirtualMeetingRoomDialog from './EmbedVirtualMeetingRoomDialog';


export const ROOM_MODES = {
  'normal': 'Normal (1-4)',
  'group': 'Group (5+)'
};

export const ROOM_TYPES = {
  'meeting_room': 'Meeting Room',
  'lobby': 'Lobby',
};


const DEFAULT_STATE = {
  url: '',
  roomType: Object.keys( ROOM_TYPES )[ 0 ],
  roomMode: Object.keys( ROOM_MODES )[ 0 ],
  isLocked: true,
  shouldRecord: true,
  isVirtualTranscriptIncluded: true,
  isVirtualTextAnalysisIncluded: true,
  isVirtualVideoAnalysisIncluded: true,
  isEmbedDialogOpen: false
}


class VirtualMeetingRoomDialog extends React.Component {
  constructor(props){
    super(props);


    this.state = {
      ...this.constructNewState()
    };
  }



  constructNewState = () => {
    let shouldRecord = this.canRecordMeeting() ? true : false;
    let isVirtualTranscriptIncluded = this.canTranscribe() ? true : false;
    let isVirtualTextAnalysisIncluded = this.canPerformTextAnalysis() ? true : false;
    let isVirtualVideoAnalysisIncluded = this.canPerformVideoAnalysis() ? true : false;
    let isLocked = false;


    if( this.props.meetingRoom ){
      shouldRecord = this.props.meetingRoom.shouldRecord || false;
      isVirtualTranscriptIncluded = this.props.meetingRoom.isVirtualTranscriptIncluded || false;
      isVirtualTextAnalysisIncluded = this.props.meetingRoom.isVirtualTextAnalysisIncluded || false;
      isVirtualVideoAnalysisIncluded = this.props.meetingRoom.isVirtualVideoAnalysisIncluded || false;
      isLocked = this.props.meetingRoom.isLocked || false;
    }


    let url = DEFAULT_STATE.url;
    if(
      this.props.isSubscribedToSites &&
      this.props.companySitesProfile &&
      this.props.companySitesProfile.isSetupComplete &&
      !this.props.companySitesProfile.isShutdown
    ){
      const domain = (
        this.props.companySitesProfile.byoDomain ||
        this.props.companySitesProfile.newDomain
      );

      url = `${ domain }`;
    }


    return ({
      ...DEFAULT_STATE,
      shouldRecord,
      url,
      isLocked,
      isVirtualTranscriptIncluded,
      isVirtualTextAnalysisIncluded,
      isVirtualVideoAnalysisIncluded,
    });
  }


  componentDidUpdate = oldProps => {
    if( this.props.open && oldProps.open !== this.props.open ){

      return this.setState({
        ...this.constructNewState()
      });
    }
  }


  validateStep = () => {
    if( !Boolean( this.state.url ) ){
      this.props.showSnackbar('Please specify the Meeting URL.');

      return false;
    }


    if (
      this.props.subscribedVirtualPlan &&
      !this.props.subscribedVirtualPlan.isVirtualEmbedIncluded
    ) {
      if(
        !this.props.isSubscribedToSites ||
        !this.props.companySitesProfile ||
        !this.props.companySitesProfile.isSetupComplete ||
        this.props.companySitesProfile.isShutdown ||
        !(
          this.props.companySitesProfile.byoDomain ||
          this.props.companySitesProfile.newDomain
        )
      ){
        this.props.showSnackbar(`You must be subscribed to Sites and use that domain.`);

        return false;
      }


      const domain = (
        this.props.companySitesProfile.byoDomain ||
        this.props.companySitesProfile.newDomain
      );


      let strippedUrl = this.state.url.replace(
        /^https:\/\//, ''
      ).replace(
        /^www\./, ''
      );

      if( !strippedUrl.startsWith( `${ domain }` ) ){
        this.props.showSnackbar(`The Meeting URL must start with "${ domain }".`);

        return false;
      }
    }


    return true;
  }


  canRecordMeeting = () => (
    this.props.subscribedVirtualPlan ?
      this.props.subscribedVirtualPlan.isVirtualVideoRecordingAvailable
    :
      false
  )


  canTranscribe = () => (
    this.props.subscribedVirtualPlan ?
      this.props.subscribedVirtualPlan.isVirtualTranscriptIncluded
    :
      false
  )


  canPerformTextAnalysis = () => (
    this.props.subscribedVirtualPlan ?
      this.props.subscribedVirtualPlan.isVirtualTextAnalysisIncluded
    :
      false
  )


  canPerformVideoAnalysis = () => (
    this.props.subscribedVirtualPlan ?
      this.props.subscribedVirtualPlan.isVirtualVideoAnalysisIncluded
    :
      false
  )


  onAddRoom = () => {
    if( !this.isMeetingRoomCreated() && !this.validateStep() ){
      return Promise.reject();
    }

    return this.props.onAddRoom({
      url: this.state.url,
      roomType: this.state.roomType,
      roomMode: this.state.roomMode,
      isLocked: this.state.isLocked,
      shouldRecord: this.state.shouldRecord,
      isVirtualTranscriptIncluded: this.state.isVirtualTranscriptIncluded,
      isVirtualTextAnalysisIncluded: this.state.isVirtualTextAnalysisIncluded,
      isVirtualVideoAnalysisIncluded: this.state.isVirtualVideoAnalysisIncluded,
    });
  }


  toggleTranscribeMeeting = e => {
    const isVirtualTranscriptIncluded = e.target.value;
    let isVirtualTextAnalysisIncluded = this.state.isVirtualTextAnalysisIncluded;

    if( !isVirtualTranscriptIncluded ){
      isVirtualTextAnalysisIncluded = false;
    }


    return this.setState({
      isVirtualTranscriptIncluded,
      isVirtualTextAnalysisIncluded
    });
  }


  togglePerformTextAnalysis = e => {
    const isVirtualTextAnalysisIncluded = e.target.value;
    let isVirtualTranscriptIncluded = this.state.isVirtualTranscriptIncluded;

    if( isVirtualTextAnalysisIncluded ){
      isVirtualTranscriptIncluded = true;
    }


    return this.setState({
      isVirtualTranscriptIncluded,
      isVirtualTextAnalysisIncluded
    });
  }


  toggleCanRecord = e => {
    const shouldRecord = e.target.value;
    let isVirtualTranscriptIncluded = this.state.isVirtualTranscriptIncluded;
    let isVirtualTextAnalysisIncluded = this.state.isVirtualTextAnalysisIncluded;
    let isVirtualVideoAnalysisIncluded = this.state.isVirtualVideoAnalysisIncluded;

    if( !shouldRecord ){
      isVirtualTranscriptIncluded = false;
      isVirtualTextAnalysisIncluded = false;
      isVirtualVideoAnalysisIncluded = false;
    }


    return this.setState({
      shouldRecord,
      isVirtualTranscriptIncluded,
      isVirtualTextAnalysisIncluded,
      isVirtualVideoAnalysisIncluded
    });
  }


  isMeetingRoomCreated = () => (
    this.props.meetingRoom &&
    this.props.meetingRoom.id
  )


  render(){
    const { classes } = this.props;

    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          { this.isMeetingRoomCreated() ? 'Edit' : 'Add' } Meeting Room

          {
            (
              !this.isMeetingRoomCreated() ||
              (
                !this.props.subscribedVirtualPlan ||
                !this.props.subscribedVirtualPlan.isVirtualEmbedIncluded
              )
            ) ?
              null
            :
              <>
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.embedButton}
                  onClick={() => this.setState({ isEmbedDialogOpen: true })}
                >
                  <Code className={classes.code}/>

                  View Embed Code
                </Button>

                <EmbedVirtualMeetingRoomDialog
                  open={this.state.isEmbedDialogOpen}
                  onClose={() => this.setState({ isEmbedDialogOpen: false })}
                  meetingRoom={this.props.meetingRoom}
                />
              </>
          }
        </DialogTitle>


        <DialogContent className={classes.dialogContent}>
          {
            this.isMeetingRoomCreated() ?
              null
            :
              <>
                <TextField
                  fullWidth
                  required
                  label='Meeting Room URL'
                  value={this.state.url}
                  onChange={e => this.setState({ url: e.target.value })}
                  variant='outlined'
                  className={classes.textField}
                  helperText={'This meeting room will only be available at this URL. NOTE: You must also place a specific script tag on this page on your website for this meeting room to be available.'}
                />

                <TextField
                  select
                  fullWidth
                  label='Room Type'
                  value={this.state.roomType}
                  onChange={e => this.setState({ roomType: e.target.value })}
                  variant='outlined'
                  className={classes.textField}
                  helperText={
                    <>
                      Lobby - This room should be on your website. Participants can enter this room at any time and you will be notified when a cusomter enters this room.<br />
                      Meeting Room - This room should not be posted anywhere. Participants can enter this room at any time (unless you lock the room) but you will not be notified when a customer enters this room.
                    </>
                  }
                >
                  {
                    Object.keys( ROOM_TYPES ).map(
                      (t, i) => (
                        <MenuItem value={t} key={i}>{ ROOM_TYPES[ t ] }</MenuItem>
                      )
                    )
                  }
                </TextField>

                <TextField
                  select
                  fullWidth
                  label='Room Size'
                  value={this.state.roomMode}
                  onChange={e => this.setState({ roomMode: e.target.value })}
                  variant='outlined'
                  className={classes.textField}
                  helperText={'Only the allowed number of participants can enter this room at the same time.'}
                >
                  {
                    Object.keys( ROOM_MODES ).map(
                      (m, i) => (
                        <MenuItem value={m} key={i}>{ ROOM_MODES[ m ] }</MenuItem>
                      )
                    )
                  }
                </TextField>

                {
                  !this.canRecordMeeting() ?
                    null
                  :
                    <TextField
                      select
                      fullWidth
                      label='Record the Meeting?'
                      value={this.state.shouldRecord}
                      onChange={this.toggleCanRecord}
                      variant='outlined'
                      className={classes.textField}
                      disabled={!this.canRecordMeeting()}
                      helperText={'All participants will be required to provide their consent before joining the meeting room.'}
                    >
                      {
                        [true, false].map(
                          (t, j) => (
                            <MenuItem value={t} key={j}>{ t ? 'Yes': 'No' }</MenuItem>
                          )
                        )
                      }
                    </TextField>
                }
              </>
          }


          <TextField
            select
            fullWidth
            label='Is Room Locked?'
            value={this.state.isLocked}
            onChange={e => this.setState({ isLocked: e.target.value })}
            variant='outlined'
            className={classes.textField}
            helperText={'If the room is locked, you must first join on a host URL before participants can join on the Meeting Room URL.'}
          >
            {
              [false, true].map(
                (t, j) => (
                  <MenuItem value={t} key={j}>{ t ? 'Yes': 'No' }</MenuItem>
                )
              )
            }
          </TextField>


          {
            (
              !this.canRecordMeeting() ||
              !this.canTranscribe() ||
              (
                this.isMeetingRoomCreated() &&
                !this.state.shouldRecord
              )
            ) ?
              null
            :
              <TextField
                select
                fullWidth
                label='Transcribe the Meeting?'
                value={this.state.isVirtualTranscriptIncluded}
                onChange={this.toggleTranscribeMeeting}
                variant='outlined'
                className={classes.textField}
                disabled={!this.canTranscribe() || !this.state.shouldRecord}
                helperText={`Transcribe the meeting's audio live. Required if Text Analysis is performed. Requires the meeting to be recorded.`}
              >
                {
                  [true, false].map(
                    (t, j) => (
                      <MenuItem value={t} key={j}>{ t ? 'Yes': 'No' }</MenuItem>
                    )
                  )
                }
              </TextField>
          }

          {
            (
              !this.canRecordMeeting() ||
              !this.canPerformTextAnalysis() ||
              (
                this.isMeetingRoomCreated() &&
                !this.state.shouldRecord
              )
            ) ?
              null
            :
              <TextField
                select
                fullWidth
                label='Perform Text Analysis?'
                value={this.state.isVirtualTextAnalysisIncluded}
                onChange={this.togglePerformTextAnalysis}
                variant='outlined'
                className={classes.textField}
                disabled={!this.canPerformTextAnalysis() || !this.state.shouldRecord}
                helperText={'Analyze the audio for important words or phrases. Requires the meeting to be recorded and transcribed.'}
              >
                {
                  [true, false].map(
                    (t, j) => (
                      <MenuItem value={t} key={j}>{ t ? 'Yes': 'No' }</MenuItem>
                    )
                  )
                }
              </TextField>
          }

          {
            (
              !this.canRecordMeeting() ||
              !this.canPerformVideoAnalysis() ||
              (
                this.isMeetingRoomCreated() &&
                !this.state.shouldRecord
              )
            ) ?
              null
            :
              <TextField
                select
                fullWidth
                label='Perform Video Analysis?'
                value={this.state.isVirtualVideoAnalysisIncluded}
                onChange={e => this.setState({ isVirtualVideoAnalysisIncluded: e.target.value })}
                variant='outlined'
                className={classes.textField}
                disabled={!this.canPerformVideoAnalysis() || !this.state.shouldRecord}
                helperText={`Analyze the meeting to create a textual frame-by-frame summary of the meeting. Requires the meeting to be recorded.`}
              >
                {
                  [true, false].map(
                    (t, j) => (
                      <MenuItem value={t} key={j}>{ t ? 'Yes': 'No' }</MenuItem>
                    )
                  )
                }
              </TextField>
          }

          {
            !(
              this.isMeetingRoomCreated() &&
              !this.state.shouldRecord
            ) ?
              null
            :
              <Typography variant='caption'>
                Note: Meeting Transcript, Text Analysis, and Video Analysis are only available for recorded Meetings. Create a new Meeting Room and allow reocrding to get started.
              </Typography>
          }
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={this.props.onClose}
            color='primary'
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          <PromiseButton
            onProcess={this.onAddRoom}
            buttonProps={{
              color: 'primary',
              variant: 'contained'
            }}
          >
            { this.isMeetingRoomCreated() ? 'Edit' : 'Add' }
          </PromiseButton>
        </DialogActions>
      </Dialog>
    )
  }
}


VirtualMeetingRoomDialog.defaultProps = {
  step: null,
  open: false,
  meetingRoom: null,
  onClose: () => {},
  onAddRoom: () => {},
}


const styles = theme => ({
  dialogContent: {
    paddingTop: 10
  },
  textField: {
    marginBottom: 30
  },
  cancelButton: {
    marginRight: 15
  },
  embedButton: {
    float: 'right'
  },
  code: {
    marginRight: 5
  }
})


export default withProvider(
  CompanySitesProfileProvider,
  withContext(
    CompanySitesProfileContext,
    SnackbarContext,
    PlanContext,
    withStyles(styles)(
      VirtualMeetingRoomDialog
    )
  )
);
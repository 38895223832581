import React from 'react';
import * as d3 from 'd3';

import { format } from 'kn-react';
import { HorizontalPercentBar, isDevelopment, isStaging, isDemo } from 'go-boost-library-react';

import { Tooltip, Avatar, Grid, ListItemAvatar, ListItemText, ListItem, withStyles } from '@material-ui/core';
import { Public } from '@material-ui/icons';


const softwareUsageTableContent = props => {
  const getSoftwareIcon = (s) => {
    let baseUrl;
    if(isDevelopment() || isStaging() || isDemo()){
      baseUrl='https://hello-staging.goboost.io';
    }else {
      baseUrl='https://hello.goboost.io';
    }

    return (
      (!Boolean(s.logo) || s.logo == '0' || s.logo.toLowerCase().endsWith('unk.png')) ?
        <Public/>
      :
        <Avatar
          src={baseUrl + '/' + s.logo}
          classes={{ root: props.classes.avatarRoot, img: props.classes.avatarImage }}
        />
    )
  }

  const softwareStats = [...props.softwareStats];
  softwareStats.sort((a,b) => d3.descending(a.nbVisits, b.nbVisits));

  const totalVisits = softwareStats.map(
    s => parseInt(s.nbVisits)
  ).reduce(
    (a,b) => a+b, 0
  );

  return (
    <Grid container>
      {
        softwareStats.map(s => (
          (!s.nbVisits) ?
            null
          :
            <Grid container key={s.logo}>
              <Grid item xs={6}>
                <ListItem dense>
                  <ListItemAvatar>
                    <Tooltip title={s.label}>
                      { getSoftwareIcon(s) }
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText
                    primary={ s.label || 'Unknown' }
                    secondary={format.commas(s.nbVisits || 0) + ' Visits'}
                  />
                </ListItem>
              </Grid>


              <Grid item xs={6}>
                <div className={props.classes.barWrapper}>
                  <HorizontalPercentBar
                    backgroundColor='#fff'
                    color={ '#41c8ac' }
                    widthPercent={ (parseInt(s.nbVisits) || 0) / totalVisits }
                    displayPercent={ (parseInt(s.nbVisits) || 0) / totalVisits }
                  />
                </div>
              </Grid>

            </Grid>
        ))
      }
    </Grid>
  )
}

softwareUsageTableContent.defaultProps = {
  softwareStats: []
}


const styles = theme => ({
  avatarRoot: {
    backgroundColor: 'transparent'
  },
  avatarImage: {
    // Matomo icons are too big for the avatar - so size the down.
    width: '75%',
    height: '75%',
  },
  barWrapper: {
    marginTop: 21,
    overflow: 'hidden'
  }
})


export default withStyles(styles)(softwareUsageTableContent);
import React from 'react';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import { VirtualFormDesignContext } from 'Virtual/CompanyVirtual/VirtualFormDesigns/VirtualFormDesignProvider';
import history from 'history.js';
import Opportunity from 'Opportunity/Opportunity';


const companyJob = props => {
  const fetchCompanyVirtualFormDesign = opportunity => props.companyVirtualFormDesigns.find(
    c => c.id === opportunity.opportunitySourceId
  );

  const fetchSteps = opportunity => {
    const companyVirtualFormDesign = fetchCompanyVirtualFormDesign(
      opportunity
    );

    return companyVirtualFormDesign ? companyVirtualFormDesign.steps : [];
  };

  const fetchMediaSteps = opportunity => {
    const companyVirtualFormDesign = fetchCompanyVirtualFormDesign(
      opportunity
    );

    return companyVirtualFormDesign ? companyVirtualFormDesign.mediaSteps : [];
  };


  return (
    <Opportunity
      opportunityId={props.match.params.id}
      defaultAddress={`${ props.company.addressLineOne }, ${ props.company.city }, ${ props.company.state } ${ props.company.postalCode }`}
      fetchSteps={fetchSteps}
      fetchMediaSteps={fetchMediaSteps}
      canEdit={props.companyVirtualProfile && !props.companyVirtualProfile.isShutdown}
      meetWithText={'Invite this customer to meet with you in one of your online Virtual Meeting Rooms.'}
      onClickContactGetStarted={() => history.push('/virtual/settings')}
      showExternalAssetConnection={true}
    />
  )
}


export default withContext(
  CompanyVirtualProfileContext,
  VirtualFormDesignContext,
  CompanyRoleContext,
  companyJob
)
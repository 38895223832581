import React from 'react';

import { withStyles } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import Preview from './Preview';

import PostLoader from './PostLoader';
import Engagement from './Engagement';



class LinkStatusFacebookPost extends React.Component {
  state={
    loading: false,
    meta: null
  }

  // componentDidMount = () => {
  //   if( this.props.text ){
  //     this.setState(
  //       { loading: true },
  //       this.processGraphScraper
  //     );
  //   }
  // }

  // componentDidUpdate = oldProps => {
  //   const { text, url } = this.props;

  //   if( text !== oldProps.text || url !== oldProps.url ){
  //     this.setState(
  //       { loading: true },
  //       this.processGraphScraper
  //     );
  //   }
  // }

  cleanText = text => (
    text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "")
  )

  render() {
    const { classes } = this.props;

    if (this.props.loading) {
      return (
        <div style={{ padding: 10 }} className={`fade-out ${classes.container}`}>
          <PostLoader />
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <AccountCircle
            className={classes.avatar}
          />

          <div className={classes.userInfo}>
            <span className={classes.username}>John Smith</span>
            <span className={classes.timestamp}>Just now - Pittsburgh, Pennsylvania</span>
          </div>
        </div>

        <div>
          <div className={classes.post}>
            { this.props.text || '' }
          </div>

          {
            this.props.meta && this.props.feedType === 'Link' ?
              <Preview { ...this.props.meta } />
            :
              null
          }

        </div>

        <Engagement
          stats={this.props.stats}
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    maxWidth: '375px',
    display: 'block',
    width: '100%',
    boxShadow: '0px 9px 12px rgba(0, 0, 0, 0.06)',
    background: '#fff',
    textAlign: 'left',
    border: '1px solid #dddfe2',
    borderRadius: 3
  },
  header: {
    display: 'flex',
    padding: '12px',
  },
  avatar: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  },
  userInfo: {
    flexGrow: '1',
    paddingLeft: '8px',
  },
  username: {
    display: 'inline-block',
    width: '100%',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontSize: '14px',
    color: '#385898',
  },
  timestamp: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '12px',
    color: '#616770',
  },
  post: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '13px',
    color: '#141823',
    display: 'block',
    padding: '0 12px 12px 12px',
  },
  footer: {
    borderTop: '0.5px solid #dcdee3',
  },
  likebar: {
    display: 'flex',
    margin: '0 20px',
    padding: '10px 0',
    borderBottom: '0.5px solid #dcdee3',
  },
  likebarImg: {
    width: '16px',
    height: '16px',
  },
  likebarSpan: {
    alignSelf: 'center',
    color: '#616770',
    fontSize: '13px',
    paddingLeft: '10px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
  },
  action: {
    flex: '1',
    alignSelf: 'center',
  },
  actionImg: {
    width: '16px',
    height: '15px',
    alignSelf: 'center',
  },
  actionDiv: {
    color: '#616770',
    fontWeight: 'bold',
    fontSize: '13px',
    paddingLeft: '10px',
    display: 'inline-block',
  },
})


LinkStatusFacebookPost.defaultProps = {
  text: '',
  meta: {},
  feedType: ''
}


export default withStyles(styles)(LinkStatusFacebookPost);
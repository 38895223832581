import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { UserRoleGuard } from 'go-boost-library-react';

import AppNavigation from '../../AppNavigation/AppNavigation';
import NewOrganization from './NewOrganization/NewOrganization';
import NewChildOrganization from './NewChildOrganization/NewChildOrganization';
import OrganizationIndex from './OrganizationIndex/OrganizationIndex';
import OrganizationDetails from './OrganizationDetails/OrganizationDetails';
import NewCompany from '../Companies/NewCompany/NewCompany';

class Organizations extends React.Component {

  render(){
    return (
      <UserRoleGuard authorizeRole={role => role.isOrganizationRole()}>
        <AppNavigation title="Organizations">
          <Switch>
            <Route exact path="/organizations" component={ OrganizationIndex }/>
            <Route exact path="/organizations/:id/new" component={ NewOrganization }/>
            <Route exact path="/organizations/:id/children/new" component={ NewChildOrganization }/>
            <Route exact path="/organizations/:id/companies/new" component={ NewCompany }/>
            <Route path="/organizations/:id" component={ OrganizationDetails }/>
          </Switch>
        </AppNavigation>
      </UserRoleGuard>
    );
  }

}

export default Organizations;




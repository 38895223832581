import React from 'react';
import { log } from 'kn-react';
import HelpIcon from '@material-ui/icons/Help';
import HeadsetMic from '@material-ui/icons/HeadsetMic';
import supportPortalLink from './supportPortalLink';


export const SUPPORT_PORTAL_KEY = 'SupportPortal';

export default [
  {
    primaryText: 'Live Chat',
    icon: <HeadsetMic />,
    onClick: () => {
      const { fcWidget } = window;
      if (!fcWidget) {
        log('Cannot find fcWidget!');
        return;
      }

      fcWidget.open();
      fcWidget.show();
    },
    tooltipTitle: 'Live Chat'
  },
  {
    primaryText: 'Support Portal',
    icon: <HelpIcon />,
    onClick: () => window.open(`${ supportPortalLink }`, '_blank'),
    tooltipTitle: 'Support Portal',
    key: SUPPORT_PORTAL_KEY
  }
];

import React from 'react';
import Axios from 'axios';
import Moment from 'moment';
import { Redirect } from 'react-router-dom';

import { withContext, log } from 'kn-react';
import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';

import { LinearProgress } from '@material-ui/core';

import FeedItem from './FeedItem';

import FeedItemModel from 'Social/FeedItem/FeedItemModel';


class EditFeedItem extends React.Component {
  state = {
    feedItem: {},
    isPosted: false, // TODO @alex... fix this and clean up
    loadingFeedItem: true,
    loadingMeta: false
  }


  componentDidMount = () => {
    this.setState(
      { loadingFeedItem: true },
      this.fetchFeedItem
    )
  }


  fetchFeedItem = () => {
    return Axios.get(
      `/api/social/feed_items/${this.props.match.params.feedItemId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const feedItem = FeedItemModel.fromJSON( response.data.feed_item.feed_content );

      if(
        response.data.feed_item.base_feed_item_id &&
        Moment( response.data.feed_item.updated_at ).isBefore( response.data.base_feed_item.updated_at )
      ){
        this.props.showSnackbar('There is a newer version available for this feed item. Delete this Feed Item to update to the newer version.', { autoHide: false })
      }

      feedItem.postAt = Moment.utc( feedItem.postAt.toString() ).local();

      return this.setState({
        feedItem,
        loadingFeedItem: false,
        isPosted: response.data.feed_item.is_posted
      });
    });
  }


  onProcess = feedItem => {
    feedItem.postAt = Moment.utc(feedItem.postAt).format('YYYY-MM-DDTHH:mm:ss');

    return Axios.put(
      `/api/social/feed_items/${this.props.match.params.feedItemId}`,
      {
        feed_item: {
          ...feedItem
        },
        post_at: feedItem.postAt
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(this.fetchFeedItem).then(response => {
      this.props.showSnackbar('Feed Item successfully updated.');
    }).catch(error => {

    })
  }


  acknowledgeNewerFeedItem = () => {
    this.setState({ acknowledgedNewerFeedItem: true })
  }



  render() {
    if( this.state.loadingFeedItem ){
      return <LinearProgress />
    } else if ( this.state.isPosted ){
      return <Redirect to={`/social/feed_items/${ this.state.feedItem.id }/view`} />
    }


    return(
      <FeedItem
        feedItem={this.state.feedItem}
        actionText={'Edit'}
        feedTypeDisabled={true}
        onProcess={this.onProcess}
      />
    )
  }
}


export default withContext(
  UserRoleContext,
  SnackbarContext,
  EditFeedItem
)
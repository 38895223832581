import React from 'react';
import Axios from 'axios';
import { withContext } from 'kn-react';
import { Switch, Route } from 'react-router-dom';
import { SubNavigation, ReviewsStarIcon, UserRoleContext } from 'go-boost-library-react';

import DashboardIcon from '@material-ui/icons/Dashboard';

import { VirtualFormDesignContext } from './VirtualFormDesignProvider';
import VirtualFormDesigns from './VirtualFormDesigns';
import CompanyVirtualFormDesign from './CompanyVirtualFormDesign';


class VirtualFormsIndex extends React.Component {
  render(){
    return (
      <Switch>
        <Route exact path="/virtual/widget_and_forms/company_virtual_form_designs/:id" component={ CompanyVirtualFormDesign }/>
        <Route exact path="/virtual/widget_and_forms" component={ VirtualFormDesigns }/>
      </Switch>
    );
  }

}

export default withContext(
  VirtualFormDesignContext,
  UserRoleContext,
  VirtualFormsIndex
);
import React from 'react';
import { CircleMarker, Tooltip } from 'react-leaflet';
import * as d3 from 'd3';
import Axios from 'axios';
import L from 'leaflet';

import { ServicesColors, MegaphoneIcon, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log, format } from 'kn-react';

import { Typography, withStyles } from '@material-ui/core';

import ProductSummaryMap from '../ProductSummaryMap/ProductSummaryMap';

class AdsSummaryMap extends React.Component {

  state = {
    stats: null,
    companyDistribution: [],
    loading: true,
    totalViews: 0,
    companyCostAverage: 0,
  }

  componentDidMount = () => {
    this.fetchCompanyAdsProfilesStats()
      .then(this.fetchAdsMapData)
  }


  fetchCompanyAdsProfilesStats = () => {
    log('fetchCompanyAdsProfilesStats');

    return Axios.get(
      `/api/ads/company_ads_profiles/organizations/${this.props.organization.id}/stats`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchCompanyAdsProfilesStats response', response);
        this.setState({ stats: response.data.stats, loading: false });
      })
  }


  fetchAdsMapData = () => {
    log('fetchAdsMapData');

    return Axios.get(
      `/api/ads/aggregate_ads_reports/organizations/${this.props.organization.id}/company_distribution`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchAdsMapData response', response);

        let companyCostAverage = 0;

        const totalViews = response.data.company_distribution.map(d => {
          companyCostAverage += d.cost_cents;
          return d.views;
        }).reduce((a, b) => (a + b), 0);

        companyCostAverage /= response.data.company_distribution.length;


        const maxViews = d3.max(response.data.company_distribution, d => d.views);
        this.radiusScale = d3.scaleSqrt().domain([0, maxViews]).range([2.5, 12]);

        this.setState({
          companyDistribution: response.data.company_distribution,
          companyCostAverage,
          totalViews
        });
      })
  }


  render() {
    const { companyDistribution, loading, stats } = this.state;

    if (loading) {
      return null;
    }

    const companiesWithPosition = companyDistribution.filter(c => c.latitude && c.longitude);

    let bounds;
    if (companiesWithPosition.length) {
      bounds = new L.LatLngBounds(companiesWithPosition.map(c => [c.latitude, c.longitude]));
    }

    const darkerColor = d3.rgb(ServicesColors.ads.primary).darker(0.2);

    return (
      <ProductSummaryMap
        title="Ads"
        titleLink="/ads"
        icon={MegaphoneIcon}
        iconColor={ServicesColors.ads.primary}
        bounds={bounds}
        enrolledCount={stats.is_setup_complete_count}
        incompleteCount={stats.count - stats.is_setup_complete_count}
        metric1Title={format.commas(this.state.totalViews)}
        metric1Caption="Total Views"
        metric2Title={format.money(this.state.companyCostAverage / 100)}
        metric2Caption="Avg. Company Spend"
      >
        {
          companiesWithPosition.map(c => {
            return (
              <CircleMarker
                key={c.company_id}
                center={[c.latitude, c.longitude]}
                radius={this.radiusScale(c.views)}
                weight={1}
                opacity={0.9}
                color={darkerColor}
                fillOpacity={0.6}
                fillColor={ServicesColors.ads.primary}
              >
                <Tooltip>
                  <Typography variant="body2">{c.name}</Typography>
                  <Typography variant="caption">
                    {format.commas(c.views)} Total Views
                    <br/>
                    {format.money(c.cost_cents / 100)} Spend
                  </Typography>
                </Tooltip>
              </CircleMarker>
            );
          })
        }
      </ProductSummaryMap>
    )
  }
}


const styles = {};

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    AdsSummaryMap
  )
);
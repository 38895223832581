import React from 'react';
import Axios from 'axios';

import { MaxWidth, log, formHelpers, AutoError, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, OrganizationRoleContext } from 'go-boost-library-react';

import { Typography, withStyles, MenuItem, TextField, Grid } from '@material-ui/core';

import history from 'history.js';
import OrganizationAutosuggest from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/OrganizationAutosuggest/OrganizationAutosuggest';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';


class NewOrganization extends React.Component {
  state = {
    organizationName: '',
    organizationId: '',
    possibleOrganizationChildTypes: [],
    organizationChildTypeId: '',
    newOrganizationChildTypeName: '',
  }



  componentDidMount = () => {
    this.getOrganizationChildTypes();
  }


  getOrganizationChildTypes = () => {
    log('getOrganizationChildTypes');
    return Axios.get(
      `/api/core/organizations/${this.props.match.params.id}/child_types`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
        log('getOrganizationChildTypes response', response);
        this.setState({ possibleOrganizationChildTypes: response.data.child_types });
      });
  }


  onOrganizationSuggestionSelected = organization => {
    return this.setState(
      {
        organizationId: organization.id,
        organizationName: organization.name
      }
    );
  }


  createChildOrganization = () => {
    const { organizationChildTypeId } = this.state;

    return Axios.put(
      `/api/core/organization_child/organizations/${ this.props.match.params.id }`,
      {
        child_organization_id: this.state.organizationId,
        organization_child_type_id: organizationChildTypeId === 'new' ? undefined : organizationChildTypeId,
        new_organization_child_type_name: organizationChildTypeId === 'new' ? this.state.newOrganizationChildTypeName : undefined,
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('onCreateOrganization response', response);
      history.push(`/organizations/${response.data.organization_child.parent_organization_id}`)
    });
  }


  onProcess = () => {
    return this.validate()
      .then(this.createChildOrganization)
      .catch(AutoError.catch.bind(this));;
  }

  onValidationError = error => {
    log(error.message)
    this.props.showSnackbar(error.message);
  }

  onError = error => {
    log(error.response)
    this.props.showSnackbar(error.response.data.message);
  }

  validate = () => {
    const baseValidations = {
      organizationId: {
        presence: { message: 'Please select an organization.' }
      }
    };

    const { organizationChildTypeId } = this.state;

    if (organizationChildTypeId === 'new') {
      baseValidations['newOrganizationChildTypeName'] = {
        presence: { message: 'Please specify the new type for this organization.' }
      };
    } else {
      baseValidations['organizationChildTypeId'] = {
        presence: { message: 'Please enter the type of the organization.' }
      };
    }

    return formHelpers.validate(this.state, baseValidations);
  }



  render() {
    const { classes } = this.props;
    const { organizationChildTypeId } = this.state;

    return (
      <MaxWidth maxWidth={400}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <KnowledgeBaseLaunchBanner />

            <Grid item xs={12}>
              <Typography variant="h5">Add a new suborganization</Typography>
              <Typography variant="caption">Please enter the following information to add a new suborganization.</Typography>
            </Grid>

            <Grid item xs={12}>
              <OrganizationAutosuggest
                organizationName={this.state.organizationName}
                onChangeInputValue={organizationName => this.setState({ organizationName })}
                onSuggestionSelected={this.onOrganizationSuggestionSelected}
                organizationId={this.props.currentUserRole.roleTypeId}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Organization Type"
                value={organizationChildTypeId}
                onChange={e => this.setState({ organizationChildTypeId: e.target.value })}
              >
                {
                  this.state.possibleOrganizationChildTypes.map(t => (
                    <MenuItem key={t.id} value={t.id}>
                      {t.name}
                    </MenuItem>
                  ))
                }
                <MenuItem
                  key="newOrganizationType"
                  value="new"
                >
                  New Organization Type
                </MenuItem>
              </TextField>

              {
                organizationChildTypeId === 'new' ?
                  <TextField
                    fullWidth
                    label="New Organization Type"
                    value={this.state.newOrganizationChildTypeName}
                    onChange={e => this.setState({ newOrganizationChildTypeName: e.target.value })}
                    margin="normal"
                  />
                  : null
              }
            </Grid>

            <Grid item xs={12}>
              <PromiseButton onProcess={this.onProcess}>
                Create
              </PromiseButton>
            </Grid>
          </Grid>
        </div>
      </MaxWidth>
    );
  }

}

const styles = theme => ({
  root: {
    padding: 16
  }
});

export default withContext(
  OrganizationRoleContext,
  SnackbarContext,
  withStyles(styles)(NewOrganization)
);
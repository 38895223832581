import React from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';

export default props => {
  return (
    <ListItem>
      <ListItemText
        primary={ props.leftPrimary }
        secondary={ props.leftSecondary }
      />
      <ListItemSecondaryAction>
        <ListItemText
          style={{ textAlign: 'right' }}
          primary={ props.rightPrimary}
          secondary={ props.rightSecondary}
          secondaryTypographyProps={ props.rightSecondaryTypographyProps || {} }
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

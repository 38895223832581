import React from 'react';

import { withContext, log } from 'kn-react';
import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';

import { BoostedServicesContext } from 'Ads/CompanyAds/BoostedServices/BoostedServicesProvider';
import { CompanyAdsProfileContext } from '../CompanyAdsProfile/CompanyAdsProfileProvider';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { UNITED_STATES } from 'SignUp/SignUp';
import AdsPlanSetup from './AdsPlanSetup/AdsPlanSetup';
import AdsSettingsSetup from './AdsSettingsSetup/AdsSettingsSetup';
import AdsSetupCompletion from './AdsSetupCompletion/AdsSetupCompletion';
import AdsUndoResubscriptionAlert from 'Ads/CompanyAds/AdsUndoResubscriptionAlert/AdsUndoResubscriptionAlert';
import BoostStrategySetup from '../BoostedServices/BoostedServicesSetup/BoostStrategySetup/BoostStrategySetup';
import BoostZoneSetup from '../BoostedServices/BoostedServicesSetup/BoostZoneSetup/BoostZoneSetup';
import CheckoutSetup from './CheckoutSetup/CheckoutSetup';
import ConfigureBoostedServices from './ConfigureBoostedServices/ConfigureBoostedServices';
import FacebookAccountSetup from 'SharedSetup/FacebookAccountSetup';
import ServiceAreaSetup from 'SharedSetup/ServiceAreaSetup/ServiceAreaSetup';
import SurveySetup from '../BoostedServices/BoostedServicesSetup/SurveySetup/SurveySetup';
import TransitionProvider from 'TransitionProvider/TransitionProvider';



class AdsSetupProcess extends React.Component {
  componentDidMount = () => {
    if (!this.props.companyAdsProfile) {
      this.props.createCompanyAdsProfile();
    }
  }

  render() {
    return (
      this.props.companyAdsProfile ?
        <WrappedTransitionProvider initialStatePath="/ads/setup" machineStates={machineStates} />
      :
        null
    );
  }
}

export default withContext(CompanyAdsProfileContext, AdsSetupProcess);



// 1) Wrap the Transition Provider with the Providers it will need to evaluate transitions
const WrappedTransitionProvider = withContext(
  PlanContext,
  CompanyRoleContext,
  CompanyAdsProfileContext,
  BoostedServicesContext,
  TransitionProvider
);



const sharedState = {
  selectedPlanId: null
};

const guardSelectedPlanId = props => (
  !sharedState.selectedPlanId ? '/ads/setup/plans' : null
);


// 2) Define the steps of the process, each with the component, a path, optionally `exact` for the path, and a transitions object. At minimum, the transitions object should define a 'next' key, which is a function of props that returns a string path or object of the form `{ path: String, params: Object?, query: Object? } `
const machineStates = [
  {
    path: '/ads/setup/plans',
    component: props => <AdsPlanSetup {...props} selectedPlanId={sharedState.selectedPlanId}/>,
    guard: props => {
      log('sharedState', sharedState);
      log('AdsPlanSetup guard, plans', props.upgradableAdsPlans);

      if( !props.isAdsAvailable ){
        return '/';
      } else if( props.companyAdsProfile.isSetupComplete ){
        return '/ads';
      } else if( props.company.country !== UNITED_STATES ){
        return '/ads/country_unavailable';
      }

      trackCurrentUserSegmentEvent(
        props.currentUserRole.userId,
        'started_ads_setup',
        props.getUserRoleAuthHeaders,
        { funnel: true }
      );

      if (props.upgradableAdsPlans.length === 1) {
        sharedState.selectedPlanId = sharedState.selectedPlanId || props.upgradableAdsPlans[0].id;
        return '/ads/setup/ads_settings';
      }

      return null;
    },
    transitions: {
      next: props => {
        sharedState.selectedPlanId = props.selectedPlanId;
        return '/ads/setup/ads_settings';
      },
    }
  },
  {
    path: '/ads/setup/ads_settings',
    component: props => <AdsSettingsSetup {...props} showBack={props.upgradableAdsPlans.length > 1} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/ads/setup/plans',
      next: '/ads/setup/service_area',
    }
  },
  {
    path: '/ads/setup/service_area',
    component: props => <ServiceAreaSetup {...props} productUndoResubscriptionAlert={AdsUndoResubscriptionAlert} />,
    guard: props => (
      guardSelectedPlanId(props)
      ||
      (!props.companyAdsProfile.companyShortName ? '/ads/setup/ads_settings' : null)
    ),
    transitions: {
      back: '/ads/setup/ads_settings',
      next: '/ads/setup/boosted_services'
    }
  },

  {
    path: '/ads/setup/boosted_services',
    component: props => <ConfigureBoostedServices {...props} selectedPlanId={sharedState.selectedPlanId}/>,
    exact: true,
    guard: props => (
      guardSelectedPlanId(props)
      ||
      (!props.company.serviceArea.includedGeographies.length ? '/ads/setup/service_area' : null)
    ),
    transitions: {
      back: '/ads/setup/service_area',
      next: '/ads/setup/checkout',
      setup: props => ({
        path: (
          isFacebookBoostedService(props) ?
            '/ads/setup/boosted_services/:id/facebook_account_setup'
            :
            '/ads/setup/boosted_services/:id/survey'
        ),
        params: { id: props.boostedServiceId }
      }),
      editSurvey: props => ({
        path: (
          isFacebookBoostedService(props) ?
            '/ads/setup/boosted_services/:id/facebook_account_setup'
            :
            '/ads/setup/boosted_services/:id/survey'
        ),
        params: { id: props.boostedServiceId }
      }),
      editBoostZone: props => ({
        path: '/ads/setup/boosted_services/:id/boost_zone',
        params: { id: props.boostedServiceId }
      }),
      editBoostStrategy: props => ({
        path: '/ads/setup/boosted_services/:id/boost_strategy',
        params: { id: props.boostedServiceId }
      })
    }
  },


  // Nested Boosted Services Setup route
  {
    path: '/ads/setup/boosted_services/:id/facebook_account_setup',
    component: props => (
      <FacebookAccountSetup
        { ...props }
        shouldEnqueueReviewsImporter={false}
        shouldConnectBusinessManager={true}
        linkAccountText="Link your Facebook account to run ads on Facebook."
        boostedServiceId={props.boostedServices.find(b => b.id == props.match.params.id).id}
        productUndoResubscriptionAlert={AdsUndoResubscriptionAlert}
        knowledgeBasePath={'/connecting_to_facebook_through_goboost_ads'}
        knowledgeBaseTooltipTitle={'Learn about connecting Facebook to your Ads'}
      />
    ),
    guard: props => (
      guardSelectedPlanId(props)
        ||
        !isFacebookBoostedService(props) ? 'continue' : null
    ),
    transitions: {
      back: '/ads/setup/boosted_services',
      next: props => ({
        path: '/ads/setup/boosted_services/:id/survey',
        params: { id: props.match.params.id }
      }),
    }
  },

  {
    component: SurveySetup,
    path: '/ads/setup/boosted_services/:id/survey',
    guard: (props, transitionKey) => (
      guardSelectedPlanId(props)
        ||
      hasSurvey(props) ? null : { continue: transitionKey === 'setup' ? 'next' : transitionKey }
    ),
    transitions: {
      back: props => ({
        path: '/ads/setup/boosted_services/:id/facebook_account_setup',
        params: { id: props.boostedServiceId }
      }),
      next: props => (
        !isConfigured(props) ?
          {
            path: '/ads/setup/boosted_services/:id/boost_zone',
            params: { id: props.boostedServiceId }
          }
          :
          '/ads/setup/boosted_services'
      )
    }
  },

  {
    component: BoostZoneSetup,
    path: '/ads/setup/boosted_services/:id/boost_zone',
    guard: guardSelectedPlanId,
    transitions: {
      back: props => (
        !isConfigured(props) ?
          {
            path: '/ads/setup/boosted_services/:id/survey',
            params: { id: props.boostedServiceId }
          }
          :
          '/ads/setup/boosted_services'
      ),
      next: props => (
        !isConfigured(props) ?
          {
            path: '/ads/setup/boosted_services/:id/boost_strategy',
            params: { id: props.boostedServiceId }
          }
          :
          '/ads/setup/boosted_services'
      )
    }
  },
  {
    component: props => <BoostStrategySetup {...props} selectedPlanId={sharedState.selectedPlanId} />,
    path: '/ads/setup/boosted_services/:id/boost_strategy',
    guard: guardSelectedPlanId,
    transitions: {
      back: props => (
        !isConfigured(props) ?
          {
            path: '/ads/setup/boosted_services/:id/boost_zone',
            params: { id: props.boostedServiceId }
          }
          :
          '/ads/setup/boosted_services'
      ),
      next: '/ads/setup/boosted_services'
    }
  },
  {
    component: props => <CheckoutSetup {...props} selectedPlanId={sharedState.selectedPlanId} />,
    path: '/ads/setup/checkout',
    guard: guardSelectedPlanId,
    transitions: {
      back: '/ads/setup/boosted_services',
      next: '/ads/setup/done'
    }
  },
  {
    component: props => <AdsSetupCompletion {...props} />,
    path: '/ads/setup/done',
    guard: guardSelectedPlanId,
    transitions: {
      back: '/ads',
      next: '/ads'
    }
  },

];


const isConfigured = props => {
  return getCompanyBoostedService(props).isConfigured();
}

const isFacebookBoostedService = props => {
  return getBoostedService(props).isFacebookChannel(props.channels);
}


const hasSurvey = props => {
  return getBoostedService(props).surveyId;
}

const getCompanyBoostedService = props => {
  return props.companyBoostedServices.find(c => c.boostedServiceId == props.boostedServiceId);
}


const getBoostedService = props => {
  return props.boostedServices.find(b => b.id == props.boostedServiceId);
}


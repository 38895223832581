import React from 'react';

import { SnackbarContext, UserRoleContext, Ability, CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Grid, Paper, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import ReviewRequestSettingsCardContent from './ReviewRequestSettingsCardContent';


class ReviewRequestSettingsCard extends React.Component {



  render() {
    const { classes, currentUserRole } = this.props;

    const isShutdown = this.props.companyReviewsProfile && this.props.companyReviewsProfile.isShutdown;

    return (
      <Paper className={classes.paperRoot}>
        <ReviewRequestSettingsCardContent>
          {
            props => (
              (!isShutdown && currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_REVIEWS )) ?
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.onProcess()}
                    id='update-settings-button'
                    name='update-settings-button'
                  >
                    Update
                  </Button>
                </Grid>
              :
                null
            )
        }
        </ReviewRequestSettingsCardContent>
      </Paper>
    );
  }

}

const styles = theme => ({
  paperRoot: {
    padding: 16
  }
});



export default withStyles(styles)(
  withContext(
    UserRoleContext,
    CompanyRoleContext,
    SnackbarContext,
    CompanyReviewsProfileContext,
    ReviewRequestSettingsCard
  )
);
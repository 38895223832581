import React from 'react';
import UserInvitationTable from '../../UserInvitationTable/UserInvitationTable';


class CompanyInvitationCard extends React.Component {

  render(){
    const { company } = this.props;

    return (
      <UserInvitationTable
        newInvitationPath={this.props.newInvitationPath}
        roleType="Company"
        roleTypeId={company.id}
        roleTypePath="companies"
        roleTypeName={company.name || ''}
        showDescendantsInfo = { false }
      />
    );
  }

}

export default CompanyInvitationCard;
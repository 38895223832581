import React from 'react';
import * as d3 from 'd3';

import { KnPieChart, OpportunityModel } from 'go-boost-library-react';
import { log } from 'kn-react';

import { withStyles, LinearProgress } from '@material-ui/core';

import { getTimePeriodMoment, dashboardDataRequest, getStartDate } from 'SharedDashboard/sharedDashboard';

class OpportunityTypePieChart extends React.Component {

  state = {
    opportunitiesLoading: true,
    opportunitiesRefreshing: true,
    opportunityTypeDistribution: [],
    totalOpportunities: 0,
  }


  componentDidMount = () => {
    this.fetchOpportunityTypeDistribution();
  }


  componentDidUpdate = oldProps => {
    if(this.props !== oldProps){
      this.setState(
        { opportunitiesRefreshing: true },
        this.fetchOpportunityTypeDistribution
      );
    }
  }

  fetchOpportunityTypeDistribution = () => {
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return dashboardDataRequest(
      '/core/opportunities',
      '/opportunity_type_distribution',
      this.props,
      {
        start_date: getStartDate(timePeriodMoment),
        opportunity_types: ['call','form_entry'],
        channel_ids: this.props.channelIds,
        is_spam: false,
        company_ids: this.props.companyIds || undefined
      }
    )
    .then(response => {
      log('fetchOpportunityTypeDistribution response', response);

      const opportunityTypeDistribution = response.data.opportunity_type_distribution.map(d => ({
        count: d.count,
        opportunityType: d.opportunity_type
      }));

      const totalOpportunities = d3.sum(opportunityTypeDistribution, d => d.count)

      this.setState({
        opportunityTypeDistribution,
        totalOpportunities,
        opportunitiesLoading: false,
        opportunitiesRefreshing: false,
      });
    })
  };

  render(){
    const OPPORTUNITY_DATA_COLORS =  [
      this.props.theme.palette.primary.main,
      this.props.theme.palette.secondary.main
    ];

    const opportunitiesPieData = this.state.opportunityTypeDistribution.map(d => ({
      name: OpportunityModel.opportunityTypeDisplayName[d.opportunityType],
      value: d.count,
    }));

    if(this.state.opportunitiesLoading){
      return(<LinearProgress/>)
    }

    return (
      <div
        style={{
          opacity: this.state.opportunitiesRefreshing ? 0.5 : 1,
          marginLeft: 'auto',
          marginRight: 'auto',
          width: 300
        }}
      >
        <KnPieChart
          data={opportunitiesPieData}
          colors={OPPORTUNITY_DATA_COLORS}
          width={300}
          height={300}
          innerRadius={75}
          total={this.state.totalOpportunities}
          centerLabel='Opportunities'
        />
      </div>
    );
  }

}


OpportunityTypePieChart.defaultProps = {
  timePeriod: {},
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  companyIds: [],
  channelIds: []
};


const styles = theme => ({

})

export default withStyles(
  styles,
  { withTheme: true }
)( OpportunityTypePieChart )
import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, withProvider } from 'kn-react';

import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import CheckoutSetup from './CheckoutSetup/CheckoutSetup';
import TransitionProvider from 'TransitionProvider/TransitionProvider';
import VirtualFormDesignSetup from './VirtualFormDesignSetup/VirtualFormDesignSetup';
import VirtualFormDesignSetupProvider, { VirtualFormDesignSetupContext } from './VirtualFormDesignSetup/VirtualFormDesignSetupProvider';
import VirtualPlanSetup from './VirtualPlanSetup/VirtualPlanSetup';
import VirtualSetupCompletion from './VirtualSetupCompletion/VirtualSetupCompletion';

class VirtualSetupProcess extends React.Component {
  componentDidMount = () => {
    if(!this.props.companyVirtualProfile) {
      this.props.createCompanyVirtualProfile();
    }
  }

  render() {
    return (
      this.props.companyVirtualProfile ?
        <WrappedTransitionProvider initialStatePath="/virtual/setup" machineStates={machineStates} />
      :
        null
    );
  }
}


export default withProvider(
  VirtualFormDesignSetupProvider,
  withContext(
    CompanyVirtualProfileContext,
    VirtualFormDesignSetupContext,
    VirtualSetupProcess
  )
);


// 1) Wrap the Transition Provider with the Providers it will need to evaluate transitions
const WrappedTransitionProvider = withContext(
  VirtualFormDesignSetupContext,
  CompanyVirtualProfileContext,
  PlanContext,
  CompanyRoleContext,
  TransitionProvider
);

// 2) Define the steps of the process, each with the component, a path, optionally `exact` for the path, and a transitions object. At minimum, the transitions object should define a 'next' key, which is a function of props that returns an object of the form `{ path: String, params: Object?, query: Object? } `
const sharedState = {
  selectedPlanId: null,
  selectedVirtualFormDesignIds: []
};


const guardSelectedPlanId = props => (
  !sharedState.selectedPlanId ? '/virtual/setup/plans' : null
);


const machineStates = [
  // Select a Plan
  {
    component: props => <VirtualPlanSetup {...props} selectedPlanId={sharedState.selectedPlanId}/>,
    path: '/virtual/setup/plans',
    guard: props => {
      if( !props.isVirtualAvailable ){
        return '/'
      }else if( props.companyVirtualProfile.isSetupComplete ){
        return '/virtual';
      }

      if (props.upgradableVirtualPlans.length === 1) {
        sharedState.selectedPlanId = sharedState.selectedPlanId || props.upgradableVirtualPlans[0].id;
        // return '/virtual/setup/form_designs';
        return {
          continue: 'next',
          props: { selectedPlanId: sharedState.selectedPlanId || props.upgradableVirtualPlans[0].id }
        }
      }

      return null;
    },
    transitions: {
      next: props => {
        sharedState.selectedPlanId = props.selectedPlanId;
        return '/virtual/setup/form_designs';
      }
    }
  },
  // Select Form Designs
  {
    path: '/virtual/setup/form_designs',
    component: props => <VirtualFormDesignSetup selectedPlanId={sharedState.selectedPlanId} selectedVirtualFormDesignIds={sharedState.selectedVirtualFormDesignIds} {...props} showBack={props.upgradableVirtualPlans.length > 1} />,
    guard: (props, transitionKey) => {
      const guardedPath = guardSelectedPlanId(props);
      if( guardedPath ){
        return guardedPath;
      }


      const virtualFormDesignIds = props.planVirtualFormDesigns.filter(
        p => p.planId === sharedState.selectedPlanId
      ).map(p => p.virtualFormDesignId);

      const virtualFormDesigns = props.virtualFormDesigns.filter(
        v => virtualFormDesignIds.includes( v.id )
      );

      if( virtualFormDesigns && virtualFormDesigns.length === 1 ){
        sharedState.selectedVirtualFormDesignIds = [ virtualFormDesigns[ 0 ].id ];
        return {
          continue: transitionKey,
          props: { selectedVirtualFormDesignIds: sharedState.selectedVirtualFormDesignIds }
        }
      }

      return null;
    },
    transitions: {
      back: '/virtual/setup/plans',
      next: props => {
        sharedState.selectedVirtualFormDesignIds = props.selectedVirtualFormDesignIds;
        return '/virtual/setup/checkout';
      }
    }
  },
  // Checkout
  {
    path: '/virtual/setup/checkout',
    component: props => <CheckoutSetup {...props} selectedPlanId={sharedState.selectedPlanId} selectedVirtualFormDesignIds={sharedState.selectedVirtualFormDesignIds}/>,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/virtual/setup/form_designs',
      next: '/virtual/setup/done',
    }
  },
  {
    path: '/virtual/setup/done',
    component: props => <VirtualSetupCompletion {...props} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/virtual',
      next: '/virtual',
    }
  },
];

import React from 'react';
import Axios from 'axios';

import { Alert, MeetingAnalysis, MeetingStats, ExternalAssetConnectionTable, UserRoleContext, SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, format } from 'kn-react';

import { Add, OpenInNew, Edit, Delete, Settings } from '@material-ui/icons';
import { withStyles, Typography, IconButton, Tooltip, Button, Link, Dialog, Popover, MenuItem } from '@material-ui/core';

import ExternalAccessConnectionModel from 'ExternalAssetConnection/ExternalAccessConnectionModel';
import ExternalAssetConnectionDialog, { SERVICETITAN, JOB, LOCATION, CUSTOMER, EXTERNAL_SOURCE_OPTIONS } from 'ExternalAssetConnection/ExternalAssetConnectionDialog';
import MeetingModel from './MeetingModel';
import MeetingRoomModel from 'Virtual/CompanyVirtual/VirtualSettings/MeetingRoomModel';
import MeetingSummaryDialog from './MeetingSummaryDialog';
import NoteModel from 'Note/NoteModel';
import NoteTable from 'Note/NoteTable';



class MeetingDetails extends React.Component {
  state = {
    meeting: null,
    meetingRoom: null,
    meetingRooms: [],
    meetings: [],
    notes: [],
    externalAssetConnections: [],
    isLoadingExternalAssetConnections: false,
    totalExternalAssetConnections: 0,
    isViewingSummary: false,
    externalAssetConnectionsLimit: 5,
    isViewingExternalConnections: false,
    externalAssetConnectionsPage: 0,
    isExternalAssetConnectionsDialogOpen: false,
    isViewingMeetingNotes: false,
    optionsMenuAnchor: null,
    activeExternalAccessConnection: null
  }


  componentDidMount = () => {
    const fetchMeetingCallback = () => this.fetchMeetingSummaries(
      this.fetchExternalAssetConnections
    );

    return this.fetchMeeting( fetchMeetingCallback );
  }



  fetchMeeting = callback => {
    return Axios.get(
      `/api/virtual/meetings/${ this.props.match.params.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const meeting = MeetingModel.fromJSON( response.data.meeting );
      const meetingRoom = MeetingRoomModel.fromJSON( response.data.meeting_room );


      return this.setState(
        {
          meeting,
          meetingRoom
        },
        callback
      );
    });
  }



  fetchMeetingSummaries = callback => {
    return Axios.get(
      `/api/virtual/meetings/${ this.props.match.params.id }/summaries`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const meetingRooms = response.data.meeting_rooms.map(
        m => MeetingRoomModel.fromJSON( m )
      );

      const meetings = response.data.meetings.map(
        m => MeetingModel.fromJSON( m )
      );

      const notes = response.data.notes.map(
        n => NoteModel.fromJSON( n )
      );


      return this.setState(
        {
          meetingRooms,
          meetings,
          notes
        },
        callback
      );
    });
  }



  fetchExternalAssetConnections = callback => {
    return Axios.get(
      `/api/core/external_asset_connections/companies/${ this.props.company.id }`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          source: 'Meeting',
          source_id: this.state.meeting.id,
          limit: this.state.externalAssetConnectionsLimit,
          page: this.state.externalAssetConnectionsPage,
        }
      }
    ).then(response => {
      const externalAssetConnections = response.data.external_asset_connections.map(
        e => ExternalAccessConnectionModel.fromJSON( e )
      );


      return this.setState(
        {
          externalAssetConnections,
          isLoadingExternalAssetConnections: false,
          totalExternalAssetConnections: response.data.total
        },
        callback
      );
    })
  }



  onChangeExternalAssetConnectionTablePagination = pageOrRowsPerPage => {
    const newState = {
      isLoadingExternalAssetConnections: true
    };

    if( +pageOrRowsPerPage.page >= 0 ){
      newState.externalAssetConnectionsPage = +pageOrRowsPerPage.page;
    } else {
      newState.externalAssetConnectionsPage = 0;
      newState.externalAssetConnectionsLimit = pageOrRowsPerPage.rowsPerPage;
    }

    return this.setState(
      newState,
      this.fetchExternalAssetConnections
    );
  }



  onModifyExternalAssetConnection = () => {
    return this.setState(
      {
        isExternalAssetConnectionsDialogOpen: false,
        optionsMenuAnchor: null,
        activeExternalAccessConnection: null
      },
      this.fetchExternalAssetConnections
    )
  }



  onClickExternalConnectionButton = () => {
    return this.setState(
      {
        isViewingExternalConnections: true,
        externalAssetConnectionsPage: 0,
        externalAssetConnectionsLimit: 5,
        isLoadingExternalAssetConnections: true,
      },
      this.fetchExternalAssetConnections
    );
  }



  constructAdditionalSecondaryChildren = externalAssetConnection => {
    if( externalAssetConnection.externalSourceId ){
      return <></>;
    }


    const onClose = () => this.setState({
      activeExternalAccessConnection: null,
      optionsMenuAnchor: null,
      isExternalAssetConnectionsDialogOpen: false
    });

    const onClickEdit = () => this.setState({
      activeExternalAccessConnection: this.state.activeExternalAccessConnection,
      optionsMenuAnchor: null,
      isExternalAssetConnectionsDialogOpen: true
    });

    const onClickDelete = () => this.onDeleteExternalAccessConnection(
      this.state.activeExternalAccessConnection.id
    );


    const externalAssetConnectionId = (
      !this.state.activeExternalAccessConnection ?
        null
      :
        this.state.activeExternalAccessConnection.id
    );

    return (
      <div style={{ width: 'right', textAlign: 'right' }}>
        <IconButton
          onClick={e => this.setState({ optionsMenuAnchor: e.currentTarget, activeExternalAccessConnection: externalAssetConnection })}
        >
          <Settings />
        </IconButton>

        <Popover
          open={Boolean(this.state.optionsMenuAnchor)}
          anchorEl={this.state.optionsMenuAnchor}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            component={'a'}
            href={`https://go.servicetitan.com/#/Search?go_boost_external_asset_connection=${ externalAssetConnectionId }`}
            target={'_blank'}
          >
            <OpenInNew className={this.props.classes.icon} />

            Connect
          </MenuItem>

          <MenuItem
            onClick={onClickEdit}
          >
            <Edit className={this.props.classes.icon} />

            Edit
          </MenuItem>

          <MenuItem
            onClick={onClickDelete}
          >
            <Delete className={this.props.classes.icon} />

            Delete
          </MenuItem>
        </Popover>
      </div>
    )
  }



  fetchIsListItemButton = externalAssetConnection => {
    return Boolean(
      externalAssetConnection.externalSourceId
    )
  }



  onDeleteExternalAccessConnection = activeExternalAccessConnectionId => {
    return Axios.delete(
      `/api/core/external_asset_connections/${ activeExternalAccessConnectionId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      () => this.props.showSnackbar('External Asset Connection successfully deleted.')
    ).then(
      this.onModifyExternalAssetConnection
    );
  }



  onClickListItem = externalAssetConnection => {
    const option = EXTERNAL_SOURCE_OPTIONS[ SERVICETITAN ].find(
      o => o.name === externalAssetConnection.externalSourceType
    );


    window.open(
      option.createUrl( externalAssetConnection ),
      '_blank'
    );
  }



  onCloseExternalAssetConnectionDialog = () => {
    return this.setState({
      isExternalAssetConnectionsDialogOpen: false,
      optionsMenuAnchor: null,
      activeExternalAccessConnection: null
    });
  }



  render(){
    const { classes } = this.props;

    const hasSummary = (
      (
        this.state.meetings &&
        this.state.meetings.filter(
          m => m.id !== this.state.meeting.id
        ).length
      ) ||
      (
        this.state.notes &&
        this.state.notes.length
      )
    );


    return (
      <div
        className={classes.room}
      >
        <Typography variant='h6'>
          Meeting Details
        </Typography>

        {
          !hasSummary ?
            null
          :
            <Alert
              info
              className={classes.summaryAlert}
            >
              <Typography
                variant='body1'
                className={classes.summaryAlertText}
              >
                A summary is available for this Meeting
              </Typography>

              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({ isViewingSummary: true })}
                className={classes.summaryButton}
                size='small'
              >
                View Summary
              </Button>
            </Alert>
        }

        <MeetingStats
          meeting={this.state.meeting}
          meetingRoom={this.state.meetingRoom}
        />

        <Button
          onClick={this.onClickExternalConnectionButton}
          variant='outlined'
          color='primary'
        >
          { this.state.totalExternalAssetConnections || 0 } External Connections
        </Button>

        <Button
          onClick={() => this.setState({ isViewingMeetingNotes: true })}
          variant='outlined'
          color='primary'
          className={classes.meetingNotesButton}
        >
          Meeting Notes
        </Button>

        <Dialog
          maxWidth='sm'
          fullWidth
          open={this.state.isViewingMeetingNotes}
          onClose={() => this.setState({ isViewingMeetingNotes: false })}
        >
          <NoteTable
            roleType={this.props.currentUserRole.roleType}
            roleTypeId={this.props.currentUserRole.roleTypeId}
            noteSource={'Meeting'}
            noteSourceId={this.props.match.params.id}
          />
        </Dialog>

        <MeetingAnalysis
          meeting={this.state.meeting}
        />

        <MeetingSummaryDialog
          meetingRooms={this.state.meetingRooms}
          meetings={this.state.meetings}
          notes={this.state.notes}
          open={this.state.isViewingSummary}
          onClose={() => this.setState({ isViewingSummary: false })}
        />

        <Dialog
          maxWidth='sm'
          fullWidth
          open={this.state.isViewingExternalConnections}
          onClose={() => this.setState({ isViewingExternalConnections: false })}
        >
          <ExternalAssetConnectionTable
            externalAssetConnections={this.state.externalAssetConnections}
            total={this.state.totalExternalAssetConnections}
            isLoading={this.state.isLoadingExternalAssetConnections}
            rowsPerPage={this.state.externalAssetConnectionsLimit}
            page={this.state.externalAssetConnectionsPage}
            secondaryActions={(
              <Tooltip title={'Add To Your CRM'}>
                <IconButton
                  onClick={() => this.setState({ isExternalAssetConnectionsDialogOpen: true })}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            )}
            showCreatedAt={true}
            caption={'Connect assets in GoBoost to assets in your CRM. Be sure to visit your CRM with the GoBoost Chrome Extension to finish the process.'}
            changePagination={this.onChangeExternalAssetConnectionTablePagination}
            constructAdditionalSecondaryChildren={this.constructAdditionalSecondaryChildren}
            fetchIsListItemButton={this.fetchIsListItemButton}
            onClickListItem={this.onClickListItem}
          />
        </Dialog>

        <ExternalAssetConnectionDialog
          externalAccessConnection={this.state.activeExternalAccessConnection}
          open={this.state.isExternalAssetConnectionsDialogOpen}
          onClose={this.onCloseExternalAssetConnectionDialog}
          onUpdateOrCreate={this.onModifyExternalAssetConnection}
          source={'Meeting'}
          sourceId={this.state.meeting ? this.state.meeting.id : null}
        />
      </div>
    )
  }
}


const styles = theme => ({
  room: {
    // height: '100%',
    // width: '100%',
    // minHeight: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    // flex: 1,
  },
  summaryButton: {
    marginLeft: 'auto'
  },
  summaryAlert: {
    display: 'flex',
    marginTop: 15
  },
  summaryAlertText: {
    lineHeight: 2
  },
  meetingNotesButton: {
    float: 'right'
  },
  button: {
    marginBottom: 5
  }
})


MeetingDetails.defaultProps = {
}


export default withContext(
  CompanyRoleContext,
  UserRoleContext,
  SnackbarContext,
  withStyles(styles)(
    MeetingDetails
  )
)
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { AdsChip, SitesChip, ReviewsChip, SocialChip, VirtualChip, PromiseButton, PlanContext } from 'go-boost-library-react';
import { withContext, withProvider, format } from 'kn-react';

import { List, ListItem, ListItemText, withStyles, Button, ListItemSecondaryAction, Typography } from '@material-ui/core';

import CompanyAdsProfileProvider, { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import CompanyReviewsProfileProvider, { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import CompanySocialProfileProvider, { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import CompanyVirtualProfileProvider, { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';


const ADS = 'Ads';
const REVIEWS = 'Reviews';
const SITES = 'Sites';
const SOCIAL = 'Social';
const VIRTUAL = 'Virtual';

class CompanyProductDetails extends React.Component {
  state = {

  }

  confirmShutDown = (product, shutdownFunction) => {
    let message = `Are you sure you want to shutdown ${ product }? This action cannot be undone. `;
    switch (product) {
      case ADS:
        message += `Please note, this action may delete all data, end your subscription, stop all running ads, release any tracking phone numbers, and sign out of your Facebook account.`;
        break;
      case REVIEWS:
        message += `Please note, this action may delete all data, end your subscription and sign out of your Google and Facebook accounts.`;
        break;
      case SITES:
        message += `Please note, this action may delete all data, end your subscription, disable your website, and release any tracking phone numbers or GoBoost-managed domains.`;
        break;
      case SOCIAL:
        message += `Please note, this action may delete all data, end your subscription, stop any future posts, and sign out of your Facebook account.`;
        break;
      case SOCIAL:
        message += `Please note, this action may delete all data, end your subscription, disable your Meeting Room and Virtual Work Requests, and disable access to GoBoost Integrations.`;
        break;
      default:
        break;
    }

    if( window.confirm( message ) ){
      return shutdownFunction();
    }

    return Promise.reject();
  }

  render() {
    const { availableServices, classes, companyAdsProfile, companySitesProfile, companyReviewsProfile, companySocialProfile, companyVirtualProfile } = this.props;

    return (
      !availableServices.length ?
        <Typography color="textSecondary" variant="caption" className={this.props.classes.noProducts}>
          You currently have no available products. Contact support for help getting started.
        </Typography>
      :
        <List>
          {
            !this.props.isAdsAvailable ?
              null
            :
              <>
                <ListItem classes={{ root: classes.listItemRoot }}>
                  <ListItemText
                    primary={<AdsChip />}
                    secondary={(
                      this.props.subscribedAdsPlan && this.props.subscribedAdsPlan.name ?
                        `Plan: ${ this.props.subscribedAdsPlan.name || '' }`
                      :
                        'Ads'
                    )}
                    secondaryTypographyProps={{
                      style: { marginTop: 5 }
                    }}
                  />

                  {
                    companyAdsProfile && companyAdsProfile.isSetupComplete && !companyAdsProfile.isShutdown ?
                      <ListItemSecondaryAction>
                        <PromiseButton
                          buttonProps={{
                            color: 'secondary',
                            variant: 'contained'
                          }}
                          onProcess={() => this.confirmShutDown(ADS, this.props.shutdownCompanyAdsProfile)}
                        >
                          Shut down
                        </PromiseButton>
                      </ListItemSecondaryAction>
                    :
                      <ListItemSecondaryAction>
                        <Button
                          component={RouterLink}
                          color='primary'
                          variant='contained'
                          to={'/ads'}
                        >
                          Subscribe
                        </Button>
                      </ListItemSecondaryAction>
                  }
                </ListItem>
              </>
          }


          {
            !this.props.isSitesAvailable ?
              null
            :
              <ListItem classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  primary={<SitesChip />}
                  secondary={(
                    this.props.subscribedSitesPlan && this.props.subscribedSitesPlan.name ?
                      `Plan: ${ this.props.subscribedSitesPlan.name || '' }`
                    :
                      'Sites'
                  )}
                  secondaryTypographyProps={{
                    style: { marginTop: 5 }
                  }}
                />

                {
                  companySitesProfile && companySitesProfile.isSetupComplete && !companySitesProfile.isShutdown ?
                    <ListItemSecondaryAction>
                      <PromiseButton
                        buttonProps={{
                          color: 'secondary',
                          variant: 'contained'
                        }}
                        onProcess={() => this.confirmShutDown(SITES, this.props.shutdownCompanySitesProfile)}
                      >
                        Shut down
                      </PromiseButton>
                    </ListItemSecondaryAction>
                  :
                    <ListItemSecondaryAction>
                      <Button
                        component={RouterLink}
                        color='primary'
                        variant='contained'
                        to={'/sites'}
                      >
                        Subscribe
                      </Button>
                    </ListItemSecondaryAction>
                }
              </ListItem>
          }


          {
            !this.props.isReviewsAvailable ?
              null
            :
              <ListItem classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  primary={<ReviewsChip />}
                  secondary={(
                    this.props.subscribedReviewsPlan && this.props.subscribedReviewsPlan.name ?
                      `Plan: ${ this.props.subscribedReviewsPlan.name || '' }`
                    :
                      'Reviews'
                  )}
                  secondaryTypographyProps={{
                    style: { marginTop: 5 }
                  }}
                />

                {
                  companyReviewsProfile && companyReviewsProfile.isSetupComplete && !companyReviewsProfile.isShutdown ?
                    <ListItemSecondaryAction>
                      <PromiseButton
                        buttonProps={{
                          color: 'secondary',
                          variant: 'contained'
                        }}
                        onProcess={() => this.confirmShutDown(REVIEWS, this.props.shutdownCompanyReviewsProfile)}
                      >
                        Shut down
                      </PromiseButton>
                    </ListItemSecondaryAction>
                  :
                    <ListItemSecondaryAction>
                      <Button
                        component={RouterLink}
                        color='primary'
                        variant='contained'
                        to={'/reviews'}
                      >
                        Subscribe
                      </Button>
                    </ListItemSecondaryAction>
                }
              </ListItem>
          }

          {
            !this.props.isSocialAvailable ?
              null
            :
              <ListItem classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  primary={<SocialChip />}
                  secondary={(
                    this.props.subscribedSocialPlan && this.props.subscribedSocialPlan.name ?
                      `Plan: ${ this.props.subscribedSocialPlan.name || '' }`
                    :
                      'Social'
                  )}
                  secondaryTypographyProps={{
                    style: { marginTop: 5 }
                  }}
                />

                {
                  companySocialProfile && companySocialProfile.isSetupComplete && !companySocialProfile.isShutdown ?
                    <ListItemSecondaryAction>
                      <PromiseButton
                        buttonProps={{
                          color: 'secondary',
                          variant: 'contained'
                        }}
                        onProcess={() => this.confirmShutDown(SOCIAL, this.props.shutdownCompanySocialProfile)}
                      >
                        Shut down
                      </PromiseButton>
                    </ListItemSecondaryAction>
                  :
                    <ListItemSecondaryAction>
                      <Button
                        component={RouterLink}
                        color='primary'
                        variant='contained'
                        to={'/social'}
                      >
                        Subscribe
                      </Button>
                    </ListItemSecondaryAction>
                }
              </ListItem>
          }

          {
            !this.props.isVirtualAvailable ?
              null
            :
              <ListItem classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  primary={<VirtualChip />}
                  secondary={(
                    this.props.subscribedVirtualPlan && this.props.subscribedVirtualPlan.name ?
                      `Plan: ${ this.props.subscribedVirtualPlan.name || '' }`
                    :
                      'Virtual'
                  )}
                  secondaryTypographyProps={{
                    style: { marginTop: 5 }
                  }}
                />

                {
                  companyVirtualProfile && companyVirtualProfile.isSetupComplete && !companyVirtualProfile.isShutdown ?
                    <ListItemSecondaryAction>
                      <PromiseButton
                        buttonProps={{
                          color: 'secondary',
                          variant: 'contained'
                        }}
                        onProcess={() => this.confirmShutDown(VIRTUAL, this.props.shutdownCompanyVirtualProfile)}
                      >
                        Shut down
                      </PromiseButton>
                    </ListItemSecondaryAction>
                  :
                    <ListItemSecondaryAction>
                      <Button
                        component={RouterLink}
                        color='primary'
                        variant='contained'
                        to={'/virtual'}
                      >
                        Subscribe
                      </Button>
                    </ListItemSecondaryAction>
                }
              </ListItem>
          }
        </List>
    );
  }
}


const styles = theme => ({
  listItemRoot: {
    backgroundColor: '#FAFAFA',
    border: '1px solid #F5F5F5',
    borderRadius: 5,
    padding: 20,
  },
  priceRoot: {
    textAlign: 'right'
  },
  price: {
    color: theme.palette.primary.main,
    fontSize: 32,
  },
  period: {
    color: '#7f8788',
    fontSize: 16,
  },
  noProducts: {
    textAlign: 'left',
    padding: 25,
  }
});

export default withProvider(
  CompanyAdsProfileProvider,
  CompanySitesProfileProvider,
  CompanyReviewsProfileProvider,
  CompanySocialProfileProvider,
  CompanyVirtualProfileProvider,
  withStyles(styles)(
    withContext(
      PlanContext,
      CompanyAdsProfileContext,
      CompanySitesProfileContext,
      CompanyReviewsProfileContext,
      CompanySocialProfileContext,
      CompanyVirtualProfileContext,
      CompanyProductDetails
    )
  )
)
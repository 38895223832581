import React from 'react';
import Axios from 'axios';

import { withContext } from 'kn-react';
import { SnackbarContext, Alert, PromiseButton } from 'go-boost-library-react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, withStyles } from '@material-ui/core';



const DEFAULT_STATE = {
  content: ''
}


class NoteDialog extends React.Component {
  state = {
    ...DEFAULT_STATE
  }

  componentDidUpdate = oldProps => {
    if( this.props.open && oldProps.open !== this.props.open ){
      return this.setState({
        ...DEFAULT_STATE
      });
    }
  }

  validateNote = () => {
    return Boolean( this.state.content );
  }


  onAddNote = () => {
    if( !this.validateNote() ){
      this.props.showSnackbar('Please specify the content of the note.');

      return Promise.resolve();
    }

    return this.props.onAddNote(this.state.content);
  }


  render(){
    const { classes } = this.props;

    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          Add Note
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <TextField
            required
            fullWidth
            multiline
            rows={3}
            label='Content'
            value={this.state.content}
            onChange={e => this.setState({ content: e.target.value })}
            variant='outlined'
            className={classes.textField}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={this.props.onClose}
            color='primary'
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          <PromiseButton
            onProcess={this.onAddNote}
            buttonProps={{
              color: 'primary',
              variant: 'contained'
            }}
          >
            Add
          </PromiseButton>
        </DialogActions>
      </Dialog>
    )
  }
}


NoteDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onAddNote: () => {},
}


const styles = theme => ({
  dialogContent: {
    paddingTop: 10
  },
  textField: {
    marginBottom: 30
  },
  cancelButton: {
    marginRight: 15
  }
})


export default withContext(
  SnackbarContext,
  withStyles(styles)(
    NoteDialog
  )
)
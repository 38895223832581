import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, withProvider } from 'kn-react';

import { GoogleAccountContext } from 'GoogleAccount/GoogleAccountProvider';
import CompanyAdsProfileProvider, { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import CompanyReviewsProfileProvider, { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import ProductCompletionPage from 'SharedSetup/ProductCompletionPage/ProductCompletionPage';

import socialNextStep from './SocialNextSteps';


class SocialSetupCompletion extends React.Component {
  state = {
    nextSteps: []
  }

  componentDidMount = () => {
    this.setState({
      nextSteps: socialNextStep(this.props)
    })
  }

  render(){
    const { classes } = this.props;

    const possibleLinks = [
      {
        isAvailable: this.props.isReviewsAvailable,
        profile: this.props.companyReviewsProfile,
        text: 'Setup Reviews',
        link: '/reviews'
      },
      {
        isAvailable: this.props.isSitesAvailable,
        profile: this.props.companySitesProfile,
        text: 'Setup Sites',
        link: '/sites'
      },
      {
        isAvailable: this.props.isAdsAvailable,
        profile: this.props.companyAdsProfile,
        text: 'Setup Ads',
        link: '/ads'
      },
    ];

    const productLinks = [];
    possibleLinks.forEach(
      l => {
        if(
          l.isAvailable &&
          (
            !l.profile ||
            ( l.profile && !l.profile.isSetupComplete )
          )
        ){
          productLinks.push({
            text: l.text,
            link: l.link,
          })
        }
      }
    )


    return(
      <ProductCompletionPage
        product={'Social'}
        nextSteps={this.state.nextSteps}
        productLinks={productLinks}
        dashboardLink={'/social'}
      />
    )
  }
}

export default withProvider(
  CompanyReviewsProfileProvider,
  CompanySitesProfileProvider,
  CompanyAdsProfileProvider,
  withContext(
    CompanyReviewsProfileContext,
    CompanySitesProfileContext,
    CompanyAdsProfileContext,
    GoogleAccountContext,
    CompanyRoleContext,
    PlanContext,
    SocialSetupCompletion
  )
)
import React from 'react';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { ChannelContext } from 'Channels/ChannelProvider';
import VirtualDashboard from 'Virtual/VirtualDashboard/VirtualDashboard';

const virtualDashboard = props => {
  return (
    <VirtualDashboard
      roleTypePath="organizations"
      roleTypeId={props.organization.id}
      currentUserRole={props.currentUserRole}
      getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
      channelIds={props.virtualChannels.map(c => c.id)}
    />
  )
};

export default withContext(
  OrganizationRoleContext,
  ChannelContext,
  virtualDashboard
);
import React from 'react';

import { IconButton, SvgIcon } from '@material-ui/core';


const contractIcon = props => (
  <IconButton
    aria-label="Contract Icon"
    style={{ backgroundColor: '#F7F6F6' }}
    disabled
  >
    <SvgIcon
      viewBox="0 0 45 50"
      style={{ fontSize: props.fontSize }}
    >
      <path d="M36.5555 11.6667L25.8889 1M36.5555 11.6667H25.8889V1M36.5555 11.6667V27.2222M25.8889 1H1V49H36.5555V44.5556" fill='none' stroke={props.fill}/>
      <line x1="6.33203" y1="17.3906" x2="31.2209" y2="17.3906" fill='none' stroke={props.fill}/>
      <line x1="6.33203" y1="22.7227" x2="31.2209" y2="22.7227" fill='none' stroke={props.fill}/>
      <line x1="6.33203" y1="28.0547" x2="24.9987" y2="28.0547" fill='none' stroke={props.fill}/>
      <line x1="6.33203" y1="44.0566" x2="21.4431" y2="44.0566" fill='none' stroke={props.fill}/>
      <circle cx="34.7776" cy="35.6663" r="8.88889" fill='none' stroke={props.fill}/>
      <path d="M34.7754 30.7793V37.0015H37.8865" fill='none' stroke={props.fill}/>
    </SvgIcon>
  </IconButton>
);


contractIcon.defaultProps = {
  fill: '#5E5F64'
}


export default contractIcon;
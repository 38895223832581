import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { HorizontalPercentBar } from 'go-boost-library-react';
import { withContext } from 'kn-react';
import React from 'react';
import { ReviewSourcesContext } from 'Reviews/ReviewSourcesProvider';
import LikeFacebookReaction from './FacebookIcons/LikeFacebookReaction';
import LoveFacebookReaction from './FacebookIcons/LoveFacebookReaction';
import WowFacebookReaction from './FacebookIcons/WowFacebookReaction';
import HahaFacebookReaction from './FacebookIcons/HahaFacebookReaction';
import SorryFacebookReaction from './FacebookIcons/SorryFacebookReaction';
import AngerFacebookReaction from './FacebookIcons/AngerFacebookReaction';

import reactionsIconAndNames from './ReactionsIconAndNames';


const reactionsDistribution = props => {
  const { reactions, classes } = props;

  const reactionTypes = Object.keys(reactions);

  const reactionsCount = reactionTypes.map(
    r => reactions[r]
  );

  const sum = reactionsCount.reduce((a, b) => a+b, 0)
  const max = Math.max(...reactionsCount)


  return (
    <Grid container item xs={12}>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Reactions Distribution</Typography>
        </Grid>

        <Grid container item xs={12}>
          {
            sum ?
              reactionsIconAndNames.map((r, i) => {
                let Icon = r.icon;

                return (
                  <Grid container key={i} style={{ paddingBottom: 5 }}>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <div style={{ marginRight: 5 }}>
                        <Icon />
                      </div>

                      {r.name}
                    </Grid>

                    <Grid item xs={8}>
                      <HorizontalPercentBar
                        backgroundColor='#fff'
                        color='#37caad'
                        widthPercent={reactions[ r.key ] / max}
                        displayPercent={reactions[ r.key ] / sum}
                        number={reactions[ r.key ]}
                      />
                    </Grid>
                  </Grid>
                )
              })
            :
              <p style={{ opacity: 0.5 }}>No chart data available</p>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

reactionsDistribution.defaultProps = {
  reactions: {},
}


const styles = theme => ({
  smallAvatar: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
  smallAvatarImage: {
    height: 20,
  }
});


export default withStyles(styles)(
  withContext(ReviewSourcesContext, reactionsDistribution)
);
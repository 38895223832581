import { Button, Grid, Typography, withStyles, IconButton, Tooltip } from '@material-ui/core';
import { MaxWidth, withContext } from 'kn-react';
import React from 'react';
import FeedList from 'Social/Feed/FeedList';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import SocialUndoResubscription from 'Social/CompanySocial/SocialUndoResubscription/SocialUndoResubscription';
import { KnowledgeBaseButton } from 'go-boost-library-react';


class Feed extends React.Component {
  componentDidMount = () => {
    return this.props.fetchFeedsForPlan(this.props.selectedPlanId)
  }

  componentDidUpdate = oldProps => {
    if( oldProps.selectedPlanId !== this.props.selectedPlanId ){
      return this.props.fetchFeedsForPlan(this.props.selectedPlanId)
    }
  }

  render() {
    const { classes } = this.props;

    const supportText = this.props.feeds ?
        'Click below to add a Feed.'
      :
        ''
    ;

    const companyFeedFeedIds = this.props.companyFeeds.map(f => f.feedId);



    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <SocialUndoResubscription />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6" color="primary">
                Current Feeds

                &nbsp;

                <KnowledgeBaseButton
                  path={'/what-is-a-feed'}
                  tooltipTitle={'Learn more about Feeds'}
                />
              </Typography>
            </Grid>

            {/* <CancelSetupButton /> */}

            <Grid item xs={12}>
              <FeedList
                availableFeeds={this.props.feeds.filter(f => companyFeedFeedIds.includes(f.id))}
                selectedFeedIds={companyFeedFeedIds}
                getPreviewFeedLink={() => {}}
                warningText={`You do not have any Company Feeds. ${ supportText }`}
                canEditFeed={false}
              />
            </Grid>

            {
              this.props.feeds.length === this.props.companyFeeds.length ?
                null
              :
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">Available Feeds</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FeedList
                      availableFeeds={this.props.feeds.filter(f => !companyFeedFeedIds.includes(f.id))}
                      selectedFeedIds={this.props.companyFeeds.map(f => f.feedId)}
                      onClickSetup={feed => this.props.subscribeToFeed(feed.id)}
                      setupText={'Subscribe to Feed'}
                    />
                  </Grid>
                </>
            }

            <Grid item container xs={12}>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => this.props.transitionTo('back', this.props.transitions)}
                >
                  Back
                </Button>
              </Grid>

              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.transitionTo('next', this.props.transitions)}
                  disabled={!this.props.companyFeeds || !this.props.companyFeeds.length}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: 0
  },
  add: {
    textAlign: 'right'
  }
});

export default withContext(
  TransitionContext,
  withStyles(styles)(Feed)
);
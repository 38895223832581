import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Send from '@material-ui/icons/Send';

import { format } from 'kn-react'
import { BarChart, BarLineChart,  ChangeMetric, StarsGroupIcon, ReviewsIcon, ResponseRateIcon } from 'go-boost-library-react';
import CumulativeRatingChart from './CumulativeRatingChart';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


class ReviewDashboardSummary extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };


  render() {
    const {
      classes,
    } = this.props;
    const { value } = this.state;


    const { ratingAverage, ratingAverageChange } = this.props;
    const ratingsMetric = <ChangeMetric
      title="Average Rating"
      // ratingAverage is a string so convert to float to determine if truthy
      metric={ parseFloat(ratingAverage) ? d3.format('.2f')(ratingAverage) : '-' }
      icon={StarsGroupIcon}
      iconColor="#37caad"
      iconBackgroundColor='rgba(55, 202, 173, 0.2)'
      change={ ratingAverageChange || ''}
      width={260}
      height={260}
    />

    const { totalReviews, totalReviewsChange } = this.props;
    const reviewsMetric = <ChangeMetric
      title="Total Reviews"
      metric={ d3.format(',')(totalReviews) }
      change={ totalReviewsChange || '' }
      icon={ReviewsIcon}
      iconColor="#5a7af9"
      iconBackgroundColor='rgba(90, 122, 249, 0.2)'
      width={260}
      height={260}
    />

    const { responseCount, responseCountChange } = this.props;
    const responseCountMetric = <ChangeMetric
      title="Reviews You've Replied To"
      metric={ d3.format(',')(responseCount) }
      change={ responseCountChange || '' }
      icon={ResponseRateIcon}
      iconColor="#9e70ff"
      iconBackgroundColor='rgba(158, 112, 255, 0.2)'
      width={260}
      height={260}
    />


    const { totalRequests, totalRequestsChange } = this.props;
    const reviewRequestsMetric = <ChangeMetric
      title="Total Requests"
      metric={ format.commas(totalRequests || 0) }
      change={ totalRequestsChange || '' }
      icon={Send}
      iconColor="#ff9a48"
      iconBackgroundColor='rgba(255, 154, 72, 0.2)'
      width={260}
      height={260}
    />


    const requestsLineStyles = {
      'Review Requests': {
        type: 'monotone',
        dataKey: 'Review Requests',
        stroke: '#ff9a48',
        dot: false
      }
    }

    const requestsBarStyles = {
      'Opened Requests': {
        type: 'monotone',
        dataKey: 'Opened Requests',
        barSize: 20,
        fill: '#37caad'
      }
    }


    const responseCountBarStyles = {
      'Positive Reviews': {
        type: 'monotone',
        dataKey: 'Positive',
        barSize: 20,
        fill: '#afd889'
      },
      'Neutral Reviews': {
        type: 'monotone',
        dataKey: 'Neutral',
        barSize: 20,
        fill: '#ccc'
      },
      'Negative Reviews': {
        type: 'monotone',
        dataKey: 'Negative',
        barSize: 20,
        fill: '#ff8c5f'
      }
    }


    const barLineTooltipProps = {
      formatter: (d, name) => {
        if(name === 'Response Rate') {
          return format.percent(d);
        } else {
          return d3.format(',')(d);
        }
      }
    };


    return (
      <Paper>
        <Hidden mdDown>
          <div style={{ borderBottom: '1px solid #efefef' }}>
            <Tabs variant='fullWidth' value={value} onChange={this.handleChange}>
              <Tab variant='fullWidth' icon={ratingsMetric} />
              <Tab variant='fullWidth' icon={reviewsMetric} />
              <Tab variant='fullWidth' icon={reviewRequestsMetric} />
              <Tab variant='fullWidth' icon={responseCountMetric} />
            </Tabs>
          </div>

          {
            value === 0 &&
              <CumulativeRatingChart
                data={this.props.ratingAverageChartData}
                tooltipProps={{
                  formatter: v => d3.format('.2f')(v)
                }}
              />
          }

          {
            value === 1 &&
              <BarChart
                data={this.props.ratingAverageChartData}
                barStyles={{
                  'Count': {
                    dataKey:"Count",
                    fill:"#5a7af9",
                  }
                }}
                yAxis={{
                  label: { value: 'Reviews', angle: -90, position: 'insideLeft' }
                }}
                tooltipProps={{
                  formatter: v => d3.format(',')(v)
                }}
                chartMargin={{
                  top: 20,
                  bottom: 20,
                  left: 20,
                  right: 20
                }}
              />
          }

          {
            value === 2 &&
              <BarLineChart
                data={this.props.requestsChartData}
                lineStyles={requestsLineStyles}
                barStyles={requestsBarStyles}
                yAxis={{
                  label: {
                    value: 'Review Requests',
                    angle: -90,
                    position: 'insideLeft'
                  },
                  allowDecimals: false,
                }}
              />
          }

          {
            value === 3 &&
              <BarLineChart
                data={this.props.ratingAverageChartData}
                barStyles={responseCountBarStyles}
                tooltipProps={barLineTooltipProps}
                yAxis={{
                  label: {
                    value: 'Reviews Replied To',
                    angle: -90,
                    position: 'insideLeft'
                  }
                }}
              />
          }
        </Hidden>

        <Hidden lgUp>
          <div>{ratingsMetric}</div>
          <div>{reviewsMetric}</div>
          <div>{reviewRequestsMetric}</div>
          <div>{responseCountMetric}</div>
        </Hidden>
      </Paper>
    );
  }
}


const styles = theme => ({

});



export default withStyles(styles)(ReviewDashboardSummary);
import React from 'react';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import AdsDashboard from '../../AdsDashboard/AdsDashboard';

const adsDashboard = props => (
  <AdsDashboard
    {...props}
    roleTypePath="companies"
    roleTypeId={props.company.id}
    getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
    isSearchCompaniesVisible={false}
  />
);

export default withContext(
  CompanyRoleContext,
  adsDashboard
);
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { FanIcon, PaveIcon, PromiseButton, UserRoleContext, Ability, isDevelopment } from 'go-boost-library-react';
import { MaxWidth, withContext } from 'kn-react';

import { Button, Grid, Typography, withStyles, IconButton, Tooltip, Card, CardActionArea, CardMedia, CardContent, CardActions } from '@material-ui/core';
import { Videocam } from '@material-ui/icons';

import SvgIcon from 'Virtual/Icons/SvgIcon';



const ICONS = {
  FanIcon,
  PaveIcon
}


class VirtualFormDesignList extends React.Component {
  render() {
    const { classes } = this.props;

    const setupVirtualFormIds = this.props.companyVirtualFormDesigns.map(
      d => d.virtualFormDesignId
    );

    const canEdit = (
      this.props.canEdit &&
      this.props.currentUserRole.roleHasAbility( Ability.EDIT_VIRTUAL_COMPANY_VIRTUAL_PROFILE )
    );

    return (
      <Grid container spacing={16}>
        {
          this.props.virtualFormDesigns.map(
            (v, i) => {
              const isSelected = this.props.selectedVirtualFormDesignIds.includes(
                v.id
              );

              const companyVirtualFormDesign = this.props.companyVirtualFormDesigns.find(
                d => d.virtualFormDesignId === v.id
              );


              let previewUrl = (
                companyVirtualFormDesign && companyVirtualFormDesign.url ?
                  companyVirtualFormDesign.url
                :
                  v.previewUrl
              );
              if( previewUrl && !previewUrl.match(/https:\/\/|http:\/\//) ){
                const schema = (
                  isDevelopment() ?
                    'http://'
                  :
                    'https://'
                );

                previewUrl = `${ schema }${ previewUrl }`;
              }


              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={i}
                >
                  <Card>
                    <CardActionArea
                      component={previewUrl ? 'a' : CardActionArea}
                      href={previewUrl ? previewUrl : undefined}
                      target={previewUrl ? '_blank' : undefined}
                    >
                      {
                        v.previewImageUrl ?
                          <CardMedia
                            className={classes.cardMedia}
                            image={v.previewImageUrl}
                            title={v.name}
                          />
                        :
                          <div className={classes.cardMedia}>
                            <Videocam className={classes.noDesignLogo} />
                          </div>
                      }
                    </CardActionArea>

                    <CardContent className={classes.cardContent}>
                      {/* <Icon className={classes.icon}/> */}
                      {
                        !v.icon ?
                          null
                        :
                          <SvgIcon
                            viewBox={v.icon.viewBox}
                            paths={v.icon.paths}
                            className={classes.icon}
                          />
                      }

                      <Typography variant="h6">{v.name}</Typography>
                    </CardContent>

                    <CardActions className={classes.cardActions}>
                      {
                        v.previewUrl ?
                          <Button
                            variant='outlined'
                            color='secondary'
                            className={classes.previewButton}
                            component={'a'}
                            href={previewUrl || '#'}
                            target='_blank'
                          >
                            { companyVirtualFormDesign ? 'View' : 'Preview' }
                          </Button>
                        :
                          null
                      }

                      {
                        (
                          !isSelected &&
                          !companyVirtualFormDesign &&
                          canEdit
                        ) ?
                          (
                            this.props.shouldSetupDesignWhenSelected ?
                              <PromiseButton
                                onProcess={() => this.props.onSelectDesign( v )}
                                buttonProps={{
                                  color: 'secondary',
                                  variant: 'contained',
                                  fullWidth: true
                                }}
                              >
                                Setup
                              </PromiseButton>
                            :
                              <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => this.props.onSelectDesign( v )}
                              >
                                Select
                              </Button>
                          )

                        :
                          null
                      }

                      {
                        isSelected ?
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => this.props.onSelectDesign( v )}
                          >
                            Selected
                          </Button>
                        :
                          companyVirtualFormDesign ?
                            <Button
                              variant='contained'
                              color='primary'
                              component={RouterLink}
                              to={`/virtual/widget_and_forms/company_virtual_form_designs/${ companyVirtualFormDesign.id }`}
                            >
                              Edit
                            </Button>
                          :
                            null
                      }
                    </CardActions>
                  </Card>
                </Grid>
              )
            }
          )
        }
      </Grid>
    );
  }
}


VirtualFormDesignList.defaultProps = {
  selectedVirtualFormDesignIds: [],
  companyVirtualFormDesigns: [],
  virtualFormDesigns: [],
  onSelectDesign: () => {},
  shouldSetupDesignWhenSelected: false,
  canEdit: true
}


const styles = theme => ({
  formDesignListWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  },
  active: {
    backgroundColor: `#F3F3F3 !important`,
    border: `3px solid ${ theme.palette.primary.main } !important`,
    boxShadow: '3px 3px 3px lightgrey',
  },
  virtualFormDesign: {
    margin: 5,
    border: `2px solid #C3C3C3`,
    borderRadius: 6,
    padding: 10,
    backgroundColor: 'white'
  },
  nonSetupVirtualFormDesign: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '3px 3px 3px lightgrey',
      backgroundColor: '#F3F3F3'
    }
  },
  iconDiv: {
    height: 100,
    textAlign: 'center'
  },
  icon: {
    fontSize: 35,
    color: theme.palette.secondary.main,
    marginRight: 25
  },
  typographyDiv: {
    marginTop: 10,
    textAlign: 'center'
  },
  viewButton: {
    marginTop: 15
  },
  cardMedia: {
    width: '100%',
    height: 300,
    backgroundColor: 'whitesmoke',
    textAlign: 'center'
  },
  cardContent: {
    paddingBottom: 10,
    display: 'flex'
  },
  cardActions: {
    justifyContent: 'right'
  },
  previewButton: {
    marginRight: 15
  },
  noDesignLogo: {
    color: 'grey',
    fontSize: 80,
    paddingTop: 110
  },
});



export default withContext(
  UserRoleContext,
  withStyles(styles)(
    VirtualFormDesignList
  )
);
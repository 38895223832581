import React from 'react';
import Axios from 'axios';
// Kinertia
import { log, formHelpers, AutoError, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, UserRoleContext } from 'go-boost-library-react';
// Material
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SignUpCodeModel from 'SignUp/SignUpCodeModel';
import OrganizationModel from 'Settings/Organizations/OrganizationModel';

const DEFAULT_STATE = {
  loadingOrganizations: true,
  possibleOrganizations: [],
  organizationId: '',
  loadingSignUpCodes: true,
  possibleSignUpCodes: [],
  signUpCodeId: '',
  email: '',
};



class NewCompanyInvitationDialog extends React.Component {
  state = DEFAULT_STATE

  componentDidMount = () => {
    this.getPossibleOrganizations();
  }


  getPossibleOrganizations = () => {
    const { organization } = this.props;
    log('getPossibleOrganizations', organization.id);

    this.setState({ loadingOrganizations: true });

    return Axios.get(
      `/api/core/organizations/${organization.id}/family`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('getPossibleOrganizations response', response);
        const possibleOrganizations = response.data.family.map(o => OrganizationModel.fromJSON(o));
        this.setState({ possibleOrganizations, loadingOrganizations: false });
      });
  }


  getPossibleSignUpCodes = () => {
    const { organizationId } = this.state;
    log('getPossibleSignUpCodes', organizationId);

    this.setState({ loadingSignUpCodes: true });

    return Axios.get(
      `/api/core/organizations/${organizationId}/sign_up_codes`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('getPossibleSignUpCodes response', response);
        const possibleSignUpCodes = response.data.sign_up_codes.map(c => SignUpCodeModel.fromJSON(c));

        this.setState({
          possibleSignUpCodes,
          loadingSignUpCodes: false,
          signUpCodeId: possibleSignUpCodes.length === 1 ? possibleSignUpCodes[0].id : ''
        });
      });
  }


  inviteCompany = () => {
    return Axios.post(
      '/api/core/company_invitations',
      {
        email: this.state.email,
        sign_up_code_id: this.state.signUpCodeId
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('inviteCompany response', response);
        this.props.showSnackbar('Company invitation sent.');
        this.props.onInviteUser();
        this.onClose();
      });
  }


  onProcess = () => {
    log('onProcess', this.state);
    return this.validate()
      .then(this.inviteCompany)
      .catch(AutoError.catch.bind(this));;
  }

  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }

  onError = error => {
    log('error', error);
    this.props.showSnackbar(error.response.data.message);
  }

  validate = () => {
    log(this.state.role_type_id)
    const baseValidations = {
      email: {
        presence: { message: 'Please enter a valid email.' },
        email: { message: 'Please enter a valid email.' }
      },
      organizationId: {
        presence: { message: 'Please select an organization.' }
      },
      signUpCodeId: {
        presence: { message: 'Please select a sign up code.' }
      }
    };
    return formHelpers.validate(this.state, baseValidations);
  }

  onChangeOrganization = e => {
    this.setState({ organizationId: e.target.value }, this.getPossibleSignUpCodes);
  }

  onClose = () => {
    this.setState({ email: '', organizationId: '', signUpCodeId: '' }, this.props.onClose);
  }


  render() {
    const { loadingOrganizations, loadingSignUpCodes } = this.state;

    return (
      <Dialog id="create-company-invitation-dialog" open={this.props.open} fullWidth maxWidth="sm">
        <DialogTitle>Invite a New Company</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the following information to invite a new company.
            </DialogContentText>

          <TextField
            onChange={e => this.setState({ email: e.target.value })}
            fullWidth
            label="Email"
            margin="normal"
            value={this.state.email}
            name='email'
          />

          <TextField
            select
            fullWidth
            label="Organization"
            margin="normal"
            value={this.state.organizationId}
            onChange={this.onChangeOrganization}
            disabled={loadingOrganizations}
            name='organization'
          >
            {
              this.state.possibleOrganizations.map(o => (
                <MenuItem key={o.id} value={o.id}>
                  {o.name}
                </MenuItem>
              ))
            }
          </TextField>


          <TextField
            select
            fullWidth
            label="Sign Up Code"
            margin="normal"
            value={this.state.signUpCodeId}
            onChange={e => this.setState({ signUpCodeId: e.target.value })}
            disabled={loadingSignUpCodes}
            name='sign-up-code'
          >
            {
              this.state.possibleSignUpCodes.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  {c.code}
                </MenuItem>
              ))
            }
          </TextField>

        </DialogContent>

        <DialogActions>
          <Button onClick={this.onClose} color="outlined" color="primary">
            Cancel
            </Button>
          <PromiseButton
            disabled={!this.state.organizationId || !this.state.signUpCodeId}
            onProcess={this.onProcess}
          >
            Send
          </PromiseButton>
        </DialogActions>
      </Dialog>
    );
  }

}


export default withContext(UserRoleContext, SnackbarContext, NewCompanyInvitationDialog);
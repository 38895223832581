import LikeFacebookReaction from './FacebookIcons/LikeFacebookReaction';
import LoveFacebookReaction from './FacebookIcons/LoveFacebookReaction';
import WowFacebookReaction from './FacebookIcons/WowFacebookReaction';
import HahaFacebookReaction from './FacebookIcons/HahaFacebookReaction';
import SorryFacebookReaction from './FacebookIcons/SorryFacebookReaction';
import AngerFacebookReaction from './FacebookIcons/AngerFacebookReaction';

export default [
    {
      name: 'Like',
      icon: LikeFacebookReaction,
      key: 'post_reactions_like_total'
    },
  {
    name: 'Love',
    icon: LoveFacebookReaction,
    key: 'post_reactions_love_total'
  },
  {
    name: 'Wow',
    icon: WowFacebookReaction,
    key: 'post_reactions_wow_total'
  },
  {
    name: 'Haha',
    icon: HahaFacebookReaction,
    key: 'post_reactions_haha_total'
  },
  {
    name: 'Sorry',
    icon: SorryFacebookReaction,
    key: 'post_reactions_sorry_total'
  },
  {
    name: 'Anger',
    icon: AngerFacebookReaction,
    key: 'post_reactions_anger_total'
  },
]
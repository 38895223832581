import { Button, Grid, Typography, withStyles, IconButton, Tooltip } from '@material-ui/core';
import { MaxWidth, withContext } from 'kn-react';
import React from 'react';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import FeedList from 'Social/Feed/FeedList';
import { FeedContext } from 'Social/Feed/FeedProvider';
import { Add } from '@material-ui/icons';
import NewFeedDialog from 'Social/Feed/NewFeedDialog';


class Feed extends React.Component {
  state={
    dialogOpen: false
  }

  onClickLaunchFeed = feed => {
   return
  }

  render() {
    const { classes, siteDesigns, companySitesProfile, companySiteDesigns } = this.props;

    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>

            <Grid item xs={12}>
              <Typography variant="h6" color="primary">Current Feeds</Typography>
            </Grid>

            <Grid item xs={12} className={classes.add}>
              <Tooltip title='Add Feed'>
                <IconButton onClick={() => this.setState({ dialogOpen: true })}>
                  <Add />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <FeedList
                availableFeeds={this.props.feeds}
                selectedFeedIds={this.props.feeds.map(f => f.id)}
                getPreviewFeedLink={() => {}}
                onClickSetup={() => {}}
                warningText={`You do not have any Feeds. Click the + to create your first Feed.`}
                setupText={''}
                canLaunchFeed={false}
              />
            </Grid>

            <NewFeedDialog
              open={this.state.dialogOpen}
              onClose={() => this.setState({ dialogOpen: false })}
            />
          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: 0
  },
  add: {
    textAlign: 'right'
  }
});

export default withContext(
  FeedContext,
  withStyles(styles)(Feed)
);
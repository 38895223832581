import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';



// const data =  [
//   { image: 'https://storage.googleapis.com/go-boost-partners-public/boosted_service_assets/bs-6-2.jpg', url: 'http://localhost:8080/wf/waterfurnace2'},
//   { image: 'https://storage.googleapis.com/go-boost-partners-public/boosted_service_assets/bs-6-3.jpg', url: 'http://localhost:8080/wf/waterfurnace3'},
//   { image: 'https://storage.googleapis.com/go-boost-partners-public/boosted_service_assets/bs-6-4.jpg', url: 'http://localhost:8080/wf/waterfurnace2'},
//   { image: 'https://storage.googleapis.com/go-boost-partners-public/boosted_service_assets/bs-6-5.jpg', url: 'http://localhost:8080/wf/waterfurnace2'},
// ]


const adCarousel = props => {
  return (
    <div className={props.classes.cardRootContainer}>
      <div className={props.classes.cardScrollContainer} style={{ width: (props.itemWidth + (2 * props.itemMargin)) * props.data.length, height: props.itemHeight }}>

        {
          props.data.map((ad, i) => (
            <div className={props.classes.media} style={{ height: props.itemHeight, width: props.itemWidth, marginRight: props.itemMargin, marginLeft: props.itemMargin }} key={i}>

              {
                ad.url ?
                  <a href={`${ad.url}`} target="_blank" className={props.classes.mediaLink}>
                    <img className={props.classes.mediaImage} src={ad.image} />
                  </a>
                  :
                  <img className={props.classes.mediaImage} src={ad.image} />
              }

            </div>
          ))
        }
      </div>
    </div>
  );
}


adCarousel.defaultProps = {
  itemWidth: 200,
  itemMargin: 10,
  itemHeight: 360,
};

const styles = theme => ({
  cardRootContainer: {
    position: 'relative',
    maxWidth: '100%',
    overflowX: 'scroll',
    backgroundColor: 'whitesmoke',
  },
  cardScrollContainer: {
    position: 'relative',
    overflowX: 'auto',
    padding: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  media: {
    float: 'left',
  },
  mediaLink: {
    '&:hover': {
      outline: `3px solid ${theme.palette.primary.main}`
    },
    '&:focus': {
      outline: `3px solid ${theme.palette.primary.main}`
    }
  },
  mediaImage: {
    width: '100%',
    border: '1px solid #efefef',
  },
});


export default withStyles(styles)(adCarousel);
import React from 'react';

import { withContext, withProvider } from 'kn-react';
import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';

import { withStyles } from '@material-ui/core';

import ProductCompletionPage from 'SharedSetup/ProductCompletionPage/ProductCompletionPage';
import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import CompanyReviewsProfileProvider, { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import CompanySocialProfileProvider, { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';

import adsNextSteps from './AdsNextSteps';


class AdsSetupCompletion extends React.Component {
  state = {
    nextSteps: []
  }

  componentDidMount = () => {
    this.setState({
      nextSteps: adsNextSteps(this.props)
    })
  }

  render(){
    const { classes } = this.props;


    const knowledgeBaseArticles = [
      {
        url: `${ this.props.theme.knowledgeBaseUrl || 'https://support.goboost.com/goboost' }/add_blockers_and_goboost`,
        name: 'Turn Off Ad Blocker'
      },
      {
        url: `${ this.props.theme.knowledgeBaseUrl || 'https://support.goboost.com/goboost' }/what_can_i_expect_from_goboost_ads`,
        name: 'What to Expect from GoBoost Ads'
      }
    ];

    return(
      <ProductCompletionPage
        product={'Ads'}
        dashboardLink={'/ads'}
        nextSteps={this.state.nextSteps}
        knowledgeBaseArticles={knowledgeBaseArticles}
      />
    )
  }
}


const styles = theme => ({})


export default withProvider(
  CompanyReviewsProfileProvider,
  CompanySitesProfileProvider,
  CompanySocialProfileProvider,
  withContext(
    CompanyReviewsProfileContext,
    CompanySitesProfileContext,
    CompanySocialProfileContext,
    FacebookAccountContext,
    CompanyRoleContext,
    PlanContext,
    withStyles(styles, { withTheme: true })(AdsSetupCompletion)
  )
)
import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { log } from 'kn-react';

import SocialDashboardContent from './SocialDashboardContent';
import FeedItemModel from 'Social/FeedItem/FeedItemModel';

import { metricChange, compareToPrior, absoluteChangeFormat } from 'SharedDashboard/sharedDashboard';

import { createStatsChartData, createReactionDistribution, getStatsTotals } from './SocialAggregationHelpers';


const timePeriodOptions = [
  { unit: 'month', label: 'All time' },
  { unit: 'day', numberOfUnits: 7, label: 'Past 7 days' },
  { unit: 'day', numberOfUnits: 30, label: 'Past 30 days' },
  { unit: 'day', numberOfUnits: 90, label: 'Past 90 days' },
  { unit: 'month', numberOfUnits: 12, label: 'Past year' },
];



class SitesDashboard extends React.Component {

  state = {
    // Filters
    timePeriod: timePeriodOptions[2],
    reviewSource: 'all',
    companies: [],

    statsChartData: [],
    statsTotals: {},
    statsChange: {},
    reactions: {}
  }



  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {
    return Axios.all([
      this.fetchCompanyFeedItems(),
      this.fetchUpcomingPosts()
    ]);
  }


  onChangeTimePeriod = timePeriod => {
    this.setState(
      { timePeriod },
      this.fetchData
    );
  }



  onChangeCompanies = companies => {
    this.setState(
      { companies },
      this.fetchData
    );
  }


  getTimePeriodMoment = () => {
    const { numberOfUnits, unit } = this.state.timePeriod;
    return numberOfUnits ? moment().subtract(numberOfUnits, unit) : undefined;
  }


  getStartDate = timePeriodMoment => {
    return timePeriodMoment ? timePeriodMoment.format('YYYY-MM-DD') : undefined;
  }



  dashboardDataRequest = (controllerPath, subPath, extraParams = {}) => {
    const { companies } = this.state;
    const companyIds = companies.map(c => c.id);

    return Axios.get(
      `/api${ controllerPath }/${this.props.roleTypePath}/${this.props.roleTypeId}${subPath}`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          ...extraParams,
          company_ids: companyIds.length ? companyIds : undefined
        }
      }
    )
  }



  fetchCompanyFeedItems = () => {
    const timePeriodMoment = this.getTimePeriodMoment();

    const { timePeriod } = this.state;

    const publishedAtFormat = timePeriod.unit === 'month' ? '%Y-%m-01' : '%Y-%m-%d';

    return this.dashboardDataRequest(
      '/social/company_feed_items',
      '/stats',
      {
        start_date: this.getStartDate(timePeriodMoment),
        published_at_format: publishedAtFormat,
      }
    )
    .then(response => {
      log('fetchCompanyFeedItems response', response);
      const stats = response.data.stats;

      if(!stats.length) {
        this.setState({
          statsChartData: [],
          statsTotals: {},
          statsChange: {},
          reactions: {}
        });
        return;
      }


      // Chart Data
      const statsChartData = createStatsChartData(stats, timePeriod);


      // Totals
      const statsTotals = getStatsTotals(stats);


      // Reactions
      const reactions = createReactionDistribution(stats);


      // Change
      if(!timePeriodMoment) {
        this.setState({
          statsChartData,
          statsTotals,
          reactions,
          statsChange: {},
        });
        return;
      }


      const { numberOfUnits, unit } = this.state.timePeriod;
      const priorStartDate = timePeriodMoment.clone().subtract(numberOfUnits, unit).format('YYYY-MM-DD');
      const priorEndDate = timePeriodMoment.clone().subtract(1, 'day').format('YYYY-MM-DD');

      return this.dashboardDataRequest(
        '/social/company_feed_items',
        '/stats',
        {
          start_date: priorStartDate,
          end_date: priorEndDate,
          published_at_format: publishedAtFormat,
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;
        const priorRequestStats = getStatsTotals( response.data.stats );

        const impressionsChange = compareToPrior(
          statsTotals.totalImpressions,
          priorRequestStats.totalImpressions,
          'impressions',
          priorPeriodName,
          absoluteChangeFormat
        );

        const uniqueImpressionsChange = compareToPrior(
          statsTotals.totalUniqueImpressions,
          priorRequestStats.totalUniqueImpressions,
          'unique impressions',
          priorPeriodName,
          absoluteChangeFormat
        );

        const clicksChange = compareToPrior(
          statsTotals.totalClicks,
          priorRequestStats.totalClicks,
          'clicks',
          priorPeriodName,
          absoluteChangeFormat
        );

        const likesChange = compareToPrior(
          statsTotals.totalLikes,
          priorRequestStats.totalLikes,
          'likes',
          priorPeriodName,
          absoluteChangeFormat
        );


        const statsChange = {
          impressionsChange: metricChange(impressionsChange),
          uniqueImpressionsChange: metricChange(uniqueImpressionsChange),
          clicksChange: metricChange(clicksChange),
          likesChange: metricChange(likesChange),
        };


        this.setState({
          statsChartData,
          statsTotals,
          reactions,
          statsChange
        });
      });
    });
  }


  fetchUpcomingPosts = () => {
    return Axios.get(
      '/api/social/feed_items',
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      const feedItems = response.data.feed_items.filter(
        f =>  moment( f.post_at ).isAfter( new Date() )
      ).sort(
        (a, b) => d3.ascending(moment(a.post_at), moment(b.post_at))
      ).slice(0, 3).map(
        f => FeedItemModel.fromJSON( f )
      );

      this.setState({ feedItems });
    })
  }



  render(){
    return (
      <SocialDashboardContent
        dataAsOfDate={this.props.dataAsOfDate}
        onChangeCompanies={this.onChangeCompanies}
        companies={this.state.companies}
        timePeriod={this.state.timePeriod}
        timePeriodOptions={timePeriodOptions}
        onChangeTimePeriod={this.onChangeTimePeriod}
        roleTypePath={this.props.roleTypePath}
        roleTypeId={this.props.roleTypeId}
        getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
        isSearchCompaniesVisible={this.props.isSearchCompaniesVisible}
        statsChartData={this.state.statsChartData}
        statsTotals={this.state.statsTotals}
        statsChange={this.state.statsChange}
        reactions={this.state.reactions}
        feedItems={this.state.feedItems}
      />
    );
  }

}

SitesDashboard.propsTypes = {
  roleTypePath: PropTypes.string.isRequired,
  roleTypeId: PropTypes.number.isRequired,
  getUserRoleAuthHeaders: PropTypes.func.isRequired,
};

SitesDashboard.defaultProps = {
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  isSearchCompaniesVisible: false,
};


export default SitesDashboard;
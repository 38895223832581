import React from 'react';
import Axios from 'axios';

import { log, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, OrganizationRoleContext } from 'go-boost-library-react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import OrganizationAutosuggest from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/OrganizationAutosuggest/OrganizationAutosuggest';

class NewCompanyOrganizationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          organization: this.props.organization,
          organizations: [],
          loading: true,
          organizationName: ''
        }
      }

  componentDidMount = () => {
    this.getPossibleOrganizations();
  }

  getPossibleOrganizations = () => {
    const { organization } = this.props;
    log('getPossibleOrganizations', organization.id);

    return Axios.get(
      `/api/core/organizations/${organization.id}/family`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('getPossibleOrganizations response', response);
      const organizations = response.data.family;
      this.setState({ organizations, loading: false });
    });
  }


  onClose = () => {
    this.setState({ email: '', roleId: '' }, this.props.onClose);
  }

  createNewCompanyOrganization = () => {
    if( this.state.organizationName === this.state.organization.name ){
      return this.props.createNewCompanyOrganization(
        this.state.organization.id
      );
    }

    this.props.showSnackbar('Please select an organization.');

    return Promise.resolve();
  }


  render(){
    const { classes } = this.props;
    const { organizations, organization } = this.state;


    return (
      <Dialog open={this.props.open} fullWidth maxWidth="sm" classes={{ paper: classes.dialogPaper }}>
        <DialogTitle id="form-dialog-title">Associated a Company with a New Organization</DialogTitle>
          <DialogContent classes={{ root: classes.dialogContentRoot }}>
            <DialogContentText>
              Please enter the new organization this company will belong to.
            </DialogContentText>

            <OrganizationAutosuggest
              organizationName={this.state.organizationName}
              onChangeInputValue={organizationName => this.setState({ organizationName })}
              onSuggestionSelected={organization => this.setState({ organization, organizationName: organization.name })}
              organizationId={this.props.organization.id}
              style={{ marginTop: 5 }}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="outlined" color="primary">
            Cancel
          </Button>

          <PromiseButton onProcess={this.createNewCompanyOrganization}>
            Create
          </PromiseButton>
        </DialogActions>

      </Dialog>
    );
  }

}


NewCompanyOrganizationDialog.defaultProps = {
    organization: ''
}


const styles = theme => ({
  dialogPaper: {
    overflowY: 'initial',
  },
  dialogContentRoot: {
    overflowY: 'initial',
  },
});

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    SnackbarContext,
    NewCompanyOrganizationDialog
  )
);
import React from 'react';
import { withContext } from 'kn-react';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import ProductPricingTable from 'SharedSetup/ProductPlanSetup/ProductPricingTable';
import ProductPlanSetup from 'SharedSetup/ProductPlanSetup/ProductPlanSetup';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import AdsUndoResubscriptionAlert from 'Ads/CompanyAds/AdsUndoResubscriptionAlert/AdsUndoResubscriptionAlert';

class AdsPlanSetup extends React.Component {
  componentDidMount() {
    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'started_ads_plan_step', this.props.getUserRoleAuthHeaders);
  }

  render() {
    return (
        <ProductPricingTable
        {...this.props}
        subscribedProductPlan={this.props.subscribedAdsPlan}
        productPlans={this.props.upgradableAdsPlans}
        productName="Ads"
        productUndoResubscriptionAlert={AdsUndoResubscriptionAlert}
      />
    );
  }
}

export default withContext(TransitionContext, AdsPlanSetup);
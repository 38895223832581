import React from 'react';
import { MaxWidth, log } from 'kn-react';
import { withStyles, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import paperPadding from 'paperPadding';

const errorPage = props => {
  log('props', props);
  const { location: { state } } = props;
  log('state', state);

  return (
    <div className={props.classes.errorRoot}>
      <MaxWidth maxWidth={400}>
        <Error className={props.classes.errorIcon} />
        <Typography variant="h5" color="primary">{props.errorMessage || 'Something went wrong!'}</Typography>
        <Typography variant="caption" paragraph>Contact us for assistance.</Typography>
        <Typography variant="caption" color="textSecondary">
          Error { state.errorStatus || 'Unknown'}{ state.errorMessage ? `: ${state.errorMessage}` : null}
        </Typography>

      </MaxWidth>
    </div>
  );
}

const styles = theme => ({
  errorRoot: {
    padding: paperPadding,
    marginTop: 100,
    textAlign: 'center',
  },
  errorIcon: {
    fontSize: 100,
  },
});

export default withStyles(styles)(errorPage);
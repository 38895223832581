import React from "react";
import { withContext } from 'kn-react';

import { withStyles, Grid } from "@material-ui/core";

import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';

const BIG_MAX_HEIGHT = 256;
const SMALL_MAX_HEIGHT = 170;


const photoPreview = props => {
  const { classes } = props;

  const photo = (mediaItemId, size, maxHeight, key) => {
    const mediaItem = props.mediaItems.find(
      m => m.id === mediaItemId
    );

    if( !mediaItem ){
      return(<></>)
    }


    return(
      <Grid
        item
        xs={size}
        style={{
          maxHeight,
          textAlign: 'center',
          // paddingTop: 1,
          // paddingBottom: 1,
        }}
        key={key}
      >
        <img
          src={mediaItem.url}
          style={{
            maxWidth: '100%',
            maxHeight,
          }}/>
      </Grid>
    )
  }

  const preview = () => {
    const { attachments } = props;

    switch (attachments.length) {
      case 0:
        return

      case 1:
        return photo(attachments[0], 12, BIG_MAX_HEIGHT)

      case 2:
        return attachments.map(
          (a, i) => photo(a, 6, BIG_MAX_HEIGHT, i)
        )

      case 3:
        return (
          <>
            { photo(attachments[0], 6, BIG_MAX_HEIGHT, 0) }

            <Grid item xs={6} style={{ maxHeight: BIG_MAX_HEIGHT }}>
              {
                attachments.slice(1).map(
                  (a, i) => photo(a, 12, Math.floor( BIG_MAX_HEIGHT/2 ), i+1)
                )
              }
            </Grid>
          </>
        )

      case 4:
        return attachments.map(
          (a, i) => photo(a, 6, BIG_MAX_HEIGHT, i)
        )

      case 5:
        return (
          <>
            {
              attachments.slice(0,2).map(
                (a, i) => photo(a, 6, BIG_MAX_HEIGHT, i+1)
              )
            }

            {
              attachments.slice(2).map(
                (a, i) => photo(a, 4, SMALL_MAX_HEIGHT, i+1)
              )
            }
          </>
        )

      default:
        return (
          <>
            {
              attachments.slice(0,2).map(
                (a, i) => photo(a, 6, BIG_MAX_HEIGHT, i+1)
              )
            }

            {
              attachments.slice(2, 4).map(
                (a, i) => photo(a, 4, SMALL_MAX_HEIGHT, i+1)
              )
            }

            <Grid item xs={4} className={classes.opaqueDiv}>
              { photo(attachments[4], 12, SMALL_MAX_HEIGHT, 6) }

              <p className={classes.numberOverlay}>+{ attachments.length-5 }</p>
            </Grid>
          </>
        )
    }
  }

  const photos = props.attachments.length;
  const height = photos >= 5 ? BIG_MAX_HEIGHT+SMALL_MAX_HEIGHT
      : photos === 4 ? BIG_MAX_HEIGHT*2
        : photos > 0 ? BIG_MAX_HEIGHT : 0
  ;

  return (
    <div className={classes.container} style={{ height }}>
      <Grid container spacing={8}>
        {
          preview()
        }
      </Grid>
    </div>
  );
};



const styles = theme => ({
  container: {
    maxWidth: '375px',
    borderTop: '0.5px solid #dedede',
  },
  imageWrapper: {
    display: 'block',
    width: '100%',
    height: '0',
    paddingTop: '51.25%',
    borderBottom: '0.5px solid #dedede',
  },
  info: {
    padding: 15,
    backgroundColor: '#f2f3f5',
  },
  siteName: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#616770',
    fontFamily: '"Roboto", sans-serif',
  },
  title: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontSize: '15px',
    fontFamily: '"Roboto", sans-serif',
    color: '#1d2129',
    marginTop: '3px',
  },
  description: {
    display: 'block',
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '13px',
    color: '#616770',
    marginTop: '3px',
  },
  opaqueDiv: {
    opacity: 0.5,
    position: 'relative'
  },
  numberOverlay: {
    color: 'white',
    top: '40%',
    left: '40%',
    position: 'absolute',
    margin: 0,
    fontSize: 32,
  }
})

photoPreview.defaultProps = {
  ogImageUrl: '',
  ogUrl: '',
  ogTitle: '',
  ogDescription: ''
}

export default withContext(
  MediaItemsContext,
  withStyles(styles)(
    photoPreview
  )
)
import { JsonModel } from 'kn-react';

export default class PlanBoostedServiceModel {

  static fromJSON(json){
    let model = new PlanBoostedServiceModel();
    return JsonModel.modelFromJSON(model, json);
  }


  toJSON(){
    return JsonModel.modelToJSON(this);
  }

}

import React from 'react';

import { IconButton, SvgIcon } from '@material-ui/core';


const noIcon = props => (
  <IconButton
    aria-label="No Icon"
    style={{ backgroundColor: '#F7F6F6' }}
    disabled
  >
    <SvgIcon
      viewBox="0 0 50 50"
      style={{ fontSize: props.fontSize }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 48.5L25 31L43 48.5L48.5 43L31 25L48.5 7L43 1.5L25 19L7 1.5L1.5 7L19.5 25L1.5 43L7 48.5Z"
        stroke={props.fill}
      />
    </SvgIcon>
  </IconButton>
);


noIcon.defaultProps = {
  fill: '#5E5F64'
}


export default noIcon;
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { MaxWidth, withContext } from 'kn-react';
import { KnowledgeBaseButton } from 'go-boost-library-react';
import React from 'react';
import SiteDesignList from './SiteDesignList/SiteDesignList';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import { getSiteLink } from 'Sites/siteDesignPreviewHelpers';

import hashIdCoder from 'hashIdCoder';


class SiteDesigns extends React.Component {

  onClickCreateSite = siteDesign => {
    let companySiteDesign;

    return this.props.findOrCreateCompanySiteDesign(siteDesign.id)
      .then(_companySiteDesign => {
        companySiteDesign = _companySiteDesign;
        this.props.transitionTo('editSurvey', this.props.transitions, { siteDesignId: siteDesign.id });
      })
      .then(() => this.props.duplicateSite(siteDesign.baseSiteId, companySiteDesign.id))
      .then(this.props.fetchCompanySiteDesigns);
  }

  onClickEditSettings = siteDesign => {
    return this.props.transitionTo('editSurvey', this.props.transitions, { siteDesignId: siteDesign.id });
  }

  onClickLaunchSite = siteDesign => {
    let companySiteDesign;

    return this.props.findOrCreateCompanySiteDesign(siteDesign.id)
      .then(_companySiteDesign => {
        companySiteDesign = _companySiteDesign;

        if( companySiteDesign.siteId ){
          return Promise.resolve( companySiteDesign.siteId );
        }

        return this.props.duplicateSite(
          siteDesign.baseSiteId,
          companySiteDesign.id
        ).then(site => site.id);
      }).then(siteId => this.props.updateCompanySitesProfile({
        companySiteDesignId: companySiteDesign.id,
        testLink: `https://site-${hashIdCoder.encode( siteId )}-preview.goboost.xyz`
      })).then(this.props.fetchCompanySiteDesigns);
  }

  getSiteLink = siteDesign => {
    const companySiteDesign = this.props.companySiteDesigns.find(
      d => d.siteDesignId === siteDesign.id
    );

    return getSiteLink(companySiteDesign.siteId)
  }

  render() {
    const { classes, siteDesigns, companySitesProfile, companySiteDesigns, subscribedSitesPlan } = this.props;

    let activeSiteDesign;
    let subscribedPlanSiteDesigns = [];
    const inactiveSiteDesigns = [];
    const availableSiteDesigns = [];


    if( subscribedSitesPlan ){
      subscribedPlanSiteDesigns = this.props.filterSiteDesignsByPlanId(subscribedSitesPlan.id);
    }

    siteDesigns.forEach(d => {
      const companySiteDesign = companySiteDesigns.find(cd => cd.siteDesignId === d.id);

      if( companySiteDesign && companySiteDesign.id === companySitesProfile.companySiteDesignId ){
        activeSiteDesign = d;
      } else if ( companySiteDesign ){
        inactiveSiteDesigns.push( d );
      } else if( subscribedPlanSiteDesigns.find(s => s === d) ) {
        availableSiteDesigns.push( d );
      }
    })

    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>


            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Current Site Design

                &nbsp;

                <KnowledgeBaseButton
                  path={'/recommended-site-changes'}
                  tooltipTitle={'Learn about our recommended Site updates'}
                />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <SiteDesignList
                selectedSiteDesignId={activeSiteDesign ? activeSiteDesign.id : null}
                getPreviewSiteLink={() => companySitesProfile.getSiteLink()}
                availableSiteDesigns={activeSiteDesign ? [activeSiteDesign] : []}
                canLaunchEditor={true}
                onClickEditSettings={this.onClickEditSettings}
              />
            </Grid>


            {
              inactiveSiteDesigns.length ?
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      Created Site Designs

                      &nbsp;

                      <KnowledgeBaseButton
                        path={'/swapping-site-designs'}
                        tooltipTitle={'Learn about switching Site Designs'}
                      />
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <SiteDesignList
                      availableSiteDesigns={inactiveSiteDesigns}
                      getPreviewSiteLink={d => this.getSiteLink(d)}
                      onClickSetup={this.onClickLaunchSite}
                      onClickEditSettings={this.onClickEditSettings}
                      setupText='Launch This Design'
                      canLaunchEditor={true}
                    />
                  </Grid>
                </>
                : null
            }


            {
              availableSiteDesigns.length ?
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      Available Site Designs

                      &nbsp;

                      <KnowledgeBaseButton
                        path={'/swapping-site-designs'}
                        tooltipTitle={'Learn about switching Site Designs'}
                      />
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <SiteDesignList
                      availableSiteDesigns={availableSiteDesigns}
                      onClickSetup={this.onClickCreateSite}
                      setupText='Build this Design'
                    />
                  </Grid>
                </>
                : null
            }


          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: 0
  },
});

export default withStyles(styles)(
  withContext(
    TransitionContext,
    SiteDesigns
  )
);
import React from 'react';
import { withContext, MaxWidth } from 'kn-react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, TextField, withStyles, Button, Typography, Paper } from '@material-ui/core';
import { SnackbarContext } from 'go-boost-library-react';

import FileUpload from './FileUpload/FileUpload';
import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';
import history from 'history.js';

class UploadMediaItem extends React.Component {

  state = {
    file: null,
    name: ''
  };


  onUpload = () => {
    if(!this.state.name){
      return this.props.showSnackbar('Please enter a name for the Media Item.')
    }

    return Promise.resolve().then(
      () => this.props.showSnackbar('Your Media Item is being uploaded. You will be notified once the upload is complete.', { autoHide: false })
    ).then(
      this.props.onNavigate
    ).then(
      () => this.props.createMediaItem(this.state.file, this.state.name)
    ).then(
      this.props.fetchMediaItems
    ).then(
      () => this.props.showSnackbar(`Your ${this.state.name} Media Item is done uploading.`, { autoHide: false })
    )
  }



  render(){
    const { classes } = this.props;

    return (
      <Paper>
        <MaxWidth maxWidth={1200} style={{ padding: 8 }}>
          <Grid container spacing={8} className={classes.root}>
            <Grid item xs={6}>
              <Typography variant='h6' color='primary'>
                Upload Media Item
              </Typography>
            </Grid>

            <Grid item xs={6} style={{ textAlign: 'right' }}>
              { this.props.cancelButton }

              <Button
                onClick={this.onUpload}
                variant='contained'
                color='primary'
              >
                Upload
              </Button>
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <FileUpload
                onChangeFile={file => this.setState({ file })}
              />

              <TextField
                fullWidth
                variant='outlined'
                label='Uploaded File'
                value={this.state.file ? this.state.file.name : ''}
                disabled
                helperText='Click the + to upload a new file.'
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                label='Name'
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                onBlur={e => this.setState({ name: e.target.value.trim() })}
              />
            </Grid>
          </Grid>
        </MaxWidth>
      </Paper>
    );
  }

}

const styles = {
  root: {
    padding: 15
  }
};


UploadMediaItem.defaultProps = {
  onNavigate: () => history.push(`/social/media_items`)
}


export default withContext(
  SnackbarContext,
  MediaItemsContext,
  withStyles(styles)(
    UploadMediaItem
  )
);
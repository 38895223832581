import React from 'react';
import Axios from 'axios';
import Moment from 'moment';
import urlParser from 'url';


import './FeedItem.css';

import { MaxWidth, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, UserRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';

import { Grid, Typography, withStyles, TextField, MenuItem, InputLabel, FormGroup, Paper } from '@material-ui/core';
import { SingleDatePicker } from 'react-dates';

import LinkStatusFeedItemForm from 'Social/FeedItem/LinkStatusFeedItemForm';
import PhotoFeedItemForm from 'Social/FeedItem/PhotoFeedItemForm';
import VideoFeedItemForm from 'Social/FeedItem/VideoFeedItemForm';
import FeedItemPreview from 'Social/FeedItem/FeedItemPreview';
import HalfHourIncrements from './HalfHourIncrements';


const FEED_TYPES = [
  'Link',
  'Status',
  'Photo',
  'Video'
]

class FeedItem extends React.Component {
  state = {
    feedItem: {
      feedType: FEED_TYPES[0],
      text: '',
      url: '',
      attachments: [],
      postAt: '',
      meta: null
    },
    loadingMeta: false,
    postAtFocused: false
  }



  componentDidMount = () => {
    if( this.props.feedItem && Object.keys(this.props.feedItem).length ){
      this.setState({
        feedItem: this.props.feedItem
      });
    } else{
      this.setState({
        feedItem: {
          ...this.state.feedItem,
          postAt: Moment().hour( Moment().hour() + 1 ).minute(0).second(0).format()
        }
      });
    }
  }


  componentDidUpdate = oldProps => {
    if( this.props.feedItem !== oldProps.feedItem && this.props.feedItem && Object.keys(this.props.feedItem).length ){
      this.setState({
        feedItem: this.props.feedItem
      })
    }
  }



  onBlurLinkStatusFormUrl = (text, url) => {
    return this.setState(
      { loadingMeta: true},
      () => this.onBlurLinkStatusForm(text, url)
    )
  }



  onBlurLinkStatusForm = (text, url) => {
    let preprocessFunction = url === this.state.feedItem.url ?
        () => Promise.resolve('')
      :
        () => this.processGraphScraper(url)
    ;

    return preprocessFunction().then(linkMeta => {
      this.setState({
        feedItem: {
          ...this.state.feedItem,
          text,
          url,
          linkMeta: linkMeta ? linkMeta : this.state.feedItem.linkMeta
        }
      })
    })
  }



  onBlurPhotoForm = (text, attachments) => {
    return this.setState({
      feedItem: {
        ...this.state.feedItem,
        text,
        attachments
      }
    });
  }



  onBlurVideoForm = (text, attachments) => {
    return this.setState({
      feedItem: {
        ...this.state.feedItem,
        text,
        attachments
      }
    });
  }



  onUpdatePostAt = (dateTime, part) => {
    const postAt = Moment(this.state.feedItem.postAt);

    if( part === 'date' ){
      postAt.dayOfYear( dateTime.dayOfYear() );
      postAt.year( dateTime.year() );
    } else {
      postAt.hour( dateTime.hour() );
      postAt.minute( dateTime.minute() );
    }

    return this.setState({
      feedItem: {
        ...this.state.feedItem,
        postAt: postAt.format()
      }
    });
  }



  processGraphScraper = url => {
    if( !url ){
      this.setState({ loadingMeta: false });

      return Promise.reject('No URL found in text.');
    }


    let linkMeta;
    return Axios.post(
      '/api/social/open_graph_scraper/parse',
      { url }
    ).then(response => {
      linkMeta = response.data.results;

      if (linkMeta) {
        let images = linkMeta.ogImage;

        if (images instanceof Array) {
          linkMeta.ogImage = images[0];
        }

        let domain = linkMeta.ogUrl;

        if (domain) {
          linkMeta.ogUrl = urlParser.parse(url).host;
        }
      }
    }).catch(error => {
      linkMeta = null;
    }).finally(() => {
      this.setState({
        feedItem: {
          ...this.state.feedItem,
          linkMeta
        },
        loadingMeta: false
      });

      return linkMeta
    })
  }



  onChangeFeedType = e => {
    return this.setState({
      feedItem: {
        ...this.state.feedItem,
        feedType: e.target.value,
        attachments: []
      }
    });
  }



  fetchPostAtTime = () => {
    const postAt = Moment( this.state.feedItem.postAt );

    return postAt.format('h:mm A');
  }


  onProcess = () => {
    if( !this.state.feedItem.text ){
      this.props.showSnackbar('Feed text is required.')
      return Promise.reject('text')
    } else if( !this.state.feedItem.postAt ){
      this.props.showSnackbar('Feed post date and time are required.')
      return Promise.reject('postAt')
    } else if ( Moment( this.state.feedItem.postAt ).isBefore( Moment().add(30, 'minutes') ) ){
      this.props.showSnackbar('Feed post date and time must be at least 30 minutes in the future.')
      return Promise.reject('postAt')
    }

    return this.props.onProcess(this.state.feedItem)
  }



  render() {
    const { classes } = this.props;

    const Preview = FeedItemPreview(this.state.feedItem.feedType);
    const Post = <Preview
      text={this.state.feedItem.text}
      url={this.state.feedItem.url}
      meta={this.state.feedItem.linkMeta}
      loading={this.state.loadingMeta}
      attachments={this.state.feedItem.attachments}
      feedType={this.state.feedItem.feedType}
    />;


    let Form;
    if( FEED_TYPES.slice(0,2).includes(this.state.feedItem.feedType) ){
      Form = (
        <LinkStatusFeedItemForm
          feedItem={this.state.feedItem}
          feedType={this.state.feedItem.feedType}
          onBlurText={this.onBlurLinkStatusForm}
          onBlurLink={this.onBlurLinkStatusFormUrl}
        />);
    } else if( FEED_TYPES.slice(2,3).includes(this.state.feedItem.feedType) ){
      Form = (
        <PhotoFeedItemForm
          feedItem={this.state.feedItem}
          onBlur={this.onBlurPhotoForm}
        />
      );
    } else if( FEED_TYPES.slice(3).includes(this.state.feedItem.feedType) ){
      Form = (
        <VideoFeedItemForm
          feedItem={this.state.feedItem}
          onBlur={this.onBlurVideoForm}
        />
      );
    }



    return (
      <MaxWidth maxWidth={1000}>
        <Paper>
          <div className={classes.root}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  { this.props.actionText } Feed Item

                  {
                    !this.props.currentUserRole.isCompanyRole() ?
                      null
                    :
                      <>
                        &nbsp;

                        <KnowledgeBaseButton
                          path={'/editing_goboost_social_feed_posts'}
                          tooltipTitle={'Learn about customizing Feed Posts'}
                        />
                      </>
                  }
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Feed Type'
                  select
                  value={this.state.feedItem.feedType}
                  onChange={this.onChangeFeedType}
                  disabled={this.props.feedTypeDisabled}
                >
                  {
                    FEED_TYPES.map(
                      (f, i) => <MenuItem value={f} key={i}>{f}</MenuItem>
                    )
                  }
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.previewContainer}>
                { Post }
              </Grid>

              <Grid item xs={12} sm={6}>
                { Form }
              </Grid>

              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel
                    htmlForm="datetime-picker"
                  >
                    Post At Date
                  </InputLabel>

                  <SingleDatePicker
                    date={Moment(this.state.feedItem.postAt)}
                    onDateChange={date => this.onUpdatePostAt(date, 'date')}
                    focused={this.state.postAtFocused}
                    onFocusChange={({ focused }) => this.setState({ postAtFocused: focused })}
                    id="datetime-picker"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel
                    htmlForm="datetime-picker"
                  >
                    Post At Time
                  </InputLabel>

                  <TextField
                    fullWidth
                    select
                    value={this.fetchPostAtTime()}
                    onChange={e => this.onUpdatePostAt(Moment(`1/1/2020 ${e.target.value}`), 'time')}
                    variant="outlined"
                  >
                    {
                      HalfHourIncrements.map(
                        (f, i) => <MenuItem value={f} key={i}>{f}</MenuItem>
                      )
                    }
                  </TextField>
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <PromiseButton
                  variant='contained'
                  color='primary'
                  onProcess={this.onProcess}
                >
                  {this.props.actionText}
                </PromiseButton>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </MaxWidth>
    );
  }
}

const styles = theme => ({
  root: {
    padding: 16,
  },
  previewContainer: {
    textAlign: 'center'
  },
  dateTimePicker: {
    width: '100%',
    padding: '18.5px 14px',
    border: '1px solid #bbb',
    borderRadius: 2,
    height: 56
  }
});


FeedItem.defaultProps = {
  feedItem: {},
  actionText: 'Create',
  feedTypeDisabled: false,
  onProcess: () => Promise.resolve(''),
}


export default withContext(
  UserRoleContext,
  SnackbarContext,
  withStyles(styles)(FeedItem)
)
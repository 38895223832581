import React from 'react';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import SitesDashboard from 'Sites/SitesDashboard/SitesDashboard';

const sitesDashboard = props => (
  <SitesDashboard
    roleTypePath="organizations"
    roleTypeId={props.organization.id}
    currentUserRole={props.currentUserRole}
    getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
    isSearchCompaniesVisible={true}
  />
);

export default withContext(
  OrganizationRoleContext,
  sitesDashboard
);
import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, withStyles, MenuItem, Typography } from '@material-ui/core';




class ReorderStepDialog extends React.Component {
  state = {
    index: 0
  }

  componentDidMount = () => {
    return this.constructState();
  }


  componentDidUpdate = oldProps => {
    if( this.props.open && oldProps.open !== this.props.open ){
      return this.constructState();
    }
  }


  constructState = () => {
    return this.setState({
      index: this.props.index || 0
    });
  }


  render(){
    const { classes } = this.props;

    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          Reorder Step
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Typography variant='body1' className={classes.positionLabel}>
            Current Position: { String( this.props.index >= 0 ? this.props.index + 1 : 0 ) }
          </Typography>

          <TextField
            select
            required
            fullWidth
            label='Position'
            value={this.state.index}
            onChange={e => this.setState({ index: e.target.value })}
            variant='outlined'
            className={classes.textField}
          >
            {
              ( this.props.steps || [] ).map(
                (_, i) => (
                  <MenuItem value={i} key={i}>{ String( i + 1 ) }</MenuItem>
                )
              )
            }
          </TextField>
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={this.props.onClose}
            color='primary'
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            onClick={() => this.props.onUpdateStep( this.state.index )}
            color='primary'
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}


ReorderStepDialog.defaultProps = {
  open: false,
  index: 0,
  steps: [],
  onClose: () => {},
  onUpdateStep: () => {}
}


const styles = theme => ({
  dialogContent: {
    paddingTop: 10
  },
  textField: {
    marginBottom: 30
  },
  cancelButton: {
    marginRight: 15
  },
  positionLabel: {
    marginBottom: 25
  }
})


export default withStyles(styles)(
  ReorderStepDialog
)
import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';

import { withContext, log } from 'kn-react';

import ReviewsDashboardContent from './ReviewsDashboardContent';
import { ReviewSourcesContext } from 'Reviews/ReviewSourcesProvider'
import ReviewModel from 'Reviews/ReviewModel';
import { compareToPrior, metricChange, getPriorTimePeriod, absoluteChangeFormat, decimalChangeFormat, fillMissingMoments } from 'SharedDashboard/sharedDashboard';


const timePeriodOptions = [
  { unit: 'month', label: 'All time' },
  { unit: 'day', numberOfUnits: 7, label: 'Past 7 days' },
  { unit: 'day', numberOfUnits: 30, label: 'Past 30 days' },
  { unit: 'day', numberOfUnits: 90, label: 'Past 90 days' },
  { unit: 'month', numberOfUnits: 12, label: 'Past year' },
];

const chartFormats = {
  day: 'MMMM D',
  month: 'MMM YYYY',
};


class ReviewsDashboard extends React.Component {

  state = {
    // Filters
    timePeriod: timePeriodOptions[0],
    reviewSource: 'all',
    companies: [],

    ratingAverage: '',
    ratingAverageChange: ' ',
    ratingAverageChartData: [],

    ratingDistribution: [],
    reviewSourceDistribution: [],

    totalReviews: '',
    totalReviewsChange: '',

    responseCount: '',
    responseCountChange: '',

    totalRequests: '',
    totalRequestsChange: '',
    requestsChartData: [],

    recentReviews: [],
  }

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {
    return Axios.all([
      this.fetchReviewStats(),
      this.fetchReviewsPublishedAtDistribution(),
      this.fetchRequestStats(),
      this.fetchRequestsCreatedAtDistribution(),
      this.fetchReviewSourceDistribution(),
      this.fetchRecentReviews(),
      this.props.fetchRelevantReviewSources(),
    ]);
  }


  fetchReviewStats = () => {
    const timePeriodMoment = this.getTimePeriodMoment();

    return this.dashboardDataRequest(
      '/reviews',
      '/stats',
      { start_date: this.getStartDate(timePeriodMoment) }
    )
    .then(response => {
      log('fetchReviewStats response', response);
      const reviewStats = response.data.stats;
      const ratingAverage = (reviewStats.rating_average || 0).toFixed(2);
      const totalReviews = reviewStats.count || 0;
      const responseCount = reviewStats.is_responded_to_sum;
      const ratingDistribution = [1,2,3,4,5].map(r => (
        { rating: r, count: reviewStats[`r${r}`] }
      ));

      this.setState({
        ratingAverage,
        totalReviews,
        responseCount,
        ratingDistribution,
      });


      if(!timePeriodMoment) {
        this.setState({
          ratingAverageChange: ' ',
          totalReviewsChange: ' ',
          responseCountChange: ' ',
        });
        return;
      }

      const { numberOfUnits, unit } = this.state.timePeriod;
      const priorTimePeriod = getPriorTimePeriod(timePeriodMoment, numberOfUnits, unit);

      return this.dashboardDataRequest(
        '/reviews',
        '/stats',
        {
          start_date: priorTimePeriod[0],
          end_date: priorTimePeriod[1]
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;
        const priorReviewStats = response.data.stats;


        const ratingAverageChange = compareToPrior(
          ratingAverage,
          priorReviewStats.rating_average,
          'reviews',
          priorPeriodName,
          decimalChangeFormat
        );


        const totalReviewsChange = compareToPrior(
          totalReviews,
          priorReviewStats.count,
          'reviews',
          priorPeriodName,
          absoluteChangeFormat
        );

        log('responseCount', responseCount);
        log('priorReviewStats', priorReviewStats);
        const responseCountChange = compareToPrior(
          responseCount,
          priorReviewStats.is_responded_to_sum,
          'review replies',
          priorPeriodName,
          absoluteChangeFormat
        );


        this.setState({
          ratingAverageChange: metricChange(ratingAverageChange),
          totalReviewsChange: metricChange(totalReviewsChange),
          responseCountChange: metricChange(responseCountChange)
         });
      });
    });

  }


  dashboardDataRequest = (controllerPath, subPath, extraParams = {}) => {
    const { reviewSource, companies } = this.state;
    const companyIds = companies.map(c => c.id);

    return Axios.get(
      `/api${ controllerPath }/${this.props.roleTypePath}/${this.props.roleTypeId}${subPath}`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          ...extraParams,
          review_source_id: (reviewSource === 'all') ? reviewSource : reviewSource.id,
          company_ids: companyIds.length ? companyIds : undefined
        }
      }
    )
  }


  getTimePeriodMoment = () => {
    const { numberOfUnits, unit } = this.state.timePeriod;
    return numberOfUnits ? moment().subtract(numberOfUnits, unit) : undefined;
  }


  getStartDate = timePeriodMoment => {
    return timePeriodMoment ? timePeriodMoment.format('YYYY-MM-DD') : undefined;
  }


  fetchReviewsPublishedAtDistribution = () => {
    const timePeriodMoment = this.getTimePeriodMoment();

    const { timePeriod } = this.state;
    const publishedAtFormat = timePeriod.unit === 'month' ? '%Y-%m-01' : '%Y-%m-%d';

    return this.dashboardDataRequest(
        '/reviews',
        '/published_at_distribution',
        {
          published_at_format: publishedAtFormat,
          start_date: this.getStartDate(timePeriodMoment)
        }
      )
      .then(response => {
        log('fetchReviewsPublishedAtDistribution response', response);
        const publishedAtDistribution = response.data.published_at_distribution;


        if(!publishedAtDistribution.length) {
          this.setState({ ratingAverageChartData: [] });
          return;
        }



        const endMoment = moment();
        let startMoment;
        if (timePeriod.numberOfUnits) {
          startMoment = endMoment.clone().subtract(timePeriod.numberOfUnits, timePeriod.unit);
        } else {
          startMoment = moment(publishedAtDistribution.slice(-1)[0].published_at);
        }


        let ratingsData = fillMissingMoments(
          publishedAtDistribution,
          startMoment,
          endMoment,
          timePeriod.unit,
          d => d.published_at,
          (m, d = {
            count: 0,
            rating_average: 0,
            cumulative_sum: 0,
            cumulative_count: 0,
            is_responded_to_sum: 0,
            is_responded_to_positive: 0,
            is_responded_to_neutral: 0,
            is_responded_to_negative: 0,
            r1: 0,
            r2: 0,
            r3: 0,
            r4: 0,
            r5: 0,
          }) => ({
            Count: d.count,
            Rating: d.rating_average,
            'Overall Rating': isNaN(d.cumulative_sum / d.cumulative_count) ? null : d.cumulative_sum / d.cumulative_count,
            'Response Rate': isNaN(d.is_responded_to_sum / d.count) ? 0 : d.is_responded_to_sum / d.count,
            'Positive': d.is_responded_to_positive,
            'Neutral': d.is_responded_to_neutral,
            'Negative': d.is_responded_to_negative,
            'r1': d.r1, '1': 1,
            'r2': d.r2, '2': 2,
            'r3': d.r3, '3': 3,
            'r4': d.r4, '4': 4,
            'r5': d.r5, '5': 5,
            name: m.format(chartFormats[timePeriod.unit]),
          })
        );


        ratingsData.forEach((d,i) => {
          if (i < 1) return;
          d['Overall Rating'] = d['Overall Rating'] || ratingsData[i-1]['Overall Rating']
        })

        let ratingAverageChartData = ratingsData;
        if(timePeriod.numberOfUnits) {
          ratingAverageChartData = ratingAverageChartData.slice(-timePeriod.numberOfUnits);
        }
        log('ratingAverageChartData', ratingAverageChartData);
        this.setState({ ratingAverageChartData });
      });
  }




  fetchRequestStats = () => {
    const timePeriodMoment = this.getTimePeriodMoment();

    return this.dashboardDataRequest(
      '/reviews/review_requests',
      '/stats',
      { start_date: this.getStartDate(timePeriodMoment) }
    )
    .then(response => {
      log('fetchRequestStats response', response);
      const requestStats = response.data.stats;
      const totalRequests = requestStats.count || 0;
      const openedRequests = requestStats.is_clicked_sum || 0;


      this.setState({
        requestStats,
        totalRequests,
        openedRequests,
      });


      if(!timePeriodMoment) {
        this.setState({
          totalRequestsChange: ' ',
        });
        return;
      }


      const { numberOfUnits, unit } = this.state.timePeriod;
      const priorStartDate = timePeriodMoment.clone().subtract(numberOfUnits, unit).format('YYYY-MM-DD');
      const priorEndDate = timePeriodMoment.clone().subtract(1, 'day').format('YYYY-MM-DD');

      return this.dashboardDataRequest(
        '/reviews/review_requests',
        '/stats',
        {
          start_date: priorStartDate,
          end_date: priorEndDate
        }
      )
      .then(response => {
        const priorPeriodName = `prior ${ numberOfUnits } ${ unit }s`;
        const priorRequestStats = response.data.stats;

        const totalRequestsChange = compareToPrior(
          totalRequests,
          priorRequestStats.count,
          'requests',
          priorPeriodName,
          absoluteChangeFormat
        );

        this.setState({
          totalRequestsChange: metricChange(totalRequestsChange),
        });
      });
    });

  }




  fetchRequestsCreatedAtDistribution = () => {
    const timePeriodMoment = this.getTimePeriodMoment();

    const { timePeriod } = this.state;
    const createdAtFormat = timePeriod.unit === 'month' ? '%Y-%m-01' : '%Y-%m-%d';

    return this.dashboardDataRequest(
        '/reviews/review_requests',
        '/created_at_distribution',
        {
          created_at_format: createdAtFormat,
          start_date: this.getStartDate(timePeriodMoment)
        }
      )
      .then(response => {
        log('fetchRequestsCreatedAtDistribution response', response);
        const createdAtDistribution = response.data.created_at_distribution;

        if(!createdAtDistribution.length) {
          this.setState({ requestsChartData: [] });
          return;
        }


        const endMoment = moment();
        let startMoment;
        if (timePeriod.numberOfUnits) {
          startMoment = endMoment.clone().subtract(timePeriod.numberOfUnits, timePeriod.unit);
        } else {
          startMoment = moment(createdAtDistribution.slice(-1)[0].formatted_created_at);
        }

        let requestsData = fillMissingMoments(
          createdAtDistribution,
          startMoment,
          endMoment,
          timePeriod.unit,
          d => d.formatted_created_at,
          (m, d = {
            count: 0,
            is_clicked_to_sum: 0,
          }) => ({
            'Review Requests': d.count,
            'Opened Requests': d.is_clicked_sum,
            name: m.format(chartFormats[timePeriod.unit]),
          })
        );

        let requestsChartData = requestsData;
        if(timePeriod.numberOfUnits) {
          requestsChartData = requestsChartData.slice(-timePeriod.numberOfUnits);
        }

        this.setState({ requestsChartData });
      });
  }


  fetchReviewSourceDistribution = () => {
    const timePeriodMoment = this.getTimePeriodMoment();

    return this.dashboardDataRequest(
      '/reviews',
      '/review_source_distribution',
      { start_date: this.getStartDate(timePeriodMoment) }
    )
      .then(response => {
        log('fetchReviewSourceDistribution response', response);
        const reviewSourceDistribution = response.data.review_source_distribution;
        this.setState({ reviewSourceDistribution });
      });
  }

  fetchRecentReviews = () => {
    const timePeriodMoment = this.getTimePeriodMoment();
    return this.dashboardDataRequest(
      '/reviews',
      '',
      { limit: 5, offset: 0, start_date: this.getStartDate(timePeriodMoment) }
    )
      .then(response => {
        log('fetchRecentReviews response', response);
        const recentReviews = response.data.reviews.map(r => ReviewModel.fromJSON(r))
        this.setState({ recentReviews });
      });
  }


  onChangeTimePeriod = timePeriod => {
    this.setState(
      { timePeriod },
      this.fetchData
    );
  }

  onChangeReviewSource = reviewSource => {
    this.setState(
      { reviewSource },
      this.fetchData
    );
  }


  onChangeCompanies = companies => {
    this.setState(
      { companies },
      this.fetchData
    );
  }


  render() {
    return (
      <>
        <ReviewsDashboardContent
          currentUserRole={this.props.currentUserRole}
          roleTypePath={this.props.roleTypePath}
          roleTypeId={this.props.roleTypeId}
          isSearchCompaniesVisible={this.props.isSearchCompaniesVisible}

          timePeriodOptions={timePeriodOptions}
          timePeriod={this.state.timePeriod}
          onChangeTimePeriod={this.onChangeTimePeriod}

          reviewSources={this.props.reviewSources}
          reviewSource={this.state.reviewSource}
          onChangeReviewSource={this.onChangeReviewSource}

          ratingAverage={this.state.ratingAverage}
          ratingAverageChange={this.state.ratingAverageChange}
          ratingAverageChartData={this.state.ratingAverageChartData}

          totalReviews={this.state.totalReviews}
          totalReviewsChange={this.state.totalReviewsChange}

          responseCount={this.state.responseCount}
          responseCountChange={this.state.responseCountChange}

          totalRequests={this.state.totalRequests}
          totalRequestsChange={this.state.totalRequestsChange}
          requestsChartData={this.state.requestsChartData}

          ratingDistribution={this.state.ratingDistribution}
          recentReviews={this.state.recentReviews}

          reviewSourceDistribution={this.state.reviewSourceDistribution}

          onChangeCompanies={this.onChangeCompanies}
          getUserRoleAuthHeaders= {this.props.getUserRoleAuthHeaders}

          googleAccountPresent = {this.props.googleAccountPresent}
          facebookAccountPresent = {this.props.facebookAccountPresent}
        />
      </>
    );
  }

}


ReviewsDashboard.propsTypes = {
  roleTypePath: PropTypes.string.isRequired,
  roleTypeId: PropTypes.number.isRequired,
  getUserRoleAuthHeaders: PropTypes.func.isRequired,
};


export default withContext(ReviewSourcesContext, ReviewsDashboard);
import React from 'react';
import UserTable from '../../UserTable/UserTable';



class OrganizationUserCard extends React.Component {

  render() {
    const { organization, showDescendantsInfo, showViewAllUsers } = this.props;

    return (
      <UserTable
        userRolesUrl={`/api/core/organizations/${organization.id}/user_roles`}
        roleTypeId={organization.id}
        roleTypeName={organization.name || ''}
        showDescendantsInfo={ showDescendantsInfo }
        showViewAllUsers={ showViewAllUsers }
      />
    );
  }

}

OrganizationUserCard.defaultProps = {
	showDescendantsInfo: false,
  showViewAllUsers: false
}

export default OrganizationUserCard;

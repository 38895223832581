import React from 'react';
import { PromiseButton } from 'go-boost-library-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, TextField, withStyles } from '@material-ui/core';
import OrganizationAutosuggest from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/OrganizationAutosuggest/OrganizationAutosuggest';


class EditExternalDataDialog extends React.Component  {
  state = {
    externalData: ''
  }

  componentDidUpdate = oldProps => {
    if( oldProps !== this.props ){
      return this.setState({
        externalData: (
          !this.props.externalData ?
            ''
          :
           JSON.stringify(
            this.props.externalData,
            null,
            2
          )
        )
      });
    }
  }


  isValidExternalDataJson = () => {
    try{
      return JSON.parse( this.state.externalData ) && true;
    } catch {
      // Props change, even in the callback of setState in this.onChangeExternalData.
      // Therefore, use a window alert to notify of error
      window.alert('Please enter a valid External Data JSON.');

      return;
    }
  }


  onSubmit = () => {
    if( !this.isValidExternalDataJson() ){
      return Promise.reject();
    }

    return this.props.onSubmit(
      JSON.parse( this.state.externalData )
    );
  }


  render(){
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle>
          Edit External Data
        </DialogTitle>

        <DialogContent>
          <TextField
            multiline
            fullWidth
            value={this.state.externalData}
            onChange={e => this.setState({ externalData: e.target.value })}
            variant='outlined'
            InputProps={{
              style: { fontSize: 15 }
            }}
            className={classes.code}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            onClick={this.props.onClose}
            style={{ float: 'left' }}
          >
            Close
          </Button>

          <PromiseButton
            variant='contained'
            color='primary'
            onProcess={() => this.onSubmit()}
          >
            Edit
          </PromiseButton>
        </DialogActions>
      </Dialog>
    )
  }
}


const styles = theme => ({
  code: {
    backgroundColor: '#eee',
    marginTop: 5
  }
})


EditExternalDataDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => Promise.reject()
}


export default withStyles(styles)(
  EditExternalDataDialog
)
import React from 'react';

import { isDevelopment, isStaging, isDemo } from 'go-boost-library-react';
import hashIdCoder from 'hashIdCoder';



export default site => {
  if( !site || !site.id ){
    return '';
  }

  const siteModelHashId = hashIdCoder.encode(
    site.id
  );

  let basePath;

  if( isDevelopment() ) {
    basePath = `http://site-${siteModelHashId}-preview.localhost:2048`;
  }
  else if( isStaging() ) {
    basePath = `https://site-${siteModelHashId}-preview.staging.goboost.xyz`;
  }
  else if( isDemo() ) {
    basePath = `https://site-${siteModelHashId}-preview.demo.goboost.xyz`;
  }
  else {
    basePath = `https://site-${siteModelHashId}-preview.goboost.xyz`;
  }

  if( !basePath ){
    return '';
  }


  return `${ basePath }/?retrieve_feedback=true`;
}
import React from 'react';
import { withContext } from 'kn-react';

import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import AdsUndoResubscriptionAlert from 'Ads/CompanyAds/AdsUndoResubscriptionAlert/AdsUndoResubscriptionAlert';
import Survey from 'SharedSetup/Survey/Survey';


const surveySetup = props => {
  const { id } = props.match.params;
  const { boostedServices } = props;
  const boostedService = boostedServices.find(b => b.id == id);

  const companyAdsProfile = props.companyAdsProfile || {};

  return (
    <Survey
      {...props}
      surveyId={boostedService.surveyId}
      surveyTitle={`${boostedService.name} Setup`}
      onSubmit={() => props.transitionTo('next', props.transitions, { boostedServiceId: boostedService.id })}
      canSubmitSurvey={companyAdsProfile.isShutdown ? !companyAdsProfile.isSetupComplete : true}
      productUndoResubscriptionAlert={AdsUndoResubscriptionAlert}
    />
  );

};

export default withContext(TransitionContext, surveySetup);

import React from 'react';
import Axios from 'axios';

import { withContext } from 'kn-react';
import { withStyles, IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { SnackbarContext, UserRoleContext, NoteTable as LibraryNoteTable } from 'go-boost-library-react';


import { VirtualFormDesignContext } from 'Virtual/CompanyVirtual/VirtualFormDesigns/VirtualFormDesignProvider';
import NoteModel from './NoteModel';
import NoteDialog from './NoteDialog';




class VirtualSettings extends React.Component {
  state = {
    isLoading: true,
    isOpen: false,
    notes: [],
    total: 0,
    page: 0,
    rowsPerPage: 5,
  }


  componentDidMount = () => {
    return this.fetchNotes();
  }


  componentDidUpdate = oldProps => {
    if(
      ( this.props.noteSource && oldProps.noteSource != this.props.noteSource ) ||
      ( this.props.noteSourceId && oldProps.noteSourceId != this.props.noteSourceId )
    ){
      return this.setState(
        { isLoading: true },
        this.fetchNotes
      )
    }
  }



  fetchNotes = () => {
    return Axios.get(
      `/api/core/notes`,
      {
        params: {
          role_type: this.props.roleType,
          role_type_id: this.props.roleTypeId,
          note_source: this.props.noteSource,
          note_source_id: this.props.noteSourceId,
          page: this.state.page,
          limit: this.state.rowsPerPage
        },
        headers: this.props.getUserRoleAuthHeaders()
      }
    ).then(response => {
      const notes = response.data.notes.map(
        n => NoteModel.fromJSON( n )
      );

      const total = response.data.total;

      return this.setState({
        notes,
        total,
        isLoading: false
      });
    })
  }


  onAddNote = content => {
    return Axios.post(
      `/api/core/notes`,
      {
        role_type: this.props.roleType,
        role_type_id: this.props.roleTypeId,
        note_source: this.props.noteSource,
        note_source_id: this.props.noteSourceId,
        content
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(() => {
      this.props.showSnackbar('Note successfully created.');

      return this.setState(
        { isOpen: false },
        this.fetchNotes
      );
    });
  }


  changePagination = newState => {
    return this.setState(
      {
        ...newState,
        isLoading: true,
      },
      this.fetchNotes
    )
  }



  render() {
    const { classes } = this.props;

    return (
      <>
        <LibraryNoteTable
          total={this.state.total}
          notes={this.state.notes}
          isLoading={this.state.isLoading}
          secondaryActions={(
            <Tooltip title={'Add Note'}>
              <IconButton
                onClick={() => this.setState({ isOpen: true })}
              >
                <Add />
              </IconButton>
            </Tooltip>
          )}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          changePagination={this.changePagination}
          rowsPerPageOptions={this.props.rowsPerPageOptions}
        />

        <NoteDialog
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          onAddNote={this.onAddNote}
        />
      </>
    );
  }
}


VirtualSettings.defaultProps = {
  roleType: undefined,
  roleTypeId: undefined,
  noteSource: undefined,
  noteSourceId: undefined,
  rowsPerPageOptions: [5, 10, 25, 50]
}


const styles = theme => ({
});

export default withContext(
  VirtualFormDesignContext,
  SnackbarContext,
  UserRoleContext,
  withStyles(styles)(
    VirtualSettings
  )
);
import React from 'react';
import { withContext } from 'kn-react';

import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import CompanyServiceArea from 'Settings/Companies/CompanyServiceArea/CompanyServiceArea';



const companyServiceArea = props => (
  <CompanyServiceArea
    isShutdown={props.companyVirtualProfile && props.companyVirtualProfile.isShutdown}
  />
);



export default withContext(
  CompanyVirtualProfileContext,
  companyServiceArea
);
import React from 'react';
import Axios from 'axios';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import { ChannelContext } from 'Channels/ChannelProvider';
import BoostedServiceModel from '../../BoostedServiceModel';

const DEFAULT_STATE = {
  loadingServices: true,
  // boostedServices: [],
  organizationBoostedServices: [],
};


export const OrganizationBoostedServicesContext = React.createContext(DEFAULT_STATE);


class OrganizationBoostedServicesProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }


  fetchData = () => {
    this.fetchBoostedServices()
      .finally(() => this.setState({ loadingServices: false }));
  }

  fetchBoostedServices = () => {
    return Axios.get(
        `/api/ads/boosted_services/organizations/${this.props.organization.id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      )
      .then(response => {
        log('fetchBoostedServices', response);
        const boostedServices = response.data.boosted_services.map(b => (
          BoostedServiceModel.fromJSON(b)
        ))
        this.setState({ boostedServices });
      });
  }


  render(){
    const { loadingServices } = this.state;

    if(loadingServices) {
      return <LinearProgress/>;
    }

    return (
      <OrganizationBoostedServicesContext.Provider
        value={{
          boostedServices: this.state.boostedServices,
        }}
      >
        { this.props.children }
      </OrganizationBoostedServicesContext.Provider>
    );
  }
}


export default withContext(OrganizationRoleContext, ChannelContext, OrganizationBoostedServicesProvider);
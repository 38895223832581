import React from 'react';
import { withContext } from 'kn-react';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import ProductPricingTable from 'SharedSetup/ProductPlanSetup/ProductPricingTable';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import ReviewsUndoResubscriptionAlert from 'Reviews/CompanyReviews/ReviewsUndoResubscriptionAlert/ReviewsUndoResubscriptionAlert';
class ReviewsPlanSetup extends React.Component {

  componentDidMount() {
    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'started_reviews_plan_step', this.props.getUserRoleAuthHeaders);
  }

  render() {
    return (
      <ProductPricingTable
        {...this.props}
        subscribedProductPlan={this.props.subscribedReviewsPlan}
        productName="Reviews"
        productPlans={this.props.upgradableReviewsPlans}
        productUndoResubscriptionAlert={ReviewsUndoResubscriptionAlert}
      />
    );
  }
}

export default withContext(TransitionContext, ReviewsPlanSetup);

import React from 'react';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import Opportunity from 'Opportunity/Opportunity';


const companyJob = props => {
  return (
    <Opportunity
      opportunityId={props.match.params.id}
      defaultAddress={`${ props.company.addressLineOne }, ${ props.company.city }, ${ props.company.state } ${ props.company.postalCode }`}
      canEdit={props.companySitesProfile && !props.companySitesProfile.isShutdown}
    />
  )
}


export default withContext(
  CompanySitesProfileContext,
  CompanyRoleContext,
  companyJob
)
import React from 'react';
import { withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';

import AppNavigation from 'AppNavigation/AppNavigation';
import CompanyUsers from './CompanyUsers/CompanyUsers';
import OrganizationUsers from './OrganizationUsers/OrganizationUsers';


const users = props => (
  <AppNavigation title="Users">
    { props.currentUserRole.isCompanyRole() ? <CompanyUsers/> : <OrganizationUsers /> }
  </AppNavigation>
);



export default withContext(UserRoleContext, users);

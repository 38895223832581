import React from 'react';


class MediaItemPreview extends React.Component {

  state = {
    mediaItem: ''
  }


  componentDidMount = () => {
    if(this.props.mediaItem) {
      this.setState({ mediaItem: this.props.mediaItem });
    }
  }


  componentDidUpdate = oldProps => {
     if (this.props.mediaItem !== oldProps.mediaItem) {
      this.setState({ mediaItem: this.props.mediaItem });
    }
  }


  constructNode = () => {
    const { mediaItem } = this.state;

    if( !mediaItem || !mediaItem.mimeType ){
      return null;
    }
    else if( mediaItem.mimeType.includes('image') ){
      return (
        <img src={mediaItem.url} />
      );
    }
    else if( mediaItem.mimeType.includes('video') ){
      return (
        <video controls={true}>
          <source src={mediaItem.url} type={mediaItem.mimeType}/>
          Your browser does not support HTML5 video.
        </video>
      );
    }
    else if( mediaItem.mimeType.includes('audio') ){
      return (
        <audio controls={true}>
          <source src={mediaItem.url} type={mediaItem.mimeType}/>
          Your browser does not support the audio element.
        </audio>
      );
    }
    else if( mediaItem.mimeType.includes('application') ){
      return (
        <a href={`http://localhost:8080/api/core/media_items/${ mediaItem.id }/download`}>
          {mediaItem.name}
        </a>
      );

    }
  }


  render(){
    return this.constructNode();
  }

}



export default MediaItemPreview

import React from 'react';

import { Alert, isDevelopment, Ability, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log, MaxWidth } from 'kn-react';

import { Edit, OpenInNew } from '@material-ui/icons';
import { Typography, Grid, withStyles, Toolbar, Tooltip, IconButton, Card, CardHeader } from '@material-ui/core';

import { getSiteLink } from 'Sites/siteDesignPreviewHelpers';
import { SiteDesignsContext } from '../SiteDesignsProvider';
import AdCarousel from 'Ads/CompanyAds/BoostedServices/BoostedServicesList/AdCarousel/AdCarousel';
import paperPadding from 'paperPadding';

class SiteDesignList extends React.Component {

  getSiteEditorUrl = siteDesign => {
    let siteBuilderUrl;

    if( !siteDesign.baseSiteId ){
      return window.alert('No Site Found.')
    }

    if( isDevelopment() ){
      siteBuilderUrl = window.location.origin.replace(':3000',':3001');
    } else {
      siteBuilderUrl = window.location.origin + '/site_builder';
    }


    return siteBuilderUrl + `/sites/${siteDesign.baseSiteId}`;

  }


  render() {
    const { classes } = this.props;
    const { siteDesigns } = this.props;

    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>

          <Grid item xs={12}>
            {
              siteDesigns.length ?

                siteDesigns.sort(
                  (a, b) => b.createdAt - a.createdAt
                ).map(d => {
                  let carousel;
                  if (d.assetData && d.assetData.carousel) {
                    carousel = d.assetData.carousel;
                  }


                  return (
                    <Grid item xs={12} key={d.id} style={{ paddingBottom: 15 }}>
                      <Card>

                        <CardHeader
                          className={classes.cardHeader}
                          disableTypography
                          title={
                            <Typography variant="h6">{d.name}</Typography>
                          }
                          subheader=""
                          avatar={null}
                          action={
                            <Toolbar disableGutters>
                              {
                                this.props.trueUserRole.roleHasAbility( Ability.EDIT_SITES_ORGANIZATION_SITE_DESIGN ) ?
                                  <Tooltip title="Edit Site Design" enterDelay={500}>
                                    <IconButton component='a' href={this.getSiteEditorUrl(d)}>
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                :
                                  null
                              }

                              <Tooltip title="Visit Site" enterDelay={500}>
                                <IconButton component="a" href={getSiteLink(d.baseSiteId)} target="_blank">
                                  <OpenInNew />
                                </IconButton>
                              </Tooltip>
                            </Toolbar>
                          }
                        />

                        {
                          carousel ?
                            <AdCarousel data={carousel} itemWidth={265} />
                            : null
                        }
                      </Card>
                    </Grid>
                  );
                })
              :
                <Alert warning>No site designs found for your selected plan. Please contact us for assistance.</Alert>
            }

          </Grid>

        </Grid>
      </MaxWidth>
    );
  }

}


SiteDesignList.defaultProps = {
  showBack: true,
  siteDesigns: [],
  canLaunchSite: true
};


const styles = theme => ({
  paper: {
    padding: paperPadding
  },
  cardHeader: {
    borderBottom: '1px solid #efefef',
  },
});


export default withContext(
  OrganizationRoleContext,
  SiteDesignsContext,
  withStyles(styles)(
    SiteDesignList
  )
);
import { JsonModel } from 'kn-react';

export default class SiteModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    const model = new SiteModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

}

import React from 'react';
import moment from 'moment';
import { withContext } from 'kn-react';
import { ReviewListItem, UserRoleContext } from 'go-boost-library-react';
import { List } from '@material-ui/core';
import { ReviewSourcesContext } from 'Reviews/ReviewSourcesProvider';

class RecentReviewsList extends React.Component {
	render() {
		const { reviews, respondableReviewSourceIds, currentUserRole } = this.props;

		return (
			<List>
				{
					reviews.map(r => {
						const timeDifference = moment().diff(moment(r.publishedAt),'hours');
						const time = (timeDifference < 24) ? `${timeDifference} hours ago` : moment(r.publishedAt).format('MMM D, YYYY');

						const content = r.content || '';
						r.content = (content.length > 250) ? content.slice(0,250).concat('...') : content;

						const reviewSource = this.props.getReviewSourceById(r.reviewSourceId);

						return (
							<ReviewListItem
								key={r.id}
								to={`/reviews/${r.id}`}
								review={r}
								time={time}
								icon={(reviewSource) ? reviewSource.getAvatar() : null}
								limitText={true}
								isRespondable={ respondableReviewSourceIds.includes(r.reviewSourceId) }
								showCompanyName={currentUserRole.roleType === 'Organization'}
							/>
						)
					})
				}
			</List>
		)
	}
}

RecentReviewsList.defaultProps = {
	reviews: [],
};

export default withContext(
	ReviewSourcesContext,
	UserRoleContext,
	RecentReviewsList
);
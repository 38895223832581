import React from 'react';
import Axios from 'axios';
import Moment from 'moment';

import { SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import CompanyFeedItemModel from './CompanyFeedItemModel';
import FeedItemModel from 'Social/FeedItem/FeedItemModel';
import FeedItemPreview from 'Social/FeedItem/FeedItemPreview';
import history from 'history.js';
import SocialTimeline from 'Social/Timeline/SocialTimeline';

class FeedTimeline extends React.Component {
  state = {
    feedItems: [],
    companyFeedItems: [],
    isLoading: false
  }


  componentDidMount = () => {
    return this.fetchData();
  }



  fetchData = () => (
    this.setState(
      { isLoading: true },
      () => this.fetchFeedItems()
        .then(
          feedItems => this.fetchCompanyFeedItemsForFeedItemIds(
            feedItems.map(f => +f.id)
          )
        )
        .finally(() => this.setState({ isLoading: false }))
    )
  )



  fetchFeedItems = () => {
    return Axios.get(
      `/api/social/feed_items/feeds/${this.props.match.params.feedId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const feedItems = response.data.feed_items.map(
        f => FeedItemModel.fromJSON(f)
      );

      Object.keys(response.data.statistics).forEach(
        feedItemId => {
          feedItems.find(
            f => f.id == feedItemId
          )['stats'] = FeedItemModel.fromJSON( response.data.statistics[ feedItemId ]);
        }
      );

      this.setState({ feedItems });

      return feedItems;
    })
  }


  fetchCompanyFeedItemsForFeedItemIds = feedItemIds => {
    return Axios.get(
      `/api/social/company_feed_items/companies/${this.props.company.id}/distribution`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const companyFeedItems = response.data.distribution.map(
        f => CompanyFeedItemModel.fromJSON(f)
      ).filter(c => feedItemIds.includes( +c.feedItemId ) );

      this.setState({ companyFeedItems });
    })
  }


  timelineContent = feedItem => {
    const Preview = FeedItemPreview(feedItem.feedType);

    return <Preview
      text={feedItem.feedContent.text}
      url={feedItem.feedContent.url}
      meta={feedItem.feedContent.linkMeta}
      attachments={feedItem.feedContent.attachments}
      feedType={feedItem.feedContent.feedType}
      stats={feedItem.stats}
    />
  }

  onDeleteItem = feedItem => {
    const { getUserRoleAuthHeaders } = this.props;
    let deleteFunction;

    if( !feedItem.baseFeedItemId && window.confirm('Are you sure you do not want to post this Feed Item?') ){
      deleteFunction = () => Axios.post(
        `/api/social/feed_items/feeds/${feedItem.feedId}`,
        {
          feed_item: feedItem,
          should_post: false
        },
        { headers: getUserRoleAuthHeaders() }
      );
    } else if( window.confirm('Are you sure you want to delete this Feed Item? This action cannot be undone.') ){
      deleteFunction = () => Axios.delete(
        `/api/social/feed_items/${feedItem.id}`,
        { headers: this.props.getUserRoleAuthHeaders() }
      )
    }

    return deleteFunction().then(response => {
      return this.fetchFeedItems()
    })
  }


  fetchEditLink = feedItem => {
    const { getUserRoleAuthHeaders } = this.props;

    if( feedItem.baseFeedItemId ){
      return `/social/feed_items/${feedItem.id}/edit`
    }

    return function() {
      if( window.confirm('Are you sure you want to edit this Feed Item?') ){
        return Axios.post(
          `/api/social/feed_items/feeds/${feedItem.feedId}`,
          {
            feed_item: feedItem,
            should_post: true
          },
          { headers: getUserRoleAuthHeaders() }
        ).then(response => history.push(`/social/feed_items/${response.data.feed_item.id}/edit`))
      }
    }
  }


  deleteTooltipTextGetter = feedItem => {
    if( feedItem.baseFeedItemId ){
      return 'Delete Feed Item'
    } else{
      return `Don't Post Feed Item`
    }
  }


  onPostItem = feedItem => {
    const createFeedItem = feedItem.roleType === 'Organization' && this.props.currentUserRole.isCompanyRole() ?
        () => Axios.post(
          `/api/social/feed_items/feeds/${feedItem.feedId}`,
          {
            feed_item: feedItem,
            should_post: true,
            post_at: Moment()
          },
          { headers: this.props.getUserRoleAuthHeaders() }
        )
      :
        () => Promise.resolve({ data: { feed_item : feedItem } })
    ;

    return createFeedItem().then(response => {
      return Axios.post(
        `/api/social/feed_items/${response.data.feed_item.id}`,
        { company_id: this.props.company.id },
        { headers: this.props.getUserRoleAuthHeaders() }
      )
    }).then(
      this.fetchData
    ).then(
      () => this.props.showSnackbar('Feed Item will be live soon!')
    );
  }


  onRestoreItem = feedItem => {
    return Axios.put(
      `/api/social/feed_items/${ feedItem.id }`,
      {
        feed_item: {
          ...feedItem.feedContent
        },
        post_at: Moment.utc(feedItem.postAt).format().slice(0,-1),
        should_post: true
      },
      { headers: this.props.getUserRoleAuthHeaders() },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(this.fetchFeedItems)
  }

  isFeedItemPosted = feedItem => {
    const companyFeedItem = this.state.companyFeedItems.find(
      c => +c.feedItemId === +feedItem.id
    );

    return Boolean( companyFeedItem )
  }


  render() {
    const isShutdown = this.props.companySocialProfile && this.props.companySocialProfile.isShutdown;

    return (
      <SocialTimeline
        items={this.state.feedItems}
        timelineContent={this.timelineContent}
        fetchEditLink={feedItem => this.fetchEditLink(feedItem)}
        onDeleteItem={this.onDeleteItem}
        deleteTooltipTextGetter={this.deleteTooltipTextGetter}
        onPostItem={this.onPostItem}
        onRestoreItem={this.onRestoreItem}
        showEditButton={isShutdown ? false : true}
        showDeleteButton={isShutdown ? false : true}
        showPostButton={isShutdown ? false : true}
        showViewButtonForItem={this.isFeedItemPosted}
        fetchViewLink={feedItem => `/social/feed_items/${feedItem.id}/view`}
        isFeedItemPosted={this.isFeedItemPosted}
        isLoading={this.state.isLoading}
      />
    );
  }

}

export default withContext(
  CompanySocialProfileContext,
  CompanyRoleContext,
  SnackbarContext,
  FeedTimeline
)
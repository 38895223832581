import React from 'react';
import { withContext, MaxWidth, log } from 'kn-react';
import { Grid, Button, TextField, withStyles, MenuItem, Typography, Paper } from '@material-ui/core';
import { MediaItemsContext } from 'MediaItems/MediaItemsProvider';
import { SnackbarContext, PromiseButton, UserRoleContext } from 'go-boost-library-react';
import FileUpload from './FileUpload/FileUpload';
import history from 'history.js';
import { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';


class EditMediaItem extends React.Component {

  state = {
    mediaItem: {},
    file: null,
    name: '',
    isDirty: false,
  };

  componentDidMount = () => {
    const mediaItem = this.props.mediaItems.find(
      m => m.id == this.props.match.params.id
    );

    log('mediaItem', mediaItem)
    this.setState({ mediaItem, name: mediaItem.name });
  }



  onCancel = e => {
    this.setState({ name: this.state.mediaItem.name, file: null, isDirty: false });
  }


  onSave = e => {
    if(!this.state.name){
      return this.props.showSnackbar('Name is required.')
    } else if ( !this.isOwnedByEntity() ){
      return;
    }

    return this.props.updateMediaItem(this.state.mediaItem.id, this.state.file, this.state.name)
    .then(mediaItem => {
      this.setState({ mediaItem, isDirty: false});
    }).then(() => this.props.showSnackbar('Media Item successfully updated.'))
  }


  onDelete = e => {
    if( !window.confirm('Are you sure you want to delete this Media Item?') ){
      return Promise.resolve('')
    }

    return this.props.deleteMediaItem(this.state.mediaItem.id)
    .then( this.props.fetchMediaItems )
    .then(() => {
      this.props.showSnackbar('Media Item successfully deleted.');

      return history.push(
        `/social/media_items`
      )
    })
  }


  isOwnedByEntity = () => (
    this.state.mediaItem.roleType == this.props.currentUserRole.roleType && this.state.mediaItem.roleTypeId == this.props.currentUserRole.roleTypeId
  )



  render() {
    const { classes } = this.props;
    const isShutdown = this.props.companySocialProfile && this.props.companySocialProfile.isShutdown;

    return (
      <Paper>
        <MaxWidth maxWidth={1200}>
          <Grid container spacing={16} className={classes.root}>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={this.onCancel}
                variant='outlined'
                color='primary'
                style={{ marginRight: 15 }}
                disabled={!this.state.isDirty || !this.isOwnedByEntity() || isShutdown}
              >
                Cancel
              </Button>

              <PromiseButton
                onProcess={this.onDelete}
                buttonProps={{
                  variant: 'contained',
                  color: 'secondary',
                  disabled: !this.isOwnedByEntity()
                }}
                buttonStyles={{
                  marginRight: 15
                }}
              >
                Delete
              </PromiseButton>

              <PromiseButton
                onProcess={this.onSave}
                buttonProps={{
                  variant: 'contained',
                  color: 'primary',
                  disabled: !this.isOwnedByEntity() || isShutdown
                }}
              >
                Save
              </PromiseButton>
            </Grid>

            <Grid item xs={12}>
              <div style={{textAlign: 'center'}}>
                <FileUpload
                  onChangeFile={file => this.setState({ file, isDirty: true })}
                  mediaItem={ this.state.file ? '' : this.state.mediaItem }
                  disabled={!this.isOwnedByEntity()}
                />
              </div>
            </Grid>

            {
              this.state.file ?
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    value={this.state.file.name}
                    name='file-name'
                    id='file-name'
                    label='Uploaded File Name'
                    variant='outlined'
                    fullWidth
                    disabled
                  />
                </Grid>
              :
                null
            }

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value, isDirty: true })}
                onBlur={e => this.setState({ name: e.target.value.trim() })}
                name='name'
                id='name'
                label='Name'
                variant='outlined'
                fullWidth
                disabled={!this.isOwnedByEntity()}
              />
            </Grid>
          </Grid>
        </MaxWidth>
      </Paper>
    );
  }

}

EditMediaItem.defaultProps = {
  mediaItems: []
};

const styles = {
  root: {
    padding: 15
  },
  textField: {
    paddingBottom: 15
  }
};


export default withStyles(styles)(
  withContext(
    CompanySocialProfileContext,
    UserRoleContext,
    MediaItemsContext,
    SnackbarContext,
    EditMediaItem
  )
);
import React from 'react';

import { OrganizationRoleContext, SnackbarContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import FilterIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import CompanyFilterTable from 'Settings/Companies/CompanyTable/CompanyFilterTable';



class CompanyAutosuggestDialog extends React.Component {
  state = {
    companies: [],
    dialogOpen: false,
  }

  onClose = () => {
    this.setState(
      { dialogOpen: false },
      () => this.props.onChangeCompanies(this.state.companies)
    );

  }

  onClickTextField = e => {
    // force blur so user does not enter text in this text field
    e.preventDefault();
    e.target.blur();
    this.setState({ dialogOpen:true });
  }

  render(){
    const { classes } = this.props;

    const companyCount = this.state.companies.length;
    const fieldValue = `${companyCount ? companyCount : 'All'} Compan${ companyCount === 1 ? 'y' : 'ies' }`;


    return (
      <form onSubmit={this.onClickTextField}>
        <TextField
          fullWidth
          onClick={this.onClickTextField}
          onSubmit={this.onClickTextField}
          onFo={this.onClickTextField}
          value={fieldValue}
          label='Companies'
        />

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.onClose}
          classes={{
            paper: classes.paper
          }}
          fullWidth
          maxWidth={'lg'}
        >
          <CompanyFilterTable
            actions={
              <Tooltip
                title={'Apply Filter'}
              >
                <IconButton
                  onClick={this.onClose}
                  color="outlined"
                  color="primary"
                >
                  <FilterIcon/>
                </IconButton>
              </Tooltip>
            }
            initialCompanies={this.props.initialCompanies}
            organization={this.props.organization}
            getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
            onCompaniesChange={companies => this.setState({ companies })}
          />

        </Dialog>
      </form>
    );
  }

}

const styles = theme => ({
  paper: {
    // height: '90%',
  },
});

CompanyAutosuggestDialog.defaultProps = {
  initialCompanies: [],
  roleTypeId: '',
  onChangeCompanies: () => {}
}

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    SnackbarContext,
    CompanyAutosuggestDialog
  )
);
import React from 'react';

import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import {log, withContext, format } from 'kn-react';
import Axios from 'axios';
import Moment from 'react-moment';


import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import SearchQueryModel from './SearchQueryModel';

class SearchQueries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 0,
      searchQueries: [],
      total: null
    }
  }


  componentDidMount = () => {
    this.fetchSearchQueries();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevState.page !== this.state.page) {
      this.fetchSearchQueries();
    }
  }

  fetchSearchQueries = () => {
    const { page } = this.state;
    return Axios.get(
      '/api/ads/adwords_search_queries',
      {
        params: { page },
        headers: this.props.getUserRoleAuthHeaders()
      }
    )
    .then(response => {
      log('fetchSearchQueries', page, response);
      const { search_queries, total } = response.data;
      this.setState({
        searchQueries: search_queries.map(q => SearchQueryModel.fromJSON(q)),
        loading: false,
        total,
      });
      const top = window.document.getElementById('top');
      if(top) top.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest',  });
    });
  }

  toggleRelevance = (searchQuery, index) => {
    let isRelevant;

    if(searchQuery.isRelevant === null){
      isRelevant = 1;
    } else if (searchQuery.isRelevant == 1){
      isRelevant = 0;
    } else {
      isRelevant = null;
    }

    Axios.post(
      `/api/ads/adwords_search_queries/${ searchQuery.id }`,
      {
        is_relevant: isRelevant,
        reviewed_by_user_id: isRelevant === null ? null : this.props.trueUserId,
        reviewed_at: isRelevant === null ? null : new Date(),
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('toggleRelevance', response);
      const newSearchQueries = [...this.state.searchQueries];
      newSearchQueries[index] = SearchQueryModel.fromJSON(response.data.search_query);
      this.setState({ searchQueries: newSearchQueries });
    });
  }


  render() {
    const { classes } = this.props;
    const { searchQueries, total, page } = this.state;

    const trs = [];

    searchQueries.forEach((q,i) => {
      const lastDate = i > 0 ? searchQueries[i - 1].date : null;
      if(lastDate !== q.date) {
        trs.push(
          <TableRow key={q.date.toString()} style={{ backgroundColor: 'whitesmoke', height: 28 }}>
            <TableCell colSpan={7} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              <Moment format="dddd MMMM D, YYYY" date={q.date}/>
            </TableCell>
          </TableRow>
        );
      }

      trs.push(
        <TableRow key={q.id}>
          <TableCell component="th" scope="row">
            {q.searchQuery}
          </TableCell>
          <TableCell>{q.keyword}</TableCell>
          <TableCell style={{ minWidth: 85 }}>{format.phone(q.adwordsAccountId)}</TableCell>

          <TableCell align="right">{q.conversions}</TableCell>
          <TableCell align="right">{q.clicks}</TableCell>
          <TableCell align="right">{format.money(q.cost / 1e6)}</TableCell>
          <TableCell>
            <IconButton
              onClick={() => this.toggleRelevance(q,i)}
            >
              {
                q.isRelevant === null ?
                  <RadioButtonUncheckedIcon/>
                :
                  <RadioButtonCheckedIcon style={{ color: q.isRelevant ? '#106BA7' : '#BB4B15' }}/>
              }
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });


    return (
      <div>
        <div id="top" style={{ position: 'absolute', top: -64 }}/>

          <Paper>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Search Query</TableCell>
                <TableCell>Keyword</TableCell>
                <TableCell>Account</TableCell>
                <TableCell align="right">Conversions</TableCell>
                <TableCell align="right">Clicks</TableCell>
                <TableCell align="right">Cost</TableCell>
                <TableCell>Relevant</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trs}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={total || 0}
            rowsPerPage={50}
            page={page}
            onChangePage={(e,p) => this.setState({ page:p }) }
            rowsPerPageOptions={[50]}
          />
        </Paper>
      </div>
    );
  }
}

const styles = {
  table: {
    minWidth: 750
  }
};


export default  withStyles(styles)(withContext(UserRoleContext, SnackbarContext,SearchQueries));
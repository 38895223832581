import React from 'react';

import { ServicesColors, MegaphoneIcon, ReviewsStarIcon, PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withProvider, withContext } from 'kn-react';

import { Grid, Typography } from '@material-ui/core';
import { Public as PublicIcon, People as PeopleIcon, Videocam as VideocamIcon } from '@material-ui/icons';

import ActivateProduct from './ActivateProduct/ActivateProduct';
import CompanyAdsProfileProvider, { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import CompanyReviewsProfileProvider, { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import CompanySocialProfileProvider, { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import CompanyVirtualProfileProvider, { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import GoToAds from './GoToAds/GoToAds';
import GoToReviews from './GoToReviews/GoToReviews';
import GoToSites from './GoToSites/GoToSites';
import GoToSocial from './GoToSocial/GoToSocial';
import GoToVirtual from './GoToVirtual/GoToVirtual';



class ActivateOrGoToProduct extends React.Component {


  render() {

    let availableProductCount = 0;
    ['isReviewsAvailable', 'isAdsAvailable', 'isSitesAvailable', 'isSocialAvailable', 'isVirtualAvailable'].forEach(k => {
      availableProductCount += (this.props[k] ? 1 : 0);
    });

    const md = Math.floor( 12 / availableProductCount );

    return (
      <>
        <Grid item xs={12}>
          <Typography paragraph variant="h5">
            Services Available to You
          </Typography>
        </Grid>

        {
          availableProductCount === 5 ?
            <Grid item xs={12} md={1} />
          :
            null
        }

        {
          this.props.isAdsAvailable ?
            <Grid item xs={12} md={md}>
              {
                this.props.isSubscribedToAds && this.props.companyAdsProfile && this.props.companyAdsProfile.isSetupComplete ?
                  <GoToAds />
                  :
                  <ActivateProduct
                    to="/ads"
                    buttonText="Try Ads Platform"
                    backgroundImage="/images/ads_background.png"
                    description="Boost your business online with the Ads platform"
                    icon={MegaphoneIcon}
                    colors={ServicesColors.ads}
                  />

              }
            </Grid>
            : null
        }


{
          this.props.isReviewsAvailable ?
            <Grid item xs={12} md={md}>
              {
                this.props.isSubscribedToReviews && this.props.companyReviewsProfile && this.props.companyReviewsProfile.isSetupComplete ?
                  <GoToReviews />
                  :
                  <ActivateProduct
                    to="/reviews"
                    buttonText="Try Reviews Platform"
                    backgroundImage="/images/reviews_background.png"
                    description="Boost your online reputation with the Reviews platform"
                    icon={ReviewsStarIcon}
                    colors={ServicesColors.reviews}
                  />

              }
            </Grid>
            : null
        }

        {
          this.props.isSitesAvailable ?
            <Grid item xs={12} md={md}>
              {
                this.props.isSubscribedToSites && this.props.companySitesProfile && this.props.companySitesProfile.isSetupComplete ?
                  <GoToSites />
                  :
                  <ActivateProduct
                    to="/sites"
                    buttonText="Try Sites Platform"
                    backgroundImage="/images/sites_background.png"
                    description="Boost your business web presence with the Sites platform"
                    icon={PublicIcon}
                    colors={ServicesColors.sites}
                  />

              }
            </Grid>
            : null
        }

        {
          this.props.isSocialAvailable ?
            <Grid item xs={12} md={md}>
              {
                this.props.isSubscribedToSocial && this.props.companySocialProfile && this.props.companySocialProfile.isSetupComplete ?
                  <GoToSocial />
                  :
                  <ActivateProduct
                    to="/social"
                    buttonText="Try Social Platform"
                    backgroundImage="/images/social_background.png"
                    description="Boost your social media presence with the Social platform"
                    icon={PeopleIcon}
                    colors={ServicesColors.social}
                  />

              }
            </Grid>
            : null
        }

        {
          this.props.isVirtualAvailable ?
            <Grid item xs={12} md={md}>
              {
                this.props.isSubscribedToVirtual && this.props.companyVirtualProfile && this.props.companyVirtualProfile.isSetupComplete ?
                  <GoToVirtual />
                  :
                  <ActivateProduct
                    to="/virtual"
                    buttonText="Try Virtual Platform"
                    backgroundImage="/images/virtual_background.png"
                    description="Boost your company's efficiency with the Virtual platform"
                    icon={VideocamIcon}
                    colors={ServicesColors.virtual}
                  />

              }
            </Grid>
            : null
        }

      </>
    );
  }

}



export default withProvider(
  CompanyReviewsProfileProvider,
  CompanyAdsProfileProvider,
  CompanySitesProfileProvider,
  CompanySocialProfileProvider,
  CompanyVirtualProfileProvider,
  withContext(
    CompanyReviewsProfileContext,
    CompanyAdsProfileContext,
    CompanySitesProfileContext,
    CompanySocialProfileContext,
    CompanyVirtualProfileContext,
    PlanContext,
    CompanyRoleContext,
    ActivateOrGoToProduct
  )
);
import React from 'react';
import L from 'leaflet';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Paper, Typography, withStyles, Link } from '@material-ui/core';
import { Map as LeafletMap, TileLayer, DivOverlay } from 'react-leaflet';


class ProductSummary extends React.Component {


  componentDidMount = () => {
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.touchZoom.disable();
  }


  render() {
    const { classes } = this.props;

    const Icon = this.props.icon;

    return (
      <Paper>
        <div className={classes.root}>
          <Grid container spacing={16}>

            <Grid item xs={12}>
              <span style={{ color: this.props.iconColor }}>
                <Icon className={classes.icon}/>
              </span>
              <Typography variant="h5">
                <Link component={ RouterLink } to={this.props.titleLink} color="inherit">{this.props.title}</Link>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} className={classes.metric}>
              <Typography variant="h6">{this.props.enrolledCount}</Typography>
              <Typography variant="caption">Enrolled Companies</Typography>
            </Grid>

            {/* <Grid item xs={6} sm={3} className={classes.metric}>
              <Typography variant="h6">{this.props.incompleteCount}</Typography>
              <Typography variant="caption">Incomplete Companies</Typography>
            </Grid> */}

            <Grid item xs={12} sm={4} className={classes.metric}>
              <Typography variant="h6">{this.props.metric1Title}</Typography>
              <Typography variant="caption">{this.props.metric1Caption}</Typography>
            </Grid>

            <Grid item xs={12} sm={4} className={classes.metric}>
              <Typography variant="h6">{this.props.metric2Title}</Typography>
              <Typography variant="caption">{this.props.metric2Caption}</Typography>
            </Grid>

          </Grid>
        </div>

        <LeafletMap
          bounds={this.props.bounds.pad(this.props.boundsPaddingBufferRatio)}
          zoom={13}
          style={{
            height: 400,
          }}
          ref={mapRef => this.map = mapRef ? mapRef.leafletElement : null}
        >
          <TileLayer
            {...tileLayerProps}
            opacity={this.props.opacity}
          />
          {
            !this.props.children.length ?
              <div className={classes.noCompanies}>No Companies</div>
            :
            this.props.children
          }
        </LeafletMap>
      </Paper>
    );
  }


}


ProductSummary.defaultProps = {
  bounds: new L.LatLngBounds(
    [[24.396308, -124.848974], [49.384358, -66.885444]]
  ),
  boundsPaddingBufferRatio: 0.025,
  opacity: 0.9,
};


const tileLayerProps = {
  url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png',
  attribution: '',
  maxZoom: 19,
  subdomains: 'abcd',
};



const styles = theme => ({
  root: {
    padding: 12,
  },
  icon: {
    fontSize: 30,
    paddingRight: 10,
    float: 'left'
  },
  metric: {
    textAlign: 'center',
  },
  secondaryText: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right'
    },
  },
  noCompanies: {
    color: '#555',
    opacity: 0.75,
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    lineHeight: '400px',
    textAlign: 'center',
    fontSize: 18,
  }
})


export default withStyles(styles)(
  ProductSummary
);
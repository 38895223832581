import React from 'react';

import { UserRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';
import Axios from 'axios';

import { LinearProgress } from '@material-ui/core';

import ChannelModel from './ChannelModel';


const DEFAULT_STATE = {
  loadingChannels: true,
  channels: [],
  adsChannels: [],
  sitesChannels: [],
  virtualChannels: []
};


export const ChannelContext = React.createContext(DEFAULT_STATE);


class ChannelProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchChannels();
  }


  fetchChannels = () => {
    return Axios.get(
      `/api/core/channels`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchChannels response',response)
      const channels = response.data.channels.map(channel=> ChannelModel.fromJSON(channel))
      this.setState({
        channels,
        adsChannels: this.getAdsChannels(channels),
        sitesChannels: this.getSitesChannels(channels),
        virtualChannels: this.getVirtualChannels(channels),
        loadingChannels: false
      });
    })
  }


  getAdsChannels = channels => {
    const channelOptions = [
      ChannelModel.fromJSON({ id: 'all', name: 'All Channels' }),
    ];

    channels.filter(c => c.isFacebook() || c.isGoogleSearch()).forEach(c => channelOptions.push(c));

    return channelOptions;
  }


  getSitesChannels = channels => {
    return channels.filter(c => c.isWebsite());
  }


  getVirtualChannels = channels => {
    return channels.filter(c => c.isVirtualWorkRequest());
  }


  render(){
    const { loadingChannels } = this.state;

    if(loadingChannels) {
      return <LinearProgress/>;
    }

    return (
      <ChannelContext.Provider
        value={{
          fetchChannels: this.fetchChannels,
          channels: this.state.channels,
          adsChannels: this.state.adsChannels,
          sitesChannels: this.state.sitesChannels,
          virtualChannels: this.state.virtualChannels
        }}
      >
        { this.props.children }
      </ChannelContext.Provider>
    );
  }
}


export default withContext(UserRoleContext, ChannelProvider);
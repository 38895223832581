import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



const invitationLinkDialog = props => {
  const link = window.location.origin + `/user_invitations/${props.invitation.token}/accept`;

  return (
    <Dialog open={props.open} maxWidth="md" onClose={props.onClose}>
      <DialogTitle id="form-dialog-title">Invitation Link</DialogTitle>

      <DialogContent>
        <DialogContentText>
          The invitation can be accepted at <a href={link} target='_blank'>{link}</a>.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} variant="outlined" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

invitationLinkDialog.defaultProps = {
  open: false,
  invitation: '',
  onClose: () => {}
}

export default invitationLinkDialog
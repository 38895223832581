import React from 'react';

import { PlanContext, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Grid } from '@material-ui/core';

import AdsSummaryMap from './AdsSummaryMap/AdsSummaryMap';
import ReviewsSummaryMap from './ReviewsSummaryMap/ReviewsSummaryMap';
import SitesSummaryMap from './SitesSummaryMap/SitesSummaryMap';
import SocialSummaryMap from './SocialSummaryMap/SocialSummaryMap';
import VirtualSummaryMap from './VirtualSummaryMap/VirtualSummaryMap';



class GoToProduct extends React.Component {


  render() {
    return (
      <>

        {
          this.props.isAdsAvailable ?
            <Grid item xs={12}>
              <AdsSummaryMap />
            </Grid>
          :
            null
        }


        {
          this.props.isReviewsAvailable ?
            <Grid item xs={12}>
              <ReviewsSummaryMap/>
            </Grid>
          :
            null
        }


        {
          this.props.isSitesAvailable ?
            <Grid item xs={12}>
              <SitesSummaryMap />
            </Grid>
          :
            null
        }


        {
          this.props.isSocialAvailable ?
            <Grid item xs={12}>
              <SocialSummaryMap />
            </Grid>
          :
            null
        }

        {
          this.props.isVirtualAvailable ?
            <Grid item xs={12}>
              <VirtualSummaryMap />
            </Grid>
          :
            null
        }

      </>
    );
  }

}


export default withContext(
  PlanContext,
  OrganizationRoleContext,
  GoToProduct
);
import { JsonModel } from 'kn-react';

export default class ReviewRequestModel {

	static fromJSON(json) {
		const model = new ReviewRequestModel();
		return JsonModel.modelFromJSON(model, json);
	}

	toJSON() {
		return JsonModel.modelToJSON(this);
	}
	
}
import React from 'react';
import ReviewDetails from 'Reviews/ReviewDetails/ReviewDetails';

const organizationReviewDetails = props => {

  return (
    <ReviewDetails
      {...props}
      canEdit={false}
    />
  )
}


export default organizationReviewDetails
import React from 'react';
import Axios from 'axios';
import classnames from 'classnames';

import { ChangeMetric, SnackbarContext, CompanyRoleContext, OpportunityModel } from 'go-boost-library-react';
import { MaxWidth, withContext, log } from 'kn-react';

import { Grid, Typography, withStyles, Paper, Button } from '@material-ui/core';
import { Work as WorkIcon, AlarmOn } from '@material-ui/icons';

import { dashboardDataRequest } from 'SharedDashboard/sharedDashboard';
import JobsTable from './JobsTable';



const DEFAULT_CONVERSION_STATUSES = [
  OpportunityModel.NONCONTACTED_CONVERSION_STATUS,
  OpportunityModel.CONTACTED_CONVERSION_STATUS
];



class Jobs extends React.Component {
  state = {
    isLoading: true,
    page: 0,
    rowsPerPage: 10,
    opportunities: [],
    total: 0,
    opportunityConversionStatusDistribution: [],
    conversionStatuses: DEFAULT_CONVERSION_STATUSES
  }

  componentDidMount = () => {
    return this.fetchOpportunityConversionStatusDistribution().then(
      this.fetchOpportunities
    );
  }


  fetchOpportunities = () => {
    return Axios.get(
      `/api/core/opportunities/companies/${ this.props.company.id }`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          page: this.state.page,
          limit: this.state.rowsPerPage,
          channel_ids: this.props.channelIds,
          company_ids: [ this.props.company.id ],
          opportunity_types: ['form_entry'],
          is_spam: false,
          conversion_statuses: this.state.conversionStatuses
        }
      }
    ).then(response => {
      log('fetchOpportunities response', response);
      const opportunities = response.data.opportunities.map(
        o => OpportunityModel.fromJSON( o )
      );

      this.setState({
        opportunities: opportunities,
        total: response.data.total,
        isLoading: false
      });
    });
  }


  changePagination = newState => {
    return this.setState(
      {
        ...newState,
        isLoading: true,
      },
      this.fetchOpportunities
    )
  }


  fetchOpportunityConversionStatusDistribution = () => {
    return dashboardDataRequest(
      '/core/opportunities',
      '/opportunity_conversion_status_distribution',
      {
        ...this.props,
        roleTypePath: 'companies',
        roleTypeId: this.props.currentUserRole.roleTypeId,
      },
      {
        opportunity_types: ['form_entry'],
        channel_ids: this.props.channelIds,
        is_spam: false,
        company_ids: [ this.props.company.id ],
        conversion_statuses: this.state.conversionStatuses
      }
    ).then(response => {
      log('fetchOpportunityConversionStatusDistribution response', response);
      const opportunityConversionStatusDistribution = response.data.opportunity_conversion_status_distribution.map(d => ({
        count: d.count,
        conversionStatus: d.conversion_status
      }));


      return this.setState({
        opportunityConversionStatusDistribution
      });
    });
  }


  isConversionStatus = conversionStatus => {
    return (
      this.state.conversionStatuses.length === 1 &&
      this.state.conversionStatuses[ 0 ] === conversionStatus
    );
  }


  onClickFilter = conversionStatus => {
    let conversionStatuses = [];
    if( this.isConversionStatus( conversionStatus ) ){
      conversionStatuses = [ ...DEFAULT_CONVERSION_STATUSES ]
    } else {
      conversionStatuses = [ conversionStatus ];
    }


    return this.setState(
      {
        conversionStatuses,
        isLoading: true
      },
      this.fetchOpportunities
    )
  }


  render() {
    const { classes } = this.props;

    const noncontactedDistribution = this.state.opportunityConversionStatusDistribution.find(
      d => d.conversionStatus == null
    );

    const contactedDistribution = this.state.opportunityConversionStatusDistribution.find(
      d => d.conversionStatus === OpportunityModel.CONTACTED_CONVERSION_STATUS
    );

    return (
      <div >
        <Typography variant="h6">Jobs</Typography>

        <MaxWidth maxWidth={1200}>
          <Paper className={classes.summary}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.filterGrid}>
                <Button
                  fullWidth
                  className={classnames(classes.filterMetric, this.isConversionStatus('') ? classes.activeFilterMetric : '')}
                  onClick={() => this.onClickFilter('')}
                  disabled={this.state.isLoading}
                >
                  <ChangeMetric
                    title={`Noncontacted ${ this.props.opportunityName }`}
                    metric={noncontactedDistribution ? noncontactedDistribution.count || 0 : 0}
                    icon={WorkIcon}
                    iconColor="#5a7af9"
                    iconBackgroundColor="rgba(90, 122, 249, 0.2)"
                    width={260}
                    height={260}
                  />
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  fullWidth
                  className={classnames(classes.filterMetric, this.isConversionStatus('contacted') ? classes.activeFilterMetric : '')}
                  onClick={() => this.onClickFilter('contacted')}
                  disabled={this.state.isLoading}
                >
                  <ChangeMetric
                    title={`Contacted ${ this.props.opportunityName }`}
                    metric={contactedDistribution ? contactedDistribution.count || 0 : 0}
                    icon={AlarmOn}
                    iconColor="#9e70ff"
                    iconBackgroundColor="rgba(158, 112, 255, 0.2)"
                    width={260}
                    height={260}
                  />
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <div className={classes.filterCaption}>
            <Typography variant='caption'>
              Click a metric to filter to those Jobs
            </Typography>
          </div>

          <Paper className={classes.paper}>
            <JobsTable
              total={this.state.total}
              jobs={this.state.opportunities}
              isLoading={this.state.isLoading}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangeRowsPerPage={e => this.changePagination({ rowsPerPage: e.target.value })}
              onChangePage={(e, p) => this.changePagination({ page: p })}
              showAddress={this.props.showAddress}
              showGrade={this.props.showGrade}
              viewJobBaseUrl={this.props.viewJobBaseUrl}
              companyVirtualFormDesigns={this.props.companyVirtualFormDesigns}
              opportunityName={this.props.opportunityName}
            />
          </Paper>
        </MaxWidth>
      </div>
    );
  }
}


Jobs.defaultProps = {
  channelIds: [],
  showAddress: false,
  showGrade: false,
  viewJobBaseUrl: '',
  companyVirtualFormDesigns: [],
  opportunityName: ''
}


const styles = theme => ({
  paper: {
    padding: 16,
  },
  tableRow: {
    textDecoration: 'none',
  },
  summary: {
    // marginBottom: 25
  },
  filterMetric: {
    textTransform: 'none'
  },
  filterGrid: {
    borderRight: '1px solid lightgrey'
  },
  filterCaption: {
    marginBottom: 50
  },
  activeFilterMetric: {
    backgroundColor: 'lightgray !important'
  },
  noData: {
		textAlign: 'center',
		padding: 25,
		color: '#777',
	},
});

export default withContext(
  CompanyRoleContext,
  SnackbarContext,
  withStyles(styles)(
    Jobs
  )
);
import React from 'react';

import { SnackbarContext, Ability, UserRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';
import { withContext } from 'kn-react';
import paperPadding from 'paperPadding';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { BoostedServicesContext } from 'Ads/CompanyAds/BoostedServices/BoostedServicesProvider';
import { CompanyAdsProfileContext } from '../CompanyAdsProfile/CompanyAdsProfileProvider';
import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import AdsSettingsCard from './AdsSettingsCard';
import FacebookAccountCard from 'FacebookAccount/FacebookAccountCard';



class AdsSettings extends React.Component {
	state = {
		companyShortName: this.props.companyAdsProfile.companyShortName,
		facebookPageUrl: this.props.companyAdsProfile.facebookPageUrl,
	}

	onUpdate = () => {
		const { companyShortName, facebookPageUrl } = this.state;
		return this.props.updateCompanyAdsProfile({ companyShortName, facebookPageUrl })
			.then(() => {
				this.props.showSnackbar('Ads Settings Successfully Updated');
			});
  }

	onChangeCompanyShortName = companyShortName => {
		if (this.props.currentUserRole.roleHasAbility(Ability.EDIT_COMPANY_ADS_PROFILE)) {
			this.setState({ companyShortName });
		}
	}

	onChangeFacebookPageUrl = facebookPageUrl => {
		this.setState({ facebookPageUrl });
	}

	render() {
		const { classes, currentUserRole } = this.props;
		const { companyShortName, facebookPageUrl } = this.state;


		let hasFacebookBoostedService = this.props.facebookCompanyBoostedServices().length;
		const isShutdown = this.props.companyAdsProfile && this.props.companyAdsProfile.isShutdown;

		return (
			<div className={classes.root}>
				<Grid container spacing={16}>
					<Grid item xs={12}>
						<Typography variant="h5">Ads Settings</Typography>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.paperRoot}>
							<AdsSettingsCard
								companyShortName={companyShortName}
								onChangeCompanyShortName={this.onChangeCompanyShortName}
								facebookPageUrl={facebookPageUrl}
								onChangeFacebookPageUrl={this.onChangeFacebookPageUrl}
								includePaper={false}
							/>

							{
								isShutdown || !this.props.currentUserRole.roleHasAbility(Ability.EDIT_COMPANY_ADS_PROFILE) ? null :
								<Grid item xs={12} style={{ textAlign: 'right' }}>
									<Button className={classes.submitButton} name='update-settings-button' onClick={this.onUpdate} color='primary' variant='contained'>Update</Button>
								</Grid>
							}
						</Paper>
					</Grid>

					{
						hasFacebookBoostedService ?
							<Grid item xs={12} id='connected-accounts'>
								<Paper className={classes.paperRoot}>
									<Typography variant="subtitle1" color="primary">
										Facebook Account Page

										&nbsp;

										<KnowledgeBaseButton
											path={'/connecting_to_facebook_through_goboost_ads'}
											tooltipTitle={'Learn about connecting Facebook to your Ads'}
										/>
									</Typography>
									<Typography style={{paddingBottom: 24}} variant="body2">Ads for any Facebook Boosted service will run on this Page.</Typography>

									<FacebookAccountCard
										shouldEnqueueReviewsImporter={false}
										shouldConnectBusinessManager={true}
										canSignOut={isShutdown}
										isProductShutdown={isShutdown}
										canSignIn={!(
											this.props.facebookAccountPage &&
											this.props.facebookAccountPage.id &&
											!this.props.facebookAccountPage.isError
										)}
									/>
								</Paper>
							</Grid>
						:
							null
					}


				</Grid>
			</div>
		);
	}

}

const styles = theme => ({
	root: {
		padding: 16
	},
	paperRoot: {
		padding: paperPadding
	},
	settings: {
		position: 'relative',
	},
})

export default withContext(
	CompanyAdsProfileContext,
	UserRoleContext,
	FacebookAccountContext,
	SnackbarContext,
	BoostedServicesContext,
	withStyles(styles)(AdsSettings)
);
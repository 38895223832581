import React from 'react';

import { SnackbarContext, Incrementer, PromiseButton, PlanContext, Ability, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, MaxWidth, format } from 'kn-react';

import { withStyles, Button, Typography, Grid, LinearProgress, Paper } from '@material-ui/core';

import { BoostedServicesContext } from '../BoostedServicesProvider';
import { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import CheckoutSummary from '../CheckoutSummary/CheckoutSummary';
import history from 'history.js';
import paperPadding from 'paperPadding';

class AddToBalance extends React.Component {
  state = {
    boostedService: null,
    companyBoostedService: null,
    amountCents: 500 * 100,
    incrementAmountCents: 50 * 100,
    minimumAmountCents: 50 * 100
  }

  componentDidMount = () => {
    const { id } = this.props.match.params;
    const companyBoostedService = this.props.companyBoostedServices.find(c => c.boostedServiceId == id);
    const boostedService = this.props.boostedServices.find(b => b.id == id);

    this.setState({ companyBoostedService, boostedService });
  }


  addToBalance = () => {
    const { id } = this.props.match.params;
    const companyBoostedService = this.props.companyBoostedServices.find(c => c.boostedServiceId == id);
    this.props.addToBoostBalances({ [companyBoostedService.id]: this.state.amountCents })
      .then(() => {
        this.props.showSnackbar('Charge processing.');
        history.push('/ads/boosted_services');
      });
  }

  decrementAmount = () => {
    let { amountCents } = this.state;
    const { incrementAmountCents, minimumAmountCents } = this.state;

    amountCents = Math.max(
      minimumAmountCents,
      amountCents - incrementAmountCents
    );

    this.setState({ amountCents });
  }


  incrementAmount = () => {
    let { amountCents } = this.state;
    const { incrementAmountCents, minimumAmountCents } = this.state;

    amountCents = Math.max(
      minimumAmountCents,
      amountCents + incrementAmountCents
    );

    this.setState({ amountCents });
  }



  render() {
    const { classes } = this.props;
    const { amountCents, companyBoostedService, boostedService } = this.state;
    const plan = this.props.subscribedAdsPlan;

    if (!companyBoostedService) {
      return <LinearProgress />;
    }

    const isShutdown = this.props.companyAdsProfile && this.props.companyAdsProfile.isShutdown;

    return (
      <MaxWidth maxWidth={500}>
        <div className={classes.root}>
          <Grid container spacing={16}>

            <Grid item xs={12}>
              <Typography variant="h5">Boost Balance</Typography>
              <Typography variant="caption">Add to your balance for your <span className={classes.colorPrimary}>{ boostedService.name }</span> ads.</Typography>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paperRoot}>

                <Incrementer
                  label="ADD TO BOOST BALANCE"
                  onIncrement={this.incrementAmount}
                  onDecrement={this.decrementAmount}
                  value={format.money(amountCents / 100 || 0)}
                />

                <CheckoutSummary
                  primaryChargeCents={amountCents}
                  managementFeePercent={plan.adsManagementFeePercent}
                  showTotal={false}
                />
              </Paper>

            </Grid>

            <Grid item xs={6}>
              <Button variant="outlined" color="primary" onClick={() => history.push('/ads/boosted_services')}>
                Back
              </Button>
            </Grid>

            {
              this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_BILLING ) && !isShutdown ?
                  <Grid item xs={6} className={classes.update}>
                    <PromiseButton
                      buttonProps={{
                        variant: "contained",
                        color: "primary"
                      }}
                      onProcess={this.addToBalance}
                    >
                      Add To Balance
                    </PromiseButton>
                  </Grid>
                :
                  null
            }

          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: paperPadding,
  },
  update: {
    textAlign: 'right',
    paddingTop: 5
  }
});


export default withStyles(styles)(
  withContext(
    BoostedServicesContext,
    CompanyAdsProfileContext,
    PlanContext,
    CompanyRoleContext,
    SnackbarContext,
    AddToBalance
  )
);
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 512 512" x="0px" y="0px">
    <defs>
      <linearGradient id="Degradado_sin_nombre_4" x1="266.39" y1="496.01" x2="266.39" y2="15.99" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#ffde6e"/>
        <stop offset="1" stop-color="#ff4c41"/>
      </linearGradient>
    </defs>
    <path d="M266.39 16c-132.56 0-240 107.45-240 240s107.45 240 240 240 240-107.45 240-240-107.45-240-240-240zM161.17 342a25.53 25.53 0 0 1-21.59-39.26c-30.51-9-51.41-22.53-52.45-23.21a11.27 11.27 0 0 1 12.35-18.85 198 198 0 0 0 63.6 24.66c36.67 7.05 64.92 4.1 65.21 4.08a11.39 11.39 0 0 1 12.43 10 11.26 11.26 0 0 1-9.95 12.43 179.86 179.86 0 0 1-18.56.8c-7.05 0-15.93-.36-25.94-1.29a25.58 25.58 0 0 1-25.1 30.64zm103.57 70.88c-42.15 0-74.21-2.09-74.21-12.21s34.17-18.32 76.33-18.32 76.32 8.21 76.32 18.32-36.29 12.2-78.44 12.2zm180.91-133.36c-1 .68-21.94 14.19-52.46 23.21a25.58 25.58 0 1 1-46.68 8.6c-10 .93-18.89 1.29-25.94 1.29a179.64 179.64 0 0 1-18.56-.8 11.25 11.25 0 0 1-9.95-12.43 11.38 11.38 0 0 1 12.43-10c.28 0 28.54 3 65.21-4.08a197.87 197.87 0 0 0 63.59-24.66 11.27 11.27 0 0 1 12.36 18.85z" fill="url(#Degradado_sin_nombre_4)"/>
    <path d="M448.89 263.92a11.25 11.25 0 0 0-15.6-3.25 197.87 197.87 0 0 1-63.59 24.66c-36.67 7.05-64.93 4.1-65.21 4.08a11.38 11.38 0 0 0-12.43 10 11.25 11.25 0 0 0 9.94 12.41 179.64 179.64 0 0 0 18.56.8c7.05 0 15.93-.36 25.94-1.29a25.61 25.61 0 1 0 46.68-8.6c30.52-9 51.42-22.53 52.46-23.21a11.26 11.26 0 0 0 3.25-15.6z" fill="#102236"/>
    <path d="M212.21 312.62a179.86 179.86 0 0 0 18.56-.8 11.26 11.26 0 0 0 9.95-12.43 11.39 11.39 0 0 0-12.43-10c-.29 0-28.54 3-65.21-4.08a198 198 0 0 1-63.6-24.66 11.27 11.27 0 0 0-12.35 18.85c1 .68 21.94 14.19 52.45 23.21a25.58 25.58 0 1 0 46.69 8.6c10.01.95 18.89 1.31 25.94 1.31z" fill="#102236"/>
    <path d="M266.86 382.34c-42.16 0-76.33 8.21-76.33 18.32s32.06 12.21 74.21 12.21 78.44-2.09 78.44-12.21-34.18-18.32-76.32-18.32z" fill="#102236"/>
  </SvgIcon>
);
import React from 'react';
import { withContext } from 'kn-react';
import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import ProductUndoResubscriptionAlert from 'CompanyRole/productUndoResubscriptionAlert';


const reviewsUndoResubscriptionAlert = props => (
  <ProductUndoResubscriptionAlert
    isResubscribing={(
      props.companyReviewsProfile &&
      props.companyReviewsProfile.isShutdown &&
      !props.companyReviewsProfile.isSetupComplete
    )}
    undoText={'You are resubscribing to Reviews.'}
    onProcess={props.undoResubscriptionToReviews}
  />
);


export default withContext(
  CompanyReviewsProfileContext,
  reviewsUndoResubscriptionAlert
);
import React from 'react';
import { Paper, Button, withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import paperPadding from 'paperPadding';


class ImageUpload extends React.Component {

  state = {
    imagePreviewUrl: '',
  }

  componentDidMount = () => {
    this.setState({ imagePreviewUrl: this.props.existingImageUrl });
  }



  onChangeImage = e => {
    e.preventDefault();

    const reader = new FileReader();
    let file = e.target.files[0];

    if( !file ){
      return;
    } else if( this.props.maxFileSize && file.size > this.props.maxFileSize ){
      window.alert('The chosen file exceeds the max file size. Please upload a smaller file.');
      return;
    }

    reader.onloadend = () => {
      const imagePreviewUrl = reader.result;

      this.setState({
        file: file,
        imagePreviewUrl
      });

      this.props.onChangeImage(file, imagePreviewUrl)
    }

    reader.readAsDataURL(file);
  }


  render() {
    const { classes } = this.props;
    const { imagePreviewUrl } = this.state;


    return (
      <div>
        <form>
          <input
            accept="image/*"
            className={classes.input}
            id={this.props.inputId}
            type="file"
            onChange={ e => this.onChangeImage(e)}
          />
          <label htmlFor={this.props.inputId}>
            <Button classes={{ root: classes.uploadButton }} component="span" variant="outlined" color="primary">
              {
                imagePreviewUrl ?
                  <img src={imagePreviewUrl} className={classes.image} />
                :
                  <Add className={ classes.uploadButtonIcon }/>
              }
            </Button>
          </label>
        </form>
      </div>
    )
  }
}

const styles = theme => ({
  paper: {
    padding: paperPadding
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    padding: 30,
    margin: 10,
    border: '1px dashed #ccc',
  },
  uploadButtonIcon: {
    fontSize: 50,
  },
  image: {
    maxWidth: '100%'
  }
});


ImageUpload.defaultProps = {
  inputId: 'contained-button-file',
  maxFileSize: null
}


export default withStyles(styles)(ImageUpload);
import React from 'react';

import { PlanContext, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, MaxWidth } from 'kn-react';

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AddToHomeScreenAlert from 'AddToHomeScreen/AddToHomeScreenAlert';
import AppNavigation from 'AppNavigation/AppNavigation';
import GoToProduct from 'Home/OrganizationHome/Products/GoToProduct';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';


class OrganizationHome extends React.Component {

  render() {
    const { currentUserRole, classes, theme } = this.props;

    return (
      <AppNavigation title="Home">
        <MaxWidth maxWidth={800} style={{ padding: 16 }}>
          <Grid container spacing={16}>
            <KnowledgeBaseLaunchBanner />

            <Grid item sm={12}>
              <Typography paragraph variant="h4" className={classes.greeting}>
                Welcome {currentUserRole.userFirstName}!
              </Typography>
            </Grid>

            <Grid item sm={12}>
              <AddToHomeScreenAlert/>
            </Grid>

            <GoToProduct/>

          </Grid>
        </MaxWidth>
      </AppNavigation>
    );
  }

}


const styles = theme => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center'
  },

});

export default withStyles(styles)(
  withContext(
    PlanContext,
    OrganizationRoleContext,
    OrganizationHome
  )
);
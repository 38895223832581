import React from 'react';

import { MaxWidth, log, formHelpers, AutoError, withContext } from 'kn-react';
import { SnackbarContext, UserRoleContext, Ability, KnowledgeBaseButton } from 'go-boost-library-react';

import { Typography, TextField, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';

class ReviewRequestSettingsCardContent extends React.Component {
  state = {
    reviewRequestDefaultMessage: (
      this.props.companyReviewsProfile.reviewRequestDefaultMessage
      ||
      `Please review us. We'd appreciate your feedback!`
    ),
  }

  updateProfile = () => {
    return this.props.updateCompanyReviewsProfile({
      review_request_default_message: this.state.reviewRequestDefaultMessage
    })
    .then(() => this.props.showSnackbar('Review request default message was successfully updated.'))
  }

  onProcess = () => {
    return this.validate()
      .then(this.updateProfile)
      .then(this.props.onProcessEnd)
      .catch(AutoError.catch.bind(this));
  }

  onValidationError = error => {
    log(error.message)
    this.props.showSnackbar(error.message);
  }

  validate = () => {
    const baseValidations = {
      reviewRequestDefaultMessage: {
        presence: { message: `Please enter a default review request message.` },
        length: { atMost: 160, message:'Please enter a message that is no longer than 160 characters.' }
      }
    };

    return formHelpers.validate(this.state, baseValidations);
  }


  render() {
    const { classes, currentUserRole } = this.props;
    const { reviewRequestDefaultMessage } = this.state;

    const Wrapper = this.props.wrapperComponent || React.Fragment;
    const Children = props => {
      const Child = this.props.children;
      return <Child {...props} onProcess={this.onProcess}/>
    };

    return (
      <>
        <Wrapper>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">
                Review Request Settings

                &nbsp;

                <KnowledgeBaseButton
                  path={'/sending_review_requests'}
                  tooltipTitle={'Learn more about requesting reviews'}
                />
              </Typography>
              <Typography variant="body2">To ease the process of requesting reviews, create a default message that can easily be sent with each review request.</Typography>

              <TextField
                onChange={e => this.setState({ reviewRequestDefaultMessage: e.target.value })}
                onBlur={e => this.setState({ reviewRequestDefaultMessage: e.target.value.trim() })}
                disabled={!currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_REVIEWS )}
                fullWidth
                variant='outlined'
                name='review-request-default-message'
                id='review-request-default-message'
                multiline={true}
                label='Review Request Default Message'
                margin="normal"
                value={reviewRequestDefaultMessage}
                helperText={
                  <span style={{ color: (reviewRequestDefaultMessage.length > 160) ? 'red' : 'black' }}>
                    { reviewRequestDefaultMessage.length || 0 }/160 characters used.
                  </span>
                }
              />
            </Grid>
          </Grid>
        </Wrapper>

        <Children/>
      </>
    );
  }

}

const styles = theme => ({

});

ReviewRequestSettingsCardContent.defaultProps = {
  reviewRequestDefaultMessage: '',
	companyReviewsProfile: {},
  updateCompanyReviewsProfile: () => {},
  onProcessEnd: () => {},
}



export default withStyles(styles)(
  withContext(
    UserRoleContext,
    CompanyReviewsProfileContext,
    SnackbarContext,
    ReviewRequestSettingsCardContent
  )
);
import React from 'react';
import { withProvider, withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';

import CompanyVirtual from './CompanyVirtual/CompanyVirtual';
import OrganizationVirtual from './OrganizationVirtual/OrganizationVirtual';

const virtual = props => (
  props.currentUserRole.isCompanyRole() ?
    <CompanyVirtual/>
  :
    <OrganizationVirtual/>
);

export default withContext(
  UserRoleContext,
  virtual
);
import React from 'react';
import { withContext } from 'kn-react';

import { PromiseButton } from 'go-boost-library-react';

import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Button } from '@material-ui/core';

import { FeedContext } from 'Social/Feed/FeedProvider';


class NewFeedDialog extends React.Component {
  state={
    name: ''
  }

  onClose = () => {
    this.setState(
      { name: '' },
      this.props.onClose
    )
  }

  onCreate = () => {
    return this.props.createFeed(this.state.name)
      .then(this.onClose)
  }

  render(){
    return(
      <Dialog
        open={this.props.open}
        onClose={this.onClose}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>
          New Feed
        </DialogTitle>

        <DialogContent>
          <TextField
            value={this.state.name}
            onChange={e => this.setState({ name: e.target.value })}
            onBlur={e => this.setState({ name: e.target.value.trim() })}
            fullWidth
            label='Feed Name'
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={this.onClose} variant='outlined' color='primary'>
            Cancel
          </Button>

          <PromiseButton
            onProcess={this.onCreate}
            buttonProps={{
              variant: 'contained',
              color: 'primary',
              disabled: Boolean(!this.state.name)
            }}
          >
            Create
          </PromiseButton>
        </DialogActions>
      </Dialog>
    )
  }
}

NewFeedDialog.defaultProps = {
  onClose: () => {},
  open: false
}

export default withContext(
  FeedContext,
  NewFeedDialog
)
import React from 'react';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { PromiseButton, SnackbarContext, PlanContext, Ability, CompanyRoleContext, isDevelopment } from 'go-boost-library-react';
import { withContext, withProvider, log } from 'kn-react';

import { Button, Grid, Typography, withStyles, Paper, IconButton, Tooltip, List, ListItem, ListItemText, ListItemSecondaryAction, Switch, TextField } from '@material-ui/core';
import { Edit, Delete, Reorder } from '@material-ui/icons';

import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import { MediaItemsContext  } from 'MediaItems/MediaItemsProvider';
import { VirtualFormDesignContext } from './VirtualFormDesignProvider';
import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import ImageUpload from 'ImageUpload/ImageUpload';
import ReorderStepDialog from './ReorderStepDialog/ReorderStepDialog';
import StepDialog from './StepDialog/StepDialog';


const EMBED_DIV_ID = 'embed';

class CompanyVirtualFormDesign extends React.Component {
  state = {
    isStepDialogOpen: false,
    isReorderDialogOpen: false,
    activeStepKey: '',
    isMediaStep: false,
    url: '',
    exitSearchPhrase: '',
    initialResponseMessage: '',
    finalResponseMessage: '',
  }


  componentDidMount = () => {
    // Sometimes React does not scroll the component into view
    const embedDiv = document.getElementById( EMBED_DIV_ID );

    if( window.location.href.includes(`#${ EMBED_DIV_ID }`) && embedDiv ){
      embedDiv.scrollIntoView();
    }


    return this.setInitialState();
  }


  componentDidUpdate = oldProps => {
    if( this.props.companyVirtualFormDesigns !== oldProps.companyVirtualFormDesigns ){
      return this.setInitialState();
    }
  }


  setInitialState = () => {
    const companyVirtualFormDesign = this.props.companyVirtualFormDesigns.find(
      d => d.id == this.props.match.params.id
    );

    if( !companyVirtualFormDesign ){
      return;
    }


    return this.setState({
      url: companyVirtualFormDesign.url,
      exitSearchPhrase: companyVirtualFormDesign.exitSearchPhrase,
      initialResponseMessage: companyVirtualFormDesign.initialResponseMessage,
      finalResponseMessage: companyVirtualFormDesign.finalResponseMessage,
    });
  }


  getStepType = isMediaStep => (
    isMediaStep?
      'mediaSteps'
    :
      'steps'
  )


  getSteps = () => {
    const companyVirtualFormDesign = this.props.companyVirtualFormDesigns.find(
      d => d.id == this.props.match.params.id
    );

    if( !companyVirtualFormDesign ){
      return [];
    }


    const stepType = this.getStepType( this.state.isMediaStep );

    return companyVirtualFormDesign[ stepType ];
  }


  onUpdateStep = step => {
    const steps = [ ...this.getSteps() ];

    const stepIndex = steps.findIndex(
      s => s.key === this.state.activeStepKey
    );

    if( stepIndex < 0 ){
      steps.push( step );
    } else {
      steps.splice(
        stepIndex,
        1,
        step
      );
    }

    const stepType = this.getStepType( this.state.isMediaStep );


    return this.props.updateCompanyVirtualFormDesign(
      this.props.match.params.id,
      { [ stepType ]: steps }
    ).then(
      () => this.setState(
        { isStepDialogOpen: false },
        () => this.props.showSnackbar(`Step successfully ${ this.state.activeStepKey ? 'edited' : 'added' }.`)
      )
    );
  }


  generateStepKey = () => {
    const numericStepKeys = this.getSteps().map(
      s => +s.key
    ).filter(
      k => ![null, undefined, NaN].includes( k )
    );

    if( !numericStepKeys || !numericStepKeys.length ){
      return 0
    }


    return Math.max( ...numericStepKeys ) + 1;
  }


  onAddStep = () => {
    return this.setState({
      isStepDialogOpen: true,
      activeStepKey: null,
      isMediaStep: false
    });
  }


  onAddMediaStep = () => {
    return this.setState({
      isStepDialogOpen: true,
      activeStepKey: null,
      isMediaStep: true
    });
  }


  onToggleStep = (isMediaStep, stepIndex) => {
    const companyVirtualFormDesign = this.props.companyVirtualFormDesigns.find(
      d => d.id == this.props.match.params.id
    );

    if( !companyVirtualFormDesign ){
      return;
    }


    const stepType = this.getStepType( isMediaStep );

    const steps = [ ...companyVirtualFormDesign[ stepType ] ];
    const step = steps[ stepIndex ];
    step.isInactive = [undefined, null].includes( step.isInactive ) ? true : !step.isInactive;

    steps.splice(
      stepIndex,
      1,
      step
    );


    return this.props.updateCompanyVirtualFormDesign(
      this.props.match.params.id,
      { [ stepType ]: steps }
    ).then(
      () => this.setState(
        { isStepDialogOpen: false },
        () => this.props.showSnackbar(`Step successfully ${ step.isInactive ? 'deactivated' : 'activated' }.`)
      )
    );
  }


  onDeleteStep = (isMediaStep, stepIndex) => {
    if( !window.confirm(`Are you sure you want to continue?`) ){
      return;
    }


    const companyVirtualFormDesign = this.props.companyVirtualFormDesigns.find(
      d => d.id == this.props.match.params.id
    );

    if( !companyVirtualFormDesign ){
      return;
    }


    const stepType = this.getStepType( isMediaStep );

    const steps = [ ...companyVirtualFormDesign[ stepType ] ];

    steps[ stepIndex ] = JSON.parse(
      JSON.stringify({
        ...steps[ stepIndex ],
        isDeleted: true
      })
    );


    return this.props.updateCompanyVirtualFormDesign(
      this.props.match.params.id,
      { [ stepType ]: steps }
    ).then(
      () => this.setState(
        { isStepDialogOpen: false },
        () => this.props.showSnackbar(`Step successfully deleted.`)
      )
    );
  }


  validateUpdateSetting = () => {
    if( !Boolean( this.state.url ) ){
      this.props.showSnackbar('Please specify the Virtual Work Request URL.');

      return false;
    }


    if (
      this.props.subscribedVirtualPlan &&
      !this.props.subscribedVirtualPlan.isVirtualEmbedIncluded
    ) {
      if(
        !this.props.isSubscribedToSites ||
        !this.props.companySitesProfile ||
        !this.props.companySitesProfile.isSetupComplete ||
        this.props.companySitesProfile.isShutdown ||
        !(
          this.props.companySitesProfile.byoDomain ||
          this.props.companySitesProfile.newDomain
        )
      ){
        this.props.showSnackbar(`You must be subscribed to Sites and use that domain.`);

        return false;
      }


      const domain = (
        this.props.companySitesProfile.byoDomain ||
        this.props.companySitesProfile.newDomain
      );


      let strippedUrl = this.state.url.replace(
        /^https:\/\//, ''
      ).replace(
        /^www\./, ''
      );

      if( !strippedUrl.startsWith( `${ domain }` ) ){
        this.props.showSnackbar(`The Virtual Work Request URL and Media Upload URL must start with "${ domain }".`);

        return false;
      }
    }


    return true;
  }


  onUpdateSettings = () => {
    if( !this.validateUpdateSetting() ){
      return Promise.reject();
    }

    return this.props.updateCompanyVirtualFormDesign(
      this.props.match.params.id,
      {
        url: this.state.url,
        exitSearchPhrase: this.state.exitSearchPhrase,
        initialResponseMessage: this.state.initialResponseMessage,
      }
    ).then(
      () => this.props.showSnackbar(`Settings successfully updated.`)
    );
  }


  uploadBackgroundImage = () => {
    const { backgroundImageFile } = this.state;

    if( !backgroundImageFile ){
      return Promise.resolve(null);
    }

    return this.props.createMediaItem(backgroundImageFile).then(
      mediaItem => this.props.updateCompanyVirtualFormDesign(
        this.props.match.params.id,
        { backgroundImageUrl: mediaItem.url }
      )
    ).then(
      () => this.props.showSnackbar(`Background image successfully changed updated.`)
    );
  }


  onChangeBackgroundImage = backgroundImageFile => {
    log('onChangeBackgroundImage', backgroundImageFile);
    this.setState({ backgroundImageFile });
  }


  scriptTagText = (embedScriptUrl, embedId) => (
    `<script async src="${ embedScriptUrl }" data-gb-embed-id="${ embedId }"></script>`
  )



  canParseFromClipboard = () => {
    if( !(navigator && navigator.clipboard && navigator.clipboard.readText ) ){
      return false;
    }

    return true;
  }



  copyScriptTagTextToClipboard = (embedScriptUrl, embedId) => {
    return navigator.clipboard.writeText(
      this.scriptTagText(embedScriptUrl, embedId)
    ).then(
      () => this.props.showSnackbar('Script Tag successfully coppied to your clipboard')
    )
  }


  onUpdateStepIndex = index => {
    const companyVirtualFormDesign = this.props.companyVirtualFormDesigns.find(
      d => d.id == this.props.match.params.id
    );

    let steps = (
      this.state.isMediaStep ?
        companyVirtualFormDesign.mediaSteps
      :
        companyVirtualFormDesign.steps
    );

    const activeStepIndex = steps.findIndex(
      s => s.key === this.state.activeStepKey
    );

    steps.splice(
      index,
      0,
      steps.splice(activeStepIndex, 1)[ 0 ]
    );


    const stepType = this.getStepType( this.state.isMediaStep );

    return this.props.updateCompanyVirtualFormDesign(
      this.props.match.params.id,
      { [ stepType ]: steps }
    ).then(
      () => this.setState(
        { isReorderDialogOpen: false },
        () => this.props.showSnackbar(`Step successfully moved.`)
      )
    );
  }


  render() {
    const { classes } = this.props;

    const companyVirtualFormDesign = this.props.companyVirtualFormDesigns.find(
      d => d.id == this.props.match.params.id
    );

    const virtualFormDesign = this.props.virtualFormDesigns.find(
      d => companyVirtualFormDesign && d.id === companyVirtualFormDesign.virtualFormDesignId
    );

    if( !companyVirtualFormDesign || !virtualFormDesign ){
      return (
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography variant="h6">Virtual Work Request Designs</Typography>
          </Grid>
        </Grid>
      );
    }


    const stepTypeSteps = (
      this.state.isMediaStep ?
        companyVirtualFormDesign.mediaSteps
      :
        companyVirtualFormDesign.steps
    );

    const steps = (
      stepTypeSteps || []
    ).filter(
      s => !s.isDeleted
    );

    const activeStepIndex = (
      (
        steps &&
        (
          this.state.activeStepKey ||
          this.state.activeStepKey === 0
        )
      ) ?
        steps.findIndex(
          s => s.key === this.state.activeStepKey
        )
      :
        null
    );

    const activeStep = (
      activeStepIndex >= 0 ?
        steps[ activeStepIndex ]
      :
        null
    );

    const canEmbed = (
      this.props.subscribedVirtualPlan &&
      this.props.subscribedVirtualPlan.isVirtualEmbedIncluded
    );

    let previewUrl = (
      companyVirtualFormDesign ?
        companyVirtualFormDesign.url
      :
        '#'
    );
    if( previewUrl !== '#' && !previewUrl.match(/https:\/\/|http:\/\//) ){
      const schema = (
        isDevelopment() ?
          'http://'
        :
          'https://'
      );

      previewUrl = `${ schema }${ previewUrl }`;
    }

    const isShutdown = this.props.companyVirtualProfile && this.props.companyVirtualProfile.isShutdown;
    const canEdit = (
      this.props.currentUserRole.roleHasAbility( Ability.EDIT_VIRTUAL_COMPANY_VIRTUAL_FORM_DESIGN ) &&
      !isShutdown
    );

    return (
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <Typography variant="h6">{ virtualFormDesign.name } - Virtual Work Request Form</Typography>
        </Grid>

        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            component={'a'}
            variant='outlined'
            color='secondary'
            href={previewUrl}
            target={'_blank'}
            className={classes.viewVirtualWorkRequest}
          >
            View Your Virtual Work Request Form
          </Button>

          <Button
            component={RouterLink}
            variant='contained'
            color='secondary'
            to={`/virtual/widget_and_forms`}
          >
            Add New Virtual Form Industry
          </Button>
        </Grid>


        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Steps</Typography>

            <List>
              {
                companyVirtualFormDesign.steps.map(
                  (s, i) => {
                    // Loop through all steps so indices are correct
                    if( s.isDeleted ){
                      return;
                    }

                    return (
                      <ListItem key={i}>
                        <ListItemText
                          primaryTypographyProps={{
                            className: classes.headline
                          }}
                          primary={
                            <>
                              { s.headline }

                              {
                                s.isRequired ?
                                  <span className={classes.isRequired}>*</span>
                                :
                                  null
                              }
                            </>
                          }
                          secondary={s.type}
                        />


                        <ListItemSecondaryAction>
                          {
                            s.canExclude && canEdit ?
                              <Tooltip title={`${ s.isInactive ? 'Activate' : 'Deactivate' } Step`}>
                                <Switch
                                  color="primary"
                                  checked={s.isInactive !== true}
                                  onClick={() => this.onToggleStep(false, i)}
                                />
                              </Tooltip>
                            :
                              null
                          }

                          <Tooltip title='Edit Step'>
                            <IconButton
                              onClick={() => this.setState({ isStepDialogOpen: true, activeStepKey: s.key, isMediaStep: false })}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>

                          {
                            !canEdit ?
                              null
                            :
                              <Tooltip title='Reorder Step'>
                                <IconButton
                                  onClick={() => this.setState({ isReorderDialogOpen: true, activeStepKey: s.key, isMediaStep: false })}
                                >
                                  <Reorder />
                                </IconButton>
                              </Tooltip>
                          }


                          {
                            s.canExclude && canEdit ?
                              <Tooltip title='Delete Step'>
                                <IconButton
                                  onClick={() => this.onDeleteStep(false, i)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            :
                              null
                          }
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  }
                )
              }
            </List>

            <Typography className={classes.isRequiredText} variant='caption'>* Required</Typography>

            {
              !canEdit ?
                null
              :
                <Grid item xs={12} className={classes.addButtonGrid}>
                  <Button
                    size='small'
                    variant='contained'
                    color='secondary'
                    onClick={this.onAddStep}
                  >
                    Add Step
                  </Button>
                </Grid>
            }
          </Paper>
        </Grid>


        <StepDialog
          open={this.state.isStepDialogOpen}
          onClose={() => this.setState({ isStepDialogOpen: false })}
          step={activeStep}
          onUpdateStep={step => this.onUpdateStep(step)}
          generateStepKey={this.generateStepKey}
          isMediaStep={this.state.isMediaStep}
        />

        <ReorderStepDialog
          open={this.state.isReorderDialogOpen}
          index={activeStepIndex}
          steps={steps}
          onClose={() => this.setState({ isReorderDialogOpen: false })}
          onUpdateStep={this.onUpdateStepIndex}
        />


        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Virtual Form Settings</Typography>

            <TextField
              fullWidth
              className={classes.textField}
              label={'URL'}
              variant="outlined"
              value={this.state.url}
              onChange={e => this.setState({ url: e.target.value })}
              onBlur={e => this.setState({ url: e.target.value.trim() })}
              helperText='URL on your website where the Virtual Form steps will be hosted. NOTE: This Virtual Form will only work on the URL you provide.'
            />

            <TextField
              fullWidth
              className={classes.textField}
              label={'Exit Search Phrase'}
              variant="outlined"
              value={this.state.exitSearchPhrase}
              onChange={e => this.setState({ exitSearchPhrase: e.target.value })}
              onBlur={e => this.setState({ exitSearchPhrase: e.target.value.trim() })}
              helperText='When a customers selects an option where you cannot provide assistance, what search phrase should be used in Google to help the customers find other local contractors?'
            />

            <TextField
              fullWidth
              multiline
              rows={2}
              className={classes.textField}
              label={'Initial Automated Response'}
              variant="outlined"
              value={this.state.initialResponseMessage}
              onChange={e => this.setState({ initialResponseMessage: e.target.value })}
              onBlur={e => this.setState({ initialResponseMessage: e.target.value.trim() })}
              helperText={
                <>
                  When the first automated response is sent to your customer, what should it say? <br />

                  <span style={{ color: ( this.state.initialResponseMessage || '' ).length >= 160 ? 'red' : 'inherit' }}>
                    NOTE: Responses longer than 160 characters will be split into multiple messages. Current characters: { ( this.state.initialResponseMessage || '' ).length }
                  </span>
                </>
              }
            />

            {/* <TextField
              fullWidth
              multiline
              rows={2}
              className={classes.textField}
              label={'Final Automated Response'}
              variant="outlined"
              value={this.state.finalResponseMessage}
              onChange={e => this.setState({ finalResponseMessage: e.target.value })}
              onBlur={e => this.setState({ finalResponseMessage: e.target.value.trim() })}
              helperText={
                <>
                  After your customer responds to your automated message, what should it say? <br />

                  <span style={{ color: ( this.state.finalResponseMessage || '' ).length >= 160 ? 'red' : 'inherit' }}>
                    NOTE: Responses longer than 160 characters will be split into multiple messages. Current characters: { ( this.state.finalResponseMessage || '' ).length }
                  </span>
                </>
              }
            /> */}

            {
              !canEdit ?
                null
              :
                <div className={classes.updateButtonDiv}>
                  <PromiseButton
                    onProcess={this.onUpdateSettings}
                    buttonProps={{
                      variant: 'contained',
                      color: 'secondary',
                      size: 'small'
                    }}
                  >
                    Update
                  </PromiseButton>
                </div>
            }
          </Paper>
        </Grid>


        <Grid item xs={6} id={EMBED_DIV_ID}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Embedding the Virtual Work Request &amp; Widget</Typography>

            <div
              className={classes.tagPreviewImagesWrapper}
            >
              <div
                className={classes.tagPreviewImagesDiv}
              >
                <img
                  src={'https://storage.googleapis.com/go-boost-partners-public/virtual/assets/forms_embed_widget_example.png'}
                  className={classes.leftTagPreviewImage}
                />

                <img
                  src={'https://storage.googleapis.com/go-boost-partners-public/virtual/assets/forms_embed_widget_example_1.png'}
                  className={classes.leftTagPreviewImage}
                />
              </div>

              <div
                className={classes.tagPreviewImagesDiv}
              >
                {
                  virtualFormDesign.previewImageUrl ?
                    <img
                      src={virtualFormDesign.previewImageUrl}
                      className={classes.tagPreviewImage}
                    />
                  :
                    null
                }

                {
                  (
                    virtualFormDesign.backgroundImageUrl &&
                    companyVirtualFormDesign.backgroundImageUrl &&
                    virtualFormDesign.backgroundImageUrl !== companyVirtualFormDesign.backgroundImageUrl
                  ) ?
                    <Typography variant='caption'>Note: Your Virtual Work Request Design uses a different background image than the default. Your Virtual Work Request Form may look different.</Typography>
                  :
                    null
                }
              </div>
            </div>

            <Typography
              variant="body1"
              className={classes.scriptTagText}
            >
              Before this Virtual Work Request Design can go live, the following code must be on
              your website. Place this script tag on the webpage that will host your Virtual Work
              Request Design. Note: this step is done automatically on your GoBoost website.
            </Typography>

            <TextField
              variant="outlined"
              multiline
              value={this.scriptTagText(virtualFormDesign.embedScriptUrl, companyVirtualFormDesign.embedId)}
              className={classes.codeBlock}
            />

            {
              !this.canParseFromClipboard() ?
                null
              :
                <div className={classnames(classes.updateButtonDiv, classes.scriptTagSection)}>
                  <Button
                    onClick={() => this.copyScriptTagTextToClipboard(virtualFormDesign.embedScriptUrl, companyVirtualFormDesign.embedId)}
                    variant='contained'
                    color='secondary'
                    size='small'
                  >
                    Copy to Clipboard
                  </Button>
                </div>
            }

            <Typography
              variant="body1"
              className={classes.scriptTagText}
            >
              Use the Virtual Work Request widget to seamlessly direct customers to the
              Virtual Work Request Design on your website. Provide an anchor element,
              such as a div or span, with the id attribute set to 'go-boost-forms-widget'.
            </Typography>

            <TextField
              variant="outlined"
              multiline
              value={`<div id="go-boost-forms-widget"></div>`}
              className={classnames(classes.codeBlock, classes.scriptTagSection)}
            />

            <Typography
              variant="body1"
              className={classes.scriptTagText}
            >
              Embed your Virtual Work Request Design on your website by providing
              an anchor element, such as a div or span, with the id attribute set to
              'go-boost-forms-embed'. Note: this Virtual Work Request will only work
              on&nbsp;{ this.state.url }.
            </Typography>

            {
              canEmbed ?
                <TextField
                  variant="outlined"
                  multiline
                  value={`<div id="go-boost-forms-embed"></div>`}
                  className={classnames(classes.codeBlock, classes.scriptTagSection)}
                />
              :
                <Typography
                  variant='caption'
                  className={classes.upgradeText}
                >
                  Upgrade to a paid plan. Contact support@goboost.com to learn more.
                </Typography>
            }
          </Paper>
        </Grid>


        <Grid item xs={6} id='logo'>
          <Paper className={classes.paper}>
            <Typography variant="h6">Background Image</Typography>

            <div style={{ textAlign: 'center' }}>
              <ImageUpload
                existingImageUrl={companyVirtualFormDesign.backgroundImageUrl}
                onChangeImage={this.onChangeBackgroundImage}
              />
              <Typography variant="caption">Supported Image Formats: JPG, PNG, GIF</Typography>
            </div>

            {
               canEdit ?
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <PromiseButton
                      onProcess={this.uploadBackgroundImage}
                      buttonProps= {{
                        variant: 'contained',
                        color: 'secondary',
                        type: 'submit',
                        id: 'update-logo',
                        name: 'update-logo',
                        size: 'small'
                      }}
                    >
                      Update
                    </PromiseButton>
                  </Grid>
                :
                  null
            }

          </Paper>
        </Grid>
      </Grid>
    );
  }
}


CompanyVirtualFormDesign.defaultProps = {
  selectedVirtualFormDesignIds: []
}


const styles = theme => ({
  paper: {
    padding: 16,
  },
  add: {
    textAlign: 'right'
  },
  isRequiredText: {
    color: 'red'
  },
  isRequired: {
    color: 'red',
    fontSize: 10,
    marginLeft: 5
  },
  addButtonGrid: {
    textAlign: 'right'
  },
  bottomPaper: {
    marginTop: 25
  },
  codeBlock: {
    width: '100%',
    backgroundColor: 'whitesmoke'
  },
  textField: {
    marginTop: 25
  },
  updateButtonDiv: {
    width: '100%',
    textAlign: 'right',
    marginTop: 15
  },
  scriptTagText: {
    marginTop: 15,
    marginBottom: 15
  },
  scriptTagSection: {
    marginBottom: 15
  },
  tagPreviewImagesWrapper: {
    display: 'flex',
    marginBottom: 15
  },
  tagPreviewImagesDiv: {
    width: '50%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  leftTagPreviewImage: {
    maxWidth: '45%',
    maxHeight: '80%',
    marginRight: '5%',
    margin: 'auto',
  },
  tagPreviewImage: {
    maxHeight: 175,
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    borderRadius: 2
  },
  upgradeText: {
    marginBottom: 25
  },
  viewVirtualWorkRequest: {
    marginRight: 15
  },
  headline: {
    maxWidth: '50%',
    wordBreak: 'break-word'
  }
});

export default withProvider(
  CompanySitesProfileProvider,
  withContext(
    CompanyVirtualProfileContext,
    CompanySitesProfileContext,
    VirtualFormDesignContext,
    CompanyRoleContext,
    MediaItemsContext,
    SnackbarContext,
    PlanContext,
    withStyles(styles)(
      CompanyVirtualFormDesign
    )
  )
);
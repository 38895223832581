import React from 'react';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import SocialDashboard from 'Social/SocialDashboard/SocialDashboard';

const socialDashboard = props => (
  <SocialDashboard
    roleTypePath="organizations"
    roleTypeId={props.organization.id}
    currentUserRole={props.currentUserRole}
    getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
    isSearchCompaniesVisible={true}
  />
);

export default withContext(
  OrganizationRoleContext,
  socialDashboard
);
import React from 'react';
import Axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withContext, MaxWidth } from 'kn-react';
import { AuthContext, SnackbarContext } from 'go-boost-library-react';
import { Link } from 'react-router-dom';
import history from '../history';


class Forgot extends React.Component {

  state = {
    email: '',
    token: this.props.match.reset_token
  }

  componentDidMount() {
    if(this.props.hasToken()){
        this.props.showSnackbar(`You're already signed in.`);
        history.push('/');
      }
  }

  onSubmit = e => {
    e.preventDefault();

    if(!this.state.email.length){
      return this.props.showSnackbar('Please enter a valid email.');
    }

    return Axios.post(
      '/api/core/auth/forgot',
      {
        email: this.state.email
      }
    )
    .then(response => {
      this.props.history.push('/sign_in')
      this.props.showSnackbar('Check your email to reset your password.')
    })
  }

  render() {
    return (
      <MaxWidth maxWidth={300}>
          <form onSubmit={this.onSubmit} style={{ marginTop: 50, marginBottom: 25}}>

                <TextField
                  label="Email"
                  type="email"
                  onChange={e => this.setState({ email: e.target.value })}
                  value={this.state.email}
                  fullWidth
                  style={{ marginBottom: 25 }}
                  margin="normal"
                />

                <Button
                  variant="contained"
                  style={{ marginTop: 15 }}
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  SEND RESET INSTRUCTIONS
                </Button>

                <Button
                  component={Link}
                  to="/sign_in"
                  style={{ marginTop: 15 }}
                  color="primary"
                  fullWidth
                >
                  Sign in
                </Button>
            </form>
        </MaxWidth>
    );
  }
}


export default withContext(AuthContext, SnackbarContext, Forgot);

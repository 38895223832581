import React from 'react';
import { format } from 'kn-react';
import { withStyles, Button, Paper, Typography } from '@material-ui/core';
import { Incrementer, Alert } from 'go-boost-library-react';
import boostStrategies from '../../BoostStrategy/boostStrategies';
import creditCardFeePercent from 'Ads/CompanyAds/creditCardFeePercent';
import paperPadding from 'paperPadding';


class BoostStrategyForm extends React.Component {


  activeBoostStrategy = boostStrategyName => {
    boostStrategyName = boostStrategyName || this.props.boostStrategyName;
    return boostStrategies[boostStrategyName];
  }

  isMonthly = boostStrategyName => {
    boostStrategyName = boostStrategyName || this.props.boostStrategyName;
    return boostStrategyName === boostStrategies.monthly.name;
  }

  setStrategy = boostStrategy => {
    let { boostTotalCents } = this.props;
    boostTotalCents = Math.max(boostStrategy.minimumAmountCents, boostTotalCents);

    const isMonthly = this.isMonthly(boostStrategy.name);

    this.onBoostStrategyChange({
      boostTotalCents,
      boostStrategyName: boostStrategy.name,
      monthlyMaxCents: isMonthly ? boostTotalCents : null,
    });
  }



  decrementAmount = () => {
    let {
      incrementAmountCents,
      boostTotalCents,
      monthlyMaxCents
    } = this.props;

    const minimumAmountCents = this.activeBoostStrategy().minimumAmountCents;

    boostTotalCents = Math.max(
      minimumAmountCents,
      boostTotalCents - incrementAmountCents
    );

    if (this.isMonthly()) {
      monthlyMaxCents = boostTotalCents;
    };

    this.onBoostStrategyChange({ boostTotalCents, monthlyMaxCents });
  }


  incrementAmount = () => {
    let { incrementAmountCents, maximumAmountCents, boostTotalCents, monthlyMaxCents } = this.props;

    boostTotalCents = Math.min(
      maximumAmountCents,
      boostTotalCents + incrementAmountCents
    );

    if (this.isMonthly()) {
      monthlyMaxCents = boostTotalCents;
    };

    this.onBoostStrategyChange({ boostTotalCents, monthlyMaxCents });
  }


  onBoostStrategyChange = newProps => {
    const { boostStrategyName, boostTotalCents, monthlyMaxCents } = this.props;
    this.props.onBoostStrategyChange({
      boostStrategyName,
      boostTotalCents,
      monthlyMaxCents,
      ...newProps,
    });
  }



  strategyButton = (boostStrategy) => {
    const { classes, boostStrategyName } = this.props;
    const isActive = boostStrategyName === boostStrategy.name;

    return (
      <Button
        classes={{ root: isActive ? classes.strategyButtonActive + ' ' + classes.strategyButton : classes.strategyButton }}
        onClick={() => this.setStrategy(boostStrategy)}
      >
        <div className={isActive ? classes.strategyTextActive : classes.strategyText}>
          {boostStrategy.displayName}
        </div>
      </Button>
    );
  }


  render() {
    const { classes, boostTotalCents } = this.props;
    const activeBoostStrategy = this.activeBoostStrategy();


    const totalFeePercent = format.percent(
      this.props.plan.adsManagementFeePercent + creditCardFeePercent
    );

    return (
      <Paper className={classes.paperRoot}>
        <Incrementer
          label="BOOST AMOUNT"
          onIncrement={this.incrementAmount}
          onDecrement={this.decrementAmount}
          value={format.money(boostTotalCents / 100 || 0)}
        />


        {
          boostTotalCents === activeBoostStrategy.minimumAmountCents ?
            <div style={{ marginTop: 25 }}>
              <Alert warning>
                {format.money(boostTotalCents / 100)} is the minimum Boost Amount for the {activeBoostStrategy.displayName} Boost Strategy.
              </Alert>
            </div>
            : null
        }


        <div className={classes.toggleStrategyWrapper}>
          {this.strategyButton(boostStrategies.starter)}
          {this.strategyButton(boostStrategies.monthly)}
          {this.strategyButton(boostStrategies.aggressive)}
        </div>


        <Typography variant="body2" className={classes.strategyDescription} paragraph={true}>
          {activeBoostStrategy.description.call(this)}
        </Typography>

        <Typography variant="caption" color="primary">
          Please note that {totalFeePercent} of the Boost Amount is used to cover Program Management and Media Placement.
          </Typography>
      </Paper>
    );
  }

}


BoostStrategyForm.defaultProps = {
  onBoostStrategyChange: () => { },
  incrementAmountCents: 50 * 100,
  maximumAmountCents: 50000 * 100,
};


const styles = theme => ({
  paperRoot: {
    padding: paperPadding,
  },
  toggleStrategyWrapper: {
    marginTop: 30,
    textAlign: 'center',
  },
  strategyButton: {
    width: 105,
    backgroundColor: '#f1f1f1',
    borderRadius: 0,
  },
  strategyButtonActive: {
    backgroundColor: theme.palette.primary.main,
  },
  strategyText: {
    color: 'gray',
  },
  strategyTextActive: {
    color: 'white',
  },
  strategyDescription: {
    marginTop: 20,
    textAlign: 'justify',
    color: '#555',
  },
});


export default withStyles(styles)(BoostStrategyForm);
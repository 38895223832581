import React from 'react';
import { PromiseButton } from 'go-boost-library-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, TextField, withStyles } from '@material-ui/core';
import OrganizationAutosuggest from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/OrganizationAutosuggest/OrganizationAutosuggest';


class NewExternalDataDialog extends React.Component  {
  state = {
    organizationName: '',
    organizationId: '',
    externalId: '',
    externalData: '',
    isAdvancedShown: false
  }

  componentDidUpdate = oldProps => {
    if( this.props.open !== oldProps.open ){
      this.setState({
        organizationName: '',
        organizationId: '',
        externalId: '',
        externalData: '',
        isAdvancedShown: false
      });
    }
  }

  render(){
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle>
          New External Data
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            className={classes.dialogContentText}
          >
            Please enter the organization this data will belong to.
          </DialogContentText>

          <OrganizationAutosuggest
            organizationName={this.state.organizationName}
            onChangeInputValue={organizationName => this.setState({ organizationName })}
            onSuggestionSelected={organization => this.setState({ organizationName: organization.name, organizationId: organization.id })}
            organizationId={this.props.organizationId}
          />

          <DialogContentText
            className={classes.dialogContentText}
          >
            Please specify the External ID, if present.
          </DialogContentText>

          <TextField
            fullWidth
            label='External ID'
            variant='outlined'
            value={this.state.externalId}
            onChange={e => this.setState({ externalId: e.target.value })}
            onBlur={() => this.setState({ externalId: this.state.externalId.trim() })}
            className={classes.textField}
          />

          <div
            className={classes.advancedLink}
          >
            <Link
              onClick={() => this.setState({ isAdvancedShown: !this.state.isAdvancedShown })}
              style={{ color: 'grey' }}
            >
              Advanced
            </Link>
          </div>

          {
            !this.state.isAdvancedShown ?
              null
            :
              <>
                <DialogContentText
                  className={classes.dialogContentText}
                >
                  Please provide External Data, if any.
                </DialogContentText>

                <TextField
                  multiline
                  fullWidth
                  value={this.state.externalData}
                  onChange={e => this.setState({ externalData: e.target.value })}
                  variant='outlined'
                  InputProps={{
                    style: { fontSize: 15 }
                  }}
                  className={classes.code}
                />
              </>
          }
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            onClick={this.props.onClose}
            style={{ float: 'left' }}
          >
            Close
          </Button>

          <PromiseButton
            variant='contained'
            color='primary'
            onProcess={() => this.props.onSubmit({
              organizationId: this.state.organizationId,
              externalId: this.state.externalId,
              externalData: this.state.externalData,
            })}
          >
            Create
          </PromiseButton>
        </DialogActions>
      </Dialog>
    )
  }
}


const styles = theme => ({
  code: {
    backgroundColor: '#eee',
    marginTop: 5
  },
  textField: {
    marginTop: 5
  },
  dialogContentText: {
    marginTop: 25,
    marginBottom: 0
  },
  advancedLink: {
    textAlign: 'center',
    marginTop: 25,
    marginBottom: 25
  }
})


NewExternalDataDialog.defaultProps = {
  open: false,
  organizationId: '',
  onClose: () => {},
  isValidOrganizationExternalData: () => {},
  onSubmit: () => Promise.reject()
}


export default withStyles(styles)(
  NewExternalDataDialog
)
import React from 'react';
import Axios from 'axios';
import { PromiseButton, SnackbarContext, Ability, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, formHelpers, AutoError, log } from 'kn-react';

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Button, withStyles, MenuItem, Typography, Grid, Divider } from '@material-ui/core';

import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';


export const SERVICETITAN = 'ServiceTitan';

export const JOB = 'Job';
export const LOCATION = 'Location';
export const CUSTOMER = 'Customer';



export const EXTERNAL_SOURCE_OPTIONS = {
  [ SERVICETITAN ]: [
    {
      name: JOB,
      secondaryExternalSourceIdHelperText: 'Please enter the Job #.',
      createUrl: e => `https://go.servicetitan.com/#/Job/Index/${ e.externalSourceId }`
    },
    {
      name: LOCATION,
      secondaryExternalSourceIdHelperText: `Please enter the Location's name.`,
      createUrl: e => `https://go.servicetitan.com/#/Location/${ e.externalSourceId }`
    },
    {
      name: CUSTOMER,
      secondaryExternalSourceIdHelperText: `Please enter the Customer's name.`,
      createUrl: e => `https://go.servicetitan.com/#/Customer/${ e.externalSourceId }`
    },
  ]
};



export const DEFAULT_STATE = {
  source: null,
	sourceId: null,
	externalSource: null,
	externalSourceType: null,
	secondaryExternalSourceId: null,
}


class ExternalAssetConnectionDialog extends React.Component {
  state = {
    ...DEFAULT_STATE
  }



  componentDidMount = () => {
    return this.constructState();
  }



  componentDidUpdate = oldProps => {
    if( this.props.open && oldProps.open !== this.props.open ){
      return this.constructState();
    }
  }



  constructState = () => {
    const { externalAccessConnection } = this.props;

    if( !externalAccessConnection ){
      return this.setState({
        ...DEFAULT_STATE,
        source: this.props.source || '',
        sourceId: this.props.sourceId || '',
      });
    }


    return this.setState({
      source: this.props.source || externalAccessConnection.source || '',
      sourceId: this.props.sourceId || externalAccessConnection.sourceId || '',
      externalSource: externalAccessConnection.externalSource || '',
      externalSourceType: externalAccessConnection.externalSourceType || '',
      secondaryExternalSourceId: externalAccessConnection.secondaryExternalSourceId || '',
    });
  }



  validateStep = () => {
    const baseValidations = {
      source: {
        presence: { message: 'Please select the type of GoBoost Asset you are connecting to.' }
      },
      sourceId: {
        presence: { message: 'Please select the GoBoost Asset you are connecting to.' }
      },
      externalSource: {
        presence: { message: 'Please select the source of the External Asset you are connecting to.' }
      },
      externalSourceType: {
        presence: { message: 'Please select the type of External Asset you are connecting to.' }
      },
      secondaryExternalSourceId: {
        presence: { message: 'Please enter an identifier for the External Asset.' }
      },
    };

    return formHelpers.validate(this.state, baseValidations);
  }



  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }



  onError = error => {
    log('error', error);
    this.props.showSnackbar(error.response.data.message);
  }



  onSubmit = () => {
    let updateOrCreate;
    let message = 'External Asset Connect was successfully ';

    if( this.props.externalAccessConnection ){
      updateOrCreate = this.onUpdateExternalAccessConnection;
      message += 'updated.';
    } else {
      updateOrCreate = this.onCreateExternalAccessConnection;
      message += 'created.';
    }


    return this.validateStep().then(
      updateOrCreate
    ).then(
      () => this.props.showSnackbar( message )
    ).then(
      this.props.onUpdateOrCreate
    ).catch(AutoError.catch.bind(this));;
  }



  onUpdateExternalAccessConnection = () => {
    return Axios.put(
      `/api/core/external_asset_connections/${ this.props.externalAccessConnection.id }`,
      { secondary_external_source_id: this.state.secondaryExternalSourceId },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
  }



  onCreateExternalAccessConnection = () => {
    return Axios.post(
      `/api/core/external_asset_connections`,
      {
        source: this.state.source,
        source_id: this.state.sourceId,
        external_source: this.state.externalSource,
        external_source_type: this.state.externalSourceType,
        secondary_external_source_id: this.state.secondaryExternalSourceId,
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
  }



  render(){
    const { classes } = this.props;

    const canEdit = this.props.currentUserRole.roleHasAbility( Ability.EDIT_EXTERNAL_ASSET_CONNECTION );
    const isShutdown = this.props.companyVirtualProfile && this.props.companyVirtualProfile.isShutdown;

    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth='md'
        fullWidth
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >

        <DialogTitle>
          { this.props.externalAccessConnection ? 'Edit' : 'Add' } External Connection
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <TextField
            select
            required
            fullWidth
            label='External Asset'
            value={this.state.externalSource || ''}
            onChange={e => this.setState({ externalSource: e.target.value })}
            variant='outlined'
            className={classes.textField}
            disabled={Boolean(this.props.externalAccessConnection)}
          >
            {
              Object.keys( EXTERNAL_SOURCE_OPTIONS ).map(
                (s, i) => (
                  <MenuItem value={s} key={i}>{ s }</MenuItem>
                )
              )
            }
          </TextField>

          <TextField
            select
            required
            fullWidth
            label='External Asset Type'
            value={this.state.externalSourceType || ''}
            onChange={e => this.setState({ externalSourceType: e.target.value })}
            variant='outlined'
            className={classes.textField}
            disabled={Boolean(this.props.externalAccessConnection)}
          >
            {
              !this.state.externalSource ?
                <MenuItem value={''}></MenuItem>
              :
                EXTERNAL_SOURCE_OPTIONS[ this.state.externalSource ].map(
                (t, i) => (
                  <MenuItem value={t.name} key={i}>{ t.name }</MenuItem>
                )
              )
            }
          </TextField>

          <TextField
            required
            fullWidth
            label='External Asset Identifier'
            value={this.state.secondaryExternalSourceId || ''}
            onChange={e => this.setState({ secondaryExternalSourceId: e.target.value })}
            onBlur={e => this.setState({ secondaryExternalSourceId: e.target.value.trim() })}
            variant='outlined'
            className={classes.textField}
            helperText={(
              !this.state.externalSource || !this.state.externalSourceType ?
                null
              :
                EXTERNAL_SOURCE_OPTIONS[ this.state.externalSource ].find(
                  s => s.name === this.state.externalSourceType
                ).secondaryExternalSourceIdHelperText
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={this.props.onClose}
            color='primary'
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          {
            !isShutdown && canEdit ?
              <PromiseButton
                buttonProps={{
                  variant: 'contained',
                  color: 'primary',
                }}
                onProcess={this.onSubmit}
              >
                { this.props.externalAccessConnection ? 'Edit' : 'Add' }
              </PromiseButton>
            :
              null
          }
        </DialogActions>
      </Dialog>
    )
  }
}


ExternalAssetConnectionDialog.defaultProps = {
  externalAccessConnection: null,
  open: false,
  onClose: () => {},
  onUpdateOrCreate: () => {},
  source: null,
  sourceId: null,
}


const styles = theme => ({
  dialogContent: {
    paddingTop: 10
  },
  textField: {
    marginBottom: 30
  },
  multipleTypeTitle: {
    marginBottom: 15
  },
  divider: {
    marginBottom: 15
  },
  cancelButton: {
    marginRight: 15
  },
  deleteButtonDiv: {
    width: '100%',
    textAlign: 'right',
    marginBottom: 15
  }
})


export default withContext(
  CompanyVirtualProfileContext,
  CompanyRoleContext,
  SnackbarContext,
  withStyles(styles)(
    ExternalAssetConnectionDialog
  )
)
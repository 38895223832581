import React from 'react';

import { PromiseButton, SnackbarContext, CompanyRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';
import { withContext, MaxWidth } from 'kn-react';

import { Grid, Typography } from '@material-ui/core';

import PaymentMethodForm from './PaymentMethodForm/PaymentMethodForm';

const paymentMethod = props => (
  <MaxWidth maxWidth={650}>
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Payment Method

          &nbsp;

          <KnowledgeBaseButton
            path={'/update_payment_information'}
            tooltipTitle={'Learn how to update your payment method'}
          />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <PaymentMethodForm
          clearOnSave={true}
          children={paymentMethodForm => (
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <PromiseButton
                buttonProps={{
                  fullWidth: false,
                  variant: 'contained',
                  color: 'primary',
                  type: 'submit',
                }}
                type="submit"
                onProcess={() => {
                  const action = props.company.stripeLastFour ? 'updated' : 'created';

                  return props.findOrCreateCustomer()
                    .then(
                      paymentMethodForm.onClickSave
                    )
                    .then(() => {
                      props.showSnackbar(`Payment method was successfully ${action}.`);
                    })
                    .catch(error => {
                      if (error.message) {
                        props.showSnackbar(error.message);
                      }
                    })
                }}
              >
                Save
              </PromiseButton>
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  </MaxWidth>
);

export default withContext(
  SnackbarContext,
  CompanyRoleContext,
  paymentMethod
);
import React from 'react';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import { GoogleAccountContext } from 'GoogleAccount/GoogleAccountProvider';
import ReviewsDashboard from '../../ReviewsDashboard/ReviewsDashboard';

const reviewsDashboard = props => {
  const googleAccountPresent = Boolean(props.googleAccount.id && props.googleAccountIsSignedIn());
  const facebookAccountPresent = Boolean(props.facebookAccount.id && props.facebookAccountIsSignedIn());

  return(
  <ReviewsDashboard
    roleTypePath="companies"
    roleTypeId={props.company.id}
    currentUserRole={props.currentUserRole}
    getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
    isSearchCompaniesVisible={false}
    googleAccountPresent = { googleAccountPresent }
    facebookAccountPresent = { facebookAccountPresent }
  />);
};

export default withContext(
  CompanyRoleContext,
  GoogleAccountContext,
  FacebookAccountContext,
  reviewsDashboard,
);
import React from 'react';

import { withContext } from 'kn-react';
import { Typography, withStyles, Grid } from '@material-ui/core';

import EmbedScriptTag, { embedScriptSource } from './EmbedScriptTag';
import ReviewsStars from './ReviewsStars';
import EmbedReviewsList from './EmbedReviewsList';
import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';


class ReviewsEmbedding extends React.Component {
  // componentDidMount = () => {
  //   const scriptTag = document.createElement('script');


  //   scriptTag.defer = true;
  //   scriptTag.type = 'application/javascript';
  //   scriptTag.src = embedScriptSource();
  //   scriptTag.setAttribute('data-gb-reviews-embed-id', this.props.companyReviewsProfile.embedId);


  //   document.head.appendChild(
  //     scriptTag
  //   );
  // }



  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">Embed Your Reviews</Typography>
        </Grid>

        <Grid item xs={12}>
          <EmbedScriptTag />
        </Grid>

        <Grid item xs={12}>
          <ReviewsStars />
        </Grid>

        <Grid item xs={12}>
          <EmbedReviewsList />
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 5
  },
  avatarText: {
    lineHeight: '30px'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  paperRoot: {
    padding: 16
  },
  codeBlock: {
    width: '100%',
    backgroundColor: 'whitesmoke'
  }
})

ReviewsEmbedding.defaultProps = {
  onEdit: siteId => {},
  onAdd: () => {},
  loading: true,
  onUpdateCompanyReviewSite: () => {},
}

export default withContext(
  CompanyReviewsProfileContext,
  withStyles(styles)(ReviewsEmbedding)
)
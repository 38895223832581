import React from 'react';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import { withContext } from 'kn-react';
import { AuthContext, removeStoredCurrentUserRoleId } from 'go-boost-library-react';

const sign_out = props => {
  if(props.credentials) {
    Axios.post('/api/core/auth/sign_out', {}, { headers: props.getAuthHeaders() });
  }

  props.removeCredentials();
  removeStoredCurrentUserRoleId();

  return (
    <Redirect to="/sign_in"/>
  );
}

export default withContext(AuthContext, sign_out);
import React from 'react';
import * as d3 from 'd3';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { StarsRatingDisplay, HorizontalPercentBar } from 'go-boost-library-react';
import ReviewRatingDistribution from 'Reviews/ReviewRatingDistribution';


export const ratingColors = {
  5: '#7dc8a2',
  4: '#afd889',
  3: '#ffd855',
  2: '#ffb24f',
  1: '#ff8c5f',
};


export default props => {


  const ratings = Object.keys(ratingColors).sort(d3.descending);
  const countsByRating = {};

  ratings.forEach(r => countsByRating[r] = 0);
  props.ratingDistribution.forEach(r => countsByRating[r.rating] = r.count);

  const counts = Object.values(countsByRating);
  const sum = d3.sum(counts);
  const max = d3.max(counts);
  const weightedAverage = d3.sum(props.ratingDistribution, d => +d.rating * d.count ) / sum;


  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Typography variant='subtitle1' style={{ marginTop: 10 }}>Rating Distribution</Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', textAlign: 'center' }}>
          <StarsRatingDisplay
            average={weightedAverage}
          />
          <Typography variant='caption'>{ d3.format(',')(sum)} { sum === 1 ? 'review' : 'reviews' }</Typography>
        </div>
      </Grid>

      <Grid item xs={12} sm={8}>
        <ReviewRatingDistribution
          ratings={ratings}
          countsByRating={countsByRating}
          max={max}
          sum={sum}
        />
      </Grid>
    </Grid>

  );
}






import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Index from './Index';
import CampaignTemplate from './CampaignTemplate/CampaignTemplate';
import OfflineEventSet from './OfflineEventSet/OfflineEventSet';


class FacebookTemplates extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {

    };
  }

  render(){
    return (
      <Switch>
        <Route exact path="/ads/facebook_templates" component={ Index } />
        <Route exact path="/ads/facebook_templates/campaigns/:id" component={ CampaignTemplate } />
        <Route exact path="/ads/facebook_templates/offline_event_sets/:id" component={ OfflineEventSet } />
      </Switch>
    );
  }

}

export default FacebookTemplates;
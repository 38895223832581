import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'kn-react';

import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';


import * as d3 from 'd3';
import { OverTimeChart, ChangeMetric, FormIcon, HandClickIcon } from 'go-boost-library-react';
import { LocationOn, People, Visibility, ThumbUp } from '@material-ui/icons';

import ImpressionsChart, { ImpressionsMetric } from 'Social/SocialCharts/ImpressionsChart';
import UniqueImpressionsChart, { UniqueImpressionsMetric } from 'Social/SocialCharts/UniqueImpressionsChart';
import ClicksChart, { ClicksMetric } from 'Social/SocialCharts/ClicksChart';
import LikesChart, { LikesMetric } from 'Social/SocialCharts/LikesChart';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

class SocialDashboardSummaryContent extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };


  render() {
    const { classes } = this.props;
    const { value } = this.state;


    const impressionsMetric = <ImpressionsMetric
      statsTotals={this.props.statsTotals}
      statsChange={this.props.statsChange}
    />;

    const uniqueImpressionsMetric = <UniqueImpressionsMetric
      statsTotals={this.props.statsTotals}
      statsChange={this.props.statsChange}
    />;

    const clicksMetric = <ClicksMetric
      statsTotals={this.props.statsTotals}
      statsChange={this.props.statsChange}
    />;

    const likesMetric = <LikesMetric
        statsTotals={this.props.statsTotals}
        statsChange={this.props.statsChange}
      />;


    return (
      <div className={classes.root}>
        <Hidden mdDown>
          <Tabs variant='fullWidth' value={value} onChange={this.handleChange}>
            <Tab variant='fullWidth' icon={impressionsMetric} />
            <Tab variant='fullWidth' icon={uniqueImpressionsMetric} />
            <Tab variant='fullWidth' icon={clicksMetric} />
            <Tab variant='fullWidth' icon={likesMetric} />
          </Tabs>
        {value === 0 && <ImpressionsChart statsChartData={this.props.statsChartData}/>}
        {value === 1 && <UniqueImpressionsChart statsChartData={this.props.statsChartData}/>}
        {value === 2 && <ClicksChart statsChartData={this.props.statsChartData}/>}
        {value === 3 && <LikesChart statsChartData={this.props.statsChartData}/>}
      </Hidden>

      <Hidden lgUp>
        <div>{impressionsMetric}</div>
        <div>{uniqueImpressionsMetric}</div>
        <div>{clicksMetric}</div>
        <div>{likesMetric}</div>
      </Hidden>
      </div>
    );
  }
}


SocialDashboardSummaryContent.defaultProps = {
  statsChartData: [],
  statsTotals: {},
  statsChange: {},
}

export default withStyles(styles)(SocialDashboardSummaryContent);
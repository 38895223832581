import React from 'react';
import { withContext, format } from 'kn-react';
import { Grid, Paper, withStyles, Typography,  MenuItem, TextField } from '@material-ui/core';
import { Call, LocationOn, Work as WorkIcon, AlarmOn, Payment } from '@material-ui/icons';
import { OverTimeChart, ChangeMetric, ServicesColors, UserRoleContext } from 'go-boost-library-react';

import CompanyAutosuggestDialog from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/CompanyAutosuggest/CompanyAutosuggestDialog';
import FetchedOpportunitiesTable from 'Opportunities/FetchedOpportunitiesTable';

import { ChannelContext } from 'Channels/ChannelProvider';

import { getTimePeriodMoment, getStartDate } from 'SharedDashboard/sharedDashboard';
import VirtualWorkRequestMap from './VirtualWorkRequestMap';



class VirtualDashboardContent extends React.Component {

  state = {
    value: 0
  }

  render(){
    const { classes } = this.props;

    const companyIds = this.props.companies.map(c => c.id);

    const opportunitiesStartDate = getStartDate( getTimePeriodMoment(this.props.timePeriod) );

    const opportunitiesLineStyles = {
      'Virtual Work Requests': {
        type:"monotone",
        dataKey:"Virtual Work Requests",
        stroke:ServicesColors.sites.primary,
        activeDot: { r: 8 }
      }
    }


    const noncontactedDistribution = this.props.opportunityConversionStatusDistribution.find(
      d => d.conversionStatus === null
    );
    const noncontactedDistributionChange = this.props.opportunityConversionStatusDistributionChanges.find(
      d => d.conversionStatus === null
    );

    const contactedDistribution = this.props.opportunityConversionStatusDistribution.find(
      d => d.conversionStatus === 'contacted'
    );
    const contactedDistributionChange = this.props.opportunityConversionStatusDistributionChanges.find(
      d => d.conversionStatus === 'contacted'
    );

    const convertedDistribution = this.props.opportunityConversionStatusDistribution.find(
      d => d.conversionStatus === 'converted'
    );
    const convertedDistributionChange = this.props.opportunityConversionStatusDistributionChanges.find(
      d => d.conversionStatus === 'converted'
    );

    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Typography variant="h6">Virtual Dashboard</Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              select
              value={ this.props.timePeriod }
              onChange={e => this.props.onChangeTimePeriod(e.target.value) }
              fullWidth
              label={'Time Period'}
            >
              {
                this.props.timePeriodOptions.map((t,i) => (
                  <MenuItem value={t} key={i}>{ t.label }</MenuItem>
                ))
              }
            </TextField>
          </Grid>

          {
            this.props.isSearchCompaniesVisible ?
              <Grid item xs={12} sm={3}>
                <CompanyAutosuggestDialog
                  initialCompanies={this.props.companies}
                  roleTypeId={this.props.roleTypeId}
                  onChangeCompanies={this.props.onChangeCompanies}
                />
              </Grid>
            :
              null
          }
          <Grid item xs={12} sm={this.props.isSearchCompaniesVisible ? 6 : 9}/>

          <Grid item xs={12} >
            <Paper className={classes.dashboardItem}>
              <Grid container>
                <Grid item xs={12} sm={6} md={3}>
                  <ChangeMetric
                    title="Virtual Work Requests"
                    metric={ this.props.totalOpportunities || 0 }
                    icon={Call}
                    iconColor="#ff9a48"
                    iconBackgroundColor="rgba(255, 154, 72, 0.2)"
                    change={ this.props.totalOpportunitiesChange || '' }
                    width={260}
                    height={260}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <ChangeMetric
                    title="Noncontacted Virtual Work Requests"
                    metric={noncontactedDistribution ? noncontactedDistribution.count || 0 : 0}
                    icon={WorkIcon}
                    iconColor="#5a7af9"
                    iconBackgroundColor="rgba(90, 122, 249, 0.2)"
                    change={noncontactedDistributionChange ? noncontactedDistributionChange.metric || '' : '' }
                    width={260}
                    height={260}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <ChangeMetric
                    title="Contacted Virtual Work Requests"
                    metric={contactedDistribution ? contactedDistribution.count || 0 : 0}
                    icon={AlarmOn}
                    iconColor="#9e70ff"
                    iconBackgroundColor="rgba(158, 112, 255, 0.2)"
                    change={contactedDistributionChange ? contactedDistributionChange.metric || '' : '' }
                    width={260}
                    height={260}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <ChangeMetric
                    title="Converted Virtual Work Requests"
                    metric={convertedDistribution ? convertedDistribution.count || 0 : 0}
                    icon={Payment}
                    iconColor="#37caad"
                    iconBackgroundColor='rgba(55, 202, 173, 0.2)'
                    change={convertedDistributionChange ? convertedDistributionChange.metric || '' : '' }
                    width={260}
                    height={260}
                  />
                </Grid>
              </Grid>

              <OverTimeChart
                data={this.props.opportunitiesChartData}
                lineStyles={opportunitiesLineStyles}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} >
            <Paper className={classes.dashboardItem}>
              <div className={classes.iconAndLabel}>
                <ChangeMetric
                  title=""
                  metric={null}
                  icon={LocationOn}
                  iconColor="#5a7af9"
                  iconBackgroundColor="rgba(90, 122, 249, 0.2)"
                  width={260}
                  height={260}
                />

                <Typography variant='h4' className={classes.virtualWorkRequestLocations}>
                  Virtual Work Request Locations
                </Typography>
              </div>

              <VirtualWorkRequestMap
                opportunities={this.props.opportunities}
                includedGeoTypeIds={this.props.includedGeoTypeIds}
                excludedGeoTypeIds={this.props.excludedGeoTypeIds}
                bounds={this.props.virtualWorkRequestMapDefaultBounds}
                center={this.props.virtualWorkRequestMapCenter}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} >
            <FetchedOpportunitiesTable
              getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
              rowsPerPageOptions={[5,10]}
              roleTypePath={this.props.roleTypePath}
              roleTypeId={this.props.roleTypeId}
              channels={this.props.virtualChannels}
              showCompanyName={this.props.isSearchCompaniesVisible}
              isSpamFiltered={true}
              opportunityTableTitle={'Virtual Work Requests'}
              viewOpportunityRoute={opportunity => `/virtual/jobs/opportunities/${ opportunity.id }`}
              showViewOpportunity={this.props.showViewOpportunity}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

}




const styles = theme => ({
  root: {
    padding: 16,
    opportunityDataColors: []
  },
  dashboardItem: {
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
  },
  softwareTable: {
    overflowY: 'auto',
  },
  iconAndLabel: {
    display: 'flex'
  },
  virtualWorkRequestLocations: {
    marginTop: 25
  }
});


VirtualDashboardContent.defaultProps = {
  companies: [],
  timePeriod: {},
  timePeriodOptions: [],
  onChangeTimePeriod: () => {},
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  isSearchCompaniesVisible: false,
  onChangeCompany: () => {},
  totalOpportunities: '',
  totalOpportunitiesChange: '',
  opportunitiesChartData: [],
  opportunities: [],
  includedGeoTypeIds: [],
  excludedGeoTypeIds: [],
  opportunityConversionStatusDistribution: [],
  opportunityConversionStatusDistributionChanges: [],
  virtualWorkRequestMapDefaultBounds: [],
  virtualWorkRequestMapCenter: [],
  showViewOpportunity: false
}



export default withContext(
  UserRoleContext,
  ChannelContext,
  withStyles(styles)(
    VirtualDashboardContent
  )
)
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 512 512" x="0px" y="0px">
    <path d="M405.12 354.58s15.71 31.83 27.07 60.4a236.39 236.39 0 0 0 61.13-159c0-131.05-106.25-237.3-237.32-237.3S18.68 124.93 18.68 256 124.93 493.32 256 493.32a236.36 236.36 0 0 0 112.22-28.16 38.48 38.48 0 0 1-1.65-11.16c0-21.29 38.55-99.42 38.55-99.42zm-52.51-194.19c3.05-.59 30.41-5.35 49.74 7.38a69.57 69.57 0 0 1 24.59 27.87 12.18 12.18 0 1 1-22.33 9.72A46.74 46.74 0 0 0 389 188.1c-9.39-6.22-26.15-4.84-31.79-3.79a12.17 12.17 0 0 1-4.55-23.92zM96.22 212.68a12.17 12.17 0 0 1-11.16-17 69.57 69.57 0 0 1 24.59-27.87c19.33-12.73 46.69-8 49.74-7.38a12.17 12.17 0 0 1-4.55 23.92c-5.64-1.05-22.4-2.43-31.79 3.79a46.74 46.74 0 0 0-15.66 17.26 12.19 12.19 0 0 1-11.17 7.28zm46.27 80c-18.91 0-34.24-17.37-34.24-38.8s15.33-38.8 34.24-38.8 34.24 17.37 34.24 38.8-15.33 38.81-34.24 38.81zm180.29 100.89a12.18 12.18 0 0 1-16.38-5.3 60.76 60.76 0 0 0-53.07-29.89c-37.79 0-52.37 28.48-53 29.69a12.17 12.17 0 0 1-21.91-10.59c.84-1.79 21.42-43.44 74.89-43.44 52.13 0 73.85 41.39 74.75 43.15a12.18 12.18 0 0 1-5.28 16.38zm13.74-139.68c0-21.43 15.32-38.8 34.23-38.8S405 232.46 405 253.89s-15.33 38.8-34.24 38.8-34.24-17.37-34.24-38.8z" fill="#ffde6e"/>
    <path d="M368.22 465.16A38.55 38.55 0 0 0 443.67 454c0-7.7-5-22.84-11.48-39a238.39 238.39 0 0 1-63.97 50.16z" fill="#008fff"/>
    <path d="M432.19 415c-11.36-28.57-27.07-60.4-27.07-60.4s-38.55 78.11-38.55 99.4a38.48 38.48 0 0 0 1.65 11.16A238.39 238.39 0 0 0 432.19 415z" fill="#008fff"/>
    <path d="M253.33 334c-53.47 0-74.05 41.65-74.89 43.44a12.17 12.17 0 0 0 21.91 10.59c.61-1.21 15.19-29.69 53-29.69a60.76 60.76 0 0 1 53.07 29.89 12.17 12.17 0 0 0 21.68-11.08c-.92-1.72-22.64-43.15-74.77-43.15z" fill="#102236"/>
    <path d="M142.49 215.09c-18.91 0-34.24 17.37-34.24 38.8s15.33 38.8 34.24 38.8 34.24-17.37 34.24-38.8-15.33-38.8-34.24-38.8z" fill="#102236"/>
    <path d="M405 253.89c0-21.43-15.33-38.8-34.24-38.8s-34.23 17.37-34.23 38.8 15.32 38.8 34.23 38.8S405 275.32 405 253.89z" fill="#102236"/>
    <path d="M357.16 184.31c5.64-1.05 22.4-2.43 31.79 3.79a46.74 46.74 0 0 1 15.66 17.26 12.18 12.18 0 1 0 22.33-9.72 69.57 69.57 0 0 0-24.59-27.87c-19.33-12.73-46.69-8-49.74-7.38a12.17 12.17 0 0 0 4.55 23.92z" fill="#102236"/>
    <path d="M123.05 188.1c9.39-6.22 26.15-4.84 31.79-3.79a12.17 12.17 0 0 0 4.55-23.92c-3-.59-30.41-5.35-49.74 7.38a69.57 69.57 0 0 0-24.59 27.87 12.18 12.18 0 1 0 22.33 9.72 46.74 46.74 0 0 1 15.66-17.26z" fill="#102236"/>
  </SvgIcon>
);
import React from 'react';
import Axios from 'axios';

import { SurveyQuestions, SurveyQuestionModel, SurveyQuestionResponseModel, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, MaxWidth } from 'kn-react';

import { withStyles, Typography, LinearProgress } from '@material-ui/core';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';

class Survey extends React.Component {

  state = {
    loading: true,
    survey: {},
    questions: [],
    responseDataByQuestionId: {},
  }


  componentDidMount(){
    this.fetchQuestionsAndResponses(this.props.surveyId);
    this.sendInitialSegmentEvents();
  }

  sendInitialSegmentEvents = () => {
    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'started_boosted_service_survey', this.props.getUserRoleAuthHeaders);
  }


  fetchQuestionsAndResponses = surveyId => {
    log('fetchQuestionsAndResponses', surveyId);
    return Axios.get(
      `/api/core/surveys/${ surveyId }/questions_and_responses`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchQuestionsAndResponses response', response);

      const questions = response.data.questions.map(q => SurveyQuestionModel.fromJSON(q));
      log('fetchQuestionsAndResponses questions', questions);

      const responses = response.data.responses.map(r => SurveyQuestionResponseModel.fromJSON(r));
      log('fetchQuestionsAndResponses responses', responses);

      const responseDataByQuestionId = {};
      responses.forEach(r => responseDataByQuestionId[r.surveyQuestionId] = r.data);
      log('fetchQuestionsAndResponses responseDataByQuestionId', responseDataByQuestionId);

      this.setState({
        questions,
        responseDataByQuestionId,
        loading: false,
      });
    })

  }


  onResponseDataByQuestionIdChange = responseDataByQuestionId => {
    this.setState({ responseDataByQuestionId });
  }


  onSubmit = () => {
    return Axios.post(
      `/api/core/surveys/${this.props.surveyId}/responses`,
      { responses: this.state.responseDataByQuestionId },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(this.props.onSubmit)
    .catch(error => log('onSubmit error', error));
  }

  render(){
    if(this.state.loading){
      return <LinearProgress/>;
    }

    const { classes} = this.props;
    const { questions , responseDataByQuestionId } = this.state;

    const ProductUndoResubscriptionAlert = this.props.productUndoResubscriptionAlert;

    return (
      <MaxWidth maxWidth={'80%'}>
        <div className={classes.productUndoResubscriptionAlert}>
          <ProductUndoResubscriptionAlert />
        </div>

        <MaxWidth maxWidth={'60%'}>
          <Typography variant="h5" className={classes.heading}>
            { this.props.surveyTitle }
          </Typography>

          <SurveyQuestions
            questions={questions}
            responseDataByQuestionId={responseDataByQuestionId}
            onResponseDataByQuestionIdChange={this.onResponseDataByQuestionIdChange}
            onSubmit={this.onSubmit}
            canSubmitSurvey={this.props.canSubmitSurvey}
            onBack={this.props.onBack}
            previewUrl={this.props.previewUrl}
          />
        </MaxWidth>
      </MaxWidth>
    );
  }

}

const styles = theme => ({
  heading: {
    marginTop: 100,
    padding: 15,
  },
  productUndoResubscriptionAlert: {
    width: '100%',
    paddingTop: 15,
  }
});

Survey.defaultProps = {
  onSubmit: () => {},
  headers: {},
  canSubmitSurvey: true,
  productUndoResubscriptionAlert: () => null,
  onBack: () => null,
  previewUrl: ''
}


export default withStyles(styles)(withContext(CompanyRoleContext,  Survey));

import React from 'react';
import { withContext } from 'kn-react';
import Axios from 'axios';
import { SnackbarContext, fetchStoredTrueUserId } from 'go-boost-library-react';
import { LinearProgress } from '@material-ui/core';

import history from 'history.js';



class ReportSpam extends React.Component{
  componentDidMount = () => {
    this.reportSpamOpportunity();

    this.props.showSnackbar('Spam reported!');

    return setTimeout(
      () => history.push('/'),
      fetchStoredTrueUserId() ? 0 : 1000
    );
  }


  reportSpamOpportunity = () => {
    return Axios.post(
      `/api/core/opportunities/${ this.props.match.params.opportunityId }/report_spam`
    );
  }

  render(){
    return(
      <LinearProgress />
    );
  }
}


export default withContext(
  SnackbarContext,
  ReportSpam
)
import React from 'react';
import { CircleMarker, Tooltip } from 'react-leaflet';
import * as d3 from 'd3';
import Axios from 'axios';
import L from 'leaflet';

import { ServicesColors, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log, format } from 'kn-react';

import { People } from '@material-ui/icons';
import { Typography, withStyles } from '@material-ui/core';

import ProductSummaryMap from '../ProductSummaryMap/ProductSummaryMap';

class SocialSummaryMap extends React.Component {

  state = {
    stats: null,
    companyFeedItemStats: [],
    loading: true,
  }

  componentDidMount = () => {
    this.fetchCompanySocialProfilesStats()
      .then(this.fetchSocialMapData)
  }


  fetchCompanySocialProfilesStats = () => {
    log('fetchCompanySocialProfilesStats');

    return Axios.get(
      `/api/social/company_social_profiles/organizations/${this.props.organization.id}/stats`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchCompanySocialProfilesStats response', response);
      this.setState({ stats: response.data.stats, loading: false });
    });
  }


  fetchSocialMapData = () => {
    log('fetchSocialMapData');

    return Axios.get(
      `/api/social/company_feed_items/organizations/${this.props.organization.id}/stats`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          group_by_company: true,
          published_at_format: '%Y-%m-01'
        }
      }
    ).then(response => {
      log('fetchSocialMapData response', response);

      this.setState({ companyFeedItemStats: response.data.stats });
    });
  }


  render() {
    const { companyFeedItemStats, loading, stats } = this.state;

    if (loading) {
      return null;
    }


    const statsByCompany = {}

    companyFeedItemStats.forEach(c => {
      if( !statsByCompany[ c.company_id ] ){
        statsByCompany[ c.company_id ] = {
          latitude: c.latitude,
          longitude: c.longitude,
          impressions: 0,
          clicks: 0,
        };
      }

      statsByCompany[ c.company_id ].impressions += ( c.post_impressions || 0 );
      statsByCompany[ c.company_id ].clicks += ( c.post_engaged_users || 0 );
    });

    const totalImpressions = Object.keys( statsByCompany ).map(companyId => statsByCompany[ companyId ].impressions).reduce((a, b) => (a+b), 0);
    const totalClicks = Object.keys( statsByCompany ).map(companyId => statsByCompany[ companyId ].clicks).reduce((a, b) => (a+b), 0);
    const radiusScale = d3.scaleSqrt().domain([0, totalImpressions]).range([2.5, 12]);

    let bounds;
    if ( Object.keys( statsByCompany ).length ) {
      bounds = new L.LatLngBounds(
        Object.keys( statsByCompany ).map(
          c => [statsByCompany[ c ].latitude, statsByCompany[ c ].longitude]
        )
      );
    }

    const darkerColor = d3.rgb(ServicesColors.social.primary).darker(0.2);

    return (
      <ProductSummaryMap
        title="Social"
        titleLink="/social"
        icon={People}
        iconColor={ServicesColors.social.primary}
        bounds={bounds}
        enrolledCount={stats.is_setup_complete_count}
        incompleteCount={stats.count - stats.is_setup_complete_count}
        metric1Title={format.commas(totalImpressions)}
        metric1Caption="Total Impressions"
        metric2Title={format.commas(totalClicks)}
        metric2Caption="Total Clicks"
      >
        {
          Object.keys( statsByCompany ).map(companyId => {
            const c = statsByCompany[ companyId ];

            return (
              <CircleMarker
                key={companyId}
                center={[c.latitude, c.longitude]}
                radius={radiusScale(c.impressions)}
                weight={1}
                opacity={0.9}
                color={darkerColor}
                fillOpacity={0.6}
                fillColor={ServicesColors.social.primary}
              >
                <Tooltip>
                  <Typography variant="body2">{c.name}</Typography>
                  <Typography variant="caption">
                    {format.commas(c.impressions)} Total Impressions
                    <br/>
                    {format.commas(c.clicks)} Total Clicks
                  </Typography>
                </Tooltip>
              </CircleMarker>
            );
          })
        }
      </ProductSummaryMap>
    )
  }
}


const styles = {};

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    SocialSummaryMap
  )
);
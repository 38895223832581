import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { withContext, log } from 'kn-react';
import { BoostedServicesContext } from '../BoostedServicesProvider';
import CompanyBoostedServicesList from './CompanyBoostedServicesList/CompanyBoostedServicesList';
import SetupBoostedServicesList from './SetupBoostedServicesList/SetupBoostedServicesList';
import { CompanyAdsProfileContext } from '../../CompanyAdsProfile/CompanyAdsProfileProvider';

class BoostedServicesList extends React.Component {

  onClickSetup = boostedService => {
    log('onClickSetup', boostedService);

    this.props.createCompanyBoostedService(boostedService.id)
      .catch(error => {
        if (error.response.status === 409) {
          log('Company Boosted Service already exists. Continuing...');
        } else {
          throw error;
        }
      })
      .then(() => this.props.onClickSetup(boostedService));
  }


  onToggleCompanyBoostedService = (e,c) => {
    log('onToggleCompanyBoostedService', e, c);
    this.props.toggleCompanyBoostedService(c);
  }


  render(){
    const {
      channels,
      boostedServices,
      companyBoostedServices,
      getStartedBoostedServices,
      readyToBoostCompanyBoostedServices,
      onClickEditSurvey,
      onClickEditBoostZone,
      onClickEditBoostStrategy,
      onClickAddToBalance,
      showToggleCompanyBoostedService,
      includeSetupList,
      companyBoostedServicesSubheader,
      setupBoostedServicesSubheader,
    } = this.props;

    return (
      <Grid container spacing={16}>
        <CompanyBoostedServicesList
          channels={channels}
          boostedServices={boostedServices}
          readyToBoostCompanyBoostedServices={readyToBoostCompanyBoostedServices}
          onClickSetup={this.onClickSetup}
          onClickEditSurvey={onClickEditSurvey}
          onClickEditBoostZone={onClickEditBoostZone}
          onClickEditBoostStrategy={onClickEditBoostStrategy}
          onClickAddToBalance={onClickAddToBalance}
          onToggle={showToggleCompanyBoostedService ? this.onToggleCompanyBoostedService : undefined}
          subheader={companyBoostedServicesSubheader}
        />

        {
          includeSetupList ?
            <SetupBoostedServicesList
              channels={channels}
              getStartedBoostedServices={getStartedBoostedServices}
              companyBoostedServices={companyBoostedServices}
              onClickSetup={this.onClickSetup}
              subheader={setupBoostedServicesSubheader}
            />
          : null
        }
      </Grid>
    );
  }

}

BoostedServicesList.defaultProps = {
  showToggleCompanyBoostedService: true,
  includeSetupList: true,
  companyBoostedServicesSubheader: 'Ready to Boost',
  setupBoostedServicesSubheader: 'Get started',
};


const styles = theme => ({

});

export default withStyles(styles)(
  withContext(
    BoostedServicesContext, // boostedServices, companyBoostedServices, createCompanyBoostedService
    CompanyAdsProfileContext, // channels
    BoostedServicesList
  )
);
import { format } from 'kn-react';

const boostStrategies = {};

const boostStrategiesArray = [
  {
    name: 'starter',
    displayName: 'Starter',
    minimumAmountCents: 0 * 100,
    description: function(){
      return `Your ads will stop running after you spend your balance for this boosted service. You'll need to manually add to your balance to keep your ads running.`
    }
  },
  {
    name: 'monthly',
    displayName: 'Monthly',
    minimumAmountCents: 50 * 100,
    description: function(){
      return `Each month, we'll add ${ format.money(this.props.boostTotalCents / 100, { decimals: 0 }) } for this boosted service if your balance drops below 10% of your boost amount.`
    }
  },
  {
    name: 'aggressive',
    displayName: 'Aggressive',
    minimumAmountCents: 500 * 100,
    description: function(){
      return `We'll automatically add another ${ format.money(this.props.boostTotalCents / 100, { decimals: 0 }) } to your balance for this boosted service when your balance drops below 10% of your boost amount.`
    }
  }
];

boostStrategiesArray.forEach(b => boostStrategies[b.name] = b);

export default boostStrategies;
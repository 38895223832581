import React from 'react';
import Axios from 'axios';
import history from '../history';
import qs from 'qs';
import { Link as RouterLink } from 'react-router-dom';
import { MaxWidth, log, formHelpers, AutoError, withContext, usStatesList, canadaProvincesList } from 'kn-react';
import { PromiseButton, SnackbarContext, AuthContext, CredentialsModel, ThemeContext } from 'go-boost-library-react';
import { Grid, Link, MenuItem, TextField, Typography } from '@material-ui/core';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import GooglePlacesAutosuggest from './GooglePlacesAutosuggest/GooglePlacesAutosuggest';
import paperPadding from 'paperPadding';
import CompanyInvitationModel from './CompanyInvitationModel';
import OrganizationModel from 'Settings/Organizations/OrganizationModel';
import FreshChatButton from 'AppNavigation/freshChatButton';


export const UNITED_STATES = 'United States';
export const CANADA = 'Canada';


const COUNTRIES = [
  UNITED_STATES,
  CANADA
]


class SignUp extends React.Component {
  state = {
    loadingCompanyInvitation: true,
    companyInvitation: null,
    signUpCodeOwnerOrganization: null,
    cantFindPlace: false,
    googlePlaceId: null,
    googlePlace: null,
    name: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    postalCode: '',
    country: COUNTRIES[ 0 ],
    phoneNumber: '',
    primaryWebsite: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    code: '',
  }


  componentDidMount() {
    if (this.props.hasToken()) {
      history.push('/');
    }

    const queryParams = qs.parse(window.location.search.slice(1));
    const { code, token } = queryParams;

    log('queryParams', queryParams);

    const newState = {
      code: code || '',
      token: token || '',
    };

    if (token) {
      this.fetchCompanyInvitation(token);
    } else {
      newState.loadingCompanyInvitation = false;
    }

    this.setState(newState)
  }



  fetchCompanyInvitation = token => {
    log('fetchCompanyInvitation', token);
    return Axios.get(`/api/core/company_invitations/${token}`)
      .then(this.parseExistingCompanyInvitation)
      .catch(error => {
        log('error', error);
        if (error.response && error.response.status === 404) {
          this.props.showSnackbar(error.response.data.message)
          return;
        }
        throw error;
      })
      .finally(() => this.setState({ loadingCompanyInvitation: false }))
  }



  onCantFindPlace = e => {
    e.preventDefault();
    const { cantFindPlace, googlePlace } = this.state;

    this.setState({
      cantFindPlace: !cantFindPlace,
      googlePlace: cantFindPlace ? null : googlePlace
    });
  }


  onProcess = () => {
    return this.validate()
      .then(this.postSignUpData)
      .then(credentials => this.signInAndRedirect(credentials))
      .catch(error => {
        log('error.response', error.response)
        if(
          error.response &&
          error.response.status === 409 &&
          error.response.data &&
          error.response.data.message.toLowerCase().includes('email')
        ){
          this.props.showSnackbar(error.response.data.message);
          return;
        } else if( error.response && error.response.status === 409 ){
          this.parseExistingCompanyInvitation(error.response, this.onProcess);
          this.props.showSnackbar(error.response.data.message);
          return;
        } else if (error.response && error.response.status === 400) {
          this.props.showSnackbar(error.response.data.message);
          return;
        }

        throw error;
      }).catch(AutoError.catch.bind(this));
  }

  onSuggestionSelected = suggestion => {
    this.setState({ googlePlaceId: suggestion.place_id, googlePlace: suggestion });
  }


  onClearSuggestion = suggestion => {
    this.setState({ googlePlaceId: null, googlePlace: null });
  }

  validate = () => {
    const { cantFindPlace, password } = this.state;
    let validations = {
      phoneNumber: {
        presence: { message: `Please enter your company's phone number.` },
        length: { equalTo: 10, message: `Company phone number must be 10 digits.` }
      },
      firstName: {
        presence: { message: 'Please enter your first name.' }
      },
      lastName: {
        presence: { message: 'Please enter your last name.' }
      },
      email: {
        presence: { message: 'Please enter your email address.' },
        email: { message: 'Please enter a valid email address.' }
      },
      password: {
        presence: { message: 'Please enter a password.' },
        length: { atLeast: 8, message: 'Password must be at least 8 characters.' }
      },
      confirmPassword: {
        presence: { message: 'Please confirm your password.' },
        equalTo: { value: password, message: 'Passwords must match.' }
      }
    };

    if (cantFindPlace) {
      validations = {
        name: {
          presence: { message: `Please enter your company's name.` }
        },
        addressLineOne: {
          presence: { message: `Please enter your company's address.` }
        },
        city: {
          presence: { message: `Please enter your company's city.` }
        },
        state: {
          presence: { message: `Please select your company's state.` }
        },
        postalCode: {
          presence: { message: `Please enter your company's postal code.` },
        },
        ...validations,
      };
    } else {
      validations = {
        googlePlaceId: {
          presence: { message: `Please search for and select your company.` }
        },
        ...validations,
      };
    }


    return formHelpers.validate(this.state, validations);
  }

  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }

  postSignUpData = () => {
    return Axios.post(
      '/api/core/sign_up',
      {
        google_place_id: this.state.googlePlaceId,
        name: this.state.name,
        address_line_one: this.state.addressLineOne,
        address_line_two: this.state.addressLineTwo,
        city: this.state.city,
        state: this.state.state,
        postal_code: this.state.postalCode,
        country: this.state.country,
        phone_number: this.state.phoneNumber,
        primary_website: this.state.primaryWebsite,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        confirm_password: this.state.confirmPassword,
        code: this.state.code,
        hostname: window.location.hostname,
        token: this.state.token,
      }
    )
      .then(response => {
        log('postData', response);
        const credentials = CredentialsModel.fromJSON(response.data.credentials);
        log('credentials', credentials);
        return credentials;
      })
      .catch(error => {
        throw new PostSignUpDataError(error);
      });
  }


  parseExistingCompanyInvitation = (response, callback) => {
    log('parseExistingCompanyInvitation', response);
    const companyInvitation = CompanyInvitationModel.fromJSON(response.data.company_invitation);
    const signUpCodeOwnerOrganization = OrganizationModel.fromJSON(response.data.sign_up_code_owner_organization);
    this.setState(
      {
        companyInvitation,
        signUpCodeOwnerOrganization,
        code: response.data.sign_up_code.code,
        email: companyInvitation.email,
      },
      () => callback ? callback() : null
    );
  }

  onPostSignUpDataError = error => {
    log('error.response', error.response);
    this.props.showSnackbar((error.response && error.response.data) ? error.response.data.message : 'Something went wrong.');
  };


  signInAndRedirect = credentials => {
    this.props.setCredentials(credentials);
    history.push('/');
  }

  onClickChat = () => {
    const { fcWidget } = window;
    if (!fcWidget) {
      log('Cannot find fcWidget!');
      return;
    }

    fcWidget.open();
    fcWidget.show();
  }


  render() {
    const { classes } = this.props;
    const { loadingCompanyInvitation, signUpCodeOwnerOrganization, companyInvitation, googlePlace, cantFindPlace } = this.state;

    if (loadingCompanyInvitation) {
      return <LinearProgress />;
    }

    const disableEmailAndCode = this.state.companyInvitation;


    const statesList = (
      this.state.country === UNITED_STATES ?
        usStatesList
      :
        canadaProvincesList
    );


    return (
      <div className={classes.root}>
        <MaxWidth maxWidth={600}>
          <Paper className={classes.paperRoot}>
            {
              signUpCodeOwnerOrganization ?
                <div style={{ textAlign: 'center' }}>
                  <Typography variant="caption">JOIN</Typography>
                  <Typography variant="h5" color="primary" gutterBottom>{this.props.theme.appTitle}</Typography>
                  <Typography variant="body2" paragraph>Hi, {companyInvitation.email}. You're invited to join the {signUpCodeOwnerOrganization.name} team.</Typography>
                </div>
                :
                <>
                  <div style={{ float: 'right' }}>
                    <FreshChatButton/>
                  </div>
                  <Typography variant="h4">Sign Up</Typography>
                </>
            }

            <Grid container spacing={16}>
              <Grid item sm={12}>

                {
                  !cantFindPlace ?
                    <GooglePlacesAutosuggest
                      initialInputValue={this.state.name}
                      onSuggestionSelected={this.onSuggestionSelected}
                      onChangeValue={newValue => this.setState({ name: newValue || '' })}
                    />
                    :
                    <TextField
                      autoComplete="new-password"
                      label="Company Name"
                      name="company-name"
                      value={this.state.name}
                      onChange={e => this.setState({ name: e.target.value })}
                      onBlur={() => formHelpers.trim.call(this, 'name')}
                      margin="normal"
                      fullWidth
                    />
                }

                <Typography variant="caption">
                  <Link href="#" onClick={this.onCantFindPlace}>
                    {
                      cantFindPlace ?
                        'Search for your company info...'
                        :
                        `Can't find your company info? Enter it instead.`
                    }
                  </Link>
                </Typography>


                {
                  !cantFindPlace && googlePlace ?
                    <Paper elevation={1} style={{ marginTop: 15 }}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={googlePlace.structured_formatting.main_text}
                            secondary={googlePlace.structured_formatting.secondary_text}
                          />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Remove" onClick={this.onClearSuggestion}>
                              <CloseIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Paper>
                    : null
                }
              </Grid>


              {
                this.state.cantFindPlace ?
                  <>
                    <Grid item sm={12}>
                      <TextField
                        label="Company Address Line One"
                        name="company-address-line-one"
                        value={this.state.addressLineOne}
                        onChange={e => this.setState({ addressLineOne: e.target.value })}
                        onBlur={() => formHelpers.trim.call(this, 'addressLineOne')}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="Company Address Line Two"
                        name="company-address-line-two"
                        value={this.state.addressLineTwo}
                        onChange={e => this.setState({ addressLineTwo: e.target.value })}
                        onBlur={() => formHelpers.trim.call(this, 'addressLineTwo')}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="Company City"
                        name="company-city"
                        value={this.state.city}
                        onChange={e => this.setState({ city: e.target.value })}
                        onBlur={() => formHelpers.trim.call(this, 'city')}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="company-state">Company State</InputLabel>
                        <Select
                          native
                          value={this.state.state}
                          name="company-state"
                          onChange={e => this.setState({ state: e.target.value })}
                        // inputProps={{
                        //   name: 'company-state',
                        //   id: 'company-state',
                        // }}
                        >
                          {
                            [''].concat( statesList ).map(s => <option key={s} value={s}>{s}</option>)
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <TextField
                        label="Company Postal Code"
                        name="company-postal-code"
                        value={this.state.postalCode}
                        onChange={e => this.setState({ postalCode: e.target.value })}
                        onBlur={() => formHelpers.trim.call(this, 'postalCode')}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <TextField
                        label="Company Country"
                        name="company-country"
                        value={this.state.country}
                        onChange={e => this.setState({ country: e.target.value })}
                        margin="normal"
                        fullWidth
                        select
                      >
                        {
                          COUNTRIES.map((c, i) => (
                            <MenuItem value={c} key={i}>{c}</MenuItem>
                          ))
                        }
                      </TextField>
                    </Grid>
                  </>
                  :
                  null
              }

              <Grid item sm={6}>
                <TextField
                  label="Company Phone Number"
                  name="company-phone-number"
                  onChange={e => {
                    const phoneNumber = e.target.value.replace(/[^\d]/g, '');
                    this.setState({ phoneNumber });
                  }}
                  inputProps={{
                    maxLength: 10,
                    type: 'tel',
                    pattern: '[0-9]*',
                    noValidate: true
                  }}
                  value={this.state.phoneNumber}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="Company Primary Website"
                  name="company-primary-website"
                  value={this.state.primaryWebsite}
                  onChange={e => this.setState({ primaryWebsite: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, 'primaryWebsite')}
                  margin="normal"
                  fullWidth
                  inputProps={{
                    type: 'url',
                  }}
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="First Name"
                  name="first-name"
                  value={this.state.firstName}
                  onChange={e => this.setState({ firstName: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, 'firstName')}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="Last Name"
                  name="last-name"
                  value={this.state.lastName}
                  onChange={e => this.setState({ lastName: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, 'lastName')}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  label="Email"
                  name="email"
                  value={this.state.email}
                  disabled={disableEmailAndCode}
                  onChange={disableEmailAndCode ? undefined : e => this.setState({ email: e.target.value })}
                  onBlur={disableEmailAndCode ? undefined : () => formHelpers.trim.call(this, 'email')}
                  type="email"
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, 'password')}
                  margin="normal"
                  fullWidth
                  helperText="At least eight characters."
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="Confirm Password"
                  name="confirm-password"
                  type="password"
                  value={this.state.confirmPassword}
                  onChange={e => this.setState({ confirmPassword: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, 'confirmPassword')}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  label="Sign Up Code"
                  name="sign-up-code"
                  value={this.state.code}
                  disabled={disableEmailAndCode}
                  onChange={disableEmailAndCode ? undefined : e => this.setState({ code: e.target.value })}
                  onBlur={disableEmailAndCode ? undefined : () => formHelpers.trim.call(this, 'code')}
                  margin="normal"
                  helperText={
                    this.state.companyInvitation ?
                      ''
                      :
                      "Leave this blank if you don't have one."
                  }
                  fullWidth
                />
              </Grid>

              <Grid item sm={12}>
                <PromiseButton onProcess={this.onProcess} fullWidth>
                  Sign Up
                </PromiseButton>
              </Grid>

              <Grid item sm={12}>
                <Typography className={classes.signInLinkRoot}>
                  Already have an account?&nbsp;
                  <Link
                    component={RouterLink}
                    to="/sign_in"
                    className={classes.signInLink}
                  >
                    Sign in
                  </Link>
                </Typography>
              </Grid>

            </Grid>
          </Paper>

          <Typography className={classes.termsText}>
            By signing up, you agree to our <Link component="a" href="https://www.goboost.com/terms-of-service" target="_blank">Terms of Service</Link> and <Link component="a" href="https://www.goboost.com/privacy-policy" target="_blank">Privacy Policy</Link>
          </Typography>

        </MaxWidth>
      </div>
    );
  }

}

const styles = theme => ({
  root: {
    padding: 16,
    marginTop: 50,
    paddingBottom: 50,
  },
  paperRoot: {
    padding: paperPadding
  },
  heading: {
    textAlign: 'center'
  },
  signInLinkRoot: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  signInLink: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  termsText: {
    textAlign: 'center',
    marginTop: 15,
  }
});

export default withStyles(styles)(
  withContext(
    AuthContext,
    SnackbarContext,
    ThemeContext,
    SignUp
  )
);


class PostSignUpDataError extends Error {
  constructor(error) {
    super(error.message);
    this.name = 'PostSignUpDataError';
    this.response = error.response;
  }
}
import React from 'react';
import { withProvider, withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';

import CompanyReporting from './CompanyReporting/CompanyReporting';
import OrganizationReporting from './OrganizationReporting/OrganizationReporting';

const Reporting = props => (
  props.currentUserRole.isCompanyRole() ?
    <CompanyReporting/>
  :
    <OrganizationReporting/>
);

export default withContext(
  UserRoleContext,
  Reporting
);
import React from 'react';
import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { MaxWidth, withContext, log } from 'kn-react';
import { Alert, KnowledgeBaseButton } from 'go-boost-library-react';
import BoostedServicesList from '../../BoostedServices/BoostedServicesList/BoostedServicesList';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import AdsUndoResubscriptionAlert from 'Ads/CompanyAds/AdsUndoResubscriptionAlert/AdsUndoResubscriptionAlert';

class ConfigureBoostedServices extends React.Component {

  componentDidMount() {
    trackCurrentUserSegmentEvent(
      this.props.currentUserRole.userId,
      'started_boosted_services_step',
      this.props.getUserRoleAuthHeaders,
      { funnel: true }
    );
  }

  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions);
  }

  onClickNext = () => {
    return this.props.transitionTo('next', this.props.transitions);
  }


  onClickSetup = boostedService => {
    // Create the company boosted service here?
    log('onClickSetup', boostedService);

    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'started_boosted_service_survey_step', this.props.getUserRoleAuthHeaders, boostedService);

    return this.props.transitionTo('setup', this.props.transitions, { boostedServiceId: boostedService.id });
  }

  onClickEditSurvey = boostedService => {
    this.props.transitionTo('editSurvey', this.props.transitions, { boostedServiceId: boostedService.id });
  }

  onClickEditBoostZone = boostedService => {
    this.props.transitionTo('editBoostZone', this.props.transitions, { boostedServiceId: boostedService.id });
  }

  onClickEditBoostStrategy = boostedService => {
    this.props.transitionTo('editBoostStrategy', this.props.transitions, { boostedServiceId: boostedService.id });
  }


  render(){
    const { classes, companyBoostedServices, selectedPlanId } = this.props;


    const selectedPlanBoostedServices = this.props.filterBoostedServicesByPlanId(selectedPlanId);

    const getStartedBoostedServices = selectedPlanBoostedServices.filter(b => {
      const c = companyBoostedServices.find(c => c.boostedServiceId == b.id);
      return !c || !c.isConfigured();
    });


    const configuredCompanyBoostedServices = companyBoostedServices.filter(c => {
      const inPlan = selectedPlanBoostedServices.find(b => b.id == c.boostedServiceId);
      return (inPlan && c.isConfigured()) || c.hasBoostBalance();
    });

    const hasConfiguredServices = configuredCompanyBoostedServices.length;

    let nextButton = (
      <Button
        color="primary"
        variant="contained"
        onClick={this.onClickNext}
        disabled={!hasConfiguredServices}
      >
        Next
      </Button>
    );

    if (!hasConfiguredServices) {
      nextButton = (
        <Tooltip title="Please setup a Boosted Service to continue.">
          <span>
            { nextButton }
          </span>
        </Tooltip>
      )
    }

    return (
      <MaxWidth maxWidth={650}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <AdsUndoResubscriptionAlert />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5">
                Boosted Services

                &nbsp;

                <KnowledgeBaseButton
                  path={'/google_ads_vs_facebook_ads'}
                  tooltipTitle={'Learn more about the different types of Boosted Services'}
                />
              </Typography>
            </Grid>

            {/* <CancelSetupButton /> */}

            <Grid item xs={12}>
              {
                (
                  (getStartedBoostedServices && getStartedBoostedServices.length)
                  ||
                  (configuredCompanyBoostedServices && configuredCompanyBoostedServices.length)
                ) ?
                  <BoostedServicesList
                    getStartedBoostedServices={getStartedBoostedServices}
                    readyToBoostCompanyBoostedServices={configuredCompanyBoostedServices}
                    showToggleCompanyBoostedService={false}
                    onClickEditSurvey={this.onClickEditSurvey}
                    onClickEditBoostZone={this.onClickEditBoostZone}
                    onClickEditBoostStrategy={this.onClickEditBoostStrategy}
                    onClickAddToBalance={this.onClickEditBoostStrategy}
                    onClickSetup={this.onClickSetup}
                  />
                :
                  <Alert warning>
                    Please contact us for help setting up your first Boosted Service.
                  </Alert>
              }
            </Grid>

            <Grid item xs={6}>
              <Button color="primary" variant="outlined" onClick={this.onClickBack}>Back</Button>
            </Grid>

            <Grid item xs={6} style={{ textAlign: 'right' }}>
            { nextButton }
            </Grid>


          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  },
});

export default withStyles(styles)(
  withContext(
    TransitionContext,
    ConfigureBoostedServices
  )
);

import React from 'react';
import { format } from 'kn-react';
import { withStyles, Typography, TextField,FormControl,FormLabel, RadioGroup, Radio, FormControlLabel, Checkbox } from '@material-ui/core';
import { KnowledgeBaseButton } from 'go-boost-library-react';
import AvailableDomainsSearch from './AvailableDomainsSearch/AvailableDomainsSearch';
import domainYearlyCostCents from './domainYearlyCostCents';
import paperPadding from 'paperPadding';

class DomainSettings extends React.Component {


  onChangeIsByoDomain = e => {
    const isByoDomain = e.target.value.toString() === 'true';
    this.props.onChangeIsByoDomain(isByoDomain);
  }


  render() {
    const { classes, isByoDomain, byoDomain, newDomain } = this.props;

    return (
      <div className={classes.root} >
        <FormControl component="fieldset" className={classes.radioButtons}>
          <RadioGroup
            value={isByoDomain.toString()}
            onChange={this.onChangeIsByoDomain}
          >
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="I will use my existing domain."
              id='select-existing-domain'
              name='select-existing-domain'
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label={(
                <>
                  I will purchase a new domain for ${ format.money(domainYearlyCostCents / 100) } per year.

                  &nbsp;

                  <KnowledgeBaseButton
                    path={'/domains'}
                    tooltipTitle={'Learn more about purchasing your domain through GoBoost'}
                  />
                </>
              )}
              id='select-purchase-domain'
              name='select-purchase-domain'
            />
          </RadioGroup>
        </FormControl>


          {
            isByoDomain ?
              <>
              <TextField
                fullWidth
                label="Existing Domain"
                placeholder="mycompany.com"
                helperText={`Don't include "http(s)" or "www".`}
                value={byoDomain}
                onChange={e => this.props.onChangeByoDomain( (e.target.value || '').trim() )}
                id='existing-domain-input'
                name='existing-domain-input'
              />
              </>
            : null
          }

          {
            isByoDomain.toString() === 'false' ?
              <AvailableDomainsSearch
                domain={this.props.newDomain}
                onChangeNewDomain={this.props.onChangeNewDomain}
                ownedDomain={this.props.ownedDomain}
              />
            : null
          }

      </div>
    );
  }

}


const styles = theme => ({
  root: {
    padding: paperPadding
  },
})



export default withStyles(styles)(DomainSettings);

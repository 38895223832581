import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '@material-ui/core';

import history from 'history.js';
import UploadMediaItem from './UploadMediaItem';


export default () => (
  <UploadMediaItem
    onNavigate={() => history.push(`/social/media_items`)}
    cancelButton={
      <Button
        component={RouterLink}
        to={`/social/media_items`}
        variant='outlined'
        color='primary'
        style={{ marginRight: 15 }}
      >
        Cancel
      </Button>
    }
  />
)
/*global analytics*/
import Axios from 'axios';
import { log } from 'kn-react';

export const trackCompanyOwnerSegmentEvent = (companyId, eventName, getUserRoleAuthHeaders) => {
  if (window.location.origin.includes('localhost')) {
    return;
  }

  return Axios.post(
    `/api/core/segment_service/companies/${ companyId }/owners`,
    { event_name: eventName },
    { headers: getUserRoleAuthHeaders() }
  )
}


export const trackCurrentUserSegmentEvent = (userId, eventName, getUserRoleAuthHeaders, data={}) => {
  log('event', eventName)


  trackClientSideCurrentUserSegmentEvent(
    eventName,
    data
  );


  if (window.location.origin.includes('localhost')) {
    return;
  }

  return Axios.post(
    `/api/core/segment_service/users/${userId}`,
    { data, event_name: eventName },
    { headers: getUserRoleAuthHeaders() }
  )
}



export const trackClientSideCurrentUserSegmentEvent = (eventName, data={}) => {
  log('trackClientSideCurrentUserSegmentEvent event', eventName)

  if (
    window.location.origin.includes('localhost') ||
    typeof analytics === 'undefined'
  ) {
    return;
  }


  return analytics.track(
    eventName,
    data
  );
}
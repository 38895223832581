import React from 'react';

import { log, withContext } from 'kn-react';
import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import history from 'history.js';


class CreateCampaignTemplate extends React.Component {
  state = {
    campaignId: '',
    boostedServiceId: '',
  }


  onCreate = () => {
    Axios.post(
      `/api/ads/facebook_templates/campaigns/create`,
      {
        campaign_id: this.state.campaignId,
        boosted_service_id: this.state.boostedServiceId,
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('onCreate', response);
      this.props.onClose();
      this.props.showSnackbar('Campaign Template created.');
      history.push(`/ads/facebook_templates/campaigns/${ response.data.facebook_campaign_template.id }`);
    });
  }



  render() {
    const { boostedServices } = this.props;

    return (
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Campaign Template</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a new Campaign Template, enter the ID and select a boosted service below.
            </DialogContentText>


            <TextField
              autoFocus
              margin="dense"
              id="campaign-id"
              label="Campaign ID"
              value={this.state.campaignId}
              onChange={ e => this.setState({ campaignId: (e.target.value || '').trim() }) }
              type="text"
              fullWidth
            />


            <FormControl margin="normal">
              <InputLabel>Boosted Service</InputLabel>
              <Select
                native
                value={this.state.boostedServiceId}
                onChange={ e => this.setState({ boostedServiceId: e.target.value })}
                fullWidth
              >
                <option value=""></option>
                {
                  boostedServices.map(b => (
                    <option value={b.id} key={b.id}>{ b.name } - { b.id} </option>
                  ))
                }
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.onCreate}
              disabled={this.state.campaignId.length < 8 || ! this.state.boostedServiceId}
              color="primary"
              variant="contained"
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

CreateCampaignTemplate.defaultProps = {
  boostedServices: [],
};

export default withContext(UserRoleContext, SnackbarContext, CreateCampaignTemplate);
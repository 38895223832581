import React from 'react';
import Axios from 'axios';
import AppNavigation from '../AppNavigation/AppNavigation';
import { withContext, format } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationModel from './NotificationModel';


class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      notifications: [],
      loading: true
    }
  }

  componentDidMount = () => {
    this.fetchNotifications();
    setTimeout(this.seenNotifications,3000)
  }

  fetchNotifications = () => {
    const { page } = this.state;
    Axios.post(
      'api/core/notifications',
      {
        page:page
      },
      {headers:this.props.getUserRoleAuthHeaders()}
    )
    .then(response=>{
      const notifications = response.data.notifications.map(notification=> {
        return (NotificationModel.fromJSON(notification))
      })
      this.setState({ notifications, loading:false });
    })
  }

  dismiss = notification_id => {
    Axios.get(
      `api/core/notifications/${notification_id}/dismiss`,
      {headers:this.props.getUserRoleAuthHeaders()}
    )
    .then(response=>{
      this.setState({ notifications:this.state.notifications.filter(notification => notification.id !== notification_id) });
    })
  }

  seenNotifications = () => {
    Axios.post(
      `api/core/notifications`,
      {
        notification_ids: this.state.notifications.map(
          notification => notification.id
        )
      },
      { headers:this.props.getUserRoleAuthHeaders() }
    )
  }

  render(){

    const { classes } = this.props;

    return (
      <AppNavigation title="Notifications">
        Notifications
        {
          (this.state.loading) ? <CircularProgress className={classes.progress}/> : this.state.notifications.map(notification=>{
            return (
              <Card className={classes.notification}>
                <CardContent>
                  <Typography variant="h5" style={{ fontWeight: '500', color: 'black' }}>
                    {notification.message}
                  </Typography>
                  <Typography variant="h7" style={{ fontWeight: '500', color: '#999' }}>
                    {format.date(notification.createdAt)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant='contained' color='primary' onClick={() => this.dismiss(notification.id)}>Dismiss</Button>
                </CardActions>
              </Card>
            )
          })
        }
      </AppNavigation>
    );
  }

}

const styles = theme => ({
  notification: {
    width: '40%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '3%'
  },
  progress: {
    theme:theme
  }
})

export default withContext(UserRoleContext, withStyles(styles)(Notifications));
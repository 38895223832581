import React from 'react';

import { SnackbarContext, PromiseButton, UserRoleContext, ToggleMapModel, Ability, CompanyRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Grid, withStyles, Typography } from '@material-ui/core';

import paperPadding from 'paperPadding';
import ToggleMapForm from 'Ads/CompanyAds/BoostedServices/BoostZone/ToggleMapForm/ToggleMapForm';


class CompanyServiceArea extends React.Component {
  state = {
    serviceArea: new ToggleMapModel()
  }

  componentDidMount = () => {
    const { company } = this.props;
    const { serviceArea } = company;
    if(serviceArea){
      this.setState({ serviceArea });
    }
  }

  onToggleMapChange = toggleMapModel => {
    if (this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_INFO )){
      this.setState({ serviceArea: toggleMapModel });
    }
  }

  onProcess = () => {
    const { serviceArea } = this.state;
    return this.props.updateCompany({ serviceArea })
    .then(() => this.props.showSnackbar('Service Area updated successfully.'));
  }

  render(){
    const { company, classes, isShutdown } = this.props;
    const { serviceArea } = this.state;

    return (
      <div className={classes.root}>
        <Typography variant="h5">
          Set your Company Service Area

          &nbsp;

          <KnowledgeBaseButton
            path={'/service-areas'}
            tooltipTitle={'Learn more about your Service Area'}
          />
        </Typography>
        <Typography variant="caption">This is the area your company serves.</Typography>

          <ToggleMapForm
            toggleMapModel={serviceArea}
            onToggleMapChange={this.onToggleMapChange}
            latitude={company.latitude}
            longitude={company.longitude}
          >
            <Grid container>
              <Grid item xs={9}/>
              {
                (this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_INFO )) ?
                  <Grid item xs={3}>
                    <PromiseButton
                      buttonProps={{
                        disabled: isShutdown || serviceArea.includedGeographies.length < 1,
                        color: 'primary',
                        variant: 'contained',
                        fullWidth: true
                      }}
                      onProcess={this.onProcess}
                    >
                      Save
                    </PromiseButton>
                  </Grid>
                :
                  null
              }

            </Grid>
          </ToggleMapForm>
      </div>
    );
  }

}


CompanyServiceArea.defaultProps = {
  isShutdown: false
}

const styles = theme => ({
  root: { padding: paperPadding }
});


export default withContext(
  CompanyRoleContext,
  UserRoleContext,
  SnackbarContext,
  withStyles(styles)(CompanyServiceArea)
);

import React from 'react';
import Axios from 'axios';
import { withContext, log } from 'kn-react';
import { withStyles, Grid, Typography, LinearProgress} from '@material-ui/core';
import { UserRoleContext } from 'go-boost-library-react';

import CompanyTable from 'Settings/Companies/CompanyTable/CompanyTable';
import OrganizationUserCard from 'Settings/Users/OrganizationUsers/OrganizationUserCard/OrganizationUserCard'
import OrganizationUserInvitationCard from 'Settings/UserInvitations/OrganizationUserInvitations/OrganizationUserInvitationCard/OrganizationUserInvitationCard';
import OrganizationModel from '../OrganizationModel';
import OrganizationTable from '../OrganizationTable/OrganizationTable';
import SignUpCodeModel from 'SignUp/SignUpCodeModel';
import CompanyInvitationTable from 'Settings/Organizations/CompanyInvitations/CompanyInvitationTable/CompanyInvitationTable';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';

class OrganizationDetails extends React.Component {
  state = {
    loading: true,
    organization: null,
    defaultCompanySignUpCode: null,
    lastUserInvitedAt: null
  }

  componentDidMount = () => {
    this.fetchOrganization();
  }

  componentDidUpdate = (oldProps) => {
    if (this.props.match.params.id !== oldProps.match.params.id) {
      this.fetchOrganization();
    }
  }

  fetchOrganization = () => {
    log('fetchOrganization');
    return Axios.get(
      `/api/core/organizations/${this.props.match.params.id}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchOrganization response', response);
      const organization = OrganizationModel.fromJSON(response.data.organization);
      const defaultCompanySignUpCode = SignUpCodeModel.fromJSON(response.data.default_company_sign_up_code)
      this.setState({ organization, defaultCompanySignUpCode, loading: false });
    });
  }

  onInviteUser = () => {
    this.setState({ lastUserInvitedAt: Date.now().toString() })
  }


  render(){
    const { classes } = this.props;
    const { loading, organization, defaultCompanySignUpCode, lastUserInvitedAt } = this.state;

    if(loading) {
      return <LinearProgress/>;
    }

    return (
      <div className={classes.root}>
        <Grid
          spacing={16}
          container
        >
          <KnowledgeBaseLaunchBanner />
          <Grid item xs={12}>
            <Typography variant="h5">{organization.name || ''}</Typography>
            <Typography variant="caption" color="textSecondary">
             Default Sign Up Code: {defaultCompanySignUpCode.code || ''}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <OrganizationTable
              organization={organization}
              showDescendantsInfo={true}
            />
          </Grid>

          <Grid item xs={12}>
            <OrganizationUserCard
              organization={organization}
              onInviteUser={this.onInviteUser}
              showDescendantsInfo={true}
            />
          </Grid>

          <Grid item xs={12}>
            <OrganizationUserInvitationCard
              organization={organization}
              lastUserInvitedAt={lastUserInvitedAt}
              newInvitationPath="/organizations/:id/user_invitations/new"
              showDescendantsInfo={true}
            />
          </Grid>

          <Grid item xs={12}>
            <CompanyTable
              organization={organization}
              showDescendantsInfo={true}
            />
          </Grid>

          <Grid item xs={12}>
            <CompanyInvitationTable
              organization={organization}
              showDescendantsInfo={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

}

const styles = theme => ({
  root: {
    padding: 16
  }
});

export default withStyles(styles)(withContext(UserRoleContext, OrganizationDetails));
import React from 'react';
import PropTypes from 'prop-types';

import SitesDashboardContent from './SitesDashboardContent';


const timePeriodOptions = [
  { unit: 'month', label: 'All time' },
  { unit: 'day', numberOfUnits: 7, label: 'Past 7 days' },
  { unit: 'day', numberOfUnits: 30, label: 'Past 30 days' },
  { unit: 'day', numberOfUnits: 90, label: 'Past 90 days' },
  { unit: 'month', numberOfUnits: 12, label: 'Past year' },
];



class SitesDashboard extends React.Component {

  state = {
    // Filters
    timePeriod: timePeriodOptions[2],
    reviewSource: 'all',
    companies: [],
  }


  onChangeTimePeriod = timePeriod => {
    this.setState(
      { timePeriod },
      this.fetchData
    );
  }

  onChangeCompanies = companies => {
    this.setState(
      { companies },
      this.fetchData
    );
  }



  render(){
    return (
      <SitesDashboardContent
        dataAsOfDate={this.props.dataAsOfDate}
        onChangeCompanies={this.onChangeCompanies}
        companies={this.state.companies}
        timePeriod={this.state.timePeriod}
        timePeriodOptions={timePeriodOptions}
        onChangeTimePeriod={this.onChangeTimePeriod}
        roleTypePath={this.props.roleTypePath}
        roleTypeId={this.props.roleTypeId}
        getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
        isSearchCompaniesVisible={this.props.isSearchCompaniesVisible}
      />
    );
  }

}

SitesDashboard.propsTypes = {
  roleTypePath: PropTypes.string.isRequired,
  roleTypeId: PropTypes.number.isRequired,
  getUserRoleAuthHeaders: PropTypes.func.isRequired,
};

SitesDashboard.defaultProps = {
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  isSearchCompaniesVisible: false,
};


export default SitesDashboard;
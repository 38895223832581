import React from 'react';
import { Button, Grid,  Typography, withStyles } from '@material-ui/core';
import { MaxWidth, withContext } from 'kn-react';
import { SiteDesignsContext } from './SiteDesignsProvider';
import SiteDesignList from './SiteDesignList/SiteDesignList';


class SiteDesigns extends React.Component {

  render() {
    const { classes, siteDesigns } = this.props;

    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>


            <Grid item xs={12}>
              <Typography variant="h6" color="primary">Current Designs</Typography>
            </Grid>

            <Grid item xs={12}>
              <SiteDesignList
                availableSiteDesigns={siteDesigns}
                canLaunchSite={false}
              />
            </Grid>
          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: 0
  },
});

export default withContext(
  SiteDesignsContext,
  withStyles(styles)(
    SiteDesigns
  )
);
import React from 'react';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import AdsDashboard from '../../AdsDashboard/AdsDashboard';

const adsDashboard = props => (
  <AdsDashboard
    {...props}
    roleTypePath="organizations"
    roleTypeId={props.organization.id}
    getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
  />
);

export default withContext(
  OrganizationRoleContext,
  adsDashboard
);
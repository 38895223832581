import React from 'react';
import { withContext, format } from 'kn-react';
import { Grid, Paper, withStyles, Typography,  MenuItem, TextField } from '@material-ui/core';

import SocialDashboardSummary from './SocialDashboardSummary';
import CompanyAutosuggestDialog from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/CompanyAutosuggest/CompanyAutosuggestDialog';
import FeedItemPreview from 'Social/FeedItem/FeedItemPreview';

import { ChannelContext } from 'Channels/ChannelProvider';

import SocialTimeline from 'Social/Timeline/SocialTimeline';
import { KnPieChart } from 'go-boost-library-react';
import ReactionsDistribution from 'Social/ReactionsDistribution';


class SocialDashboardContent extends React.Component {

  state = {
    value: 0
  }

  timelineContent = feedItem => {
    const Preview = FeedItemPreview(feedItem.feedType);

    return <Preview
      text={feedItem.feedContent.text}
      url={feedItem.feedContent.url}
      meta={feedItem.feedContent.linkMeta}
      attachments={feedItem.feedContent.attachments}
      feedType={feedItem.feedContent.feedType}
    />
  }

  render(){
    const { classes } = this.props;

    const companyIds = this.props.companies.map(c => c.id);

    const reachPieChartData = [
      { name: 'Fan Impressions', value: this.props.statsTotals.totalImpressionsFan },
      { name: 'Non-Fan Impressions', value: this.props.statsTotals.totalImpressions - this.props.statsTotals.totalImpressionsFan }
    ];



    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Typography variant="h6">Sites Dashboard</Typography>

            {
              this.props.dataAsOfDate ?
                <Typography variant="caption" color="textSecondary">
                  Data as of { format.dateTime(this.props.dataAsOfDate) }
                </Typography>
              : null
            }
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              select
              value={ this.props.timePeriod }
              onChange={e => this.props.onChangeTimePeriod(e.target.value) }
              fullWidth
              label={'Time Period'}
            >
              {
                this.props.timePeriodOptions.map((t,i) => (
                  <MenuItem value={t} key={i}>{ t.label }</MenuItem>
                ))
              }
            </TextField>
          </Grid>

          {
            this.props.isSearchCompaniesVisible ?
              <Grid item xs={12} sm={3}>
                <CompanyAutosuggestDialog
                  initialCompanies={this.props.companies}
                  roleTypeId={this.props.roleTypeId}
                  onChangeCompanies={this.props.onChangeCompanies}
                />
              </Grid>
            :
              null
          }
          <Grid item xs={12} sm={this.props.isSearchCompaniesVisible ? 6 : 9}/>

          <Grid item xs={12} >
            <Paper className={classes.dashboardItem}>
              <SocialDashboardSummary
                companyIds={companyIds}
                timePeriod={this.props.timePeriod}
                roleTypePath={this.props.roleTypePath}
                roleTypeId={this.props.roleTypeId}
                getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                channelIds={this.props.sitesChannels.map(c => c.id)}
                statsChartData={this.props.statsChartData}
                statsTotals={this.props.statsTotals}
                statsChange={this.props.statsChange}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper className={classes.dashboardItem}>
              <Typography variant="h6">Reach</Typography>

              <div style={{ width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                <KnPieChart
                  data={reachPieChartData}
                  width={400}
                  height={400}
                  innerRadius={100}
                  total={format.commas(this.props.statsTotals.totalImpressions)}
                  centerLabel={ this.props.statsTotals.totalImpressions === 1 ? 'Impressions' : 'Impressions'}
                />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper className={classes.dashboardItem}>
              <Typography variant="h6">Engagement</Typography>

              <ReactionsDistribution
                reactions={this.props.reactions}
              />
            </Paper>
          </Grid>



          <Grid item xs={12} >
            <Paper className={classes.dashboardItem}>
              <Typography variant="h6">Upcoming Posts</Typography>

              <SocialTimeline
                items={this.props.feedItems}
                timelineContent={this.timelineContent}
                showEditButton={false}
                showDeleteButton={false}
                showPostButton={false}
                fetchViewLink={feedItem => `/social/feed_items/${feedItem.id}/view`}
              />
            </Paper>
          </Grid>


        </Grid>

      </div>
    );
  }

}




const styles = theme => ({
  root: {
    padding: 16,
    opportunityDataColors: []
  },
  dashboardItem: {
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
  },
  softwareTable: {
    overflowY: 'auto',
  }
});


SocialDashboardContent.defaultProps = {
  companyIds: [],
  timePeriod: {},
  timePeriodOptions: [],
  onChangeTimePeriod: () => {},
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  isSearchCompaniesVisible: false,
  onChangeCompany: () => {},
  statsChartData: [],
  statsTotals: {},
  statsChange: {},
  reactions: {},
  feedItems: []
}



export default withContext(
  ChannelContext,
  withStyles(styles)(
    SocialDashboardContent
  )
)
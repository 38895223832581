import React from 'react';
import Axios from 'axios';

import { log, withContext } from 'kn-react';
import { SnackbarContext, ToggleMap, LocationCard, UserRoleContext, ToggleMapModel, Ability } from 'go-boost-library-react';

import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { withStyles, Grid, ListItemIcon, IconButton, Paper, InputAdornment, Typography } from '@material-ui/core';

import './ToggleMapForm.css';
import AdwordsLocationModel from '../AdwordsLocationModel';
import Autosuggest from 'Autosuggest/Autosuggest';



class ToggleMapForm extends React.Component {

   state = {
    query: '',
    adwordsLocationsByGeoTypeId: {},
    // Cache geography changes in state to send changes as a ToggleMapModel
    includedGeographies: [],
    excludedGeographies: [],
    canEdit: this.props.canEdit !== null ? this.props.canEdit : this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_INFO ),
  }

  componentDidMount = () => {
    this.setToggleMapModel(this.props.toggleMapModel);
  }

  componentWillReceiveProps = (newProps) => {
    if (this.props.toggleMapModel != newProps.toggleMapModel) {
      this.setToggleMapModel(newProps.toggleMapModel);
    }
  }

  setToggleMapModel = toggleMapModel => {
    this.setState({
      includedGeographies: toggleMapModel.includedGeographies || [],
      excludedGeographies: toggleMapModel.excludedGeographies || [],
    });
  }


  onIncludedGeographiesChange = geos => {
    this.setState({
      includedGeographies: geos,
    }, () => this.onToggleMapChange());
  }

  onExcludedGeographiesChange = geos => {
    this.setState({
      excludedGeographies: geos,
    }, () => this.onToggleMapChange());
  }

  onToggleMapChange = () => {
    const { includedGeographies, excludedGeographies } = this.state;
    const toggleMapModel = new ToggleMapModel({
      includedGeographies,
      excludedGeographies,
    });
    this.props.onToggleMapChange(toggleMapModel);
  }

  onRemoveIncluded = geoTypeId => {
    if( this.state.canEdit ){
      const geos = this.state.includedGeographies.filter(g => g !== geoTypeId);
      this.onIncludedGeographiesChange(geos);
    }
  }

  onRemoveExcluded = geoTypeId => {
    if( this.state.canEdit ){
      const geos = this.state.excludedGeographies.filter(g => g !== geoTypeId);
      this.onExcludedGeographiesChange(geos);
    }
  }

  fetchAdwordsLocations = (geoTypeIds) => {
    const { includedGeographies, excludedGeographies } = this.state;
    const selectedFeatures = includedGeographies.concat(excludedGeographies);

    return Axios.post(
      '/api/ads/adwords_locations/geo_type_ids',
      { geo_type_ids: selectedFeatures.concat(geoTypeIds) }
    ).then(response => {
      log('response.data', response.data);
      const adwordsLocationData = response.data.data;
      const adwordsLocationsByGeoTypeId = {};
      adwordsLocationData.forEach(d => {
        d = AdwordsLocationModel.fromJSON(d);
        adwordsLocationsByGeoTypeId[d.censusGeoTypeId] = d;
      });

      this.setState({ adwordsLocationsByGeoTypeId });
      log('adwordsLocationsByGeoTypeId', adwordsLocationsByGeoTypeId);
    });
  }


  onMapGeoTypeChange = e => {
    log('onMapGeoTypeChange', e.target.value);
    this.setState({ mapGeoTypes: e.target.value });
  }

  onSuggestionsFetchRequested = query => {
    return Axios.get(
      `/api/ads/adwords_locations/search`,
      {
        params: {
          query,
          // limit: 25,
          census_geo_type_id_required: true
        }
      }
    ).then(response => {
      if( typeof response.data === 'object' ){
        return response.data.locations.map(
          l => AdwordsLocationModel.fromJSON(l)
        )
      }

      return []
    })
  }


  getSuggestionPrimaryValue = suggestion => {
    return suggestion.adwordsCanonicalName.split(',')[0]
  }

  getSuggestionSecondaryValue = suggestion => {
    return suggestion.adwordsCanonicalName.split(',')[1]
  }


  suggestionStartAdornment = (suggestion) => {
    const geographyCallbacks = {
      includedGeographies: {
        updateCallback: this.onIncludedGeographiesChange,
        // removeOtherGeographiesCallback: this.onRemoveExcluded,
        updateOtherGeographiesCallback: this.onExcludedGeographiesChange,
        otherGeographies: 'excludedGeographies'
      },
      excludedGeographies: {
        updateCallback: this.onExcludedGeographiesChange,
        // removeOtherGeographiesCallback: this.onRemoveIncluded,
        updateOtherGeographiesCallback: this.onIncludedGeographiesChange,
        otherGeographies: 'includedGeographies'
      }
    };

    const onClick = (e, geographiesName) => {
      e.stopPropagation();

      const callbacks = geographyCallbacks[ geographiesName ];

      const geographies = [ ...this.state[ geographiesName ] ];
      const otherGeographies = [ ...this.state[ callbacks.otherGeographies ] ];

      if( !geographies.includes( suggestion.censusGeoTypeId ) ){
        geographies.push( suggestion.censusGeoTypeId );


        callbacks.updateCallback( geographies );

        if( otherGeographies.includes( suggestion.censusGeoTypeId ) ){
          otherGeographies.splice(
            otherGeographies.findIndex(g => g === suggestion.censusGeoTypeId ),
            1
          );
        }
      }

      // Close autosuggest
      this.setState({ query: '' });

      callbacks.updateOtherGeographiesCallback( otherGeographies );
    };

    return (
      <ListItemIcon
        className={this.props.classes.listItemIcon}
      >
        <IconButton
          className={this.props.classes.iconButton}
          onClick={e => onClick(e, 'includedGeographies')}
        >
          <AddCircleOutline fontSize="small"/>
        </IconButton>

        <IconButton
          className={this.props.classes.iconButton}
          onClick={e => onClick(e, 'excludedGeographies')}
        >
          <RemoveCircleOutline fontSize="small"/>
        </IconButton>
      </ListItemIcon>
    )
  }


  onSuggestionSelected = adwordsLocation => {
    const { censusGeoTypeId } = adwordsLocation;

    // Update included/excluded geographies
    if( this.state.includedGeographies.includes( censusGeoTypeId ) ){
      const includedGeographies = [ ...this.state.includedGeographies ];
      const excludedGeographies = [ ...this.state.excludedGeographies ];

      includedGeographies.splice(
        includedGeographies.findIndex(g => g === censusGeoTypeId),
        1
      );
      excludedGeographies.push( censusGeoTypeId );

      this.onIncludedGeographiesChange( includedGeographies );
      this.onExcludedGeographiesChange( excludedGeographies );
    } else if ( this.state.excludedGeographies.includes( censusGeoTypeId ) ){
      const excludedGeographies = [ ...this.state.excludedGeographies ];

      excludedGeographies.splice(
        excludedGeographies.findIndex(g => g === censusGeoTypeId),
        1
      );

      this.onExcludedGeographiesChange( excludedGeographies );
    } else {
      const includedGeographies = [ ...this.state.includedGeographies ];

      includedGeographies.push( censusGeoTypeId );

      this.onIncludedGeographiesChange( includedGeographies );
    }

    // Update adwordsLocationsByGeoTypeId
    if( !this.state[ adwordsLocation.censusGeoTypeId ] ){
      return this.fetchAdwordsLocations([
        ...Object.keys(this.state.adwordsLocationsByGeoTypeId),
        adwordsLocation.censusGeoTypeId
      ]);
    }
  }


  render() {
    const { classes, latitude, longitude } = this.props;
    const { toggleMapModel } = this.props;
    const { adwordsLocationsByGeoTypeId } = this.state;
    const includedGeographies = toggleMapModel.includedGeographies || [];
    const excludedGeographies = toggleMapModel.excludedGeographies || [];


    const includedAdwordsLocationsData = includedGeographies.map(g => {
      return adwordsLocationsByGeoTypeId[g];
    }).filter(g => g);

    const excludedAdwordsLocationsData = excludedGeographies.map(g => {
      return adwordsLocationsByGeoTypeId[g];
    }).filter(g => g);

    const includedTitle = includedAdwordsLocationsData.length == 1 ? 'Selected Location' : 'Selected Locations';
    const excludedTitle = excludedAdwordsLocationsData.length == 1 ? 'Excluded Location' : 'Excluded Locations';

    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={12} md={8}>
            <div className={ classes.toggleMapRoot }>
              <ToggleMap
                geoTypeIdsUrl="/api/ads/adwords_locations/geo_type_ids_in_bounds"
                showPositionIcon={true}
                position={[ latitude, longitude ]}
                includedGeographies={includedGeographies}
                onGeographiesChange={this.fetchAdwordsLocations}
                onIncludedGeographiesChange={this.onIncludedGeographiesChange}
                excludedGeographies={excludedGeographies}
                onExcludedGeographiesChange={this.onExcludedGeographiesChange}
                geoTypeStyles={{
                  fontWeight: 'bold'
                }}
                includedFill="#85E6D2"
                includedStrokeColor="#3AC2A4"
                excludedFill="#EE551D"
                excludedStrokeColor="#EE551D"
                canEdit={this.state.canEdit}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Paper className={classes.autosuggest}>
              <Typography variant='body1'>Search geographies</Typography>

              <Autosuggest
                placeholder="Search by country, state, or zip code."
                inputValue={this.state.query}
                onChangeInputValue={query => this.setState({ query })}
                getSuggestionPrimaryValue={this.getSuggestionPrimaryValue}
                getSuggestionSecondaryValue={this.getSuggestionSecondaryValue}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                autosuggestId={'toggle-map-autosuggest'}
                clearAfterSelected={true}
                suggestionStartAdornment={this.suggestionStartAdornment}
              />
            </Paper>

            <LocationCard
              title={ includedGeographies.length + ' ' + includedTitle }
              locations={includedAdwordsLocationsData}
              itemName={l => l.adwordsName}
              total={false}
              onRemove={this.onRemoveIncluded}
              noLocationsText="Click an area once to select it."
            />
            <LocationCard
              title={ excludedGeographies.length + ' ' + excludedTitle }
              locations={excludedAdwordsLocationsData}
              itemName={l => l.adwordsName}
              total={false}
              onRemove={this.onRemoveExcluded}
              noLocationsText="Click a selected area again to exclude it."
            />
            { this.props.children }
          </Grid>
        </Grid>
      </div>
    );
  }
}

ToggleMapForm.defaultProps = {
  onToggleMapChange: () => {},
  submitButtonText: '',
  latitude: 40.4405556,
  longitude: -79.9961111,
  canEdit: null,
}


const styles = theme =>  ({
  root: {
    padding: 16
  },
  formWrapper: {
    textAlign: 'center',
    padding: 15,
    maxWidth: 450,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    marginTop: 15
  },
  toggleMapRoot: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      minHeight: 400,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 650,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 850,
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: 1150,
    }
  },
  autosuggest: {
    marginBottom: 15,
    padding: 15
  },
  listItemIcon: {
    display: 'grid'
  },
  iconButton: {
    width: 20,
    height: 20,
    padding: 0
  }
});


export default withStyles(styles)(withContext(UserRoleContext, SnackbarContext, ToggleMapForm));



import React from "react";
import Axios from "axios";
import qs from "qs";
import { withStyles } from "@material-ui/core/styles";
import { log, withContext, format } from "kn-react";
import { SnackbarContext, TableToolbar, UserRoleContext } from "go-boost-library-react";
import { Link as RouterLink } from "react-router-dom";

import CompanySearchBox from "Settings/Companies/CompanyTable/CompanySearchBox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { SmallTablePagination } from 'go-boost-library-react';
import downloadFile from 'Download/downloadFile';

import SubscriptionReportModel from "./SubscriptionReportModel";
import { ListItemText, Typography } from "@material-ui/core";

class SubscriptionReports extends React.Component {
  state = {
    loading: true,
    refreshing: false,
    subscriptionReports: [],
    query: '',
    limit: 50,
    page: 0,
    count: 0,
  };

  componentDidMount = () => {
    this.fetchSubscriptionReports();
  };

  fetchSubscriptionReports = () => {
    const { currentUserRole } = this.props;
    const { query, limit, page } = this.state;

    this.setState({ refreshing: true });

    return Axios.get(
      `/api/core/reporting/organizations/${currentUserRole.roleTypeId}/subscriptions`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          limit,
          page,
          query: query || undefined
        },
      }
    ).then((response) => {
      log("fetchSubscriptionReports", response);

      this.setState({
        subscriptionReports: response.data.subscription_reports.map((r) =>
          SubscriptionReportModel.fromJSON(r)
        ),
        count: response.data.count,
        loading: false,
        refreshing: false,
      });
      const top = window.document.getElementById("top");
      if (top)
        top.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
    });
  };

  onSubmitSearch = (query) => {
    this.setState({ query }, this.fetchSubscriptionReports);
  };

  onClickDownload = () => {
    const { currentUserRole } = this.props;
    const params = {
      csv: true,
      query: this.state.query || undefined
    }

    return downloadFile(
      `/api/core/reporting/organizations/${currentUserRole.roleTypeId}/subscriptions?${qs.stringify(params)}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    );
  }

  onChangeRowsPerPage = limit => {
    this.setState(
      { limit },
      this.fetchSubscriptionReports
    );
  }


  onChangePage = page => {
    this.setState(
      { page },
      this.fetchSubscriptionReports
    );
  }


  render() {
    const { classes } = this.props;
    const { subscriptionReports, refreshing } = this.state;

    return (
      <>
        <Paper>
          <TableToolbar
            title="Subscriptions"
            caption={`
              All
               company subscriptions for your organization's plans and sub-organization, with plan information.
            `}
            actions={
                <Tooltip title="Download Data">
                  <IconButton onClick={this.onClickDownload} variant="contained">
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
            }
          />

          <CompanySearchBox placeholder="Search Companies and Plans..." onSubmitSearch={this.onSubmitSearch} />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} style={{ opacity: refreshing ? 0.5 : 1 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 200 }}>Company</TableCell>
                  <TableCell style={{ width: 200 }}>Organizations</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell align="center">Date Subscribed</TableCell>
                  <TableCell align="center">Date Canceled</TableCell>
                  <TableCell>Products</TableCell>
                  {/* <TableCell align="right">Ads</TableCell>
                  <TableCell align="right">Reviews</TableCell>
                  <TableCell align="right">Sites</TableCell>
                  <TableCell align="right">Social</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionReports.length ? (
                  subscriptionReports.map((s) => (
                    <TableRow key={s.subscriptionId}>
                      <TableCell component="th" scope="row">
                        <Link component={RouterLink} to={`/companies/${s.companyId}`}>
                          {s.companyName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {
                          s.companyOrganizations.map((o,i) => (
                            <React.Fragment key={i}>
                              { o.name }{ i !== s.companyOrganizations.length ? <br/> : '' }
                            </React.Fragment>
                          ))
                        }
                      </TableCell>
                      <TableCell>
                        <ListItemText
                          primary={s.planName}
                          primaryTypographyProps={{
                            variant: 'body2',
                            style: {
                              fontWeight: 'normal'
                            }
                          }}
                          secondary={s.planOwnerOrganizationName}
                          secondaryTypographyProps={{
                            variant: 'caption'
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">{ format.dateTime(s.createdAt)}</TableCell>
                      <TableCell align="right">
                        {s.canceledAt ? format.dateTime(s.canceledAt) : null}
                      </TableCell>
                      <TableCell>
                          {
                            [
                              { key: 'isAdsIncluded', name: 'Ads' },
                              { key: 'isReviewsIncluded', name: 'Reviews' },
                              { key: 'isSitesIncluded', name: 'Sites' },
                              { key: 'isSocialIncluded', name: 'Social' },
                              { key: 'isVirtualIncluded', name: 'Virtual' },
                           ].map(p => s[p.key] ? p.name : null).filter(p => p).join(', ')
                          }
                      </TableCell>
{/*
                      <TableCell align="right">{ s.isAdsIncluded ? <CheckBoxIcon color="primary" /> : null}</TableCell>
                      <TableCell align="right">{ s.isReviewsIncluded ? <CheckBoxIcon color="primary" /> : null}</TableCell>
                      <TableCell align="right">{ s.isSitesIncluded ? <CheckBoxIcon color="primary" /> : null}</TableCell>
                      <TableCell align="right">{ s.isSocialIncluded ? <CheckBoxIcon color="primary" /> : null}</TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Typography variant="caption">{ this.state.loading ? 'Loading...' : 'No subscriptions.' }</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          <SmallTablePagination
            count={this.state.count || 0}
            rowsPerPage={this.state.limit}
            page={this.state.page}
            onChangeRowsPerPage={e => this.onChangeRowsPerPage(e.target.value)}
            onChangePage={(e, p) => this.onChangePage(p)}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </>
    );
  }
}

const styles = {
	tableWrapper: {
		overflowX: 'auto'
	},
  table: {
    minWidth: 750,
  },
};

export default withStyles(styles)(
  withContext(UserRoleContext, SnackbarContext, SubscriptionReports)
);

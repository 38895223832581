import React from 'react';

import { log, withContext } from 'kn-react';
import { SnackbarContext, Alert, UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import { Button, Typography } from '@material-ui/core';

import history from 'history.js';


class CampaignTemplate extends React.Component {
  state = {
    loading: true,
    adwordsCampaignTemplate: {},
    adwordsCampaignTemplateCounts: {},
  };

  componentDidMount = () => {
    this.load();
  }


  load = () => {
    const id = this.props.match.params.id;
    log('adwordsCampaignTemplateId', id);
    Axios.get(
      `/api/ads/adwords_templates/campaigns/${ id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.setState({
        adwordsCampaignTemplate: response.data.adwords_campaign_template,
        adwordsCampaignTemplateCounts: response.data.adwords_campaign_template_counts,
      });
    });
  }


  onToggle = () => {
    if(!window.confirm('Are you sure? Enabling this template will allow it to be deployed when users sign up or update their settings.')) {
      return;
    }

    const id = this.props.match.params.id;
    const { adwordsCampaignTemplate } = this.state;

    Axios.post(
      `/api/ads/adwords_templates/campaigns/${id}/toggle`,
      { is_enabled: !adwordsCampaignTemplate.is_enabled },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.props.showSnackbar('Campaign Template updated.');
      this.setState({ adwordsCampaignTemplate: response.data.adwords_campaign_template });
    })
    .catch(error => {
      if(error.response.status === 412) {
        this.props.showSnackbar('Campaign Template has an error and cannot be enabled.');
        this.setState({ adwordsCampaignTemplate: error.response.data.adwords_campaign_template })
      }
      else {
        this.props.showSnackbar('Something went wrong!');
        log('error', error);
      }
    });
  }

  onDelete = () => {
    if(!window.confirm('Are you sure? Deleting the template will delete all of dependencies.')) {
      return;
    }

    const id = this.props.match.params.id;

    Axios.delete(
      `/api/ads/adwords_templates/campaigns/${id}/delete`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.props.showSnackbar('Campaign Template deleted.');
      history.push('/ads/adwords_templates')
    });
  }

  captionAndTitle = (caption, title) => {
    return (
      <div key={caption} style={{ padding: 15 }}>
        <Typography variant="caption">{ caption }</Typography>
        <Typography variant="h6">
          { title }
        </Typography>
      </div>
    );
  }


  render(){
    const {
      adwordsCampaignTemplate,
      adwordsCampaignTemplateCounts,
    } = this.state;

    return (
      <div style={{ padding: 15 }}>

        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={this.onToggle}
            variant="contained"
            style={{ marginRight: 15 }}
            color={ adwordsCampaignTemplate.is_enabled ? 'default' : 'primary' }
            disabled={ adwordsCampaignTemplate.template_error ? true : false }
          >
            { adwordsCampaignTemplate.is_enabled ? 'Disable' : 'Enable' }
          </Button>
          <Button onClick={this.onDelete} variant="contained" color="secondary">Delete</Button>
        </div>


        <Typography variant="headline" paragraph>Campaign Template</Typography>
        {
          adwordsCampaignTemplate.template_error ?
            <Alert danger>
              <b>Error:</b>
              {adwordsCampaignTemplate.template_error}
            </Alert>
          : null
        }

        {
          Object.keys(adwordsCampaignTemplate).map(k => (
            this.captionAndTitle( k, (adwordsCampaignTemplate[k] || '').toString() ))
          )
        }

        {
          Object.keys(adwordsCampaignTemplateCounts).map(k => (
            this.captionAndTitle( k, (adwordsCampaignTemplateCounts[k] || '').toString() ))
          )
        }

      </div>
    );
  }

}


export default withContext(UserRoleContext, SnackbarContext, CampaignTemplate);
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withContext, isStandalone } from 'kn-react';
import { Alert, ThemeContext } from 'go-boost-library-react';
import { Link, Button, withStyles } from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';



const addToHomeScreenAlert = props => (
  isStandalone() || ( props.theme && props.theme.shouldShowAddToHomeScreen === false ) ?
    null
  :
    <Alert primaryLight component={RouterLink} to="/add_to_home_screen" className={props.classes.alert}>
      <div className={props.classes.iconWrapper}>
        <PhoneIphoneIcon/>
      </div>
      <div className={props.classes.text}>
       Add { props.theme.appTitle } to the Home Screen of your mobile device for quicker access.
      </div>
    </Alert>
)

const styles = theme => ({
  alert: {
    display: 'block',
    textDecoration: 'none'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: 50,
  },
  text: {
    marginLeft: 30,
    lineHeight: '24px'
  }
})

export default withStyles(styles)(
  withContext(ThemeContext, addToHomeScreenAlert)
);
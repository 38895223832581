import React from 'react';

import { SvgIcon as MuiSvgIcon } from '@material-ui/core';


const svgIcon = props => (
  <MuiSvgIcon
    viewBox={props.viewBox}
    style={{ fontSize: props.fontSize }}
    className={props.className}
  >
    <g dangerouslySetInnerHTML={{ __html: props.paths }} />
  </MuiSvgIcon>
);


svgIcon.defaultProps = {
  fill: '#5E5F64'
}


export default svgIcon;
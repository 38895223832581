import React from 'react';
import Axios from 'axios';

import { GoogleLocationOauth, GoogleTextLogo, SnackbarContext, UserRoleContext, Ability } from 'go-boost-library-react';
import { withContext, withProvider, log } from 'kn-react';

import { withStyles, List, ListItem } from '@material-ui/core';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import { GoogleAccountContext } from 'GoogleAccount/GoogleAccountProvider';
import paperPadding from 'paperPadding';


class GoogleAccountCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clientId: ''
    }
  }

  hasEditPermission = () => {
    return this.props.currentUserRole.roleHasAbility( Ability.EDIT_GOOGLE_ACCOUNT )
  }

  componentDidMount = () => {
    if (this.hasEditPermission()) {
      this.fetchClientId();
    }

    return this.props.fetchGoogleAccount()
      .then(this.props.fetchGoogleAccountLocation);
  }

  fetchClientId = () => {
    return Axios.get(
      `/api/core/google_accounts/auth_params`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      if (response.status == 200) {
        this.setState({ clientId:response.data.params.client_id })
      }
    })
  }

  onSelectLocation = (location) => {
    return this.props.setGoogleAccountLocation(location)
      .then(this.props.findOrCreateGoogleReviewSite)
      .then(this.props.enqueueGoogleImporter)
      .then(this.props.onUpdateAccount)
      .then(() => this.props.showSnackbar('Google Account and Location successfully setup.'))
      .catch(error => log(error));
  }

  onSignOut = () => {
    return this.props.googleSignOut()
      .then(() => this.props.onUpdateAccount());
  }

  render() {
    const {
      classes, googleAccount, googleAccountLocation, setGoogleAccount,
      setGoogleMyBusinessAccount
    } = this.props;
    const { clientId } = this.state;

    return (
      <div className={classes.paperRoot}>
        <List>
          <GoogleTextLogo width='60%'/>

          <ListItem>
            <div className={classes.authPage}>
              <GoogleLocationOauth
                onCode={this.props.googleOnCode}
                canSignIn={this.props.canSignIn === false ? false : this.hasEditPermission()}
                clientId={clientId}
                googleRedirectUrl='/auth/google/callback'
                onSelectAccount={setGoogleMyBusinessAccount}
                onSelectLocation={this.onSelectLocation}
                signOut={this.onSignOut}
                canSignOut={this.hasEditPermission()}
                googleAccount={googleAccount}
                googleAccountLocation={googleAccountLocation}
                getGoogleMyBusinessAccountsCallback={this.props.getGoogleMyBusinessAccountsCallback}
                getGoogleLocationsCallback={this.props.getGoogleLocationsCallback}
                googleAccountLocationLoading={this.props.googleAccountLocationLoading}
              />
            </div>
          </ListItem>
        </List>
      </div>
    );
  }

}

const styles = theme => ({
  paperRoot: {
    padding: paperPadding,
    textAlign: 'center'
  },
  authPage: {
    textAlign: 'center',
    width: '100%'
  }
})

GoogleAccountCard.defaultProps = {
  onUpdateAccount: () => {},
  getGoogleMyBusinessAccountsCallback: () => {},
  getGoogleLocationsCallback: () => {},
  canSignIn: true
}



export default withContext(
  UserRoleContext,
  SnackbarContext,
  GoogleAccountContext,
  CompanyReviewsProfileContext,
  withStyles(styles)(GoogleAccountCard)
);
import React from 'react';
import { withContext } from 'kn-react';
import { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import ProductUndoResubscriptionAlert from 'CompanyRole/productUndoResubscriptionAlert';


const adsUndoResubscriptionAlert = props => (
  <ProductUndoResubscriptionAlert
    isResubscribing={(
      props.companyAdsProfile &&
      props.companyAdsProfile.isShutdown &&
      !props.companyAdsProfile.isSetupComplete
    )}
    undoText={'You are resubscribing to Ads.'}
    onProcess={props.undoResubscriptionToAds}
  />
);


export default withContext(
  CompanyAdsProfileContext,
  adsUndoResubscriptionAlert
);
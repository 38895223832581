import React from 'react';
import { withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import { Typography } from '@material-ui/core';
import Axios from 'axios';
import Autosuggest from 'Autosuggest/Autosuggest';
import OrganizationModel from 'Settings/Organizations/OrganizationModel';

class OrganizationAutosuggest extends React.Component {
  onOrganizationSuggestionsFetchRequested = () => {
    return Axios.get(
      `/api/core/organizations/${ this.props.organizationId }/family`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          query: this.props.organizationName
        }
      }
    ).then(response => {
      return response.data.family.map(
        o => OrganizationModel.fromJSON( o )
      );
    });
  }


  render(){
    const { disabled } = this.props;

    return (
      <>
        <Autosuggest
          placeholder={this.props.shouldHidePlaceholder ? null : "Start typing to search organizations"}
          inputValue={this.props.organizationName}
          onChangeInputValue={!disabled ? this.props.onChangeInputValue : () => {}}
          getSuggestionPrimaryValue={!disabled ? organization => organization.name : () => {}}
          onSuggestionsFetchRequested={!disabled ? this.onOrganizationSuggestionsFetchRequested : () => Promise.resolve([])}
          onSuggestionSelected={!disabled ? this.props.onSuggestionSelected : () => {}}
          autosuggestId={'organization-autosuggest'}
          clearAfterSelected={false}
          style={this.props.style}
          onBlur={!disabled ? this.props.onBlur : () => {}}
        />

        <Typography
          variant={'caption'}
        >
          Type at least 3 characters to search for an organization.
        </Typography>
      </>
    )
  }
}


OrganizationAutosuggest.defaultProps = {
  organizationName: '',
  onChangeInputValue: () => {},
  onSuggestionSelected: () => {},
  shouldHidePlaceholder: false,
  style: {},
  onBlur: () => {},
  organizationId: '',
  disabled: false
}


export default withContext(
  UserRoleContext,
  OrganizationAutosuggest
)
import React from 'react';
import { withContext } from 'kn-react';
import { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import ProductUndoResubscriptionAlert from 'CompanyRole/productUndoResubscriptionAlert';


const socialUndoResubscriptionAlert = props => (
  <ProductUndoResubscriptionAlert
    isResubscribing={(
      props.companySocialProfile &&
      props.companySocialProfile.isShutdown &&
      !props.companySocialProfile.isSetupComplete
    )}
    undoText={'You are resubscribing to Social.'}
    onProcess={props.undoResubscriptionToSocial}
  />
);


export default withContext(
  CompanySocialProfileContext,
  socialUndoResubscriptionAlert
);
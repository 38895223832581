import React from 'react';
import Axios from 'axios';

import { TableToolbar, SmallTablePagination, UserRoleContext, CompanyModel, Ability } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { Add, GetApp as GetAppIcon } from '@material-ui/icons';
import { IconButton, Tooltip, Typography, LinearProgress, withStyles, Paper, Divider, Switch } from '@material-ui/core';

import CompaniesList from './CompaniesList';
import CompanySearchBox from './CompanySearchBox';
import downloadFile from 'Download/downloadFile';
import history from 'history.js';


class CompanyTable extends React.Component {
  state = {
    companies: [],
    page: 0,
    rowsPerPage: 10,
    count: 0,
    loading: true,
    refreshing: false,
    query: '',
    isViewingAllCompanies: false
  };

  componentDidMount = () => {
    this.fetchCompanies();
  }

  componentDidUpdate = (oldProps) => {
    if (this.props.organization && this.props.organization.id !== oldProps.organization.id) {
      this.fetchCompanies();
    }
  }

  fetchCompanies = () => {
    const { rowsPerPage, page, query, isViewingAllCompanies } = this.state;
    const { showDescendantsInfo } = this.props;
    log('fetchCompanies', rowsPerPage, page);

    return Axios.get(
      `/api/core/organizations/${this.props.organization.id}/companies`,
      {
        params: {
          limit: rowsPerPage,
          page,
          query: query ? query : undefined,
          include_descendants: showDescendantsInfo ? showDescendantsInfo : undefined,
          show_all_companies: isViewingAllCompanies ? isViewingAllCompanies : undefined
        },
        headers: this.props.getUserRoleAuthHeaders()
      }
    ).then(response => {
      log('fetchCompanies response', response);
      const companies = response.data.companies.map(c => CompanyModel.fromJSON(c));

      response.data.company_organizations.forEach(co => {
        const company = companies.find(c => c.id === co.company_id);
        const organizations = company.organizationName || []

        organizations.push(co.name);
        company.organizationName = organizations;
      })

      this.setState({
        companies,
        count: response.data.count,
        loading: false,
        refreshing: false,
      });
    });
  }


  onClickDownload = () => {
    const { showDescendantsInfo } = this.props;
    const { query } = this.state;

    return downloadFile(
      `/api/core/organizations/${this.props.organization.id}/companies`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          query: query ? query : undefined,
          include_descendants: showDescendantsInfo ? showDescendantsInfo : undefined,
          csv: true
        },
      }
    );
  };

  onClickAddCompany = () => {
    history.push(`/organizations/${this.props.organization.id}/companies/new`)
  }

  changeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage, refreshing: true }, this.fetchCompanies);
  }

  changePage = page => {
    this.setState({ page, refreshing: true }, this.fetchCompanies);
  }


  onSubmitSearch = query => {
    this.setState({ query, refreshing: true, loading: true, page: 0 }, this.fetchCompanies);
  }


  onToggleIsViewingAllCompanies = () => {
    return this.setState(
      {
        isViewingAllCompanies: !this.state.isViewingAllCompanies,
        loading: true
      },
      this.fetchCompanies
    );
  }


  render() {
    const { classes, currentUserRole, organization } = this.props;
    const { companies, loading } = this.state;
    const caption = (
      this.props.showDescendantsInfo ?
        <>
          Companies that belong to <span className={classes.primary}>{organization.name}</span> and its suborganizations.
        </>
        :
        <>
          Companies that belong to <span className={classes.primary}>{organization.name}.</span>
        </>
    );


    return (
      <Paper>
        <TableToolbar
          title="Companies"
          caption={caption}
          actions={
            (
              <div className={this.props.classes.tableToolbarActions}>
                <Tooltip title="Download companies">
                  <IconButton onClick={this.onClickDownload} name='Download companies' color="secondary" variant="contained">
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>

                {
                  !currentUserRole.roleHasAbility( Ability.CREATE_COMPANY ) ?
                    null
                  :
                    <Tooltip title="Add a new company">
                      <IconButton onClick={this.onClickAddCompany} name='Add company' color="secondary" variant="contained">
                        <Add />
                      </IconButton>
                    </Tooltip>
                }

                {
                  !this.props.showViewAllCompanies ?
                    null
                  :
                    <Tooltip
                      title={`Click to view ${ this.state.isViewingAllCompanies ? 'descendant' : 'all' } companies`}
                    >
                      <Switch
                        checked={this.state.isViewingAllCompanies}
                        onChange={this.onToggleIsViewingAllCompanies}
                      />
                    </Tooltip>
                }
              </div>
            )
          }
        />

        {
          loading ?
            <LinearProgress />
            :
            <div style={{ height: 4 }} />
        }

        <CompanySearchBox
          onSubmitSearch={this.onSubmitSearch}
        />

        {
          !loading && !companies.length ?
            <Typography variant="caption" className={classes.noData}>No companies.</Typography>
            :
            <>
              <CompaniesList
                loading={this.state.loading}
                refreshing={this.state.refreshing}
                companies={this.state.companies}
                showDescendantsInfo={this.props.showDescendantsInfo}
              />
              <Divider />
              <SmallTablePagination
                count={this.state.count || 0}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangeRowsPerPage={e => this.changeRowsPerPage(e.target.value)}
                onChangePage={(e, p) => this.changePage(p)}
                rowsPerPageOptions={[5, 10, 25, 50]}
              />
            </>
        }
      </Paper>
    );
  }

}


CompanyTable.defaultProps = {
  showDescendantsInfo: false,
  organization: {},
  getUserRoleAuthHeaders: () => {},
  showViewAllCompanies: false
}


const styles = theme => ({
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
  searchIcon: {
    marginLeft: 24,
    color: '#A7A7A7',
  },
  primary: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  tableToolbarActions: {
    textAlign: 'center'
  }
});

export default withStyles(styles)(withContext(UserRoleContext, CompanyTable));
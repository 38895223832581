import React from 'react';
import { withContext, MaxWidth } from 'kn-react';
import { KnowledgeBaseButton } from 'go-boost-library-react';
import { Grid, Typography, Button } from '@material-ui/core';
import ReviewsConnectedAccounts from 'Reviews/CompanyReviews/ReviewsSettings/ReviewsConnectedAccounts';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import ReviewsUndoResubscriptionAlert from 'Reviews/CompanyReviews/ReviewsUndoResubscriptionAlert/ReviewsUndoResubscriptionAlert';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';

class GoogleAndFacebookAccountSetup extends React.Component {
  componentDidMount = () => {
    trackCurrentUserSegmentEvent(
      this.props.currentUserRole.userId,
      'started_connect_accounts_step',
      this.props.getUserRoleAuthHeaders,
      { funnel: true }
    );
  }

  render(){
    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <ReviewsUndoResubscriptionAlert />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">
              Link Your Accounts

              {
                !this.props.knowledgeBasePath ?
                  null
                :
                  <>
                    &nbsp;

                    <KnowledgeBaseButton
                      path={this.props.knowledgeBasePath}
                      tooltipTitle={this.props.knowledgeBaseTooltipTitle}
                    />
                  </>
              }
            </Typography>
          </Grid>

          {/* <CancelSetupButton /> */}

          <Grid item xs={12}>
            <ReviewsConnectedAccounts>
              <Typography variant="subtitle1" color="primary">Link Your Google and Facebook Accounts</Typography>

              <Typography style={{paddingBottom: 24}} variant="body2">
                Sign-in below to get your reviews.
                Or click 'Next' to complete this later.
              </Typography>
            </ReviewsConnectedAccounts>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={6}>
              {
                this.props.showBack ?
                  <Button color="primary" variant="outlined" onClick={() => this.props.transitionTo('back', this.props.transitions)}>Back</Button>
                :
                  null
              }
            </Grid>

            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button style={{ marginRight: 15 }} onClick={() => this.props.transitionTo('next', this.props.transitions)}>Skip</Button>

              <Button variant="contained" color="primary" onClick={() => this.props.transitionTo('next', this.props.transitions)}>Next</Button>
            </Grid>
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }
};


GoogleAndFacebookAccountSetup.defaultProps = {
  showBack: true,
  knowledgeBasePath: '',
  knowledgeBaseTooltipTitle: '',
}


export default withContext(
  TransitionContext,
  GoogleAndFacebookAccountSetup
);
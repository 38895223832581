import React from 'react';

import { withContext } from 'kn-react';
import { CompanyRoleContext } from 'go-boost-library-react';

import { withStyles, Grid } from '@material-ui/core';

import CompanyUserCard from '../CompanyUserCard/CompanyUserCard';
import CompanyUserInvitationCard from 'Settings/UserInvitations/CompanyUserInvitations/CompanyUserInvitationCard/CompanyUserInvitationCard';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';


class UserIndex extends React.Component {


  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          spacing={16}
          container
        >
          <KnowledgeBaseLaunchBanner />
          <Grid item xs={12}>
            <CompanyUserCard company={this.props.company}/>
          </Grid>

          <Grid item xs={12}>
            <CompanyUserInvitationCard
              company={this.props.company}
              newInvitationPath="/users/user_invitations/new"
              showDescendantsInfo={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  }
});

export default withStyles(styles)(
  withContext(
    CompanyRoleContext,
    UserIndex
  )
);
import React from 'react';

import { format, withContext, MaxWidth, log } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';

import AppNavigation from '../AppNavigation/AppNavigation';
import CompanyActivityLog from './CompanyActivityLog';
import OrganizationActivityLog from './OrganizationActivityLog';


const activityLog = props => (
  <AppNavigation title="Activity">
    {
      (props.currentUserRole.isCompanyRole()) ?
        <CompanyActivityLog/>
      :
        <OrganizationActivityLog/>
      }
  </AppNavigation>
);



export default withContext(
  UserRoleContext,
  activityLog
);
import React from 'react';
import * as d3 from 'd3';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { HorizontalPercentBar } from 'go-boost-library-react';


export const ratingColors = {
  5: '#7dc8a2',
  4: '#afd889',
  3: '#ffd855',
  2: '#ffb24f',
  1: '#ff8c5f',
};


export default props => (
  <List>
    {
      props.ratings.map(r => (
        <ListItem key={r} style={{ paddingBottom: 0, height: 25 }}>
          <Typography style={{ paddingRight: 5 }}>{ r }</Typography>
          <div style={{ width: '100%' }}>
            <HorizontalPercentBar
              backgroundColor='#fff'
              color={ ratingColors[r] }
              widthPercent={ props.countsByRating[r] / props.max }
              displayPercent={ props.countsByRating[r] / props.sum }
              number={props.countsByRating[r]}
            />
          </div>
        </ListItem>
      ))

    }
  </List>
)
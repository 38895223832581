const fetchAdsNextSteps = props => {
  const nextSteps = [ ...steps ];

  if( props.facebookCompanyBoostedServices().length ){
    if( props.facebookAccountPage && props.facebookAccountPage.id ){
      nextSteps[0].checked = true;
    }
  } else {
    nextSteps.splice(0, 1);
  }

  return nextSteps
}



fetchAdsNextSteps.defaultProps = {
  facebookCompanyBoostedServices: () => [],
  facebookAccountPage: {},
}



export default fetchAdsNextSteps



export const steps = [
  {
    text: 'Connect your Facebook account.',
    checked: false,
    link: '/ads/settings#connected-accounts'
  }
]
import React from 'react';
import { CircleMarker, Tooltip } from 'react-leaflet';
import * as d3 from 'd3';
import Axios from 'axios';
import L from 'leaflet';

import { ServicesColors, ReviewsStarIcon, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log, format } from 'kn-react';

import { Typography, withStyles } from '@material-ui/core';

import { ratingColors } from 'Reviews/ReviewsDashboard/RatingDistribution';
import ProductSummaryMap from '../ProductSummaryMap/ProductSummaryMap';

class ReviewsSummaryMap extends React.Component {

  state = {
    stats: null,
    companyDistribution: [],
    loading: true,
    totalReviews: 0,
    companyRatingAverage: 0,
    companiesHistogram: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0
    }
  }

  componentDidMount = () => {
    this.fetchCompanyReviewsProfilesStats()
      .then(this.fetchReviewsMapData)
  }


  fetchCompanyReviewsProfilesStats = () => {
    log('fetchCompanyReviewsProfilesStats');

    return Axios.get(
      `/api/reviews/company_reviews_profiles/organizations/${this.props.organization.id}/stats`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchCompanyReviewsProfilesStats response', response);
        this.setState({ stats: response.data.stats, loading: false });
      })
  }


  fetchReviewsMapData = () => {
    log('fetchReviewsMapData');

    return Axios.get(
      `/api/reviews/organizations/${this.props.organization.id}/company_distribution`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchReviewsMapData response', response);

        let companyRatingAverage = 0;
        let companiesHistogram = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

        const totalReviews = response.data.company_distribution.map(d => {
          companyRatingAverage += d.rating_average;
          companiesHistogram[Math.round(d.rating_average)] += 1;
          return d.count;
        }).reduce((a, b) => (a + b), 0);

        companyRatingAverage /= response.data.company_distribution.length;

        const maxReviews = d3.max(response.data.company_distribution, d => d.count);
        this.radiusScale = d3.scaleSqrt().domain([0, maxReviews]).range([2.5, 12]);

        this.setState({
          companyDistribution: response.data.company_distribution,
          companyRatingAverage,
          companiesHistogram,
          totalReviews
        });
      })
  }


  render() {
    const { companyDistribution, loading, stats, companiesHistogram } = this.state;

    const histogramHeight = 14;
    const histogramMax = d3.max(Object.keys(companiesHistogram), k => companiesHistogram[k]);
    const histogramScale = d3.scaleLinear().domain([0, histogramMax]).range([histogramHeight, 0]);

    if (loading) {
      return null;
    }

    const companiesWithPosition = companyDistribution.filter(c => c.latitude && c.longitude);

    let bounds;
    if (companiesWithPosition.length >= 2) {
      bounds = new L.LatLngBounds(companiesWithPosition.map(c => [c.latitude, c.longitude]));
    }

    return (
      <ProductSummaryMap
        title="Reviews"
        titleLink="/reviews"
        icon={ReviewsStarIcon}
        iconColor={ServicesColors.reviews.primary}
        bounds={bounds}
        enrolledCount={stats.is_setup_complete_count}
        incompleteCount={stats.count - stats.is_setup_complete_count}
        metric1Title={format.commas(this.state.totalReviews)}
        metric1Caption="Total Reviews"
        metric2Title={format.commas(this.state.companyRatingAverage, { decimals: 2 })}
        metric2Caption="Avg. Company Rating"
      >
        {
          companiesWithPosition.map(c => {
            const color = c.rating_average ? ratingColors[Math.round(c.rating_average)] : '#555';
            return (
              <CircleMarker
                key={c.company_id}
                center={[c.latitude, c.longitude]}
                radius={this.radiusScale(c.count)}
                weight={1}
                opacity={0.9}
                color={d3.rgb(color).darker(0.2)}
                fillOpacity={0.6}
                fillColor={color}
              >
                <Tooltip>
                  <Typography variant="body2">{c.name}</Typography>
                  <Typography variant="caption">
                    {format.commas(c.count)} Total Reviews
                    <br/>
                    {format.commas(c.rating_average, { decimals: 2 })} Rating Avg.
                  </Typography>
                </Tooltip>
              </CircleMarker>
            );
          })
        }
      </ProductSummaryMap>
    )
  }
}


const styles = {};

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    ReviewsSummaryMap
  )
);
import React from 'react';

import { log, withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import { LinearProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import BoostedServiceModel from 'Ads/BoostedServiceModel';
import CreateCampaignTemplate from './CampaignTemplate/CreateCampaignTemplate';
import CreateOfflineEventSet from './OfflineEventSet/CreateOfflineEventSet';
import history from 'history.js';


class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loadingCampaignTemplates: true,
      loadingOfflineEventSets: true,
      boostedServices: [],
      facebookCampaignTemplates: [],
      facebookOfflineEventSets: [],
      createCampaignOpen: false,
      createOfflineEventSetOpen: false,
    };
  }


  componentDidMount = () => {
    this.loadBoostedServices()
      .then(this.loadCampaignTemplates)
      .then(this.loadOfflineEventSets);
  }


  loadBoostedServices = () => {
    return Axios.get(
      '/api/ads/boosted_services/all',
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('boostedServices response', response);
      const boostedServices = response.data.boosted_services.map(b => BoostedServiceModel.fromJSON(b));

      this.setState({ boostedServices });
    });
  }



  loadCampaignTemplates = () => {
    return Axios.get(
      '/api/ads/facebook_templates/campaigns',
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('response', response);
      const facebookCampaignTemplates = response.data.facebook_campaign_templates;
      log('loadCampaignTemplates', facebookCampaignTemplates);
      this.setState({
        facebookCampaignTemplates,
        loadingCampaignTemplates: false
      });
    });
  }


  loadOfflineEventSets = () => {
    return Axios.get(
      '/api/ads/facebook_offline_event_sets',
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('response', response);
      const facebookOfflineEventSets = response.data.facebook_offline_event_sets;
      log('loadOfflineEventSets', facebookOfflineEventSets);
      this.setState({
        facebookOfflineEventSets,
        loadingOfflineEventSets: false
      });
    });
  }


  render(){
    return (
      <div>
        <List subheader={<li />}>
          <CreateOfflineEventSet
            open={this.state.createOfflineEventSetOpen}
            onClose={() => this.setState({ createOfflineEventSetOpen: false })}
            boostedServices={this.state.boostedServices}
          />

          <ListSubheader style={{ backgroundColor: 'white' }}>
            Offline Event Sets
            <ListItemSecondaryAction>
              <IconButton onClick={() => this.setState({ createOfflineEventSetOpen: true })}>
                <AddIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListSubheader>

          {
            this.state.loadingOfflineEventSets ?
              <LinearProgress/>
            :
              this.state.facebookOfflineEventSets.map((e,i) => (
                <ListItem key={`offline-event-set-${i}`} button onClick={() => history.push(`/ads/facebook_templates/offline_event_sets/${e.id}`)}>
                  <ListItemText
                    primary={e.name}
                    secondary={
                      `Boosted Service ID: ${ e.boosted_service_id }`
                    }
                  />
                  <Chip
                    label={ e.is_enabled ? 'Enabled' : 'Disabled' }
                    color={ e.is_enabled ? 'primary' : 'default' }
                  />
                </ListItem>
              ))
          }



          <CreateCampaignTemplate
            open={this.state.createCampaignOpen}
            onClose={() => this.setState({ createCampaignOpen: false })}
            boostedServices={ this.state.boostedServices }
          />

          <ListSubheader style={{ backgroundColor: 'white' }}>
            Campaign Templates
            <ListItemSecondaryAction>
              <IconButton onClick={() => this.setState({ createCampaignOpen: true })}>
                <AddIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListSubheader>

          {
            this.state.loadingCampaignTemplates ?
              <LinearProgress/>
            :
              this.state.facebookCampaignTemplates.map((t,i) => (
              <ListItem key={`campaign-${i}`} button onClick={() => history.push(`/ads/facebook_templates/campaigns/${t.id}`)}>
                <ListItemText
                  primary={
                    `${t.boosted_service_id}.${t.name}.v${t.version}`
                  }
                  secondary={
                    `Campaign ID: ${t.campaign_id}`
                  }
                />
                <Chip
                  label={ t.is_enabled ? 'Enabled' : 'Disabled' }
                  color={ t.is_enabled ? 'primary' : 'default' }
                />
              </ListItem>
            ))
          }
        </List>
      </div>
    );
  }

}

export default withContext(UserRoleContext, Index);
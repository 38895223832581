import React from 'react';
import { withContext } from 'kn-react';
import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import ProductUndoResubscriptionAlert from 'CompanyRole/productUndoResubscriptionAlert';


const sitesUndoResubscriptionAlert = props => (
  <ProductUndoResubscriptionAlert
    isResubscribing={(
      props.companySitesProfile &&
      props.companySitesProfile.isShutdown &&
      !props.companySitesProfile.isSetupComplete
    )}
    undoText={'You are resubscribing to Sites.'}
    onProcess={props.undoResubscriptionToSites}
  />
);


export default withContext(
  CompanySitesProfileContext,
  sitesUndoResubscriptionAlert
);
import React from 'react';

import { log, withContext } from 'kn-react';
import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import history from 'history.js';


class OfflineEventSet extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      facebookOfflineEventSet: {}
    };
  }

  componentDidMount = () => {
    return this.fetchFacebookOfflineEventSet()
  }



  fetchFacebookOfflineEventSet = () => {
    log(this.props);
    const id = this.props.match.params.id;
    log('facebookOfflineEventSetId', id);

    return Axios.get(
      `/api/ads/facebook_offline_event_sets/${ id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchFacebookOfflineEventSet', response);
      const facebookOfflineEventSet = response.data.facebook_offline_event_set;

      this.setState({ facebookOfflineEventSet });

      return facebookOfflineEventSet.facebook_campaign_template_id
    });
  }



  onToggle = () => {
    if(!window.confirm('Are you sure? Enabling/disabling this offline event set will allow it to start tracking opportunities.')) {
      return;
    }

    const id = this.props.match.params.id;
    const { facebookOfflineEventSet } = this.state;

    return Axios.post(
      `/api/ads/facebook_offline_event_sets/${id}/toggle`,
      { is_enabled: !facebookOfflineEventSet.is_enabled },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('onToggle', response);
      this.props.showSnackbar('Offline Event Set updated.');
      this.setState({ facebookOfflineEventSet: response.data.facebook_offline_event_set });
    });
  }



  onDelete = () => {
    if(!window.confirm('Are you sure? Deleting the offline event set will not delete it in Facebook.')) {
      return;
    }

    const id = this.props.match.params.id;

    Axios.delete(
      `/api/ads/facebook_offline_event_sets/${id}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('load', response);
      this.props.showSnackbar('Offline Event Set deleted.');
      history.push('/ads/facebook_templates')
    });
  }



  captionAndTitle = (caption, title) => {
    return (
      <div key={caption} style={{ padding: 15 }}>
        <Typography variant="caption">{ caption }</Typography>
        <Typography variant="h6">
          { title }
        </Typography>
      </div>
    );
  }


  render(){
    const {
      facebookOfflineEventSet
    } = this.state;

    return (
      <div style={{ padding: 15 }}>

        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={this.onToggle}
            variant="contained"
            style={{ marginRight: 15 }}
            color={ facebookOfflineEventSet.is_enabled ? 'default' : 'primary' }
          >
            { facebookOfflineEventSet.is_enabled ? 'Disable' : 'Enable' }
          </Button>

          <Button onClick={this.onDelete} variant="contained" color="secondary">Delete</Button>
        </div>


        <Typography variant="headline">Offline Event Set</Typography>

        {
          Object.keys(facebookOfflineEventSet).map(k => (
            this.captionAndTitle( k, (facebookOfflineEventSet[k] || '').toString() ))
          )
        }

      </div>
    );
  }

}


export default withContext(UserRoleContext, SnackbarContext, OfflineEventSet);
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Material
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreditCardIcon from '@material-ui/icons/CreditCard';
// Kinertia
import { SubNavigation, ReviewsStarIcon } from 'go-boost-library-react';
// App
import AppNavigation from 'AppNavigation/AppNavigation';
import PlanReports from './PlanReports/PlanReports';
import SubscriptionReports from './SubscriptionReports/SubscriptionReports';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';

class OrganizationReporting extends React.Component {

  render() {
    var subNavLinks = [
      { primaryText: 'Plans', path: '/reporting', exactPathMatch: true, icon: <DashboardIcon /> },
      { primaryText: 'Subscriptions', path: '/reporting/subscriptions', exactPathMatch: true, icon: <CreditCardIcon /> },
    ];


    return (
      <AppNavigation title="Reporting">
        <SubNavigation links={subNavLinks}>
          <KnowledgeBaseLaunchBanner />

          <Switch>
            <Route exact path="/reporting" component={PlanReports} />
            <Route exact path="/reporting/subscriptions" component={SubscriptionReports} />
          </Switch>
        </SubNavigation>
      </AppNavigation>
    );
  }

}

export default OrganizationReporting;
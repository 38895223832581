import React from 'react';

import { UserRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

// TODO @cotter... rename this to CompanyBoostedServicesProvider?
// TODO @alex... IDK it provides both BoostedServices and CompanyBoostedServices
import BoostedServicesProvider from './CompanyAds/BoostedServices/BoostedServicesProvider';
import ChannelProvider from 'Channels/ChannelProvider';
import CompanyAds from './CompanyAds/CompanyAds';
import CompanyAdsProfileProvider from './CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import FacebookAccountProvider from 'FacebookAccount/FacebookAccountProvider';
import OrganizationAds from './OrganizationAds/OrganizationAds';
import OrganizationBoostedServicesProvider from './OrganizationAds/BoostedServices/OrganizationBoostedServicesProvider';

const ads = props => (
  <ChannelProvider>
    {
      props.currentUserRole.isCompanyRole() ?
        <CompanyAdsProfileProvider>
          <BoostedServicesProvider>
            <FacebookAccountProvider>
              <CompanyAds/>
            </FacebookAccountProvider>
          </BoostedServicesProvider>
        </CompanyAdsProfileProvider>
      :
        <OrganizationBoostedServicesProvider>
          <OrganizationAds/>
        </OrganizationBoostedServicesProvider>
    }
  </ChannelProvider>
);

export default withContext(UserRoleContext, ads);
import React from 'react';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import SocialDashboard from 'Social/SocialDashboard/SocialDashboard';

const socialDashboard = props => (
  <SocialDashboard
    roleTypePath="companies"
    roleTypeId={props.company.id}
    currentUserRole={props.currentUserRole}
    getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
    isSearchCompaniesVisible={false}
  />
);

export default withContext(
  CompanyRoleContext,
  socialDashboard
);
import React from 'react';
import { Link } from 'react-router-dom';

import { FacebookIcon, GoogleIcon, Ability, KnowledgeBaseButton } from 'go-boost-library-react';
import { log, withContext, ScrollTo } from 'kn-react';

import { Button, MenuItem, Typography, Toolbar, Tooltip } from '@material-ui/core';
import { ChevronRight, PersonAdd, Send } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';

import { ReviewSourcesContext } from 'Reviews/ReviewSourcesProvider';
import CompanyAutosuggestDialog from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/CompanyAutosuggest/CompanyAutosuggestDialog';
import DataCurrentAsOf from '../DataCurrentAsOf/DataCurrentAsOf';
import RatingDistribution from './RatingDistribution';
import RecentReviewsList from './RecentReviewsList';
import ReviewDashboardSummary from './ReviewDashboardSummary';
import ReviewSourceDistribution from './ReviewSourceDistribution';




class ReviewsDashboardContent extends React.Component {
  render() {
    let { recentReviews, classes, facebookAccountPresent, googleAccountPresent, currentUserRole } = this.props;

    const relevantReviewSourceIds = this.props.relevantReviewSources.map(
      r => r.id
    );

    const reviewSources = this.props.reviewSources.filter(
      r => relevantReviewSourceIds.includes( r.id )
    );

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h6">Reviews Dashboard</Typography>
          <DataCurrentAsOf/>
        </Grid>


        <Grid item xs={12} container spacing={16}>
          <Grid item xs={12} sm={3}>
            <TextField
              select
              label="Time Period"
              value={this.props.timePeriod}
              onChange={e => this.props.onChangeTimePeriod(e.target.value)}
              fullWidth
            >
              {
                this.props.timePeriodOptions.map((t, i) => (
                  <MenuItem value={t} key={i}>{t.label}</MenuItem>
                ))
              }
            </TextField>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              select
              label="Source"
              value={this.props.reviewSource}
              onChange={e => this.props.onChangeReviewSource(e.target.value)}
              fullWidth
            >
              <MenuItem value="all">All Sources</MenuItem>
              {
                reviewSources.map((r, i) => (
                  <MenuItem key={i} value={r}>{r.name}</MenuItem>
                ))
              }
            </TextField>
          </Grid>




          {
            this.props.isSearchCompaniesVisible ?
              <Grid item xs={12} sm={3}>
                <CompanyAutosuggestDialog
                  initialCompanies={this.props.companies}
                  roleTypeId={this.props.roleTypeId}
                  onChangeCompanies={this.props.onChangeCompanies}
                />
              </Grid>
              : null
          }



          <Hidden smDown>
            <Grid item sm={this.props.isSearchCompaniesVisible ? 3 : 6}>
              <Toolbar disableGutters style={{ justifyContent: 'flex-end' }}>
                {
                  (currentUserRole.roleHasAbility( Ability.EDIT_FACEBOOK_ACCOUNT )) ?
                    (facebookAccountPresent) ?
                      <Tooltip title="Your Facebook account is connected" disableFocusListener disableTouchListener>
                        <IconButton color="primary">
                          <FacebookIcon/>
                        </IconButton>
                      </Tooltip>
                      :
                      <Tooltip title="Connect your Facebook account" disableFocusListener disableTouchListener>
                        <IconButton
                          component={Link}
                          onClick={() => setTimeout(() => ScrollTo.scroll('connected-accounts'), 1000)}
                          to={'/reviews/settings'}
                        >
                          <FacebookIcon />
                        </IconButton>
                      </Tooltip>

                    :
                    null
                }

                {
                  (currentUserRole.roleHasAbility( Ability.EDIT_GOOGLE_ACCOUNT )) ?
                    (googleAccountPresent) ?
                      <Tooltip title="Your google account is connected" disableFocusListener disableTouchListener>
                        <IconButton
                          color="primary"
                        >
                          <GoogleIcon/>
                        </IconButton>
                      </Tooltip>
                    :
                      <Tooltip title="Connect your Google account" disableFocusListener disableTouchListener>
                        <IconButton
                          component={Link}
                          onClick={() => setTimeout(() => ScrollTo.scroll('connected-accounts'), 1000)}
                          to={'/reviews/settings'}
                        >
                          <GoogleIcon />
                        </IconButton>
                      </Tooltip>
                    :
                      null
                }

                {
                  this.props.currentUserRole.roleHasAbility( Ability.EDIT_REVIEWS_REVIEW_REQUESTS ) ?
                    <Tooltip title="Request Review">
                      <IconButton component={Link} to="/reviews/review_requests/new" color="primary" id="request-review">
                        <Send />
                      </IconButton>
                    </Tooltip>
                    : null
                }

                {
                  this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_USERS ) ?
                    <Tooltip title="New User">
                      <IconButton component={Link} to="/users/user_invitations/new" color="primary">
                        <PersonAdd />
                      </IconButton>
                    </Tooltip>
                    : null
                }
              </Toolbar>
            </Grid>
          </Hidden>


          <Hidden smUp>
            {
              this.props.currentUserRole.roleHasAbility( Ability.EDIT_REVIEWS_REVIEW_REQUESTS ) ?
                <Grid item xs={12}>
                  <Button component={Link} size="small" fullWidth to="/reviews/review_requests/new" color="light" className={classes.lightButton}>
                    <Send className={classes.leftIcon} />
                    Request Review
                    </Button>
                </Grid>
                : null
            }


            {
              this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_USERS ) ?
                <Grid item xs={12}>
                  <Button component={Link} size="small" fullWidth to="/user_invitations/new" color="primary" className={classes.lightButton}>
                    <PersonAdd className={classes.leftIcon} />
                    New User
                    </Button>
                </Grid>
                : null
            }

          </Hidden>
        </Grid>

        <Grid item xs={12}>
          <ReviewDashboardSummary
            ratingAverage={this.props.ratingAverage}
            ratingAverageChange={this.props.ratingAverageChange}
            ratingAverageChartData={this.props.ratingAverageChartData}

            totalReviews={this.props.totalReviews}
            totalReviewsChange={this.props.totalReviewsChange}

            responseCount={this.props.responseCount}
            responseCountChange={this.props.responseCountChange}

            totalRequests={this.props.totalRequests}
            totalRequestsChange={this.props.totalRequestsChange}
            requestsChartData={this.props.requestsChartData}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: 16 }}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h6">Ratings and Reviews Distribution</Typography>
                <Typography variant="caption">Breakdown of rating and review source during the selected period</Typography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <RatingDistribution ratingDistribution={this.props.ratingDistribution} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <ReviewSourceDistribution reviewSourceDistribution={this.props.reviewSourceDistribution} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>

        </Grid>

        <Grid item xs={12} >
          <Paper>
            <div style={{ padding: 16 }}>
              <Typography variant="h6">
                Recent Reviews

                {
                  this.props.currentUserRole.isCompanyRole() ?
                    <>
                      &nbsp;

                      <KnowledgeBaseButton
                        path={'/why_reviews_are_important'}
                        tooltipTitle={'Learn facts about why reviews are important'}
                      />
                    </>
                    : null
                }
              </Typography>
            </div>
            <Divider />

            {
              recentReviews.length ?
                <RecentReviewsList
                  reviews={recentReviews}
                  reviewSources={this.props.reviewSources}
                  respondableReviewSourceIds={this.props.respondableReviewSourceIds}
                />
                :
                <Typography color="textSecondary" style={{ textAlign: 'center', padding: 15 }} variant="caption">No recent reviews.</Typography>
            }
            <div style={{ paddingLeft: 12, paddingBottom: 12 }}>
              <Button component={Link} color='primary' to="/reviews/all">
                All Reviews <ChevronRight />
              </Button>
            </div>
          </Paper>
        </Grid>




      </Grid>
    )
  }
}

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  lightButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  }
});

ReviewsDashboardContent.defaultProps = {
  isSearchCompaniesVisible: true,
  onChangeTimePeriod: t => {
    log('new time period', t)
  },
  onChangeReviewSource: s => {
    log('new source', s)
  },
  onChangeCompanies: c => {
    log('new company', c)
  },
  timePeriod: null,
}

export default withStyles(styles, { withTheme: true })(
  withContext(
    ReviewSourcesContext,
    ReviewsDashboardContent,
  )
);
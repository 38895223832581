import React from 'react';
import { withStyles, Grid, Paper, Typography } from '@material-ui/core';
import { MaxWidth, withContext, log } from 'kn-react';
import BoostedServicesList from './BoostedServicesList/BoostedServicesList';
import { OrganizationBoostedServicesContext } from './OrganizationBoostedServicesProvider';


class BoostedServices extends React.Component {
    render() {
    const { classes } = this.props;

    return (
        <MaxWidth maxWidth={800}>
        <Grid container spacing={16} style={{ marginTop: 50 }}>
            <Typography variant="h5">Boosted Services</Typography>
            <Grid item xs={12}>
              <BoostedServicesList/>
            </Grid>

            <Grid item xs={12} style={{ textAlign:'center' }}>
              <Typography variant="body2" color="textSecondary">
                To add more Boosted Services across channels like Google Search and Facebook, contact us!
              </Typography>
            </Grid>
        </Grid>
      </MaxWidth>
    );
    }
}



const styles = theme => ({

});

export default withContext( OrganizationBoostedServicesContext, withStyles(styles)(BoostedServices));
import React from 'react';
import { withContext } from 'kn-react';

import { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import CompanyServiceArea from 'Settings/Companies/CompanyServiceArea/CompanyServiceArea';



const companyServiceArea = props => (
  <CompanyServiceArea
    isShutdown={props.companyAdsProfile && props.companyAdsProfile.isShutdown}
  />
);



export default withContext(
  CompanyAdsProfileContext,
  companyServiceArea
);
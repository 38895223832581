import React from 'react';

import { IconButton, SvgIcon } from '@material-ui/core';


const weekCalendarIcon = props => (
  <IconButton
    aria-label="Week Calendar Icon"
    style={{ backgroundColor: '#F7F6F6' }}
    disabled
  >
    <SvgIcon
      viewBox="0 0 47 49"
      style={{ fontSize: props.fontSize }}
    >
      <path d="M9 5H3C1.89543 5 1 5.89543 1 7V46C1 47.1046 1.89543 48 3 48H44C45.1046 48 46 47.1046 46 46V7C46 5.89543 45.1046 5 44 5H38M13 5H34.5" stroke={props.fill} fill='none'/>
      <rect x="9" y="1" width="4" height="8" rx="1" fill='none' stroke={props.fill}/>
      <rect x="34" y="1" width="4" height="8" rx="1" fill='none' stroke={props.fill}/>
      <rect x="7.5" y="23.5" width="7" height="7" rx="0.5" fill='none' stroke={props.fill}/>
      <line x1="1" y1="16.5" x2="46" y2="16.5" stroke={props.fill}/>
    </SvgIcon>
  </IconButton>
);


weekCalendarIcon.defaultProps = {
  fill: '#5E5F64'
}


export default weekCalendarIcon;
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 512 512" x="0px" y="0px">
    <path d="M256 21.42c-131 0-237.14 106.17-237.14 237.14S125 495.7 256 495.7s237.14-106.17 237.14-237.14S387 21.42 256 21.42zm-153.89 98.66a10.36 10.36 0 0 1-1-14.6c1.66-1.91 16.8-18.73 37.32-20.81s38 10 38.76 10.49a10.38 10.38 0 0 1-12 17c-.07-.06-11.88-8.05-24.69-6.8-10.47 1.06-20.71 10.32-23.77 13.8a10.4 10.4 0 0 1-14.63 1zm44.81 116.41c-19.25-1.24-33.48-23.77-31.78-50.34s18.7-47.1 38-45.86 33.48 23.77 31.78 50.33-18.75 47.1-38 45.87zm108.82 216.15c-42.57-.12-77-43.66-76.81-97.25s34.76-96.94 77.33-96.83 77 43.65 76.81 97.25-34.76 96.94-77.33 96.83zm111.44-218.52c-19.25 1.24-36.24-19.29-37.94-45.86s12.52-49.1 31.77-50.33 36.24 19.3 37.95 45.86-12.53 49.1-31.78 50.33zm38.39-121.75a10.38 10.38 0 0 1-14.62-1c-3.07-3.48-13.31-12.74-23.77-13.81-12.81-1.25-24.62 6.75-24.69 6.81a10.38 10.38 0 1 1-12-17c.73-.51 18.13-12.57 38.75-10.49s35.67 18.9 37.32 20.81a10.35 10.35 0 0 1-.99 14.68z" fill="#ffde6e"/>
    <path d="M256.26 258.56c-42.57-.11-77.19 43.24-77.33 96.83s34.24 97.13 76.81 97.25 77.19-43.24 77.33-96.83-34.24-97.14-76.81-97.25z" fill="#102236"/>
    <path d="M361 137.93c-19.25 1.23-33.48 23.77-31.77 50.33s18.69 47.1 37.94 45.86 33.48-23.77 31.78-50.33-18.68-47.1-37.95-45.86z" fill="#102236"/>
    <path d="M153.09 140.29c-19.25-1.24-36.24 19.3-37.95 45.86s12.53 49.1 31.78 50.34 36.24-19.3 38-45.87-12.58-49.1-31.83-50.33z" fill="#102236"/>
    <path d="M116.74 119.12c3.06-3.48 13.3-12.74 23.77-13.8 12.81-1.25 24.62 6.74 24.69 6.8a10.38 10.38 0 0 0 12-17c-.73-.51-18.14-12.57-38.76-10.49s-35.66 18.9-37.32 20.81a10.38 10.38 0 0 0 15.65 13.64z" fill="#102236"/>
    <path d="M369.27 77c-20.62-2.08-38 10-38.75 10.49a10.38 10.38 0 1 0 12 17c.07-.06 11.88-8.06 24.69-6.81 10.46 1.07 20.7 10.33 23.77 13.81a10.38 10.38 0 0 0 15.64-13.64C404.94 95.86 389.79 79 369.27 77z" fill="#102236"/>
  </SvgIcon>
);
import React from 'react';
import Axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import { log, format, withContext } from 'kn-react';
import { TableToolbar, TextStatusIndicator, UserRoleContext, ReviewRequestListItem, SmallTablePagination, Ability, CompanyRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';

import { Typography, withStyles, Paper, IconButton, TableBody, Tooltip, List, Link } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import Send from '@material-ui/icons/Send';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import history from 'history.js';
import ReviewRequestModel from 'Reviews/ReviewRequestModel';



class CompanyReviewRequestsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      page: 0,
      reviewRequests: [],
      loading: true,
    }
  }

  componentDidMount = () => {
    this.fetchReviewRequests()
  }

  fetchReviewRequests = () => {
    const { company , getUserRoleAuthHeaders } = this.props;
    return Axios.get(
      `/api/reviews/review_requests/companies/${ company.id }`,
      {
        headers: getUserRoleAuthHeaders(),
        params: {
          limit: 50,
          page: this.state.page
        }
      }
    )
    .then(response => {
      log('fetchReviewRequests response', response);
      const reviewRequests = response.data.review_requests.map(r => ReviewRequestModel.fromJSON(r));
      const total = (reviewRequests.length) ? reviewRequests[0].totalReviewRequests : 0;
      log('reviewRequests Models', reviewRequests)
      this.setState({ reviewRequests, loading:false, total })
    })
  }


  changePage = (page) => {
    this.setState(
      { page },
      this.fetchReviewRequests
    )
  }

  formatCreatedAt = (createdAt) => (
    moment(createdAt).format('MMMM Do YYYY, h:mm a')
  )


  createRow = (reviewRequest) => {
    const { classes } = this.props;
    const createdAt = this.formatCreatedAt(reviewRequest.createdAt);
    const clickedAt = reviewRequest.clickedAt ? moment(reviewRequest.clickedAt).format('MMMM Do YYYY, h:mm a') : 'Not clicked';
    let messageCell;

    if(reviewRequest.message && reviewRequest.message.length > 50){
      messageCell =
        <TableCell className={classes.cell}>
            {reviewRequest.message.slice(0,50)}...
        </TableCell>
    } else {
      messageCell =
        <TableCell className={classes.cell}>
          {reviewRequest.message}
        </TableCell>
    }

    return (
      <TableRow
        hover={true}
        key={reviewRequest.id}
        onClick={() => history.push(`/reviews/review_requests/${reviewRequest.id}`)}
        style={{ cursor: 'pointer' }}
      >
          <TableCell className={classes.cell}>
            <Link component={RouterLink} to={`/reviews/review_requests/${reviewRequest.id}`}>{ createdAt }</Link>
          </TableCell>

          <TableCell className={classes.cell}>
            <Typography variant="body2">
              {reviewRequest.fullName}
            </Typography>

            {
              (reviewRequest.email) ?
                <Typography variant="caption" color="textSecondary">
                  { reviewRequest.email }
                </Typography>
              :
                null
            }

            {
              (reviewRequest.phone) ?
                <Typography variant="caption" color="textSecondary">
                  { format.phone(reviewRequest.phone) }
                </Typography>
              :
                null
            }
          </TableCell>

          { messageCell }

          <TableCell>
            {
              <Typography variant="caption" color="textSecondary">
                { `${reviewRequest.requestorFirstName} ${reviewRequest.requestorLastName}` }
              </Typography>
            }
          </TableCell>

          <TableCell>
            {
              <TextStatusIndicator
                success={reviewRequest.isClicked ? true : undefined}
                text={clickedAt}
              />
            }
          </TableCell>
      </TableRow>
    )
  }

  desktopTableView = () => {
    const { classes } = this.props;
    const { reviewRequests, loading } = this.state;

    return(
      <>
        {loading ? <LinearProgress /> : null}

        {
          (!loading && !reviewRequests.length) ?
            <>
              <Typography variant="caption" className={classes.noData}>No Review Requests.</Typography>
              <Divider/>
            </>
          :
            <div className={classes.tableWrapper}>
              <Table padding='dense' style={{ opacity: (loading) ? 0.5 : 1 }}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cell}>
                      Sent
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Contact
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Message
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Requested By
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Clicked
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    (!reviewRequests) ? null : reviewRequests.map(r => this.createRow(r))
                  }
                </TableBody>
              </Table>
            </div>
        }
      </>
    )
  }

  mobileListView = () =>{
    const { reviewRequests } = this.state;
    return(
      <List>
          {
            (reviewRequests) ?
              reviewRequests.map((r, index) =>
                <React.Fragment key = { r.id }>
                  <ReviewRequestListItem
                    request={r}
                    maxChars = {250}
                  />

                  {
                    (index < reviewRequests.length-1) ?
                      <Divider />
                    :
                      null
                  }
                </React.Fragment>
              )
            :
              null
          }
      </List>
    )
  }

  render() {
    const { classes, currentUserRole, companyReviewsProfile } = this.props;
    const { total, page } = this.state;
    return (
      <Paper>
        <TableToolbar
          title={(
            <>
              { total } Review Request{ total == 1 ? '' : 's' }

              &nbsp;

              <KnowledgeBaseButton
                path={'/sending_review_requests'}
                tooltipTitle={'Learn how requesting reviews can improve your online presence'}
              />
            </>
          )}
          actions={
            (currentUserRole.roleHasAbility( Ability.EDIT_REVIEWS_REVIEW_REQUESTS )) ?
              <Tooltip title={`Request a review`}>
                <IconButton
                  to={'/reviews/review_requests/new'}
                  color="secondary"
                  variant="contained"
                  id="create-review-request"
                  component={RouterLink}
                  disabled={companyReviewsProfile && companyReviewsProfile.isShutdown}
                >
                  <Send />
                </IconButton>
              </Tooltip>
            :
              null
          }
        />

        <Hidden smDown>
          { this.desktopTableView() }
        </Hidden>

        <Hidden mdUp>
          { this.mobileListView() }
        </Hidden>

        <SmallTablePagination
          count={total || 0}
          rowsPerPage={50}
          page={page}
          onChangePage={(e, p) => this.changePage(p)}
          rowsPerPageOptions={[50]}
        />
      </Paper>
    );
  }

}

const styles = theme => ({
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 5
  },
  avatarText: {
    lineHeight: '30px'
  },
  root: {
    padding: 16
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  tableFooter: {
    textAlign: 'right',
    padding: 10,
  },
})

export default withContext(
  CompanyRoleContext,
  UserRoleContext,
  CompanyReviewsProfileContext,
  withStyles(styles)(
    CompanyReviewRequestsTable
  )
);
import { Grid, Typography, withStyles } from '@material-ui/core';
import { MaxWidth, withContext, log } from 'kn-react';
import { KnowledgeBaseButton } from 'go-boost-library-react';
import React from 'react';
import BoostedServicesList from './BoostedServicesList/BoostedServicesList';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import history from 'history.js';


class BoostedServices extends React.Component {

  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions);
  }

  onClickNext = () => {
    return this.props.transitionTo('next', this.props.transitions);
  }


  onClickSetup = boostedService => {
    // Create the company boosted service here?
    log('onClickSetup', boostedService);
    return this.props.transitionTo('setup', this.props.transitions, { boostedServiceId: boostedService.id });
  }

  onClickEditSurvey = boostedService => {
    this.props.transitionTo('editSurvey', this.props.transitions, { boostedServiceId: boostedService.id });
  }

  onClickEditBoostZone = boostedService => {
    this.props.transitionTo('editBoostZone', this.props.transitions, { boostedServiceId: boostedService.id });
  }

  onClickEditBoostStrategy = boostedService => {
    this.props.transitionTo('editBoostStrategy', this.props.transitions, { boostedServiceId: boostedService.id });
  }


  onClickAddToBalance = boostedService => {
    history.push(`/ads/boosted_services/${boostedService.id}/add_to_balance`);
  }

  render(){
    const { classes, companyBoostedServices, subscribedAdsPlan, companyAdsProfile } = this.props;

    const subscribedPlanBoostedServices = (
      subscribedAdsPlan ?
        this.props.filterBoostedServicesByPlanId(subscribedAdsPlan.id)
      :
        []
    );
    const getStartedBoostedServices = subscribedPlanBoostedServices.filter(b => {
      const c = companyBoostedServices.find(c => c.boostedServiceId == b.id);
      return !c || !c.hasBoostBalance();
    });

    log('getStartedBoostedServices', getStartedBoostedServices);

    const paidCompanyBoostedServices = companyBoostedServices.filter(c => c.hasBoostBalance());
    log('paidCompanyBoostedServices', paidCompanyBoostedServices);

    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.root}>
          <Grid container spacing={16}>

            <Grid item xs={12}>
              <Typography variant="h5" color="primary">
                Boosted Services

                &nbsp;

                <KnowledgeBaseButton
                  path={'/managing-your-boosted-services'}
                  tooltipTitle={'Learn more about managing your Boosted Services'}
                />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BoostedServicesList
                getStartedBoostedServices={getStartedBoostedServices}
                readyToBoostCompanyBoostedServices={paidCompanyBoostedServices}
                showToggleCompanyBoostedService={true}
                onClickEditSurvey={this.onClickEditSurvey}
                onClickEditBoostZone={this.onClickEditBoostZone}
                onClickEditBoostStrategy={this.onClickEditBoostStrategy}
                onClickAddToBalance={this.onClickAddToBalance}
                onClickSetup={this.onClickSetup}
                includeSetupList={!Boolean( companyAdsProfile.isShutdown )}
              />
            </Grid>

          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  },
  paperRoot: {
    padding: 0
  },
});

export default withStyles(styles)(
  withContext(
    TransitionContext,
    BoostedServices
  )
);
import React from 'react';

import { Alert, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, MaxWidth } from 'kn-react';

import { Typography, Grid, withStyles, Card, CardHeader } from '@material-ui/core';

import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import { SiteDesignsContext } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import AdCarousel from 'Ads/CompanyAds/BoostedServices/BoostedServicesList/AdCarousel/AdCarousel';
import paperPadding from 'paperPadding';



class SiteDesignPreview extends React.Component {
  render() {
    const { classes, focusedSiteDesign } = this.props;

    let carousel;
    if (
      focusedSiteDesign &&
      focusedSiteDesign.assetData &&
      focusedSiteDesign.assetData.carousel
    ) {
      carousel = focusedSiteDesign.assetData.carousel;
    }

    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            {
              focusedSiteDesign ?
                <Grid item xs={12} key={focusedSiteDesign.id} style={{ paddingBottom: 15 }}>
                  <Card>
                    <CardHeader
                      className={classes.cardHeader}
                      disableTypography
                      title={
                        <Typography variant="h6">{focusedSiteDesign.name}</Typography>
                      }
                      subheader=""
                      avatar={null}
                    />

                    {
                      carousel ?
                        <AdCarousel data={carousel} itemWidth={265} />
                        : null
                    }
                  </Card>
                </Grid>
              :
                <Alert warning>No preview found for your selected plan. Please contact us for assistance.</Alert>
            }
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }
}


SiteDesignPreview.defaultProps = {
  focusedSiteDesign: null,
};



const styles = theme => ({
  paper: {
    padding: paperPadding
  },
  cardHeader: {
    borderBottom: '1px solid #efefef',
  },
});



export default withContext(
  CompanyRoleContext,
  SiteDesignsContext,
  CompanySitesProfileContext,
  withStyles(styles)(
    SiteDesignPreview
  )
);
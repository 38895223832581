import React from 'react';
import * as d3 from 'd3';
import { ListItemText, Button, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import BoostedServiceIcon from '../BoostedServiceIcon/BoostedServiceIcon';
import AdCarousel from '../AdCarousel/AdCarousel';

const setupBoostedServicesList = props => {
  const { getStartedBoostedServices, channels, classes } = props;

  if (!getStartedBoostedServices || !getStartedBoostedServices.length) {
    return null;
  }

  const sortedGetStartedBoostedServices = [...(getStartedBoostedServices || [])];

  sortedGetStartedBoostedServices.sort((a, b) => {
    return d3.ascending(a.name, b.name);
  });


  return (
    <>
      {
        sortedGetStartedBoostedServices.map(s => {
          const ch = channels.find(c => c.id === s.channelId);

          const assetData = s.assetData || {};

          let adCarousel;
          if (assetData.adCarousel) {
            adCarousel = assetData.adCarousel.map(d => ({
              image: d.image,
              url: d.preview_url
            }));
          }

          return (
            <Grid item xs={12} key={s.id}>
              <Card>

                <CardHeader
                  className={classes.cardHeader}
                  disableTypography
                  title={
                    <Typography variant="subtitle1">{s.name}</Typography>
                  }
                  subheader={
                    <>
                      <Typography variant="caption" color="textSecondary">
                        {ch.name}
                      </Typography>
                    </>
                  }
                  avatar={
                    <Hidden xsDown>
                      <BoostedServiceIcon
                        channels={channels}
                        boostedService={s}
                      />
                    </Hidden>
                  }
                  action={
                    s.isComingSoon ?
                      <ListItemText style={{ textAlign: 'right', fontSize: 10, color: '#777' }} secondary="Coming soon!" />
                      :
                      <Button
                        onClick={() => props.onClickSetup(s)}
                        color="primary"
                        id={`setup-${ch.name}-bs-${s.id}`}
                        name={`setup-${ch.name}-bs-${s.id}`}
                      >
                        SETUP
                    </Button>
                  }
                />

                {
                  adCarousel ?
                    <AdCarousel data={adCarousel} />
                    : null
                }

              </Card>
            </Grid>
          );
        }
        )}
    </>
  );
};


const styles = theme => ({
  cardHeader: {
    borderBottom: '1px solid #efefef',
  },
});

export default withStyles(styles)(setupBoostedServicesList);

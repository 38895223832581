import React from 'react';

import { log, withContext } from 'kn-react';
import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import history from 'history.js';



class CreateOfflineEventSet extends React.Component {
  state = {
    offlineEventSetId: '',
    boostedServiceId: ''
  }


  onCreate = () => {
    Axios.post(
      `/api/ads/facebook_offline_event_sets`,
      {
        offline_event_set_id: this.state.offlineEventSetId,
        boosted_service_id: this.state.boostedServiceId
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('onCreate', response);
      this.props.onClose();
      this.props.showSnackbar('Offline Event Set created.');
      history.push(`/ads/facebook_templates/offline_event_sets/${ response.data.facebook_offline_event_set.id }`);
    });
  }



  render() {
    const { boostedServices } = this.props;

    return (
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Offline Event Set</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a new Offline Event Set, enter the ID and select a Facebook Campaign Template.
            </DialogContentText>


            <TextField
              autoFocus
              margin="dense"
              id="offline-event-set-id"
              label="Offline Event Set ID"
              value={this.state.offlineEventSetId}
              onChange={ e => this.setState({ offlineEventSetId: (e.target.value || '').trim() }) }
              type="text"
              fullWidth
            />


            <FormControl fullWidth margin="normal">
              <InputLabel>Boosted Service</InputLabel>
              <Select
                native
                value={this.state.boostedServiceId}
                onChange={ e => this.setState({ boostedServiceId: e.target.value })}
              >
                <option value=""></option>
                {
                  boostedServices.map(b => (
                    <option value={b.id} key={b.id}>{ b.name } - { b.id} </option>
                  ))
                }
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.onCreate}
              disabled={!this.state.offlineEventSetId || ! this.state.boostedServiceId}
              color="primary"
              variant="contained"
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

CreateOfflineEventSet.defaultProps = {
  boostedServices: [],
};

export default withContext(UserRoleContext, SnackbarContext, CreateOfflineEventSet);
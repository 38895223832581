import React from 'react';
import Axios from 'axios';

import { CompanyRoleContext } from 'go-boost-library-react';
import { log, withContext } from 'kn-react';

import ReviewTrackersLocationModel from './ReviewTrackersLocationModel';



const DEFAULT_STATE = {
  loading: true,
  reviewTrackersLocation: null,
};


export const ReviewTrackersLocationContext = React.createContext(DEFAULT_STATE);


class ReviewTrackersLocationProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchReviewTrackersLocation();
  }


  fetchReviewTrackersLocation = () => {
    log('fetchReviewTrackersLocation');

    const { company } = this.props;
    return Axios.get(
      `/api/reviews/review_trackers_locations/companies/${ company.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(this.parseReviewTrackersLocationResponse)
    .catch(error => {
      if(error.response.status === 404){
        log('No reviewTrackersLocation found.');
        return;
      }
      log('fetchReviewTrackersLocation error.response', error.response);
    })
    .finally(() => this.setState({ loading: false }));
  }


  createReviewTrackersLocation = () => {
    log('createReviewTrackersLocation');

    const { company } = this.props;
    return Axios.post(
      `/api/reviews/review_trackers_locations/companies/${ company.id }`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(this.parseReviewTrackersLocationResponse);
  }


  updateReviewTrackersLocation = updatedAttributes => {
    log('updateReviewTrackersLocation', updatedAttributes);

    return Axios.put(
      `/api/reviews/review_trackers_locations/${ this.state.reviewTrackersLocation.id }`,
      updatedAttributes,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(this.parseReviewTrackersLocationResponse);
  }


  parseReviewTrackersLocationResponse = response => {
    return new Promise(resolve => {
      let reviewTrackersLocation = ReviewTrackersLocationModel.fromJSON(response.data.review_trackers_location);
      log('parseReviewTrackersLocationResponse', reviewTrackersLocation);

      this.setState({ reviewTrackersLocation, loading: false }, () => resolve(reviewTrackersLocation));
    });
  }


  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <ReviewTrackersLocationContext.Provider
        value={{
          reviewTrackersLocation: this.state.reviewTrackersLocation,
          fetchReviewTrackersLocation: this.fetchReviewTrackersLocation,
          createReviewTrackersLocation: this.createReviewTrackersLocation,
          updateReviewTrackersLocation: this.updateReviewTrackersLocation,
        }}
      >
        { this.props.children }
      </ReviewTrackersLocationContext.Provider>
    );
  }

}


export default withContext(
  CompanyRoleContext,
  ReviewTrackersLocationProvider
);

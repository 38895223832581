import React from 'react';
import Axios from 'axios';

import { log, format, withContext } from 'kn-react';
import { TextStatusIndicator, UserRoleContext, CompanyRoleContext } from 'go-boost-library-react';

import { Grid, Divider, withStyles, Paper, Typography, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';

import paperPadding from 'paperPadding';
import ReviewRequestModel from 'Reviews/ReviewRequestModel';
import UserModel from 'Settings/Users/UserModel';

class CompanyReviewRequestsTable extends React.Component {
  state = {
    loading: true,
    reviewRequest: null,
    user: null,
  };

  componentDidMount = () => {
    this.fetchReviewRequest()
  }

  fetchReviewRequest = () => {
    return Axios.get(
      `/api/reviews/review_requests/${this.props.match.params.id}`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
      }
    )
      .then(response => {
        log('fetchReviewRequest response', response);
        const reviewRequest = ReviewRequestModel.fromJSON(response.data.review_request);
        const user = UserModel.fromJSON(response.data.user);
        this.setState({ reviewRequest, user, loading: false })
      })
  }

  render() {
    const { classes } = this.props;
    const { loading, reviewRequest, user} = this.state;
    if(loading) {
      return null;
    }

    return (
      <Paper className={classes.paper}>
       <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Review Request</Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItem key={ reviewRequest.id }>
              <ListItemText
                primary={reviewRequest.fullName}
                secondary={
                  <>
                  <span>Sent by { user.firstName } { user.lastName } on { format.dateTime(reviewRequest.createdAt,false) }</span>
                  <br/>
                  {
                    reviewRequest.clickedAt?
                      <TextStatusIndicator text={`Clicked ${format.dateTime(reviewRequest.clickedAt,false)}`} success/>
                    :
                    <TextStatusIndicator text="Not Clicked"/>
                  }
                  </>
                }
              />

            </ListItem>
            <ListSubheader color="primary">Message</ListSubheader>
            <ListItem>
              <ListItemText
                primary={reviewRequest.message}
                primaryTypographyProps={{
                  variant: 'body2'
                }}
              />
            </ListItem>
          </List>
        </Grid>
        </Grid>

      </Paper>
    );
  }

}

const styles = theme => ({
  paper: {
    padding: paperPadding
  },
  label: {
    textAlign: 'right',
    color: theme.palette.text.secondary
  }
});

export default withStyles(styles)(
  withContext(
    CompanyRoleContext,
    UserRoleContext,
    CompanyReviewRequestsTable
  )
);
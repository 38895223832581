import { JsonModel } from 'kn-react';

export default class CouponModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    let model = new CouponModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

  getDiscountCentsWithPlan = plan => (
    this.percentOff ? (this.percentOff / 100) * (plan.planAmountCents) : this.amountOff
  )

}

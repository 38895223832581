import React from 'react';
import Axios from 'axios';

import { log, formHelpers, AutoError, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';



class NewCompanyUserRoleDialog extends React.Component {
  state = {
    possibleCompanyRoles: [],
    roleId: '',
  };

  componentDidMount = () => {
    this.getPossibleCompanyRoles();
  }

  getPossibleCompanyRoles = () => {
    const { company } = this.props;
    log('getPossibleCompanyRoles', company.id);

    return Axios.get(
      `/api/core/companies/${company.id}/roles`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('getPossibleCompanyRoles response', response);
      const possibleCompanyRoles = response.data.roles;
      this.setState({ possibleCompanyRoles });
    });
  }

  addUserRole = () => {
    const { company, userId } = this.props;
    const { roleId } = this.state;


    return Axios.post(
      `/api/core/user_roles`,
      {
        user_id: userId,
        role_id: roleId,
        role_type: 'Company',
        role_type_id: company.id
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('addUserRole response', response);

      this.props.onUserRoleAdded();
      this.props.onClose();
    });
  }

  onProcess = () => {
    log('onProcess', this.state);
    return this.validate()
      .then(this.addUserRole)
      .catch(AutoError.catch.bind(this));;
  }

  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }

  onError = error => {
    log('error', error);
    this.props.showSnackbar(error.response.data.message)
  }

  validate = () => {
    log(this.state.role_type_id)
    const baseValidations = {
      roleId: {
        presence: { message: "Please select the user's role." }
      }
    };
    return formHelpers.validate(this.state, baseValidations);
  }

  render(){
    const { roleId, possibleCompanyRoles } = this.state;


    return (
      <Dialog open={this.props.open} maxWidth="sm">
        <DialogTitle id="form-dialog-title">Add a User Role</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the following information to add a new role for this user.
            </DialogContentText>


            <TextField
              select
              fullWidth
              label="Company Role"
              value={roleId}
              onChange={e => this.setState({ roleId: e.target.value })}
            >
              {
                possibleCompanyRoles.map(r => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))
              }
          </TextField>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <PromiseButton onProcess={this.onProcess}>
            Add
          </PromiseButton>
        </DialogActions>

      </Dialog>
    );
  }

}

const styles = theme => ({

});

export default withStyles(styles)(
  withContext(
    CompanyRoleContext,
    SnackbarContext,
    NewCompanyUserRoleDialog
  )
);
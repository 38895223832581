import React from 'react';

import { SnackbarContext, PromiseButton, PlanContext, KnowledgeBaseButton } from 'go-boost-library-react';
import { withContext, log, MaxWidth, formHelpers, AutoError } from 'kn-react';

import { Typography, Paper, Grid, Button, withStyles, Divider } from '@material-ui/core';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import BaseSiteDomainSettings from './BaseSiteDomainSettings/BaseSiteDomainSettings';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import DomainSettings from './DomainSettings/DomainSettings';
import SitesUndoResubscription from 'Sites/CompanySites/SitesSetup/SitesUndoResubscription/SitesUndoResubscription';
class DomainSetup extends React.Component {

  constructor(props){
    super(props);

    const { companySitesProfile } = props;

    let isByoDomain;
    if (companySitesProfile.byoDomain) {
      isByoDomain = true;
    } else if(companySitesProfile.newDomain) {
      isByoDomain = false;
    } else {
      isByoDomain = '';
    }


    this.state = {
      isByoDomain,
      byoDomain: companySitesProfile.byoDomain || '',
      newDomain: companySitesProfile.newDomain || '',
    };
  }

  componentDidMount() {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(currentUserRole.userId, 'finished_sites_plan_step', getUserRoleAuthHeaders);

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      'started_sites_domain_step',
      getUserRoleAuthHeaders,
      { funnel: true }
    );
  }


  onChangeIsByoDomain = isByoDomain => {
    const newState = isByoDomain ? { newDomain: '' } : { byoDomain: '' };
    this.setState({...newState, isByoDomain });
  }


  onChangeByoDomain = byoDomain => {
    let domain = byoDomain && byoDomain.toLowerCase() || '';

    domain = domain.replace(/^http:\/\/|^https:\/\/|^www\./, '');

    this.setState({ byoDomain: domain });
  }


  onChangeSubdomain = subdomain => {
    const selectedPlan = this.props.upgradableSitesPlans.find(p => p.id == this.props.selectedPlanId);

    subdomain = subdomain.replace(/^www\./, '');
    const byoDomain = `${ subdomain }.${ selectedPlan && selectedPlan.baseSiteDomain || '' }`.replace(/[^a-zA-Z0-9\-_\.]/, '').toLowerCase();

    this.setState({
      byoDomain,
      isByoDomain: true
    });
  }


  onChangeNewDomain = newDomain => {
      const domain = newDomain && newDomain.toLowerCase() || null;

      this.setState({ newDomain: domain });
    // if( newDomain ){
    //   const domain = newDomain && newDomain.toLowerCase() || null;

    //   this.setState({ newDomain: domain });
    // }
  }


  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions);
  }

  onClickNext = () => {
    return this.validate()
      .then(this.updateCompanySitesProfile)
      .then(() => this.props.transitionTo('next', this.props.transitions))
      .catch(error => {
        if( error.response && error.response.status === 409 ){
          this.props.showSnackbar( error.response.data.message );
        } else{
          throw error
        }
      })
      .catch(AutoError.catch.bind(this));
  }


  validate = () => {
    let validations = {};

    const selectedPlan = this.props.upgradableSitesPlans.find(p => p.id == this.props.selectedPlanId);

    if(this.state.isByoDomain && selectedPlan && selectedPlan.isBaseSite) {
      const domain = ( selectedPlan.baseSiteDomain || '' ).replace('.', '\\.');

      validations.byoDomain = [
        {
          regex: {
            pattern: new RegExp(`^[^\\.]+\\.${ domain }$`),
            message: `Subdomain cannot contain a '.'. Contact Support for help.`
          }
        },
        {
          regex: {
            pattern: new RegExp(`^(${ domainRegex }\\.?)+${ domain }$`),
            message: `Please enter a valid subdomain.`
          }
        },
      ];
    } else if(this.state.isByoDomain) {
      validations.byoDomain = byoDomainValidations;
    } else {
      validations.newDomain = {
        presence: {
          message: `Please find a new domain.`
        }
      };
    }

    return formHelpers.validate(this.state, validations);
  }

  onValidationError = e => {
    this.props.showSnackbar(e.message);
  }

  updateCompanySitesProfile = () => {
    const { byoDomain, newDomain, isByoDomain } = this.state;
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'finished_sites_plan_step', this.props.getUserRoleAuthHeaders);


    if( this.state.isByoDomain ){
      trackCurrentUserSegmentEvent(
        currentUserRole.userId,
        'byod_domain_info_needed',
        getUserRoleAuthHeaders
      );
      trackCurrentUserSegmentEvent(
        currentUserRole.userId,
        'added_byo_domain',
        getUserRoleAuthHeaders
      );
    } else {
      trackCurrentUserSegmentEvent(
        currentUserRole.userId,
        'added_domain_to_buy',
        getUserRoleAuthHeaders
      );
    }

    return this.props.updateCompanySitesProfile({
      // set null to an empty string so the profile only has
      // either a byo_domain or a new_domain, but not both
      byo_domain: isByoDomain ? byoDomain : null,
      new_domain: isByoDomain ? null : newDomain,
    });
  }


  render(){
    const { classes } = this.props;
    const { isByoDomain, byoDomain, newDomain } = this.state;

    let canNext = (
      ( isByoDomain !== '' || newDomain === this.props.companySitesProfile.purchasedDomain ) &&
      (
        ( isByoDomain && byoDomain ) ||
        ( !isByoDomain && newDomain )
      )
    );

    const selectedPlan = this.props.upgradableSitesPlans.find(p => p.id == this.props.selectedPlanId);


    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <SitesUndoResubscription />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">
              {
                selectedPlan && selectedPlan.isBaseSite ?
                  <>
                    Pick a Subdomain

                    &nbsp;

                    <KnowledgeBaseButton
                      path={'/base-site-domains'}
                      tooltipTitle={'Learn more about Base Site Domains'}
                    />
                  </>
                :
                  <>
                    Pick a Domain

                    &nbsp;

                    <KnowledgeBaseButton
                      path={'/domains'}
                      tooltipTitle={'Learn more about Domains'}
                    />
                  </>
              }
            </Typography>

            {
              selectedPlan && selectedPlan.isBaseSite ?
                null
              :
                <Typography variant="body2">You can use your existing domain. If you don't have one, you can purchase one.</Typography>
            }
          </Grid>

          {/* <CancelSetupButton /> */}

          <Grid item xs={12}>
            <Paper>
              {
                selectedPlan && selectedPlan.isBaseSite ?
                  <BaseSiteDomainSettings
                    domain={selectedPlan.baseSiteDomain || ''}
                    subdomain={this.state.byoDomain.replace(`.${ selectedPlan.baseSiteDomain }`, '')}
                    onChangeSubdomain={this.onChangeSubdomain}
                  />
                :
                  <DomainSettings
                    isByoDomain={this.state.isByoDomain}
                    byoDomain={this.state.byoDomain}
                    newDomain={this.state.newDomain}
                    onChangeIsByoDomain={this.onChangeIsByoDomain}
                    onChangeByoDomain={this.onChangeByoDomain}
                    onChangeNewDomain={this.onChangeNewDomain}
                    ownedDomain={this.props.companySitesProfile.purchasedDomain}
                  />
              }
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.onClickBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <PromiseButton
              onProcess={this.onClickNext}
              buttonProps= {{
                disabled: !canNext,
                variant: 'contained',
                color: 'primary',
                type: 'submit',
              }}
            >
              Next
            </PromiseButton>
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }

}

const styles = theme => ({

});


export default withStyles(styles)(
  withContext(TransitionContext, SnackbarContext, PlanContext, DomainSetup)
);



export const domainRegex = '[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]?';
export const tldRegex = '[a-zA-Z]{2,}';

export const byoDomainValidations = {
  regex: {
    pattern: new RegExp(`^${ domainRegex }\\.${ tldRegex }$`),
    message: `Please enter a valid existing domain.`
  }
}
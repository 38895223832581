import React from 'react';
import { withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import CompanyReviews from './CompanyReviews/CompanyReviews';
import OrganizationReviews from './OrganizationReviews/OrganizationReviews';

const reviews = props => (
  props.currentUserRole.isCompanyRole() ? <CompanyReviews/> : <OrganizationReviews/>
);

export default withContext(UserRoleContext, reviews);
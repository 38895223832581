import React from 'react';
import { Paper, Grid, withStyles, Typography, TextField, Button } from '@material-ui/core';
import paperPadding from 'paperPadding';

// TODO: @cotter this should be AdsSettingsCard right?
class AdsSettings extends React.Component {

  render(){
    const {classes, companyShortName, facebookPageUrl } = this.props;

    const Wrapper = this.props.includePaper ? Paper : React.Fragment;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Wrapper className={classes.paperRoot}>
            <Typography variant="subtitle1" color="primary">Ads Company Name</Typography>
            <Typography variant="body2">Please provide a name for your company that will appear in your ads. Internet Ad Platforms limit the number of characters in each ad &mdash; so in your ads, your company name must be 30 characters or less.</Typography>
            <TextField
              label="Ads Company Name"
              value={companyShortName}
              onChange={e => this.props.onChangeCompanyShortName(e.target.value)}
              onBlur={e => this.props.onChangeCompanyShortName(e.target.value.trim())}
              inputProps={{
                maxLength: 30
              }}
              fullWidth
              margin="normal"
              name='company-short-name'
            />
            <Typography variant="caption" paragraph>{ companyShortName.length } of 30 characters</Typography>

            {
              facebookPageUrl ?
                <>
                  <Typography variant="subtitle1" color="primary">Facebook Page Url</Typography>
                  <Typography variant="body2" className={classes.companyShortNameExplanation}>To setup  Boosted Services with Facebook, we'll need to request access to create ads for your page. Please provide your page url below.</Typography>
                  <TextField
                    label="Facebook Page Url"
                    value={facebookPageUrl}
                    onChange={e => this.props.onChangeFacebookPageUrl(e.target.value)}
                    onBlur={e => this.props.onChangeFacebookPageUrl(e.target.value.trim())}
                    fullWidth
                    margin="normal"
                  />
                </>
              : null
            }
          </Wrapper>
        </Grid>
      </Grid>
    );
  }

}

const styles = theme => ({
  paperRoot: {
    padding: paperPadding,
  },

})

AdsSettings.defaultProps = {
  companyShortName: '',
  facebookPageUrl: '',
  includePaper: true,
}


export default withStyles(styles)(AdsSettings);

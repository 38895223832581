import React from 'react';
import Axios from 'axios';

import { SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, AutoError } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import CompanySocialProfileModel from './CompanySocialProfileModel';
import history from 'history.js';



const DEFAULT_STATE = {
  profileLoading: true,
  companySocialProfile: null,
};


export const CompanySocialProfileContext = React.createContext(DEFAULT_STATE);


class CompanySocialProfileProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }



  fetchData = () => {
    return this.fetchCompanySocialProfile()
      .finally(() => this.setState({ profileLoading: false }));
  }



  fetchCompanySocialProfile = () => {
    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/companies/${currentUserRole.roleTypeId}/company_social_profile`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchCompanySocialProfile', response);
      const companySocialProfile = CompanySocialProfileModel.fromJSON(response.data.company_social_profile);
      this.setState({ companySocialProfile });
    }).catch(error => {
      if(error.response.status !== 404) {
        throw error;
      }
      log('Company Social Profile Not Found')
    });
  }



  createCompanySocialProfile = () => {
    log('createCompanySocialProfile')
    return Axios.post(
      `/api/social/company_social_profiles`,
      { company_id: this.props.company.id },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      response => log('createCompanySocialProfile', response)
    ).then(
      this.fetchCompanySocialProfile
    );
  }



  enqueueCompanySocialProfileSetup = () => {
    log('enqueueCompanySocialProfileSetup');
    const { companyId } = this.state.companySocialProfile;

    return Axios.post(
      `/api/social/company_social_profiles/companies/${ companyId }/enqueue`,
      { },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      this.fetchCompanySocialProfile
    );
  }



  shutdownCompanySocialProfile = () => {
    log('shutdownCompanySocialProfile');
    const { id } = this.state.companySocialProfile;
    return Axios.post(
      `/api/social/company_social_profiles/${id}/shutdown`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('shutdownCompanySocialProfile response', response);
    }).then(
      this.props.fetchCompanyRoleProviderData
    ).then(
      this.fetchCompanySocialProfile
    );
  }



  resubscribeToSocial = () => {
    log('resubscribeToSocial');
    const { id } = this.state.companySocialProfile;
    return Axios.post(
      `/api/social/company_social_profiles/${id}/resubscribe`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('resubscribeToSocial response', response);
    }).then(
      this.fetchCompanySocialProfile
    ).then(
      () => history.push('/social')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  undoResubscriptionToSocial = () => {
    log('undoResubscriptionToSocial');
    const { id } = this.state.companySocialProfile;
    return Axios.delete(
      `/api/social/company_social_profiles/${id}/resubscribe`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('undoResubscriptionToSocial response', response);
    }).then(
      this.fetchCompanySocialProfile
    ).then(
      () => history.push('/social')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  onError = error => {
    log('error', error);
    this.props.showSnackbar(
      error.response ?
        error.response.data.message || 'Error'
      :
        'Error'
    );
  }



  render() {
    const { profileLoading } = this.state;

    if (profileLoading) {
      return <LinearProgress />;
    }



    return (
      <CompanySocialProfileContext.Provider
        value={{
          companySocialProfile: this.state.companySocialProfile,
          fetchCompanySocialProfile: this.fetchCompanySocialProfile,
          createCompanySocialProfile: this.createCompanySocialProfile,
          shutdownCompanySocialProfile: this.shutdownCompanySocialProfile,
          resubscribeToSocial: this.resubscribeToSocial,
          undoResubscriptionToSocial: this.undoResubscriptionToSocial,
          enqueueCompanySocialProfileSetup: this.enqueueCompanySocialProfileSetup
        }}
      >
        {this.props.children}
      </CompanySocialProfileContext.Provider>
    );
  }
}


export default withContext(
  CompanyRoleContext,
  SnackbarContext,
  CompanySocialProfileProvider
);
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 512 512" x="0px" y="0px">
    <circle cx="256" cy="256" r="240.92" fill="#ffd972"/>
    <path d="M251.12 321.81c61.11 0 115.19 9.37 148.26 23.76-1.19 2.15-2.45 4.26-3.76 6.35a127.12 127.12 0 0 0 21.67-70.64c0-5.34-322.58-4.95-322.58 0a126.61 126.61 0 0 0 18.93 66.35c-1-1.73-2-3.48-2.89-5.25 33.44-12.57 83.9-20.57 140.37-20.57z" fill="#252c37"/>
    <path d="M256 422.32c-59.81 0-112-28.46-139.86-70.75q-1.29-1.95-2.5-3.94c1.31 2.29 2.68 4.56 4.13 6.78 27.51 42 79.07 70.3 138.15 70.3 60.23 0 112.62-29.41 139.7-72.79-27.9 42.08-79.98 70.4-139.62 70.4z" fill="#f25268"/>
    <path d="M256 422.32c59.64 0 111.72-28.31 139.62-70.4 1.31-2.09 2.57-4.2 3.76-6.35-33.07-14.39-87.15-23.76-148.26-23.76-56.47 0-106.93 8-140.37 20.57.92 1.77 1.89 3.52 2.89 5.25q1.22 2 2.5 3.94C144 393.86 196.19 422.32 256 422.32z" fill="#f25268"/>
    <path d="M94 163.84l62.32 32.61c5.94 3.11 13.51 1.53 17-4.46 3.27-5.6 1.51-13.89-4.46-17l-62.32-32.62c-5.95-3.11-13.51-1.52-17 4.46-3.27 5.6-1.51 13.89 4.47 17z" fill="#252c37"/>
    <path d="M106.85 229.48l62.32-32.62c5.94-3.11 7.73-11.41 4.46-17-3.53-6-11-7.59-17-4.46L94.29 208c-5.94 3.12-7.73 11.42-4.46 17 3.53 6 11 7.58 17 4.46z" fill="#252c37"/>
    <path d="M405.43 142.36L343.11 175c-5.95 3.11-7.74 11.41-4.46 17 3.53 6 11 7.59 17 4.46L418 163.84c5.95-3.12 7.74-11.41 4.47-17-3.53-6-11.05-7.58-17-4.46z" fill="#252c37"/>
    <path d="M417.71 208l-62.32-32.61c-5.95-3.11-13.52-1.52-17 4.46-3.27 5.6-1.51 13.89 4.46 17l62.32 32.62c6 3.11 13.52 1.52 17-4.46 3.27-5.6 1.51-13.89-4.46-17z" fill="#252c37"/>
  </SvgIcon>
);
import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, withProvider } from 'kn-react';

import { withStyles } from '@material-ui/core';

import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import { GoogleAccountContext } from 'GoogleAccount/GoogleAccountProvider';
import CompanyAdsProfileProvider, { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import CompanySocialProfileProvider, { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import fetchReviewsNextSteps from './ReviewsNextSteps';
import ProductCompletionPage from 'SharedSetup/ProductCompletionPage/ProductCompletionPage';


class ReviewsSetupCompletion extends React.Component {
  state = {
    nextSteps: []
  }

  componentDidMount = () => {
    return fetchReviewsNextSteps({
      facebookAccountPage: this.props.facebookAccountPage,
      googleAccountLocation: this.props.googleAccountLocation,
      company: this.props.company,
      getUserRoleAuthHeaders: this.props.getUserRoleAuthHeaders,
      currentUserRole: this.props.currentUserRole,
    }).then(nextSteps => this.setState({ nextSteps }))
  }

  render(){
    const { classes } = this.props;

    const possibleLinks = [
      {
        isAvailable: this.props.isAdsAvailable,
        profile: this.props.companyAdsProfile,
        text: 'Setup Ads',
        link: '/ads'
      },
      {
        isAvailable: this.props.isSitesAvailable,
        profile: this.props.companySitesProfile,
        text: 'Setup Sites',
        link: '/sites'
      },
      {
        isAvailable: this.props.isSocialAvailable,
        profile: this.props.companySocialProfile,
        text: 'Setup Social',
        link: '/social'
      },
    ];

    const productLinks = [];
    possibleLinks.forEach(
      l => {
        if(
          l.isAvailable &&
          (
            !l.profile ||
            ( l.profile && !l.profile.isSetupComplete )
          )
        ){
          productLinks.push({
            text: l.text,
            link: l.link,
          })
        }
      }
    );


    const knowledgeBaseArticles = [
      {
        url: `${ this.props.theme.knowledgeBaseUrl || 'https://support.goboost.com/goboost' }/responding-to-reviews`,
        name: 'Respond to Reviews'
      },
      {
        url: `${ this.props.theme.knowledgeBaseUrl || 'https://support.goboost.com/goboost' }/sending_review_requests`,
        name: 'Learn About Requesting Reviews'
      }
    ];


    return(
      <ProductCompletionPage
        product={'Reviews'}
        nextSteps={this.state.nextSteps}
        productLinks={productLinks}
        dashboardLink={'/reviews'}
        knowledgeBaseArticles={knowledgeBaseArticles}
      />
    )
  }
}



const styles = theme => ({})



export default withProvider(
  CompanyAdsProfileProvider,
  CompanySitesProfileProvider,
  CompanySocialProfileProvider,
  withContext(
    CompanyAdsProfileContext,
    CompanySitesProfileContext,
    CompanySocialProfileContext,
    FacebookAccountContext,
    GoogleAccountContext,
    CompanyRoleContext,
    PlanContext,
    withStyles(styles, { withTheme: true })(ReviewsSetupCompletion)
  )
)
import React from 'react';
import { withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import CompanySites from './CompanySites/CompanySites';
import CompanySitesProfileProvider from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import MediaItemsProvider from 'MediaItems/MediaItemsProvider';
import OrganizationSites from './OrganizationSites/OrganizationSites';
import ChannelProvider from 'Channels/ChannelProvider';
import { default as CompanySiteDesignsProvider } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import { default as OrganizationSiteDesignsProvider } from 'Sites/OrganizationSites/SiteDesigns/SiteDesignsProvider';

const sites = props => (
  <ChannelProvider>
    <MediaItemsProvider>
      {
        props.currentUserRole.isCompanyRole() ?
          <CompanySitesProfileProvider>
            <CompanySiteDesignsProvider>
              <CompanySites/>
            </CompanySiteDesignsProvider>
          </CompanySitesProfileProvider>
        :
          <OrganizationSiteDesignsProvider>
            <OrganizationSites/>
          </OrganizationSiteDesignsProvider>
      }
    </MediaItemsProvider>
  </ChannelProvider>
);

export default withContext(UserRoleContext, sites);
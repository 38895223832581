import React from 'react';

import { withStyles } from '@material-ui/core';

const facebookPostButton = props => (
  <button
    className={props.classes.button}
    onClick={props.onClick}
  >
    Post
  </button>
)

const styles = theme => ({
  button: {
    cursor: 'pointer',
    fontSize: 12,
    padding: '0 16px',
    height: 28,
    width: '100%',
    backgroundColor: '#4267b2',
    borderColor: '#4267b2',
    color: '#fff',
    border: '1px solid',
    borderRadius: '2px',
    fontSize: '12px',
    '-webkit-font-smoothing': 'antialiased',
    fontWeight: 'bold',
    justifyContent: 'center',
    padding: '0 8px',
    position: 'relative',
    textAlign: 'center',
    textShadow: 'none',
    verticalAlign: 'middle',

    '&:hover': {
      backgroundColor: '#2851A3'
    },
  }
})

facebookPostButton.defaultProps = {
  onClick: () => {}
}

export default withStyles(styles)(facebookPostButton)
import React from 'react';
import { Link } from 'react-router-dom';

import { withContext } from 'kn-react';
import { ServicesColors, MegaphoneIcon, UserRoleContext, Ability } from 'go-boost-library-react';

import { Dashboard, Call, Build } from '@material-ui/icons';
import { Typography, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ProductCard from '../ProductCard/ProductCard';

const colors = ServicesColors.ads;

const goToAds = props => {
  const { classes } = props;
  return (
    <ProductCard colors={colors} icon={MegaphoneIcon} backgroundImage="/images/ads_background.png">

      <Typography paragraph variant="h6">
        Ads
      </Typography>

      <Button
        fullWidth
        variant="contained"
        component={Link}
        to="/ads"
        className={classes.containedButton}
        style={{ backgroundColor: colors.primary, color: colors.contrastText }}
      >
        <Dashboard className={classes.leftIcon}/>
        Dashboard
      </Button>

      <Button
        fullWidth
        variant="outlined"
        component={Link}
        to="/ads/boosted_services"
        className={classes.outlinedButton}
        style={{ borderColor: colors.primary, color: colors.primary }}
      >
        <Build className={classes.leftIcon}/>
        Boosted Services
      </Button>


      {/* {
        props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_USERS ) ?
          <Button
            fullWidth
            variant="outlined"
            component={Link}
            to="/ads/opportunities"
            className={classes.outlinedButton}
            style={{ borderColor: colors.primary, color: colors.primary }}
          >
            <Call className={classes.leftIcon}/>
            Opportunities
          </Button>
        : null
      } */}


    </ProductCard>
  );
}


const styles = theme => ({
  containedButton: {
    color: 'white',
    backgroundColor: '#FD9A51',
    '&:hover': {
      backgroundColor: '#FD9A51',
    },
    marginBottom: 15
  },
  outlinedButton: {
    borderColor: '#FD9A51',
    '&:hover': {
      borderColor: '#FD9A51',
    },
    marginBottom: 15
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
})

export default withStyles(styles)(
  withContext(UserRoleContext, goToAds)
);
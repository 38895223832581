import { JsonModel } from 'kn-react';

export default class CompanySitesProfileModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    let model = new CompanySitesProfileModel();
    model = JsonModel.modelFromJSON(model, json);
    return model;
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }


  getActiveDomain(){
    return this.byoDomain || this.newDomain;
  }

  getSiteLink(){
    return `https://www.${this.getActiveDomain()}`;
  }

}

import moment from 'moment';

import { fillMissingMoments, chartFormats } from 'SharedDashboard/sharedDashboard';


export const createStatsChartData = (stats, timePeriod) => {
  if( !stats.length ){
    return []
  }


  const endMoment = moment();
  let startMoment;
  if (timePeriod.numberOfUnits) {
    startMoment = endMoment.clone().subtract(timePeriod.numberOfUnits, timePeriod.unit);
  } else if( stats[0].reported_on ) {
    startMoment = moment(stats[0].reported_on);
  } else {
    startMoment = endMoment;
  }

  let statsData = fillMissingMoments(
    stats,
    startMoment,
    endMoment,
    timePeriod.unit,
    d => d.reported_on,
    (m, d = {
      post_impressions: 0,
      post_impressions_unique: 0,
      post_engaged_users: 0,
      post_reactions_like_total: 0
    }) => ({
      'Impressions': d.post_impressions,
      'Daily Unique Impressions': d.post_impressions_unique,
      Clicks: d.post_engaged_users,
      Likes: d.post_reactions_like_total,
      name: m.format(chartFormats[timePeriod.unit]),
    })
  );

  let statsChartData = statsData;
  if(timePeriod.numberOfUnits) {
    statsChartData = statsChartData.slice(-timePeriod.numberOfUnits);
  }

  return statsChartData
}


export const createReactionDistribution = (stats, ) => {
  const reactions = {
    post_reactions_like_total: 0,
    post_reactions_love_total: 0,
    post_reactions_wow_total: 0,
    post_reactions_haha_total: 0,
    post_reactions_sorry_total: 0,
    post_reactions_anger_total: 0,
  };

  Object.keys(reactions).forEach(
    r => {
      reactions[r] = stats.map(
        s => s[r]
      ).reduce((a,b) => a+b, 0);
    }
  )

  return reactions
}


export const getStatsTotals = stats => {
  const totalImpressions = stats.map(
    s => s.post_impressions
  ).reduce((a, b) => a+b, 0);

  const totalUniqueImpressions = stats.map(
    s => s.post_impressions_unique
  ).reduce((a, b) => a+b, 0);

  const totalClicks = stats.map(
    s => s.post_engaged_users
  ).reduce((a, b) => a+b, 0);

  const totalLikes = stats.map(
    s => s.post_reactions_like_total
  ).reduce((a, b) => a+b, 0);

  const totalImpressionsFan = stats.map(
    s => s.post_impressions_fan
  ).reduce((a,b) => a+b, 0);

  return {
    totalImpressions,
    totalUniqueImpressions,
    totalClicks,
    totalLikes,
    totalImpressionsFan
  }
}
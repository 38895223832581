import React from 'react';
import Axios from 'axios';

import { withContext } from 'kn-react';
import { SnackbarContext, Alert, PromiseButton, MeetingSummary } from 'go-boost-library-react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, withStyles } from '@material-ui/core';



const DEFAULT_STATE = {
  content: ''
}


class MeetingSummaryDialog extends React.Component {
  state = {
    ...DEFAULT_STATE
  }

  componentDidUpdate = oldProps => {
    if( this.props.open && oldProps.open !== this.props.open ){
      return this.setState({
        ...DEFAULT_STATE
      });
    }
  }

  validateNote = () => {
    return Boolean( this.state.content );
  }


  onAddNote = () => {
    if( !this.validateNote() ){
      this.props.showSnackbar('Please specify the content of the note.');

      return Promise.resolve();
    }

    return this.props.onAddNote(this.state.content);
  }


  render(){
    const { classes } = this.props;


    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth='lg'
        fullWidth
        PaperComponent={'div'}
        PaperProps={{
          className: classes.dialog
        }}
      >
        <div className={classes.dialogContent}>
          <MeetingSummary
            meetingRooms={this.props.meetingRooms}
            meetings={this.props.meetings}
            notes={this.props.notes}
          />
        </div>
      </Dialog>
    )
  }
}


MeetingSummaryDialog.defaultProps = {
  open: false,
  onClose: () => {},
  meetingRooms: [],
  meetings: [],
  notes: [],
}


const styles = theme => ({
  dialogContent: {
    height: '100%',
    padding: 30
  },
  textField: {
    marginBottom: 30
  },
  cancelButton: {
    marginRight: 15
  },
  dialog: {
    height: '100%'
  }
})


export default withContext(
  SnackbarContext,
  withStyles(styles)(
    MeetingSummaryDialog
  )
)
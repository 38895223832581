import React from 'react';
import Axios from 'axios';
import L from 'leaflet';
import * as d3 from 'd3';
import { opportunityConstants } from 'kn-react';
import { ServicesColors } from 'go-boost-library-react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Paper, Typography, withStyles, Link } from '@material-ui/core';
import { Map as LeafletMap, TileLayer, DivOverlay, CircleMarker, Tooltip } from 'react-leaflet';

const { ADDRESS_KEY } = opportunityConstants;


const DEFAULT_BOUNDS = (
  new L.LatLngBounds(
    [[24.396308, -124.848974], [49.384358, -66.885444]]
  )
);


class VirtualWorkRequestMap extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      includedGeographyFeatures: [],
      excludedGeographyFeatures: [],
    }
  }

  componentDidMount = () => {
    return this.initializeMap();
  }


  initializeMap = () => {
    if( !this.map ){
      return setTimeout(
        this.initializeMap,
        100
      );
    }


    window.mapComponent = this;

    this.map.touchZoom.disable();

    const overlayPane = this.map.getPane('overlayPane');
    this.map.createPane('excludedPane', overlayPane);
    this.map.createPane('includedPane', overlayPane);

    this.includedLayer = L.geoJSON(null, {
      pane: 'includedPane',
      style: this.includedStyle,
    }).addTo(this.map);

    this.excludedLayer = L.geoJSON(null, {
      pane: 'excludedPane',
      style: this.excludedStyle,
    }).addTo(this.map);


    return this.fetchFeatures();
  }


  componentDidUpdate = oldProps => {
    if(
      this.props.includedGeoTypeIds !== oldProps.includedGeoTypeIds ||
      this.props.excludedGeoTypeIds !== oldProps.excludedGeoTypeIds
    ){
      return this.fetchFeatures();
    }
  }


  fetchFeaturesForGeographies = geographies => {
    if( !geographies || !geographies.length ){
      return Promise.resolve();
    }

    const promises = geographies.map(
      g => (
        new Promise((resolve, reject) => Axios.get(
          `https://storage.googleapis.com/go-boost-partners-public/census_geographies/${ g }.json`
        ).then(response => resolve(response.data)))
      )
    );

    return Promise.all(
      promises
    );
  }


  fetchFeatures = () => {
    let includedGeographyFeatures;
    let excludedGeographyFeatures;

    return this.fetchFeaturesForGeographies(
      this.props.includedGeoTypeIds
    ).then(_includedGeographyFeatures => {
      includedGeographyFeatures = _includedGeographyFeatures || [];

      return this.fetchFeaturesForGeographies(
        this.props.excludedGeoTypeIds
      );
    }).then(_excludedGeographyFeatures => {
      excludedGeographyFeatures = _excludedGeographyFeatures || [];

      includedGeographyFeatures.forEach(
        f => this.includedLayer.addData( f )
      );

      excludedGeographyFeatures.forEach(
        f => this.excludedLayer.addData( f )
      );

      return this.setState({
        includedGeographyFeatures,
        excludedGeographyFeatures
      });
    })
  }


  includedStyle = feature => {
    return {
      ...this.getGeoTypeStyles(feature),
      fillColor: this.props.includedFill,
      color: this.props.includedStrokeColor,
    }
  }


  excludedStyle = feature => {
    return {
      ...this.getGeoTypeStyles(feature),
      fillColor: this.props.excludedFill,
      color: this.props.excludedStrokeColor,
    }
  }


  getGeoTypeStyles = feature => {
    const currentGeoTypes = this.getCurrentGeoTypes();
    const geoType = feature.properties.geo_type;
    const active = currentGeoTypes.indexOf(geoType) > -1;
    return {
      weight: active ? 1 : 1,
      fillOpacity: active ? 0.6 : 0.2,
    };
  }


  getCurrentGeoTypes = () => {
    const zoom = this.map.getZoom();
    if ( zoom < 8 ) {
      return ['state'];
    } else if (zoom <= 9) {
      return ['county'];
    } else if (zoom <= 10) {
      return ['place'];
    }
    else if (zoom > 10) {
      return ['zcta'];
    }
  }


  render() {
    const { classes } = this.props;

    const opportunitiesWithPositions = this.props.opportunities.filter(
      o => {
        if( !o.additionalData ){
          return false;
        }

        const addressQuestion = o.additionalData.find(
          d => d.key === ADDRESS_KEY
        );
        if( !addressQuestion || !addressQuestion.value ){
          return false;
        }

        const latLng = addressQuestion.value.latLng;
        if(
          !latLng ||
          !latLng.lat ||
          !latLng.lng
        ){
          return false;
        }


        return true;
      }
    );


    let bounds;
    if (opportunitiesWithPositions.length) {
      bounds = new L.LatLngBounds(opportunitiesWithPositions.map(o => {
        const addressQuestion = o.additionalData.find(
          d => d.key === ADDRESS_KEY
        );

        return [
          addressQuestion.value.latLng.lat,
          addressQuestion.value.latLng.lng
        ];
      }));
    } else if( !this.props.center || !this.props.center.length ){
      bounds = DEFAULT_BOUNDS;
    }

    const darkerColor = d3.rgb(ServicesColors.ads.primary).darker(0.2);


    return (
      <LeafletMap
        bounds={bounds ? bounds.pad(this.props.boundsPaddingBufferRatio) : undefined}
        center={this.props.center && this.props.center.length ? this.props.center : undefined}
        zoom={9}
        style={{
          height: 600,
        }}
        ref={mapRef => this.map = mapRef ? mapRef.leafletElement : null}
      >
        <TileLayer
          {...tileLayerProps}
          opacity={this.props.opacity}
        />
        {
          !opportunitiesWithPositions || !opportunitiesWithPositions.length ?
            <div className={classes.noCompanies}>No Virtual Work Requests Yet</div>
          :
            <>
              {
                opportunitiesWithPositions.map((o, i) => {
                  const addressQuestion = o.additionalData.find(
                    d => d.key === ADDRESS_KEY
                  );

                  return (
                    <CircleMarker
                      key={i}
                      center={[addressQuestion.value.latLng.lat, addressQuestion.value.latLng.lng]}
                      weight={1}
                      opacity={0.9}
                      color={darkerColor}
                      fillOpacity={0.6}
                      fillColor={ServicesColors.ads.primary}
                    >
                      <Tooltip>
                        <Typography variant="body2">{o.name}</Typography>
                      </Tooltip>
                    </CircleMarker>
                  );
                })
              }
            </>
        }
      </LeafletMap>
    );
  }


}


VirtualWorkRequestMap.defaultProps = {
  bounds: DEFAULT_BOUNDS,
  center: null,
  boundsPaddingBufferRatio: 0.025,
  opacity: 0.9,
  includedFill: "#85E6D2",
  includedStrokeColor: "#3AC2A4",
  excludedFill: "#EE551D",
  excludedStrokeColor: "#EE551D",
};


const tileLayerProps = {
  url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png',
  attribution: '',
  maxZoom: 19,
  subdomains: 'abcd',
  opportunities: [],
  includedGeoTypeIds: [],
  excludedGeoTypeIds: [],
  includedFill: "#85E6D2",
  includedStrokeColor: "#3AC2A4",
  excludedFill: "#EE551D",
  excludedStrokeColor: "#EE551D",
};



const styles = theme => ({
  root: {
    padding: 12,
  },
  icon: {
    fontSize: 30,
    paddingRight: 10,
    float: 'left'
  },
  metric: {
    textAlign: 'center',
  },
  secondaryText: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right'
    },
  },
  noCompanies: {
    color: '#555',
    opacity: 0.75,
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    lineHeight: '400px',
    textAlign: 'center',
    fontSize: 18,
  }
})


export default withStyles(styles, )(
  VirtualWorkRequestMap
);
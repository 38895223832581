import React from 'react';
import Axios from 'axios';

import { log, withContext } from 'kn-react';
import { OpportunityTable, UserRoleContext, Ability, KnowledgeBaseButton } from 'go-boost-library-react';

import { withStyles, Paper } from '@material-ui/core';

import OpportunitiesSummaryModel from 'Opportunities/OpportunitiesSummaryModel';



class FetchedOpportunitiesTable extends React.Component {
 state = {
    page: 0,
    query: this.props.query || null,
    idQuery: this.props.idQuery || null,
    total: 0,
    opportunities: [],
    loading: true,
    rowsPerPage: this.props.rowsPerPage || this.props.rowsPerPageOptions[0]
  };


  componentDidMount = () => {
    this.fetchOpportunities();
  }


  nonTextFieldPropsHaveUpdated = (nextProps) => {
    return(
      ( this.props.startDate != nextProps.startDate ) ||
      ( this.props.companyIds != nextProps.companyIds ) ||
      ( this.props.channels != nextProps.channels )
    );
  }


  textFieldPropsHaveUpdated = (nextProps) => {
    return(
      ( this.props.query != nextProps.query ) ||
      ( this.props.idQuery != nextProps.idQuery )
    );
  }


  componentWillReceiveProps = nextProps => {
    // if(this.props != nextProps) {
    if( this.nonTextFieldPropsHaveUpdated( nextProps ) ){
      this.setState(
        {
          page: 0,
          total: 0,
          loading: true
        },
        this.fetchOpportunities
      );
    } else if(
      this.textFieldPropsHaveUpdated( nextProps ) &&
      (
        ( nextProps.query && nextProps.query.length >= 3 ) ||
        ( !nextProps.query && this.props.query ) || // clearing query
        ( nextProps.idQuery ) ||
        ( nextProps.idQuery != 0 && !nextProps.idQuery && this.props.idQuery ) // clearing idQuery
      )
    ){
      this.onQueryUpdateFetchRequested({
        query: nextProps.query,
        idQuery: nextProps.idQuery
      });
    }
  }


  onQueryUpdateFetchRequested = ({ query, idQuery }) => {
    clearTimeout(this.fetchQueryTimeout);

    this.fetchQueryTimeout = setTimeout(() => {
      this.setState(
        { query, idQuery, page: 0, total: 0, loading: true },
        this.fetchOpportunities
      );
    }, 500);
  };


  changePage = (page) => {
    this.setState(
      { page, loading: true },
      () => this.fetchOpportunities()
    )
  }


  changeRowsPerPage = (rowsPerPage) => {
    this.setState(
      { rowsPerPage, loading: true },
      () => this.fetchOpportunities()
    )
    this.props.onChangeRowsPerPage(rowsPerPage);
  }


  fetchElasticsearchOpportunities = () => {
    const { idQuery } = this.state;
    const channelIds = this.props.channels.map(c => +c.id).filter(c => c);

    const query = (
      this.state.query && this.state.query.split(' ').length > 1 ?
        `"${ this.state.query }"`
      :
        `*${ this.state.query }*`
    );

    const opportunityData = {
      page: this.state.page,
      limit: this.state.rowsPerPage || this.props.rowsPerPageOptions[0],
      start_date: this.props.startDate || undefined,
      channel_ids: channelIds.length ? channelIds : undefined,
      company_ids: this.props.companyIds || undefined,
      opportunity_types: ['call','form_entry'],
      is_spam: false,
      id_query: idQuery ? idQuery : undefined,
      q: query,
    };

    return Axios.get(
      `/api/core/opportunities/_search`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: opportunityData
      }
    );
  }


  fetchDbOpportunities = () => {
    const { query, idQuery } = this.state;
    const channelIds = this.props.channels.map(c => c.id);

    const opportunityData = {
      page: this.state.page,
      limit: this.state.rowsPerPage || this.props.rowsPerPageOptions[0],
      start_date: this.props.startDate || undefined,
      channel_ids: channelIds,
      company_ids: this.props.companyIds,
      opportunity_types: ['call','form_entry'],
      include_descendants: (!this.props.companyIds.length && this.props.roleTypePath === 'organizations') || undefined,
      // is_spam: this.props.isSpamFiltered ? false : undefined
      is_spam: false,
      id_query: idQuery ? idQuery : undefined,
      query: query ? query : undefined,
    };

    return Axios.get(
      `/api/core/opportunities/${this.props.roleTypePath}/${this.props.roleTypeId}`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: opportunityData
      }
    );
  }


  fetchOpportunities = () => {
    let fetchOpportunitiesFunction;
    if(
      this.state.query &&
      (
        this.props.currentUserRole.isCompanyRole() ||
        ( this.props.currentUserRole.roleTypeName === 'GoBoost' )
      )
    ){
      fetchOpportunitiesFunction = this.fetchElasticsearchOpportunities;
    } else {
      fetchOpportunitiesFunction = this.fetchDbOpportunities;
    }


    return fetchOpportunitiesFunction()
      .then(response => {
        log('fetchOpportunities response', response);
        const opportunities = OpportunitiesSummaryModel.fromJSON(response.data.opportunities);

        this.setState({
          opportunities: opportunities.opportunities,
          total: response.data.total,
          loading: false
        });
        log('Latest opportunities', opportunities.opportunities)
      });
  }


  onProcessSpam = opportunityId => {
    return Axios.post(
      `/api/core/opportunities/${ opportunityId }/spam`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then( this.fetchOpportunities )
  }


  render(){
    const { rowsPerPageOptions } = this.props;

    const actions = (
      <>
        { this.props.actions }

        {
          !this.props.shouldShowKnowledgeBase ?
            null
          :
            <>
              &nbsp;

              <KnowledgeBaseButton
                path={this.props.knowledgeBasePath || '/lead_opportunities_from_your_website'}
                tooltipTitle={`Learn more about your ${ this.props.opportunityTableTitle }`}
              />
            </>
        }
      </>
    );

    return (
      <Paper>
        <OpportunityTable
          total={this.state.total}
          page={this.state.page}
          opportunities={this.state.opportunities}
          loading={this.state.loading}
          rowsPerPage={this.state.rowsPerPage || rowsPerPageOptions[0]}
          rowsPerPageOptions={rowsPerPageOptions}
          changePage={p => this.changePage(p)}
          changeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage) }
          showCompanyName={this.props.showCompanyName}
          // isSpamFiltered={this.props.isSpamFiltered && this.props.displaySpamLabel}
          actions={actions}
          showMarkAsSpam={this.props.currentUserRole.roleHasAbility( Ability.EDIT_OPPORTUNITY_IS_MANUAL_SPAM )}
          onProcessSpam={this.onProcessSpam}
          opportunityTableTitle={this.props.opportunityTableTitle}
          showViewOpportunity={this.props.showViewOpportunity}
          viewOpportunityRoute={this.props.viewOpportunityRoute}
        />
      </Paper>
    );
  }

}

const styles = theme => ({
  progress: {
    theme:theme
  }
})

FetchedOpportunitiesTable.defaultProps = {
  rowsPerPage: 10,
  rowsPerPageOptions: [10,25,50],
  channels: [],
  startDate: null,
  companyIds: [],
  onChangeRowsPerPage: r => {
    log('new rows per page',r)
  },
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  showCompanyName: true,
  isSpamFiltered: null,
  displaySpamLabel: true,
  actions: null,
  query: null,
  idQuery: null,
  opportunityTableTitle: 'Opportunities',
  showViewOpportunity: false,
  viewOpportunityRoute: () => {},
  shouldShowKnowledgeBase: false,
  knowledgeBasePath: ''
}


export default withContext(
  UserRoleContext,
  withStyles(styles)(
    FetchedOpportunitiesTable
  )
)
import React from 'react';

import { log, withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import { LinearProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import BoostedServiceModel from '../../BoostedServiceModel';
import CreateCampaignTemplate from './CampaignTemplate/CreateCampaignTemplate';
import CreateConversionTrackerTemplate from './ConversionTrackerTemplate/CreateConversionTrackerTemplate';
import history from '../../../history';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boostedServices: [],
      loadingConversionTrackerTemplates: true,
      adwordsConversionTrackerTemplates: [],
      createConversionTrackerOpen: false,
      loadingCampaignTemplates: true,
      adwordsCampaignTemplates: [],
      createCampaignOpen: false,
    };
  }


  componentDidMount = () => {
    this.loadBoostedServices()
      .then(this.loadConversionTrackerTemplates)
      .then(this.loadCampaignTemplates);
  }


  loadBoostedServices = () => {
    return Axios.get(
      '/api/ads/boosted_services/all',
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('boostedServices response', response);
        const boostedServices = response.data.boosted_services.map(b => BoostedServiceModel.fromJSON(b));

        this.setState({ boostedServices });
      });
  }


  loadConversionTrackerTemplates = () => {
    return Axios.get(
      '/api/ads/adwords_templates/conversion_trackers',
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        const adwordsConversionTrackerTemplates = response.data.adwords_conversion_tracker_templates;
        log('loadConversionTrackerTemplates', adwordsConversionTrackerTemplates);
        this.setState({
          adwordsConversionTrackerTemplates,
          loadingConversionTrackerTemplates: false
        });
      });
  }


  loadCampaignTemplates = () => {
    return Axios.get(
      '/api/ads/adwords_templates/campaigns',
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('response', response);
        const adwordsCampaignTemplates = response.data.adwords_campaign_templates;
        log('loadCampaignTemplates', adwordsCampaignTemplates);
        this.setState({
          adwordsCampaignTemplates,
          loadingCampaignTemplates: false
        });
      });
  }


  render() {
    const {
      adwordsConversionTrackerTemplates,
      adwordsCampaignTemplates,
      boostedServices,
    } = this.state;

    return (
      <div>
        <CreateConversionTrackerTemplate
          open={this.state.createConversionTrackerOpen}
          onClose={() => this.setState({ createConversionTrackerOpen: false })}
          boostedServices={this.state.boostedServices}
        />
        <List subheader={<li />}>
          <ListSubheader style={{ backgroundColor: 'white' }}>
            Conversion Tracker Templates
            <ListItemSecondaryAction>
              <IconButton onClick={() => this.setState({ createConversionTrackerOpen: true })}>
                <AddIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListSubheader>
          {
            this.loadingConversionTrackerTemplates ?
              <LinearProgress />
              :
              adwordsConversionTrackerTemplates.map((t, i) => {
                const bs = boostedServices.find(b => b.id == t.boosted_service_id);
                return (
                  <ListItem key={`conversion-tracker-${i}`} button onClick={() => history.push(`/ads/adwords_templates/conversion_trackers/${t.id}`)}>
                    <ListItemText
                      primary={
                        `${t.boosted_service_id}-${bs.name}: ${t.name}`
                      }
                      secondary={
                        `Conversion Tracker ID: ${t.conversion_tracker_id}, Version: ${t.version}`
                      }
                    />
                    <Chip
                      label={t.is_enabled ? 'Enabled' : 'Disabled'}
                      color={t.is_enabled ? 'primary' : 'default'}
                    />
                  </ListItem>
                )
              })
          }

          <CreateCampaignTemplate
            open={this.state.createCampaignOpen}
            onClose={() => this.setState({ createCampaignOpen: false })}
            boostedServices={this.state.boostedServices}
          />
          <ListSubheader style={{ backgroundColor: 'white' }}>
            Campaign Templates
            <ListItemSecondaryAction>
              <IconButton onClick={() => this.setState({ createCampaignOpen: true })}>
                <AddIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListSubheader>

          {
            this.loadingCampaignTemplates ?
              <LinearProgress />
              :
              adwordsCampaignTemplates.map((t, i) => {
                const bs = boostedServices.find(b => b.id == t.boosted_service_id);
                return (
                  <ListItem key={`campaign-${i}`} button onClick={() => history.push(`/ads/adwords_templates/campaigns/${t.id}`)}>
                    <ListItemText
                      primary={
                        `${t.boosted_service_id}-${bs.name}: ${t.name}`
                      }
                      secondary={
                        `Campaign ID: ${t.campaign_id}, Version: ${t.version}`
                      }
                    />
                    {
                      t.template_error ?
                        <Chip label="Error" style={{ backgroundColor: 'red', color: 'white' }}
                        />
                        :
                        <Chip
                          label={t.is_enabled ? 'Enabled' : 'Disabled'}
                          color={t.is_enabled ? 'primary' : 'default'}
                        />
                    }
                  </ListItem>
                );
              })
          }
        </List>
      </div>
    );
  }

}

export default withContext(UserRoleContext, Index);
import React from 'react';
import { withProvider, withContext } from 'kn-react';
import { UserRoleContext } from 'go-boost-library-react';
import FeedProvider from 'Social/Feed/FeedProvider';
import MediaItemsProvider from 'MediaItems/MediaItemsProvider'

import CompanySocial from './CompanySocial/CompanySocial';
import OrganizationSocial from './OrganizationSocial/OrganizationSocial';

const social = props => (
  props.currentUserRole.isCompanyRole() ?
    <CompanySocial/>
  :
    <OrganizationSocial/>
);

export default withProvider(
  FeedProvider,
  MediaItemsProvider,
  withContext(
    UserRoleContext,
    social
  )
);
import React from 'react';

import { log, withContext } from 'kn-react';
import { SnackbarContext, PromiseButton, UserRoleContext } from 'go-boost-library-react';
import Axios from 'axios';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';


class CreateFacebookReport extends React.Component {
  state = {
    csvData: '',
  }


  onCreate = () => {
    return Axios.post(
      `/api/ads/facebook_reports`,
      { csv_data: this.state.csvData },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('onCreate response', response);
      this.setState({ csvData: '' }, () => {
        this.props.onSuccess();
        this.props.onClose();
        this.props.showSnackbar(response.data.message);
      })
    })
    .catch(error => {
      log('onCreate error', error);
      this.props.showSnackbar(`Something went wrong. Error: ${ error.response.status } ${ JSON.stringify(error.response).slice(0,255) }...`);
    });
  }



  render() {
    return (
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">New Facebook Report</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create new Facebook Report data, paste the CSV export below.
            </DialogContentText>


            <TextField
              autoFocus
              margin="dense"
              label="CSV Report Data"
              value={this.state.csvData}
              onChange={ e => this.setState({ csvData: (e.target.value || '').trim() }) }
              type="text"
              fullWidth
              multiline
              rowsMax="50"
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              Cancel
            </Button>
            <PromiseButton
              onProcess={this.onCreate}
              disabled={!this.state.csvData.length}
              color="primary"
              variant="contained"
            >
              Create
            </PromiseButton>
          </DialogActions>
        </Dialog>
    );
  }
}

CreateFacebookReport.defaultProps = {
  boostedServices: [],
};

export default withContext(UserRoleContext, SnackbarContext, CreateFacebookReport);
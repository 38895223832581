import Moment from 'moment';

const halfHourIncrements = () => {
  const formatMoment = moment => {
    return moment.format('h:mm A')
  }

  const momentTime = Moment('1/1/2020 12:00 AM');

  const times = [  ];


  for(let i=0; i < 48; i++){
    times.push( formatMoment( momentTime ) );

    momentTime.add(30, 'minutes');
  }

  return times
}

export default halfHourIncrements()
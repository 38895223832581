import React from 'react';
import Axios from 'axios';
import { withStyles, TextField, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon, Button } from '@material-ui/core';
import { RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons';
import { AutoError, formHelpers, withContext, log } from 'kn-react';
import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';


class AvailableDomainsSearch extends React.Component {

  state = {
    loading: false,
    domain: '',
    lastCheckedDomain: ' ',
    domainAvailability: null,
    tooExpensive: null,
    canPurchase: null,
  }

  componentDidMount = () => {
    const { domain } = this.props;
    if(domain) {
      this.setState({ domain }, this.fetchDomainAvailability);
    }
  }



  onSubmit = e => {
    e.preventDefault();
    log('onSubmit');

    return this.validate()
      .then(this.fetchDomainAvailability)
      .catch(AutoError.catch.bind(this));
  }


  validate = () => {

    const validations = {
      domain: {
        regex: {
          pattern: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
          message: `Please enter a valid domain.`
        }
      },
    };

    return formHelpers.validate(this.state, validations);
  }

  onValidationError = e => {
    this.props.showSnackbar(e.message);
  }


  fetchDomainAvailability = () => {
    const { domain } = this.state;

    this.props.onChangeNewDomain('');
    this.setDomainAvailability(null, { loading: true});

    return Axios.post(
      `/api/sites/domains/available`,
      { domain },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchDomainAvailability response', response);
      const domainAvailability = response.data.domain_availability;

      this.setDomainAvailability(domainAvailability, {
        loading: false,
        lastCheckedDomain: domain
      });
    })
  }


  setDomainAvailability = (domainAvailability, additionalState={}) => {
    const domainPriceCents = ( domainAvailability && domainAvailability.price ) || Infinity;
    const renewalPriceCents = ( domainAvailability && domainAvailability.renewal_price ) || Infinity;

    // Though the limit in the UI is $20, account for taxes
    const tooExpensive = Math.max( domainPriceCents, renewalPriceCents ) > 18 * 100;
    const canPurchase = domainAvailability && domainAvailability.available && !tooExpensive;
    const isPreviouslyPurchasedNewDomain = this.props.ownedDomain && this.state.domain === this.props.ownedDomain;

    if(
      ( isPreviouslyPurchasedNewDomain ) ||
      ( !tooExpensive && canPurchase )
    ) {
      const newDomain = (
        isPreviouslyPurchasedNewDomain ?
          this.props.ownedDomain
        :
          domainAvailability.domain
      );

      this.props.onChangeNewDomain( newDomain );
    }

    this.setState({
      ...additionalState,
      domainAvailability,
      tooExpensive,
      canPurchase,
    });

  }


  render(){
    const { classes } = this.props;
    const { domainAvailability, loading, domain, lastCheckedDomain, tooExpensive, canPurchase } = this.state;

    const helperText = loading ? 'Checking availability...' : ( lastCheckedDomain === domain ? ' ' : `Type a domain and press enter to check availability. Don't include "http(s)" or "www".`);
    const isPreviouslyPurchasedNewDomain = this.props.ownedDomain && this.state.domain === this.props.ownedDomain;


    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <TextField
            fullWidth
            label="Find a New Domain"
            placeholder="mycompany.com"
            value={domain}
            onChange={e => this.setState({ domain: (e.target.value || '').trim() })}
            helperText={helperText}
          />

          <Button
            variant="contained"
            onClick={this.onSubmit}
            type="submit"
            size="small"
            color="secondary"
            className={classes.searchButton}
          >
            Check availability
          </Button>
        </form>

        {
          isPreviouslyPurchasedNewDomain ?
            <Paper elevation={1} style={{ marginTop: 15 }}>
              <List>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'h6',
                      color: 'primary'
                    }}
                    id='domain-availability'
                    name='domain-availability'
                    primary={this.props.ownedDomain}
                    secondary={'This domain was already purchased using this platform.'}
                  />
                  <ListItemSecondaryAction>
                    <ListItemText
                      primary="$20.00"
                      secondary="per year"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Paper>
          :
            domainAvailability && this.state.domain === this.state.lastCheckedDomain ?
              <Paper elevation={1} style={{ marginTop: 15 }}>
                <List>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h6',
                        color: 'primary'
                      }}
                      id='domain-availability'
                      name='domain-availability'
                      primary={domainAvailability.domain}
                      secondary={domainAvailability.available ? ( !tooExpensive ? 'Available' : 'This domain is not available from our platform, but may be purchased from other providers.' ): 'Unavailable'}
                    />
                    <ListItemSecondaryAction>
                    {
                        domainAvailability.available && !tooExpensive ?
                          <ListItemText
                            primary="$20.00"
                            secondary="per year"
                          />
                        : null
                      }
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Paper>
            : null
        }


      </div>
    );
  }

}


AvailableDomainsSearch.defaultProps = {
  domain: '',
  onChangeNewDomain: newDomain => {},
  ownedDomain: null,
};


const styles = theme => ({
  searchButton: {
    marginTop: 15
  }
});


export default withStyles(styles)(
  withContext(
    UserRoleContext,
    SnackbarContext,
    AvailableDomainsSearch
  )
);
import React from 'react';
import { format } from 'kn-react';

import { OverTimeChart, HandClickIcon, ChangeMetric } from 'go-boost-library-react';

const clicksChart = props => {
  const clicksLineStyles = {
    'Clicks': {
      type:"monotone",
      dataKey:"Clicks",
      stroke:"#ff9a48",
      activeDot: { r: 8 }
    }
  };


  return (
    <OverTimeChart
      data={props.statsChartData}
      lineStyles={clicksLineStyles}
      tooltipProps={{
        formatter: value => format.commas(value)
      }}
    />
  )
}


clicksChart.defaultProps = {
  statsChartData: []
}

export default clicksChart



const ClicksMetric = props => (
  <ChangeMetric
    title="Clicks"
    metric={ format.commas( props.statsTotals.totalClicks ) || 0 }
    icon={HandClickIcon}
    iconColor="#ff9a48"
    iconBackgroundColor="rgba(255, 154, 72, 0.2)"
    change={ props.statsChange.clicksChange || '' }
    width={260}
    height={260}
  />
)

ClicksMetric.defaultProps = {
  statsTotals: {},
  statsChange: {},
}

export { ClicksMetric }
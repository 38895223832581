import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Index from './Index';
import CampaignTemplate from './CampaignTemplate/CampaignTemplate';
import ConversionTrackerTemplate from './ConversionTrackerTemplate/ConversionTrackerTemplate';


class AdwordsTemplates extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {

    };
  }

  render(){
    return (
      <Switch>
        <Route exact path="/ads/adwords_templates" component={ Index } />
        <Route exact path="/ads/adwords_templates/conversion_trackers/:id" component={ ConversionTrackerTemplate } />
        <Route exact path="/ads/adwords_templates/campaigns/:id" component={ CampaignTemplate } />
      </Switch>
    );
  }

}

export default AdwordsTemplates;
import React from 'react';
import Axios from 'axios';

import { MaxWidth, log, formHelpers, AutoError, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, OrganizationRoleContext } from 'go-boost-library-react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Refresh from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import history from 'history.js';
import SignUpCodeModel from 'SignUp/SignUpCodeModel';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';


class NewOrganization extends React.Component {
  state = {
    name: '',
    possibleOrganizationChildTypes: [],
    organizationChildTypeId: '',
    parentOrganizationId: this.props.match.params.id,
    possibleParents: [],
    newOrganizationChildTypeName: '',
    code: ''
  }



  componentDidMount = () => {
    this.onGenerateCode();
    this.getOrganizationChildTypes();
    this.getPossibleParents();
  }

  createOrganization = () => {
    const { organizationChildTypeId } = this.state;

    return Axios.post(
      '/api/core/organizations',
      {
        name: this.state.name,
        parent_organization_id: this.state.parentOrganizationId,
        code: this.state.code,
        organization_child_type_id: organizationChildTypeId === 'new' ? undefined : organizationChildTypeId,
        new_organization_child_type_name: organizationChildTypeId === 'new' ? this.state.newOrganizationChildTypeName : undefined,
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('onCreateOrganization response', response);
        history.push(`/organizations/${response.data.organization.id}`)
      });
  }

  getOrganizationChildTypes = () => {
    log('getOrganizationChildTypes');
    return Axios.get(
      `/api/core/organizations/${this.props.match.params.id}/child_types`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('getOrganizationChildTypes response', response);
        this.setState({ possibleOrganizationChildTypes: response.data.child_types });
      });
  }

  getPossibleParents = () => {
    log('getPossibleParents');
    return Axios.get(
      `/api/core/organizations/${this.props.match.params.id}/family`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('getPossibleParents response', response);
        this.setState({ possibleParents: response.data.family });
      });
  }

  onProcess = () => {
    return this.validate()
      .then(this.createOrganization)
      .catch(AutoError.catch.bind(this));;
  }

  onValidationError = error => {
    log(error.message)
    this.props.showSnackbar(error.message);
  }

  onError = error => {
    log(error.response)
    this.props.showSnackbar(error.response.data.message);
  }

  validate = () => {
    const baseValidations = {
      name: {
        presence: { message: 'Please enter the organization name.' }
      },
      parentOrganizationId: {
        presence: { message: 'Please specify the parent of the organization.' }
      },

    };

    const { organizationChildTypeId } = this.state;

    if (organizationChildTypeId === 'new') {
      baseValidations['newOrganizationChildTypeName'] = {
        presence: { message: 'Please specify the new type for this organization.' }
      };
    } else {
      baseValidations['organizationChildTypeId'] = {
        presence: { message: 'Please enter the type of the organization.' }
      };
    }

    // Add this last so the other validations are run first.
    baseValidations['code'] = {
      presence: { message: 'A sign up code is required.' },
      length: { atLeast: 8, message: 'Sign up code must be at least 6 characters.' }
    };

    return formHelpers.validate(this.state, baseValidations);
  }


  onGenerateCode = () => {
    this.setState({ code: SignUpCodeModel.generateCode() })
  }




  render() {
    const { classes } = this.props;
    const { organizationChildTypeId } = this.state;

    return (
      <MaxWidth maxWidth={400}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <KnowledgeBaseLaunchBanner />
            <Grid item xs={12}>
              <Typography variant="h5">Create an Organization</Typography>
              <Typography variant="caption">Please enter the following information to create a new organization.</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                onChange={e => this.setState({ name: e.target.value })}
                fullWidth
                label="Organization Name"
                margin="normal"
                value={this.state.name}
              />
            </Grid>

            <Grid item xs={12}>

              <TextField
                select
                fullWidth
                label="Organization Type"
                value={organizationChildTypeId}
                onChange={e => this.setState({ organizationChildTypeId: e.target.value })}
              >
                {
                  this.state.possibleOrganizationChildTypes.map(t => (
                    <MenuItem key={t.id} value={t.id}>
                      {t.name}
                    </MenuItem>
                  ))
                }
                <MenuItem
                  key="newOrganizationType"
                  value="new"
                >
                  New Organization Type
                </MenuItem>
              </TextField>

              {
                organizationChildTypeId === 'new' ?
                  <TextField
                    fullWidth
                    label="New Organization Type"
                    value={this.state.newOrganizationChildTypeName}
                    onChange={e => this.setState({ newOrganizationChildTypeName: e.target.value })}
                    margin="normal"
                  />
                  : null
              }
            </Grid>


            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Parent Organization"
                value={this.state.parentOrganizationId}
                onChange={e => this.setState({ parentOrganizationId: e.target.value })}
              >
                {
                  this.state.possibleParents.map(parent => (
                    <MenuItem key={parent.id} value={String(parent.id)}>
                      {parent.name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Grid>


            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Sign Up Code"
                helperText="A unique code for signing up companies to the new organization."
                value={this.state.code}
                onChange={e => this.setState({ code: SignUpCodeModel.cleanCode(e.target.value) })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Generate a new code">
                        <IconButton onClick={this.onGenerateCode}>
                          <Refresh />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <PromiseButton onProcess={this.onProcess}>
                Create
              </PromiseButton>
            </Grid>
          </Grid>
        </div>
      </MaxWidth>
    );
  }

}

const styles = theme => ({
  root: {
    padding: 16
  }
});

export default withStyles(styles)(withContext(OrganizationRoleContext, SnackbarContext, NewOrganization));
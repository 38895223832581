import React from 'react';
import { withContext } from 'kn-react';
import { GoogleTagManagerContext } from './GoogleTagManagerProvider';
import { UserRoleContext } from 'go-boost-library-react';

class GoogleTagManagerUserData extends React.Component {

  componentDidMount = () => {
    this.refreshDataLayer(this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.refreshDataLayer(nextProps);
  }


  refreshDataLayer = (props) => {
    const { currentUserRole, trueUserRole } = props;

    if(!currentUserRole || !trueUserRole) {
      return;
    }

    this.props.pushToDataLayer({
      currentUserRoleName: currentUserRole.roleName,
      currentUserRoleType: currentUserRole.roleType,
      currentUserRoleTypeId: currentUserRole.roleTypeId,
      currentUserRoleTypeName: currentUserRole.roleTypeName,
      currentUserRoleUserId: currentUserRole.userId,
      currentUserRoleUserEmail: currentUserRole.userEmail,
      currentUserRoleUserFirstName: currentUserRole.userFirstName,
      currentUserRoleUserLastName: currentUserRole.userLastName,
      trueUserRoleName: trueUserRole.roleName,
      trueUserRoleType: trueUserRole.roleType,
      trueUserRoleTypeId: trueUserRole.roleTypeId,
      trueUserRoleTypeName: trueUserRole.roleTypeName,
      trueUserRoleUserId: trueUserRole.userId,
      trueUserRoleUserEmail: trueUserRole.userEmail,
      trueUserRoleUserFirstName: trueUserRole.userFirstName,
      trueUserRoleUserLastName: trueUserRole.userLastName
    });
  }


  render(){
    return <>{this.props.children}</>
  }


}

export default withContext(UserRoleContext, GoogleTagManagerContext, GoogleTagManagerUserData);


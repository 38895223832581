import React from 'react';
import { format } from 'kn-react';
import { List, Link, Divider } from '@material-ui/core';
import creditCardFeePercent from 'Ads/CompanyAds/creditCardFeePercent';
import CostRow from 'SharedSetup/costRow';



class CheckoutSummary extends React.Component {

  state = {
    showDetails: false
  };


  showDetails = e => {
    e.preventDefault();
    this.setState({ showDetails: !this.state.showDetails });
  }


  render() {
    const { primaryChargeCents, managementFeePercent } = this.props;

    const creditCardFeeCents = creditCardFeePercent * primaryChargeCents;
    const managementFeeCents = managementFeePercent * primaryChargeCents;

    const amountRemainingCents = primaryChargeCents - creditCardFeeCents - managementFeeCents;

    return (
      <List>
        <div>
          <CostRow
            leftPrimary="Boost Amount"
            rightPrimary={format.money(amountRemainingCents / 100)}
          />

          <CostRow
            leftPrimary="Program Management and Media Placement"
            leftSecondary={format.percent(managementFeePercent + creditCardFeePercent)}
            rightPrimary={format.money((managementFeeCents + creditCardFeeCents) / 100)}
            rightSecondary={
              <Link href="#" onClick={this.showDetails}>Details</Link>
            }
            rightSecondaryTypographyProps={{
              variant: 'caption'
            }}
          />

          {
            this.state.showDetails ?
              <>
                <Divider />
                <CostRow
                  leftPrimary="Program Management"
                  leftSecondary={format.percent(managementFeePercent)}
                  rightPrimary={format.money(managementFeeCents / 100)}
                />

                <CostRow
                  leftPrimary="Credit Card Processing"
                  leftSecondary={format.percent(creditCardFeePercent)}
                  rightPrimary={format.money(creditCardFeeCents / 100)}
                />
              </>
              : null
          }

          {
            this.props.showTotal ?
              <>
                <Divider />
                <CostRow
                  leftPrimary="Today's Total"
                  rightPrimary={format.money(primaryChargeCents / 100)}
                />
              </>
              : null
          }



        </div>
      </List>
    )
  }
}




CheckoutSummary.defaultProps = {
  primaryChargeCents: 0,
  managementFeePercent: 0.05,
  showTotal: true
};


export default CheckoutSummary;

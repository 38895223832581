import React from 'react';

import { withContext, log } from 'kn-react';
import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';

import { BoostedServicesContext } from 'Ads/CompanyAds/BoostedServices/BoostedServicesProvider';
import { CompanyAdsProfileContext } from '../CompanyAdsProfile/CompanyAdsProfileProvider';
import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import BoostedServices from './BoostedServices';
import BoostStrategySetup from './BoostedServicesSetup/BoostStrategySetup/BoostStrategySetup';
import BoostZoneSetup from './BoostedServicesSetup/BoostZoneSetup/BoostZoneSetup';
import Checkout from './Checkout/Checkout';
import FacebookAccountSetup from 'SharedSetup/FacebookAccountSetup';
import SurveySetup from './BoostedServicesSetup/SurveySetup/SurveySetup';
import TransitionProvider from 'TransitionProvider/TransitionProvider';

// 1) Wrap the Transition Provider with the Providers it will need to evaluate transitions
const WrappedTransitionProvider = withContext(
  PlanContext,
  CompanyRoleContext,
  CompanyAdsProfileContext,
  BoostedServicesContext,
  FacebookAccountContext,
  TransitionProvider
);

// 2) Define the steps of the process, each with the component, a path, optionally `exact` for the path, and a transitions object. At minimum, the transitions object should define a 'next' key, which is a function of props that returns a string path or object of the form `{ path: String, params: Object?, query: Object? } `
const machineStates = [
  {
    path: '/ads/boosted_services',
    component: BoostedServices,
    exact: true,
    transitions: {
      setup: props => ({
        path: (
          isFacebookBoostedService(props) ?
            '/ads/boosted_services/:id/facebook_account_setup'
          :
            '/ads/boosted_services/:id/survey'
        ),
        params: { id: props.boostedServiceId }
      }),
      editSurvey: props => ({
        path: (
          isFacebookBoostedService(props) ?
            '/ads/boosted_services/:id/facebook_account_setup'
          :
            '/ads/boosted_services/:id/survey'
        ),
        params: { id: props.boostedServiceId }
      }),
      editBoostZone: props => ({
        path: '/ads/boosted_services/:id/boost_zone',
        params: { id: props.boostedServiceId }
      }),
      editBoostStrategy: props => ({
        path: '/ads/boosted_services/:id/boost_strategy',
        params: { id: props.boostedServiceId }
      })
    }
  },

  {
    path: '/ads/boosted_services/:id/facebook_account_setup',
    component: props => (
      <FacebookAccountSetup
        { ...props }
        boostedServiceId={props.match.params.id}
        shouldEnqueueReviewsImporter={false}
        shouldConnectBusinessManager={true}
        linkAccountText={`Link your Facebook account to run ads on Facebook.`}
        canSignOut={(
          props.companyAdsProfile && props.companyAdsProfile.isShutdown ?
            true
          :
            !( props.facebookAccount && props.facebookAccount.id )
        )}
        isProductShutdown={(
          props.companyAdsProfile &&
          props.companyAdsProfile.isShutdown
        )}
        canSignIn={!(
          props.facebookAccount &&
          props.facebookAccount.id
        )}
        cancelTo='/ads/boosted_services'
      />
    ),
    guard: props => !isFacebookBoostedService(props) ? 'continue' : null,
    transitions: {
      back: '/ads/boosted_services',
      next: props => ({
        path: '/ads/boosted_services/:id/survey',
        params: { id: props.boostedServiceId }
      }),
    }
  },

  {
    component: SurveySetup,
    path: '/ads/boosted_services/:id/survey',
    guard: (props, transitionKey) => (
      hasSurvey({ ...props, boostedServiceId: props.boostedServiceId /*props.match.params.id*/ }) ? null : { continue: transitionKey === 'setup' ? 'next' : transitionKey }
    ),
    transitions: {
      back: props => ({
        path: '/ads/boosted_services/:id/facebook_account_setup',
        params: { id:  props.boostedServiceId }
      }),
      next: props => (
          !hasBoostBalance(props) ?
          {
            path: '/ads/boosted_services/:id/boost_zone',
            params: { id:  props.boostedServiceId }
          }
          :
          '/ads/boosted_services'
      )
      }
  },

  {
    component: BoostZoneSetup,
    path: '/ads/boosted_services/:id/boost_zone',
    transitions: {
      back: props => (
        !hasBoostBalance(props) ?
        { path: '/ads/boosted_services/:id/survey', params: { id: props.boostedServiceId } }
        :
        '/ads/boosted_services'
      ),
      next: props => (
        !hasBoostBalance(props) ?
          {
            path: '/ads/boosted_services/:id/boost_strategy',
            params: { id: props.boostedServiceId }
          }
          :
          '/ads/boosted_services'
        )
      }
    },
  {
    component: props => (
      <BoostStrategySetup
        {...props}
        cancelTo={'/ads/boosted_services'}
      />
    ),
    path: '/ads/boosted_services/:id/boost_strategy',
    transitions: {
      back: props => (
        !hasBoostBalance(props) ?
          {
            path: '/ads/boosted_services/:id/boost_zone',
            params: { id: props.boostedServiceId }
          }
        :
          '/ads/boosted_services'
      ),
      next: props => (
        hasBoostBalance(props) ?
          '/ads/boosted_services'
            :
          {
            path: '/ads/boosted_services/:id/checkout',
            params: { id: props.boostedServiceId }
          }
        )
    }
  },
  {
    component: Checkout,
    path: '/ads/boosted_services/:id/checkout',
    transitions: {
      back: props => (
        {
          path: '/ads/boosted_services/:id/boost_strategy',
          params: { id: props.boostedServiceId }
        }
      ),
      next: '/ads/boosted_services'
    }
  },

];



const hasBoostBalance = props => {
  log('hasBoostBalance', getCompanyBoostedService(props));
  return getCompanyBoostedService(props).hasBoostBalance();
}

const isFacebookBoostedService = props => {
  return getBoostedService(props).isFacebookChannel(props.channels);
}


const hasSurvey = props => {
  return getBoostedService(props).surveyId;
}

const getCompanyBoostedService = props => {
  return props.companyBoostedServices.find(c => c.boostedServiceId == props.boostedServiceId);
}


const getBoostedService = props => {
  return props.boostedServices.find(b => b.id == props.boostedServiceId);
}


export default props => (
  <WrappedTransitionProvider machineStates={machineStates} />
);

import React from 'react';
import Axios from 'axios';

import { TableToolbar, Ability, SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Add } from '@material-ui/icons';
import { Divider, ListItemText, Paper, Grid, withStyles, TextField, IconButton, Tooltip, Link } from '@material-ui/core';

import EditExternalDataDialog from './EditExternalDataDialog';
import EditExternalIdDialog from './EditExternalIdDialog';
import NewExternalDataDialog from './NewExternalDataDialog';
import OrganizationExternalDataModel from './OrganizationExternalDataModel';
import OrganizationModel from 'Settings/Organizations/OrganizationModel';


const INITIAL_DIALOG_STATE = {
  isNewDataDialogOpen: false,
  isEditIdDataDialogOpen: false,
  isEditDataDialogOpen: false,
  editIdDataIndex: null,
  editDataDataIndex: null,
}

class ExternalData extends React.Component {
  state = {
    ...INITIAL_DIALOG_STATE,
    organizationExternalData: [],
    organizations: []
  }

  componentDidMount = () => {
    return this.fetchExternalData();
  }

  fetchExternalData = () => {
    return Axios.get(
      `/api/core/organization_external_data/companies/${ this.props.companyId }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      if( !response.data.organization_external_data ){
        return this.setState({
          organizationExternalData: [],
          organizations: []
        });
      }


      const organizationExternalData = response.data.organization_external_data.map(
        o => OrganizationExternalDataModel.fromJSON( o )
      );

      const organizations = response.data.organizations.map(
        o => OrganizationModel.fromJSON( o )
      );


      return this.setState({
        organizationExternalData,
        organizations
      });
    })
  }


  onUpdateOrganizationExternalData = (dataIndex, updatedAttributes) => {
    const organizationExternalData = this.state.organizationExternalData[ dataIndex ];

    return Axios.put(
      `/api/core/organization_external_data/${ organizationExternalData.id }`,
      updatedAttributes,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      this.onCloseDialogs
    ).then(
      this.props.showSnackbar('External Data attributes successfully updated.')
    ).then( this.fetchExternalData )
    .catch(() => this.props.showSnackbar('There was an error processing this request. Please check the external data.'));
  }



  isValidOrganizationExternalData = organizationExternalData => {
    const { organizationId, externalId, externalData } = organizationExternalData;

    const isValidExternalData = data => {
      try {
        return JSON.parse( data ) || true;
      } catch (error) {
        return false;
      }
    }

    if( !organizationId ){
      window.alert('Please select an organization.');

      return false;
    }

    if( !externalId || !externalId.trim() ){
      window.alert('Please enter an External ID.');

      return false;
    }

    if( externalData && !isValidExternalData( externalData ) ){
      window.alert('Please enter valid JSON.');

      return false;
    }


    return true;
  }


  onCreateNewExternalData = organizationExternalData => {
    if( this.isValidOrganizationExternalData( organizationExternalData ) ){
      const { organizationId, externalId, externalData } = organizationExternalData;

      return Axios.post(
        '/api/core/organization_external_data',
        {
          owner_organization_id: organizationId,
          role_type: 'Company',
          role_type_id: this.props.companyId,
          external_id: externalId,
          external_data: externalData ? JSON.parse( externalData ) : undefined
        },
        { headers: this.props.getUserRoleAuthHeaders() }
      ).then(
        () => this.setState({ isNewDataDialogOpen: false })
      ).then(
        () => this.props.showSnackbar('External Data successfully created.')
      ).then(this.fetchExternalData)
      .catch(() => this.props.showSnackbar('There was an error processing this request. Please check the external data.'));;
    } else {
      return Promise.reject();
    }
  }


  onCloseDialogs = () => (
    this.setState({
      ...INITIAL_DIALOG_STATE
    })
  )


  render() {
    const canCreateExternalData = this.props.currentUserRole.roleHasAbility(
      Ability.CREATE_ORGANIZATION_EXTERNAL_DATA
    );


    if(
      !this.state.organizationExternalData.length &&
      !canCreateExternalData
    ){
      return <></>
    }

    const { classes } = this.props;

    return (
      <Paper style={{ padding: 15 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <TableToolbar
              title="External Data"
              actions={
                !canCreateExternalData ?
                  null
                :
                  <>
                    <Tooltip
                      title={'Create New External Data'}
                    >
                      <IconButton
                        onClick={() => this.setState({ isNewDataDialogOpen: true })}
                      >
                        <Add/>
                      </IconButton>
                    </Tooltip>
                  </>
              }
            />

            <Grid container>
              {
                this.state.organizationExternalData.map((o, i) => {
                  const organization = this.state.organizations.find(
                    org => org.id === o.ownerOrganizationId
                  );

                  return (
                    <Grid container key={i}>
                      <Grid item xs={12}>
                        <ListItemText
                          primary={
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '50%' }}>
                                External ID: { o.externalId || '' }
                              </div>

                              {
                                !canCreateExternalData ?
                                  null
                                :
                                  <div style={{ width: '50%', textAlign: 'right' }}>
                                    <Link
                                      onClick={() => this.setState({ isEditIdDataDialogOpen: true, editIdDataIndex: i })}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      Edit External ID
                                    </Link>
                                  </div>
                              }
                            </div>
                          }
                          secondary={organization.name}
                          style={{ padding: 20 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {
                          o.externalData ?
                            <div style={{ padding: 15 }}>
                              <pre>
                                <TextField
                                  multiline
                                  fullWidth
                                  disabled
                                  value={JSON.stringify(
                                    o.externalData,
                                    null,
                                    2
                                  )}
                                  variant='outlined'
                                  InputProps={{
                                    style: { fontSize: 15 }
                                  }}
                                  className={classes.code}
                                />
                              </pre>

                              {
                                !canCreateExternalData ?
                                  null
                                :
                                  <Link
                                    onClick={() => this.setState({
                                      editDataDataIndex: i,
                                      isEditDataDialogOpen: true
                                    })}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Edit External Data
                                  </Link>
                              }
                            </div>
                          :
                            !canCreateExternalData ?
                              null
                            :
                              <div
                                style={{
                                  marginLeft: 15
                                }}
                              >
                                <Link
                                  onClick={() => this.setState({
                                    editDataDataIndex: i,
                                    isEditDataDialogOpen: true
                                  })}
                                  style={{ cursor: 'pointer' }}
                                >
                                  Create External Data
                                </Link>
                              </div>
                        }
                      </Grid>

                      {
                        i < this.state.organizationExternalData.length - 1 ?
                          <Grid
                            item
                            xs={12}
                            className={classes.divider}
                          >
                            <Divider />
                          </Grid>
                        :
                          null
                      }
                    </Grid>
                  );
                })
              }
            </Grid>
          </Grid>
        </Grid>

        {
          !canCreateExternalData ?
            null
          :
            <>
              <NewExternalDataDialog
                open={this.state.isNewDataDialogOpen}
                onClose={this.onCloseDialogs}
                isValidOrganizationExternalData={this.isValidOrganizationExternalData}
                onSubmit={this.onCreateNewExternalData}
                organizationId={this.props.currentUserRole.roleTypeId}
              />

              <EditExternalIdDialog
                open={this.state.isEditIdDataDialogOpen}
                externalId={(
                  this.state.isEditIdDataDialogOpen ?
                    this.state.organizationExternalData[ this.state.editIdDataIndex ].externalId || ''
                  :
                    ''
                )}
                onClose={this.onCloseDialogs}
                onSubmit={
                  externalId => this.onUpdateOrganizationExternalData(
                    this.state.editIdDataIndex,
                    { external_id: externalId }
                  )
                }
              />

              <EditExternalDataDialog
                open={this.state.isEditDataDialogOpen}
                externalData={(
                  this.state.isEditDataDialogOpen ?
                    this.state.organizationExternalData[ this.state.editDataDataIndex ].externalData || ''
                  :
                    ''
                )}
                onClose={this.onCloseDialogs}
                onSubmit={
                  externalData => this.onUpdateOrganizationExternalData(
                    this.state.editDataDataIndex,
                    { external_data: externalData }
                  )
                }
              />
            </>
        }
      </Paper>
    )
  }
}


const styles = theme => ({
  code: {
    backgroundColor: '#eee'
  },
  divider: {
    marginTop: 15
  }
})




ExternalData.defaultProps = {
  companyId: null,
  onChangeExternalData: e => {},
  canEditExternalData: false,
  onUpdateExternalData: () => Promise.resolve()
}



export default withContext(
  UserRoleContext,
  SnackbarContext,
  withStyles(styles)(ExternalData)
)
import { JsonModel } from 'kn-react';

export default class FacebookReportModel {

	static fromJSON(json) {
		const model = new FacebookReportModel();
		return JsonModel.modelFromJSON(model, json);
	}

	toJSON() {
		return JsonModel.modelToJSON(this);
	}

}

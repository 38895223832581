import React from 'react';

import { AppNavigation, MegaphoneIcon, ReviewsStarIcon, ThemeContext, PlanContext, Ability, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, isStandalone } from 'kn-react';

// appDrawerLinks
import withStyles from '@material-ui/core/styles/withStyles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessIcon from '@material-ui/icons/Business';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GetAppIcon from '@material-ui/icons/GetApp';
import HistoryIcon from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import SocialIcon from '@material-ui/icons/People';
import Videocam from '@material-ui/icons/Videocam';

import appBarMenuItems, { SUPPORT_PORTAL_KEY } from './appBarMenuItems';
import userRoleNavItem from './userRoleNavItem';

class CompanyNavigation extends React.PureComponent {


  render() {
    const { currentUserRole } = this.props;

    let appDrawerLinks = [
      userRoleNavItem(this.props),
      { divider: true },
    ];


    appDrawerLinks.push({ primaryText: 'Home', path: '/', icon: <HomeIcon />, exactPathMatch: true });

    if (this.props.isAdsAvailable
      &&
      currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_COMPANY_ADS_PROFILE, Ability.EDIT_COMPANY_ADS_PROFILE)
    ) {
      appDrawerLinks.push({ primaryText: 'Ads', path: '/ads', icon: <MegaphoneIcon /> });
    }

    if (this.props.isReviewsAvailable
      &&
      currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_COMPANY_REVIEWS, Ability.EDIT_COMPANY_REVIEWS)
    ) {
      appDrawerLinks.push({ primaryText: 'Reviews', path: '/reviews', icon: <ReviewsStarIcon /> });
    }

    if (this.props.isSitesAvailable
      &&
      currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_COMPANY_SITES_PROFILE, Ability.EDIT_COMPANY_SITES_PROFILE)
    ) {
      appDrawerLinks.push({ primaryText: 'Sites', path: '/sites', icon: <PublicIcon /> });
    }

    if (this.props.isSocialAvailable
      &&
      currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_SOCIAL_COMPANY_SOCIAL_PROFILE, Ability.EDIT_SOCIAL_COMPANY_SOCIAL_PROFILE)
    ) {
      appDrawerLinks.push({ primaryText: 'Social', path: '/social', icon: <SocialIcon/> });
    }

    if (this.props.isVirtualAvailable
      &&
      currentUserRole.roleHasAtLeastOneAbility(Ability.EDIT_VIRTUAL_COMPANY_VIRTUAL_PROFILE, Ability.VIEW_VIRTUAL_COMPANY_VIRTUAL_PROFILE)
    ) {
      appDrawerLinks.push({ primaryText: 'Virtual', path: '/virtual', icon: <Videocam/> });
    }



    appDrawerLinks = [
      ...appDrawerLinks,
      { divider: true },
      { primaryText: 'Company Profile', path: '/company_profile', icon: <BusinessIcon /> },
    ];

    if (currentUserRole.roleHasAtLeastOneAbility(
      Ability.VIEW_COMPANY_BILLING,
      Ability.EDIT_COMPANY_BILLING,
    )) {
      appDrawerLinks.push({ primaryText: 'Billing', path: '/billing/payment_history', icon: <AttachMoneyIcon />, isActive: () => window.location.pathname.startsWith('/billing') });
    }


    if (currentUserRole.roleHasAtLeastOneAbility(
      Ability.VIEW_COMPANY_USERS,
      Ability.EDIT_COMPANY_USERS,
    )) {
      appDrawerLinks.push({ primaryText: 'Users', path: '/users', icon: <PeopleIcon /> });
    }


    if (currentUserRole.roleHasAbility(
      Ability.VIEW_COMPANY_ACTIVITY
    )) {
      appDrawerLinks.push({ primaryText: 'Activity', path: '/activity', icon: <HistoryIcon/> });
    }

    appDrawerLinks.push({ primaryText: 'Profile', path: '/profile', icon: <PersonIcon /> });

    if (!isStandalone() && ( !this.props.theme || this.props.theme.shouldShowAddToHomeScreen !== false )) {
      appDrawerLinks.push({ primaryText: 'Add to Home Screen', path: '/add_to_home_screen', icon: <GetAppIcon /> });
    }

    appDrawerLinks.push({ primaryText: 'Sign out', path: '/sign_out', icon: <ExitToAppIcon /> });


    const supportPortalLinkIndex = appBarMenuItems.findIndex(
      a => a.key === SUPPORT_PORTAL_KEY
    );

    if(
      supportPortalLinkIndex >= 0 &&
      this.props.theme &&
      this.props.theme.knowledgeBaseUrl
    ){
      appBarMenuItems[ supportPortalLinkIndex ] = {
        ...appBarMenuItems[ supportPortalLinkIndex ],
        onClick: () => window.open(`${ this.props.theme.knowledgeBaseUrl }`, '_blank'),
      };
    }


    return (
      <AppNavigation title={this.props.title} appDrawerLinks={appDrawerLinks} appBarMenuItems={appBarMenuItems}>
        {this.props.children}
      </AppNavigation>
    );
  }

}



const styles = theme => ({});



export default withContext(
  ThemeContext,
  PlanContext,
  CompanyRoleContext,
  withStyles(styles, { withTheme: true })(
    CompanyNavigation
  )
);
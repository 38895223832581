import React from 'react';
import Axios from 'axios';

import { SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, AutoError } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import CompanyReviewsProfileModel from './CompanyReviewsProfileModel';
import history from 'history.js';



const DEFAULT_STATE = {
  profileLoading: true,
  companyReviewsProfile: null,
};


export const CompanyReviewsProfileContext = React.createContext(DEFAULT_STATE);


class CompanyReviewsProfileProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }



  fetchData = () => {
    return this.fetchCompanyReviewsProfile()
      .finally(() => this.setState({ profileLoading: false }));
  }



  fetchCompanyReviewsProfile = () => {
    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/companies/${currentUserRole.roleTypeId}/company_reviews_profile`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchCompanyReviewsProfile', response);
      const companyReviewsProfile = CompanyReviewsProfileModel.fromJSON(response.data.company_reviews_profile);
      this.setState({ companyReviewsProfile });
    }).catch(error => {
      if(error.response.status !== 404) {
        throw error;
      }
      log('Company Reviews Profile Not Found')
    });
  }



  createCompanyReviewsProfile = () => {
    log('createCompanyReviewsProfile')
    return Axios.post(
      `/api/reviews/company_reviews_profiles`,
      { },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      response => log('createCompanyReviewsProfile', response)
    ).then(
      this.fetchCompanyReviewsProfile
    );
  }



  updateCompanyReviewsProfile = updatedAttributes => {
    log('updateCompanyReviewsProfile', updatedAttributes);
    const { id } = this.state.companyReviewsProfile;

    return Axios.put(
      `/api/reviews/company_reviews_profiles/${id}`,
      updatedAttributes,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('updateCompanyReviewsProfile response', response);
      const companyReviewsProfile = CompanyReviewsProfileModel.fromJSON(response.data.company_reviews_profile);
      this.setState({ companyReviewsProfile });
    });
  }



  enqueueCompanyReviewsProfileSetup = () => {
    log('enqueueSetup');
    const { id } = this.state.companyReviewsProfile;
    return Axios.get(
      `/api/reviews/company_reviews_profile_setup/${id}/enqueue`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      this.fetchCompanyReviewsProfile
    );
  }



  enqueueFacebookImporter = () => {
    log('enqueueFacebookImporter');
    const { id } = this.state.companyReviewsProfile;
    return Axios.get(
      `/api/reviews/company_reviews_profiles/${id}/enqueue_facebook_importer`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('enqueueFacebookImporter response', response);
    });
  }



  enqueueGoogleImporter = () => {
    log('enqueueGoogleImporter');
    const { id } = this.state.companyReviewsProfile;
    return Axios.get(
      `/api/reviews/company_reviews_profiles/${id}/enqueue_google_importer`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('enqueueFacebookImporter response', response);
    });
  }



  shutdownCompanyReviewsProfile = () => {
    log('shutdownCompanyReviewsProfile');
    const { id } = this.state.companyReviewsProfile;
    return Axios.post(
      `/api/reviews/company_reviews_profiles/${id}/shutdown`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('shutdownCompanyReviewsProfile response', response);
    }).then(
      this.props.fetchCompanyRoleProviderData
    ).then(
      this.fetchCompanyReviewsProfile
    );
  }



  resubscribeToReviews = () => {
    log('resubscribeToReviews');
    const { id } = this.state.companyReviewsProfile;
    return Axios.post(
      `/api/reviews/company_reviews_profiles/${id}/resubscribe`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('resubscribeToReviews response', response);
    }).then(
      this.fetchCompanyReviewsProfile
    ).then(
      () => history.push('/reviews')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  undoResubscriptionToReviews = () => {
    log('undoResubscriptionToReviews');
    const { id } = this.state.companyReviewsProfile;
    return Axios.delete(
      `/api/reviews/company_reviews_profiles/${id}/resubscribe`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('undoResubscriptionToReviews response', response);
    }).then(
      this.fetchCompanyReviewsProfile
    ).then(
      () => history.push('/reviews')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  onError = error => {
    log('error', error);
    this.props.showSnackbar(
      error.response ?
        error.response.data.message || 'Error'
      :
        'Error'
    );
  }



  render() {
    const { profileLoading } = this.state;

    if (profileLoading) {
      return <LinearProgress />;
    }


    return (
      <CompanyReviewsProfileContext.Provider
        value={{
          companyReviewsProfile: this.state.companyReviewsProfile,
          fetchCompanyReviewsProfile: this.fetchCompanyReviewsProfile,
          createCompanyReviewsProfile: this.createCompanyReviewsProfile,
          updateCompanyReviewsProfile: this.updateCompanyReviewsProfile,
          enqueueCompanyReviewsProfileSetup: this.enqueueCompanyReviewsProfileSetup,
          enqueueFacebookImporter: this.enqueueFacebookImporter,
          enqueueGoogleImporter: this.enqueueGoogleImporter,
          shutdownCompanyReviewsProfile: this.shutdownCompanyReviewsProfile,
          resubscribeToReviews: this.resubscribeToReviews,
          undoResubscriptionToReviews: this.undoResubscriptionToReviews
        }}
      >
        {this.props.children}
      </CompanyReviewsProfileContext.Provider>
    );
  }
}


export default withContext(
  CompanyRoleContext,
  SnackbarContext,
  CompanyReviewsProfileProvider
);
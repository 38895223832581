import React from 'react';
import { format } from 'kn-react';

import { OverTimeChart, ChangeMetric } from 'go-boost-library-react';
import { ThumbUp } from '@material-ui/icons';

const likesChart = props => {
  const likesLineStyles = {
    'Likes': {
      type:"monotone",
      dataKey:"Likes",
      stroke:"#9e70ff",
      activeDot: { r: 8 }
    }
  };


  return (
    <OverTimeChart
      data={props.statsChartData}
      lineStyles={likesLineStyles}
      tooltipProps={{
        formatter: value => format.commas(value)
      }}
    />
  )
}


likesChart.defaultProps = {
  statsChartData: []
}

export default likesChart



const LikesMetric = props => (
  <ChangeMetric
    title="Likes"
    metric={ format.commas( props.statsTotals.totalLikes ) || 0 }
    icon={ThumbUp}
    iconColor="#9e70ff"
    iconBackgroundColor="rgba(158, 112, 255, 0.2)"
    change={ props.statsChange.likesChange || '' }
    width={260}
    height={260}
  />
)

LikesMetric.defaultProps = {
  statsTotals: {},
  statsChange: {},
}

export { LikesMetric }
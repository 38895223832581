import React from 'react';
import { log } from 'kn-react';
import { Tooltip, IconButton } from '@material-ui/core';
import HeadsetMic from '@material-ui/icons/HeadsetMic';


const onClickChat = () => {
  const { fcWidget } = window;
  if (!fcWidget) {
    log('Cannot find fcWidget!');
    return;
  }

  fcWidget.open();
  fcWidget.show();
}

export default props => (
  <Tooltip title="Live Chat" enterDelay={500}>
    <IconButton onClick={onClickChat}>
      <HeadsetMic />
    </IconButton>
  </Tooltip>
);
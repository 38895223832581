import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { CompanySitesProfileContext } from '../CompanySitesProfile/CompanySitesProfileProvider';
import { SiteDesignsContext } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import SiteDesigns from './SiteDesigns';
import SurveySetup from './SiteDesignsSetup/SurveySetup/SurveySetup';
import TransitionProvider from 'TransitionProvider/TransitionProvider';

// 1) Wrap the Transition Provider with the Providers it will need to evaluate transitions
const WrappedTransitionProvider = withContext(
  PlanContext,
  CompanyRoleContext,
  CompanySitesProfileContext,
  SiteDesignsContext,
  TransitionProvider
);

// 2) Define the steps of the process, each with the component, a path, optionally `exact` for the path, and a transitions odject. At minimum, the transitions object should define a 'next' key, which is a function of props that returns a string path or object of the form `{ path: String, params: Object?, query: Object? } `
const machineStates = [
  {
    path: '/sites/site_designs',
    component: SiteDesigns,
    exact: true,
    transitions: {
      editSurvey: props => ({
        path: '/sites/site_designs/:id/survey',
        params: { id: props.siteDesignId }
      }),
    }
  },
  {
    path: '/sites/site_designs/:id/survey',
    component: SurveySetup,
    guard: (props, transitionKey) => (
      hasSurvey(props) ? null : { continue: transitionKey === 'editSurvey' ? 'next' : transitionKey }
    ),
    transitions: {
      back: '/sites/site_designs',
      next: '/sites/site_designs'
    }
  },

];



const hasSurvey = props => {
  return getSiteDesign(props).surveyId;
}

const getSiteDesign = props => {
  return props.siteDesigns.find(d => d.id == props.siteDesignId);
}


export default props => (
  <WrappedTransitionProvider machineStates={machineStates} />
);

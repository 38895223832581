import React from 'react';
import Axios from 'axios';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { withStyles } from '@material-ui/core';

import { ChannelContext } from 'Channels/ChannelProvider';
import { OrganizationBoostedServicesContext } from '../OrganizationBoostedServicesProvider';
import OrganizationBoostedServicesList from './OrganizationBoostedServicesList/OrganizationBoostedServicesList';
import OrganizationBoostedServiceStatsModel from './OrganizationBoostedServicesList/OrganizationBoostedServiceStatsModel';


class BoostedServicesList extends React.Component {

  state = {
    loading: true,
    organizationBoostedServiceStats: []
  }

  componentDidMount = () => {
    this.fetchOrganizationBoostedServiceStats();
  }

  fetchOrganizationBoostedServiceStats = () => {

    return Axios.get(
      `/api/ads/boosted_services/organizations/${this.props.organization.id}/stats`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchOrganizationBoostedServiceStats response', response);
      const organizationBoostedServiceStats = response.data.stats.map(s => (
        OrganizationBoostedServiceStatsModel.fromJSON(s)
      ));

      this.setState({ organizationBoostedServiceStats, loading: false });
    });
  }


  render(){
    const {
      channels,
      boostedServices,
      showToggleCompanyBoostedService,
    } = this.props;

    const { organizationBoostedServiceStats, loading } = this.state;


    return (
      <>
        {
          !loading && boostedServices.length ?
            <OrganizationBoostedServicesList
              channels={channels}
              boostedServices={boostedServices}
              organizationBoostedServiceStats={organizationBoostedServiceStats}
              onToggle={showToggleCompanyBoostedService ? this.onToggleCompanyBoostedService : undefined}
            />
          : null
        }
      </>
    )
  }

}

BoostedServicesList.defaultProps = {
  showToggleCompanyBoostedService: true,
  organizationBoostedServices: []
};


const styles = theme => ({

});

export default withStyles(styles)(
  withContext(OrganizationRoleContext, ChannelContext, OrganizationBoostedServicesContext, BoostedServicesList)
);
import React from 'react';
import SocialDashboardSummaryContent from './SocialDashboardSummaryContent';



class SocialDashboardSummary extends React.Component {

  render(){
    return (
      <div style={{ opacity: this.props.summaryRefreshing ? 0.5 : 1 }}>
        <SocialDashboardSummaryContent
          statsChartData={this.props.statsChartData}
          statsTotals={this.props.statsTotals}
          statsChange={this.props.statsChange}
        />
      </div>
    );
  }

}

SocialDashboardSummary.defaultProps = {
  summaryRefreshing: false,
  statsChartData: [],
  statsTotals: {},
  statsChange: {},
};


export default SocialDashboardSummary
import React from 'react';
import * as d3 from 'd3';

import { format } from 'kn-react';
import { withStyles } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { TextStatusIndicator } from 'go-boost-library-react';

import AdCarousel from 'Ads/CompanyAds/BoostedServices/BoostedServicesList/AdCarousel/AdCarousel';
import BoostedServiceIcon from '../BoostedServiceIcon/BoostedServiceIcon';

class OrganizationBoostedServicesList extends React.Component {
  render() {
    const { channels, boostedServices, classes, organizationBoostedServiceStats } = this.props;

    return (
      <Grid container>
        {
          boostedServices.map(b => {
            const ch = channels.find(c => c.id === b.channelId);
            const stats = organizationBoostedServiceStats.find(
              s => s.boostedServiceId === b.id
            );

          let textStatusIndicatorProps;

          if (stats.sumIsActivated > 0) {
            textStatusIndicatorProps = {
              success: true,
              text: 'Live'
            }
          } else {
            textStatusIndicatorProps = {
              text: 'Inactive',
            };
          }

            const assetData = b.assetData || {};
            let adCarousel;
            if (assetData.adCarousel) {
              adCarousel = assetData.adCarousel.map(d => ({
                image: d.image,
                url: d.preview_url
              }));
            }

            return (
              <Grid item xs={12} key={b.id}>
                <Card classes={{ root: classes.card }}>
                  <CardHeader
                    className={classes.cardHeader}
                    disableTypography
                    title={
                      <Typography variant="subtitle1">{b.name}</Typography>
                    }
                    subheader={
                      <>
                        <Typography variant="caption" color="textSecondary">
                          {ch.name}
                        </Typography>
                        <TextStatusIndicator {...textStatusIndicatorProps} />
                      </>
                    }
                    avatar={
                      <Hidden xsDown>
                        <BoostedServiceIcon
                          channels={channels}
                          boostedService={b}
                        />
                      </Hidden>
                    }
                    action={
                      b.isComingSoon ?
                        <ListItemText style={{ textAlign: 'right', fontSize: 10, color: '#777' }} secondary="Coming soon!" />
                        :
                        null
                    }
                  />
                  {
                    adCarousel ?
                      <AdCarousel data={adCarousel} />
                      : null
                  }

                  <List dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <BusinessCenterIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Companies"
                        primaryTypographyProps={{
                          color: 'primary'
                        }}
                        secondary={`${ format.commas(stats.sumIsActivated) } Active, ${ format.commas(stats.countCompanyId - stats.sumIsActivated)} Inactive`}
                        secondaryTypographyProps={{
                          color: 'textPrimary'
                        }}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <AttachMoneyIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Boost Balances"
                        primaryTypographyProps={{
                          color: 'primary'
                        }}
                        secondary={`${ format.money(stats.sumBoostBalanceCents / 100) } Available, ${ format.money(stats.amountSpentCents / 100) } Spent`}
                        secondaryTypographyProps={{
                          color: 'textPrimary'
                        }}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <VisibilityIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Views / Clicks"
                        primaryTypographyProps={{
                          color: 'primary'
                        }}
                        secondary={`${format.commas(stats.sumViews)} View${ stats.sumViews === 1 ? '' : 's' }, ${format.commas(stats.sumClicks)} Click${ stats.sumClicks === 1 ? '' : 's' }`}
                        secondaryTypographyProps={{
                          color: 'textPrimary'
                        }}
                      />
                    </ListItem>

                    {/* <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <CallIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Opportunities"
                        primaryTypographyProps={{
                          color: 'primary'
                        }}
                        secondary={`${ stats.countCallOpportunities } Call${ stats.countCallOpportunities === 1 ? '' : 's' }, ${ stats.countFormEntryOpportunities } Form Entr${ stats.countFormEntryOpportunities === 1 ? 'y' : 'ies' }`}
                        secondaryTypographyProps={{
                          color: 'textPrimary'
                        }}
                      />
                    </ListItem> */}

                  </List>

                </Card>
              </Grid>
            );
          })
        }
      </Grid>
    )
  }
}

const styles = theme => ({
  card: {
    marginBottom: 25,
  },
  cardHeader: {
    borderBottom: '1px solid #efefef',
  },
})

export default withStyles(styles)(OrganizationBoostedServicesList);
import hashIdCoder from 'hashIdCoder';
import { isDevelopment, isStaging, isDemo } from 'go-boost-library-react';


export const getSiteLink = siteId => {
  const siteHashId = hashIdCoder.encode(siteId);

  if( isDevelopment() ) {
    return `http://site-${siteHashId}-preview.localhost:2048`;
  }
  else if( isStaging() ) {
    return `https://site-${siteHashId}-preview.staging.goboost.xyz`;
  }
  else if( isDemo() ) {
    return `https://site-${siteHashId}-preview.demo.goboost.xyz`;
  }
  else {
    return `https://site-${siteHashId}-preview.goboost.xyz`;
  }
}
import React from 'react';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import ReviewsDashboard from '../../ReviewsDashboard/ReviewsDashboard';

const reviewsDashboard = props => (
  <ReviewsDashboard
    roleTypePath="organizations"
    roleTypeId={props.organization.id}
    currentUserRole={props.currentUserRole}
    getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
  />
);

export default withContext(
  OrganizationRoleContext,
  reviewsDashboard,
);
import React from 'react';
import Axios from 'axios';

import { SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, AutoError } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import CompanyVirtualProfileModel from './CompanyVirtualProfileModel';
import history from 'history.js';



const DEFAULT_STATE = {
  virtualProfileLoading: true,
  companyVirtualProfile: null
};


export const CompanyVirtualProfileContext = React.createContext(DEFAULT_STATE);


class CompanyVirtualProfileProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }



  updateCompanySiteProvider = () => {
    return this.fetchData();
  }



  fetchData = () => {
    return this.fetchCompanyVirtualProfile()
      .finally(() => this.setState({ virtualProfileLoading: false }));
  }



  fetchCompanyVirtualProfile = () => {
    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/companies/${ currentUserRole.roleTypeId }/company_virtual_profile`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchCompanyVirtualProfile', response);
      const companyVirtualProfile = CompanyVirtualProfileModel.fromJSON(response.data.company_virtual_profile);
      this.setState({ companyVirtualProfile });
    }).catch(error => {
      if(error.response.status !== 404) {
        throw error;
      }
      log('Company Virtual Profile Not Found')
    });
  }



  createCompanyVirtualProfile = () => {
    log('createCompanyVirtualProfile')
    return Axios.post(
      `/api/virtual/company_virtual_profiles`,
      { company_id: this.props.company.id },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      response => log('createCompanyVirtualProfile', response)
    ).then(
      this.fetchCompanyVirtualProfile
    );
  }



  updateCompanyVirtualProfile = updatedAttributes => {
    log('updateCompanyVirtualProfile', updatedAttributes);
    const { id } = this.state.companyVirtualProfile;
    const companyVirtualProfile = CompanyVirtualProfileModel.fromJSON(updatedAttributes).toJSON();
    return Axios.put(
      `/api/virtual/company_virtual_profiles/${id}`,
      companyVirtualProfile,
      { headers: this.props.getUserRoleAuthHeaders()}
    ).then(
      this.fetchCompanyVirtualProfile
    );
  }



  enqueueCompanyVirtualProfileSetup = selectedVirtualFormDesignIds => {
    log('enqueueCompanyVirtualProfileSetup');

    const { companyId } = this.state.companyVirtualProfile;

    return Axios.post(
      `/api/virtual/company_virtual_profiles/companies/${ companyId }/enqueue`,
      {
        virtual_form_design_ids: selectedVirtualFormDesignIds
      },
      { headers: this.props.getUserRoleAuthHeaders()}
    ).then(response => {
      log('enqueueCompanyVirtualProfileSetup response', response);
    }).then(
      this.fetchCompanyVirtualProfile
    );
  }



  shutdownCompanyVirtualProfile = () => {
    log('shutdownCompanyVirtualProfile');
    const { id } = this.state.companyVirtualProfile;
    return Axios.post(
      `/api/virtual/company_virtual_profiles/${id}/shutdown`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('shutdownCompanyVirtualProfile response', response);
    }).then(
      this.props.fetchCompanyRoleProviderData
    ).then(
      this.fetchCompanyVirtualProfile
    );
  }



  resubscribeToVirtual = () => {
    log('resubscribeToVirtual');
    const { id } = this.state.companyVirtualProfile;
    return Axios.post(
      `/api/virtual/company_virtual_profiles/${id}/resubscribe`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('resubscribeToVirtual response', response);
    }).then(
      this.fetchCompanyVirtualProfile
    ).then(
      () => history.push('/virtual')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  undoResubscriptionToVirtual = () => {
    log('undoResubscriptionToVirtual');
    const { id } = this.state.companyVirtualProfile;
    return Axios.delete(
      `/api/virtual/company_virtual_profiles/${id}/resubscribe`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('undoResubscriptionToVirtual response', response);
    }).then(
      this.fetchCompanyVirtualProfile
    ).then(
      () => history.push('/virtual')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  onError = error => {
    log('error', error);
    this.props.showSnackbar(
      error.response ?
        error.response.data.message || 'Error'
      :
        'Error'
    );
  }



  render(){
    const { virtualProfileLoading } = this.state;

    if(virtualProfileLoading) {
      return <LinearProgress/>;
    }

    return (
      <CompanyVirtualProfileContext.Provider
        value={{
          companyVirtualProfile: this.state.companyVirtualProfile,
          fetchCompanyVirtualProfile: this.fetchCompanyVirtualProfile,
          createCompanyVirtualProfile: this.createCompanyVirtualProfile,
          updateCompanyVirtualProfile: this.updateCompanyVirtualProfile,
          enqueueCompanyVirtualProfileSetup: this.enqueueCompanyVirtualProfileSetup,
          shutdownCompanyVirtualProfile: this.shutdownCompanyVirtualProfile,
          resubscribeToVirtual: this.resubscribeToVirtual,
          undoResubscriptionToVirtual: this.undoResubscriptionToVirtual
        }}
      >
        { this.props.children }
      </CompanyVirtualProfileContext.Provider>
    );
  }
}


export default withContext(
  CompanyRoleContext,
  SnackbarContext,
  CompanyVirtualProfileProvider
);
import React from 'react';
import { Typography, withStyles, Paper, TextField } from '@material-ui/core';
import { reviewsBucketUrl } from './EmbedScriptTag';


const reviewsStars = props => {
  const { classes } = props;

  return (
    <Paper className={classes.paperRoot}>
      <Typography variant="subtitle1" color="primary">Reviews Stars</Typography>

      <Typography variant="body1">
        After placing the script tag in your source code, you can embed your Reviews rating represented by stars.
        Provide an anchor element, such as a div or span, with the data attribute, 'data-gb-reviews-embed-type',
        set to 'stars'. Refer to the following example:
      </Typography>
      <br />

      <TextField
        variant="outlined"
        multiline
        value={`<div data-gb-reviews-embed-type="stars"></div>`}
        className={classes.codeBlock}
      />

      {/* <div data-gb-reviews-embed-type="stars"></div> */}
      <img
        src={`${ reviewsBucketUrl() }/stars_example.png`}
        style={{
          maxWidth: '150px'
        }}
      />
    </Paper>
  );
}

const styles = theme => ({
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 5
  },
  avatarText: {
    lineHeight: '30px'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  paperRoot: {
    padding: 16
  },
  codeBlock: {
    width: '100%',
    backgroundColor: 'whitesmoke'
  }
})

export default withStyles(styles)(reviewsStars)
import React from 'react';
import { PromiseButton } from 'go-boost-library-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withStyles } from '@material-ui/core';


class EditExternalIdDialog extends React.Component  {
  state = {
    externalId: ''
  }


  componentDidUpdate = oldProps => {
    if( oldProps !== this.props ){
      return this.setState({
        externalId: this.props.externalId || ''
      });
    }
  }


  onSubmit = () => {
    if(
      !this.state.externalId
    ){
      window.alert('Please enter a valid External ID.');

      return Promise.reject();
    }

    return this.props.onSubmit( this.state.externalId );
  }


  render(){
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle>
          Edit External ID
        </DialogTitle>

        <DialogContent>
          <TextField
            fullWidth
            label='External ID'
            variant='outlined'
            value={this.state.externalId}
            onChange={e => this.setState({ externalId: e.target.value })}
            onBlur={() => this.setState({ externalId: this.state.externalId.trim() })}
            className={classes.textField}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            onClick={this.props.onClose}
            style={{ float: 'left' }}
          >
            Close
          </Button>

          <PromiseButton
            variant='contained'
            color='primary'
            onProcess={() => this.onSubmit(
              this.state.externalId
            )}
          >
            Edit
          </PromiseButton>
        </DialogActions>
      </Dialog>
    )
  }
}


const styles = theme => ({
  textField: {
    marginTop: 5
  }
})


EditExternalIdDialog.defaultProps = {
  open: false,
  externalId: '',
  onClose: () => {},
  onSubmit: () => Promise.reject(),
}


export default withStyles(styles)(
  EditExternalIdDialog
)
import React from 'react';
import Axios from 'axios';

import { SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, AutoError } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import { ChannelContext } from 'Channels/ChannelProvider';
import CompanyAdsProfileModel from './CompanyAdsProfileModel';
import history from 'history.js';



const DEFAULT_STATE = {
  adsProfileLoading: true,
  companyAdsProfile: null,
};


export const CompanyAdsProfileContext = React.createContext(DEFAULT_STATE);


class CompanyAdsProfileProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }


  fetchData = () => {
    this.fetchCompanyAdsProfile()
      .finally(() => this.setState({ adsProfileLoading: false }));
  }

  fetchCompanyAdsProfile = () => {
    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/companies/${currentUserRole.roleTypeId}/company_ads_profile`,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
      .then(response => {
        log('fetchCompanyAdsProfile', response);
        const companyAdsProfile = CompanyAdsProfileModel.fromJSON(response.data.company_ads_profile);
        this.setState({ companyAdsProfile });
      })
      .catch(error => {
        if(error.response.status !== 404) {
          throw error;
        }
        log('Company Ads Profile Not Found')
      });
  }


  createCompanyAdsProfile = () => {
    log('createCompanyAdsProfile')
    return Axios.post(
      `/api/ads/company_ads_profiles`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      response => log('createCompanyAdsProfile', response)
    ).then(
      this.fetchCompanyAdsProfile
    );
  }


  updateCompanyAdsProfile = updatedAttributes => {
    log('updateCompanyAdsProfile', updatedAttributes);
    const { id } = this.state.companyAdsProfile;
    const companyAdsProfile = new CompanyAdsProfileModel(updatedAttributes);
    return Axios.put(
      `/api/ads/company_ads_profiles/${id}`,
      { company_ads_profile: companyAdsProfile },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('updateCompanyAdsProfile response', response);
      const companyAdsProfile = CompanyAdsProfileModel.fromJSON(response.data.company_ads_profile);
      this.setState({ companyAdsProfile });
    });
  }




  enqueueCompanyAdsProfileSetup = () => {
    log('enqueueSetup');
    const { id } = this.state.companyAdsProfile;
    return Axios.post(
      `/api/ads/company_ads_profile_setup/${id}/enqueue`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('enqueueCompanyAdsProfileSetup response', response);
    }).then(
      this.fetchCompanyAdsProfile
    );
  }


  shutdownCompanyAdsProfile = () => {
    log('shutdownCompanyAdsProfile');
    const { id } = this.state.companyAdsProfile;
    return Axios.post(
      `/api/ads/company_ads_profiles/${id}/shutdown`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('shutdownCompanyAdsProfile response', response);
    }).then(
      this.props.fetchCompanyRoleProviderData
    ).then(
      this.fetchCompanyAdsProfile
    );
  }


  resubscribeToAds = () => {
    log('resubscribeToAds');
    const { id } = this.state.companyAdsProfile;
    return Axios.post(
      `/api/ads/company_ads_profiles/${id}/resubscribe`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('resubscribeToAds response', response);
    })
    .then(
      this.fetchCompanyAdsProfile
    ).then(
      () => history.push('/ads')
    ).catch(
      AutoError.catch.bind(this)
    );
  }


  undoResubscriptionToAds = () => {
    log('undoResubscriptionToAds');
    const { id } = this.state.companyAdsProfile;
    return Axios.delete(
      `/api/ads/company_ads_profiles/${id}/resubscribe`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('undoResubscriptionToAds response', response);
    }).then(
      this.fetchCompanyAdsProfile
    ).then(
      () => history.push('/ads')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  onError = error => {
    log('error', error);
    this.props.showSnackbar(
      error.response ?
        error.response.data.message || 'Error'
      :
        'Error'
    );
  }



  render() {
    const { adsProfileLoading } = this.state;

    if (adsProfileLoading) {
      return <LinearProgress />;
    }

    return (
      <CompanyAdsProfileContext.Provider
        value={{
          companyAdsProfile: this.state.companyAdsProfile,
          channels: this.props.channels,
          fetchCompanyAdsProfile: this.fetchCompanyAdsProfile,
          createCompanyAdsProfile: this.createCompanyAdsProfile,
          updateCompanyAdsProfile: this.updateCompanyAdsProfile,
          enqueueCompanyAdsProfileSetup: this.enqueueCompanyAdsProfileSetup,
          shutdownCompanyAdsProfile: this.shutdownCompanyAdsProfile,
          resubscribeToAds: this.resubscribeToAds,
          undoResubscriptionToAds: this.undoResubscriptionToAds
        }}
      >
        {this.props.children}
      </CompanyAdsProfileContext.Provider>
    );
  }
}


export default withContext(
  SnackbarContext,
  ChannelContext,
  CompanyRoleContext,
  CompanyAdsProfileProvider
);
import React from 'react';
import * as d3 from 'd3';
import { log } from 'kn-react';

import { isDevelopment, isStaging, isDemo } from 'go-boost-library-react';

import { Avatar, Typography, withStyles } from '@material-ui/core';
import { getTimePeriodMoment, dashboardDataRequest , getStartDate } from 'SharedDashboard/sharedDashboard';
import { Public } from '@material-ui/icons';

import MatomoReportModel from 'Sites/SitesDashboard/MatomoReportModel';
import SoftwareUsageTableContent from './SoftwareUsageTableContent';


class SoftwareUsageTable extends React.Component {
  state = {
    loadingSoftware: true,
    refreshingSoftware: false,
    softwareStats: [],
  }

  componentDidMount = () => {
    this.fetchSoftwareStats();
  }

  componentDidUpdate = oldProps => {
    if(this.props !== oldProps){
      this.setState(
        { refreshingSoftware: true },
        this.fetchSoftwareStats
      )

    }
  }

  fetchSoftwareStats = () => {
    const timePeriodMoment = getTimePeriodMoment(this.props.timePeriod);

    return dashboardDataRequest(
      '/sites/site_devices_reports',
      '/stats',
      this.props,
      {
        start_date: getStartDate(timePeriodMoment),
        segment: this.props.segment ? this.props.segment : null,
        companyIds: this.props.companyIds.length ? this.props.companyIds : undefined
      }
    )
    .then(response => {
      log('fetchSoftwareStats response', response);

      const softwareStats = response.data.stats.map(
        r => MatomoReportModel.fromJSON(r)
      );
      log('softwareStats',softwareStats)

      this.setState({
        softwareStats,
        loadingSoftware: false,
        refreshingSoftware: false
      });
    });
  }

  getSoftwareIcon = (s) => {
    let baseUrl;
    if(isDevelopment() || isStaging() || isDemo() ){
      baseUrl='https://hello-staging.goboost.io';
    } else {
      baseUrl='https://hello.goboost.io';
    }

    return (
      (s.logo.toLowerCase().endsWith('unk.png')) ?
        <Public/>
      :
        <Avatar src={baseUrl+'/'+s.logo} />
    )
  }

  placeholderTypography = text => {
    return (
      <Typography color="textSecondary" variant="caption" classes={{ root: this.props.classes.placeholderTypography }}>
        {text}
      </Typography>
    );
  }

  render(){
    const { classes } = this.props;
    const { softwareStats, loadingSoftware, refreshingSoftware } = this.state;

    if (loadingSoftware){
      return this.placeholderTypography('Loading...');
    }

    if (!loadingSoftware && softwareStats.length === 0){
      return this.placeholderTypography('No Data');
    }

    return (
      <div className={classes.root} style={{ opacity: refreshingSoftware ? 0.5 : 1 }}>
        <SoftwareUsageTableContent
          softwareStats={softwareStats}
        />
      </div>
    );
  }
}

SoftwareUsageTable.defaultProps = {
  timePeriod: {},
  roleTypePath: '',
  roleTypeId: '',
  getUserRoleAuthHeaders: () => {},
  segment: null,
  companyIds: [],
}

const styles = theme => ({
  root: {
    minHeight: 200,
    maxHeight: 270,
  },
  placeholderTypography: {
    padding: 16,
    textAlign: 'center'
  }
});


export default withStyles(styles)(SoftwareUsageTable);
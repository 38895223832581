import React from 'react';
import Axios from 'axios';

import { MaxWidth, withContext, log } from 'kn-react';
import { SnackbarContext, MeetingsTable, CompanyRoleContext } from 'go-boost-library-react';

import { Typography, withStyles } from '@material-ui/core';

import { ChannelContext } from 'Channels/ChannelProvider';
import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import { VirtualFormDesignContext } from 'Virtual/CompanyVirtual/VirtualFormDesigns/VirtualFormDesignProvider';
import MeetingModel from './MeetingModel';
import MeetingRoomModel from 'Virtual/CompanyVirtual/VirtualSettings/MeetingRoomModel';


class Meetings extends React.Component {
  state = {
    isLoading: true,
    page: 0,
    rowsPerPage: 10,
    meetings: [],
    meetingRooms: [],
    total: 0
  }

  componentDidMount = () => {
    return this.fetchMeetings();
  }


  fetchMeetings = () => {
    return Axios.get(
      `/api/virtual/meetings/companies/${ this.props.company.id }`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          page: this.state.page,
          limit: this.state.rowsPerPage
        }
      }
    ).then(response => {
      log('fetchMeetings response', response);
      const meetings = response.data.meetings.map(
        m => MeetingModel.fromJSON( m )
      );

      const meetingRooms = response.data.meeting_rooms.map(
        m => MeetingRoomModel.fromJSON( m )
      );


      this.setState({
        meetings,
        meetingRooms,
        total: response.data.total,
        isLoading: false
      });
    });
  }


  changePagination = newState => {
    return this.setState(
      {
        ...newState,
        isLoading: true,
      },
      this.fetchMeetings
    )
  }


  render() {
    const { classes } = this.props;

    return (
      <div >
        <Typography variant="h6">Meetings</Typography>

        <MaxWidth maxWidth={1200}>
          <MeetingsTable
            total={this.state.total}
            meetings={this.state.meetings}
            meetingRooms={this.state.meetingRooms}
            isLoading={this.state.isLoading}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangeRowsPerPage={e => this.changePagination({ rowsPerPage: e.target.value })}
            onChangePage={(e, p) => this.changePagination({ page: p })}
          />
        </MaxWidth>
      </div>
    );
  }
}


Meetings.defaultProps = {
}


const styles = theme => ({
  tableRow: {
    textDecoration: 'none',
  },
  summary: {
    // marginBottom: 25
  },
  filterMetric: {
    textTransform: 'none'
  },
  filterGrid: {
    borderRight: '1px solid lightgrey'
  },
  filterCaption: {
    marginBottom: 50
  },
  activeFilterMetric: {
    backgroundColor: 'lightgray !important'
  },
  noData: {
		textAlign: 'center',
		padding: 25,
		color: '#777',
	},
});

export default withContext(
  CompanyVirtualProfileContext,
  VirtualFormDesignContext,
  CompanyRoleContext,
  ChannelContext,
  SnackbarContext,
  withStyles(styles)(
    Meetings
  )
);
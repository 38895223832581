import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Link
} from "@material-ui/core";
import { format } from "kn-react";

const CouponCheckoutRow = props => {
  const { coupon, selectedPlan } = props;

  const discountText = coupon.percentOff ? `${coupon.percentOff}% off` : `${format.money(coupon.amountOff / 100)} off`;
  const durationText = (
    coupon.duration === "forever" ? "forever" : (
      coupon.duration === "once" ?
        "one time"
      : `for ${coupon.durationInMonths} ${ coupon.duration_in_months === 1 ? "month" : "months" }`
    )
  );


  return (
    <ListItem>
      <ListItemText
        primary="Subscription Discount"
        secondary={
          <>
            {discountText} {durationText}
          </>
        }
      />
      <ListItemSecondaryAction>
        <ListItemText
          style={{ textAlign: "right" }}
          primary={format.money(
            -coupon.getDiscountCentsWithPlan(selectedPlan) / 100
          )}
          secondary={
            <Link component="button" onClick={props.onClickRemove}>Remove</Link>
          }
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default CouponCheckoutRow;

CouponCheckoutRow.defaultProps = {
  onClickRemove: () => {}
};
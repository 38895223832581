import React from 'react';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { withStyles } from '@material-ui/core';

import { USER } from './Category';
import ActivityLogList from './ActivityLogList';

const organizationActivityLog = props => {
  const { classes, organization } = props;

  return (
    <ActivityLogList
      parentOrganizationId={ organization.id }
      includeDescendants={false}
      negatedCategories={ [ USER ] }
      showEntityName={true}
      entityFieldName={'organizationName'}
      tableToolbarCaption={
        <>
          Previous activity at <span className={classes.primary}>{organization.name}</span> and its children organizations.
        </>
      }
    />
  )
}

const styles = theme => ({
  primary: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
})

export default withContext(
  OrganizationRoleContext,
  withStyles(styles)(
    organizationActivityLog
  )
)
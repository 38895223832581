import React from 'react';

import { UserRoleContext, Ability } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import { GoogleAccountContext } from 'GoogleAccount/GoogleAccountProvider';
import ReviewDetails from 'Reviews/ReviewDetails/ReviewDetails';

const companyReviewDetails = props => {
  const googleAccountPresent = Boolean(props.googleAccount.id && props.googleAccountIsSignedIn());
  const facebookAccountPresent = Boolean(props.facebookAccount.id && props.facebookAccountIsSignedIn());
  const canEdit = props.currentUserRole.roleHasAbility( Ability.EDIT_REVIEWS_REVIEW_RESPONSES );

  return (
    <ReviewDetails
      {...props}
      googleAccountPresent={googleAccountPresent}
      facebookAccountPresent={facebookAccountPresent}
      canEdit={canEdit}
      refresh={true}
    />
  )
}


export default withContext(
  UserRoleContext,
  GoogleAccountContext,
  FacebookAccountContext,
  companyReviewDetails
);
import React from 'react';
import Axios from 'axios';
import { SnackbarContext, AuthContext, CredentialsModel } from 'go-boost-library-react';
import { log, MaxWidth, formHelpers, withContext, AutoError } from 'kn-react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, TextField, Button } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import history from '../history';
import paperPadding from 'paperPadding';

class Reset extends React.Component {

  state = {
    password: '',
    confirmPassword: '',
  }

  componentDidMount = () => {
    if(this.props.hasToken()){
      history.push('/');
    }
  }


  onSubmit = e => {
    e.preventDefault();
    return this.validate()
      .then(this.resetPassword)
      .then(credentials => this.signInAndRedirect(credentials))
      .catch(AutoError.catch.bind(this));
  }

  validate = () => {
    const { password } = this.state;
    let validations = {
      password: {
        presence: { message: 'Please enter a password.' },
        length: { atLeast: 8, message: 'Password must be at least 8 characters.' }
      },
      confirmPassword: {
        presence: { message: 'Please confirm your password.' },
        equalTo: { value: password, message: 'Passwords must match.' }
      }
    };

    return formHelpers.validate(this.state, validations);
  }


  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }


  resetPassword = () => {
    return Axios.post(
      '/api/core/auth/reset',
      {
        password: this.state.password,
        reset_token: this.props.match.params.reset_token
      }
    )
    .then(response => {
      log('resetPassword', response);
      const credentials = CredentialsModel.fromJSON(response.data.credentials);
      log('credentials', credentials);
      return credentials;
    })
    .catch(error => {
      log(error);
      throw {
        name: 'ResetPasswordError',
        message: error.response.data.message
      };
    });
  }

  onResetPasswordError = error => {
    this.props.showSnackbar(error.message);
  };


  signInAndRedirect = credentials => {
    this.props.setCredentials(credentials);
    history.push('/');
  }

  render(){
    const { classes } = this.props;
    const { errorMessage } = this.state;


    if(errorMessage) {
      return (
        <div className={classes.errorRoot}>
          <MaxWidth maxWidth={400}>
            <Error className={ classes.errorIcon}/>
            <Typography variant="h5" color="primary">{ errorMessage }</Typography>
            <Typography variant="caption">Contact us for assistance.</Typography>
          </MaxWidth>
        </div>
      )
    }

    return (
      <MaxWidth maxWidth={400}>
        <Paper className={classes.paperRoot}>
          <div className={classes.headingWrapper}>
            <Typography variant="h4">Reset Password</Typography>
            <Typography variant="caption">Enter your new password below.</Typography>
          </div>

          <form onSubmit={this.onSubmit} >
            <Grid container spacing={16}>

              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, 'password')}
                  margin="normal"
                  fullWidth
                />
                <Typography variant="caption">At least eight characters.</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Confirm Password"
                  type="password"
                  value={this.state.confirmPassword}
                  onChange={e => this.setState({ confirmPassword: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, 'confirmPassword')}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    margin="normal"
                    fullWidth
                  >
                    Reset
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
    </MaxWidth>
    );
  }

}

const styles = theme => ({
  errorRoot: {
    padding: paperPadding,
    marginTop: 100,
    textAlign: 'center',
  },
  errorIcon: {
    fontSize: 100,
  },
  paperRoot: {
    marginTop: 100,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 35,
    paddingBottom: 35,
  },
  headingWrapper: {
    textAlign: 'center',
  },
});

export default withStyles(styles)(withContext(AuthContext, SnackbarContext, Reset));
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Alert, CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Grid, withStyles } from '@material-ui/core';
import { Warning } from '@material-ui/icons';


const stripePaymentAlert = props => (
  props.company.stripeError ?
    <Grid item sm={12}>
      <Alert warning component={RouterLink} to="/billing/payment_method" className={props.classes.alert}>
        <div className={props.classes.iconWrapper}>
          <Warning />
        </div>

        <div className={props.classes.text}>
          There was an error processing your card. Please inspect your billing information.
        </div>
      </Alert>
    </Grid>
    :
    null
);




const styles = theme => ({
  alert: {
    display: 'block',
    textDecoration: 'none'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: 50,
  },
  text: {
    marginLeft: 30,
    lineHeight: '24px'
  }
})

export default withContext(
  CompanyRoleContext,
  withStyles(styles)(
    stripePaymentAlert
  )
);
import React from 'react';
import Axios from 'axios';
import Moment from 'moment';

import { withContext } from 'kn-react';
import { SnackbarContext, UserRoleContext } from 'go-boost-library-react';

import FeedItem from './FeedItem';

import history from 'history.js';


const newFeedItem = props => {
  const onProcess = feedItem => {
    feedItem.postAt = Moment.utc(feedItem.postAt).format('YYYY-MM-DDTHH:mm:ss');

    return Axios.post(
      `/api/social/feed_items/feeds/${props.match.params.feedId}`,
      {
        feed_item: feedItem,
        post_at: feedItem.postAt,
        feed_type: feedItem.feedType
      },
      { headers: props.getUserRoleAuthHeaders() }
    ).then(response => {
      props.showSnackbar('Feed Item successfully created.');
      return history.push(`/social/feeds/${props.match.params.feedId}/timeline`)
    }).catch(error => {

    })
  }


  return(
    <FeedItem
      actionText={'Create'}
      feedTypeDisabled={false}
      onProcess={onProcess}
    />
  )
}


export default withContext(
  UserRoleContext,
  SnackbarContext,
  newFeedItem
)
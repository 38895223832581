import React from 'react';
import Axios from 'axios';
import hashIdCoder from 'hashIdCoder';

import { SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, AutoError } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import CompanySitesProfileModel from './CompanySitesProfileModel';
import history from 'history.js';
import SiteModel from 'Sites/SiteModel';



const DEFAULT_STATE = {
  sitesProfileLoading: true,
  companySitesProfile: null,
  channels: {}
};


export const CompanySitesProfileContext = React.createContext(DEFAULT_STATE);


class CompanySitesProfileProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }



  updateCompanySiteProvider = () => {
    return this.fetchData();
  }



  fetchData = () => {
    return this.fetchCompanySitesProfile()
      .finally(() => this.setState({ sitesProfileLoading: false }));
  }



  fetchCompanySitesProfile = () => {
    const { currentUserRole } = this.props;
    return Axios.get(
      `/api/core/companies/${ currentUserRole.roleTypeId }/company_sites_profile`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchCompanySitesProfile', response);
      const companySitesProfile = CompanySitesProfileModel.fromJSON(response.data.company_sites_profile);
      this.setState({ companySitesProfile });
    }).catch(error => {
      if(error.response.status !== 404) {
        throw error;
      }
      log('Company Sites Profile Not Found')
    });
  }



  createCompanySitesProfile = () => {
    log('createCompanySitesProfile')
    return Axios.post(
      `/api/sites/company_sites_profiles`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      response => log('createCompanySitesProfile', response)
    ).then(
      this.fetchCompanySitesProfile
    );
  }



  updateCompanySitesProfile = updatedAttributes => {
    log('updateCompanySitesProfile', updatedAttributes);
    const { id } = this.state.companySitesProfile;
    const companySitesProfile = new CompanySitesProfileModel(updatedAttributes);
    return Axios.put(
      `/api/sites/company_sites_profiles/${id}`,
      { company_sites_profile: companySitesProfile },
      { headers: this.props.getUserRoleAuthHeaders()}
    ).then(response => {
      log('updateCompanySitesProfile response', response);
      const companySitesProfile = CompanySitesProfileModel.fromJSON(response.data.company_sites_profile);
      this.setState({ companySitesProfile });
    });
  }



  enqueueCompanySitesProfileSetup = site => {
    log('enqueueCompanySitesProfileSetup');

    const { id } = this.state.companySitesProfile;
    const siteHashId = hashIdCoder.encode(site.id);

    return Axios.post(
      `/api/sites/company_sites_profile_setup/${id}/enqueue`,
      {
        test_link: `https://site-${siteHashId}-preview.goboost.xyz/?retrieve_feedback=true`
      },
      { headers: this.props.getUserRoleAuthHeaders()}
    ).then(response => {
      log('enqueueCompanySitesProfileSetup response', response);
    }).then(
      this.fetchCompanySitesProfile
    );
  }



  duplicateSite = ( siteId, companySiteDesignId ) => {
    log('duplicateSite');
    return Axios.post(
      `/api/sites/${siteId}/duplicate`,
      { company_site_design_id: companySiteDesignId },
      { headers: this.props.getUserRoleAuthHeaders()}
    ).then(response => {
      return SiteModel.fromJSON( response.data.site )
    });
  }



  shutdownCompanySitesProfile = () => {
    log('shutdownCompanySitesProfile');
    const { id } = this.state.companySitesProfile;
    return Axios.post(
      `/api/sites/company_sites_profiles/${id}/shutdown`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('shutdownCompanySitesProfile response', response);
    }).then(
      this.props.fetchCompanyRoleProviderData
    ).then(
      this.fetchCompanySitesProfile
    );
  }



  resubscribeToSites = () => {
    log('resubscribeToSites');
    const { id } = this.state.companySitesProfile;
    return Axios.post(
      `/api/sites/company_sites_profiles/${id}/resubscribe`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('resubscribeToSites response', response);
    }).then(
      this.fetchCompanySitesProfile
    ).then(
      () => history.push('/sites')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  undoResubscriptionToSites = () => {
    log('undoResubscriptionToSites');
    const { id } = this.state.companySitesProfile;
    return Axios.delete(
      `/api/sites/company_sites_profiles/${id}/resubscribe`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('undoResubscriptionToSites response', response);
    }).then(
      this.fetchCompanySitesProfile
    ).then(
      () => history.push('/sites')
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  onError = error => {
    log('error', error);
    this.props.showSnackbar(
      error.response ?
        error.response.data.message || 'Error'
      :
        'Error'
    );
  }



  render(){
    const { sitesProfileLoading } = this.state;

    if(sitesProfileLoading) {
      return <LinearProgress/>;
    }

    return (
      <CompanySitesProfileContext.Provider
        value={{
          companySitesProfile: this.state.companySitesProfile,
          fetchCompanySitesProfile: this.fetchCompanySitesProfile,
          createCompanySitesProfile: this.createCompanySitesProfile,
          updateCompanySitesProfile: this.updateCompanySitesProfile,
          enqueueCompanySitesProfileSetup: this.enqueueCompanySitesProfileSetup,
          shutdownCompanySitesProfile: this.shutdownCompanySitesProfile,
          duplicateSite: this.duplicateSite,
          resubscribeToSites: this.resubscribeToSites,
          undoResubscriptionToSites: this.undoResubscriptionToSites
        }}
      >
        { this.props.children }
      </CompanySitesProfileContext.Provider>
    );
  }
}


export default withContext(
  CompanyRoleContext,
  SnackbarContext,
  CompanySitesProfileProvider
);
import React from 'react';
import { Grid, Paper, TextField, Typography, withStyles, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ThemeContext, ShareIcon } from 'go-boost-library-react';
import { MaxWidth, withContext } from 'kn-react';
import AppNavigation from 'AppNavigation/AppNavigation';


class AddToHomeScreen extends React.Component {

  render() {
    const { theme, classes } = this.props;

    return (
      <AppNavigation title="Add To Home Screen">

        <MaxWidth maxWidth={800} style={{ padding: 16 }}>

          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">For quick access to {theme.appTitle}, add it to the home screen on your mobile device.</Typography>
            </Grid>


            <Grid item xs={12}>
              <Paper style={{ padding: 16 }}>
                <Typography variant="h6" paragraph>iOS</Typography>

                <Typography variant="body2" paragraph>
                  <ul>
                    <li>Tap the <span className={classes.icon}><ShareIcon /></span> Share button on the browser's toolbar.</li>
                    <li>Tap the <span className={classes.colorPrimary}>Add to Home Screen</span> button in the Share menu.</li>
                    <li>You'll be prompted to name the shortcut before tapping the Add button. </li>
                    <li>The shortcut can be dragged around and placed anywhere, including in app folders — just like a normal app icon.</li>
                  </ul>
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper style={{ padding: 16 }}>
                <Typography variant="h6" paragraph>Chrome for Android</Typography>
                <Typography variant="body2" paragraph>
                  <ul>
                    <li>Tap the <span className={classes.icon}><MoreVertIcon/></span> menu button and tap <span className={classes.colorPrimary}>Add to Home Screen</span>.</li>
                    <li>You'll be able to enter a name for the shortcut and then Chrome will add it to your home screen.</li>
                    <li>The icon will appear on your home screen like any other app shortcut or widget, so you can drag it around and put it wherever you like.</li>
                  </ul>
                </Typography>
              </Paper>

            </Grid>

          </Grid>

        </MaxWidth >
      </AppNavigation >
    );
  }

}


const styles = theme => ({
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
    position: 'relative',
    top: 4
  }
});


export default withStyles(styles)(
  withContext(
    ThemeContext,
    AddToHomeScreen
  )
)
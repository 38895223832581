import React from 'react';

import { CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import SitesDashboard from 'Sites/SitesDashboard/SitesDashboard';

const sitesDashboard = props => (
  <SitesDashboard
    roleTypePath="companies"
    roleTypeId={props.company.id}
    dataAsOfDate={props.companySitesProfile.matomoReportsLastUpdatedAt}
    currentUserRole={props.currentUserRole}
    getUserRoleAuthHeaders={props.getUserRoleAuthHeaders}
  />
);

export default withContext(
  CompanyRoleContext,
  CompanySitesProfileContext,
  sitesDashboard
);
import React from 'react';
import { format } from 'kn-react';

import { OverTimeChart, ChangeMetric } from 'go-boost-library-react';
import { Visibility } from '@material-ui/icons';

const uniqueImpressionsChart = props => {
  const uniqueImpressionsLineStyles = {
    'Daily Unique Impressions': {
      type:"monotone",
      dataKey:"Daily Unique Impressions",
      stroke:"#5a7af9",
      activeDot: { r: 8 }
    }
  };


  return (
    <OverTimeChart
      data={props.statsChartData}
      lineStyles={uniqueImpressionsLineStyles}
      tooltipProps={{
        formatter: value => format.commas(value)
      }}
    />
  )
}


uniqueImpressionsChart.defaultProps = {
  statsChartData: []
}

export default uniqueImpressionsChart



const UniqueImpressionsMetric = props => (
  <ChangeMetric
    title="Daily Unique Impressions"
    metric={ format.commas( props.statsTotals.totalUniqueImpressions ) || 0 }
    icon={Visibility}
    iconColor="#5a7af9"
    iconBackgroundColor="rgba(90, 122, 249, 0.2)"
    change={ props.statsChange.uniqueImpressionsChange || '' }
    width={260}
    height={260}
  />
)

UniqueImpressionsMetric.defaultProps = {
  statsTotals: {},
  statsChange: {}
}

export { UniqueImpressionsMetric }
import React from 'react';
import Axios from 'axios';

import { withContext, log } from 'kn-react';
import { SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';

import { LinearProgress } from '@material-ui/core';

import VirtualFormDesignModel from 'Virtual/VirtualFormDesign/VirtualFormDesignModel';
import CompanyVirtualFormDesignModel from './CompanyVirtualFormDesignModel';



const DEFAULT_STATE = {
  virtualFormDesignsLoading: true,
  virtualFormDesigns: [],
  companyVirtualFormDesigns: []
};


export const VirtualFormDesignContext = React.createContext(DEFAULT_STATE);


class VirtualFormDesignProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {
    this.fetchVirtualFormDesigns()
      .then(this.fetchCompanyVirtualFormDesigns)
      .finally(() => this.setState({ virtualFormDesignsLoading: false }));
  }



  fetchVirtualFormDesigns = () => {
    return Axios.get(
      `/api/virtual/virtual_form_designs/companies/${ this.props.company.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchVirtualFormDesigns', response);
      const virtualFormDesigns = response.data.virtual_form_designs.map(
        v => VirtualFormDesignModel.fromJSON( v )
      );

      this.setState({ virtualFormDesigns });
    }).catch(error => {
      if(error.response.status !== 404) {
        throw error;
      }
    });
  }


  fetchCompanyVirtualFormDesigns = () => {
    return Axios.get(
      `/api/virtual/company_virtual_form_designs/companies/${ this.props.company.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchCompanyVirtualFormDesigns', response);
      const companyVirtualFormDesigns = response.data.company_virtual_form_designs.map(
        v => CompanyVirtualFormDesignModel.fromJSON( v )
      );
      log('companyVirtualFormDesigns', companyVirtualFormDesigns);

      this.setState({ companyVirtualFormDesigns });
    }).catch(error => {
      if(error.response.status !== 404) {
        throw error;
      }
    });
  }


  createCompanyVirtualFormDesign = virtualFormDesignId => {
    return Axios.post(
      `/api/virtual/company_virtual_form_designs/virtual_form_designs/${ virtualFormDesignId }`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('createCompanyVirtualFormDesign', response);
      return CompanyVirtualFormDesignModel.fromJSON(
        response.data.company_virtual_form_design
      );
    }).catch(error => {
      if(error.response.status !== 404) {
        throw error;
      }
    });
  }


  updateCompanyVirtualFormDesign = (companyVirtualFormDesignId, updatedAttributes) => {
    const companyVirtualFormDesign = CompanyVirtualFormDesignModel.fromJSON(updatedAttributes).toJSON();
    return Axios.put(
      `/api/virtual/company_virtual_form_designs/${ companyVirtualFormDesignId }`,
      { company_virtual_form_design: companyVirtualFormDesign },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      this.fetchCompanyVirtualFormDesigns
    ).catch(error => {
      if(error.response.status !== 404) {
        throw error;
      }
    });
  }


  render(){
    const { virtualFormDesignsLoading } = this.state;

    if(virtualFormDesignsLoading) {
      return <LinearProgress/>;
    }

    return (
      <VirtualFormDesignContext.Provider
        value={{
          ...this.state,
          fetchVirtualFormDesigns: this.fetchVirtualFormDesigns,
          fetchCompanyVirtualFormDesigns: this.fetchCompanyVirtualFormDesigns,
          createCompanyVirtualFormDesign: this.createCompanyVirtualFormDesign,
          updateCompanyVirtualFormDesign: this.updateCompanyVirtualFormDesign
        }}
      >
        { this.props.children }
      </VirtualFormDesignContext.Provider>
    );
  }
}


export default withContext(CompanyRoleContext, SnackbarContext, VirtualFormDesignProvider);
import React from 'react';
import Axios from 'axios';

import { SnackbarContext, PlanContext, Ability, CompanyRoleContext, KnowledgeBaseButton } from 'go-boost-library-react';
import { withContext, log, withProvider, AutoError, ScrollTo } from 'kn-react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { CompanyReviewsProfileContext } from '../CompanyReviewsProfile/CompanyReviewsProfileProvider';
import CompanyReviewSiteModel from '../../CompanyReviewSiteModel';
import CompanyReviewSitesCard from '../CompanyReviewSites/CompanyReviewSitesCard';
import paperPadding from 'paperPadding';
import ReviewRequestSettingsCard from './ReviewRequestSettingsCard';
import ReviewsConnectedAccounts from './ReviewsConnectedAccounts';
import ReviewTrackersLocationCard from '../ReviewTrackersLocation/ReviewTrackersLocationCard';
import ReviewTrackersLocationProvider from 'Reviews/CompanyReviews/ReviewTrackersLocation/ReviewTrackersLocationProvider';



class ReviewsSettings extends React.Component {
  state = {
    companyShortName: this.props.companyReviewsProfile.companyShortName,
    facebookPageUrl: this.props.companyReviewsProfile.facebookPageUrl,
    companyReviewSites: [],
    companyReviewSitesLoading: true,
    existingSiteDialogOpen: false,
    newSiteDialogOpen: false,
    focusedCompanyReviewSite: {},
    newCompanyReviewSite: {}
  }

  componentDidMount = () => {
    this.fetchCompanyReviewSites();
  }


  fetchReviewTrackersLocation = () => {
    return Axios.get(`/api/reviews/review_trackers_locations/`)
  }


  onChangeCompanyShortName = companyShortName => {
    this.setState({ companyShortName });
  }

  onChangeFacebookPageUrl = facebookPageUrl => {
    this.setState({ facebookPageUrl });
  }

  onUpdateProfile = (companyReviewsProfile) => {
    return this.props.updateCompanyReviewsProfile(companyReviewsProfile)
      .finally(() => this.props.showSnackbar('Reviews Settings successfully updated.'))
  }

  openDialog = (reviewSite) => {
    log('edit', reviewSite)
    this.setState({
      focusedCompanyReviewSite: reviewSite,
      existingSiteDialogOpen: true
    });
  }

  fetchCompanyReviewSites = () => {
    return Axios.get(
      `/api/reviews/company_review_sites/companies/${this.props.companyReviewsProfile.companyId}`,
      {
        headers: this.props.getUserRoleAuthHeaders()
      }
    )
      .then(response => {
        const companyReviewSites = response.data.company_review_sites.map(r => CompanyReviewSiteModel.fromJSON(r));
        log('Reviews Models', companyReviewSites);
        this.setState({ companyReviewSites, companyReviewSitesLoading: false });
      })
  }

  onUpdateCompanyReviewSite = (reviewSite) => {
    const { companyReviewSites } = this.state;
    const index = companyReviewSites.findIndex(r => r.id === reviewSite.id);
    companyReviewSites[index] = reviewSite;
    this.setState({ companyReviewSites, existingSiteDialogOpen: false });
  }

  onExistingSiteClose = (callback) => {
    this.setState(
      { existingSiteDialogOpen: false },
      () => (callback) ? callback() : null
    );
  }


  onCreateCompanyReviewSite = (companyReviewSite) => {
    const { companyReviewSites } = this.state;

    this.setState({ companyReviewSites: [...companyReviewSites, companyReviewSite], newSiteDialogOpen: false })
  }



  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }

  onError = error => {
    log('error', error);
    this.props.showSnackbar(error.response.data.message);
  }


  render() {
    const { classes, currentUserRole, subscribedReviewsPlan } = this.props;
    const { companyReviewSites, companyReviewSitesLoading } = this.state;

    const hasEditAbility = (currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_REVIEWS )) ? true : false;
    const isShutdown = this.props.companyReviewsProfile && this.props.companyReviewsProfile.isShutdown;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">Reviews Settings</Typography>
        </Grid>

        {
          (subscribedReviewsPlan && subscribedReviewsPlan.isReviewTrackersIncluded) ?
            <Grid item xs={12}>
              <Paper className={classes.paperRoot}>
                <ReviewTrackersLocationCard
                  reviewTrackersLocation={this.props.reviewTrackersLocation}
                  fieldsDisabled={!hasEditAbility}
                >
                  {
                    props => (
                      (!isShutdown && currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_REVIEWS )) ?
                        <div style={{ paddingTop: 16 }}>
                          <Grid container spacing={16}>
                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => props.onProcess().catch(AutoError.catch.bind(this)) }
                                id='update-settings-button'
                                name='update-settings-button'
                              >
                                Update
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      :
                        null
                    )
                  }
                </ReviewTrackersLocationCard>
              </Paper>
            </Grid>
          :
            null
        }

        <Grid item xs={12}>
          <ReviewRequestSettingsCard/>
        </Grid>

        <Grid item xs={12} ref='connectedAccounts'>
          <ScrollTo id='connected-accounts' />
          <ReviewsConnectedAccounts
            onUpdateAccount={this.fetchCompanyReviewSites}

          >
            <Typography variant="subtitle1" color="primary">
              Connect Accounts

              &nbsp;

              <KnowledgeBaseButton
                path={'/connecting_google_and_facebook_reviews'}
                tooltipTitle={'Learn more about connecting Google and Facebook to Reviews'}
              />
            </Typography>
          </ReviewsConnectedAccounts>
        </Grid>

        <Grid item xs={12}>
          <CompanyReviewSitesCard
            companyReviewSites={companyReviewSites}
            onEdit={this.openDialog}
            onAdd={() => this.setState({ newSiteDialogOpen: true })}
            loading={companyReviewSitesLoading}
            onUpdateCompanyReviewSite={this.onUpdateCompanyReviewSite}
          />
          {/* <CompanyReviewSitesDialog
            open={this.state.existingSiteDialogOpen}
            companyReviewSite={this.state.focusedCompanyReviewSite}
            onUpdateCompanyReviewSite={this.onUpdateCompanyReviewSite}
            onClose={(callback) => this.onExistingSiteClose(callback)}
          />
          <NewCompanyReviewSitesDialog
            open={this.state.newSiteDialogOpen}
            onCreateCompanyReviewSite={this.onCreateCompanyReviewSite}
            onClose={() => this.setState({ newSiteDialogOpen: false })}
          /> */}
        </Grid>
      </Grid>
    );
  }

}

const styles = theme => ({
  paperRoot: {
    padding: paperPadding
  },
  settings: {
    position: 'relative',
  },
})

export default withStyles(styles)(
  withProvider(
    ReviewTrackersLocationProvider,
    withContext(
      CompanyRoleContext,
      CompanyReviewsProfileContext,
      PlanContext,
      SnackbarContext,
      ReviewsSettings
    )
  )
);
import React from 'react';

import { IconButton, TextField, InputAdornment, withStyles } from '@material-ui/core';
import { Add, Search, Clear } from '@material-ui/icons';


class CompanySearchBox extends React.Component {
  state = {
    query: '',
  };


  onSubmitSearch = e => {
    e.preventDefault();

    this.props.onSubmitSearch(
      this.state.query.trim()
    );
  }


  onClickClear = () => {
    this.setState(
      { query: ''},
      () => this.props.onSubmitSearch('')
    );
  }


  render() {
    const { classes } = this.props;
    const { query } = this.state;

    return (
      <form onSubmit={this.onSubmitSearch}>
        <TextField
          placeholder={ this.props.placeholder }
          value={query}
          onChange={e => this.setState({ query: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment className={classes.searchIcon} position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              query ?
                <InputAdornment position="end">
                  <IconButton onClick={this.onClickClear}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              :
                null
            )
          }}
          inputProps={{
            className: classes.search,
          }}
          fullWidth
        />
        <button style={{ display: 'none' }} />
      </form>
    );
  }

}


CompanySearchBox.defaultProps = {
  onSubmitSearch: query => {},
  placeholder: 'Search Companies...'
}


const styles = theme => ({
  search: {
    paddingTop: 10,
    paddingBottom: 14,
  },
  searchIcon: {
    marginLeft: 24,
    color: '#A7A7A7',
  }
});

export default withStyles(styles)(CompanySearchBox);
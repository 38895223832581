import React from 'react';
import Axios from 'axios';

import { log, withContext } from 'kn-react';
import { SnackbarContext, Ability, TableToolbar, UserRoleContext, CompanyRoleContext, PlanContext, KnowledgeBaseButton } from 'go-boost-library-react';

import { Grid, Paper } from '@material-ui/core';

import AppNavigation from '../../AppNavigation/AppNavigation';
import CompanyInfoCard from 'Settings/Companies/CompanyDetails/CompanyInfoCard';
import CompanyProductDetails from 'Settings/Companies/CompanyProductDetails/CompanyProductDetails';
import ExternalData from './ExternalData';
import OrganizationModel from 'Settings/Organizations/OrganizationModel';
import SignUpCodeModel from 'SignUp/SignUpCodeModel';
import StripePaymentAlert from 'CompanyRole/stripePaymentAlert';
import SubscriptionList from 'Settings/Subscriptions/SubscriptionList/SubscriptionList';
import KnowledgeBaseLaunchBanner from 'KnowledgeBaseLaunchBanner/KnowledgeBaseLaunchBanner';

class CompanyProfile extends React.Component {
  state = {
    signUpCodes: [],
    organizations: []
  }


  componentDidMount = () => {
    return this.getSignUpOrganization()
      .then( this.getPossibleSignUpCodes );
  }


  getSignUpOrganization = () => {
    log('getSignUpOrganization');
    return Axios.get(
      `/api/core/organizations/${this.props.company.signUpOrganizationId}`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          include_descendants: false
        }
      }
    ).then(response => {
      log('getSignUpOrganization response', response);
      const organization = OrganizationModel.fromJSON(
        response.data.organization
      );

      this.setState({ organizations: [ organization ] });
    });
  }


  getPossibleSignUpCodes = () => {
    log('getPossibleSignUpCodes');
    return Axios.get(
      `/api/core/sign_up_codes/companies/${this.props.company.id}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('getPossibleSignUpCodes response', response);
      const signUpCodes = response.data.sign_up_codes.map(c => SignUpCodeModel.fromJSON(c));

      this.setState({ signUpCodes });
    });
  }

  updateCompany = updatedAttributes => {
    return this.props.updateCompany(
      updatedAttributes
    ).then(
      () => this.props.showSnackbar('Company Info Successfully Updated')
    )
  }


  render() {
    const canEdit = this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_INFO );
    const canEditExternalData = this.props.currentUserRole.roleHasAbility( Ability.EDIT_ORGANIZATION_EXTERNAL_DATA );

    const planIds = new Set();
    const subscriptions = [
      this.props.activeAdsSubscription,
      this.props.activeReviewsSubscription,
      this.props.activeSitesSubscription,
      this.props.activeSocialSubscription,
      this.props.activeVirtualSubscription,
    ].filter(s => s && !planIds.has( s.planId ) && planIds.add( s.planId ));

    return (
      <AppNavigation title="Company Profile">
        <div style={{ padding: 16 }}>

          <Grid container spacing={16}>
            <StripePaymentAlert/>
            <KnowledgeBaseLaunchBanner />

            <Grid item xs={12}>
              <CompanyInfoCard
                canEdit={canEdit}
                company={this.props.company}
                signUpCodes={this.state.signUpCodes}
                organizations={this.state.organizations}
                onUpdateCompany={this.updateCompany}
              />
            </Grid>


            <Grid item xs={12}>
              <Paper style={{ padding: 15 }}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <TableToolbar
                      title="Subscriptions"
                    />
                    <SubscriptionList
                      subscriptions={subscriptions}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>


            <Grid item xs={12}>
              <Paper style={{ padding: 15 }}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <TableToolbar
                      title={(
                        <>
                          Available Products

                          &nbsp;

                          <KnowledgeBaseButton
                            path={'/how_to_upgrade_or_downgrade_products'}
                            tooltipTitle={'Learn how to upgrade and downgrade your Products'}
                          />
                        </>
                      )}
                    />
                    <CompanyProductDetails/>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>


            <Grid item xs={12}>
              <ExternalData
                companyId={this.props.company.id}
                canEditExternalData={canEditExternalData}
              />
            </Grid>
          </Grid>
        </div>
      </AppNavigation>
    );
  }

}

export default withContext(
  CompanyRoleContext,
  UserRoleContext,
  SnackbarContext,
  PlanContext,
  CompanyProfile
);
